/* eslint-disable */
import React, {
	useEffect,
	useRef,
	useState,
	useCallback,
	useMemo,
} from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Select from "react-select";
import { toast } from "react-toastify";
import _ from "lodash";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { RiArrowDownSLine } from "react-icons/ri";
import Button from "components/Button/Button.component";
import Tab from "components/NavTabs/NavTab.component";
import Tabs from "components/NavTabs/NavTabs.component";
import InputCustom from "components/InputCustom/InputCustom.component";
import CustomSelect from "components/CustomSelect/CustomSelect.component";
import DropdownIndicator from "components/DropdownIndicator/DropdownIndicator.component";
import * as actions from "../../Configurations/actions";
import { IconPencil, IconX } from "assets/icons/icons";
import { FiCheck, FiTrash2 } from "react-icons/fi";
import { ConfigurationsState } from "types/reducers/configurations.reducer.type";
import { number_format, parseNumberCustom } from "utils/functions";

import styles from "./EmployeeModal.module.scss";

import {
	customSelectStyles,
	employerTypeOptions,
	employmentTypeOptions,
	activeInactiveOptions,
	allFamilyMemberOptions,
	salaryPaymentTypeOptions,
	yesNoOptions,
	inputOnFocusSelect,
	inputNumberKeydownHandler,
	contractOptions,
	DummyDataProjects,
	valuePercentageProjectSwitch,
	genderOptions,
	doNothing,
	validNumber,
	FamilyMemberOption,
} from "utils/globalVariables";
import {
	clearEmployeeModalData,
	deleteEmployee,
	deleteEmployeeProject,
	removeAttachments,
	setIsAddEmployeeOn,
	setUnchangableBrutto,
	setUnchangableNetto,
	submitCreateEmployee,
	submitEditEmployee,
	submitSaveEmployee,
} from "../actions";

import InputField from "components/InputField/InputField.component";
import { calculateSalary } from "utils/brutoNeto";
import EmployeeNameAndImage from "../EmployeeNameAndImage/EmployeeNameAndImage";
import DropdownSelect from "components/DropdownSelect/DropdownSelect.component";
import Loader from "components/partials/Loader/Loader.component";
import {
	DraftStatus,
	Employee,
	EmployeeProjectBudgetLine,
	FamilyMember,
	FamilyMemberType,
	SalaryPaymentType,
	SaveEmployee,
} from "types/employee.type";
import { EmployeesReducer } from "types/reducers/employees.reducer.type";
import EmployeeAttachments from "../EmployeeAttachments/EmployeeAttachments";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.component";
import { formatNumber_ } from "utils/functions";
import { globalState } from "types/globalState.type";
import ReactTooltip from "react-tooltip";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import { FamilyMembersHealthInsuranceSalaryCalculationSetup } from "services/salaries.service";
import { getContribute } from "services/configuration.service";


export type FileUploadRequest = {
	data: string;
	name: string;
	extension: string;
}

const EmployeesModal = (props) => {
	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);

	const userClientId = useSelector(
		(state: globalState) => state.globalReducer?.user?.clientId
	);

	const employeesReducer: EmployeesReducer = useSelector(
		(state: RootStateOrAny) => state.employeesReducer
	);

	const modalEmployeeSelected: Employee =
		employeesReducer.modalEmployeeSelected;

	const activeTab: number = employeesReducer.activeTab;
	let isEmployeeSelected: boolean = modalEmployeeSelected?.id > 0;
	const submitButtonLoading = employeesReducer.submitButtonLoading;
	const loadingDraftButton = employeesReducer.loadingDraftButton;
	const deleteButtonLoading = employeesReducer.deleteButtonLoading;

	const dispatch = useDispatch();

	const subWrapperRef = useRef(null);
	const [editMode, setEditMode] = useState(false);
	const [modalEmployeeInputs, setModalEmployeeInputs] = useState<any>({});
	const [employerType, setEmployerType] = useState(employerTypeOptions[0]);
	const [gender, setGender] = useState(genderOptions[0]);
	const [salaryPaymentType, setSalaryPaymentType] = useState(
		salaryPaymentTypeOptions[0]
	);
	const [employmentType, setEmploymentType] = useState(
		employmentTypeOptions[0]
	);
	const [activeInactive, setActiveInactive] = useState(
		activeInactiveOptions[0]
	);
	const [healthInsurance, setHealthInsurance] = useState(yesNoOptions[2]);
	const [expirationContractType, setExpirationContractType] = useState<any>(
		contractOptions[0]
	);

	const attachmentFileInput = useRef(null);
	const [attachmentFile, setAttachmentFile] = useState(null);
	const [isAttachmentFilePicked, setIsAttachmentFilePicked] = useState(false);

	const [familyMember, setFamilyMember] = useState([]);
	const [sector, setSector] = useState([]);

	const [pickedProjects, setPickedProjects] = useState([]);
	const [toggledBudgetLines, setToggledBudgetLines] = useState([]);
	const [toggledSelectableBudgetLines, setToggledSelectableBudgetLines] =
		useState([]);
	const [pickedProjectsWithBudgetLines, setPickedProjectsWithBudgetLines] =
		useState([]);
	const [pickedProjectBudgetLines, setPickedProjectBudgetLines] = useState([]);
	const [pickedSectors, setPickedSectors] = useState([]);
	const [pickedSectorsIds, setPickedSectorsIds] = useState([]);
	const [projectValueType, setProjectValueType] = useState({});
	const [noTaxes, setNoTaxes] = useState(false);
	const [projectToDelete, setProjectToDelete] = useState(null);
	const [sectorOptionsLeft, setSectorOptionsLeft] = useState(null);
	const [displaySalaryValues, setDisplaySalaryValues] = useState(true);

	const updatedValue = useRef("");
	const updatedHourValue = useRef("");
	const [hours, setHours] = useState<number>();
	const [percentageValue, setPercentageValue] = useState(null);
	const [familyHealthInsuranceOn, setFamilyHealthInsuranceOn] = useState(false);

	const [allPercentages, setAllPercentages] = useState(null);
	const [euroBudget, setEuroBudgetg] = useState(null);

	const [hasPassedCondition, setHasPassedCondition] = useState(false);
	const [isDeleteProjectOn, setIsDeleteProjectOn] = useState(false);
	const isAddEmployeeOn: boolean = employeesReducer?.isAddEmployeeOn;
	const bruttoInitial: number = employeesReducer?.initialBrutto;
	const calculationNeeded = useRef(false);

	const [firstBrutto, setFirstBrutto] = useState(null);
	const firstBruttoRef = useRef(null);
	const [isTrueCalculatetProject, setIsTrueCalculatetProject] = useState(true);
	const [, updateState] = useState();

	const [isProjectValid, setIsProjectValid] = useState(false);
	const [loading, setLoading] = useState(true);

	const initialLoad = () => {
		dispatch(actions.getAllProjects({ tenantId, activeProjectsOnly: true }));
		dispatch(actions.getContribute());
		dispatch(actions.getTaxDegreesInformation());
		dispatch(actions.getHealthInsurance(tenantId));
		dispatch(actions.getTenantSectors(tenantId));
	};

	const state: ConfigurationsState = useSelector(
		(state: globalState) => state.configurationsReducer
	);
	const allProjects = state?.projects;
	const allSectors = state?.tenantSectors;
	const contribute = state.contributionData;
	const tenantHealthInsurance = state.tenantHealthInsurance;

	useEffect(() => {
		if (localStorage.getItem("token")) {
			initialLoad();
		}
	}, []);

	useEffect(() => {
		let tempArray = [...toggledBudgetLines];
		pickedProjects.forEach((project, index) => {
			tempArray.push(true);
		});

		let selectableBudgetLines = [...toggledSelectableBudgetLines];

		pickedProjects.forEach((project, index) => {
			selectableBudgetLines.push(false);
		});
		setToggledBudgetLines(tempArray);
		setToggledSelectableBudgetLines(selectableBudgetLines);
	}, [pickedProjects]);

	function toggleBudgetLines(projectIndex) {
		let tempArray = [...toggledBudgetLines];

		tempArray[projectIndex] = !tempArray[projectIndex];

		setToggledBudgetLines(tempArray);
	}
	function toggleSelectableBudgetLines(projectIndex, project) {
		if (modalEmployeeSelected && project) {
			handleProjectPick(
				null,
				allProjects?.find((e) => e.id == project.id)
			);
		}

		let tempArray = [...toggledSelectableBudgetLines];
		tempArray[projectIndex] = !tempArray[projectIndex];

		setToggledSelectableBudgetLines(tempArray);
	}

	const closeModal = () => {
		props.setShowEmployeeModal(false);
		setEditMode((prev) => false);

		setTimeout(() => {
			setGender(genderOptions[0]);
			setEmployerType(employerTypeOptions[0]);
			setHealthInsurance(yesNoOptions[2]);
			setSalaryPaymentType(salaryPaymentTypeOptions[0]);
			setExpirationContractType(contractOptions[0]);
			setEmploymentType((prev) => employmentTypeOptions[0]);
			setActiveInactive((prev) => activeInactiveOptions[0]);
			setPickedProjects([]);
			setPickedProjectBudgetLines([]);
			setPickedProjectsWithBudgetLines([]);
			setToggledSelectableBudgetLines([]);
			setToggledBudgetLines([]);
			setPickedSectors([]);
			setProjectValueType({});
			setIsAttachmentFilePicked(false);
			setAttachmentFile(null);
			dispatch(removeAttachments());
			setNoTaxes(false);
		}, 500);
	};

	const onCloseModal = () => {
		props.setShowEmployeeModal(false);
	};

	const createFamilyMemberArray = (data: {
		[key: number]: number;
	}): FamilyMember[] => {
		if (Object.keys(data ?? {}).length === 0) return [];
		return Object.entries(data).map(([key, value]) => ({
			familyMemberType: Number(key) as unknown as FamilyMemberType,
			members: value,
		}));
	};

	const onSave = (draftStatus = DraftStatus.Undrafted) => {
		if (
			(salaryPaymentType?.value === SalaryPaymentType.Project &&
				isProjectValid === false) ||
			calculationNeeded.current
		) {
			toast.error("Ju lutem kalkuloni vlerat para se te ruani!");
			return;
		}
		if (
			salaryPaymentType?.value === SalaryPaymentType.Hourly &&
			!modalEmployeeInputs?.pricePerHour
		) {
			toast.error("Paga duhet te jete e plotesuar!");
			return;
		}
		if (
			salaryPaymentType?.value !== SalaryPaymentType.Hourly &&
			!modalEmployeeInputs.bruttoSalary
		) {
			toast.error("Paga duhet te jete e plotesuar!");
			return;
		}

		let firstName = "";
		let lastName = "";
		if (modalEmployeeInputs.firstName) {
			firstName = modalEmployeeInputs.firstName.split(" ")[0];
			lastName = modalEmployeeInputs.firstName.replace(firstName, "");
		}

		const payload: Partial<SaveEmployee> = {
			id: modalEmployeeInputs.id,
			userId: tenantId,
			firstName: firstName.trim(),
			lastName: lastName.trim(),
			bank: modalEmployeeInputs.bank,
			bankAccountNumber: modalEmployeeInputs.bankAccountNumber,
			email: modalEmployeeInputs.email,
			draftStatus,
			phone: modalEmployeeInputs.phone,
			familyContact: modalEmployeeInputs.familyContact,
			employerTier: employerType?.value,
			employmentType: employmentType?.value,
			salaryPaymentType: salaryPaymentType?.value,
			gender: gender?.value,
			bruttoSalary: modalEmployeeInputs.bruttoSalary,
			nettoSalary: modalEmployeeInputs.nettoSalary,
			tax: modalEmployeeInputs.tax,
			noTaxes,
			contributePercentage: modalEmployeeInputs.contributePercentage,
			healthInsurancePercentage: modalEmployeeInputs.insurancePercentage,
			healthInsuranceValue: modalEmployeeInputs?.healthInsuranceValue,
			contribution: modalEmployeeInputs.contribution,
			unchangableBrutto: modalEmployeeInputs.unchangableBrutto,
			pricePerHour: !!modalEmployeeInputs.pricePerHour
				? modalEmployeeInputs.pricePerHour
				: 0,
			jobStarted: modalEmployeeInputs.jobStarted,
			contractStarted: modalEmployeeInputs.contractStarted,
			birthDate: modalEmployeeInputs.birthDate,
			municipality: modalEmployeeInputs.municipality,
			address: modalEmployeeInputs.address,
			occupation: modalEmployeeInputs.occupation,
			personalNumber: modalEmployeeInputs.personalNumber,
			attachmentIds: employeesReducer?.pickedFiles?.map((e) => e.attachmentId),
			expirationContract: modalEmployeeInputs?.expirationContract?.length
				? modalEmployeeInputs?.expirationContract
				: null,
			sectors: pickedSectors.map((sector) => ({
				id: sector.value,
				name: sector.label,
			})),
			familyMembers: createFamilyMemberArray(
				modalEmployeeInputs.familyMemberCount as any
			),
			projectBudgetLines: pickedProjectsWithBudgetLines?.map((project) => {
				return {
					projectId: project.id,
					budgetLines: project.budgetLines.map((line) => {
						return {
							budgetLineId: line.id,
							budget: line.budget,
							type: line.type,
							checked: (line.checked ?? false).toString(),
						};
					}),
				};
			}),
			healthInsurance: healthInsurance?.value,
		};

		const loadingDraftButton = draftStatus === 1 ? true : false;
		dispatch(
			submitSaveEmployee(
				tenantId,
				draftStatus ? 3 : 1,
				payload,
				loadingDraftButton
			)
		);
		setIsAddEmployeeOn(!modalEmployeeInputs.id);
	};

	const [showConfirmModal, setShowConfirmModal] = useState<{
		show: boolean;
		callback?: any;
		title?: string;
		subTitle?: string;
	}>({ show: false });

	const [confirmLoading, setConfirmLoading] = useState(false);

	const deleteEmployeeHandler = (tenantId, employeeId, activeTab, callback) => {
		setShowConfirmModal((prev) => {
			return {
				show: true,
				title: "Fshij punëtorin",
				subTitle: "A jeni të sigurtë që doni ta fshini punëtorin?",
				callback: function () {
					setConfirmLoading(true);
					dispatch(deleteEmployee(tenantId, employeeId, activeTab, callback));
				},
			};
		});
	};

	const handleModalEmployeeInputsChange = (event) => {
		setModalEmployeeInputs((prevState) => {
			return { ...prevState, [event.target.name]: event.target.value };
		});

		if (event.target.name === "insurancePercentage") {
			updatedValue.current = event.target.value;
			updatedHourValue.current = event.target.value;
		}

		if (event.target.name === "pricePerHour") {
			updatedHourValue.current = event.target.value;
		}
	};

	const handleKeyDown = (e) => {
		const newFirstBrutto = e.target.value.replace(",", ".");
		setFirstBrutto(newFirstBrutto);
		firstBruttoRef.current = newFirstBrutto;
		if (salaryPaymentType?.value === 1 && tenantHealthInsurance?.prime) {
			setModalEmployeeInputs((prevState) => ({
				...prevState,
				unchangableBrutto: e.target.value.replace(",", "."),
			}));
		}
	};

	useEffect(() => {
		if ("caches" in window) {
			caches.keys().then((names) => {
				names.forEach((name) => {
					caches.delete(name);
				});
			});
		}
	}, []);

	const inputBrutoOnChange = async (event) => {
		const brutoValue = event.target.value.replace(",", ".");
		setModalEmployeeInputs((prevState) => ({
			...prevState,
			bruttoSalary: brutoValue,
		}));

		const calculatedSalary = await calculateSalary({
			gross: +brutoValue,
			contributionPercentage: +modalEmployeeInputs?.contributePercentage,
			employerTier: employerType.value,
			noTaxes: noTaxes,
		});

		if (salaryPaymentType?.value === 3 && !hasPassedCondition) {
			setFirstBrutto(calculatedSalary?.bruto);
			firstBruttoRef.current = calculatedSalary?.bruto;
			setHasPassedCondition(true);
		}

		if (salaryPaymentType?.value == 2 && !hasPassedCondition) {
			setFirstBrutto(calculatedSalary?.bruto);
			firstBruttoRef.current = calculatedSalary?.bruto;
			setHasPassedCondition(true);
		}

		if (salaryPaymentType?.value === 1) {
			if (!isAddEmployeeOn) {
				setModalEmployeeInputs((prevState) => ({
					...prevState,
					unchangableBrutto: brutoValue,
					contribution: calculatedSalary.contribution,
					tax: calculatedSalary.tax,
				}));
			} else {
				if (!tenantHealthInsurance?.prime) {
					setModalEmployeeInputs((prevState) => ({
						...prevState,
						unchangableBrutto: brutoValue,
						contribution: calculatedSalary.contribution,
						tax: calculatedSalary.tax,
					}));
				} else {
					setModalEmployeeInputs((prevState) => ({
						...prevState,
						unchangableBrutto: brutoValue,
						contribution: calculatedSalary.contribution,
						tax: calculatedSalary.tax,
					}));
				}
			}
		} else {
			setModalEmployeeInputs((prevState) => ({
				...prevState,
				unchangableBrutto: firstBrutto,
			}));
		}
		if (salaryPaymentType?.value === SalaryPaymentType.Fixed) {
			setFirstBrutto(brutoValue);
			firstBruttoRef.current = brutoValue;
		}

		if (isTrueCalculatetProject) {
			+calculatedSalary.neto > 0
				? setModalEmployeeInputs((prevState) => ({
					...prevState,
					nettoSalary: calculatedSalary.neto,
					tempBrutto: calculatedSalary.bruto,
					unchangableBrutto: calculatedSalary.bruto,
					tax: calculatedSalary.tax,
					contribution: calculatedSalary.contribution,
				}))
				: setModalEmployeeInputs((prevState) => ({
					...prevState,
					nettoSalary: "",
					tempNetto: "",
					tax: "",
					contribution: "",
				}));
		}
		+calculatedSalary.neto > 0
			? dispatch(setUnchangableNetto(calculatedSalary?.neto))
			: dispatch(setUnchangableNetto(""));
	};

	const inputNetoOnChange = async (event) => {
		const netoValue = event.target.value.replace(",", ".");
		setModalEmployeeInputs((prevState) => ({
			...prevState,
			nettoSalary: netoValue,
		}));

		const calculatedSalary = await calculateSalary({
			net: +netoValue,
			contributionPercentage: +modalEmployeeInputs?.contributePercentage,
			employerTier: employerType.value,
			noTaxes,
		});

		setFirstBrutto(calculatedSalary?.bruto);
		firstBruttoRef.current = calculatedSalary?.bruto;
		if (salaryPaymentType?.value === 3 && !hasPassedCondition) {
			setFirstBrutto(calculatedSalary?.bruto);
			firstBruttoRef.current = calculatedSalary?.bruto;
			setHasPassedCondition(true);
		}

		if (salaryPaymentType?.value == 2 && !hasPassedCondition) {
			setFirstBrutto(calculatedSalary?.bruto);
			firstBruttoRef.current = calculatedSalary?.bruto;
			setHasPassedCondition(true);
		}

		+calculatedSalary.neto > 0
			? setModalEmployeeInputs((prevState) => ({
				...prevState,
				bruttoSalary: calculatedSalary.bruto,
				tempBrutto: calculatedSalary.bruto,
				unchangableBrutto: calculatedSalary.bruto,
				tax: calculatedSalary.tax,
				contribution: calculatedSalary.contribution,
			}))
			: setModalEmployeeInputs((prevState) => ({
				...prevState,
				bruttoSalary: "",
				tempBrutto: "",
				unchangableBrutto: "",
				tax: "",
				contribution: "",
			}));

		+calculatedSalary.neto > 0
			? dispatch(setUnchangableBrutto(calculatedSalary?.bruto))
			: dispatch(setUnchangableBrutto(""));
	};

	useEffect(() => {
		if (!modalEmployeeInputs?.insurancePercentage) {
			setModalEmployeeInputs((prevState) => ({
				...prevState,
				insurancePercentage: null,
			}));
		}
	}, [modalEmployeeInputs?.insurancePercentage]);

	const familyMemberCountChangeHandler = (item, event) => {
		setModalEmployeeInputs((prevState: RootStateOrAny) => ({
			...prevState,
			familyMemberCount: {
				...prevState.familyMemberCount,
				[item.value]: +event.target.value.replace(",", "."),
			},
		}));
	};

	const sectorCountChangeHandler = (item, event) => {
		setModalEmployeeInputs((prevState: RootStateOrAny) => ({
			...prevState,
			sectorCount: {
				...prevState.sectorCount,
				[item.value]: +event.target.value.replace(",", "."),
			},
		}));
	};

	const familyMemberOptionsLeft = useMemo(() => {
		return allFamilyMemberOptions.filter(
			(e) => !familyMember.find((f) => f.value === e.value)
		);
	}, [familyMember]);

	const handleFamilyMemberCountDeleteRow = (event, item) => {
		setFamilyMember((prev) => {
			return prev.filter((e) => e.value !== item.value);
		});

		setModalEmployeeInputs((prevState) => {
			let returnObject = prevState.familyMemberCount;
			delete returnObject[item.value];
			return {
				...prevState,
				familyMemberCount: returnObject,
			};
		});
	};

	const handleProjectPick = (event, item) => {
		if (!isAddEmployeeOn) {
			// setFirstBrutto(null)
			setModalEmployeeInputs((prevState) => ({
				...prevState,
				insurancePercentage: null,
				bruttoSalary: null,
				nettoSalary: null,
				pricePerHour: null,
			}));
		}

		setPickedProjectBudgetLines((prevProjectBudgetLines) => {
			//if it exists just return the previous otherwise add it
			if (prevProjectBudgetLines.find((e) => e.id === item.id)) {
				return prevProjectBudgetLines;
			}
			return [
				...prevProjectBudgetLines,
				{ id: item?.id, budgetLines: item.budgetLines },
			];
		});

		setPickedProjects((prev) => {
			if ((prev ?? []).find((e) => e.id === item.id)) {
				return prev;
			}
			return _.union(prev, [item]);
		});

		setProjectValueType((prev) => ({ ...prev, [item.value]: 1 }));

		setModalEmployeeInputs((prevState: RootStateOrAny) => ({
			...prevState,
			projectsValues: {
				...prevState.projectsValues,
				[item.id]: "",
			},
			projectsPercentages: {
				...prevState.projectsPercentages,
				[item.id]: "",
			},
		}));
	};

	const handleSectorPick = (event, item) => {
		setPickedSectors((prev) => _.union(prev, [item]));
		setPickedSectorsIds((prev) => _.union(prev, [item?.value]));
	};

	const handleProjectDelete = (event, project) => {
		setProjectToDelete(project);
		setIsDeleteProjectOn(true);
	};

	const handleDeleteUserProject = () => {
		let project = projectToDelete;
		setPickedProjects((prev) => {
			return prev.filter((e) => e.id !== project?.id);
		});

		setPickedProjectsWithBudgetLines((prev) => {
			return prev.filter((e) => e.id !== project.id);
		});

		setPickedProjectBudgetLines((prev) => {
			return prev.filter((e) => e.id !== project.id);
		});

		setProjectValueType((prev) => {
			return {
				...prev,
				[project.value]: 1,
			};
		});

		setModalEmployeeInputs((prevState) => ({
			...prevState,
			projectsPercentages: {
				...prevState.projectsPercentages,
				[project.value]: "",
			},
			projectsValues: {
				...prevState.projectsValues,
				[project.value]: "",
			},
		}));

		setModalEmployeeInputs((prevState) => ({
			...prevState,
			unchangableBrutto: null,
			bruttoSalary: null,
			nettoSalary: null,
		}));

		dispatch(
			deleteEmployeeProject(modalEmployeeSelected?.id, pickedProjects[0]?.id)
		);
		setIsDeleteProjectOn(false);
	};

	const handleSectorDelete = (sector, sectorIndex) => {
		let tempArray = [];

		pickedSectors.forEach((sectorCopy, index) => {
			if (index !== sectorIndex) {
				tempArray.push(sectorCopy);
			}
		});

		setPickedSectors(tempArray);
	};

	const handleProjectValueTypeChange = (item, event, project) => {
		setProjectValueType((prev) => {
			return {
				...prev,
				[project.id]: item.value,
			};
		});

		setModalEmployeeInputs((prevState: RootStateOrAny) => ({
			...prevState,
			projectsPercentages: {
				...prevState.projectsPercentages,
				[project.id]: "",
			},
			projectsValues: {
				...prevState.projectsValues,
				[project.id]: "",
			},
		}));
		let input = document.getElementById(`projectValueSetInput_${project.id}`);
		input?.focus();
	};

	useEffect(() => {
		subWrapperRef?.current &&
			(subWrapperRef.current.style.minHeight =
				subWrapperRef.current.offsetHeight + "px");
		const loadInitialContribute = async () => {
			try {
				const response = await getContribute();
				if (response.data?.data?.length) {
					setModalEmployeeInputs((prev) => ({
						...prev,
						contributePercentage: number_format(
							response?.data?.data?.[0]?.employerContribute,
							2,
							".",
							""
						),
					}));
				}
			} catch (error) {
				setModalEmployeeInputs((prev) => ({
					...prev,
					contributePercentage: "5.00",
				}));
			}
		};

		loadInitialContribute();

		if (props.showEmployeeModal) {
			setEditMode((prev) => false);
		} else {
			setFamilyMember([]);
			dispatch(clearEmployeeModalData());
			closeModal();
		}
		!isEmployeeSelected && setEditMode((prev) => true);
	}, [props.showEmployeeModal]);

	useEffect(() => {
		setModalEmployeeInputs((prevState) => ({
			...prevState,
			employerType: employerType?.value || "",
			salaryPaymentType: salaryPaymentType?.value || "",
			pricePerHour:
				salaryPaymentType?.value === 1 ? 0 : modalEmployeeInputs?.pricePerHour,
			projects: [],
			sectorIds: [],
			bruttoSalary:
				// salaryPaymentType?.value === 1 ? prevState?.bruttoSalary : 0,
				prevState?.bruttoSalary,
			// nettoSalary: salaryPaymentType?.value === 1 ? prevState?.nettoSalary : 0,
			nettoSalary: prevState?.nettoSalary,
			contribution:
				salaryPaymentType?.value === 1 ? modalEmployeeInputs?.contribution : 0,
			tax: salaryPaymentType?.value === 1 ? modalEmployeeInputs?.tax : 0,
			// unchangableBrutto: modalEmployeeInputs?.unchangableBrutto
		}));

		setDisplaySalaryValues((prev) =>
			salaryPaymentType?.value === 1 || salaryPaymentType?.value === 3
				? true
				: false
		);
	}, [employerType, salaryPaymentType]);

	useEffect(() => {
		setModalEmployeeInputs((prevState) => ({
			...prevState,
			healthInsurance: healthInsurance?.value || "",
		}));
	}, [healthInsurance]);

	useEffect(() => {
		setModalEmployeeInputs((prevState) => ({
			...prevState,
			employmentType: employmentType?.value || "",
		}));
	}, [employmentType, activeInactive]);

	useEffect(() => {
		setModalEmployeeInputs((prevState) => ({
			...prevState,
			expirationContract:
				expirationContractType?.value === 1
					? ""
					: modalEmployeeInputs?.expirationContract,
		}));
	}, [expirationContractType, modalEmployeeInputs?.expirationContract]);

	useEffect(() => {
		const recalculateSalaryAfterEmployerTypeChange = async () => {
			const calculatedSalary = await calculateSalary({
				gross: modalEmployeeInputs?.bruttoSalary,
				contributionPercentage: +modalEmployeeInputs?.contributePercentage,
				employerTier: employerType.value,
				noTaxes,
			});
			modalEmployeeInputs?.bruttoSalary &&
				setModalEmployeeInputs((prevState) => ({
					...prevState,
					nettoSalary: calculatedSalary.neto,
					contribution: calculatedSalary.contribution,
					tax: calculatedSalary.tax,
				}));
		};

		recalculateSalaryAfterEmployerTypeChange();
	}, [employerType]);

	useEffect(() => {
		setLoading(true);
		setModalEmployeeInputs(null);
		let familyMemberSet = {};
		const memberTypes = {
			adultsChildren: FamilyMemberType.AdultsChildren,
			parent: FamilyMemberType.Parents,
			children: FamilyMemberType.Children,
			partners: FamilyMemberType.Partner,
		};

		for (const [key, value] of Object.entries(memberTypes)) {
			const count = modalEmployeeSelected.familyMembers?.[key];
			if (count > 0) {
				familyMemberSet[value] = count;
			}
		}

		if (modalEmployeeSelected?.bruttoSalary) {
			setIsProjectValid(true);
		}

		setFamilyMember(
			allFamilyMemberOptions.filter((memberOption) => {
				return familyMemberSet[memberOption.value] > 0;
			})
		);

		setModalEmployeeInputs((prevState: RootStateOrAny) => ({
			...prevState,
			...modalEmployeeSelected,
			firstName:
				modalEmployeeSelected?.firstName && modalEmployeeSelected?.lastName
					? modalEmployeeSelected?.firstName +
					" " +
					modalEmployeeSelected?.lastName
					: modalEmployeeSelected?.firstName,
			familyMemberCount: familyMemberSet,
		}));
		modalEmployeeSelected.gender &&
			setGender(genderOptions[+modalEmployeeSelected.gender - 1]);
		modalEmployeeSelected.employerType &&
			setEmployerType(
				employerTypeOptions[modalEmployeeSelected.employerType - 1]
			);
		if (modalEmployeeSelected?.healthInsurance) {
			setHealthInsurance(
				yesNoOptions[modalEmployeeSelected.healthInsurance - 1]
			);
		} else {
			setHealthInsurance(yesNoOptions[2]);
		}
		modalEmployeeSelected.salaryPaymentType &&
			setSalaryPaymentType(
				salaryPaymentTypeOptions[modalEmployeeSelected.salaryPaymentType - 1]
			);

		if (modalEmployeeSelected?.expirationContract) {
			setExpirationContractType(contractOptions[1]);
		}

		modalEmployeeSelected?.employmentType &&
			setEmploymentType(
				(prev) =>
					employmentTypeOptions[modalEmployeeSelected.employmentType - 1]
			);
		modalEmployeeSelected?.status &&
			setActiveInactive(
				(prev) => activeInactiveOptions[modalEmployeeSelected.status - 1]
			);
		let temporaryPickedProjectsWithBudgetLines = [];

		modalEmployeeSelected?.project?.map((project) => {
			let tempObject = {
				name: project.name,
				paymentType: modalEmployeeSelected?.salaryPaymentType,
				budgetLines: modalEmployeeSelected?.budgetLines[`${project?.id}`],
			};
			temporaryPickedProjectsWithBudgetLines.push(tempObject);
		});

		setPickedProjects(modalEmployeeSelected?.project || []);
		setPickedProjectsWithBudgetLines(temporaryPickedProjectsWithBudgetLines);
		setNoTaxes(modalEmployeeSelected?.noTaxes);

		updatedValue.current =
			modalEmployeeSelected?.insurancePercentage?.toString();

		setEmployerType((prev) => {
			if ((modalEmployeeSelected as any)?.employerTier == 2) {
				return employerTypeOptions[1];
			}
			return employerTypeOptions[0];
		});

		setFirstBrutto((modalEmployeeSelected as any)?.unchangableBrutto ?? 0);
		firstBruttoRef.current =
			(modalEmployeeSelected as any)?.unchangableBrutto ?? 0;

		setTimeout(() => {
			setLoading(false);
		}, 500);
	}, [modalEmployeeSelected]);

	useEffect(() => {
		if (loading) return;
		onClickSalary();
	}, [loading]);

	function addBudgetLine(budgetLine, projectId, projectIndex) {
		let temporaryArray = [...pickedProjectBudgetLines];
		temporaryArray[projectIndex].budgetLine = temporaryArray[
			projectIndex
		].budgetLines.filter(
			(loopBudgetLine) => loopBudgetLine.id !== budgetLine.id
		);

		let tempArray = [...pickedProjectsWithBudgetLines];
		if (tempArray[projectIndex]) {
			tempArray[projectIndex] = {
				id: projectId,
				budgetLines: [
					...tempArray[projectIndex]?.budgetLines,
					{
						id: budgetLine?.id,
						name: budgetLine?.name,
						budget: 0,
						type: tempArray[projectIndex]?.budgetLines[0]?.type,
						primaryBudget: budgetLine.budget,
					},
				],
			};
		} else {
			tempArray[projectIndex] = {
				id: projectId,
				budgetLines: [
					{
						id: budgetLine?.id,
						name: budgetLine?.name,
						budget: 0,
						type: 0,
						primaryBudget: budgetLine.budget,
					},
				],
			};
		}
		setPickedProjectsWithBudgetLines(tempArray);
		toggleSelectableBudgetLines(projectIndex, null);
	}

	function removeBudgetLine(budgetLine, projectId, projectIndex) {
		let tempArray = [...pickedProjectsWithBudgetLines];
		if (tempArray[projectIndex]) {
			tempArray[projectIndex] = {
				id: projectId,
				budgetLines: [
					...tempArray[projectIndex]?.budgetLines?.filter(
						(tempBudgetLine) => tempBudgetLine?.id !== budgetLine?.id
					),
				],
			};
		} else {
			tempArray[projectIndex] = {
				id: projectId,
				budgetLines: [],
			};
		}
		let tempPickedProjectBudgetLines = [...pickedProjectBudgetLines];
		tempPickedProjectBudgetLines[projectIndex] = {
			...tempPickedProjectBudgetLines[projectIndex],
			budgetLines: [
				...tempPickedProjectBudgetLines[projectIndex].budgetLine,
				budgetLine,
			],
		};

		setPickedProjectBudgetLines(tempPickedProjectBudgetLines);
		setPickedProjectsWithBudgetLines(tempArray);
	}

	useEffect(() => {
		if (salaryPaymentType?.value === 3) {
			if (modalEmployeeInputs?.bruttoSalary && editMode) {
				let eventReplicate = {
					target: {
						value: `${modalEmployeeInputs?.bruttoSalary}`,
					},
				};
				// inputBrutoOnChange(eventReplicate);
			}
		}
	}, [modalEmployeeInputs?.bruttoSalary]);

	useEffect(() => {
		let tempArray: any = [];
		modalEmployeeInputs?.sector &&
			modalEmployeeInputs?.sector?.length !== 0 &&
			modalEmployeeInputs?.sector?.forEach((sector) => {
				tempArray.push({ value: sector?.id, label: sector?.name });
			});

		setPickedSectors(tempArray);
	}, [modalEmployeeInputs?.sector]);

	function setBudgetLineValues(value, budgetLineIndex, projectIndex) {
		setIsProjectValid(false);
		let tempProjectsArray = [...pickedProjectsWithBudgetLines];
		let tempProjectObj = {
			...tempProjectsArray[projectIndex],
		};

		let tempProjectObjBudgetLinesArray = [...tempProjectObj.budgetLines];

		tempProjectObjBudgetLinesArray[budgetLineIndex] = {
			...tempProjectObjBudgetLinesArray[budgetLineIndex],
			budget: parseInt(value),
			unchangableBudget: parseInt(value),
		};

		tempProjectsArray[projectIndex] = {
			...tempProjectObj,
			budgetLines: [...tempProjectObjBudgetLinesArray],
		};
		let calculatedBudget = 0;

		tempProjectsArray.forEach((project) => {
			project?.budgetLines?.forEach((budgetLine) => {
				calculatedBudget += budgetLine?.budget;
			});
		});

		let tempModalEmployeeInputs = {
			...modalEmployeeInputs,
			bruttoSalary: calculatedBudget,
		};

		setPickedProjectsWithBudgetLines(tempProjectsArray);
	}

	function setBudgetLineTypes(value, budgetLineIndex, projectIndex) {
		let tempProjectsArray = [...pickedProjectsWithBudgetLines];
		let tempProjectObj = {
			...tempProjectsArray[projectIndex],
		};

		let tempProjectObjBudgetLinesArray = [...tempProjectObj.budgetLines];

		let budgetLinesArray = [];

		tempProjectObjBudgetLinesArray.forEach((element) => {
			budgetLinesArray.push({
				...element,
				type: value,
			});
		});
		tempProjectObjBudgetLinesArray = budgetLinesArray;

		tempProjectsArray[projectIndex] = {
			...tempProjectObj,
			budgetLines: [...tempProjectObjBudgetLinesArray],
		};

		setPickedProjectsWithBudgetLines(tempProjectsArray);
	}

	function setCheckboxValues(
		checkedValue,
		budgetLineIndex,
		projectIndex,
		budgedLine
	) {
		let tempProjectsArray = [...pickedProjectsWithBudgetLines];
		let tempProjectObj = {
			...tempProjectsArray[projectIndex],
		};

		let tempProjectObjBudgetLinesArray = [...tempProjectObj.budgetLines];

		tempProjectObjBudgetLinesArray[budgetLineIndex] = {
			...tempProjectObjBudgetLinesArray[budgetLineIndex],
			checked: checkedValue === true ? "true" : "false",
		};

		let calculatedBudget = 0;

		tempProjectsArray[projectIndex] = {
			...tempProjectObj,
			budgetLines: [...tempProjectObjBudgetLinesArray],
		};

		// let tempModalEmployeeInputs = {
		//   ...modalEmployeeInputs,
		//   bruttoSalary: calculatedBudget,
		// };
		//
		// setModalEmployeeInputs(tempModalEmployeeInputs);
		setPickedProjectsWithBudgetLines(tempProjectsArray);
	}

	function setCheckboxValuesForHours(
		checkedValue,
		budgetLineIndex,
		projectIndex,
		budgedLine
	) {
		let tempProjectsArray = [...pickedProjectsWithBudgetLines];
		let tempProjectObj = {
			...tempProjectsArray[projectIndex],
		};

		let tempProjectObjBudgetLinesArray = [...tempProjectObj.budgetLines];

		tempProjectObjBudgetLinesArray[budgetLineIndex] = {
			...tempProjectObjBudgetLinesArray[budgetLineIndex],
			checked: checkedValue == true ? "true" : "false",
		};

		let calculatedBudget = 0;

		tempProjectsArray[projectIndex] = {
			...tempProjectObj,
			budgetLines: [...tempProjectObjBudgetLinesArray],
		};

		let tempModalEmployeeInputs = {
			...modalEmployeeInputs,
			bruttoSalary: calculatedBudget,
		};

		setTimeout(() => {
			calculateExpensesByHours(tempProjectObjBudgetLinesArray[budgetLineIndex]);
		}, 1000);

		setModalEmployeeInputs(tempModalEmployeeInputs);
		setPickedProjectsWithBudgetLines(tempProjectsArray);
	}

	function calculateExpensesByHours(budgetLine) {
		if (budgetLine?.checked == "true") {
			let budgetCalculatedByHours =
				budgetLine?.budget * Number(updatedHourValue?.current);
			let percentageOfExpenses =
				budgetCalculatedByHours *
				(+modalEmployeeInputs?.contributePercentage / 100);
			let brutoWithoutExpenses = budgetCalculatedByHours - percentageOfExpenses;
			setAllPercentages(brutoWithoutExpenses);

			let allPercentagesToBeChanged = allPercentages;
			allPercentagesToBeChanged += brutoWithoutExpenses;
			setAllPercentages(allPercentagesToBeChanged);

			let tempObject = {
				...modalEmployeeInputs,
			};
		}
	}

	const handleFamilyMemberOnClick = (item) => {
		setFamilyMember((prev) => {
			return [...prev, item];
		});

		setModalEmployeeInputs((prevState: RootStateOrAny) => ({
			...prevState,
			familyMemberCount: {
				...prevState.familyMemberCount,
				[item.value]: 1,
			},
		}));
	};

	function checkPickedProjects() {
		return allProjects?.filter(
			(e) => !pickedProjects.find((a) => e.id == a.id)
		);
	}

	const getFamilyMembersHealthInsuranceSetup =
		(): FamilyMembersHealthInsuranceSalaryCalculationSetup => {
			let partners = 0;
			let children = 0;
			let parent = 0;
			let adultsChildren = 0;
			Object.entries(
				modalEmployeeInputs?.familyMemberCount ?? ({} as any)
			).forEach(([key, value]: [key: any, value: any]) => {
				let familyMemberType = Number(key) as unknown as FamilyMemberType;
				switch (familyMemberType) {
					case FamilyMemberType.Partner:
						partners = value ?? 0;
						break;
					case FamilyMemberType.Children:
						children = value ?? 0;
						break;
					case FamilyMemberType.AdultsChildren:
						adultsChildren = value ?? 0;
						break;
					case FamilyMemberType.Parents:
						parent = value ?? 0;
						break;
				}
			});

			return {
				parent,
				children,
				adultsChildren,
				partners,
			};
		};

	const calculateFinalSalary = async () => {
		const familyMembers = getFamilyMembersHealthInsuranceSetup();
		const calculatedSalary = await calculateSalary({
			gross: +firstBruttoRef.current,
			contributionPercentage: +modalEmployeeInputs?.contributePercentage,
			employerTier: employerType.value,
			noTaxes,
			userId: tenantId,
			healthInsurance: {
				hasHealthInsurance: healthInsurance?.value === 1,
				healthInsurancePercentage:
					modalEmployeeInputs?.insurancePercentage ?? 0,
				familyMembers,
			},
		});

		setModalEmployeeInputs((prevState) => ({
			...prevState,
			bruttoSalary: calculatedSalary?.bruto,
			nettoSalary: calculatedSalary?.neto,
			tax: calculatedSalary?.tax,
			contribution: calculatedSalary?.contribution,
			insurancePercentage: calculatedSalary?.healthInsurancePercentage,
			healthInsuranceValue: calculatedSalary?.healthInsuranceValue,
		}));
	};

	const onClickProjects = () => {
		if (
			!modalEmployeeInputs?.bruttoSalary ||
			pickedProjectsWithBudgetLines?.length > 0
		) {
			if (salaryPaymentType?.value === SalaryPaymentType.Project) {
				let bruttoCalculated = 0;
				let budgetLineWithoutContribute = 0;

				pickedProjectsWithBudgetLines.forEach((element) => {
					element?.budgetLines.forEach((budgetLine) => {
						let project = allProjects.find(
							(x) => x.budgetLines.find((y) => y.id == budgetLine?.id) != null
						);

						let primaryBudget = budgetLine?.primaryBudget;
						if (project) {
							const budgetLineFromAllProjects = project.budgetLines?.find(
								(x) => x.id == budgetLine?.id
							);
							if (budgetLineFromAllProjects) {
								primaryBudget = Number(budgetLineFromAllProjects.budget);
							}
						}

						if (budgetLine?.type === 0) {
							// EURO
							if (
								budgetLine?.checked === true ||
								budgetLine?.checked === "true"
							) {
								budgetLineWithoutContribute = +budgetLine?.budget;
							} else {
								budgetLineWithoutContribute = +budgetLine?.budget;
							}

							if (
								budgetLine?.checked === true ||
								budgetLine?.checked === "true"
							) {
								bruttoCalculated +=
									+budgetLineWithoutContribute /
									(1 + +modalEmployeeInputs?.contributePercentage / 100);
							} else {
								bruttoCalculated += +budgetLineWithoutContribute;
							}
						}

						if (budgetLine?.type === 1) {
							console.log("jam ketu ne 2");
							// PERCENTAGE\=

							let percentage = budgetLine?.budget / 100;
							if (percentage > 1) {
								toast.warning(
									"Nuk mund te kaloni vleren 100% te linjes buxhetore"
								);
								setFirstBrutto(0);
								firstBruttoRef.current = 0;
								return;
							}

							let percentageOfPrimaryBudget = primaryBudget * percentage;
							let calculatedSalaryByPercentage = percentageOfPrimaryBudget;

							if (
								budgetLine?.checked === true ||
								budgetLine?.checked === "true"
							) {
								+modalEmployeeInputs?.contributePercentage;
								let budgetLineContribute =
									calculatedSalaryByPercentage *
									(+modalEmployeeInputs?.contributePercentage / 100);
								budgetLineWithoutContribute =
									calculatedSalaryByPercentage - budgetLineContribute;

								calculatedSalaryByPercentage = budgetLineWithoutContribute;
							}
							bruttoCalculated += calculatedSalaryByPercentage;
						}
					});
				});

				setFirstBrutto(bruttoCalculated);
				firstBruttoRef.current = bruttoCalculated;

				if (
					bruttoCalculated != null &&
					bruttoCalculated > 0 &&
					updatedValue.current == null
				) {
					updatedValue.current = "0";
				}
				setModalEmployeeInputs({
					...modalEmployeeInputs,
					bruttoSalary: formatNumber_(bruttoCalculated || 0, 2),
					unchangableBrutto: firstBruttoRef.current,
				});
			} else if (salaryPaymentType?.value === SalaryPaymentType.Hourly) {
				let bruttoCalculated = 0;
				let budgetLineWithoutContribute = 0;

				pickedProjectsWithBudgetLines?.forEach((element) => {
					element?.budgetLines.forEach((budgetLine) => {
						let budgetCalculatedByHours =
							budgetLine?.budget * Number(updatedHourValue?.current);

						if (budgetLine?.checked == "true") {
							let percentageOfBudget =
								budgetCalculatedByHours *
								(+modalEmployeeInputs?.contributePercentage / 100);
							let brutoWithoutExpenses =
								budgetCalculatedByHours - percentageOfBudget;
							budgetCalculatedByHours = brutoWithoutExpenses;
						} else {
							bruttoCalculated +=
								budgetCalculatedByHours *
								(1 + +modalEmployeeInputs?.contributePercentage / 100);
						}
					});
				});

				let tempObject = {
					...modalEmployeeInputs,
				};

				setModalEmployeeInputs({
					...tempObject,
					bruttoSalary: bruttoCalculated,
				});
			}
		}
	};

	const onClickSalary = () => {
		calculationNeeded.current = false;
		setIsProjectValid(true);
		onClickProjects();

		if (updatedValue.current && salaryPaymentType?.value != SalaryPaymentType.Hourly) {
			calculateFinalSalary();
		}
	};

	const handleHealthInsuranceChange = async (value) => {
		//if no health insurance
		if (value?.value != 1) {
			setFamilyMember([]);
			setModalEmployeeInputs((prev) => ({
				...prev,
				insurancePercentage: null,
				familyMembers: {
					adultsChildren: 0,
					children: 0,
					parent: 0,
					partners: 0,
				},
				familyMemberCount: {},
			}));
		}
		setHealthInsurance(value);
		updatedValue.current = "0";
		setIsTrueCalculatetProject(true);
		calculationNeeded.current = true;

		if (value?.value === 2) {
			if (isAddEmployeeOn) {
				if (Number(updatedValue.current) === 0) {
					let calculatedSalary = await calculateSalary({
						gross: +firstBrutto,
						contributionPercentage: +modalEmployeeInputs?.contributePercentage,
						employerTier: employerType.value,
						noTaxes,
					});
					setModalEmployeeInputs((prevState) => ({
						...prevState,
						bruttoSalary: firstBrutto,
						nettoSalary: calculatedSalary?.neto,
					}));
				} else {
					setModalEmployeeInputs((prevState) => ({
						...prevState,
						bruttoSalary: firstBrutto,
					}));
				}
			} else {
				let calculatedSalary = await calculateSalary({
					gross: +modalEmployeeInputs?.unchangableBrutto,
					contributionPercentage: +modalEmployeeInputs?.contributePercentage,
					employerTier: employerType.value,
					noTaxes,
				});
				setModalEmployeeInputs((prevState) => ({
					...prevState,
					bruttoSalary: modalEmployeeInputs?.unchangableBrutto,
					nettoSalary: calculatedSalary?.neto,
				}));
			}
		}
	};

	const onChangeSalaryPaymentType = (value) => {
		setSalaryPaymentType((prev) => value);
		setFirstBrutto(null);
		firstBruttoRef.current = null;
		setModalEmployeeInputs((prevState) => ({
			...prevState,
			insurancePercentage: null,
			bruttoSalary: null,
			nettoSalary: null,
		}));

		setPickedProjects([]);
		setPickedProjectsWithBudgetLines([]);
	};

	const onContributionsChange = async (percentage = 0, format = false) => {
		if (percentage > 100) {
			toast.warning(
				"Nuk mund te kaloni vleren 100% te kontributit te punetorit/punëdhënësit"
			);
			return;
		} else if (percentage < 0) {
			toast.warning(
				"Nuk mund te zgjedhni vlere me te vogel se 0% te kontributit te punetorit/punëdhënësit"
			);
			return;
		}

		const calculatedSalaryBruto = await calculateSalary({
			gross: +modalEmployeeInputs?.bruttoSalary,
			contributionPercentage: percentage,
			employerTier: employerType.value,
			noTaxes,
		});

		setModalEmployeeInputs((prevState) => ({
			...prevState,
			contributePercentage: format ? number_format(percentage, 2) : percentage,
			bruttoSalary: calculatedSalaryBruto?.bruto,
			nettoSalary: calculatedSalaryBruto?.neto,
			tax: calculatedSalaryBruto?.tax,
			contribution: calculatedSalaryBruto?.contribution,
		}));
	};
	return (
		<>
			<Modal
				open={props.showEmployeeModal}
				onClose={onCloseModal}
				classNames={{
					root: styles.modalRoot,
					overlay: styles.modalOverlay,
					modalContainer: styles.modalContainer,
					modal: styles.responsiveModal,
				}}
				showCloseIcon={false}
				closeOnOverlayClick={false}
			>
				<div className={styles.body}>
					<div className={styles.subWrapper} ref={subWrapperRef}>
						{isEmployeeSelected ? (
							<div className={styles.buttonsContainer}>
								{!editMode ? (
									<div
										className={`${styles.icon}`}
										title="Ndrysho"
										onClick={() => {
											setEditMode((prev) => true);
										}}
									>
										<IconPencil />
									</div>
								) : (
									""
								)}
								<div
									className={`${styles.icon} ${styles.trash}`}
									title="Fshij"
									onClick={() => {
										deleteEmployeeHandler(
											tenantId,
											modalEmployeeSelected?.id || 0,
											activeTab,
											() => {
												setShowConfirmModal({ show: false });
												setConfirmLoading(false);
											}
										);
									}}
								>
									{deleteButtonLoading ? (
										<Loader style={{ width: "20px", height: "20px" }} />
									) : (
										<FiTrash2 />
									)}
								</div>
							</div>
						) : (
							""
						)}

						{isDeleteProjectOn ? (
							<ModalCustom
								show={isDeleteProjectOn}
								contentClass={styles.contentClass}
								footerClass={styles.footerClass}
								onClose={() => {
									setIsDeleteProjectOn((prev) => false);
								}}
								hideFooterButton={false}
								submitText={"Po"}
								onSubmit={() => handleDeleteUserProject()}
							>
								<div className={styles.deleteCompanyMessage}>
									Jeni të sigurtë që doni të fshini këtë projekt?
								</div>
							</ModalCustom>
						) : null}

						<Tabs
							tabPillsClass={`${styles.tabPills} ${!isEmployeeSelected ? styles.pt2 : ""
								}`}
							tabRoot={"employeeModal"}
						>
							<Tab title="Informata Personale">
								<div
									className={`${styles.personalInfoContent} ${styles.modalPage}`}
								>
									<EmployeeNameAndImage
										handleModalEmployeeInputsChange={
											handleModalEmployeeInputsChange
										}
										modalEmployeeInputs={modalEmployeeInputs}
										setModalEmployeeInputs={setModalEmployeeInputs}
										editMode={editMode}
										modalEmployeeSelected={modalEmployeeSelected}
									/>
									<div className={`${styles.row} ${styles.contentRow}`}>
										<div className={styles.row}>
											<div className={styles.rowTitle}>Numri personal:</div>
											<InputCustom
												onChangeHandler={(event) => {
													handleModalEmployeeInputsChange(event);
												}}
												onWheel={(event) => event.currentTarget.blur()}
												onKeyDown={(event) => {
													if (
														validNumber.test(event?.key) &&
														event?.target?.value?.length >= 10
													) {
														toast.warn(
															"Numri personal duhet të jetë 10 karaktere i gjatë!",
															{
																toastId: "personalNumberToast",
															}
														);
														event.preventDefault();
													}
												}}
												onBlur={(event) => {
													if (event?.target?.value?.length < 10) {
														event?.target?.parentNode?.classList?.add(
															"inputInvalid"
														);
														if (
															event.target.parentNode.querySelector(
																".validationText"
															)
														)
															event.target.parentNode.querySelector(
																".validationText"
															).textContent =
																"Numri personal duhet të jetë 10 karaktere i gjatë!";
													} else {
														event?.target?.parentNode?.classList?.remove(
															"inputInvalid"
														);
													}
												}}
												className={`${styles.rowInput} ${styles.noArrowsNumberInput}`}
												name="personalNumber"
												value={modalEmployeeInputs?.personalNumber || ""}
												readOnly={!editMode}
												type="number"
												positiveInteger={true}
												isRequired={editMode}
												min="0"
												validationText={
													"Numri personal nuk duhet të jetë i zbrazët"
												}
											/>
										</div>
									</div>
									<div className={`${styles.row} ${styles.contentRow}`}>
										<div className={styles.row}>
											<div className={styles.rowTitle}>Datëlindja:</div>
											<InputCustom
												onChangeHandler={handleModalEmployeeInputsChange}
												className={styles.rowInput}
												name="birthDate"
												value={modalEmployeeInputs?.birthDate || ""}
												readOnly={!editMode}
												type="date"
												noFuture={true}
											// min
											/>
										</div>
									</div>
									<div className={`${styles.row} ${styles.contentRow}`}>
										<div className={styles.row}>
											<div className={styles.rowTitle}>Gjinia:</div>
											<CustomSelect
												options={genderOptions}
												className={`${styles.customSelect} ${styles.rowInput} ${!editMode && styles.pointerEventsNone
													}`}
												onChangeHandler={(value) => setGender((prev) => value)}
												selected={gender}
												placeholder="Zgjedh opsionin"
												name="gender"
												components={{ DropdownIndicator }}
												controlStyle={{ borderRadius: "4px" }}
												{...customSelectStyles}
											/>
										</div>
									</div>
									<div className={`${styles.row} ${styles.contentRow}`}>
										<div className={styles.row}>
											<div className={styles.rowTitle}>Telefoni:</div>
											<InputCustom
												onChangeHandler={handleModalEmployeeInputsChange}
												className={`${styles.rowInput} ${styles.noArrowsNumberInput}`}
												name="phone"
												value={modalEmployeeInputs?.phone || ""}
												readOnly={!editMode}
												isRequired={editMode}
												customType="phone"
												type="number"
											// validationText={"Shkruani numër telefoni valid"}
											/>
										</div>
									</div>
									<div className={`${styles.row} ${styles.contentRow}`}>
										<div className={styles.row}>
											<div className={styles.rowTitle}>Kualifikimi:</div>
											<InputCustom
												onChangeHandler={handleModalEmployeeInputsChange}
												className={styles.rowInput}
												name="qualification"
												value={modalEmployeeInputs?.qualification || ""}
												readOnly={!editMode}
											/>
										</div>
									</div>
								</div>
							</Tab>

							<Tab title="Adresa">
								<div className={`${styles.addressContent} ${styles.modalPage}`}>
									<EmployeeNameAndImage
										handleModalEmployeeInputsChange={
											handleModalEmployeeInputsChange
										}
										modalEmployeeInputs={modalEmployeeInputs}
										setModalEmployeeInputs={setModalEmployeeInputs}
										editMode={editMode}
										modalEmployeeSelected={modalEmployeeSelected}
									/>
									<div className={`${styles.row} ${styles.contentRow}`}>
										<div className={styles.row}>
											<div className={styles.rowTitle}>Adresa:</div>
											<InputCustom
												onChangeHandler={handleModalEmployeeInputsChange}
												className={styles.rowInput}
												name="address"
												value={modalEmployeeInputs?.address || ""}
												readOnly={!editMode}
											/>
										</div>
									</div>
									<div className={`${styles.row} ${styles.contentRow}`}>
										<div className={styles.row}>
											<div className={styles.rowTitle}>Komuna:</div>
											<InputCustom
												onChangeHandler={handleModalEmployeeInputsChange}
												className={styles.rowInput}
												name="municipality"
												value={modalEmployeeInputs?.municipality || ""}
												readOnly={!editMode}
											/>
										</div>
									</div>
									<div className={`${styles.row} ${styles.contentRow}`}>
										<div className={styles.row}>
											<div className={styles.rowTitle}>Email:</div>
											<InputCustom
												onChangeHandler={handleModalEmployeeInputsChange}
												className={styles.rowInput}
												name="email"
												value={modalEmployeeInputs?.email || ""}
												readOnly={!editMode}
												isRequired={editMode}
												type="email"
												validationText={"Shkruani email valid"}
											/>
										</div>
									</div>
								</div>
							</Tab>

							<Tab title="Informata për pagë">
								<div
									className={`${styles.salaryInfoContent} ${styles.modalPage}`}
								>
									<EmployeeNameAndImage
										handleModalEmployeeInputsChange={
											handleModalEmployeeInputsChange
										}
										modalEmployeeInputs={modalEmployeeInputs}
										setModalEmployeeInputs={setModalEmployeeInputs}
										editMode={editMode}
										modalEmployeeSelected={modalEmployeeSelected}
									/>
									<div className={`${styles.row} ${styles.contentRow}`}>
										<div className={styles.colHalf}>
											<div className={styles.row}>
												<div className={styles.rowTitle}>Punëdhënësi:</div>
												<CustomSelect
													options={employerTypeOptions}
													className={`${styles.customSelect} ${styles.rowInput
														} ${!editMode && styles.pointerEventsNone} asterix`}
													onChangeHandler={(value) =>
														setEmployerType((prev) => value)
													}
													selected={employerType}
													placeholder="Zgjedh opsionin"
													name="employerType"
													components={{ DropdownIndicator }}
													controlStyle={{ borderRadius: "4px" }}
													{...customSelectStyles}
												/>
											</div>
											<div className={styles.row}>
												<div className={styles.rowTitle}>Banka:</div>
												<InputCustom
													onChangeHandler={handleModalEmployeeInputsChange}
													className={styles.rowInput}
													name="bank"
													value={modalEmployeeInputs?.bank || ""}
													readOnly={!editMode}
													type="text"
												/>
											</div>
											<div className={styles.row}>
												<div className={styles.rowTitle}>
													Numri i llogarisë:
												</div>
												<InputCustom
													onChangeHandler={handleModalEmployeeInputsChange}
													onKeyDown={(event) => {
														if (
															event.key === "ArrowUp" ||
															event.key === "ArrowDown"
														) {
															event.preventDefault();
															return false;
														}
														if (
															validNumber.test(event?.key) &&
															event?.target?.value?.length >= 16
														) {
															toast.warn(
																"Numri i llogarisë duhet të jetë 16 karaktere i gjatë!",
																{
																	toastId: "accountNumberToast",
																}
															);
															event.preventDefault();
															return false;
														}
													}}
													onBlur={(event) => {
														if (
															event?.target?.value?.length > 0 &&
															event?.target?.value?.length < 16
														) {
															event?.target?.parentNode?.classList?.add(
																"inputInvalid"
															);
															if (
																event.target.parentNode.querySelector(
																	".validationText"
																)
															)
																event.target.parentNode.querySelector(
																	".validationText"
																).textContent =
																	"Numri i llogarisë duhet të jetë 16 karaktere i gjatë!";
														} else {
															event?.target?.parentNode?.classList?.remove(
																"inputInvalid"
															);
														}
													}}
													onWheel={(event) => event.currentTarget.blur()}
													min="0"
													positiveInteger={true}
													className={`${styles.rowInput} ${styles.noArrowsNumberInput} ${styles.noAsterix}`}
													name="bankAccountNumber"
													value={modalEmployeeInputs?.bankAccountNumber || ""}
													readOnly={!editMode}
													type="number"
													// isRequired={editMode}
													validationText={
														"Numri i llogarisë nuk duhet të jetë i zbrazët"
													}
												/>
											</div>
											<div className={styles.row}>
												<div className={styles.rowTitle}>
													Kontributi i Punëtorit/Punëdhënësit:
												</div>
												<InputCustom
													value={
														modalEmployeeInputs?.contributePercentage || ""
													}
													onFocusHandler={inputOnFocusSelect}
													onChangeHandler={(e) =>
														onContributionsChange(e.target.value)
													}
													className={`${styles.rowInput} ${styles.percentageSignInput} ${styles.noArrowsNumberInput}`}
													isRequired={editMode}
													min="0"
													onBlur={(e) =>
														onContributionsChange(e.target.value, true)
													}
													onWheel={(event) => event.currentTarget.blur()}
													validationText={
														"Paga bruto nuk duhet të jetë e zbrazët"
													}
													attributes={{
														name: "contributePercentage",
														autoComplete: "off",
													}}
												/>
											</div>
										</div>

										<div className={styles.colHalf}>
											<div className={styles.row}>
												<div className={styles.rowTitle}>Pagesa:</div>
												<CustomSelect
													options={salaryPaymentTypeOptions}
													className={`${styles.customSelect} ${styles.rowInput
														} ${!editMode && styles.pointerEventsNone}`}
													onChangeHandler={(value) =>
														onChangeSalaryPaymentType(value)
													}
													selected={salaryPaymentType}
													placeholder="Zgjedh opsionin"
													name="salaryPaymentType"
													components={{ DropdownIndicator }}
													controlStyle={{ borderRadius: "4px" }}
													{...customSelectStyles}
												/>
											</div>
											{displaySalaryValues ? (
												<>
													<div className={styles.row}>
														<div className={styles.rowTitle}>Paga Bruto:</div>
														<InputCustom
															value={modalEmployeeInputs?.bruttoSalary || ""}
															// value={modalEmployeeInputs?.bruttoSalary}
															readOnly={
																salaryPaymentType.value == 3 ? true : !editMode
															}
															onFocusHandler={inputOnFocusSelect}
															onKeyDown={(e) => handleKeyDown(e)}
															onChangeHandler={inputBrutoOnChange}
															className={`${styles.rowInput} ${styles.euroSignInput} ${styles.noArrowsNumberInput}`}
															isRequired={editMode}
															validationText={
																"Paga bruto nuk duhet të jetë e zbrazët"
															}
															attributes={{
																name: "bruttoSalary",
																autoComplete: "off",
															}}
														/>
													</div>
													<div className={styles.row}>
														<div className={styles.rowTitle}>Paga Neto:</div>
														<InputCustom
															value={modalEmployeeInputs?.nettoSalary || ""}
															readOnly={
																salaryPaymentType.value == 3 ? true : !editMode
															}
															onFocusHandler={inputOnFocusSelect}
															// onKeyDown={inputNumberKeydownHandler}
															onChangeHandler={inputNetoOnChange}
															className={`${styles.rowInput} ${styles.euroSignInput} ${styles.noArrowsNumberInput}`}
															isRequired={editMode}
															validationText={
																"Paga neto nuk duhet të jetë e zbrazët"
															}
															attributes={{
																name: "nettoSalary",
																autoComplete: "off",
															}}
														/>
													</div>
													<div className={styles.row}>
														<div className={styles.rowTitle}>
															Jo TAP:
															<input
																type="checkbox"
																checked={noTaxes}
																onChange={async ({ target }) => {
																	const { checked } = target;
																	setNoTaxes(checked);

																	const calculatedSalaryBruto =
																		await calculateSalary({
																			gross: +modalEmployeeInputs?.bruttoSalary,
																			contributionPercentage:
																				+modalEmployeeInputs?.contributePercentage,
																			employerTier: employerType.value,
																			noTaxes: checked,
																		});

																	setModalEmployeeInputs((prevState) => ({
																		...prevState,
																		bruttoSalary: calculatedSalaryBruto?.bruto,
																		nettoSalary: calculatedSalaryBruto?.neto,
																		tax: calculatedSalaryBruto?.tax,
																		contribution:
																			calculatedSalaryBruto?.contribution,
																	}));
																}}
															/>
														</div>
													</div>
												</>
											) : (
												<>
													<div className={styles.row}>
														<div className={styles.rowTitle}>Paga me orë:</div>
														<InputCustom
															onChangeHandler={handleModalEmployeeInputsChange}
															className={`${styles.rowInput} ${styles.tableInput} ${styles.euroSignInput}`}
															name="pricePerHour"
															value={modalEmployeeInputs?.pricePerHour || ""}
															readOnly={!editMode}
														// type="number"
														/>
													</div>
													{pickedProjectsWithBudgetLines &&
														pickedProjectsWithBudgetLines?.length > 0 && (
															<>
																<div className={styles.row}>
																	<div className={styles.rowTitle}>
																		Paga Bruto:
																	</div>
																	<InputCustom
																		value={
																			modalEmployeeInputs?.bruttoSalary || ""
																		}
																		readOnly={!editMode}
																		onFocusHandler={inputOnFocusSelect}
																		onKeyDown={inputNumberKeydownHandler}
																		onChangeHandler={inputBrutoOnChange}
																		className={`${styles.rowInput} ${styles.euroSignInput} ${styles.noArrowsNumberInput}`}
																		isRequired={editMode}
																		validationText={
																			"Paga bruto nuk duhet të jetë e zbrazët"
																		}
																		attributes={{
																			name: "bruttoSalary",
																			autoComplete: "off",
																		}}
																	/>
																</div>

																<div className={styles.row}>
																	<div className={styles.rowTitle}>
																		Paga Neto:
																	</div>
																	<InputCustom
																		value={
																			modalEmployeeInputs?.nettoSalary || ""
																		}
																		readOnly={!editMode}
																		onFocusHandler={inputOnFocusSelect}
																		onKeyDown={inputNumberKeydownHandler}
																		onChangeHandler={inputNetoOnChange}
																		className={`${styles.rowInput} ${styles.euroSignInput} ${styles.noArrowsNumberInput}`}
																		isRequired={editMode}
																		validationText={
																			"Paga neto nuk duhet të jetë e zbrazët"
																		}
																		attributes={{
																			name: "nettoSalary",
																			autoComplete: "off",
																		}}
																	/>
																</div>
															</>
														)}
												</>
											)}

											{tenantHealthInsurance?.prime && (
												<>
													<div className={styles.row}>
														<div className={styles.rowTitle}>
															Sigurimi shëndetësor:
														</div>
														<CustomSelect
															options={yesNoOptions}
															className={`${styles.customSelect} ${styles.rowInput
																} ${!editMode && styles.pointerEventsNone}`}
															onChangeHandler={(value) =>
																handleHealthInsuranceChange(value)
															}
															selected={healthInsurance}
															placeholder="Zgjedh opsionin"
															name="healthInsurance"
															components={{ DropdownIndicator }}
															controlStyle={{ borderRadius: "4px" }}
															{...customSelectStyles}
														/>
													</div>
												</>
											)}
											{tenantHealthInsurance?.prime && (
												<>
													<div className={styles.row}>
														<div className={styles.rowTitle}>
															% e mbulueshmërisë së sigurimit:
														</div>
														<InputCustom
															onChangeHandler={handleModalEmployeeInputsChange}
															onFocusHandler={inputOnFocusSelect}
															className={`${styles.rowInput} ${styles.noArrowsNumberInput}`}
															name="insurancePercentage"
															onWheel={(event) => event.currentTarget.blur()}
															min="0"
															value={modalEmployeeInputs?.insurancePercentage}
															readOnly={!editMode}
															type="number"
															attributes={{
																disabled:
																	healthInsurance?.value === 1 ? false : true,
															}}
														/>
													</div>
												</>
											)}

											{tenantHealthInsurance?.prime && (
												<>
													<div
														className={`${styles.row} ${styles.familyMemberRow}`}
													>
														<div className={styles.rowTitle}>Familjar:</div>
														<DropdownSelect
															data={familyMemberOptionsLeft}
															buttonText="Zgjedh ..."
															className={styles.rowInput}
															readOnly={!editMode}
															optionClick={(event, item) =>
																handleFamilyMemberOnClick(item)
															}
														/>
													</div>
												</>
											)}
											<div
												className={`${styles.row} ${styles.familyMemberRowContent}`}
											>
												{familyMember?.map((item, index) => {
													return (
														<div
															key={index}
															className={`${styles.row} ${styles.item}`}
														>
															<div className={styles.rowInput}>
																{" "}
																{item.label}
															</div>
															<InputField
																value={
																	modalEmployeeInputs?.familyMemberCount?.[
																	item.value
																	] || ""
																}
																readOnly={!editMode}
																onKeydownHandler={inputNumberKeydownHandler}
																onChangeHandler={familyMemberCountChangeHandler.bind(
																	this,
																	item
																)}
																className={`${styles.rowTitle}`}
																type={editMode ? "number" : ""}
																min="1"
																attributes={{
																	name: item.label,
																	readOnly: !editMode,
																	autoComplete: "off",
																}}
															/>
															{editMode ? (
																<div
																	className={styles.deleteItem}
																	title="Fshij"
																	onClick={(event) =>
																		handleFamilyMemberCountDeleteRow(
																			event,
																			item
																		)
																	}
																>
																	<IconX />
																</div>
															) : (
																""
															)}
														</div>
													);
												})}
											</div>
											<div className={styles.buttonCalculateWrapper}>
												{" "}
												<Button
													className={`${styles.button}`}
													onClickHandler={onClickSalary}
												>
													Llogarit
												</Button>
											</div>
										</div>
									</div>
									<div className={`${styles.row} ${styles.tableRow}`}>
										<table className={styles.additionDeductionTable}>
											<thead>
												<tr>
													<th colSpan={2} style={{ width: "50%" }}>
														Shtesat
													</th>
													<th colSpan={2} style={{ width: "50%" }}>
														Ndalesat
													</th>
												</tr>
												<tr>
													<th>Item</th>
													<th>Vlera</th>
													<th>Item</th>
													<th>Vlera</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													{(salaryPaymentType &&
														salaryPaymentType.value === 1) ||
														salaryPaymentType.value === 3 ? (
														<>
															<td>Paga bruto fikse</td>
															<td className={`${styles.salaryTableValue}`}>
																<InputCustom
																	onChangeHandler={doNothing}
																	className={`${styles.rowInput} ${styles.tableInput} ${styles.euroSignInput}`}
																	value={modalEmployeeInputs?.bruttoSalary}
																	readOnly={true}
																/>
															</td>
														</>
													) : (
														<>
															<td>Paga me orë</td>
															<td>
																<InputCustom
																	onChangeHandler={
																		handleModalEmployeeInputsChange
																	}
																	className={`${styles.rowInput} ${styles.tableInput} ${styles.euroSignInput}`}
																	name="pricePerHour"
																	value={
																		modalEmployeeInputs?.pricePerHour || ""
																	}
																	readOnly={!editMode}
																	type="number"
																/>
															</td>
														</>
													)}
													<td>Kontributet pensionale</td>
													{modalEmployeeInputs?.contribution ? (
														<td>
															{parseNumberCustom(
																number_format(
																	modalEmployeeInputs?.contribution * 2,
																	2
																)
															)}
															€
														</td>
													) : (
														<td>0.00€</td>
													)}
												</tr>
												<tr>
													<td></td>
													<td></td>
													<td>Tatimi në të ardhura</td>
													{modalEmployeeInputs?.tax ? (
														<td>{modalEmployeeInputs?.tax || ""}€</td>
													) : (
														<td></td>
													)}
												</tr>
												<tr>
													<td className={styles.addProjectButtonContainer}>
														{salaryPaymentType?.value !== 1 && (
															<>
																<p>Projektet</p>
																<AiOutlinePlusCircle />
																<DropdownSelect
																	data={checkPickedProjects()}
																	className={styles.rowInput}
																	optionClick={(event, item) => {
																		handleProjectPick(event, item);
																	}}
																	readOnly={!editMode}
																/>
															</>
														)}
													</td>
													{allProjects?.length > 0 ? <td></td> : <td></td>}
													<td>Sigurimi shëndetësor</td>
													{tenantHealthInsurance?.prime ? (
														<td>{tenantHealthInsurance?.prime}€</td>
													) : (
														<td></td>
													)}
												</tr>
												{editMode && pickedProjects.length
													? pickedProjects.map((project, index) => {
														return (
															<>
																<tr key={index}>
																	<td>
																		{salaryPaymentType?.value !== 1 && (
																			<div
																				className={`${styles.projectRow} ${styles.item}`}
																			>
																				<div
																					className={`${styles.rowInput} ${styles.dropDownArrowContainer}`}
																				>
																					<p
																						className={
																							toggledBudgetLines[index] &&
																							styles.toggledProject
																						}
																						onClick={() => {
																							toggleBudgetLines(index);
																						}}
																					>
																						{project.name}
																					</p>
																					<div
																						className={
																							styles.budgetLineSelectorContainer
																						}
																					>
																						<RiArrowDownSLine
																							onClick={() =>
																								toggleSelectableBudgetLines(
																									index,
																									project
																								)
																							}
																						/>
																						{pickedProjectBudgetLines[index]
																							?.budgetLines.length !== 0 &&
																							toggledSelectableBudgetLines[
																							index
																							] && (
																								<div
																									className={
																										styles.budgetLines
																									}
																								>
																									{pickedProjectBudgetLines[
																										index
																									]?.budgetLines &&
																										pickedProjectBudgetLines[
																											index
																										]?.budgetLines?.map(
																											(
																												budgetLine,
																												budgetLineIndex
																											) => {
																												return (
																													<div
																														className={
																															styles.budgetLine
																														}
																														onClick={() =>
																															addBudgetLine(
																																budgetLine,
																																project?.id,
																																index
																															)
																														}
																														key={
																															budgetLineIndex
																														}
																													>
																														<p>
																															{
																																budgetLine?.name
																															}
																														</p>
																														<AiOutlinePlusCircle />
																													</div>
																												);
																											}
																										)}
																								</div>
																							)}
																					</div>
																				</div>
																				<div
																					className={`${styles.deleteItem} ${styles.deleteSelectedProject}`}
																					title="Fshij"
																					onClick={(event) =>
																						handleProjectDelete(
																							event,
																							project
																						)
																					}
																				>
																					<BsTrash />
																				</div>
																			</div>
																		)}
																	</td>
																	<td></td>
																	<td></td>
																	<td></td>
																</tr>
																{salaryPaymentType?.value !==
																	SalaryPaymentType.Fixed &&
																	pickedProjectsWithBudgetLines[index]
																		?.budgetLines &&
																	toggledBudgetLines[index] &&
																	pickedProjectsWithBudgetLines[
																		index
																	]?.budgetLines.map(
																		(budgetLine, budgetLineIndex) => {
																			return (
																				<tr
																					className={styles.budgetLineRow}
																					key={budgetLineIndex}
																				>
																					<td>
																						<div
																							className={
																								styles.budgetLineContentWrapper
																							}
																						>
																							<p>{budgetLine?.name}</p>
																							<div
																								style={{
																									transform: "rotate(45deg)",
																									cursor: "pointer",
																								}}
																								onClick={() =>
																									removeBudgetLine(
																										budgetLine,
																										project?.id,
																										index
																									)
																								}
																							>
																								<AiOutlinePlusCircle />
																							</div>
																						</div>
																					</td>
																					<td>
																						<div
																							className={
																								styles.budgetLineContentWrapper
																							}
																						>
																							<InputCustom
																								className={
																									styles.budgetLineInput
																								}
																								value={budgetLine?.budget}
																								onChangeHandler={(e) => {
																									setBudgetLineValues(
																										e.target.value,
																										budgetLineIndex,
																										index
																									);
																								}}
																							/>

																							{salaryPaymentType?.value ===
																								3 && (
																									<div
																										className={
																											styles.typeSelectContainer
																										}
																									>
																										<div>
																											<input
																												type="checkbox"
																												checked={
																													budgetLine?.checked ==
																													"true"
																												}
																												onChange={(e) =>
																													setCheckboxValues(
																														e.target.checked,
																														budgetLineIndex,
																														index,
																														budgetLine
																													)
																												}
																												data-tip="Nuk mbulohen shpenzimet"
																											/>
																											<ReactTooltip
																												place="top"
																												type="dark"
																												effect="solid"
																												multiline={false}
																												className="customTooltip2"
																											/>
																										</div>
																									</div>
																								)}

																							{salaryPaymentType?.value ===
																								2 && (
																									<>
																										<div
																											className={
																												styles.checkboxWrapper
																											}
																										>
																											<div
																												className={
																													styles.hrCheckbox
																												}
																											>
																												<input
																													type="checkbox"
																													checked={
																														budgetLine?.checked ==
																														"true"
																													}
																													onChange={(e) =>
																														setCheckboxValuesForHours(
																															e.target.checked,
																															budgetLineIndex,
																															index,
																															budgetLine
																														)
																													}
																													data-tip="Nuk mbulohen shpenzimet"
																												/>
																												<ReactTooltip
																													place="top"
																													type="dark"
																													effect="solid"
																													multiline={false}
																													className="tooltip3"
																												/>
																											</div>

																											<div
																												className={
																													styles.hrStyle
																												}
																											>
																												<p>HR</p>
																											</div>
																										</div>
																									</>
																								)}
																						</div>
																					</td>
																					<td></td>
																					<td></td>
																				</tr>
																			);
																		}
																	)}
															</>
														);
													})
													: null}
												{!editMode && pickedProjectsWithBudgetLines.length
													? pickedProjectsWithBudgetLines.map(
														(project, index) => {
															return (
																<>
																	<tr key={index}>
																		<td>
																			{salaryPaymentType?.value !== 1 && (
																				<div
																					className={`${styles.projectRow} ${styles.item}`}
																				>
																					<div
																						className={`${styles.rowInput} ${styles.dropDownArrowContainer}`}
																					>
																						<p
																							className={
																								toggledBudgetLines[index] &&
																								styles.toggledProject
																							}
																							onClick={() => {
																								toggleBudgetLines(index);
																							}}
																						>
																							{project.name}
																						</p>
																					</div>
																				</div>
																			)}
																		</td>
																		<td></td>
																		<td></td>
																		<td></td>
																	</tr>
																	{project?.budgetLines &&
																		project?.budgetLines?.map(
																			(budgetLine, budgetLineIndex) => {
																				return (
																					<tr
																						className={styles.budgetLineRow}
																						key={budgetLineIndex}
																					>
																						<td>
																							<div
																								className={
																									styles.budgetLineContentWrapper
																								}
																							>
																								<p>{budgetLine?.name}</p>
																							</div>
																						</td>
																						<td>
																							<div
																								className={
																									styles.budgetLineContentWrapper
																								}
																							>
																								<p
																									className={
																										styles.budgetLineInput
																									}
																								>
																									{budgetLine?.budget}
																								</p>
																								{budgetLine?.type !==
																									null && (
																										<div
																											className={
																												styles.typeSelectContainer
																											}
																										>
																											{budgetLine?.checked ===
																												"true" && (
																													<div
																														className={
																															styles.checkedExpenses
																														}
																													>
																														<input
																															type="checkbox"
																															checked={true}
																														/>
																													</div>
																												)}
																											<div>
																												<div>
																													{budgetLine?.type ==
																														"1" &&
																														project?.paymentType !=
																														"2" &&
																														"%"}

																													{budgetLine?.type ==
																														"2" && "€"}

																													{project?.paymentType ==
																														"2" && "HR"}
																												</div>
																											</div>
																										</div>
																									)}
																							</div>
																						</td>
																						<td></td>
																						<td></td>
																					</tr>
																				);
																			}
																		)}
																</>
															);
														}
													)
													: null}
											</tbody>
										</table>
									</div>
								</div>
							</Tab>

							<Tab title="Informata për punëtorin">
								<div
									className={`${styles.employeeInfoContent} ${styles.modalPage}`}
								>
									<EmployeeNameAndImage
										handleModalEmployeeInputsChange={
											handleModalEmployeeInputsChange
										}
										modalEmployeeInputs={modalEmployeeInputs}
										setModalEmployeeInputs={setModalEmployeeInputs}
										editMode={editMode}
										modalEmployeeSelected={modalEmployeeSelected}
									/>
									<div className={`${styles.row} ${styles.contentRow}`}>
										<div className={styles.colHalf}>
											<div className={styles.row}>
												<div className={styles.rowTitle}>Pozita:</div>
												<InputCustom
													onChangeHandler={handleModalEmployeeInputsChange}
													className={styles.rowInput}
													name="occupation"
													value={modalEmployeeInputs?.occupation || ""}
													readOnly={!editMode}
													isRequired={editMode}
													type="text"
													validationText={"Pozita nuk duhet të jetë e zbrazët"}
												/>
											</div>
											<div className={styles.row}>
												<div className={styles.rowTitle}>Fillimi i punës:</div>
												<InputCustom
													onChangeHandler={handleModalEmployeeInputsChange}
													className={styles.rowInput}
													name="jobStarted"
													value={modalEmployeeInputs?.jobStarted || ""}
													readOnly={!editMode}
													type="date"
													min="2000-01-01"
												/>
											</div>
											<div className={styles.row}>
												<div className={styles.rowTitle}>
													Fillimi i kontratës:
												</div>
												<InputCustom
													onChangeHandler={handleModalEmployeeInputsChange}
													className={styles.rowInput}
													name="contractStarted"
													value={modalEmployeeInputs?.contractStarted || ""}
													readOnly={!editMode}
													type="date"
													min="2000-01-01"
												/>
											</div>
											<div className={styles.row}>
												<div className={styles.rowTitle}>
													Skadimi i kontratës:
												</div>
												<CustomSelect
													options={contractOptions}
													className={`${styles.customSelect} ${styles.rowInput
														} ${!editMode && styles.pointerEventsNone}`}
													onChangeHandler={(value) => {
														setExpirationContractType((prev) => value);
													}}
													selected={expirationContractType}
													placeholder="Zgjedh opsionin"
													name="expirationContractType"
													components={{ DropdownIndicator }}
													{...customSelectStyles}
													controlStyle={{
														...customSelectStyles.controlStyle,
														borderRadius: "4px",
														minWidth: "fit-content",
														backgroundColor: "inherit",
													}}
												/>
											</div>
											{expirationContractType &&
												expirationContractType.value === 2 ? (
												<div className={`${styles.row} pt-0`}>
													<div className={styles.rowTitle}></div>
													<InputCustom
														onChangeHandler={handleModalEmployeeInputsChange}
														className={`${styles.rowInput} pt-0`}
														inputClass={`pl-0 pt-0`}
														name="expirationContract"
														value={
															modalEmployeeInputs?.expirationContract || ""
														}
														readOnly={!editMode}
														type="date"
													/>
												</div>
											) : (
												<></>
											)}

											{userClientId && editMode ? (
												allSectors?.length > 0 ? (
													<div
														className={`${styles.row} ${styles.familyMemberRow}`}
													>
														<div className={styles.rowTitle}>Sektori:</div>
														<DropdownSelect
															data={allSectors?.filter(
																(sector) => !pickedSectors.includes(sector)
															)}
															buttonText="Zgjedh ..."
															className={styles.rowInput}
															optionClick={(event, item) => {
																handleSectorPick(event, item);
															}}
															readOnly={!editMode}
														/>
													</div>
												) : (
													<div
														className={`${styles.row} ${styles.familyMemberRow}`}
													>
														<div className={styles.rowTitle}>Sektori:</div>
														<DropdownSelect
															data={[]}
															buttonText="Zgjedh ..."
															className={styles.rowInput}
															optionClick={(event, item) => {
																handleSectorPick(event, item);
															}}
															readOnly={!editMode}
														/>
													</div>
												)
											) : null}
											{editMode && pickedSectors.length
												? pickedSectors.map((sector, index) => {
													return (
														<div
															className={styles.pickedSectorWrapper}
															key={index}
														>
															<div
																className={`${styles.projectRow} ${styles.item}`}
															>
																<div className={styles.rowInput2}>
																	{sector?.label}
																</div>
																<div
																	className={`${styles.deleteItem}`}
																	title="Fshij"
																	onClick={(event) =>
																		handleSectorDelete(sector, index)
																	}
																>
																	<IconX />
																</div>
															</div>
														</div>
													);
												})
												: null}

											{!editMode && userClientId && (
												<div className={styles.sectorContainerWrapper}>
													<div className={styles.rowTitle}>Sektori:</div>
													<div className={styles.sectors}>
														{modalEmployeeInputs?.sector?.map(
															(employeeSector, index) => {
																return (
																	<div key={index}>
																		<div
																			className={`${styles.projectRow} ${styles.item}`}
																		>
																			<div className={styles.rowInput2}>
																				{employeeSector?.name}
																			</div>
																		</div>
																	</div>
																);
															}
														)}
													</div>
												</div>
											)}
										</div>
										<div className={styles.colHalf}>
											<div className={styles.row}>
												<div className={styles.rowTitle}>
													Kontakti i një familjari:
												</div>
												<InputCustom
													onChangeHandler={handleModalEmployeeInputsChange}
													className={styles.rowInput}
													name="familyContact"
													value={modalEmployeeInputs?.familyContact || ""}
													readOnly={!editMode}
													type="text"
												/>
											</div>
											<div className={styles.row}>
												<div className={styles.rowTitle}>
													Me kohë të plotë / Me kohë të pjesëshme / Kontraktues
												</div>
												<CustomSelect
													options={employmentTypeOptions}
													className={`${styles.customSelect} ${styles.rowInput
														} ${!editMode && styles.pointerEventsNone}`}
													onChangeHandler={(value) =>
														setEmploymentType((prev) => value)
													}
													selected={employmentType}
													placeholder="Zgjedh opsionin"
													name="employmentType"
													components={{ DropdownIndicator }}
													controlStyle={{
														borderRadius: "4px",
														minWidth: "fit-content",
													}}
													{...customSelectStyles}
												/>
											</div>
											<div className={styles.row}>
												<div className={styles.rowTitle}>Aktiv / Joaktiv:</div>
												<CustomSelect
													options={activeInactiveOptions}
													className={`${styles.customSelect} ${styles.rowInput
														} ${!editMode && styles.pointerEventsNone}`}
													onChangeHandler={(value) =>
														setActiveInactive((prev) => value)
													}
													selected={activeInactive}
													placeholder="Zgjedh opsionin"
													name="status"
													components={{ DropdownIndicator }}
													controlStyle={{
														borderRadius: "4px",
														minWidth: "fit-content",
													}}
													{...customSelectStyles}
												/>
											</div>

											<EmployeeAttachments
												editMode={editMode}
												modalEmployeeInputs={modalEmployeeInputs}
												setModalEmployeeInputs={setModalEmployeeInputs}
												setShowConfirmModal={setShowConfirmModal}
												setConfirmLoading={setConfirmLoading}
											/>
										</div>
									</div>
								</div>
							</Tab>
						</Tabs>
					</div>
					<Button
						className={styles.closeModal}
						onClickHandler={closeModal}
						viewType={"shell"}
						attributes={{
							title: "Anulo",
						}}
					>
						<IconX />
					</Button>
				</div>
				{editMode ? (
					<div className={styles.footer}>
						{!(isEmployeeSelected && activeTab === 1) && (
							<Button
								onClickHandler={() => onSave(DraftStatus.Draft)}
								className={`${styles.button} ${styles.shellButton}`}
								loading={loadingDraftButton}
								viewType={"shell"}
							>
								Ruaj draftin
							</Button>
						)}
						<Button
							className={`${styles.button}`}
							onClickHandler={() => {
								onSave(DraftStatus.Undrafted);
							}}
							loading={submitButtonLoading}
						>
							Ruaj
						</Button>
					</div>
				) : (
					""
				)}
			</Modal>
			<ConfirmModal
				show={showConfirmModal.show}
				confirmCallback={showConfirmModal.callback}
				rejectCallback={() => {
					setShowConfirmModal({ show: false });
				}}
				title={showConfirmModal.title}
				subTitle={showConfirmModal.subTitle}
				submitText={"Fshij"}
				submitLoading={confirmLoading}
				preventBackdropCloseClick={true}
				setShowConfirmModal={setShowConfirmModal}
				setConfirmLoading={setConfirmLoading}
			/>
		</>
	);
};

export default EmployeesModal;
