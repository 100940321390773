import produce from 'immer';
import {toast} from 'react-toastify';
import {
  GET_PAGINATED_NOTIFICATIONS,
  GET_PAGINATED_NOTIFICATIONS_ERROR,
  GET_PAGINATED_NOTIFICATIONS_SUCCESS, READ_ALL_NOTIFICATIONS_SUCCESS, READ_SELECTED_NOTIFICATION_ERROR,
  READ_SELECTED_NOTIFICATION_SUCCESS,
} from './constants';
import {Notification} from '../../types/notification.type';


export interface NotificationReducerState {
  notifications?: Notification[];
  total: number;
  canSeeRead: boolean;
}

const initialState = {
  notifications: undefined,
  total: 0,
  canSeeRead: true,
};


const notificationReducer = (state = initialState, action) =>
    produce(state, draft => {
      switch (action.type) {
        case GET_PAGINATED_NOTIFICATIONS:
          if (draft.canSeeRead !== action.payload.canSeeRead) {
            draft.notifications = undefined;
          }
          break;
        case GET_PAGINATED_NOTIFICATIONS_SUCCESS:
          if (draft.canSeeRead !== action.payload.canSeeRead) {
            draft.notifications = action.payload.notifications;
            draft.canSeeRead = action.payload.canSeeRead;
            draft.total = action.payload.total;
          } else {
            if (draft.notifications !== undefined && draft.notifications.length > 0) {
              draft.notifications = [...draft.notifications, ...action.payload.notifications];
            } else {
              draft.notifications = action.payload.notifications;
            }
            draft.total = action.payload.total;
          }
          break;
        case GET_PAGINATED_NOTIFICATIONS_ERROR:
          toast.error('Notifications deshtuan te lodohen');
          break;
        case READ_SELECTED_NOTIFICATION_SUCCESS:
          const indexOfCurrentNotification = draft.notifications.findIndex((notification) => notification.id === action.payload.notificationId);

          draft.notifications[indexOfCurrentNotification].readAt = new Date();
          break;
        case READ_SELECTED_NOTIFICATION_ERROR:
          toast.error('Notification nuk mund te lexohet momentalisht!');
          break;
        case READ_ALL_NOTIFICATIONS_SUCCESS:
          draft.notifications?.forEach((notification) => {
            notification.readAt = new Date();
          });
          break;
      }
    });

export default notificationReducer;
