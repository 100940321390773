import { Salary } from "types/salary.type";
import {
  CLOSE_MONTH,
  CLOSE_MONTH_ERROR,
  CLOSE_MONTH_SUCCESS,
  CREATE_MONTH_SALARIES,
  CREATE_MONTH_SALARIES_ERROR,
  CREATE_MONTH_SALARIES_SUCCESS,
  EMAIL_CONTRACT,
  EMAIL_CONTRACT_ERROR,
  EMAIL_CONTRACT_SUCCESS,
  EMAIL_PAYSLIP,
  EMAIL_PAYSLIP_ERROR,
  EMAIL_PAYSLIP_SUCCESS,
  EXPORT_EXCEL_SALARIES,
  EXPORT_EXCEL_SALARIES_ERROR,
  EXPORT_EXCEL_SALARIES_SUCCESS,
  GENERATE_PAYSLIP,
  GENERATE_PAYSLIP_ERROR,
  GENERATE_PAYSLIP_SUCCESS,
  GET_SALARY_CONFIGURATIONS,
  LOAD_SALARIES,
  LOAD_SALARIES_ERROR,
  LOAD_SALARIES_SUCCESS,
  OPEN_MONTH_SALARY,
  OPEN_MONTH_SALARY_ERROR,
  OPEN_MONTH_SALARY_SUCCESS,
  PRINT_PAYSLIP,
  PRINT_PAYSLIP_ERROR,
  PRINT_PAYSLIP_SUCCESS,
  SET_MODAL_EDIT_MODE,
  SET_MODAL_OPEN,
  UPDATE_SALARY,
  UPDATE_SALARY_ERROR,
  UPDATE_SALARY_SUCCESS,
} from "./constants";

export const setModalOpen = (payload) => {
  return {
    type: SET_MODAL_OPEN,
    payload,
  };
};
export const setModalEditMode = (payload) => {
  return {
    type: SET_MODAL_EDIT_MODE,
    payload,
  };
};

export const loadSalaries = (payload) => {
  return {
    type: LOAD_SALARIES,
    payload,
  };
};

export const loadSalariesSuccess = (payload) => {
  return {
    type: LOAD_SALARIES_SUCCESS,
    payload,
  };
};
export const loadSalariesError = () => {
  return {
    type: LOAD_SALARIES_ERROR,
    payload: {},
  };
};

export const createMonthSalaries = (payload) => {
  return {
    type: CREATE_MONTH_SALARIES,
    payload: {
      ...payload,
    },
  };
};
export const createMonthSalariesSuccess = (salaries: Salary[]) => {
  return {
    type: CREATE_MONTH_SALARIES_SUCCESS,
    payload: {
      salaries,
    },
  };
};
export const createMonthSalariesError = () => {
  return {
    type: CREATE_MONTH_SALARIES_ERROR,
    payload: {},
  };
};

export const submitUpdateSalary = (payload) => {
  return {
    type: UPDATE_SALARY,
    payload,
  };
};
export const submitUpdateSalarySuccess = (payload) => {
  return {
    type: UPDATE_SALARY_SUCCESS,
    payload,
  };
};
export const submitUpdateSalaryError = (error) => {
  return {
    type: UPDATE_SALARY_ERROR,
    payload: {},
  };
};

export const exportEcxel = (tenantId, month, year) => {
  return {
    type: EXPORT_EXCEL_SALARIES,
    payload: {
      tenantId,
      month,
      year,
    },
  };
};
export const exportEcxelSuccess = (payload) => {
  return {
    type: EXPORT_EXCEL_SALARIES_SUCCESS,
    payload,
  };
};
export const exportEcxelError = (error) => {
  return {
    type: EXPORT_EXCEL_SALARIES_ERROR,
    payload: {},
  };
};

export const generatePayslip = (payload) => {
  return {
    type: GENERATE_PAYSLIP,
    payload,
  };
};
export const generatePayslipSuccess = (payload) => {
  return {
    type: GENERATE_PAYSLIP_SUCCESS,
    payload,
  };
};
export const generatePayslipError = (error) => {
  return {
    type: GENERATE_PAYSLIP_ERROR,
    payload: {
      error,
    },
  };
};

export const printPayslip = (payload) => {
  return {
    type: PRINT_PAYSLIP,
    payload,
  };
};
export const printPayslipSuccess = (payload) => {
  return {
    type: PRINT_PAYSLIP_SUCCESS,
    payload,
  };
};
export const printPayslipError = (error) => {
  return {
    type: PRINT_PAYSLIP_ERROR,
    payload: {
      error,
    },
  };
};

export const emailPayslip = (payload) => {
  return {
    type: EMAIL_PAYSLIP,
    payload,
  };
};
export const emailPayslipSuccess = (payload) => {
  return {
    type: EMAIL_PAYSLIP_SUCCESS,
    payload,
  };
};
export const emailPayslipError = (error) => {
  return {
    type: EMAIL_PAYSLIP_ERROR,
    payload: {
      error,
    },
  };
};

export const emailContract = (payload) => {
  return {
    type: EMAIL_CONTRACT,
    payload,
  };
};
export const emailContractSuccess = (payload) => {
  return {
    type: EMAIL_CONTRACT_SUCCESS,
    payload,
  };
};
export const emailContractError = (error) => {
  return {
    type: EMAIL_CONTRACT_ERROR,
    payload: {
      error,
    },
  };
};

export const closeMonth = (payload) => {
  return {
    type: CLOSE_MONTH,
    payload,
  };
};
export const closeMonthSuccess = (payload) => {
  return {
    type: CLOSE_MONTH_SUCCESS,
    payload,
  };
};
export const closeMonthError = (payload) => {
  return {
    type: CLOSE_MONTH_ERROR,
    payload,
  };
};

export const openMonth = (payload) => {
  return {
    type: OPEN_MONTH_SALARY,
    payload,
  };
};
export const openMonthSuccess = (payload) => {
  return {
    type: OPEN_MONTH_SALARY_SUCCESS,
    payload,
  };
};
export const openMonthError = (payload) => {
  return {
    type: OPEN_MONTH_SALARY_ERROR,
    payload,
  };
};
