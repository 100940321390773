import { FileObject } from "types/partials/fileObject.type";
import { StringLiteral } from "typescript";

export interface Employee {
  id?: number;
  tenantId?: number;
  photo?: any;
  firstName?: string;
  lastName?: string;
  personalNumber?: string;
  birthDate?: string;
  gender?: string;
  phone?: number;
  qualification?: string;
  address?: string;
  municipality?: string;
  email?: string;
  employerType?: number;
  healthInsurance?: number;
  insurancePercentage?: number;
  familyMembers?: {
    adultsChildren: number;
    children: number;
    parent: number;
    partners: number;
  };
  salaryPaymentType?: number;
  bruttoSalary?: number;
  nettoSalary?: number;
  bank?: string;
  bankAccountNumber?: number;
  hourlyValue?: number;
  occupation?: string;
  jobStarted?: string;
  contractStarted?: string;
  expirationContract?: string;
  expirationContractType?: any;
  sector?: string;
  familyContact?: string;
  employmentType?: number;
  status?: number;
  contract?: string;
  draftStatus?: number;
  project?: any;
  rawPhoto?: any;
  attachments?: FileObject[];
  budgetLines?: any;
  noTaxes: boolean;
}

export interface MinimalEmployee {
  id: number;
  name?: string;
}

export interface SaveEmployee {
  id?: number;
  userId: number;
  firstName?: string;
  lastName?: string;
  healthInsuranceValue?: number;
  phone?: string;
  email?: string;
  gender: number;
  qualification?: string;
  birthDate?: string;
  expirationContract?: string;
  address?: string;
  municipality?: string;
  familyContact?: string;
  occupation?: string;
  jobStarted?: string;
  contractStarted?: string;
  attachmentIds: number[];
  personalNumber?: string;
  employeeStatus: EmployeeStatus;
  employerTier: EmploymentTier;
  bankAccountNumber?: string;
  bank?: string;
  noTaxes: boolean;
  contributePercentage: number;
  bruttoSalary: number;
  contribution: number;
  unchangableBrutto: number;
  tax: number;
  nettoSalary: number;
  salaryPaymentType: SalaryPaymentType;
  pricePerHour: number;
  familyMembers: FamilyMember[];
  sectors: Sector[];
  employmentType: EmploymentType;
  draftStatus: DraftStatus;
  projectBudgetLines: EmployeeProjectBudgetLine[];
  healthInsurance?: number;
  healthInsurancePercentage: number;
}

export interface EmployeeProjectBudgetLine {
  projectId: number;
  budgetLines: EmployeeBudgetLine[];
}

export interface EmployeeBudgetLine {
  budgetLineId: number;
  budget: number;
  checked: "true" | "false";
}

export interface Sector {
  id?: string;
  name?: string;
}

export interface FamilyMember {
  familyMemberType: FamilyMemberType;
  members: number;
}

export enum FamilyMemberType {
  Partner = 1,
  Children = 2,
  AdultsChildren = 3,
  Parents = 4,
}

export enum DraftStatus {
  Undrafted = 0,
  Draft = 1,
}

export enum EmployeeStatus {
  Active = 1,
  Inactive = 2,
  Processing = 3,
}

export enum EmploymentTier {
  Primary = 1,
  Secondary = 2,
}

export enum EmploymentType {
  FullTime = 1,
  PartTime = 2,
  Contractor = 3,
}

export enum SalaryPaymentType {
  Fixed = 1,
  Hourly = 2,
  Project = 3,
}

export interface EmployeeToImport {
  firstName?: string;
  lastName?: string;
  name?: string;
  idNumber?: string;
  phoneNumber?: string;
  bank?: string;
  bankAccountNumber?: string;
  address?: string;
  sectors: Sector[];
  employmentType: EmploymentType;
  employmentTypeString?: string;
  grossSalary?: number;
  netSalary?: number;
  contractDate?: string;
  emailAddress?: string;
  class?: string;
  employerTier: EmploymentTier;
  jobTitle?: string;
  tax?: number;
  contribution?: number;
  contributionPercentage?: number;
  gender: number;
  errors: EmployeeToImportColumnError[];
  isValid: boolean;
}

export interface EmployeeToImportColumnError {
  column: "firstName" | "lastName" | "grossSalary" | "netSalary";
  error: string;
}
