import {
  DOWNLOAD_SALARY_LIST_EXCEL,
  DOWNLOAD_SALARY_LIST_EXCEL_SUCCESS,
  DOWNLOAD_SALARY_LIST_EXCEL_ERROR,
} from "./constants";

export const downloadSalaryListExcel = (
  tenantId: number,
  year: number,
  month: number
) => {
  return {
    type: DOWNLOAD_SALARY_LIST_EXCEL,
    payload: {
      tenantId,
      year,
      month,
    },
  };
};

export const downloadSalaryListExcelSuccess = (excelFile: string) => {
  return {
    type: DOWNLOAD_SALARY_LIST_EXCEL_SUCCESS,
    payload: {
      excelFile,
    },
  };
};

export const downloadSalaryListExcelError = (error) => {
  return {
    type: DOWNLOAD_SALARY_LIST_EXCEL_ERROR,
    payload: {
      error,
    },
  };
};
