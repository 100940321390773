import { UpcomingVacation } from "types/upcomingVacation.type";
import {
	LOAD_VACATIONS_ALL,
	LOAD_VACATIONS_ALL_SUCCESS,
	LOAD_VACATIONS_ALL_ERROR,
	LOAD_CALENDAR_VACATIONS_ALL,
	LOAD_CALENDAR_VACATIONS_SUCCESS,
	LOAD_CALENDAR_VACATIONS_ERROR,
	LOAD_VACATION_TYPES_ALL,
	LOAD_VACATION_TYPES_ALL_SUCCESS,
	LOAD_VACATION_TYPE_ALL_ERROR,
	LOAD_NEW_VACATION,
	LOAD_NEW_VACATION_SUCCESS,
	LOAD_NEW_VACATION_ERROR,
	LOAD_DELETE_VACATION,
	LOAD_DELETE_VACATION_SUCCESS,
	LOAD_DELETE_VACATION_ERROR,
	LOAD_UPDATE_VACATION,
	LOAD_UPDATE_VACATION_SUCCESS,
	LOAD_UPDATE_VACATION_ERROR,
	LOAD_SINGLE_VACATION,
	LOAD_SINGLE_VACATION_SUCCESS,
	LOAD_SINGLE_VACATION_ERROR
} from "./constants"

export const loadAllEmployeesAndVacations = (tenantId: number) => {
	return {
		type: LOAD_VACATIONS_ALL,
		payload: {
			tenantId
		}
	}
}

export const loadAllVacationsSuccess = (allVacations: UpcomingVacation[]) => {
	return {
		type: LOAD_VACATIONS_ALL_SUCCESS,
		payload: {
			allVacations
		}
	}
}

export const loadAllVacationsError = () => {
	return {
		type: LOAD_VACATIONS_ALL_ERROR,
		payload: {
		}
	}
}

export const loadAllCalendarVacations = (tenantId: number, period: number) => {
	return {
		type: LOAD_CALENDAR_VACATIONS_ALL,
		payload: {
			tenantId,
			period
		}
	}
}

export const loadAllCalendarVacationsSuccess = (allCalendarVacations: UpcomingVacation[]) => {
	return {
		type: LOAD_CALENDAR_VACATIONS_SUCCESS,
		payload: {
			allCalendarVacations
		}
	}
}

export const loadAllCalendarVacationsError = () => {
	return {
		type: LOAD_CALENDAR_VACATIONS_ERROR,
		payload: {
		}
	}
}

export const loadAllVacationTypes = () => {
	return {
		type: LOAD_VACATION_TYPES_ALL,
		payload: {
		}
	}
}

export const loadAllVacationTypesSuccess = (allVacationTypes: UpcomingVacation[]) => {
	return {
		type: LOAD_VACATION_TYPES_ALL_SUCCESS,
		payload: {
			allVacationTypes
		}
	}
}

export const loadAllVacationTypesError = () => {
	return {
		type: LOAD_VACATION_TYPE_ALL_ERROR,
		payload: {
		}
	}
}


export const loadNewVacation = (newVacation) => {
	return {
		type: LOAD_NEW_VACATION,
		payload: {
			newVacation
		}
	}
}

export const loadNewVacationSuccess = (newVacation: UpcomingVacation[]) => {
	return {
		type: LOAD_NEW_VACATION_SUCCESS,
		payload: {
			newVacation
		}
	}
}

export const loadNewVacationError = () => {
	return {
		type: LOAD_NEW_VACATION_ERROR,
		payload: {
		}
	}
}

export const loadDeleteVacation = (vacation) => {
	return {
		type: LOAD_DELETE_VACATION,
		payload: {
			vacation
		}
	}
}

export const loadDeleteVacationSuccess = (vacation: UpcomingVacation[]) => {
	return {
		type: LOAD_DELETE_VACATION_SUCCESS,
		payload: {
			vacation
		}
	}
}

export const loadDeleteVacationError = () => {
	return {
		type: LOAD_DELETE_VACATION_ERROR,
		payload: {
		}
	}
}

export const loadUpdateVacation = (vacation) => {
	return {
		type: LOAD_UPDATE_VACATION,
		payload: {
			vacation
		}
	}
}

export const loadUpdateVacationSuccess = (updatedVacation: UpcomingVacation[]) => {
	return {
		type: LOAD_UPDATE_VACATION_SUCCESS,
		payload: {
			updatedVacation
		}
	}
}

export const loadUpdateVacationError = () => {
	return {
		type: LOAD_UPDATE_VACATION_ERROR,
		payload: {
		}
	}
}

export const loadSingleVacation = (vacation: number) => {
	return {
		type: LOAD_SINGLE_VACATION,
		payload: {
			vacation
		}
	}
}

export const loadSingleVacationSuccess = (singleVacation: UpcomingVacation[]) => {
	return {
		type: LOAD_SINGLE_VACATION_SUCCESS,
		payload: {
			singleVacation
		}
	}
}

export const loadSingleVacationError = () => {
	return {
		type: LOAD_SINGLE_VACATION_ERROR,
		payload: {
		}
	}
}