import {
  DOWNLOAD_CONTRIBUTIONS_EXCEL,
  DOWNLOAD_CONTRIBUTIONS_PDF,
  LOAD_CONTRIBUTIONS,
  SEND_CONTRIBUTIONS_LIST_EMAIL,
  SEND_EMPLOYEES_EMAIL,
} from "./constants";
import { call, put, takeLatest } from "redux-saga/effects";
import * as reportService from "services/report.service";
import {
  loadAllContributionsSuccess,
  loadAllContributionsError,
  downloadContributionsPdfSuccess,
  downloadContributionsPdfError,
  downloadContributionsExcelSuccess,
  downloadContributionsExcelError,
  sendEmployeesEmailSuccess,
  sendEmployeesEmailError,
} from "./actions";

export function* loadAllContributions(action) {
  try {
    const response = yield call(
      reportService.getContributions,
      action.payload.tenantId,
      action.payload.year,
      action.payload.employeeId
    );
    yield put(loadAllContributionsSuccess(response));
  } catch (e) {
    yield put(loadAllContributionsError(e));
  }
}

export function* downloadContributionsPdf(action) {
  try {
    const response = yield call(
      reportService.exportContributionsPdf,
      action.payload.tenantId,
      action.payload.year,
      action.payload.employeeId
    );
    yield put(downloadContributionsPdfSuccess(response));
  } catch (e) {
    yield put(downloadContributionsPdfError(e));
  }
}

export function* downloadContributionsExcel(action) {
  try {
    const response = yield call(
      reportService.exportContributionsExcel,
      action.payload.tenantId,
      action.payload.year,
      action.payload.employeeId
    );
    yield put(downloadContributionsExcelSuccess(response));
  } catch (e) {
    yield put(downloadContributionsExcelError(e));
  }
}

export function* sendEmployeesEmail(action) {
  try {
    const response = yield call(
      reportService.sendContributeListToMail,
      action.payload.tenantId,
      action.payload.employeeIds,
      action.payload.email
    );

    yield put(sendEmployeesEmailSuccess(response));
  } catch (e) {
    yield put(sendEmployeesEmailError(e));
  }
}

export default function* contributionsSaga() {
  yield takeLatest(LOAD_CONTRIBUTIONS, loadAllContributions);
  yield takeLatest(DOWNLOAD_CONTRIBUTIONS_PDF, downloadContributionsPdf);
  yield takeLatest(DOWNLOAD_CONTRIBUTIONS_EXCEL, downloadContributionsExcel);
  yield takeLatest(SEND_CONTRIBUTIONS_LIST_EMAIL, sendEmployeesEmail);
}
