/* eslint-disable */
import AppWrapper from 'components/AppWrapper/AppWrapper.component';
import styles from './ManagementReports.module.scss';
import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import CommonHeader from 'components/partials/CommonHeader/CommonHeader.component';
import Checkbox from 'react-custom-checkbox';
import { FaChevronLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import ModalCustom from 'components/ModalCustom/ModalCustom.component';
import { FiDownload } from 'react-icons/fi';
import { IconDocument, IconX } from 'assets/icons/icons';
import Table from 'components/Table/Table.component';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { loadAllPaymentOrders, loadPaymentOrderExport, loadPaymentOrderPdf, sendPaymentOrderToEmail } from './actions';
import { DebounceInput } from 'react-debounce-input';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { globalState } from 'types/globalState.type';
import { format } from 'date-fns'
import { customSelectStyles, downloadTypeOptions, employerTypeOptions } from 'utils/globalVariables';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect.component';
import MonthlyStatistics from './MonthlyStatistics/MonthlyStatistics.component';
import ReportTemplateBox from '../../../components/ReportTemplateBox/reportTemplateBox.component';
import ReportsTable from '../../../components/ReportsTable/reportsTable.component';
import { monthNamesAL } from 'utils/globalVariables';
import { generateArrayOfYears } from 'utils/functions';
import { FiCalendar, FiCheck } from 'react-icons/fi';
import Statistics from './Statistics/Statistics.components';
import tableStyles from 'components/Table/Table.module.scss';
import classNames from 'classnames';
import _ from 'lodash';
import { toast } from 'react-toastify';
import LoaderCentered from 'components/partials/LoaderCentered/LoaderCentered.component';
import CustomSelect from 'components/CustomSelect/CustomSelect.component';
import DropdownIndicator from 'components/DropdownIndicator/DropdownIndicator.component';
import Button from 'components/Button/Button.component';
import { useTranslation } from 'react-i18next';
import InputCustom from 'components/InputCustom/InputCustom.component';
import { ConfigurationsState } from 'types/reducers/configurations.reducer.type';
import { getTenantSectors } from 'services/configuration.service';


let currentYear: number = new Date().getFullYear();
let currentMonth: number = new Date().getMonth();

const monthsList = monthNamesAL;
let yearsList = generateArrayOfYears(21);

const ManagementReports: FunctionComponent = () => {
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);
	const { t, i18n } = useTranslation('common');
	const history = useHistory();
	const dispatch = useDispatch();
	const componentRef = useRef<HTMLDivElement>(null)
	const [isOrderPaymentOpen, setIsOrderPaymentOpen] = useState<boolean>(false);
	const [isMonthlyStatisticsOpen, setIsMonthlyStatisticsOpen] = useState<boolean>(false);
	const [isAnnualSalaries, setIsAnnualSalaries] = useState<boolean>(false);
	const [isStatisticsModalOpen, setIsStatisticsOpenModal] = useState(false);
	const [bankSearchTerm, setBankSearchTerm] = useState<string>("");
	const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);
	const [showFilterContent, setShowFilterContent] = useState(false);
	const [pickedYear, setPickedYear] = useState(currentYear);
	const [pickedMonth, setPickedMonth] = useState(currentMonth);

	const tableHeaderData = ["", t('employees.nameAndSurname'), t('bankPay.personalId'), t('bankPay.bankAccount'), t('bankPay.bank'), t('bankPay.reasonPay'), t('bankPay.netoSalary')];
	const tableHeaderDataForAnnualSalaries = ["", t('employees.nameAndSurname'), t('bankPay.personalId'), t('monthlyStats.brutoSalary'), t('monthlyStats.employeerContribution'), t('monthlyStats.employeeContribution'), t('salaryCalculation.taxes'), t('monthlyStats.netoSalary')];
	const todayDate = format(new Date(), 'dd.MM.yyyy');
	const backdropParent: string = useSelector((state: globalState) => state.appWrapperReducer.backdropParent);
	const state = useSelector((state: RootStateOrAny) => state.paymentOrderReducer);
	const allPaymentOrders = state.allPaymentOrders?.data?.data;

	const monthsWrapperRef = useRef(null);
	const periodWrapperRef = useRef(null);

	const [mainCheckboxClick, setMainCheckboxClick] = useState(null);
	const [mainCheckboxState, setMainCheckboxState] = useState(false);
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
	const [employerType, setEmployerType] = useState(null);
	const [sector, setSector] = useState(null);
	const [isEmailOpen, setIsEmailOpen] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [emailValidMessage, setEmailValidMessage] = useState<string>("");
	const isSendEmailLoading = useSelector((state: RootStateOrAny) => state.documentsReducer.sendEmailLoading);

	const configurationState: ConfigurationsState = useSelector(
		(state: globalState) => state.configurationsReducer
	);
	const [allSectors, setAllSectors] = useState([]);

	useEffect(() => {
		if (!tenantId?.toString().length) return;

		const loadSectors = async () => {
			try {
				const response = await getTenantSectors(tenantId);
				setAllSectors(response.data);
			} catch (err) {
				console.error('failed to load all sectors');
			}
		}

		loadSectors();
	}, [tenantId])


	useEffect(() => {
		if (!isSendEmailLoading) {
			setIsModalOpen(false);
		}
	}, [isSendEmailLoading]);


	useEffect(() => {
		const requestData: { tenantId: number, month: number; year: number; bank?: string, employerType?: number, sectorId?: number } = {
			tenantId,
			month: pickedMonth + 1,
			year: pickedYear,
		};
		bankSearchTerm && (requestData.bank = bankSearchTerm);
		employerType?.value && (requestData.employerType = employerType.value);
		sector?.value && (requestData.sectorId = sector.value);
		(employerType?.value || sector?.value) && dispatch(loadAllPaymentOrders(requestData));

	}, [employerType, sector]);

	const handleSearchByBank = (event) => {
		setBankSearchTerm(event.target.value);

		let searchPass = true;
		if (event.target.value) {
			const value = event.target.value;
			if (value && !value.trim().length) {
				searchPass = false;
			}
		}

		const requestData: { tenantId: number, month: number; year: number; bank?: string, employerType?: number, } = {
			tenantId,
			month: pickedMonth + 1,
			year: pickedYear,
		};
		event?.target?.value && (requestData.bank = event.target.value);
		employerType?.value && (requestData.employerType = employerType.value);

		searchPass && dispatch(loadAllPaymentOrders(requestData));
	}

	const handleEmailChange = (e) => {
		const emailRegex = /\S+@\S+\.\S+/;
		setEmail(e.target.value);
		if (emailRegex.test(email)) {
			setIsEmailValid(true);
		} else {
			setIsEmailValid(false);
			setEmailValidMessage('Please enter a valid email!');
		}
	};

	useEffect(() => {
		const requestData: { tenantId: number, month: number; year: number; bank?: string, employerType?: number, sectorId?: number } = {
			tenantId,
			month: pickedMonth + 1,
			year: pickedYear,
			sectorId: null
		};
		bankSearchTerm && (requestData.bank = bankSearchTerm);
		employerType?.value && (requestData.employerType = employerType.value);
		dispatch(loadAllPaymentOrders(requestData));
	}, [pickedMonth, pickedYear]);

	useEffect(() => {
		showFilterContent &&
			setTimeout(() => {
				periodWrapperRef?.current && (periodWrapperRef.current.style.height = (monthsWrapperRef.current.offsetHeight + 2) + "px");
			}, 0);
	}, [showFilterContent]);


	const handleBackToReports = () => {
		history.push("/reports");
	}

	const handleOrderPaymentModal = () => {
		const requestData: { tenantId: number, month: number; year: number; bank?: string, employerType?: number, } = {
			tenantId,
			month: pickedMonth + 1,
			year: pickedYear,
		};
		bankSearchTerm && (requestData.bank = bankSearchTerm);
		employerType?.value && (requestData.employerType = employerType.value);

		dispatch(loadAllPaymentOrders(requestData));
		setIsOrderPaymentOpen(true);
	}

	const handleOpenStatisticsModal = () => {
		setIsStatisticsOpenModal(true);
	}

	const handleMonthlyStatisticsModal = () => {
		setIsMonthlyStatisticsOpen(true);
	}

	const handleAnnualSalaries = () => {
		setIsAnnualSalaries(!isAnnualSalaries);
	}


	const handlePeriodContainerClick = (event) => {
		setShowFilterContent(prev => !prev);
	}


	useEffect(() => {
		const handleOutsideMenuClick = (e: any) => {
			setShowFilterContent(!showFilterContent);
		}

		if (showFilterContent) window.addEventListener("click", handleOutsideMenuClick);
		return () => window.removeEventListener("click", handleOutsideMenuClick);
	}, [showFilterContent]);


	const handleEmailClick = (tenantId, selectedCheckboxes) => {
		dispatch(sendPaymentOrderToEmail(tenantId, pickedMonth + 1, pickedYear, selectedCheckboxes, email))
		setIsEmailOpen(false)
	};

	const handleDownloadPick = (e, item) => {
		if (item.value === 1) {
			dispatch(loadPaymentOrderExport({
				tenantId,
				employerType: employerType?.value || null,
				bank: bankSearchTerm || null,
				month: pickedMonth + 1,
				year: currentYear,
				sectorId: sector?.value || null,
			}));
		} else {
			dispatch(loadPaymentOrderPdf({
				tenantId,
				employerType: employerType?.value || null,
				bank: bankSearchTerm || null,
				month: pickedMonth + 1,
				year: currentYear,
				sectorId: sector?.value || null,
			}));
		}
	};


	const pageStyle = `
	@page {
		margin: 10px 20px;
		font-size: 15px;
	}
	.noPrint {
		display: none !important;
	}
	.footer {
		position: fixed;
		bottom: 10px;
	}
	.modalPrint {
		font-size: 13px !important;
	}
	.print-padding {
		padding: 0 20px;
	}
	.reportDropdown__indicator {
		display:none !important;
	}
	.printHeightFix {
		height: unset !important;
		min-height: unset !important;
		overflow: initial !important;
	}
	`;

	const contentRef = useRef<HTMLDivElement>(null);
	const handlePrint = useReactToPrint({
		content: () => contentRef.current,
		pageStyle: () => pageStyle,
	});
	const beforePrint = (event) => {
		handlePrint();

	}

	const handleCheckAllEmployee = () => {
		setMainCheckboxClick(prev => true);
		setMainCheckboxState(prev => !mainCheckboxState);
	}

	useEffect(() => {
		const dataLength = allPaymentOrders?.length || 0;
		const rows = selectedCheckboxes?.length || 0;
		if (dataLength) {
			(dataLength > 0 && dataLength === rows)
				?
				setMainCheckboxState(prev => true)
				:
				setMainCheckboxState(prev => false);
		}
	}, [selectedCheckboxes, allPaymentOrders]);

	useEffect(() => {
		switch (mainCheckboxState) {
			case true:
				const checkArray = [];
				mainCheckboxClick && allPaymentOrders?.forEach(item => checkArray.push(item.id));
				mainCheckboxClick && setSelectedCheckboxes(prev => checkArray);
				break;
			case false:
				mainCheckboxClick && setSelectedCheckboxes(prev => []);
				break;
		}

	}, [mainCheckboxState, mainCheckboxClick]);

	const handleCheckEmployee = (checked, id) => {
		setMainCheckboxClick(prev => false);
		checked ?
			setSelectedCheckboxes(prev => {
				return (_.union(prev, [id]));
			})
			:
			setSelectedCheckboxes(prev => {
				return selectedCheckboxes.filter(function (item) {
					return item !== id
				})
			})
	}

	const handleOpenModal = () => {
		setIsEmailOpen(false);
		setEmail("")
	};

	return (
		<AppWrapper appContentClass={styles.reportsComponent}>
			<div className={styles.header}>
				<FaChevronLeft className={styles.arrowIcon} onClick={handleBackToReports} />
				<CommonHeader title={t('reports.managmentReports')} />
			</div>
			<div className={styles.reportsInner}>
				<ReportTemplateBox name={t('reports.bankPay')} onSubmit={handleOrderPaymentModal} />
				<ReportTemplateBox name={t('reports.monthlyStatistics')} onSubmit={handleOpenStatisticsModal} />
				<ReportTemplateBox name={t('reports.monthlySalary')} onSubmit={handleMonthlyStatisticsModal} />
				<ReportTemplateBox name={t('reports.yearSalary')} onSubmit={handleAnnualSalaries} />
			</div>
			<div className={styles.modal}>
				<ModalCustom
					show={isOrderPaymentOpen}
					contentClass={styles.contentClass}
					footerClass={styles.footerClass}
					ref={contentRef}
					onClose={() => {
						setIsOrderPaymentOpen(prev => false)
					}}
					hideFooterButton={true}
				>
					<div className={styles.titleHeader}>
						<div className={styles.point}>
						</div>
						<div className={styles.orderPaymentHeader}>
							{t('reports.bankPaySalary')}
						</div>
						<div
							className={`${styles.downloadText} noPrint`}
							onClick={() => setIsDownloadModalOpen(true)}
						>
							<div className={styles.dropdownText}>
								<DropdownSelect
									data={downloadTypeOptions}
									buttonText={<div className={`${styles.dropdownText} pr-5`}>
										<div className={styles.downloadIcon}>
											<FiDownload strokeWidth="2px" />
										</div>
										{t('reports.download')}
									</div>}
									showArrow={true}
									noWidth={true}
									menuPosition="right"
									optionClick={(event, item) => {
										handleDownloadPick(event, item);
									}}
								/>
							</div>
						</div>
						<Button
							className={`${styles.downloadText} ${styles.iconText} ${styles.emailButton} noPrint`}
							onClickHandler={() => setIsEmailOpen(!isEmailOpen)}
							viewType='light'
							fixedDimensions={true}
							noHeight={true}
							noMargin={true}
						>
							{
								state.loadingEmailButton
									?
									<LoaderCentered style={{ height: "22px", width: "22px" }} />
									:
									<>
										<div className={styles.sendEmailIcon}>
											<FiDownload strokeWidth="2px" />
										</div>
										{t('salaryCalculation.sendEmail')}
									</>
							}
						</Button>

						<div className={`${styles.printIcon} noPrint`}>
							<IconDocument stroke={"#89C732"} />
						</div>
						<div
							className={`${styles.downloadText} ${styles.iconText} noPrint`}
						>
							<div
								className={styles.printText}
								onClick={(event) => {
									beforePrint(event)
								}}
							>
								{t('reports.print')}
							</div>
						</div>
						<div className={styles.headerDate}>
							Data: {todayDate}
						</div>
					</div>
					<div className={`${styles.filterRow} row v-top`} >
						<div className={`${styles.row}`}>
							<div className={`${styles.col} ${employerType?.value ? "" : "noPrint"}`}>
								<div className={styles.filterText}>
									{t('mainPage.employeer')}
								</div>
								<div className={`${styles.row} relative`}>
									<CustomSelect
										options={employerTypeOptions}
										className={`${styles.customSelect} ${styles.rowInput} ${styles.bankBoxContainer} p-0`}
										onChangeHandler={(value) => {
											setEmployerType(prev => value);
										}}
										selected={employerType}
										placeholder={t('reports.choose')}
										name="status"
										wideMenu={true}
										components={{ DropdownIndicator }}
										{...customSelectStyles}
										dropdownIndicatorStyle={{
											width: "35px",
										}}
										menuStyle={{ marginTop: 2, }}
										showIndicatorSeparator={employerType}
										placeholderStyle={{
											...customSelectStyles.placeholderStyle,
											display: "flex",
											alignItems: "center"
										}}
										controlStyle={{
											...customSelectStyles.controlStyle,
											width: "100%",
											backgroundColor: "unset",
											height: "100%",
											borderRadius: 0,
										}}
										valueContainerStyle={{
											...customSelectStyles.valueContainerStyle,
											width: "100%",
											paddingLeft: "8px",
											paddingRight: "8px",
											fontSize: "16px",
											fontWeight: "normal",
										}}
										optionsStyle={{
											...customSelectStyles.optionsStyle,
											fontSize: "16px",
											fontWeight: "normal",
										}}
									/>
									{
										employerType
											?
											<div
												className={styles.removeStatusFilter}
												title={"Hiq këtë filter"}
												onClick={(event) => {
													setEmployerType(null);
													const requestData: { tenantId: number, month: number; year: number; bank?: string, employerType?: number, } = {
														tenantId,
														month: pickedMonth + 1,
														year: pickedYear,
													};
													bankSearchTerm && (requestData.bank = bankSearchTerm);

													dispatch(loadAllPaymentOrders(requestData));
												}}
											>
												<IconX />
											</div>
											: ""
									}
								</div>
							</div>
							<div className={`${styles.col} ${bankSearchTerm.length ? "" : "noPrint"}`}>
								<div className={styles.filterText}>
									Banka
								</div>
								<DebounceInput
									id="searchInput"
									type="search"
									minLength={1}
									aria-autocomplete='none'
									autoComplete='off'
									autoFocus={false}
									debounceTimeout={200}
									value={bankSearchTerm}
									className={styles.sectorBoxContainer}
									onChange={handleSearchByBank}
								/>
							</div>
							<div className={`${styles.col} ${employerType?.value ? "" : "noPrint"}`}>
								<div className={styles.filterText}>
									{t('mainPage.sector')}
								</div>
								<div className={`${styles.row} relative`}>
									<CustomSelect
										options={allSectors}
										className={`${styles.customSelect} ${styles.rowInput} ${styles.bankBoxContainer} p-0`}
										onChangeHandler={(value) => {
											setSector(value);
										}}
										selected={sector}
										placeholder={t('reports.choose')}
										name="status"
										wideMenu={true}
										components={{ DropdownIndicator }}
										{...customSelectStyles}
										dropdownIndicatorStyle={{
											width: "35px",
										}}
										menuStyle={{ marginTop: 2, }}
										showIndicatorSeparator={sector}
										placeholderStyle={{
											...customSelectStyles.placeholderStyle,
											display: "flex",
											alignItems: "center"
										}}
										controlStyle={{
											...customSelectStyles.controlStyle,
											width: "100%",
											backgroundColor: "unset",
											height: "100%",
											borderRadius: 0,
										}}
										valueContainerStyle={{
											...customSelectStyles.valueContainerStyle,
											width: "100%",
											paddingLeft: "8px",
											paddingRight: "8px",
											fontSize: "16px",
											fontWeight: "normal",
										}}
										optionsStyle={{
											...customSelectStyles.optionsStyle,
											fontSize: "16px",
											fontWeight: "normal",
										}}
									/>
									{
										sector
											?
											<div
												className={styles.removeStatusFilter}
												title={"Hiq këtë filter"}
												onClick={(event) => {
													setSector(null);
													const requestData: { tenantId: number, month: number; year: number; bank?: string, employerType?: number, } = {
														tenantId,
														month: pickedMonth + 1,
														year: pickedYear,
														employerType
													};
													bankSearchTerm && (requestData.bank = bankSearchTerm);

													dispatch(loadAllPaymentOrders(requestData));
												}}
											>
												<IconX />
											</div>
											: ""
									}
								</div>
							</div>
						</div>
						<div className={`${styles.row} justify-end relative`}>
							<div
								className={`${styles.filterDescription} noPrint`}
							>
								{t('reports.filterPeriod')}
							</div>
							<div
								className={`${styles.filterButtons} ${backdropParent !== "calculationsPeriodFilter" ? "lowZindex" : ""}`}
								onClick={handlePeriodContainerClick}
							>
								<div className={styles.button}>
									{monthsList[pickedMonth]}
								</div>
								<div className={`${styles.button} ${styles.yearsButton}`}>
									{pickedYear}
									<div className={styles.calendar}>
										<FiCalendar />
									</div>
								</div>
							</div>
							{showFilterContent &&
								<div className={styles.filterContent} ref={periodWrapperRef}>
									<div className={styles.months} ref={monthsWrapperRef}>
										{monthsList.map((item, index) => {
											return <div
												key={index}
												className={`${styles.periodItem} ${index === pickedMonth ? styles.selected : ""}`}
												onClick={() => { setPickedMonth(prev => index) }}
											>
												{item}
											</div>
										})}
									</div>
									<div className={styles.years}>
										{yearsList.map((item, index) => {
											return <div
												key={index}
												className={`${styles.periodItem} ${item === pickedYear ? styles.selected : ""}`}
												onClick={() => { setPickedYear(prev => +item) }}
											>
												{item}
											</div>
										})}
									</div>
								</div>
							}
						</div>
					</div>
					<div
						className={styles.tableContainer}
					>
						<Table
							tableHeader={
								<thead className={`${tableStyles.tableHead} ${tableStyles.noPosition}`}>
									<tr className={tableStyles.headerRow}>
										<th className={`${tableStyles.rootCell} ${tableStyles.center} ${tableStyles.pr0} noPrint`}>
											<Checkbox
												className={styles.checkbox}
												icon={
													<div className={styles.checkboxIconContainer}>
														<FiCheck color="#261F63" size={20} />
													</div>
												}
												checked={mainCheckboxState}
												onChange={(checked) => handleCheckAllEmployee()}
											/>
										</th>
										<th className={`${tableStyles.rootCell} noPrint`}>
											{t('salaryCalculation.all')}
										</th>
									</tr>
									<tr className={tableStyles.headerRow}>
										{
											tableHeaderData && tableHeaderData.map((cell, index) => {
												const thClasses = classNames({
													"noPrint printPaddingLeft": index === 0,
													[tableStyles.headerCell]: true,
													[tableStyles.rootCell + " " + tableStyles.pr0]: index === 0,
													[tableStyles.rootCell]: index === 1,
												});
												return <th className={thClasses} key={index}>
													{cell}
												</th>
											})
										}
									</tr>
								</thead>
							}
						>
							<tbody className={`${styles.tableBody} ${tableStyles.tableBody}`}>
								{allPaymentOrders?.length > 0 && allPaymentOrders?.map((data, idx) => {
									return <tr className={styles.tableRow} key={idx}>
										<td className={`${tableStyles.rootCell} ${tableStyles.center} ${tableStyles.pr0} noPrint`}>
											<Checkbox
												className={styles.checkbox}
												icon={
													<div className={styles.checkboxIconContainer}>
														<FiCheck color="#261F63" size={20} />
													</div>
												}
												checked={selectedCheckboxes?.includes(data.employeeId)}
												onChange={(checked) => handleCheckEmployee(checked, data.employeeId)}
											/>
										</td>
										<td className={`${styles.bodyCell} ${styles.text} ${tableStyles.rootCell + " " + tableStyles.pl0}`}>
											<div>
												{data?.employeeName}
											</div>
											<div className={styles.sectorType}>
												{data?.employer === 1 ? "Primar" : "Sekondar"}
											</div>
										</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.personalNumber || "N/A"}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.bankAccountNumber || "N/A"}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.bank || "N/A"}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>Paga: {data?.month || "N/A"}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.nettoSalary || "00.00"}</td>
									</tr>
								})}
							</tbody>
						</Table>
					</div>
				</ModalCustom>
			</div>
			<MonthlyStatistics isOpen={isMonthlyStatisticsOpen} setIsMonthlyStatisticsOpen={setIsMonthlyStatisticsOpen} />
			<ReportsTable
				tableHeaderData={tableHeaderDataForAnnualSalaries}
				tableHeaderTitle={t('reports.yearSalary')}
				onPrint={beforePrint}
				data={[1, 2, 3]}
				show={isAnnualSalaries}
				setIsAnnualShowed={setIsAnnualSalaries}
			/>
			<Statistics show={isStatisticsModalOpen} setIsStatisticsShowed={setIsStatisticsOpenModal} />
			<ModalCustom
				contentClass={styles.sendEmailWrapper}
				show={isEmailOpen}
				hideFooterButton={false}
				submitLoading={isEmailOpen && isSendEmailLoading || !isEmailOpen}
				submitText={isEmailOpen ? 'Dërgo' : `${t('reports.save')}`}
				footerClass={styles.footerClass}
				onClose={handleOpenModal}
				onSubmit={() => handleEmailClick(tenantId, selectedCheckboxes)}
			>
				{isEmailOpen ? <div className={styles.emailBox} onKeyDown={(e) => {
					const inputCondition = e.key === 'Enter';
				}}>
					<InputCustom
						onChangeHandler={handleEmailChange}
						className={styles.rowInputEmail}
						name="occupation"
						value={email}
						placeholder={'Enter e-mail'}
						attributes={{ autoFocus: true, }}
						type="text"
					/>
					{!isEmailValid && email !== "" ? <span className={styles.validateEmail}>{emailValidMessage}</span> : null}
				</div> : null}
			</ModalCustom>
		</AppWrapper>
	);
}

export default ManagementReports;