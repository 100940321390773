import { FunctionComponent, useEffect, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import Table from "components/Table/Table.component";
import Tab from "components/NavTabs/NavTab.component";
import NavTabs from "components/NavTabs/NavTabs.component";
import Button from "components/Button/Button.component";
import Loader from "components/partials/Loader/Loader.component";
import AppWrapper from "components/AppWrapper/AppWrapper.component";
import CommonHeader from "components/partials/CommonHeader/CommonHeader.component";
import EmployeeModal from "./EmployeeModal/EmployeeModal.component";

import { FiLogIn } from "react-icons/fi";
import {
  AiOutlineDownload,
  AiOutlinePlusCircle,
  AiOutlineUpload,
} from "react-icons/ai";
import styles from "./Employees.module.scss";

import { Employee, EmployeeToImport } from "types/employee.type";
import { toggleModalState as toggleModalStateAppwrapper } from "components/AppWrapper/actions";
import {
  loadAllEmployees,
  loadInactiveEmployees,
  loadOnProcessEmployees,
  exportEmployees,
  toggleModalState,
  loadEmployeesTabNumbers,
  searchEmployees,
  setActiveTab,
  showEmployeeAttachments,
  setIsAddEmployeeOn,
  addEmployeeQb,
} from "./actions";
import LoaderCentered from "components/partials/LoaderCentered/LoaderCentered.component";
import { EmployeesReducer } from "types/reducers/employees.reducer.type";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import { globalState } from "types/globalState.type";
import { useTranslation } from "react-i18next";
import { QuickBooksIcon } from "assets/icons/icons";
import {
  downloadImportExcelTemplate,
  previewImportedExcel,
} from "services/employee.service";
import PreviewImportEmployeesModal from "pages/Employees/EmployeeModal/PreviewImportEmployeesModal.component";

const Employees: FunctionComponent = () => {
  const tenantId = useSelector(
    (state: globalState) => state.globalReducer.tenantId
  );
  const hasQuickbooks = useSelector(
    (state: globalState) => state.globalReducer.hasQuickbooks
  );
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootStateOrAny) => state);
  const employeesReducer: EmployeesReducer = globalState.employeesReducer;
  const tableHeaderData = [
    `${t("employees.nameAndSurname")}`,
    `${t("employees.position")}`,
    `${t("employees.telephone")}`,
    `${t("employees.emailAddress")}`,
    `${t("employees.documents")}`,
  ];
  const allEmployees: Employee[] = employeesReducer.allEmployees;
  const inactiveEmployees: Employee[] = employeesReducer.inactiveEmployees;
  const onProcessEmployees: Employee[] = employeesReducer.onProcessEmployees;
  const contentLoading: boolean = employeesReducer.loading;
  const activeTab: number = employeesReducer.activeTab;
  const exportButtonLoading: boolean = employeesReducer.exportButtonLoading;
  const appWrapperReducer = globalState.appWrapperReducer;
  const showEmployeeModal: boolean =
    appWrapperReducer.showEmployeeModal && employeesReducer.showEmployeeModal;

  const fileInputRef = useRef(null);
  const [importing, setImporting] = useState(false);
  const [employeesToImport, setEmployeesToImport] = useState<
    EmployeeToImport[]
  >([]);
  const [showImportModal, setShowImportModal] = useState(false);

  const activeEmployeesCount = employeesReducer.activeEmployeesCount;
  const inactiveEmployeesCount = employeesReducer.inactiveEmployeesCount;
  const onProcessEmployeesCount = employeesReducer.onProcessEmployeesCount;
  const showAttachmentsModal = employeesReducer.showAttachmentsModal;
  const attachmentModalItems = employeesReducer.attachmentModalItems;
  const attachmentModalName = employeesReducer.attachmentModalName;

  const setShowEmployeeModal = (state: boolean, row: any = { id: 0 }) => {
    dispatch(toggleModalState({ state, row }));
    dispatch(toggleModalStateAppwrapper({ state, row }));
  };

  const handleOnClickModal = () => {
    setShowEmployeeModal(true, { id: 0 });
    dispatch(setIsAddEmployeeOn(true));
  };

  const handleQbConnect = () => {
    dispatch(addEmployeeQb(tenantId));
  };

  const exportHandler = () => {
    dispatch(exportEmployees(tenantId, activeTab));
  };

  const employeeSearchHandler = (event) => {
    const filterEmployees = event.target.value;
    if (filterEmployees)
      dispatch(
        searchEmployees({ tenantId, filterEmployees, status: activeTab })
      );
    else {
      switch (activeTab) {
        case 1:
          dispatch(loadAllEmployees(tenantId));
          break;
        case 2:
          dispatch(loadInactiveEmployees(tenantId));
          break;
        case 3:
          dispatch(loadOnProcessEmployees(tenantId));
          break;
        default:
          break;
      }
    }
  };

  const initialLoad = () => {
    dispatch(loadAllEmployees(tenantId));
    dispatch(loadEmployeesTabNumbers(tenantId));
  };

  const tabClickHandler = (index, isSelected) => {
    dispatch(setActiveTab(index + 1));
  };

  useEffect(() => {
    switch (activeTab) {
      case 0:
        dispatch(setActiveTab(1));
        break;
      case 1:
        dispatch(loadAllEmployees(tenantId));
        break;
      case 2:
        dispatch(loadInactiveEmployees(tenantId));
        break;
      case 3:
        dispatch(loadOnProcessEmployees(tenantId));
        break;
      default:
        break;
    }

    activeTab
      ? dispatch(loadEmployeesTabNumbers(tenantId, activeTab))
      : dispatch(loadEmployeesTabNumbers(tenantId));
  }, [activeTab, dispatch, tenantId]);

  useEffect(initialLoad, []);

  const handleFileChange = async (event) => {
    setShowImportModal(true);
    setImporting(true);
    const file = event.target.files[0];

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", tenantId.toString());
      const response = await previewImportedExcel(formData);
      setEmployeesToImport(response.data.value);
    } catch (err) {
      console.error("Error uploading the file:", err);
    }
    setImporting(false);
  };

  return (
    <div>
      <AppWrapper appContentClass={styles.appContent}>
        <div className={`${styles.employeesContainer}`}>
          <CommonHeader title={t("employees.employees")}>
            <Button
              onClickHandler={exportHandler}
              className={styles.headerButton}
              loading={exportButtonLoading}
              viewType="shell"
            >
              <div className={`${styles.iconContainer}`}>
                <FiLogIn />
              </div>
              <div className={`${styles.buttonTitle}`}>
                {t("employees.export")}
              </div>
            </Button>
            {hasQuickbooks === 1 && (
              <Button
                onClickHandler={() => handleQbConnect()}
                className={`${styles.headerButton} ${styles.employeesModalButton}`}
                viewType="shell"
              >
                <div className={`${styles.iconContainer}`}>
                  <QuickBooksIcon stroke="#89c732" width="1px" />
                </div>
                <div className={`${styles.buttonTitle}`}>
                  Lidh me Quickbooks
                </div>
              </Button>
            )}

            <div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Button
                onClickHandler={() => {
                  fileInputRef.current.click();
                }}
                className={`${styles.headerButton} ${styles.employeesModalButton}`}
                viewType="shell"
              >
                <div className={`${styles.iconContainer}`}>
                  <AiOutlineUpload />
                </div>
                <div className={`${styles.buttonTitle}`}>
                  {t("employees.importFromExcel")}
                </div>
              </Button>
            </div>
            <Button
              onClickHandler={async () => {
                try {
                  const response = await downloadImportExcelTemplate();
                  const blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  });

                  // Create a link element, set its href to the blob URL, and click it to trigger the download
                  const link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = "Template.xlsx";
                  link.click();

                  // Clean up and revoke the object URL
                  window.URL.revokeObjectURL(link.href);
                } catch (err) {
                  console.error("Error downloading the Excel template:", err);
                }
              }}
              className={`${styles.headerButton} ${styles.employeesModalButton}`}
              viewType="shell"
            >
              <div className={`${styles.iconContainer}`}>
                <AiOutlineDownload />
              </div>
              <div className={`${styles.buttonTitle}`}>
                {t("employees.downloadImportExcelTemplate")}
              </div>
            </Button>
            <Button
              onClickHandler={() => handleOnClickModal()}
              className={`${styles.headerButton} ${styles.employeesModalButton}`}
              viewType="shell"
            >
              <div className={`${styles.iconContainer}`}>
                <AiOutlinePlusCircle />
              </div>
              <div className={`${styles.buttonTitle}`}>
                {t("employees.addEmployee")}
              </div>
            </Button>
          </CommonHeader>
          <div className={styles.tableContainer}>
            <NavTabs
              tabClickHandler={tabClickHandler}
              showSearch={true}
              searchChangeHandler={employeeSearchHandler}
              tabRoot={"employeesPage"}
            >
              <Tab
                tabContentClass={styles.tabContentClass}
                title={
                  activeEmployeesCount != null ? (
                    `${t("employees.all")} (${activeEmployeesCount})`
                  ) : (
                    <div className={`center ${styles.tabHeader}`}>
                      <span className={`pr-5`}>{t("employees.all")}</span>
                      <LoaderCentered
                        style={{ width: "20px", height: "20px" }}
                      />
                    </div>
                  )
                }
              >
                {!allEmployees || contentLoading ? (
                  <div className={styles.loaderWrapper}>
                    {" "}
                    <Loader />{" "}
                  </div>
                ) : contentLoading ? (
                  ""
                ) : (
                  <Table
                    data={allEmployees}
                    headerClass={styles.tableHeader}
                    headerData={tableHeaderData}
                  />
                )}
              </Tab>
              <Tab
                title={
                  inactiveEmployeesCount != null ? (
                    `${t("employees.notActive")}(${inactiveEmployeesCount})`
                  ) : (
                    <div className={`center ${styles.tabHeader}`}>
                      <span className={`pr-5`}>{t("employees.notActive")}</span>
                      <LoaderCentered
                        style={{ width: "20px", height: "20px" }}
                      />
                    </div>
                  )
                }
                tabContentClass={styles.tabContentClass}
              >
                {!inactiveEmployees || contentLoading ? (
                  <div className={styles.loaderWrapper}>
                    {" "}
                    <Loader />{" "}
                  </div>
                ) : contentLoading ? (
                  ""
                ) : (
                  <Table
                    data={inactiveEmployees}
                    headerClass={styles.tableHeader}
                    headerData={tableHeaderData}
                  />
                )}
              </Tab>
              <Tab
                title={
                  onProcessEmployeesCount != null ? (
                    `${t("employees.inProcess")} (${onProcessEmployeesCount})`
                  ) : (
                    <div className={`center ${styles.tabHeader}`}>
                      <span className={`pr-5`}>{t("employees.inProcess")}</span>
                      <LoaderCentered
                        style={{ width: "20px", height: "20px" }}
                      />
                    </div>
                  )
                }
                tabContentClass={styles.tabContentClass}
              >
                {!onProcessEmployees || contentLoading ? (
                  <div className={styles.loaderWrapper}>
                    {" "}
                    <Loader />{" "}
                  </div>
                ) : contentLoading ? (
                  ""
                ) : (
                  <Table
                    data={onProcessEmployees}
                    headerClass={styles.tableHeader}
                    headerData={tableHeaderData}
                  />
                )}
              </Tab>
            </NavTabs>
          </div>
        </div>
      </AppWrapper>
      <EmployeeModal
        showEmployeeModal={showEmployeeModal}
        setShowEmployeeModal={setShowEmployeeModal}
      />
      <PreviewImportEmployeesModal
        importing={importing}
        showModal={showImportModal}
        onCloseModal={(saved) => {
          fileInputRef.current.value = null;
          setShowImportModal(false);
          if(saved) {
            initialLoad();
          }
        }}
        employees={employeesToImport}
      />

      <ModalCustom
        show={showAttachmentsModal}
        submitText="Mbyll"
        modalMainStyle={styles.attachmentModal}
        contentClass={styles.content}
        onSubmit={() => {
          dispatch(showEmployeeAttachments({ show: false, items: [] }));
        }}
        onClose={() => {
          dispatch(showEmployeeAttachments({ show: false, items: [] }));
        }}
        header={
          <div className={styles.attachmentsHeader}>
            {t("employees.documents")}
            <small> ({attachmentModalName}) </small>
          </div>
        }
      >
        {attachmentModalItems &&
          attachmentModalItems.length &&
          attachmentModalItems.map((item) => {
            return (
              <div className={styles.row} key={item.id}>
                <div className={styles.nameWrapper}>
                  <div className={styles.name}>
                    <a
                      href={`${process.env.REACT_APP_API_URL_V2}/Files/Attachments/${item.fileName}`}
                      target="_blank"
                      rel="noreferrer"
                      title={item.fileName}
                    >
                      {item.fileName}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </ModalCustom>
    </div>
  );
};

export default Employees;
