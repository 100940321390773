import { axiosInstance } from "api"

export const saveDocumentsTemplate = (payload: any) => {
	return axiosInstance().post(`/document/createDocument`, payload,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}
	)
}

export const loadAllDocuments = (tenantId) => {
	return axiosInstance().get(`/document/getDocumentData?tenantId=${tenantId}`);
}

export const deleteSelectedDocumentsTemplate = (payload: Array<number>) => {
	return axiosInstance().post(`/document/deleteDocument`, payload);
}

export const updateDocument = (payload: any) => {
	return axiosInstance().post(`/document/updateDocument`, payload);
}

export const downloadDocument = (payload: any) => {
	return axiosInstance().post(`/document/getPath`, payload);
}

export const sendEmail = (payload: any) => {
	return axiosInstance().post(`/document/sendEmail`, payload);
}
