import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { ToastContainer } from 'react-toastify';
import { FiMail, FiLock, FiCheck } from 'react-icons/fi';
import Checkbox from "react-custom-checkbox";
import { useHistory } from 'react-router-dom';
import InputCustom from "components/InputCustom/InputCustom.component";
import Card from "components/Card/Card.component";
import Button from 'components/Button/Button.component';
import { globalState } from 'types/globalState.type';
import styles from "./Login.module.scss";
import { logIn, resetFailMessage, setEmail, getUserByEmail, logInSuccess } from 'App/actions';
import { useSelector } from 'react-redux';
import * as actions from '../../pages/Configurations/actions';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoaderCentered from 'components/partials/LoaderCentered/LoaderCentered.component';
import Loader from 'components/partials/Loader/Loader.component';
import 'react-toastify/dist/ReactToastify.css';
import ModalCustom from 'components/ModalCustom/ModalCustom.component';
import { OTPInput, SlotProps } from 'components/InputOTP';
import { confirmTwoFactorAuthenticationCode } from 'services/users.service';

interface LoginProps {
	logIn?: (userName: string, password: string) => void;
	loginFail?: boolean;
	loading?: boolean;
}

function FakeCaret() {
	return (
		<div className={styles.fakeCaretContainer}>
			<div className={styles.fakeCaret} />
		</div>
	)
}
function FakeDash() {
	return (
		<div className={styles.fakeDashContainer}>
			<div className={styles.fakeDash} />
		</div>
	);
}

function Slot({ isActive, char, hasFakeCaret }: SlotProps) {
	return (
		<div
			className={`${styles.slotContainer} ${isActive ? styles.active : ''}`}
		>
			{char !== null && <div>{char}</div>}
			{hasFakeCaret && <FakeCaret />}
		</div>
	);
}

const LoginForm: React.FC<LoginProps> = (props: LoginProps) => {
	const { t, i18n } = useTranslation('common');
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = useLocation();
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [showTwoFactorInput, setShowTwoFactorInput] = useState(false);
	const [twoFactorCode, setTwoFactorCode] = useState("");
	const [confirmingTwoFactor, setConfirmingTwoFactor] = useState(false);

	const state = useSelector((state: globalState) => state);
	const globalState = useSelector((state: globalState) => state.globalReducer);
	const isLoading = useSelector((state: globalState) => state.globalReducer?.loading);

	const otpInput = useRef(null);

	const loginHandler = () => {
		props.logIn(userName, password)
		dispatch(setEmail(userName))
		dispatch(getUserByEmail(userName))
	}

	const afterLoginHandler = (event) => {
		event.preventDefault()
	}

	const emailInputRef = useRef(null);
	const inputPasswordRef = useRef(null);


	useEffect(() => {
		dispatch(resetFailMessage());
		emailInputRef.current.focus();

		localStorage.removeItem('forgotPasswordPayload');

		const query = new URLSearchParams(history.location.search);
		const twoFactor = query.get('twoFactor');
		if (twoFactor != null) {
			setShowTwoFactorInput(true);
			otpInput.current.focus();
		}

	}, [dispatch, history.location.search, search]);

	return (
		<form
			className={styles.form}
			id="loginForm"
			onKeyDown={(e) => {
				const inputCondition = (document.activeElement === emailInputRef.current.current || document.activeElement === inputPasswordRef.current.current) && e.key === "Enter";
				inputCondition && loginHandler();
			}}
		>
			<ModalCustom show={showTwoFactorInput}
				onClose={() => setShowTwoFactorInput(false)}
				onSubmit={async () => {
					const tempAuth = JSON.parse(localStorage.getItem('tempAuth'));
					if (tempAuth == null) {
						return;
					}
					setConfirmingTwoFactor(true);
					const response = await confirmTwoFactorAuthenticationCode(tempAuth.user.id, twoFactorCode);
					if (response.data.value == true) {
						dispatch(logInSuccess(tempAuth));
						localStorage.setItem('token', tempAuth.token);
						if (
							(tempAuth.user?.roleId === 3 ||
								tempAuth.user?.roleId === 4) &&
							tempAuth.user?.agreed !== null
						) {
							history.push("/companyLogin");
						} else if (tempAuth.user?.agreed === null) {
							history.push("/licenseAgreement");
						} else {
							history.push("/");
						}
					}
					setConfirmingTwoFactor(false);
				}}
				submitLoading={confirmingTwoFactor}
				showSubmitButton={twoFactorCode.length === 6}
				header={
					<div>
						<div className={styles.modalTitle}>Konfirmimi i kodit te Two-Factor</div>
					</div>
				}
				submitText='Konfirmo'
			>
				<div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', padding: '15px' }}>
					<OTPInput ref={otpInput} maxLength={6} render={({ slots }) => (
						<>
							<div style={{ display: 'flex' }}>
								{slots.slice(0, 3).map((slot, idx) => (
									<Slot key={idx} {...slot} />
								))}
							</div>

							<FakeDash />

							<div style={{ display: 'flex' }}>
								{slots.slice(3).map((slot, idx) => (
									<Slot key={idx} {...slot} />
								))}
							</div>
						</>
					)}
						onChange={(otp) => {
							setTwoFactorCode(otp);
						}}
					/>

				</div>
			</ModalCustom>
			<Card
				className={styles.card}
				cardContentClass={styles.cardContentClass}
			>
				<div className={styles.header}>
					<h4>Payshef</h4>
					<p>{t('login.welcome')}</p>
				</div>
				<InputCustom
					placeholder="Emri i Përdoruesit ose Email-i"
					className={styles.loginInput}
					value={userName}
					onChangeHandler={(e) => setUserName(username => e.target.value)}
					ref={emailInputRef}
					iconStyle={styles.inputIcon}
					attributes={{
						id: "emailInput"
					}}
				>
					<FiMail />
				</InputCustom>
				<InputCustom
					placeholder="Fjalëkalimi"
					type="password"
					className={styles.loginInput}
					value={password}
					onChangeHandler={(e) => setPassword(password => e.target.value)}
					ref={inputPasswordRef}
					iconStyle={styles.inputIcon}
				>
					<FiLock />
				</InputCustom>
				{!isLoading ?
					<div className={`row spaceBetween`}>
						<div>
						</div>
						<div
							className={styles.forgotPassword}
							onClick={(event) => {
								dispatch(actions.sendForgotPasswordEmail(userName))
							}}
						>
							{t('login.forgotPassword')}
						</div>
					</div>
					:
					<div className={`row spaceBetween`}>
						<Loader className={styles.loading} />
					</div>
				}
				{props.loginFail ?
					<div className={`${styles.loginMessage} row spaceBetween`}>
						<div> Emri i përdoruesit ose fjalëkalimi është i pasaktë! </div>
					</div>
					:
					""
				}
				<Button
					onClickHandler={(event) => { loginHandler(); afterLoginHandler(event) }}
					className={styles.signInButton}
					loading={props.loading}
					fixDimensions={true}
				>
					Identifikohu
				</Button>
			</Card>
			<ToastContainer position="bottom-left" />
		</form >
	);
};

const mapDispatchToProps = (dispatch: any): any => {
	return bindActionCreators<any, any>({
		logIn
	}, dispatch);
}

const mapStateToProps = (state) => {
	return {
		loginFail: state.globalReducer.loginFail,
		loading: state.globalReducer.loading,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(
	withConnect)(LoginForm)

