
import {
	LOAD_ANNUAL_CERTIFICATE,
	LOAD_ANNUAL_CERTIFICATE_SUCCESS,
	LOAD_ANNUAL_CERTIFICATE_ERROR,
	LOAD_EMPLOYEE_DATA,
	LOAD_EMPLOYEE_DATA_SUCCESS,
	LOAD_EMPLOYEE_DATA_ERROR,
	CERTIFICATE_PDF,
	CERTIFICATE_PDF_SUCCESS,
	CERTIFICATE_PDF_ERROR,
	EXPORT_CERTIFICATE,
	EXPORT_CERTIFICATE_SUCCESS,
	EXPORT_CERTIFICATE_ERROR,
	EMAIL_CERTIFICATE,
	EMAIL_CERTIFICATE_SUCCESS,
	EMAIL_CERTIFICATE_ERROR,
	LOAD_VACATION_LIST,
	LOAD_VACATION_LIST_SUCCESS,
	LOAD_VACATION_LIST_ERROR,
	EXPORT_EXCEL_VACATION_LIST,
	EMAIL_VACATION_LIST_SUCCESS,
	EMAIL_VACATION_LIST,
	EMAIL_VACATION_LIST_ERROR,
	EXPORT_EXCEL_VACATION_LIST_SUCCESS,
	EXPORT_EXCEL_VACATION_LIST_ERROR,
	VACATION_LIST_PDF,
	VACATION_LIST_PDF_SUCCESS,
	VACATION_LIST_PDF_ERROR,
} from "./constants";

export const loadAnnualCertificate = (tenantId) => {
	return {
		type: LOAD_ANNUAL_CERTIFICATE,
		payload: {
			tenantId
		},
	}
}

export const loadAnnualCertificateSuccess = (payload) => {
	return {
		type: LOAD_ANNUAL_CERTIFICATE_SUCCESS,
		payload
	}
}

export const loadAnnualCertificateError = (payload) => {
	return {
		type: LOAD_ANNUAL_CERTIFICATE_ERROR,
		payload
	}
}

export const loadEmployeeData = (employeeId?: number) => {
	return {
		type: LOAD_EMPLOYEE_DATA,
		payload: {
			employeeId
		}

	}
}

export const loadEmployeeDataSuccess = (payload) => {
	return {
		type: LOAD_EMPLOYEE_DATA_SUCCESS,
		payload
	}
}

export const loadEmployeeDataError = (payload) => {
	return {
		type: LOAD_EMPLOYEE_DATA_ERROR,
		payload
	}
}

export const certificatePdf = (tenantId: number, employeeId: number) => {
	return {
		type: CERTIFICATE_PDF,
		payload: {
			tenantId,
			employeeId,
		}
	}
}
export const certificatePdfSuccess = (payload) => {
	return {
		type: CERTIFICATE_PDF_SUCCESS,
		payload
	}
}
export const certificatePdfError = (payload) => {
	return {
		type: CERTIFICATE_PDF_ERROR,
		payload
	}
}

export const exportCertificate = (tenantId, employeeId) => {
	return {
		type: EXPORT_CERTIFICATE,
		payload: {
			tenantId,
			employeeId,
		}
	}
}
export const exportCertificateSuccess = (payload) => {
	return {
		type: EXPORT_CERTIFICATE_SUCCESS,
		payload
	}
}
export const exportCertificateError = (payload) => {
	return {
		type: EXPORT_CERTIFICATE_ERROR,
		payload
	}
}

export const emailCertificate = (tenantId, employeeId, email) => {
	return {
		type: EMAIL_CERTIFICATE,
		payload: {
			tenantId,
			employeeId,
			email
		}
	}
}
export const emailCertificateSuccess = (payload) => {
	return {
		type: EMAIL_CERTIFICATE_SUCCESS,
		payload
	}
}
export const emailCertificateError = (payload) => {
	return {
		type: EMAIL_CERTIFICATE_ERROR,
		payload
	}
}

export const loadVacationList = (tenantId, from, to) => {
	return {
		type: LOAD_VACATION_LIST,
		payload: {
			tenantId,
			from,
			to,
		}
	}
}
export const loadVacationListSuccess = (payload) => {
	return {
		type: LOAD_VACATION_LIST_SUCCESS,
		payload
	}
}
export const loadVacationListError = (payload) => {
	return {
		type: LOAD_VACATION_LIST_ERROR,
		payload
	}
}

export const emailVacationList = (payload) => {
	return {
		type: EMAIL_VACATION_LIST,
		payload
	}
}
export const emailVacationListSuccess = (payload) => {
	return {
		type: EMAIL_VACATION_LIST_SUCCESS,
		payload
	}
}
export const emailVacationListError = (payload) => {
	return {
		type: EMAIL_VACATION_LIST_ERROR,
		payload
	}
}

export const exportExcelVacationList = (tenantId, month, year) => {
	return {
		type: EXPORT_EXCEL_VACATION_LIST,
		payload: {
			tenantId,
			month,
			year
		}
	}
}
export const exportExcelVacationListSuccess = (payload) => {
	return {
		type: EXPORT_EXCEL_VACATION_LIST_SUCCESS,
		payload
	}
}
export const exportExcelVacationListError = (payload) => {
	return {
		type: EXPORT_EXCEL_VACATION_LIST_ERROR,
		payload
	}
}

export const vacationListPdf = (tenantId, from, to) => {
	return {
		type: VACATION_LIST_PDF,
		payload: {
			tenantId,
			from,
			to
		}
	}
}
export const vacationListPdfSuccess = (payload) => {
	return {
		type: VACATION_LIST_PDF_SUCCESS,
		payload
	}
}
export const vacationListPdfError = (payload) => {
	return {
		type: VACATION_LIST_PDF_ERROR,
		payload
	}
}