import { useTranslation } from "react-i18next";
import produce from "immer";
import {
  DOWNLOAD_ANNUAL_SALARIES_EXCEL,
  DOWNLOAD_ANNUAL_SALARIES_EXCEL_SUCCESS,
  DOWNLOAD_ANNUAL_SALARIES_EXCEL_ERROR,
  DOWNLOAD_ANNUAL_SALARIES_PDF_ERROR,
  DOWNLOAD_ANNUAL_SALARIES_PDF_SUCCESS,
  DOWNLOAD_ANNUAL_SALARIES_PDF,
  LOAD_ANNUAL_SALARIES,
  LOAD_ANNUAL_SALARIES_ERROR,
  LOAD_ANNUAL_SALARIES_SUCCESS,
  SET_EMPLOYEES_CHECKED,
  SEND_ANNUAL_SALARY_EMAIL,
  SEND_ANNUAL_SALARY_EMAIL_SUCCESS,
  SEND_ANNUAL_SALARY_EMAIL_ERROR,
} from "./constants";
import { toast } from "react-toastify";

const initialState: any = {
  loading: false,
  emailLoading: false,
  isExcelAnnualSalariesLoading: false,
  isPdfAnnualSalariesLoading: false,
  allAnnualSalaries: [],
};

const annualSalariesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_ANNUAL_SALARIES:
        draft.loading = true;
        break;
      case LOAD_ANNUAL_SALARIES_SUCCESS:
        const newAnnualSalaries = {
          ...action.payload.res,
          employees: action.payload.res.employees.map((item) => ({
            ...item,
            isChecked: false,
          })),
        };

        draft.allAnnualSalaries = newAnnualSalaries;
        draft.loading = false;
        break;
      case LOAD_ANNUAL_SALARIES_ERROR:
        draft.loading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case DOWNLOAD_ANNUAL_SALARIES_PDF:
        draft.isPdfAnnualSalariesLoading = true;
        break;
      case DOWNLOAD_ANNUAL_SALARIES_PDF_SUCCESS:
        window.open(action.payload.downloadFile.data, "_blank");
        draft.isPdfAnnualSalariesLoading = false;
        break;
      case DOWNLOAD_ANNUAL_SALARIES_PDF_ERROR:
        draft.isPdfAnnualSalariesLoading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case DOWNLOAD_ANNUAL_SALARIES_EXCEL:
        draft.isExcelAnnualSalariesLoading = true;
        break;
      case DOWNLOAD_ANNUAL_SALARIES_EXCEL_SUCCESS:
        window.open(action.payload.downloadFile.data, "_blank");
        draft.isExcelAnnualSalariesLoading = false;
        break;
      case DOWNLOAD_ANNUAL_SALARIES_EXCEL_ERROR:
        draft.isExcelAnnualSalariesLoading = false;
        toast.error("Diçka shkoi gabim!");
        break;

      case SEND_ANNUAL_SALARY_EMAIL:
        draft.emailLoading = true;
        break;
      case SEND_ANNUAL_SALARY_EMAIL_SUCCESS:
        toast.success("Emailet u dërguan me sukses!");
        draft.emailLoading = false;
        break;
      case SEND_ANNUAL_SALARY_EMAIL_ERROR:
        draft.emailLoading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case SET_EMPLOYEES_CHECKED:
        draft.allAnnualSalaries.map((item) => {
          item.isChecked = action.payload.checked;
        });
        break;
      default:
        break;
    }
  });

export default annualSalariesReducer;
