import { OrderPaymentReport } from "../../../types/reports.type";
import {
	LOAD_ORDER_PAYMENT,
	LOAD_ORDER_PAYMENT_SUCCESS,
	LOAD_ORDER_PAYMENT_ERROR,
	LOAD_ORDER_PAYMENT_EXPORT_EXCEL,
	LOAD_ORDER_PAYMENT_EXPORT_EXCEL_SUCCESS,
	LOAD_ORDER_PAYMENT_EXPORT_EXCEL_ERROR,
	LOAD_ORDER_PAYMENT_EXPORT_PDF,
	LOAD_ORDER_PAYMENT_EXPORT_PDF_SUCCESS,
	LOAD_ORDER_PAYMENT_EXPORT_PDF_ERROR,
	SEND_EMAIL_PAYMENT_ORDER,
	SEND_EMAIL_PAYMENT_ORDER_SUCCESS,
	SEND_EMAIL_PAYMENT_ORDER_ERROR,
} from "./constants";

export const loadAllPaymentOrders = (payload) => {
	return {
		type: LOAD_ORDER_PAYMENT,
		payload
	}
}

export const loadAllPaymentOrdersSuccess = (allPaymentOrders: OrderPaymentReport[]) => {
	return {
		type: LOAD_ORDER_PAYMENT_SUCCESS,
		payload: {
			allPaymentOrders
		}
	}
}

export const loadAllPaymentOrdersError = () => {
	return {
		type: LOAD_ORDER_PAYMENT_ERROR,
		payload: {
		}
	}
}

export const loadPaymentOrderExport = (payload) => {
	return {
		type: LOAD_ORDER_PAYMENT_EXPORT_EXCEL,
		payload
	}
}

export const loadPaymentOrderExportSuccess = (excelFile) => {
	return {
		type: LOAD_ORDER_PAYMENT_EXPORT_EXCEL_SUCCESS,
		payload: {
			excelFile
		}
	}
}

export const loadPaymentOrderExportError = () => {
	return {
		type: LOAD_ORDER_PAYMENT_EXPORT_EXCEL_ERROR,
		payload: {
		}
	}
}

export const loadPaymentOrderPdf = (payload) => {
	return {
		type: LOAD_ORDER_PAYMENT_EXPORT_PDF,
		payload
	}
}

export const loadPaymentOrderPdfSuccess = (pdfFile) => {
	return {
		type: LOAD_ORDER_PAYMENT_EXPORT_PDF_SUCCESS,
		payload: {
			pdfFile
		}
	}
}

export const loadPaymentOrderPdfError = () => {
	return {
		type: LOAD_ORDER_PAYMENT_EXPORT_PDF_ERROR,
		payload: {
		}
	}
}

export const sendPaymentOrderToEmail = (tenantId: number, month: number, year: number, employeeIds: Array<number>, email: string) => {
	return {
		type: SEND_EMAIL_PAYMENT_ORDER,
		payload: {
			tenantId,
			month,
			year,
			employeeIds,
			email
		}
	}
}

export const sendPaymentOrderToEmailSuccess = (data) => {
	return {
		type: SEND_EMAIL_PAYMENT_ORDER_SUCCESS,
		payload: {
			data
		}
	}
}

export const sendPaymentOrderToEmailError = (error) => {
	return {
		type: SEND_EMAIL_PAYMENT_ORDER_ERROR,
		payload: {
			error
		}
	}
}