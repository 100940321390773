import {
  LOAD_CONTRIBUTIONS,
  LOAD_CONTRIBUTIONS_SUCCESS,
  LOAD_CONTRIBUTIONS_ERROR,
  DOWNLOAD_CONTRIBUTIONS_PDF,
  DOWNLOAD_CONTRIBUTIONS_PDF_SUCCESS,
  DOWNLOAD_CONTRIBUTIONS_PDF_ERROR,
  DOWNLOAD_CONTRIBUTIONS_EXCEL,
  DOWNLOAD_CONTRIBUTIONS_EXCEL_SUCCESS,
  DOWNLOAD_CONTRIBUTIONS_EXCEL_ERROR,
  SET_EMPLOYEES_CHECKED,
  SEND_CONTRIBUTIONS_LIST_EMAIL,
  SEND_CONTRIBUTIONS_LIST_EMAIL_SUCCESS,
  SEND_CONTRIBUTIONS_LIST_EMAIL_ERROR,
} from "./constants";

export const loadAllContributions = (
  tenantId: number,
  year: number,
  employeeId?: number
) => {
  return {
    type: LOAD_CONTRIBUTIONS,
    payload: {
      tenantId,
      year,
      employeeId,
    },
  };
};

export const loadAllContributionsSuccess = (contributions) => {
  return {
    type: LOAD_CONTRIBUTIONS_SUCCESS,
    payload: {
      contributions,
    },
  };
};

export const loadAllContributionsError = (error) => {
  return {
    type: LOAD_CONTRIBUTIONS_ERROR,
    payload: {
      error,
    },
  };
};

export const downloadContributionsPdf = (
  tenantId: number,
  year: number,
  employeeId: number
) => {
  return {
    type: DOWNLOAD_CONTRIBUTIONS_PDF,
    payload: {
      tenantId,
      year,
      employeeId,
    },
  };
};

export const downloadContributionsPdfSuccess = (contributions) => {
  return {
    type: DOWNLOAD_CONTRIBUTIONS_PDF_SUCCESS,
    payload: {
      contributions,
    },
  };
};

export const downloadContributionsPdfError = (error) => {
  return {
    type: DOWNLOAD_CONTRIBUTIONS_PDF_ERROR,
    payload: {
      error,
    },
  };
};

export const downloadContributionsExcel = (
  tenantId: number,
  year: number,
  employeeId: number
) => {
  return {
    type: DOWNLOAD_CONTRIBUTIONS_EXCEL,
    payload: {
      tenantId,
      year,
      employeeId,
    },
  };
};

export const downloadContributionsExcelSuccess = (contributions) => {
  return {
    type: DOWNLOAD_CONTRIBUTIONS_EXCEL_SUCCESS,
    payload: {
      contributions,
    },
  };
};

export const downloadContributionsExcelError = (error) => {
  return {
    type: DOWNLOAD_CONTRIBUTIONS_EXCEL_ERROR,
    payload: {
      error,
    },
  };
};

export const setEmployeesChecked = (checked) => {
  return {
    type: SET_EMPLOYEES_CHECKED,
    payload: {
      checked,
    },
  };
};

export const sendEmployeesEmail = (
  tenantId: number,
  employeeIds: Array<number>,
  email: string
) => {
  return {
    type: SEND_CONTRIBUTIONS_LIST_EMAIL,
    payload: {
      tenantId,
      employeeIds,
      email,
    },
  };
};

export const sendEmployeesEmailSuccess = (data) => {
  return {
    type: SEND_CONTRIBUTIONS_LIST_EMAIL_SUCCESS,
    payload: {
      data,
    },
  };
};

export const sendEmployeesEmailError = (error) => {
  return {
    type: SEND_CONTRIBUTIONS_LIST_EMAIL_ERROR,
    payload: {
      error,
    },
  };
};
