import { axiosInstance } from "api"

export const getNotificationsByTenantId = () => {
    return axiosInstance().get(`notification/getNotifications`);
}

export const getNewestNotifications = (page: number, canSeeRead: boolean) => {
    return axiosInstance().get(`notification/getNewestNotifications/${canSeeRead}/?page=${page}`);
}

export const updateNotificationById = (notificationId :string) => {
    return axiosInstance().put(`notification/update/${notificationId}`);
}

export const updateAllUserNotifications = () => {
    return axiosInstance().put(`notification/updateNotifications`);
}
