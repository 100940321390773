import { axiosInstance } from "api";

export const getOrderPayments = (payload) => {
  return axiosInstance().post(`/reports/paymentOrderWithFilter`, payload);
};

export const exportContributionsExcel = (
  tenantId: number,
  year: number,
  employeeId: number
) => {
  const payload = {
    tenantId,
    year,
    employeeId,
  };
  return axiosInstance().post(`/reports/statisticsExcel`, payload);
};

export const exportContributionsPdf = (
  tenantId: number,
  year: number,
  employeeId: number
) => {
  const payload = { year, tenantId, employeeId };
  return axiosInstance().post(`/reports/statisticsPdf`, payload);
};

export const getEDIData = (tenantId: number, month: number, year: number) => {
  return axiosInstance().get(
    `/reports/EDIData?month=${month}&year=${year}&tenantId=${tenantId}`
  );
};

export const sendContributeListToMail = (
  tenantId: number,
  employeeIds: number[],
  email: string
) => {
  const payload = { tenantId, employeeIds, email };
  return axiosInstance().post(`/reports/sendContributeListToMail`, payload);
};

export const getAnnualSalary = (tenantId: number, year: number) => {
  const payload = { tenantId, year };

  return axiosInstance().post(`/reports/annualSalaryWithFilter`, payload);
};

export const downloadAnnualSalariesExcelService = (
  tenantId: number,
  year: number
) => {
  const payload = {
    tenantId,
    year,
  };

  return axiosInstance().post(`/reports/exportAnnualSalary`, payload);
};

export const downloadAnnualSalariesPdfService = (
  tenantId: number,
  year: number
) => {
  const payload = {
    tenantId,
    year,
  };

  return axiosInstance().post(`/reports/annualSalaryPdf`, payload);
};

export const sendPaymentOrderToEmail = (
  tenantId: number,
  month: number,
  year: number,
  employeeIds: number[],
  email: string
) => {
  const payload = { tenantId, month, year, employeeIds, email };
  return axiosInstance().post(`/reports/sendPaymentOrderToMail`, payload);
};
export const sendMonthlySalaryToMail = (
  tenantId: number,
  month: number,
  year: number,
  employeeIds: number[]
) => {
  const payload = { tenantId, month, year, employeeIds };
  return axiosInstance().post(`/reports/sendMonthlySalaryToMail`, payload);
};
export const sendAnnualSalaryToMail = (
  tenantId: number,
  year: number,
  employeeIds: number[],
  email: string
) => {
  const payload = { tenantId, year, employeeIds, email };
  return axiosInstance().post(`/reports/sendAnnualSalaryToMail`, payload);
};

export const getMonthlyStatistics = (
  tenantId: number,
  searchTerm: any,
  month: any,
  year: any
) => {
  const payload = {
    tenantId,
    name: searchTerm,
    month: month,
    year: year,
  };

  return axiosInstance().post(`/reports/monthlySalary`, payload);
};

export const getContributions = (
  tenantId: number,
  year: number,
  employeeId: number
) => {
  const payload = { year, tenantId, employeeId };
  return axiosInstance().post(`/reports/statistics`, payload);
};

export const exportExcel = (
  tenantId: number,
  month: number,
  year: number,
  name?: string
) => {
  let payload: {
    tenantId: number;
    month: number;
    year: number;
    name?: string;
  } = {
    tenantId,
    month,
    year,
  };
  name && (payload.name = name);

  return axiosInstance().post(`/reports/exportMonthlySalary`, payload);
};

export const exportPdf = (
  tenantId: number,
  month: number,
  year: number,
  name?: string
) => {
  let payload: {
    tenantId: number;
    month: number;
    year: number;
    name?: string;
  } = {
    tenantId,
    month,
    year,
  };
  name && (payload.name = name);
  return axiosInstance().post(`/reports/monthlySalaryPdf`, payload);
};

export const exportPaymentOrderExcel = (
  tenantId: number,
  employerType?: number,
  bank?: string,
  sectorId?: number,
  month?: number,
  year?: number
) => {
  let payload: {
    tenantId: number;
    employerType?: number;
    bank?: string;
    sectorId?: number;
    month?: number;
    year?: number;
  } = {
    tenantId,
    sectorId,
    month,
    year,
  };

  employerType && (payload.employerType = employerType);
  bank && (payload.bank = bank);

  return axiosInstance().post(`/reports/exportPaymentOrder`, payload);
};

export const exportPaymentOrderPdf = (
  tenantId: number,
  employerType?: number,
  bank?: string,
  month?: number,
  year?: number,
  sectorId?: number
) => {
  let payload: {
    tenantId: number;
    employerType?: number;
    bank?: string;
    month?: number;
    year?: number;
    sectorId?: number;
  } = {
    tenantId,
    month,
    year,
    sectorId,
  };

  employerType && (payload.employerType = employerType);
  bank && (payload.bank = bank);
  return axiosInstance().post(`/reports/paymentOrderPdf`, payload);
};

export const getTenantCertificate = (tenantId) => {
  return axiosInstance().post(`/reports/tenantCertificate`, {
    tenantId: tenantId,
  });
};
export const getEmployeeCertification = (employeeId) => {
  return axiosInstance().post(
    `/reports/employeeCertification?employeeId=${employeeId}`
  );
};
export const certificatePdf = (tenantId: number, employeeId) => {
  return axiosInstance().post(
    `/reports/certificatePdf?employeeId=${employeeId}&tenantId=${tenantId}`
  );
};
export const exportCertificate = (tenantId: number, employeeId) => {
  return axiosInstance().post(
    `/reports/exportCertificate?employeeId=${employeeId}&tenantId=${tenantId}`
  );
};
export const sendCertificateToMail = (tenantId: number, employeeId, email) => {
  return axiosInstance().post(
    `/reports/sendCertificateToMail?tenantId=${tenantId}&employeeId=${employeeId}&email=${email}`
  );
};

export const getVacationList = (tenantId: number, from, to) => {
  return axiosInstance().post(
    `/reports/vacationList?tenantId=${tenantId}&from=${from}&to=${to}`
  );
};
export const emailVacationList = (payload) => {
  const { tenantId, from, to, employeeIds, email } = payload;
  const data: any = new FormData();
  data.append("tenantId", tenantId);
  data.append("from", from);
  data.append("to", to);
  data.append("email", email);
  employeeIds?.forEach((item) => {
    data.append("employeeIds[]", item);
  });
  return axiosInstance().post(`/reports/sendEmailVacationList`, data);
};
export const vacationListPdf = (tenantId: number, from, to) => {
  return axiosInstance().post(
    `/reports/vacationListPdf?tenantId=${tenantId}&from=${from}&to=${to}`
  );
};
export const exportVacationList = (tenantId: number, from, to) => {
  return axiosInstance().post(
    `/reports/exportVacationList?tenantId=${tenantId}&from=${from}&to=${to}`
  );
};

export const getAllStatisticsReport = (tenantId: number, year: number) => {
  const payload = { year, tenantId };
  return axiosInstance().post(`/reports/statistics`, payload);
};

export const sendEmailForAnnualSalaries = (
  tenantId: number,
  year: number,
  employeeIds: Array<number>,
  email: string
) => {
  const payload = { tenantId, year, employeeIds, email };
  return axiosInstance().post(`/reports/sendAnnualSalaryToMail`, payload);
};

export const sendStatisticsEmail = (email: string) => {
  const payload = {
    email,
  };
  return axiosInstance().post(`/reports/sendStatisticsToMail`, payload);
};

export const exportStatisticsPdf = (tenantId: number, year: number) => {
  const payload = { year, tenantId };
  return axiosInstance().post(`/reports/statisticsPdf`, payload);
};

export const sendMonthlySalaryEmployeesEmail = (
  tenantId: number,
  month: number,
  year: number,
  employeeIds: number[],
  email: string
) => {
  const payload = { tenantId, month, year, employeeIds, email };
  return axiosInstance().post(`/reports/sendMonthlySalaryToMail`, payload);
};

export const exportStatisticsExcel = (tenantId: number, year: number) => {
  const payload = {
    tenantId,
    year,
  };
  return axiosInstance().post(`/reports/statisticsExcel`, payload);
};

export const downloadSalaryListExcel = (
  tenantId: number,
  year: number,
  month: number
) => {
  const payload = { tenantId, year, month };
  return axiosInstance().post(`/reports/exportElectronicDeclaration`, payload);
};
