import NotFound from 'components/404/404.component';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export const Public = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state: RootStateOrAny) => state).globalReducer.isLoggedIn;

  return (
    <Route {...rest} render={props => {
      return (
        !isLoggedIn
          ?
        <Component  {...props} />
        :
        (rest.location.pathname === "/index" || rest.location.pathname === "/dashboard" || rest.location.pathname === "/login"
          ?
          <Redirect to={{
            pathname: '/',
            state: { from: props.location }
          }}
          />
          :
          (
            rest.location.pathname === "/companyLogin" 
            ?
            <Component  {...props} />
            :

            <Route component={NotFound} />
          )
        )
      )
    }}
    />
  )
};
