import InputCustom from "components/InputCustom/InputCustom.component";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import { useEffect, useState } from "react";
import styles from './SetupInactivityModal.module.scss';
import { fetchInactivityMinutes, saveInactivityMinutes } from "services/inactivity-setup.service";
import { useSelector } from "react-redux";
import { globalState } from "types/globalState.type";

const SetupInactivityModal = ({ showModal, onClose }) => {
	const [saving, setSaving] = useState(false);
	const [minutes, setMinutes] = useState<number | null>(null);
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);

	const onSave = async () => {
		setSaving(true);
		try {
			await saveInactivityMinutes(tenantId, Number(parseInt(minutes.toString())));
		} catch (err) {
			console.error('failed to save');
		}
		setSaving(false);
		onClose();
	}

	useEffect(() => {
		if (!showModal || !tenantId) {
			return;
		}

		const loadData = async () => {
			try {
				const response = await fetchInactivityMinutes(tenantId);
				setMinutes(response.data.minutes);
			} catch (err) {
				console.error('Failed to fetch inactivity minutes', err);
			}
		}

		loadData();
	}, [showModal, tenantId]);

	return (
		<ModalCustom show={showModal}
			onClose={onClose}
			onSubmit={onSave}
			submitLoading={saving}
			showSubmitButton
			showRejectButton
			header={
				<div>
					<div className={styles.modalTitle}>Koha e Inaktivitetit në minuta</div>
					<div>Pas skadencës së minutave, nëse përdoruesi nuk ka aktivitet, do të çkyçet nga sistemi.</div>
				</div>
			}

			submitText="Ruaj">
			<div>
				<InputCustom
					className={`${styles.modalInput} noArrowsNumberInput`}
					style={{ width: '100%' }}
					type="number"
					value={minutes}
					onChangeHandler={(e) => {
						setMinutes(e.target.value)
					}}
				/>
			</div>
		</ModalCustom>
	)
}

export default SetupInactivityModal;