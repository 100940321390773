import styles from "./InputField.module.scss";
const InputField = (props) => {
    return (
        <div className={`${styles.inputField} ${props.className}`}>
            {props.label && <label htmlFor={styles.inputField} style={{ color: props.labelColor }}>{props.label}</label>}
            <input
                type={props.type || "text"}
                value={props.value}
                className={styles.inputField}
                onFocus={props.onFocusHandler}
                placeholder={props.placeholder}
                onChange={props.onChangeHandler}
                onKeyDown={(e) => {
                    return props.onKeydownHandler
                        ? props.onKeydownHandler(e)
                        : () => { return props.type === "number" && e.key === "," ? true : false; }
                }}
                id={props.id}
                min={props.type === "number" ? (props.min ? props.min : "0") : ""}
                {...props.attributes}
            />
        </div>
    );
};

export default InputField;