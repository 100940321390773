import {all, fork} from 'redux-saga/effects';
import logInSaga from 'App/saga';
import dashboardSaga from 'pages/Dashboard/saga';
import employeesSaga from 'pages/Employees/saga';
import salariesSaga from 'pages/Calculations/saga';
import employeesAndVacationsSaga from 'pages/Holidays/saga';
import paymentOrderSaga from 'pages/Reports/ManagementReports/saga';
import documentsSaga from 'pages/Documents/saga';
import contributionsSaga from '../pages/Reports/TaxAdministrationReports/saga';
import annualSalariesSaga from '../components/ReportsTable/saga';
import monthlyStatisticsSaga from 'pages/Reports/ManagementReports/MonthlyStatistics/saga';
import employeeReportsSaga from 'pages/Reports/EmployeeReports/saga';
import statisticsReportSaga from '../pages/Reports/ManagementReports/Statistics/saga';
import downloadSalaryListExcel from '../pages/Reports/TaxAdministrationReports/EdiReports/saga';
import configurationSaga from 'pages/Configurations/saga';
import notificationSaga from '../pages/NotificationsPage/saga';
import globalSearchSaga from 'components/GlobalSearch/saga';

export default function* rootSaga() {
  yield all([
    fork(logInSaga),
    fork(dashboardSaga),
    fork(employeesSaga),
    fork(salariesSaga),
    fork(employeesAndVacationsSaga),
    fork(paymentOrderSaga),
    fork(documentsSaga),
    fork(contributionsSaga),
    fork(annualSalariesSaga),
    fork(monthlyStatisticsSaga),
    fork(employeeReportsSaga),
    fork(statisticsReportSaga),
    fork(downloadSalaryListExcel),
    fork(configurationSaga),
    fork(notificationSaga),
    fork(globalSearchSaga),
  ]);
}
