import { createContext, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchInactivityMinutes } from "services/inactivity-setup.service";
import { globalState } from "types/globalState.type";

type InactivityLogoutContextType = {};

export const InactivityLogoutContext = createContext<InactivityLogoutContextType>({});

type InactivityLogoutProviderProps = {
	children: ReactNode;
}

const KEY = "lastActivityTime";

export const InactivityLogoutProvider = ({ children }: InactivityLogoutProviderProps) => {
	const [minutes, setMinutes] = useState(1);
	const [check, setCheck] = useState(false);
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);

	const checkForInactivity = () => {
		const lastActivityString = getCookie(KEY);
		if (!lastActivityString?.length) {
			return;
		}
		const lastActivityTime = lastActivityString ? parseInt(lastActivityString) : 0;

		if (Date.now() - lastActivityTime > minutes * 60 * 1000) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}

	const updateLastActivityTime = () => {
		const currentTime = Date.now();
		setCookie(KEY, currentTime.toString(), minutes / 1440);
	}

	const setCookie = (name: string, value: string, days: number) => {
		const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
		document.cookie = `${name}=${value}; expires=${expires}; path=/`;
	}

	const getCookie = (name: string) => {
		const nameEQ = `${name}=`;
		const ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	useEffect(() => {
		if (!tenantId || window?.location?.pathname == '/login') {
			return;
		}

		const shouldCheck = async () => {
			try {
				const response = await fetchInactivityMinutes(tenantId);
				if (response.data?.minutes) {
					const minutesToCheck = response.data.minutes;
					setMinutes(minutesToCheck);
					if (minutesToCheck != null) {
						setCheck(true);
					}
				}
			} catch (err) {
				console.error('Failed to fetch inactivity minutes', err);
			}
		}

		shouldCheck();

	}, [tenantId]);

	useEffect(() => {
		if (!check) {
			return;
		}
		checkForInactivity();

		const interval = setInterval(() => {
			checkForInactivity();
		}, 5000);

		return () => clearInterval(interval);
	}, [check]);

	useEffect(() => {
		if (!check) {
			return;
		}
		updateLastActivityTime();
		window.addEventListener('click', updateLastActivityTime);
		window.addEventListener('keypress', updateLastActivityTime);
		window.addEventListener('scroll', updateLastActivityTime);
		window.addEventListener('mousemove', updateLastActivityTime);

		return () => {
			window.removeEventListener('click', updateLastActivityTime);
			window.removeEventListener('keypress', updateLastActivityTime);
			window.removeEventListener('scroll', updateLastActivityTime);
			window.removeEventListener('mousemove', updateLastActivityTime);
		}
	}, [check]);

	return (
		<InactivityLogoutContext.Provider value={{}}>
			{children}
		</InactivityLogoutContext.Provider>
	)
}