/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import styles from './reportsTable.module.scss';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import ModalCustom from '../ModalCustom/ModalCustom.component';
import DropdownSelect from '../DropdownSelect/DropdownSelect.component';
import { downloadTypeOptions } from '../../utils/globalVariables';
import { FiCalendar, FiCheck, FiDownload } from 'react-icons/fi';
import { IconDocument } from '../../assets/icons/icons';
import ReactToPrint from 'react-to-print';
import Table from '../Table/Table.component';
import { format } from 'date-fns';
import { generateArrayOfYears } from '../../utils/functions';
import { globalState } from '../../types/globalState.type';
import Checkbox from 'react-custom-checkbox';
import { downloadAnnualSalariesExcel, downloadAnnualSalariesPdf, loadAnnualSalaries, sendAnnualSalaryToMail, sendEmployeesEmail } from './actions';
import Loader from '../partials/Loader/Loader.component';
import { setClearGlobalSearch, setGlobalSearchDone } from '../NavBar/actions';
import { setEmployeesChecked } from './actions';
import { toast } from 'react-toastify';
import LoaderCentered from 'components/partials/LoaderCentered/LoaderCentered.component';
import { useTranslation } from 'react-i18next';
import InputCustom from 'components/InputCustom/InputCustom.component';
import tableStyles from 'components/Table/Table.module.scss';
import classNames from 'classnames';


interface Props {
	tableHeaderData?: Array<string>,
	show: boolean,
	setIsAnnualShowed?: any,
	tableHeaderTitle?: string,
	data?: any,
	onPrint?: any,
}

let currentYear: number = new Date().getFullYear();
let yearsList = generateArrayOfYears(21);

const pageStyle = `
	@page {
		margin: 10px 20px;
		font-size: 15px;
	}
	.noPrint {
		display: none !important;
	}
	.footer {
		position: fixed;
		bottom: 10px;
	}
	.modalPrint {
		font-size: 13px !important;
	}
	.print-padding {
		padding: 0 20px;
	}
	.reportDropdown__indicator {
		display:none !important;
	}
	.printHeightFix {
		height: unset !important;
		min-height: unset !important;
		overflow: initial !important;
	}
`;

const ReportsTable: React.FC<Props> = props => {
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);

	const { t, i18n } = useTranslation('common');
	const dispatch = useDispatch();

	const componentRef = useRef<HTMLDivElement>(null);
	const todayDate = format(new Date(), 'dd.MM.yyyy');
	const [showFilterContent, setShowFilterContent] = useState(false);
	const [pickedYear, setPickedYear] = useState(currentYear);
	const [checkedEmployees, setCheckedEmployees] = useState<Array<number>>([])
	const [isAllCheckboxesChecked, setIsAllCheckboxesChecked] = useState<boolean>(false);
	const allAnnualSalaries = useSelector((state: RootStateOrAny) => state.annualSalariesReducer.allAnnualSalaries);

	const state = useSelector((state: RootStateOrAny) => state.annualSalariesReducer);
	const isAnnualSalariesLoading = state.loading;
	const backdropParent: string = useSelector((state: globalState) => state.appWrapperReducer.backdropParent);

	const periodWrapperRef = useRef(null);
	const dropdownMenuRef = useRef<HTMLInputElement>(null);

	const [isEmailOpen, setIsEmailOpen] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [emailValidMessage, setEmailValidMessage] = useState<string>("");
	const isSendEmailLoading = useSelector((state: RootStateOrAny) => state.documentsReducer.sendEmailLoading);

	useEffect(() => {
		dispatch(loadAnnualSalaries(tenantId, pickedYear));
	}, [pickedYear]);

	useEffect(() => {
		if (!isSendEmailLoading) {
			setIsModalOpen(false);
		}
	}, [isSendEmailLoading]);

	useEffect(() => {
		const handleOutsideMenuClick = (e: any) => {
			if (!dropdownMenuRef?.current?.contains(e.target) && showFilterContent) {
				menuToggleButtonHandler();
			};
		}

		if (showFilterContent) window.addEventListener("click", handleOutsideMenuClick);
		showFilterContent && dispatch(setClearGlobalSearch(true));
		showFilterContent && dispatch(setGlobalSearchDone(false));
		return () => window.removeEventListener("click", handleOutsideMenuClick);
	}, [showFilterContent, dropdownMenuRef]);

	const menuToggleButtonHandler = () => {
		setShowFilterContent(!showFilterContent);
	}

	const handlePeriodContainerClick = (event) => {
		setShowFilterContent(prev => !prev);
	}

	const handleEmailChange = (e) => {
		const emailRegex = /\S+@\S+\.\S+/;
		setEmail(e.target.value);
		if (emailRegex.test(email)) {
			setIsEmailValid(true);
		} else {
			setIsEmailValid(false);
			setEmailValidMessage('Please enter a valid email!');
		}
	};

	const handleDownloadPick = (e, item) => {
		if (item.value === 1) {
			dispatch(downloadAnnualSalariesExcel(tenantId, pickedYear));
		} else {
			props?.onPrint?.();
		}
	};
	const handleEmailButton = (e) => {
		dispatch(sendAnnualSalaryToMail(tenantId, pickedYear, checkedEmployees, email))
		setIsEmailOpen(false)
	};

	const handleCheckAllEmployee = (checked) => {
		setIsAllCheckboxesChecked(!isAllCheckboxesChecked);
		dispatch(setEmployeesChecked(checked))
		isAllCheckboxesChecked && setCheckedEmployees([]);
		!isAllCheckboxesChecked && setCheckedEmployees(prevState => {
			return allAnnualSalaries.employees.map(item => item.employeeId);
		})
	}

	const handleCheckEmployee = (checked, id) => {
		setCheckedEmployees(prev => {
			if (checked) {
				return [
					...prev, id,
				];
			} else {
				return prev.filter(item => item !== id);
			}
		});
	}

	const handleOpenModal = () => {
		setIsEmailOpen(false);
		setEmail("")
	};

	return (
		<div className={styles.template}>
			<div className={styles.templateBox} >
				<ModalCustom
					show={props.show}
					contentClass={styles.contentClass}
					footerClass={styles.footerClass}
					ref={componentRef}
					onClose={() => {
						props.setIsAnnualShowed(prev => false);
					}}
					hideFooterButton={true}
				>
					<div className={`${styles.titleHeader}`}>
						<div className={styles.point}>
						</div>
						<div className={styles.orderPaymentHeader}>
							{props.tableHeaderTitle}
						</div>
						<div className={`${styles.downloadText} noPrint`}
						>
							<div className={styles.dropdownText}>
								<DropdownSelect
									data={downloadTypeOptions}
									buttonText={<div className={`${styles.dropdownText} pr-5`}>
										<div className={styles.downloadIcon}>
											<FiDownload strokeWidth="2px" />
										</div>
										{t('reports.download')}
									</div>}
									showArrow={true}
									noWidth={true}
									menuPosition="right"
									optionClick={(event, item) => {
										handleDownloadPick(event, item);
									}}
								/>
							</div>
						</div>
						<div
							className={`${styles.downloadText} ${styles.iconText} noPrint`}
							onClick={() => setIsEmailOpen(!isEmailOpen)}
						>
							{
								!state.emailLoading
									?
									<>
										<div className={styles.sendEmailIcon}>
											<FiDownload strokeWidth="2px" />
										</div>
										{t('salaryCalculation.sendEmail')}
									</>
									:
									<LoaderCentered style={{ width: "20px", height: "20px" }} />
							}
						</div>
						<div className={`${styles.printIcon} noPrint`}>
							<IconDocument stroke={'#89C732'} />
						</div>
						<div className={`${styles.downloadText} ${styles.iconText} noPrint`}>
							<ReactToPrint
								trigger={() => <div className={styles.printText}>{t('reports.print')}</div>}
								content={() => componentRef.current}
								pageStyle={pageStyle}
							/>
						</div>
						<div className={styles.headerDate}>
							Data: {todayDate}
						</div>
					</div>

					<div className={`row ${styles.filterRow}`} ref={dropdownMenuRef}>
						<div className={`${styles.filterDescription} noPrint`}>{t('reports.print')}</div>
						<div
							className={`${styles.filterButtons} ${backdropParent !== "calculationsPeriodFilter" ? "lowZindex" : ""}`}
						>
							<div className={`${styles.button} cursorAuto`}>
								{t('reports.year')}
							</div>
							<div className={`${styles.button} ${styles.yearsButton}`}
								onClick={handlePeriodContainerClick}
							>
								{pickedYear}
								<div className={styles.calendar}>
									<FiCalendar />
								</div>
							</div>
						</div>
						{showFilterContent &&
							<div className={styles.filterContent} ref={periodWrapperRef}>
								<div className={styles.years}>
									{yearsList.map((item, index) => {
										return <div
											key={index}
											className={`${styles.periodItem} ${item === pickedYear ? styles.selected : ""}`}
											onClick={() => {
												setPickedYear(prev => +item)
												setShowFilterContent(prev => false)
											}}
										>
											{item}
										</div>
									})}
								</div>
							</div>
						}
					</div>

					<div className={styles.tableContainer}>
						{
							isAnnualSalariesLoading ? <span className={styles.loader}><Loader /> </span>
								:
								<Table tableHeader={
									<thead className={`${tableStyles.tableHead} ${tableStyles.noPosition}`}>
										<tr className={tableStyles.headerRow}>
											<th className={`${tableStyles.rootCell} ${tableStyles.center} ${tableStyles.pr0} noPrint`}>
												<Checkbox
													className={styles.checkbox}
													icon={
														<div className={styles.checkboxIconContainer}>
															<FiCheck color="#261F63" size={20} />
														</div>
													}
													checked={isAllCheckboxesChecked}
													onChange={() => handleCheckAllEmployee(!isAllCheckboxesChecked)}
												/>
											</th>
											<th className={`${tableStyles.rootCell} noPrint`}>
												{t('salaryCalculation.all')}
											</th>
											<td >
											</td>
											<td className={`${styles.totalCell}`}>
												<div>
													{allAnnualSalaries?.totals?.brutto}
												</div>
											</td>
											<td className={`${styles.totalCell}`}>
												<div>
													{allAnnualSalaries?.totals?.employerContribute}
												</div>
											</td>
											<td className={`${styles.totalCell}`}>
												<div>
													{allAnnualSalaries?.totals?.personalContribute}
												</div>
											</td>
											<td className={`${styles.totalCell}`}>
												<div>
													{allAnnualSalaries?.totals?.tax}
												</div>
											</td>
											<td className={`${styles.totalCell}`}>
												<div>
													{allAnnualSalaries?.totals?.netto}
												</div>
											</td>
										</tr>
										<tr className={tableStyles.headerRow}>
											{
												props.tableHeaderData?.map((cell, index) => {
													const thClasses = classNames({
														"noPrint printPaddingLeft": index === 0,
														[tableStyles.headerCell]: true,
														[tableStyles.rootCell + " " + tableStyles.pr0]: index === 0,
														[tableStyles.rootCell]: index === 1,
													});
													return <th className={thClasses} key={index}>
														{cell}
													</th>
												}
												)
											}
										</tr>
									</thead>
								}>
									<tbody className={styles.tableBody}>
										{allAnnualSalaries?.employees?.length > 0 && allAnnualSalaries?.employees.map((data, idx) => {
											return (
												<tr className={styles.tableRow} key={idx}>
													<td className={"noPrint"}>
														<div className={`${styles.checkboxIcon}`}>
															<Checkbox
																className={styles.checkbox}
																icon={
																	<div className={styles.checkboxIconContainer}>
																		<FiCheck color="#261F63" size={20} />
																	</div>
																}
																checked={data.isChecked}
																onChange={(checked) => handleCheckEmployee(checked, data.employeeId)}
															/>
														</div>
													</td>
													<td className={`${styles.bodyCell} ${styles.text}`}>
														<div>
															{data?.employeeName}
														</div>
													</td>
													<td className={`${styles.bodyCell} ${styles.text}`}>{data?.personalNumber}</td>
													<td className={`${styles.bodyCell} ${styles.text}`}>{data?.brutto}</td>
													<td className={`${styles.bodyCell} ${styles.text}`}>{data?.personalContribute}</td>
													<td className={`${styles.bodyCell} ${styles.text}`}>{data?.employerContribute}</td>
													<td className={`${styles.bodyCell} ${styles.text}`}>{data?.tax}</td>
													<td className={`${styles.bodyCell} ${styles.text}`}>{data?.netto}</td>
												</tr>
											);
										})}
									</tbody>
								</Table>}
					</div >
				</ModalCustom >
			</div >

			<ModalCustom
				contentClass={styles.sendEmailWrapper}
				show={isEmailOpen}
				hideFooterButton={false}
				submitLoading={isEmailOpen && isSendEmailLoading || !isEmailOpen}
				submitText={isEmailOpen ? 'Dërgo' : `${t('reports.save')}`}
				footerClass={styles.footerClass}
				onClose={handleOpenModal}
				onSubmit={() => handleEmailButton(tenantId)}
			>
				{isEmailOpen ? <div className={styles.emailBox} onKeyDown={(e) => {
					const inputCondition = e.key === 'Enter';
				}}>
					<InputCustom
						onChangeHandler={handleEmailChange}
						className={styles.rowInputEmail}
						name="occupation"
						value={email}
						placeholder={'Enter e-mail'}
						attributes={{ autoFocus: true, }}
						type="text"
					/>
					{!isEmailValid && email !== "" ? <span className={styles.validateEmail}>{emailValidMessage}</span> : null}
				</div> : null}
			</ModalCustom>
		</div >
	);
};

export default ReportsTable;