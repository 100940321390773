const scope = "melitaPayroll/login";

export const LOG_IN = `${scope}/LOG_IN`;
export const LOG_IN_INIT = `${scope}/LOG_IN_INIT`;
export const LOG_IN_SUCCESS = `${scope}/LOG_IN_SUCCESS`;
export const LOG_IN_ERROR = `${scope}/LOG_IN_ERROR`;
export const LOG_IN_FAIL = `${scope}/LOG_IN_FAIL`;

export const LOG_OUT = `${scope}/LOG_OUT`;
export const RESET_FAIL_MESSAGE = `${scope}/RESET_FAIL_MESSAGE`;

export const SET_GLOBAL_TENANT_ID = `${scope}/SET_GLOBAL_TENANT_ID`;
export const SET_EMAIL = `${scope}/SET_EMAIL`;
export const SET_IS_LOGGED_IN = `${scope}/SET_IS_LOGGED_IN`;

export const GET_USER_BY_EMAIL = `${scope}/GET_USER_BY_EMAIL`;
export const GET_USER_BY_EMAIL_SUCCESS = `${scope}/GET_USER_BY_EMAIL_SUCCESS`;
export const GET_USER_BY_EMAIL_ERROR = `${scope}/GET_USER_BY_EMAIL_ERROR`;

export const SET_TOKEN = `${scope}/SET_TOKEN`;

export const GET_NOTIFICATIONS = `${scope}/GET_NOTIFICATIONS`;
export const GET_NOTIFICATIONS_SUCCESS = `${scope}/GET_NOTIFICATIONS_SUCCESS`;
export const GET_NOTIFICATIONS_ERROR = `${scope}/GET_NOTIFICATIONS_ERROR`;

export const SET_LOGO = `${scope}/SET_LOGO`;

export const SET_GLOBAL_HAS_QUICKBOOKS = `${scope}/SET_GLOBAL_HAS_QUICKBOOKS`;
