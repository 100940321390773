const scope = "melitaPayroll/reports";

export const LOAD_REPORT_STATISTICS = `${scope}/LOAD_REPORT_STATISTICS`;
export const LOAD_REPORT_STATISTICS_SUCCESS = `${scope}/LOAD_REPORT_STATISTICS_SUCCESS`;
export const LOAD_REPORT_STATISTICS_ERROR = `${scope}/LOAD_REPORT_STATISTICS_ERROR`;
export const DOWNLOAD_REPORT_STATISTICS_PDF = `${scope}/DOWNLOAD_REPORT_STATISTICS_PDF`;
export const DOWNLOAD_REPORT_STATISTICS_PDF_SUCCESS = `${scope}/DOWNLOAD_REPORT_STATISTICS_PDF_SUCCESS`;
export const DOWNLOAD_REPORT_STATISTICS_PDF_ERROR = `${scope}/DOWNLOAD_REPORT_STATISTICS_PDF_ERROR`;
export const DOWNLOAD_REPORT_STATISTICS_EXCEL = `${scope}/DOWNLOAD_REPORT_STATISTICS_EXCEL`;
export const DOWNLOAD_REPORT_STATISTICS_EXCEL_SUCCESS = `${scope}/DOWNLOAD_REPORT_STATISTICS_EXCEL_SUCCESS`;
export const DOWNLOAD_REPORT_STATISTICS_EXCEL_ERROR = `${scope}/DOWNLOAD_REPORT_STATISTICS_EXCEL_ERROR`;
export const LOAD_EMAIL_STATISTICS = `${scope}/LOAD_EMAIL_STATISTICS`;
export const LOAD_EMAIL_STATISTICS_SUCCESS = `${scope}/LOAD_EMAIL_STATISTICS_SUCCESS`;
export const LOAD_EMAIL_STATISTICS_ERROR = `${scope}/LOAD_EMAIL_STATISTICS_ERROR`;
