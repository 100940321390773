import Login from "pages/Login/Login.component";
import Dashboard from "pages/Dashboard/Dashboard.component";
import Employees from "pages/Employees/Employees.component";
import Calculations from "pages/Calculations/Calculations.component";
import Documents from "pages/Documents/Documents.component";
import Reports from "pages/Reports/Reports.component";
import Holidays from "pages/Holidays/Holidays.component";
import Configurations from "pages/Configurations/Configurations.component";
import CompanyLogin from "pages/Login/CompanyLogin/CompanyLogin.component";
import ForgotPasswordForm from "pages/Login/ForgotPasswordForm.component";
import LicenseAgreement from "pages/Login/LicenseAgreement/LicenseAgreement.component";

import { IconHome, IconUser, IconDollar, IconDocument, IconSettings, IconCalendar } from "assets/icons/icons";
import { menuIconsColor } from "./globalVariables";
import { useTranslation } from 'react-i18next';



interface RouteTypes {
    path: string;
    name: string;
    icon?: any;
    component?: any;
    type?: string;
    isExact?: boolean;
}

const dashboardRoutes: RouteTypes[] = [
    
    {
        path: "",
        name: "sideBar.dashBoard",
        icon: <IconHome stroke={menuIconsColor} width="2px" />,
        component: Dashboard,
        type: "sidebar",
        isExact: true,
    },
    {
        path: "employees",
        name: "sideBar.employees",
        icon: <IconUser stroke={menuIconsColor} width="3.5px" />,
        component: Employees,
        type: "sidebar",
        isExact: true,
    },
    {
        path: "calculations",
        name: "sideBar.salaryCalculation",
        icon: <IconDollar stroke={menuIconsColor} width="3px" />,
        component: Calculations,
        type: "sidebar",
        isExact: true,
    },
    {
        path: "documents",
        name: "sideBar.documents",
        icon: <IconDocument stroke={menuIconsColor} width="2px" />,
        component: Documents,
        type: "sidebar",
        isExact: true,
    },
    {
        path: "reports",
        name: "sideBar.reports",
        icon: <IconDocument stroke={menuIconsColor} width="2px" />,
        component: Reports,
        type: "sidebar",
        isExact: false,
    },
    {
        path: "holidays",
        name: "vacation.vacation",
        icon: <IconCalendar stroke={menuIconsColor} width="2px" />,
        component: Holidays,
        type: "sidebar",
        isExact: true,
    },
    {
        path: "configurations",
        name: "sideBar.configurations",
        icon: <IconSettings stroke={menuIconsColor} width="2px" />,
        component: Configurations,
        type: "sidebar",
        isExact: true,
    },
    {
        path: "login",
        name: "Login",
        component: Login,
        type: "user",
        isExact: true,
    },
    {
        path: "companyLogin",
        name: "CompanyLogin",
        component: CompanyLogin,
        type: "user",
        isExact: true,
    },
    {
        path: "forgotPassword",
        name: "forgotPassword",
        component: ForgotPasswordForm,
        type: "user",
        isExact: true,
    },
    {
        path: "resetPassword",
        name: "resetPassword",
        component: ForgotPasswordForm,
        type: "user",
        isExact: true,
    },
    {
        path: "licenseAgreement",
        name: "licenseAgreement",
        component: LicenseAgreement,
        type: "user",
        isExact: true,
    },
];

export default dashboardRoutes;