import { components } from 'react-select';
import { IoIosArrowDown } from "react-icons/io"
import { IconContext } from 'react-icons';

const DropdownIndicator = props => {
	return (
		<components.DropdownIndicator {...props} >
			<IconContext.Provider
				value={{ size: '30px' }}
			>
				<IoIosArrowDown />
			</IconContext.Provider>
		</components.DropdownIndicator>
	);
};

export default DropdownIndicator;