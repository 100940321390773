import produce from "immer";

import {
    GLOBAL_SEARCH_DONE,
    INIT_GLOBAL_SEARCH,
    CLEAR_GLOBAL_SEARCH,
    SEE_ALL_NOTIFICATIONS,
    SHOW_HELP_DATA,
    SYNC_QUICKBOOKS,
    SET_LANGUAGE,
} from "./constants";

const initialState = {
    searchLoading: false,
    globalSearchDone: false,
    clearSearch: false,
    error: null,
};

const navBarReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case INIT_GLOBAL_SEARCH:
                draft.searchLoading = true;
                break;
            case GLOBAL_SEARCH_DONE:
                draft.globalSearchDone = action.payload && !draft.clearSearch;
                draft.searchLoading = false;
                break;

            case CLEAR_GLOBAL_SEARCH:
                draft.globalSearchDone = false;
                draft.clearSearch = action.payload;
                break;
            case SET_LANGUAGE:
                break;
            case SYNC_QUICKBOOKS:
                break;
            case SEE_ALL_NOTIFICATIONS:
                break;
            case SHOW_HELP_DATA:
                break;
            default:
                draft = initialState;
                break;
        }
    })


export default navBarReducer;