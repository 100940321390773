import { axiosInstance } from "api"

export const getUpcomingVacations = (tenantId: number) => {
	return axiosInstance().get(`/vacations/upcoming?tenantId=${tenantId}`);
}

export const getEmployeesAndVacations = (tenantId: number) => {
	return axiosInstance().get(`/vacations/index?tenantId=${tenantId}`);
}

export const getAllCalendarVacations = (tenantId: number, period: number) => {
	return axiosInstance().get(`/vacations/calendar?tenantId=${tenantId}&period=${period}`);
}

export const getAllVacationTypes = () => {
	return axiosInstance().get(`/vacations/types`);
}

export const storeNewVacation = (newVacation) => {
	return axiosInstance().post(`/vacations/store`, newVacation);
}

export const deleteEmployeeVacation = (vacationId) => {
	return axiosInstance().delete(`/vacations/delete/${vacationId}`);
}

export const updateEmployeeVacation = (vacation) => {
	return axiosInstance().patch(`/vacations/update/${vacation.id}`, vacation);
}

export const showEmployeeVacationById = (vacationId) => {
	return axiosInstance().get(`/vacations/show/employee/${vacationId}`);
}