import { useTranslation } from 'react-i18next';
import produce from 'immer';
import {
	LOAD_UPCOMING_BIRTHDAYS, LOAD_UPCOMING_BIRTHDAYS_ERROR, LOAD_UPCOMING_BIRTHDAYS_SUCCESS,
	LOAD_UPCOMING_VACATIONS, LOAD_UPCOMING_VACATIONS_ERROR, LOAD_UPCOMING_VACATIONS_SUCCESS,
	LOAD_EXPIRED_CONTRACTS, LOAD_EXPIRED_CONTRACTS_ERROR, LOAD_EXPIRED_CONTRACTS_SUCCESS,
	LOAD_DASHBOARD_CARDS_DATA, LOAD_DASHBOARD_CARDS_DATA_SUCCESS, LOAD_DASHBOARD_CARDS_DATA_ERROR,
} from './constants';
import { toast } from 'react-toastify';
import dashboardState from 'types/reducers/dashboard.reducer.type';

const initialState: dashboardState = {
	upcomingBirthdays: null,
	upcomingVacations: null,
	expiredContracts: null,
	salaryCardsLoading: true,
	salariesTillNowData: {
		percentageDif: null,
		salariesPastMonth: null,
		salariesTillNow: null,
	},
	averageSalaryData: {
		percentageDif: null,
		averageSalariesPastMonth: null,
		averageSalaries: null,
	},
}

const dashboardReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case LOAD_UPCOMING_BIRTHDAYS:
				break;
			case LOAD_UPCOMING_BIRTHDAYS_SUCCESS:
				draft.upcomingBirthdays = action.payload.upcomingBirthdays;
				break;
			case LOAD_UPCOMING_BIRTHDAYS_ERROR:
				toast.error('Diçka shkoi gabim!');
				draft.upcomingBirthdays = { count: null, birthdays: [] };
				break;
			case LOAD_UPCOMING_VACATIONS:
				break;
			case LOAD_UPCOMING_VACATIONS_SUCCESS:
				draft.upcomingVacations = action.payload.upcomingVacations;
				break;
			case LOAD_UPCOMING_VACATIONS_ERROR:
				toast.error('Diçka shkoi gabim!');
				draft.upcomingVacations = [];
				break;
			case LOAD_EXPIRED_CONTRACTS:
				break;
			case LOAD_EXPIRED_CONTRACTS_SUCCESS:
				draft.expiredContracts = action.payload.expiredContracts;
				break;
			case LOAD_EXPIRED_CONTRACTS_ERROR:
				toast.error('Diçka shkoi gabim!');
				draft.expiredContracts = [];
				break;
			case LOAD_DASHBOARD_CARDS_DATA:
				draft.salaryCardsLoading = true;
				break;
			case LOAD_DASHBOARD_CARDS_DATA_SUCCESS:
				draft.salaryCardsLoading = false;
				draft.salariesTillNowData = action.payload.salariesTillNowData.data;
				draft.averageSalaryData = action.payload.averageSalaryData.data;
				break;
			case LOAD_DASHBOARD_CARDS_DATA_ERROR:
				draft.salaryCardsLoading = false;
				toast.error('Diçka shkoi gabim!');
				break;
			default:
				break;
		}
	});

export default dashboardReducer;