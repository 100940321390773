import { RootStateOrAny, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export const Protected = ({ component: Component, ...rest }) => {
	const isLoggedIn = useSelector((state: RootStateOrAny) => state).globalReducer.isLoggedIn;

	return (
		<Route {...rest} render={props => {
			return (
				isLoggedIn
					?
					<Component  {...props} />
					:
					<Redirect to={{
						pathname: '/login',
						state: { from: props.location }
					}}
					/>
			)
		}
		}
		/>
	)
};
