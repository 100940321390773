/* eslint-disable */
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { globalState } from "types/globalState.type";
import { ConfigurationsState } from "types/reducers/configurations.reducer.type";
import * as actions from "pages/Configurations/actions";
import InputCustom from "components/InputCustom/InputCustom.component";
import {
	IconPencil,
	IconSearch,
	IconX,
	QuickBooksIcon,
} from "assets/icons/icons";

import styles from "./Modal.module.scss";
import classNames from "classnames";
import Button from "components/Button/Button.component";
import { adminData, companyData, userData } from "types/configuration.type";
import { useEffect, useRef, useState } from "react";
import CustomSelect from "components/CustomSelect/CustomSelect.component";
import {
	activeInactiveOptions,
	customSelectStyles,
} from "utils/globalVariables";
import { AiOutlineEye } from "react-icons/ai";
import DropdownIndicator from "components/DropdownIndicator/DropdownIndicator.component";
import { DebounceInput } from "react-debounce-input";

import _ from "lodash";
import Checkbox from "components/Checkbox/Checkbox.component";
import { FiCheck } from "react-icons/fi";

interface Props { }

const CalculationsModal: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const state: ConfigurationsState = useSelector(
		(state: globalState) => state.configurationsReducer
	);
	const { showModal, modalOpener, editMode } = state;

	const roleId = useSelector(
		(state: globalState) => state.globalReducer.user?.roleId
	);
	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);
	const qbLink = state?.qbLink;

	const sidebarCollapsed: boolean = useSelector(
		(state: globalState) => state.appWrapperReducer.sidebarMenuCollapsed
	);
	const companyDataInStore: companyData = state.companyData;
	const adminDataInStore: adminData = state.adminData;
	const userDataInStore: userData = state.userData;
	const modalSubmitLoading: boolean = state.modalSubmitLoading;
	const searchedCompaniesList = state.searchedCompaniesList;

	const [passwordShown, setPasswordShown] = useState(false);
	const [companyData, setCompanyData] = useState({ ...companyDataInStore });
	const [adminData, setAdminData] = useState({ ...adminDataInStore });
	const [userData, setUserData] = useState({ ...userDataInStore });
	const [showCompaniesDropdown, setShowCompaniesDropdown] = useState(false);
	const [checkedAgreement, setCheckedAgreement] = useState<boolean>(false)
	const [checkedAccount, setCheckedAccount] = useState<boolean>(false)
	const [companyStatus, setCompanyStatus] = useState<{
		value?: number;
		label?: string;
	}>(activeInactiveOptions[0]);

	const [companySearchValue, setCompanySearchValue] = useState(null);
	const [pickedCompanies, setPickedCompanies] = useState([]);

	const closeModalCallBack = () => {
		setCompanyData({ ...companyDataInStore });
		setAdminData({ ...adminDataInStore });
		setUserData({ ...userDataInStore });
		setCompanySearchValue(null);
		setPickedCompanies([]);
		setShowCompaniesDropdown(false);
	};

	function toggle(value) {
		return !value;
	}

	useEffect(() => {
		setShowCompaniesDropdown(false);
	}, [showModal]);

	const modalCloseHandler = () => {
		dispatch(actions.setModalState({ show: false, opener: "" }));
		closeModalCallBack();
	};

	const iconPencilStyle = {
		fill: "#89c732",
		width: "20px",
		height: "20px",
	};

	const modalMainClasses = classNames({
		[styles.modalMainStyle]: true,
		[styles.sidebarCollapsed]: sidebarCollapsed,
	});

	const setInputEditMode = (event) => {
		event.stopPropagation();
	};

	const companyDataChangeHandler = (event) => {

		setCompanyData((prev) => {
			return {
				...prev,
				[event.target.name]: event.target.value,
				"checkedQb": event.target.checked
			};
		});
	};

	const adminDataChangeHandler = (event) => {
		setAdminData((prev) => {
			return {
				...prev,
				[event.target.name]: event.target.value,
			};
		});
	};

	const userDataChangeHandler = (event) => {
		setUserData((prev) => {
			return {
				...prev,
				[event.target.name]: event.target.value,
			};
		});
	};

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	let searchCode = window.location.search;
	let codeParam = new URLSearchParams(searchCode);
	let code = codeParam.get("code");

	let searchRealm = window.location.search;
	let realmParam = new URLSearchParams(searchRealm);
	let realmId = realmParam.get("realmId");
	let quickbooksState = realmParam.get("state");

	useEffect(() => {
		if (code) {
			const payload = {
				tenantId,
				code,
				realmId,
				clientId: localStorage.getItem("clientId"),
				clientSecret: localStorage.getItem("clientSecret"),
				userId: quickbooksState,
			};

			dispatch(actions.getRefreshToken(payload));
		}
	}, [code]);

	const modalsubmitHandler = (event) => {
		switch (modalOpener) {
			case "ADD_COMPANY":
				if (companyData?.password.length < 6) {
					toast.error('Fjalëkalimi duhet te jete me i gjate se 6 karaktere');
					return;
				}

				const hasNumber = /\d/.test(companyData?.password);
				const hasUpper = /[A-Z]/.test(companyData?.password);
				const hasAlpha = /[a-z]/.test(companyData?.password);

				if (!hasAlpha) {
					toast.error('Fjalëkalimi duhet te permbaje te pakten nje shkronje te vogel');
					return;
				}

				if (!hasNumber) {
					toast.error('Fjalëkalimi duhet te permbaje te pakten nje numer');
					return;
				}

				if (!hasUpper) {
					toast.error('Fjalëkalimi duhet te permbaje te pakten nje shkronje te madhe');
					return;
				}

				dispatch(actions.submitCompanyData(companyData));
				localStorage.setItem("clientId", companyData?.clientId);
				localStorage.setItem("clientSecret", companyData?.clientSecret);
				localStorage.setItem("checkedQb", companyData?.checkedQb.toString());
				dispatch(actions.getAllTenants(tenantId));
				break;
			case "ADD_ADMIN":
				if (adminData?.password.length < 6) {
					toast.error('Fjalëkalimi duhet te jete me i gjate se 6 karaktere');
					return;
				}

				const adminHasNumber = /\d/.test(adminData?.password);
				const adminHasUpper = /[A-Z]/.test(adminData?.password);
				const adminHasAlpha = /[a-z]/.test(adminData?.password);

				if (!adminHasAlpha) {
					toast.error('Fjalëkalimi duhet te permbaje te pakten nje shkronje te vogel');
					return;
				}

				if (!adminHasNumber) {
					toast.error('Fjalëkalimi duhet te permbaje te pakten nje numer');
					return;
				}

				if (!adminHasUpper) {
					toast.error('Fjalëkalimi duhet te permbaje te pakten nje shkronje te madhe');
					return;
				}
				setShowCompaniesDropdown(false);
				let adminPayload = new FormData();
				Object.entries(adminData).forEach((item) => {
					let key: string = item[0];
					let value: any = item[1];

					adminPayload.append(key, value);
				});

				pickedCompanies.forEach((item) => {
					adminPayload.append("tenantIds[]", item.id);
				});

				dispatch(actions.submitAdminData(adminPayload));

				if (roleId === 1) {
					dispatch(actions.getUserList(tenantId));
				} else {
					dispatch(actions.allTenantUsers(tenantId));
				}
				break;
			case "ADD_USER":
				if (userData?.password.length < 6) {
					toast.error('Fjalëkalimi duhet te jete me i gjate se 6 karaktere');
					return;
				}

				const userHasNumber = /\d/.test(userData?.password);
				const userHasUpper = /[A-Z]/.test(userData?.password);
				const userHasAlpha = /[a-z]/.test(userData?.password);

				if (!userHasAlpha) {
					toast.error('Fjalëkalimi duhet te permbaje te pakten nje shkronje te vogel');
					return;
				}

				if (!userHasNumber) {
					toast.error('Fjalëkalimi duhet te permbaje te pakten nje numer');
					return;
				}

				if (!userHasUpper) {
					toast.error('Fjalëkalimi duhet te permbaje te pakten nje shkronje te madhe');
					return;
				}

				let userPayload = new FormData();
				Object.entries(userData).forEach((item) => {
					let key: string = item[0];
					let value: any = item[1];

					userPayload.append(key, value);
				});

				pickedCompanies.forEach((item) => {
					userPayload.append("tenantIds[]", item.id);
				});

				dispatch(actions.submitUserData(userPayload));
				if (roleId === 1) {
					dispatch(actions.getUserList(tenantId));
				} else {
					dispatch(actions.allTenantUsers(tenantId));
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		switch (showModal) {
			case false:
				closeModalCallBack();
				break;
		}
	}, [showModal]);

	useEffect(() => {
		searchedCompaniesList && setShowCompaniesDropdown(true);
	}, [searchedCompaniesList]);

	useEffect(() => {
		companyStatus.value &&
			setCompanyData((prev) => ({ ...prev, status: companyStatus.value }));
	}, [companyStatus]);

	const searchCompanies = (searchKey) => {
		searchKey !== null && dispatch(actions.searchCompanies(searchKey));
	};

	useEffect(() => {
		searchCompanies(companySearchValue);
	}, [companySearchValue]);

	return (
		<ModalCustom
			show={showModal}
			contentClass={styles.modalContent}
			footerClass={styles.footerClass}
			modalMainStyle={modalMainClasses}
			submitLoading={modalSubmitLoading}
			onClose={modalCloseHandler}
			onSubmit={modalsubmitHandler}
			header={
				<div className={styles.modalHeader}>
					{
						{
							ADD_COMPANY: (
								<div className={styles.modalTitle}>Shto kompani</div>
							),
							ADD_ADMIN: <div className={styles.modalTitle}>Shto Administrator</div>,
							ADD_USER: <div className={styles.modalTitle}>Shto përdorues</div>,
						}[modalOpener]
					}
				</div>
			}
		>
			{
				{
					ADD_COMPANY: (
						<>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Emri i kompanisë"
										name="name"
										value={companyData?.name}
										onChangeHandler={companyDataChangeHandler}
									/>
									{!editMode && companyDataInStore?.name && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Email"
										name="email"
										value={companyData?.email}
										onChangeHandler={companyDataChangeHandler}
									/>
									{!editMode && companyDataInStore?.email && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Fjalëkalimi"
										name="password"
										type={passwordShown ? "text" : "password"}
										value={companyData?.password}
										onChangeHandler={companyDataChangeHandler}
									/>
									<div className={styles.eyeSvg}>
										<AiOutlineEye onClick={togglePassword}></AiOutlineEye>
									</div>
									{!editMode && companyDataInStore?.password && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={`${styles.modalInput} noArrowsNumberInput`}
										placeholder="NUI"
										name="businessNumber"
										value={companyData?.businessNumber}
										type="number"
										onChangeHandler={companyDataChangeHandler}
									/>
									{!editMode && companyDataInStore?.businessNumber && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<CustomSelect
										options={activeInactiveOptions}
										className={`${styles.customSelect}`}
										onChangeHandler={(value) =>
											setCompanyStatus((prev) => value)
										}
										selected={companyStatus}
										placeholder="Zgjedh opsionin"
										name="status"
										wideMenu={true}
										components={{ DropdownIndicator }}
										{...customSelectStyles}
										containerStyle={{
											width: "100%",
										}}
										controlStyle={{
											...customSelectStyles.controlStyle,
											width: "100%",
										}}
										valueContainerStyle={{
											...customSelectStyles.valueContainerStyle,
											flex: 1,
										}}
										singleValueStyle={{
											fontWeight: "500",
											paddingLeft: "0.5rem",
											fontSize: "18px",
											opacity: ".85",
										}}
									/>

									{!editMode && companyDataInStore?.status && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Fillimi i kontratës"
										name="registrationDate"
										type="date"
										value={companyData?.registrationDate}
										onChangeHandler={companyDataChangeHandler}
									></InputCustom>
									{!editMode && companyDataInStore?.registrationDate && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Skadimi i kontratës"
										name="expirationDate"
										type="date"
										value={companyData?.expirationDate}
										onChangeHandler={companyDataChangeHandler}
									></InputCustom>
									{!editMode && companyDataInStore?.expirationDate && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput2}
										placeholder="Fjalëkalimi për mbyllje të periudhës"
										name="code"
										value={companyData?.code}
										onChangeHandler={companyDataChangeHandler}
									/>
									{!editMode && companyDataInStore?.code && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.qbContainer}>
									<div className={styles.qbCheckbox}>
										<input type="checkbox" name="checkedQb" checked={companyData?.checkedQb} onChange={(checked) => companyDataChangeHandler(checked)} />
									</div>
									<div className={styles.qbCheckboxText}>
										Lidh me QuickBooks
									</div>
								</div>
							</div>
						</>
					),
					ADD_ADMIN: (
						<>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Emri mbiemri"
										name="name"
										value={adminData?.name}
										onChangeHandler={adminDataChangeHandler}
									></InputCustom>
									{!editMode && adminDataInStore?.name && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="E-mail"
										name="email"
										type="email"
										value={adminData?.email}
										onChangeHandler={adminDataChangeHandler}
									></InputCustom>
									{!editMode && adminDataInStore?.email && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Fjalëkalimi"
										name="password"
										type={passwordShown ? "text" : "password"}
										value={adminData?.password}
										onChangeHandler={adminDataChangeHandler}
									/>
									<div className={styles.eyeSvg}>
										<AiOutlineEye onClick={togglePassword}></AiOutlineEye>
									</div>
								</div>
							</div>
							<div className={`${styles.inputContainer}`}>
								{roleId === 1 ? (
									<div
										className={styles.inputBox}
										onFocus={(event) => {
											searchCompanies("");
										}}
									>
										<IconSearch color="#89c732" />
										<DebounceInput
											id="searchInput"
											type="search"
											minLength={1}
											aria-autocomplete="none"
											autoComplete="off"
											autoFocus={false}
											placeholder="Lidhje me kompani tjera"
											debounceTimeout={800}
											value={companySearchValue || ""}
											className={`${styles.modalInput} ${styles.searchBox}`}
											onChange={(event) => {
												setCompanySearchValue(event.target.value);
											}}
											onBlur={() => {
												setTimeout(() => {
													setShowCompaniesDropdown(false);
												}, 200);
											}}
										/>
									</div>
								) : null}

								{roleId === 1 ? (
									searchedCompaniesList &&
										showCompaniesDropdown &&
										searchedCompaniesList.length ? (
										<div className={styles.searchCompaniesResult}>
											<div className={styles.companiesContainer}>
												{searchedCompaniesList?.map((item, index) => {
													const isSelected = _.find(
														pickedCompanies,
														(pickedCompany) => pickedCompany.id === item.id
													);
													const itemStyles = classNames({
														[styles.company]: true,
														[styles.selected]: isSelected,
													});

													return (
														<div
															key={index}
															className={itemStyles}
															onClick={(event) => {
																if (isSelected) {
																	setPickedCompanies((prev) => {
																		if (prev && prev.length) {
																			return prev.filter(
																				(pickedItem) =>
																					pickedItem.id !== item.id
																			);
																		} else {
																			return [];
																		}
																	});
																} else {
																	setPickedCompanies((prev) => [...prev, item]);
																}
																setShowCompaniesDropdown(false);
															}}
														>
															{item.name}
														</div>
													);
												})}
											</div>
										</div>
									) : (
										""
									)
								) : null}

								{roleId === 1 && pickedCompanies?.length > 0 ? (
									pickedCompanies.length ? (
										<div className={styles.companiesContainer}>
											{pickedCompanies?.map((item, index) => {
												return (
													<div className={styles.company} key={index}>
														{item.name}
													</div>
												);
											})}
										</div>
									) : (
										""
									)
								) : null}
							</div>
						</>
					),
					ADD_USER: (
						<>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Emri mbiemri"
										name="name"
										value={userData?.name}
										onChangeHandler={userDataChangeHandler}
									></InputCustom>
									{!editMode && userDataInStore?.name && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Email"
										name="email"
										type="email"
										value={userData?.email}
										onChangeHandler={userDataChangeHandler}
									></InputCustom>
									{!editMode && userDataInStore?.email && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Fjalëkalimi"
										name="password"
										type={passwordShown ? "text" : "password"}
										value={userData?.password}
										onChangeHandler={userDataChangeHandler}
									></InputCustom>
									<div className={styles.eyeSvg}>
										<AiOutlineEye onClick={togglePassword}></AiOutlineEye>
									</div>
									{!editMode && userDataInStore?.password && (
										<div
											className={styles.iconContainer}
											onClick={setInputEditMode}
										>
											<IconPencil {...iconPencilStyle} />
										</div>
									)}
								</div>
							</div>
							<div className={`${styles.inputContainer}`}>
								{roleId === 1 ? (
									<div
										className={styles.inputBox}
										onFocus={(event) => {
											searchCompanies("");
										}}
									>
										<IconSearch color="#89c732" />
										<DebounceInput
											id="searchInput"
											type="search"
											minLength={1}
											aria-autocomplete="none"
											autoComplete="off"
											autoFocus={false}
											placeholder="Lidhje me kompani tjera"
											debounceTimeout={800}
											value={companySearchValue || ""}
											className={`${styles.modalInput} ${styles.searchBox}`}
											onChange={(event) => {
												setCompanySearchValue(event.target.value);
											}}
											onBlur={() => {
												setTimeout(() => {
													setShowCompaniesDropdown(false);
												}, 200);
											}}
										/>
									</div>
								) : null}

								{roleId === 1 ? (
									searchedCompaniesList &&
										showCompaniesDropdown &&
										searchedCompaniesList.length ? (
										<div className={styles.searchCompaniesResult}>
											<div className={styles.companiesContainer}>
												{searchedCompaniesList?.map((item, index) => {
													const isSelected = _.find(
														pickedCompanies,
														(pickedCompany) => pickedCompany.id === item.id
													);
													const itemStyles = classNames({
														[styles.company]: true,
														[styles.selected]: isSelected,
													});

													return (
														<div
															key={index}
															className={itemStyles}
															onClick={(event) => {
																if (isSelected) {
																	setPickedCompanies((prev) => {
																		if (prev && prev.length) {
																			return prev.filter(
																				(pickedItem) =>
																					pickedItem.id !== item.id
																			);
																		} else {
																			return [];
																		}
																	});
																} else {
																	setPickedCompanies((prev) => [...prev, item]);
																}
																setShowCompaniesDropdown(false);
															}}
														>
															{item.name}
														</div>
													);
												})}
											</div>
										</div>
									) : (
										""
									)
								) : null}

								{roleId === 1 ? (
									pickedCompanies.length ? (
										<div className={styles.companiesContainer}>
											{pickedCompanies?.map((item, index) => {
												return (
													<div className={styles.company} key={index}>
														{item.name}
														{/* <div
												className={styles.deleteItem}
												title="Fshij"
												onClick={(event) => {
													handleCompanyDelete(event, item)
													
												}
												}
											>
												<IconX />
											</div> */}
													</div>
												);
											})}
										</div>
									) : (
										""
									)
								) : null}
							</div>
						</>
					),
				}[modalOpener]
			}
		</ModalCustom>
	);
};

export default CalculationsModal;
