import { FunctionComponent, useEffect, useRef, useState } from 'react';

import styles from "./DropdownSelect.module.scss";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setBackdropState } from 'components/AppWrapper/actions';

interface Props {
    className?: string;
    menuClass?: string;
    itemClass?: string;
    activeClass?: string;
    buttonClass?: any;
    buttonText?: any;
    optionClick?: any;
    data?: any;
    children?: any;
    readOnly?: boolean;
    showArrow?: boolean;
    showTopArrow?: boolean;
    noWidth?: boolean;
    static?: boolean;
    showDropdownCallback?: any;
    showShadow?: boolean;
    showDropdown?: boolean;
    menuPosition?: string;
    wideMenu?: boolean;
    onClose?: () => void;
}

const DropdownSelect: FunctionComponent<Props> = (props) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dispatch = useDispatch();
    const dropdownMenuRef = useRef<HTMLInputElement>(null);

    const menuToggleButtonHandler = () => {
        setShowDropdown(!showDropdown);
        if(!showDropdown){
            (props.showDropdownCallback && props.showDropdownCallback())
        }else{
            dispatch(setBackdropState({ showBackdrop: false }));
            if(props.onClose){
                props.onClose();
            }
        }
    }

    const optionClickHandler = (event, item) => {
        menuToggleButtonHandler();
        props.optionClick(event, item);
    }

    useEffect(() => {
        const handleOutsideMenuClick = (e: any) => {
            if (!dropdownMenuRef?.current?.contains(e.target) && showDropdown) {
                if(props.onClose){
                    props.onClose();
                }
                menuToggleButtonHandler();
            }
        }
        if (showDropdown) window.addEventListener("click", handleOutsideMenuClick);
        return () => window.removeEventListener("click", handleOutsideMenuClick);
    }, [showDropdown, dropdownMenuRef]);

    useEffect(() => {
        setShowDropdown(prev => props.showDropdown);
    }, [props.showDropdown]);

    const wrapperClasses = classNames({
        [styles.listContainer]: true,
        [styles.noWidth]: props.noWidth,
        [styles.dropDownShown]: showDropdown,
        [props.className]: props.className,
        [styles.readOnly]: props.readOnly,
    });

    const menuClasses = classNames({
        [styles.menu]: true,
        [`${styles.active} ${props.activeClass || ""}`]: showDropdown,
        [styles.showTopArrow]: props.showTopArrow,
        [styles.showShadow]: props.showShadow,
        [styles.menuLeft]: props.menuPosition === "left",
        [styles.menuCenter]: props.menuPosition === "center",
        [styles.menuRight]: props.menuPosition === "right",
        [props.menuClass]: props.menuClass,
        [styles.wideMenu]: props.wideMenu,
    });

    return <div
        ref={dropdownMenuRef}
        className={wrapperClasses}>
        <button
            onClick={menuToggleButtonHandler}
            className={`${styles.toggleButton} 
            ${props.noWidth ? styles.noWidth : ""}`}
        >
            <div className={styles.selectContainer}>
                <div className={`${styles.selectName} ${props.noWidth ? styles.noWidth : ""}`}>
                    {props.buttonText}
                </div>
            </div>
            {
                props.showArrow &&
                <div className={styles.selectMenuIcon}>
                    {showDropdown ? <BsChevronUp strokeWidth="0.5px" /> : <BsChevronDown strokeWidth="0.5px" />}
                </div>
            }
        </button>
        <div className={menuClasses} >
            <div className={styles.listWrapper}>
                {props.children
                    ?
                    props.children
                    :
                    props?.data?.length
                        ? props?.data?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${styles.listItem} ${props.itemClass || ""}`}
                                    onClick={event => optionClickHandler(event, item)}
                                >
                                    {item?.label ? item?.label : item?.name}
                                </div>
                            )
                        })
                        :
                        <div className={styles.noOptions}>Nuk ka opsione {props.static ? "" : "tjera"}</div>
                }
            </div>
        </div>
    </div>
}

export default DropdownSelect;
