import produce from 'immer';
import { toast } from 'react-toastify';
import { LoginCompanies } from 'types/loginCompanies.type';
import * as constants from './constants';

const initialState: LoginCompanies [] = []

const loginCompaniesReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case constants.GET_LOGIN_COMPANIES:
				break;
			case constants.GET_LOGIN_COMPANIES_SUCCESS:
				draft = action.payload.data;
				break;
			case constants.GET_LOGIN_COMPANIES_ERROR:
				toast.error('Diqka shkoi gabim!');
				break;

			default: draft = initialState; break;
		}
	});

export default loginCompaniesReducer;