const scope = "melitaPayroll/reports";

export const LOAD_ORDER_PAYMENT = `${scope}/LOAD_ORDER_PAYMENT`;
export const LOAD_ORDER_PAYMENT_SUCCESS = `${scope}/LOAD_ORDER_PAYMENT_SUCCESS`;
export const LOAD_ORDER_PAYMENT_ERROR = `${scope}/LOAD_ORDER_PAYMENT_ERROR`;

export const LOAD_ORDER_PAYMENT_EXPORT_EXCEL = `${scope}/LOAD_ORDER_PAYMENT_EXPORT_EXCEL`;
export const LOAD_ORDER_PAYMENT_EXPORT_EXCEL_SUCCESS = `${scope}/LOAD_ORDER_PAYMENT_EXPORT_EXCEL_SUCCESS`;
export const LOAD_ORDER_PAYMENT_EXPORT_EXCEL_ERROR = `${scope}/LOAD_ORDER_PAYMENT_EXPORT_EXCEL_ERROR`;

export const LOAD_ORDER_PAYMENT_EXPORT_PDF = `${scope}/LOAD_ORDER_PAYMENT_EXPORT_PDF`;
export const LOAD_ORDER_PAYMENT_EXPORT_PDF_SUCCESS = `${scope}/LOAD_ORDER_PAYMENT_EXPORT_PDF_SUCCESS`;
export const LOAD_ORDER_PAYMENT_EXPORT_PDF_ERROR = `${scope}/LOAD_ORDER_PAYMENT_EXPORT_PDF_ERROR`;

export const SEND_EMAIL_PAYMENT_ORDER = `${scope}/SEND_EMAIL_PAYMENT_ORDER`;
export const SEND_EMAIL_PAYMENT_ORDER_SUCCESS = `${scope}/SEND_EMAIL_PAYMENT_ORDER_SUCCESS`;
export const SEND_EMAIL_PAYMENT_ORDER_ERROR = `${scope}/SEND_EMAIL_PAYMENT_ORDER_ERROR`;