/* eslint-disable */
import AppWrapper from "components/AppWrapper/AppWrapper.component";
import { useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import styles from "./Project.module.scss";
import * as tableStyles from "components/Table/Table.module.scss";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { IconPencil, QuickBooksIcon } from "assets/icons/icons";
import Button from "components/Button/Button.component";
import { globalState } from "types/globalState.type";
import CommonHeader from "components/partials/CommonHeader/CommonHeader.component";
import { FaChevronLeft } from "react-icons/fa";
import SearchInput from "components/SearchInput/SearchInput.component";
import Table from "components/Table/Table.component";
import { getAllProjects } from "../actions";
import { ConfigurationsState } from "types/reducers/configurations.reducer.type";
import classNames from "classnames";
import * as actions from "pages/Configurations/actions";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import Modal from "react-responsive-modal";
import InputCustom from "components/InputCustom/InputCustom.component";
import CustomSelect from "components/CustomSelect/CustomSelect.component";
import { projectOptions, customSelectStyles } from "utils/globalVariables";
import DropdownIndicator from "components/DropdownIndicator/DropdownIndicator.component";
import { ValuePair, projects } from "types/configuration.type";
import Select from "react-select";
import moment from "moment";
import { toast } from "react-toastify";
import {
	getAccounts,
	getQuickbooksItems,
	importProjectsFromQuickbooks,
} from "services/configuration.service";
import MikaSelect from "components/BbrosSelect/mikaSelect.component";
import { SelectOption } from "types/selectOption.type";
import "react-responsive-modal/styles.css";

interface Props { }

const Project: React.FC<Props> = (props) => {
	const tableColumns = [
		"Projektet",
		"Buxheti",
		"Vijat buxhetore",
		"Data e krijimit",
		"Statusi",
		"",
	];

	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);

	const hasQuickbooks = useSelector(
		(state: globalState) => state.globalReducer.hasQuickbooks
	);

	const initialProjectDataValues = {
		tenantId: tenantId,
		name: null,
		status: null,
		budgetLines: null,
		projectBudget: null,
		createdAt: null,
	};

	const dispatch = useDispatch();
	const history = useHistory();
	const state: ConfigurationsState = useSelector(
		(state: globalState) => state.configurationsReducer
	);
	const saving = useSelector(
		(state: globalState) =>
			state.configurationsReducer.modalSubmitLoading ?? false
	);
	const isDeletingLoader = useSelector(
		(state: RootStateOrAny) => state.vacationReducer.isDeletingLoader
	);

	const [searchTerm, setSearchTerm] = useState<string>("");
	const [isDeleteProjectModalOpen, setIsDeleteProjectModalOpen] =
		useState<boolean>(false);
	const [projectIdToDelete, setProjectIdToDelete] = useState<number>();
	const [projectIdForUpdate, setProjectIdForUpdate] = useState<number>();
	const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] =
		useState<boolean>(false);
	const [isUpdateProjectModalOpen, setIsUpdateProjectModalOpen] =
		useState<boolean>(false);
	const [inputs, setInputs] = useState([]);
	const [updateInputs, setUpdateInputs] = useState([]);
	const [projectDataValues, setProjectDataValues] = useState<projects>(
		initialProjectDataValues
	);
	const [projectStatus, setProjectStatus] = useState<{
		value?: number;
		label?: string;
	}>(projectOptions[0]);
	const [isProjectOpen, setIsProjectOpen] = useState<boolean[]>([]);
	const [quickbooksItems, setQuickbooksItems] = useState<SelectOption[]>([]);
	const [quickbooksAccounts, setQuickbooksAccounts] = useState<SelectOption[]>(
		[]
	);

	const projects = useMemo(() => {
		return state?.projects;
	}, [JSON.stringify(state?.projects)]);

	// const projectById = useMemo(() => {
	//   return state?.project;
	// }, [JSON.stringify(state?.project)]);

	const [projectById, setProjectById] = useState<any>({});
	const [projectData, setProjectData] = useState(null);
	const [projectStatusForUpdate, setProjectStatusForUpdate] = useState(null);
	const [isMoreThanOne, setIsMoreThanOne] = useState(false);

	const initialLoad = () => {
		dispatch(getAllProjects({ tenantId, activeProjectsOnly: false }));
	};

	useEffect(() => {
		initialLoad();
	}, []);

	useEffect(() => {
		setProjectStatus(projectData?.status);
	}, [projectData]);

	useEffect(() => {
		setProjectStatusForUpdate(projectById?.status);
	}, [projectById]);

	useEffect(() => {
		let tempArray = [];
		projects?.length > 0 &&
			projects.forEach(() => {
				tempArray.push(false);
			});
		setIsProjectOpen(tempArray);
	}, [projects]);

	const handleBackToSetup = () => {
		history.push("/configurations");
	};

	const statusTypeColorClasses = (type) => {
		return classNames({
			[styles.pending]: type === "Aktiv",
			[styles.done]: type === "Perfunduar",
		});
	};

	const handleQbConnect = () => {
		dispatch(actions.addProjectQb(tenantId));
	};

	const deleteProjectModalOpen = (projectId) => {
		dispatch(actions.setButtonText("Po"));
		setIsDeleteProjectModalOpen(true);
		setProjectIdToDelete(projectId);
	};

	const updateProjectModalOpen = (projectId) => {
		setIsUpdateProjectModalOpen(true);
		setProjectIdForUpdate(projectId);
		// dispatch(actions.getOneProject(projectId));
		setProjectById(projects?.find((project) => project.id == projectId));
	};

	useEffect(() => {
		setInputs(projectById?.budgetLines);
		setProjectData(projectById);
	}, [projectById]);

	useEffect(() => {
		console.log("here inputs", inputs);
	}, [inputs]);

	const handleDeleteCompany = () => {
		if (!projectIdToDelete) return;
		dispatch(actions.deleteProject({ projectId: projectIdToDelete, tenantId }));
		setIsDeleteProjectModalOpen(false);
		initialLoad();
	};

	const addProjectModalOpen = () => {
		setInputs([]);
		setProjectIdForUpdate(null);
		setIsCreateProjectModalOpen(true);
	};

	const addEmptyInput = () => {
		let tempArray = [...inputs];
		tempArray.push({});
		setInputs(tempArray);
	};

	const onInputChangeUpdate = () => {
		let tempArray = [...updateInputs];
		tempArray.push({});
		setUpdateInputs(tempArray);
	};

	type ProjectBudgetLine = {
		budgetLineId?: number;
		name: string;
		budget: number;
		contributionItemQuickbooksId?: string;
		expenseAccountQuickbooksId?: string;
	};

	const handleProjectInformation = () => {
		let payload = {
			userId: tenantId,
			budgetLines: [] as ProjectBudgetLine[],
		} as any;

		payload.projectId = projectIdForUpdate;

		if (projectIdForUpdate) {
			payload.name = projectData?.name;
		} else {
			payload.name = projectDataValues?.name;
		}

		if (projectIdForUpdate) {
			payload.createdAt = new Date(projectData?.createdAt);
		} else {
			payload.createdAt = new Date(projectDataValues?.createdAt);
		}

		if (projectStatus?.value) {
			payload.isActive = Boolean(projectStatus?.value);
		} else {
			payload.isActive = Boolean(projectStatus || true);
		}

		if (inputs.length !== 0) {
			inputs.forEach((input) => {
				let budgetLine = {} as ProjectBudgetLine;
				budgetLine.name = input.name;
				budgetLine.budget = input.budget;
				budgetLine.contributionItemQuickbooksId =
					input.contributionItemQuickbooksId;
				budgetLine.expenseAccountQuickbooksId =
					input.expenseAccountQuickbooksId;
				budgetLine.budgetLineId = input.id;
				payload.budgetLines.push(budgetLine);
			});
		}

		if (projectIdForUpdate) {
			payload.budget = projectData?.budget.replace(",", "");
		} else {
			payload.budget = projectDataValues?.projectBudget;
		}

		dispatch(actions.saveProject(payload));
	};

	useEffect(() => {
		if (saving) return;
		setIsCreateProjectModalOpen(false);
		setIsUpdateProjectModalOpen(false);
	}, [saving]);

	const projectDataChangeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;

		if (isCreateProjectModalOpen) {
			name &&
				setProjectDataValues((prev) => {
					return {
						...prev,
						[name]: value,
					};
				});
		} else {
			setProjectData((previous) => {
				return {
					...previous,
					[event.target.name]: event.target.value,
				};
			});
		}
	};

	function changeBudgetLineInputs(name, value, index) {
		let tempArray = [...inputs];
		tempArray[index] = {
			...tempArray[index],
			[name]: value,
		};

		let total = 0;
		tempArray.forEach((budgetLine) => {
			total += parseInt(budgetLine?.budget);
		});
		if (total > parseInt(projectDataValues?.projectBudget) && !isMoreThanOne) {
			setIsMoreThanOne(true);
			toast.warning("Keni kaluar vleren e projektit!");
		}
		setInputs([...tempArray]);
	}

	function changeBudgetLineInputsForUpdate(name, value, index) {
		let tempArray = [...updateInputs];
		tempArray[index] = {
			...tempArray[index],
			[name]: value,
		};
		setUpdateInputs([...tempArray]);
	}

	function toggleSelectedProject(index) {
		let tempArray = [...isProjectOpen];

		tempArray[index] = !tempArray[index];

		setIsProjectOpen(tempArray);
	}

	useEffect(() => {
		if (hasQuickbooks != 1 || !tenantId) return;

		const loadQuickbooksItems = async () => {
			try {
				const response = await getQuickbooksItems(tenantId);
				setQuickbooksItems(
					response.data.value?.map((item) => ({
						label: item.label,
						value: item.value,
						id: item.value,
					}))
				);
			} catch (err) {
				console.error("Failed to fetch quickbook items", err);
			}
		};
		const loadQuickbooksAccounts = async () => {
			try {
				const response = await getAccounts(tenantId);
				setQuickbooksAccounts(
					response.data.value?.map((item) => ({
						label: item.label,
						value: item.value,
						id: item.value,
					}))
				);
			} catch (err) {
				console.error("Failed to fetch quickbook accounts", err);
			}
		};
		loadQuickbooksItems();
		loadQuickbooksAccounts();
	}, [hasQuickbooks, tenantId]);

	async function importFromQuickbooks() {
		toast.info(
			"Duke importuar projektet dhe linjat buxhetore nga Quickbooks..."
		);
		try {
			const projectsWithBudgetLinesFromQuickbooksRes =
				await importProjectsFromQuickbooks(tenantId);
			let toastResult =
				"Nuk u gjet asnje linje buxhetore nga Quickbooks per te importuar!";
			if (projectsWithBudgetLinesFromQuickbooksRes.data?.saved > 0) {
				toastResult =
					"Projektet dhe linjat buxhetore u importuan me sukses nga Quickbooks! Ju lutem kontrolloni dhe caktoni konton e shpenzimeve te linjave buxhetore.";
			}

			toast.success(toastResult);
			initialLoad();
		} catch (err) {
			toast.error("Dicka shkoi keq gjate importimit nga Quickbooks!");
			console.error("error", err);
		}
	}

	return (
		<AppWrapper appContentClass={styles.projectsComponent}>
			<div className={styles.headerContainer}>
				<div className={styles.header}>
					<FaChevronLeft
						className={styles.arrowIcon}
						onClick={handleBackToSetup}
					/>
					<CommonHeader title="Projektet" />
				</div>

				<div className={`${styles.modalBody} column ${styles.projectList}`}>
					<Button
						viewType="shell"
						className={`${styles.headerButton} ${styles.search} ${styles.customButton}`}
						onClickHandler={addProjectModalOpen}
					>
						<div className={`${styles.iconContainer}`}>
							<AiOutlinePlusCircle />
						</div>
						Shto projekt
					</Button>
				</div>
				{hasQuickbooks === 1 && (
					<>
						<Button
							onClickHandler={importFromQuickbooks}
							className={`${styles.headerButton} ${styles.employeesModalButton}`}
							viewType="shell"
						>
							<div className={`${styles.iconContainer}`}>
								<QuickBooksIcon stroke="#89c732" width="1px" />
							</div>
							<div className={`${styles.buttonTitle}`}>
								Importo projektet nga Quickbooks
							</div>
						</Button>
						<Button
							onClickHandler={() => handleQbConnect()}
							className={`${styles.headerButton} ${styles.employeesModalButton}`}
							viewType="shell"
						>
							<div className={`${styles.iconContainer}`}>
								<QuickBooksIcon stroke="#89c732" width="1px" />
							</div>
							<div className={`${styles.buttonTitle}`}>
								Dergo projektet ne Quickbooks
							</div>
						</Button>
					</>
				)}
			</div>

			<div className={styles.searchWrapper}>
				<SearchInput
					className={styles.searchInputContainerPart}
					onChangeHandler={(event) => {
						setSearchTerm(event.target.value);
					}}
					searchPlaceholder="Kërko"
				/>
			</div>
			<div className={`${styles.tableContainer} ${styles.projectTable}`}>
				<Table
					tableHeader={
						<thead className={`${tableStyles.tableHead} ${styles.projectList}`}>
							<tr className={`${tableStyles.headerRow} `}>
								{tableColumns.map((item, index) => {
									return (
										<th className={`${tableStyles.headerCell}`} key={index}>
											{item}
										</th>
									);
								})}
							</tr>
						</thead>
					}
					tableWrapperClass={styles.tableWrapper}
					data={[]}
				>
					<tbody className={`${styles.tableBody} ${styles.projectList}`}>
						{projects?.length > 0 &&
							projects
								.filter((val) => {
									if (searchTerm === "") {
										return val;
									} else if (
										val.name.toLowerCase().includes(searchTerm.toLowerCase())
									) {
										return val;
									}
								})
								.map((project, idx) => {
									return (
										<tr
											className={classNames(
												`${styles.tableRow} ${styles.projectsTableRow} ${isProjectOpen[idx]}`,
												{
													[styles.tableRowOpened]: isProjectOpen[idx],
													[styles.tableRowClosed]: !isProjectOpen[idx],
												}
											)}
											key={idx}
										>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{project?.name}
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{project?.budget}€
											</td>
											<td
												className={`${styles.bodyCell} ${styles.text} ${styles.budgetPrice}`}
											>
												{isProjectOpen[idx] ? (
													<>
														{project?.budgetLines?.length > 0 &&
															project?.budgetLines?.map((line, index) => {
																return (
																	<div
																		className={styles.budgetContainer}
																		key={index}
																	>
																		<p className={`${styles.budgetLineInfo}`}>
																			{line?.name} &nbsp;&nbsp;{line?.budget}€
																		</p>
																	</div>
																);
															})}
													</>
												) : (
													<>
														<div className={styles.budgetContainer}>
															{project?.budgetLines !== null && (
																<p className={`${styles.budgetLineInfo}`}>
																	{project?.budgetLines?.[0]?.name} &nbsp;&nbsp;
																	{project?.budgetLines?.[0]?.budget}€
																	{project?.budgetLines?.length >= 1 &&
																		!isProjectOpen[idx] && <span>...</span>}
																</p>
															)}
														</div>
													</>
												)}
											</td>
											<td
												className={`${styles.bodyCell} ${styles.text} ${styles.budgetCreatedAt}`}
											>
												<div className={styles.budgetContainer}>
													{project?.budgetLines !== null && (
														<p className={`${styles.budgetLineInfo}`}>
															{moment(project?.createdAt).format("DD/MM/YYYY")}
														</p>
													)}
												</div>
											</td>
											<div className={styles.statusContainer}>
												<div
													className={statusTypeColorClasses(
														project?.statusName
													)}
												></div>
												<div className={styles.inputCircle}>
													<td className={`${styles.bodyCell} ${styles.text}`}>
														{project?.statusName}
													</td>
												</div>
											</div>

											<td style={{ width: "100px" }}>
												<div className={styles.pencilIcon}>
													<div
														className={styles.pencil}
														onClick={() => updateProjectModalOpen(project.id)}
													>
														<IconPencil fill="#89C732" />
													</div>
													<div>
														<FiTrash2
															onClick={() => deleteProjectModalOpen(project.id)}
														/>
													</div>
												</div>
											</td>
											<div
												className={`${styles.projectsToggleDiv}`}
												onClick={() => {
													toggleSelectedProject(idx);
												}}
											></div>
										</tr>
									);
								})}
					</tbody>
				</Table>

				{isDeleteProjectModalOpen && (
					<ModalCustom
						show={isDeleteProjectModalOpen}
						contentClass={styles.contentClass}
						submitLoading={isDeletingLoader}
						footerClass={styles.footerClass}
						onClose={() => {
							setIsDeleteProjectModalOpen((prev) => false);
						}}
						hideFooterButton={false}
						submitText={"Po"}
						onSubmit={() => handleDeleteCompany()}
					>
						<div className={styles.deleteCompanyMessage}>
							Jeni të sigurtë që doni të fshini këtë projekt?
						</div>
					</ModalCustom>
				)}

				{isCreateProjectModalOpen || isUpdateProjectModalOpen ? (
					<Modal
						open={isCreateProjectModalOpen || isUpdateProjectModalOpen}
						styles={{
							modal: {
								maxWidth: "1600px",
								zIndex: 1099,
								borderRadius: "10px", // Adjust this value to increase or decrease the roundness
								padding: "20px", // Optional: if you want some padding inside the modal
							},
							overlay: {
								background: "rgba(0, 0, 0, 0.5)", // Optional: if you want to customize the overlay background
							},
							closeButton: {
								cursor: "pointer", // Optional: additional style for the close button
							},
						}}
						center
						// contentClass={styles.contentClass}
						// submitLoading={isDeletingLoader}
						// footerClass={styles.footerClass}
						onClose={() => {
							setIsCreateProjectModalOpen((prev) => false);
							setIsUpdateProjectModalOpen((prev) => false);
						}}
					// hideFooterButton={false}
					// submitText={"Ruaj"}
					// header={
					//   <div className={styles.modalHeader}>
					//     <div className={styles.modalTitle}>
					//       {isCreateProjectModalOpen ? "Shto Projekt" : "Edito Projekt"}
					//     </div>
					//   </div>
					// }
					// onSubmit={() => handleProjectInformation()}
					>
						<>
							<div className={styles.modalHeader}>
								<div className={styles.modalTitle}>
									{isCreateProjectModalOpen ? "Shto Projekt" : "Edito Projekt"}
								</div>
							</div>
							<div className={styles.addProjectContainer}>
								<div className={styles.inputContainer}>
									<div className={styles.inputBox}>
										<InputCustom
											className={styles.modalInput}
											placeholder="Projekti"
											name="name"
											value={
												isUpdateProjectModalOpen
													? projectData?.name
													: projectDataValues?.name
											}
											onChangeHandler={projectDataChangeHandler}
										/>
									</div>
								</div>
								<div className={styles.inputContainer}>
									<div className={styles.inputBox}>
										<InputCustom
											className={styles.modalInput}
											placeholder="Vlera e buxhetit mujore"
											name={
												isUpdateProjectModalOpen ? "budget" : "projectBudget"
											}
											value={
												isUpdateProjectModalOpen
													? projectData?.budget
													: projectDataValues?.projectBudget
											}
											onChangeHandler={projectDataChangeHandler}
										/>
									</div>
								</div>
							</div>
							<div className={styles.projectStatusContainer}>
								<div className={styles.inputContainer}>
									<div className={styles.inputBox}>
										<InputCustom
											className={styles.modalInput}
											name="createdAt"
											type="date"
											value={
												isUpdateProjectModalOpen
													? moment(projectData?.createdAt).format("yyyy-MM-DD")
													: projectDataValues?.createdAt
											}
											onChangeHandler={projectDataChangeHandler}
										/>
									</div>
								</div>
								<div className={styles.inputContainer}>
									<div className={styles.inputBox}>
										<CustomSelect
											options={projectOptions}
											className={`${styles.customSelect}`}
											onChangeHandler={(value) =>
												setProjectStatus((prev) => value)
											}
											placeholder={
												isCreateProjectModalOpen
													? "Zgjedh statusin"
													: projectData?.statusName
											}
											name="status"
											wideMenu={true}
											components={{ DropdownIndicator }}
											{...customSelectStyles}
											containerStyle={{
												width: "100%",
											}}
											controlStyle={{
												...customSelectStyles.controlStyle,
												width: "100%",
											}}
											valueContainerStyle={{
												...customSelectStyles.valueContainerStyle,
												flex: 1,
											}}
											singleValueStyle={{
												fontWeight: "500",
												paddingLeft: "0.5rem",
												fontSize: "18px",
												opacity: ".85",
											}}
										/>
									</div>
								</div>
							</div>
							<hr className={styles.hrLine} />
							<Button
								viewType="shell"
								className={`${styles.headerButtonBugdetLine} ${styles.search} ${styles.customButton}`}
								onClickHandler={addEmptyInput}
							>
								<div className={`${styles.iconContainer}`}>
									<AiOutlinePlusCircle />
								</div>
								Shto vijen buxhetore
							</Button>

							<div className={styles.inputsContainer}>
								{inputs?.map((currentInput, index) => {
									let contributionItemQuickbooksValue = null;
									let expenseAccountQuickbooksValue = null;
									if (hasQuickbooks == 1) {
										if (
											currentInput?.expenseAccountQuickbooksId?.length > 0
										) {
											expenseAccountQuickbooksValue = {
												id: currentInput?.expenseAccountQuickbooksId,
												label: quickbooksAccounts?.find(
													(x) =>
														x.value ==
														currentInput?.expenseAccountQuickbooksId
												)?.label,
												value: currentInput?.expenseAccountQuickbooksId,
											};
										}

										if (
											currentInput?.contributionItemQuickbooksId?.length > 0
										) {
											contributionItemQuickbooksValue = {
												id: currentInput?.contributionItemQuickbooksId,
												label: quickbooksItems?.find(
													(x) =>
														x.value ==
														currentInput?.contributionItemQuickbooksId
												)?.label,
												value: currentInput?.contributionItemQuickbooksId,
											};
										}
									}

									return (
										<div className={styles.budgetLineContainer} key={index}>
											<div className={styles.inputContainer}>
												<div className={styles.inputBoxBudgetLine}>
													<InputCustom
														className={styles.modalInput}
														value={currentInput?.name}
														onChangeHandler={(e) => {
															changeBudgetLineInputs(
																"name",
																e.target.value,
																index
															);
														}}
													/>
												</div>
											</div>
											<div className={styles.inputContainer}>
												<div className={styles.inputBox}>
													<InputCustom
														className={styles.modalInput}
														placeholder="Vlera mujore"
														value={currentInput?.budget}
														onChangeHandler={(e) => {
															changeBudgetLineInputs(
																"budget",
																e.target.value,
																index
															);
														}}
													/>
												</div>
											</div>
											{hasQuickbooks == 1 && (
												<>
													<div className={styles.inputContainer}>
														<Select
															styles={{
																control: (base) => {
																	return {
																		...base,
																		width: 350,
																		border: "0px",
																		borderRadius: 16,
																		padding: "1rem 1.5rem",
																		fontWeight: 500,
																		boxShadow: "2px 8px 16px #18327314",
																	};
																},
															}}
															options={quickbooksItems}
															value={contributionItemQuickbooksValue}
															onChange={(option) => {
																let tempArray = [...inputs];
																tempArray[index] = {
																	...tempArray[index],
																	contributionItemQuickbooksId: option?.value,
																};

																setInputs(tempArray);
															}}
															isClearable
															noOptionsMessage={() =>
																"Nuk u gjet asnje produkt nga Quickbooks"
															}
															menuPosition="fixed"
															placeholder="Zgjedh produktin e kontributit pensional . . ."
														/>
													</div>
													<div className={styles.inputContainer}>
														<Select
															styles={{
																control: (base) => {
																	return {
																		...base,
																		width: 350,
																		border: "0px",
																		borderRadius: 16,
																		padding: "1rem 1.5rem",
																		fontWeight: 500,
																		boxShadow: "2px 8px 16px #18327314",
																	};
																},
															}}
															options={quickbooksAccounts}
															value={expenseAccountQuickbooksValue}
															onChange={(option) => {
																let tempArray = [...inputs];
																tempArray[index] = {
																	...tempArray[index],
																	expenseAccountQuickbooksId: option?.value,
																};

																setInputs(tempArray);
															}}
															isClearable
															noOptionsMessage={() =>
																"Nuk u gjet asnje konto nga Quickbooks"
															}
															menuPosition="fixed"
															placeholder="Kontoja e shpenzimit . . ."
														/>
													</div>
												</>
											)}
										</div>
									);
								})}
							</div>
							<div style={{ display: "flex", justifyContent: "end" }}>
								<Button
									onClickHandler={() => handleProjectInformation()}
									className={`${styles.btn} ${styles.submitBtn}`}
									loading={saving ?? false}
									fixDimensions={false}
								>
									Ruaj
								</Button>
							</div>
						</>
					</Modal>
				) : null}
			</div>
		</AppWrapper>
	);
};

export default Project;
