const scope = "melitaPayroll/holidays";

export const LOAD_VACATIONS_ALL = `${scope}/LOAD_VACATIONS_ALL`;
export const LOAD_VACATIONS_ALL_SUCCESS = `${scope}/LOAD_VACATIONS_ALL_SUCCESS`;
export const LOAD_VACATIONS_ALL_ERROR = `${scope}/LOAD_VACATIONS_ALL_ERROR`;
export const LOAD_CALENDAR_VACATIONS_ALL = `${scope}/LOAD_CALENDAR_VACATIONS_ALL`;
export const LOAD_CALENDAR_VACATIONS_SUCCESS = `${scope}/LOAD_CALENDAR_VACATIONS_SUCCESS`;
export const LOAD_CALENDAR_VACATIONS_ERROR = `${scope}/LOAD_CALENDAR_VACATIONS_ERROR`;
export const LOAD_VACATION_TYPES_ALL = `${scope}/LOAD_VACATION_TYPES_ALL`;
export const LOAD_VACATION_TYPES_ALL_SUCCESS = `${scope}/LOAD_VACATION_TYPES_ALL_SUCCESS`;
export const LOAD_VACATION_TYPE_ALL_ERROR = `${scope}/LOAD_VACATION_TYPE_ALL_ERROR`;
export const LOAD_NEW_VACATION = `${scope}/LOAD_NEW_VACATION`;
export const LOAD_NEW_VACATION_SUCCESS = `${scope}/LOAD_NEW_VACATION_SUCCESS`;
export const LOAD_NEW_VACATION_ERROR = `${scope}/LOAD_NEW_VACATION_ERROR`;
export const LOAD_DELETE_VACATION = `${scope}/LOAD_DELETE_VACATION`;
export const LOAD_DELETE_VACATION_SUCCESS = `${scope}/LOAD_DELETE_VACATION_SUCCESS`;
export const LOAD_DELETE_VACATION_ERROR = `${scope}/LOAD_DELETE_VACATION_ERROR`;
export const LOAD_UPDATE_VACATION = `${scope}/LOAD_UPDATE_VACATION`;
export const LOAD_UPDATE_VACATION_SUCCESS = `${scope}/LOAD_UPDATE_VACATION_SUCCESS`;
export const LOAD_UPDATE_VACATION_ERROR = `${scope}/LOAD_UPDATE_VACATION_ERROR`;
export const LOAD_SINGLE_VACATION = `${scope}/LOAD_SINGLE_VACATION`;
export const LOAD_SINGLE_VACATION_SUCCESS = `${scope}/LOAD_SINGLE_VACATION_SUCCESS`;
export const LOAD_SINGLE_VACATION_ERROR = `${scope}/LOAD_SINGLE_VACATION_ERROR`;
