import React from "react";
import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
	IconDollar,
	IconDocument,
	IconPaperclip,
	IconUserPlus,
	QuickBooksIcon,
	IconDollarFilled,
	IconTrendingUp,
	IconNoBirthdays,
	IconNoBookings,
} from "assets/icons/icons";

import styles from "./Dashboard.module.scss";
import buttonStyle from "components/Button/Button.module.scss";

import AppWrapper from "components/AppWrapper/AppWrapper.component";
import Card from "components/Card/Card.component";
import { Shortcut } from "./Shortcut/Shortcut.component";
import { IconWrapper } from "components/IconWrapper/IconWrapper.component";
import { colorsArray, menuIconsColor, todayDate } from "utils/globalVariables";
import NumberCard from "components/NumberCard/NumberCard.component";
import CalculationsCard from "./CalculationsCard/CalculationsCard.component";
import DropdownMenu from "components/partials/DropdownMenu/DropdownMenu.component";

import { ExpiredContract } from "types/expiredContract.type";
import { UpcomingBirthday } from "types/upcomingBirthday.type";
import { UpcomingVacation } from "types/upcomingVacation.type";
import {
	loadUpcomingBirthdays,
	loadUpcomingVacations,
	loadExpiredContracts,
	loadSalaryCardsData,
} from "./actions";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Loader from "components/partials/Loader/Loader.component";
import { toggleModalState as toggleModalStateAppwrapper } from "components/AppWrapper/actions";
import { toggleModalState } from "pages/Employees/actions";
import EmployeesModal from "pages/Employees/EmployeeModal/EmployeeModal.component";
import { globalState } from "types/globalState.type";
import dashboardState from "types/reducers/dashboard.reducer.type";
import { formatNumber_, number_format } from "utils/functions";
import LoaderCentered from "components/partials/LoaderCentered/LoaderCentered.component";
import ImageElement from "components/ImageElement/ImageElement.component";
import IconMissingContract from "assets/icons/undraw_terms_re_6ak4.svg";
import IconMissingBirthdays from "assets/icons/undraw_gifts_re_97j6.svg";
import IconMissingVacations from "assets/icons/undraw_booking_re_gw4j.svg";
import { logOut } from "App/actions";
import { useTranslation } from "react-i18next";

const Dashboard: React.FC = () => {
	const { t, i18n } = useTranslation("common");
	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);

	let colorPosition = -1;
	const dispatch = useDispatch();
	const history = useHistory();

	const state: dashboardState = useSelector(
		(state: globalState) => state.dashboardReducer
	);

	const upcomingBirthdays = state.upcomingBirthdays;
	const upcomingVacations = state.upcomingVacations;
	const expiredContracts = state.expiredContracts;

	useEffect(() => {
		if (localStorage.getItem("token")) {
			dispatch(loadSalaryCardsData(tenantId));
			dispatch(loadExpiredContracts(tenantId));
			dispatch(loadUpcomingBirthdays(tenantId));
			dispatch(loadUpcomingVacations(tenantId));
		}
	}, [dispatch]);

	const setShowEmployeeModal = (state: boolean, row: any = { id: 0 }) => {
		dispatch(toggleModalState({ state, row }));
		dispatch(toggleModalStateAppwrapper({ state, row }));
	};

	const appWrapperReducer = useSelector(
		(state: RootStateOrAny) => state.appWrapperReducer
	);
	const employeesReducer = useSelector(
		(state: RootStateOrAny) => state.employeesReducer
	);

	const showEmployeeModal: boolean =
		appWrapperReducer.showEmployeeModal && employeesReducer.showEmployeeModal;

	return (
		<AppWrapper appContentClass={styles.appContent}>
			<div className={styles.dashboard}>
				<Card className={styles.shortcutsCard} title={t("mainPage.shortCuts")}>
					<Shortcut
						title={t("mainPage.calculateSalarys")}
						link="calculations"
						toolTip="Kalkuluesi i pagave në muaj"
					>
						<IconDollar />
					</Shortcut>
					<Shortcut
						onClickHandler={() => setShowEmployeeModal(true, { id: 0 })}
						title={t("mainPage.addEmployees")}
						link="Punojnës"
						toolTip="Shto Punonjes"
					>
						<IconUserPlus />
					</Shortcut>
					<a
						href={"https://edeklarimi.atk-ks.org/Account/Login?ReturnUrl=%2F"}
						target={"_blank"}
						className={styles.externalLink}
					>
						<Shortcut
							title={t("mainPage.declareTaxes")}
							toolTip="Deklaro Tatimet"
							onClickHandler={() => { }}
						>
							<IconPaperclip stroke={menuIconsColor} width="1px" />
						</Shortcut>
					</a>
					<Shortcut
						title={t("mainPage.accountingQuickBooks")}
						link="calculations"
						toolTip="Integrimi me Quickbooks"
					>
						<QuickBooksIcon stroke="#89c732" width="1px" />
					</Shortcut>
				</Card>
				<div className={styles.rightCards}>
					<Card className={`${styles.shortCard} ${styles.widget}`}>
						<div
							className={`${styles.widgetContent} ${!state.salariesTillNowData ? "h-center v-center" : ""
								}`}
						>
							{state?.salaryCardsLoading ? (
								<LoaderCentered />
							) : state?.salariesTillNowData ? (
								<>
									<div className={`${styles.top}`} key={0}>
										<NumberCard
											title={t("mainPage.salarysUntilNow")}
											number={number_format(
												state?.salariesTillNowData?.salariesTillNow || 0,
												2
											)}
											numberClass={styles.numberCardNumber}
											titleClass={styles.numberCardTitle}
											cardClass={styles.numberCard}
										/>
										<IconWrapper
											className={`${styles.lineIconCustom} ${styles.svgType} ${styles.greenBg} cursorAuto`}
										>
											<IconTrendingUp fill="#89c732" />
										</IconWrapper>
									</div>
									<div className="row" key={1}>
										<div className={styles.percentageNumber}>
											{formatNumber_(
												state.salariesTillNowData?.percentageDif || 0,
												2
											) < 0
												? formatNumber_(
													state.salariesTillNowData?.percentageDif || 0,
													2
												)
												: "+" +
												formatNumber_(
													state.salariesTillNowData?.percentageDif || 0,
													2
												)}
											%
										</div>
										<div className="f15">{t("mainPage.fromLastMonth")}</div>
									</div>
								</>
							) : (
								""
							)}
						</div>
					</Card>

					<Card className={`${styles.shortCard} ${styles.widget}`}>
						<div
							className={`${styles.widgetContent} ${!state.averageSalaryData ? "h-center v-center" : ""
								}`}
						>
							{state?.salaryCardsLoading ? (
								<LoaderCentered />
							) : (
								<>
									<div className={`${styles.top}`} key={0}>
										<NumberCard
											title={t("mainPage.averageSalary")}
											number={number_format(
												state?.averageSalaryData?.averageSalaries || 0,
												2
											)}
											numberClass={styles.numberCardNumber}
											titleClass={styles.numberCardTitle}
											cardClass={styles.numberCard}
										/>
										<IconWrapper
											className={`${styles.lineIconCustom} ${styles.svgType} ${styles.purpleBg} cursorAuto`}
										>
											<IconDollarFilled />
										</IconWrapper>
									</div>
									<div className="row" key={1}>
										<div className={styles.percentageNumber}>
											{formatNumber_(
												state.averageSalaryData?.percentageDif || 0,
												2
											) < 0
												? formatNumber_(
													state.averageSalaryData?.percentageDif || 0,
													2
												)
												: "+" +
												formatNumber_(
													state.averageSalaryData?.percentageDif || 0,
													2
												)}
											%
										</div>
										<div className={`${styles.percentageText} f15`}>
											{t("mainPage.fromLastMonth")}
										</div>
									</div>
								</>
							)}
						</div>
					</Card>
				</div>

				<CalculationsCard />

				<div className={styles.bottomCardsContainer}>
					<div className={styles.bottomContainer}>
						<Card
							className={styles.bottomCard}
							title={t("mainPage.contractExpiration")}
							titleClass={styles.cardTitle}
							cardContentClass={styles.bottomCardContent}
						>
							<DropdownMenu>
								<Link
									to="employees"
									className={`${styles.threeDotsButton} ${buttonStyle.button_}`}
								>
									{" "}
									{t("employees.employees")}{" "}
								</Link>
							</DropdownMenu>
							<div className={styles.columnWrapper}>
								{!expiredContracts ? (
									<div className={styles.loaderWrapper}>
										<Loader />
									</div>
								) : expiredContracts.length ? (
									expiredContracts?.map(
										(expiredContract: ExpiredContract, index) => {
											colorPosition++;
											if (colorPosition > colorsArray.length - 1)
												colorPosition = 0;
											return (
												<div
													className={`${styles.userDataContainer} ${styles.expiredContract}`}
													key={index}
												>
													<NumberCard
														title={`${expiredContract.firstName} ${expiredContract.lastName}`}
														number={format(
															new Date(expiredContract.expirationDate),
															"dd.MM.yyyy"
														)}
														cardClass={`${styles.numberCard} ${styles.contractsNumberCard}`}
														numberClass={styles.numberCardNumber}
														titleClass={styles.numberCardTitle}
														styles={{ borderColor: colorsArray[colorPosition] }}
													/>
												</div>
											);
										}
									)
								) : (
									<div className={styles.noContractsContent}>
										{t("mainPage.noExpiration")}
										<img src={IconMissingContract} alt="" />
									</div>
								)}
							</div>
						</Card>
					</div>
					<div className={styles.bottomContainer}>
						<Card
							className={styles.bottomCard}
							title={t("mainPage.birthdays")}
							titleClass={styles.cardTitle}
							cardContentClass={styles.bottomCardContent}
						>
							<DropdownMenu>
								<Link
									to="employees"
									className={`${styles.threeDotsButton} ${buttonStyle.button_}`}
								>
									{" "}
									{t("employees.employees")}{" "}
								</Link>
							</DropdownMenu>
							{upcomingBirthdays?.count ? (
								<div className={styles.birthdaysCount}>
									{upcomingBirthdays?.count}{" "}
								</div>
							) : (
								""
							)}
							<div className={styles.columnWrapper}>
								{!upcomingBirthdays?.birthdays ? (
									<div className={styles.loaderWrapper}>
										<Loader />
									</div>
								) : upcomingBirthdays.birthdays.length ? (
									upcomingBirthdays?.birthdays.map(
										(upcomingBirthday: UpcomingBirthday, index) => {
											return (
												<div className={styles.userDataContainer} key={index}>
													<NumberCard
														title={`${upcomingBirthday.firstName} ${upcomingBirthday.lastName}`}
														number={format(
															new Date(upcomingBirthday.birthDate),
															"dd.MM.yyyy"
														)}
														cardClass={styles.numberCard}
														numberClass={styles.numberCardNumber}
														titleClass={styles.numberCardTitle}
													/>
													<div className={styles.cardImagesContainer}>
														<ImageElement
															imageLink={upcomingBirthday.photo}
															attributes={{
																title: [upcomingBirthday.photo],
															}}
														/>
													</div>
												</div>
											);
										}
									)
								) : (
									<div className={styles.noBirthdaysContent}>
										<div>
											<span className={`color-green`}></span>
											<br /> {t("mainPage.noBirthdays")} <br />
										</div>
										<div>
											<img src={IconMissingBirthdays} alt="" />
										</div>
									</div>
								)}
							</div>
						</Card>
					</div>
					<div className={styles.bottomContainer}>
						<Card
							className={`${styles.bottomCard} ${styles.cardVacations}`}
							title={t("mainPage.restDays")}
							titleClass={styles.cardTitle}
							cardContentClass={`${styles.bottomCardContent} ${styles.holidaysCardContent}`}
						>
							<DropdownMenu>
								<Link
									to="holidays"
									className={`${styles.threeDotsButton} ${buttonStyle.button_}`}
								>
									{t("mainPage.restDays")}
								</Link>
							</DropdownMenu>
							{upcomingVacations?.length ? (
								<div className={styles.todayDate}> {todayDate} </div>
							) : (
								""
							)}
							<div
								className={`${styles.columnWrapper} ${upcomingVacations && upcomingVacations.length
									? styles.unsetHeight
									: ""
									}`}
							>
								{!upcomingVacations ? (
									<div className={styles.loaderWrapper}>
										<Loader />
									</div>
								) : upcomingVacations.length ? (
									upcomingVacations?.map((item: UpcomingVacation, index) => {
										return (
											<div className={styles.userDataContainer} key={index}>
												<div className={styles.cardVacationsImage}>
													<ImageElement
														imageLink={item.employeePhoto}
														attributes={{
															title: [item.employeePhoto],
														}}
													/>
												</div>
												<NumberCard
													title={item.employeeName}
													number={format(new Date(item.from), "dd.MM.yyyy")}
													cardClass={styles.numberCard}
													numberClass={styles.numberCardNumber}
													titleClass={styles.numberCardTitle}
												/>
												<div className={styles.cardVacationsTitle}>
													{format(new Date(item.from), "dd.MM.yyyy")}
												</div>
											</div>
										);
									})
								) : (
									<div className={styles.nobookingsContent}>
										{t("mainPage.noBookings")}
										<div>
											<img src={IconMissingVacations} alt="" />
										</div>
										{/* <small>
                      {t("mainPage.everyone")} <br />
                      {t("mainPage.atWork")}
                    </small> */}
									</div>
								)}
							</div>
						</Card>
					</div>
				</div>
			</div>
			<EmployeesModal
				showEmployeeModal={showEmployeeModal}
				setShowEmployeeModal={setShowEmployeeModal}
			/>
		</AppWrapper>
	);
};

export default Dashboard;
