import userImageLink from "assets/images/user.png";
import { format, getYear } from "date-fns";
import { FamilyMemberType, SalaryPaymentType } from "types/employee.type";
import { getDate } from "utils/getDate";

export const menuIconsColor: string = "#89c732";
export const colorsArray = [
  "#89c732",
  "#d52d0f",
  "#7764E4",
  "#feb969",
  "#261f63",
  "#11cdef",
];

export const todayDate = getDate();
export const thisYear = getYear(new Date());
export const futureLimitDate = format(
  new Date(thisYear + 100 + "-01-01"),
  "yyyy-MM-dd"
);

export const validNumber = /^(?=.*[0-9])[+0-9]+$/;
export const validEmail =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const validPhone = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
export const companies = [
  {
    id: 0,
    name: "Company Company1",
    selected: true,
  },
  {
    id: 1,
    name: "Company Company2",
    selected: false,
  },
];

export const userData = {
  id: 0,
  name: "User user",
  image: userImageLink,
};

export const employerTypeOptions = [
  {
    value: 1,
    label: "Primar",
  },
  {
    value: 2,
    label: "Sekondar",
  },
];

export const employmentTypeOptions = [
  {
    value: 1,
    label: "Full Time",
  },
  {
    value: 2,
    label: "Part Time",
  },
  {
    value: 3,
    label: "Contractor",
  },
];

export const salaryPaymentTypeOptions = [
  {
    value: SalaryPaymentType.Fixed,
    label: "Fikse",
  },
  {
    value: SalaryPaymentType.Hourly,
    label: "Orë",
  },
  {
    value: SalaryPaymentType.Project,
    label: "Projekt",
  },
] as SalaryPaymentTypeOptions[];

type SalaryPaymentTypeOptions = {
  value: SalaryPaymentType;
  label: string;
}

export const yesNoOptions = [
  {
    value: 1,
    label: "Po",
  },
  {
    value: 2,
    label: "Jo",
  },
  {
    value: null,
    label: "Zgjedh",
  },
];

export const genderOptions = [
  {
    value: 1,
    label: "Femër",
  },
  {
    value: 2,
    label: "Mashkull",
  },
];

export const contractOptions = [
  {
    value: 1,
    label: "Afat të pacaktuar",
  },
  {
    value: 2,
    label: "Afat të caktuar",
  },
];

export const activeInactiveOptions = [
  {
    value: 1,
    label: "Aktiv",
  },
  {
    value: 2,
    label: "Joaktiv",
  },
];

export const projectOptions = [
  {
    value: 1,
    label: "Aktiv",
  },
  {
    value: 2,
    label: "Perfunduar",
  },
];

export const accounts = [
  {
    value: 1,
    label: "Marketing",
  },
  {
    value: 2,
    label: "Finance",
  },
  {
    value: 3,
    label: "IT",
  },
  {
    value: 4,
    label: "Administrate",
  },
];

export const allFamilyMemberOptions = [
  {
    value: FamilyMemberType.Partner,
    label: "Bashkëshort",
  },
  {
    value: FamilyMemberType.Children,
    label: "Fëmijë nën 18 vjeç",
  },
  {
    value: FamilyMemberType.AdultsChildren,
    label: "Fëmijë mbi 18 vjeç",
  },
  {
    value: FamilyMemberType.Parents,
    label: "Prindër",
  },
] as FamilyMemberOption[];

export interface FamilyMemberOption {
  value: FamilyMemberType;
  label: string;
}

export const customSelectStyles = {
  singleValueStyle: {
    position: "relative",
    top: 0,
    display: "block",
    transform: "unset",
    maxWidth: "unset",
    paddingRight: "8px",
    margin: "0",
  },
  optionsStyle: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    fontSize: "17px",
    opacity: "1",
  },
  dropdownIndicatorStyle: {
    padding: "0",
    width: "unset",
    height: "unset",
  },
  controlStyle: {
    padding: 0,
    height: "fit-content",
    width: "fit-content",
    borderRadius: "20px",
  },
  valueContainerStyle: {
    padding: 0,
    paddingLeft: 0,
    flex: "unset",
    flexWrap: "nowrap",
  },
  menuListStyle: {
    borderRadius: "10px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 3px #0000000D",
    border: "1px solid #89C732",
    opacity: 1,
    minWidth: "fit-content",
  },
  placeholderStyle: {
    position: "relative",
    transform: "unset",
    top: "unset",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "100%",
    margin: 0,
    whiteSpace: "nowrap",
    minWidth: "150px",
  },
};

export const inputOnFocusSelect = (event) => event?.target?.select();

export const inputNumberKeydownHandler = (event) => {
  if (
    (event.target.value.includes(".") &&
      (event.key === "." || event.key === ",")) ||
    (!/^[0-9.,\b]+$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Tab" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight" &&
      !(
        (event.code === "KeyA" ||
          event.code === "KeyC" ||
          event.code === "KeyX") &&
        (event.CtrlKey || event.metaKey)
      ))
  ) {
    event.preventDefault();
    return false;
  }
};

export const EmployeeFields = [
  "id",
  "tenantId",
  "photo",
  "firstName",
  "lastName",
  "personalNumber",
  "birthDate",
  "gender",
  "phone",
  "qualification",
  "address",
  "municipality",
  "email",
  "employerType",
  "healthInsurance",
  "insurancePercentage",
  "familyMember",
  "salaryPaymentType",
  "bruttoSalary",
  "nettoSalary",
  "bank",
  "bankAccountNumber",
  "hourlyValue",
  "occupation",
  "jobStarted",
  "contractStarted",
  "expirationContract",
  "sector",
  "familyContact",
  "employmentType",
  "status",
  "contract",
  "draftStatus",
];

export const DummyDataProjects = [
  {
    value: 1,
    label: "Project1",
  },
  {
    value: 2,
    label: "Project2",
  },
];
export const valuePercentageProjectSwitch = [
  {
    value: 1,
    label: "%",
  },
  {
    value: 2,
    label: "€",
  },
];

export const downloadTypeOptions = [
  {
    value: 1,
    label: "Excel",
  },
  {
    value: 2,
    label: "PDF",
  },
];

export const testData = ["Option1", "Option2", "Option3"];
export const monthNamesAL = [
  "Janar",
  "Shkurt",
  "Mars",
  "Prill",
  "Maj",
  "Qershor",
  "Korrik",
  "Gusht",
  "Shtator",
  "Tetor",
  "Nëntor",
  "Dhjetor",
];
export const monthNamesEN = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const expiredContractsFakeData = {
  data: [
    { id: 9, firstName: "Rina", lastName: "e", expirationDate: "2021.10.17" },
    { id: 11, firstName: "Rina", lastName: "e", expirationDate: "2021.11.17" },
    { id: 12, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 13, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 14, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 15, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 16, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 17, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 18, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 19, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 21, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 22, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 23, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 24, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 25, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 26, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 27, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 28, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 29, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 30, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 31, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 32, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 33, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 34, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 35, firstName: "f", lastName: "e", expirationDate: "2022.11.17" },
    { id: 37, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 38, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 39, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 40, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 41, firstName: "Rina", lastName: "e", expirationDate: "2022.11.17" },
    { id: 42, firstName: "Elda", lastName: "e", expirationDate: "2022.11.17" },
    { id: 43, firstName: "Elda", lastName: "e", expirationDate: "2022.11.17" },
    { id: 44, firstName: "f", lastName: "e", expirationDate: "2022.11.17" },
    { id: 48, firstName: "Era", lastName: "e", expirationDate: "2022.11.17" },
    { id: 50, firstName: "Era", lastName: "e", expirationDate: "2022.11.17" },
  ],
};

export const SalaryTableDataRaw = [
  {
    name: { title: "Megzon Mullahasani", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
  {
    name: { title: "Test Test", subTitle: "subtitle" },
    id: "125123123",
    brutto: "70.323",
    hours: "70.323",
    additions: "70.323",
    deductions: "70.323",
    totalBrutto: "70.323",
    contributions: "70.323",
    taxableSalary: "70.323",
    tax: "70.323",
    netto: "70.323",
  },
];

export const languageList = [
  {
    value: 1,
    label: "Shqip",
  },
  {
    value: 2,
    label: "English",
  },
];
export const notificationsData = [
  {
    id: 3,
    title: "David Test",
    subTitle: "Are you available for chat? This is test data!",
  },
  {
    id: 12,
    title: "Jackie Brown",
    subTitle: "Are you available for chat? This is test data!",
  },
  {
    id: 43,
    title: "Rich Brown",
    subTitle: "Are you available for chat? This is test data!",
  },
];
export const employeeHolidays = [
  {
    id: 1,
    name: "Megzon Mullahasani",
    vacationDaysLeft: 15,
  },
  {
    id: 2,
    name: "Arber Bakija",
    vacationDaysLeft: 5,
  },
  {
    id: 3,
    name: "Qendrim Bakija",
    vacationDaysLeft: 10,
  },
  {
    id: 4,
    name: "Dorentina Batusha",
    vacationDaysLeft: 2,
  },
  {
    id: 5,
    name: "Zani Rexha",
    vacationDaysLeft: 5,
  },
  {
    id: 6,
    name: "Leke Dalladaku",
    vacationDaysLeft: 10,
  },
  {
    id: 7,
    name: "Nina Kelmendi",
    vacationDaysLeft: 6,
  },
  {
    id: 8,
    name: "Rexhep Zekaj",
    vacationDaysLeft: 2,
  },
  {
    id: 4,
    name: "Engjull Beselica",
    vacationDaysLeft: 7,
  },
  {
    id: 5,
    name: "Valdrin Hoti",
    vacationDaysLeft: 5,
  },
  {
    id: 6,
    name: "Era Mahmutxhiku",
    vacationDaysLeft: 8,
  },
  {
    id: 7,
    name: "Dorentina Batusha",
    vacationDaysLeft: 16,
  },
  {
    id: 5,
    name: "Valdrin Hoti",
    vacationDaysLeft: 5,
  },
  {
    id: 6,
    name: "Era Mahmutxhiku",
    vacationDaysLeft: 8,
  },
  {
    id: 7,
    name: "Dorentina Batusha",
    vacationDaysLeft: 16,
  },
  {
    id: 5,
    name: "Valdrin Hoti",
    vacationDaysLeft: 5,
  },
  {
    id: 6,
    name: "Era Mahmutxhiku",
    vacationDaysLeft: 8,
  },
  {
    id: 7,
    name: "Dorentina Batusha",
    vacationDaysLeft: 16,
  },
];

export const initialSalaryModalInputs = {
  id: null,
  employeeId: null,
  tenantId: null,
  name: null,
  sector: null,
  personalNumber: null,
  brutto: null,
  extras: null,
  extraHours: null,
  totalBrutto: null,
  pensionalContribution: null,
  taxableSalary: null,
  tax: 0,
  stops: null,
  netto: null,
  overtimeTable: {
    regularHours: null,
    afterWorkHours: null,
    nightHours: null,
    guardHours: null,
    weekendHours: null,
    holidaysHours: null,
    regularPrice: null,
    afterWorkPrice: null,
    nightPrice: null,
    guardPrice: null,
    weekendPrice: null,
    holidaysPrice: null,
    totalHours: null,
  },
  extraTable: {
    extraBrutto: null,
    extraNetto: null,
    healthInsurance: null,
  },
  stopTable: {
    stops: null,
    avanse: null,
    phone: null,
    ticket: null,
  },
};

export function doNothing() {}

export function fixImageError(that) {
  if (that.getAttribute("data-checkerror")) {
    that.removeAttribute("data-checkerror");
    that.src = userImageLink;
  }
}
