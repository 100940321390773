import React, { ReactElement, useEffect, useState } from "react"
import NavTabTitle from "./NavTabTitle.component"
import styles from "./NavTab.module.scss";
import SearchInput from "components/SearchInput/SearchInput.component";
import { globalState } from "types/globalState.type";
import { useSelector } from "react-redux";

type Props = {
	children: ReactElement[];
	showSearch?: boolean;
	searchChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	tabPillsClass?: string;
	tabClickHandler?: (index: number, isSelected: boolean) => void;
	tabRoot?: string;
}

const NavTabs: React.FC<Props> = (props: Props) => {
	const [selectedTab, setSelectedTab] = useState(0);

	const employeesReducer = useSelector((state: globalState) => state.employeesReducer);
	const activeTab: number = employeesReducer.activeTab;

	useEffect(() => {
		activeTab &&
			props.tabRoot &&
			props.tabRoot === "employeesPage" &&
			setSelectedTab(prev => activeTab - 1);
	}, [activeTab, props.tabRoot]);

	return (
		<div className={`${styles.pillsContainer} ${props.tabPillsClass ? props.tabPillsClass : ""}`}>
			<ul className={styles.tabList}>
				{props.children.map((item, index) => {
					return (
						<NavTabTitle
							key={index}
							title={item.props.title}
							index={index}
							isSelected={selectedTab === index}
							setSelectedTab={setSelectedTab}
							tabClickHandler={props?.tabClickHandler}
						/>
					)
				})}
				{
					props?.showSearch
						?
						<li className={styles.seachItem}>
							<SearchInput
								className={styles.search}
								onChangeHandler={props.searchChangeHandler}
							/>
						</li>
						:
						""
				}
			</ul>
			{props.children[selectedTab]}
		</div>
	)
}

export default NavTabs;