import AppWrapper from 'components/AppWrapper/AppWrapper.component';
import styles from './Reports.module.scss';
import { FunctionComponent } from 'react';
import CommonHeader from '../../components/partials/CommonHeader/CommonHeader.component';
import TemplateBox from 'components/TemplateBox/templateBox.component';
import { useHistory } from 'react-router-dom';
import ReportTemplateBox from '../../components/ReportTemplateBox/reportTemplateBox.component';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Reports: FunctionComponent = () => {
    const { t, i18n } = useTranslation('common');

    const history = useHistory();

    const handleToManagementReports = () => {
        history.push("/reports/management/");
    }

    const handleToEmployeeReports = () => {
        history.push("/reports/employee/");
    }

    const handleToTaxAdministrationReports = () => {
        history.push("/reports/tax-administration/");
    }

    return (
        <AppWrapper appContentClass={styles.reportsComponent}>
            <div className={styles.header}>
                <CommonHeader title={t('reports.reports')} />
            </div>
            <div className={styles.reportsInner}>
                <ReportTemplateBox  name={t('reports.managmentReports')} onSubmit={handleToManagementReports} />
                <ReportTemplateBox  name={t('reports.employeeReports')} onSubmit={handleToEmployeeReports}/>
                <ReportTemplateBox  name={t('reports.atkReports')} onSubmit={handleToTaxAdministrationReports}/>
            </div>
        </AppWrapper>
    );
};

export default Reports;