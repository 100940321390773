import {
	LOAD_MONTHLY_STATISTICS,
	LOAD_EXPORT_EXCEL,
	LOAD_EXPORT_PDF, SEND_EMPLOYEES_EMAIL_MONTHLY,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
	exportExcel,
	exportPdf,
	getMonthlyStatistics,
	sendMonthlySalaryEmployeesEmail
} from 'services/report.service';
import {
	loadMonthlyStatisticsSuccess,
	loadMonthlyStatisticsError,
	loadExportExcelSuccess,
	loadExportExcelError,
	loadExportPdfSuccess,
	loadExportPdfError, sendEmployeesEmailSuccess, sendEmployeesEmailError,
} from './actions';

export function* loadMonthlyStatistics(action) {
	try {
		const response = yield call(
			getMonthlyStatistics,
			action.payload.tenantId,
			action.payload.searchTerm,
			action.payload.month,
			action.payload.year,
		);
		yield put(loadMonthlyStatisticsSuccess(response));
	} catch (e) {
		yield put(loadMonthlyStatisticsError());
	}
}

export function* loadExportExcel(action) {
	try {
		const response = yield call(
			exportExcel,
			action.payload.tenantId,
			action.payload.month,
			action.payload.year,
			action.payload.name,
		);
		yield put(loadExportExcelSuccess(response));
	} catch (e) {
		yield put(loadExportExcelError());
	}
}

export function* loadExportPdf(action) {
	try {
		const response = yield call(
			exportPdf,
			action.payload.tenantId,
			action.payload.month,
			action.payload.year,
			action.payload.name,
		);
		yield put(loadExportPdfSuccess(response));
	} catch (e) {
		yield put(loadExportPdfError());
	}
}

export function* sendEmployeesEmail(action) {
	try {
		const response = yield call(
			sendMonthlySalaryEmployeesEmail,
			action.payload.tenantId,
			action.payload.month,
			action.payload.year,
			action.payload.employeeIds,
			action.payload.email,
		);
		yield put(sendEmployeesEmailSuccess(response));
	} catch (e) {
		yield put(sendEmployeesEmailError(e));
	}
}

export default function* monthlyStatisticsSaga() {
	yield takeLatest(LOAD_MONTHLY_STATISTICS, loadMonthlyStatistics);
	yield takeLatest(LOAD_EXPORT_EXCEL, loadExportExcel);
	yield takeLatest(LOAD_EXPORT_PDF, loadExportPdf);
	yield takeLatest(SEND_EMPLOYEES_EMAIL_MONTHLY, sendEmployeesEmail);
}