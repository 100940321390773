import {call, put, takeLatest} from 'redux-saga/effects';
import {GET_PAGINATED_NOTIFICATIONS, READ_ALL_NOTIFICATIONS, READ_SELECTED_NOTIFICATION} from './constants';
import {
  getNewestNotifications,
  updateAllUserNotifications,
  updateNotificationById,
} from '../../services/notification.service';
import {
  getPaginatedNotificationsError,
  getPaginatedNotificationsSuccess,
  readAllNotificationsError,
  readAllNotificationsSuccess,
  readSelectedNotificationError,
  readSelectedNotificationSuccess,
} from './actions';


function* getPaginatedNotifications(action) {
  try {
    const response = yield call(getNewestNotifications, action.payload.page, action.payload.canSeeRead);
    yield put(getPaginatedNotificationsSuccess(response.data.data, response.data.meta.total, action.payload.canSeeRead));
  } catch (e) {
    yield put(getPaginatedNotificationsError());
  }
}

function* readSelectedNotification(action){
  try{
    const response = yield call(updateNotificationById, action.payload.notificationId);

    if(response.data){
      yield put(readSelectedNotificationSuccess(action.payload.notificationId))
    }
  }catch (e){
    yield put(readSelectedNotificationError())
  }
}

function* readAllNotifications(action){
  try{
    yield call(updateAllUserNotifications);
    yield put(readAllNotificationsSuccess());
  }catch (e){
    yield put(readAllNotificationsError());
  }
}

export default function* notificationSaga() {
  yield takeLatest(GET_PAGINATED_NOTIFICATIONS, getPaginatedNotifications);
  yield takeLatest(READ_SELECTED_NOTIFICATION, readSelectedNotification);
  yield takeLatest(READ_ALL_NOTIFICATIONS, readAllNotifications)
}
