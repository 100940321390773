const scope = "melitaPayroll/reports";

export const LOAD_CONTRIBUTIONS = `${scope}/LOAD_CONTRIBUTIONS`;
export const LOAD_CONTRIBUTIONS_SUCCESS = `${scope}/LOAD_CONTRIBUTIONS_SUCCESS`;
export const LOAD_CONTRIBUTIONS_ERROR = `${scope}/LOAD_CONTRIBUTIONS_ERROR`;
export const DOWNLOAD_CONTRIBUTIONS_PDF = `${scope}/DOWNLOAD_CONTRIBUTIONS_PDF`;
export const DOWNLOAD_CONTRIBUTIONS_PDF_SUCCESS = `${scope}/DOWNLOAD_CONTRIBUTIONS_PDF_SUCCESS`;
export const DOWNLOAD_CONTRIBUTIONS_PDF_ERROR = `${scope}/DOWNLOAD_CONTRIBUTIONS_PDF_ERROR`;
export const DOWNLOAD_CONTRIBUTIONS_EXCEL = `${scope}/DOWNLOAD_CONTRIBUTIONS_EXCEL`;
export const DOWNLOAD_CONTRIBUTIONS_EXCEL_SUCCESS = `${scope}/DOWNLOAD_CONTRIBUTIONS_EXCEL_SUCCESS`;
export const DOWNLOAD_CONTRIBUTIONS_EXCEL_ERROR = `${scope}/DOWNLOAD_CONTRIBUTIONS_EXCEL_ERROR`;
export const SET_EMPLOYEES_CHECKED = `${scope}/SET_EMPLOYEES_CHECKED`;
export const SEND_EMPLOYEES_EMAIL = `${scope}/SEND_EMPLOYEES_EMAIL`;
export const SEND_EMPLOYEES_EMAIL_SUCCESS = `${scope}/SEND_EMPLOYEES_EMAIL_SUCCESS`;
export const SEND_EMPLOYEES_EMAIL_ERROR = `${scope}/SEND_EMPLOYEES_EMAIL_ERROR`;
export const SEND_CONTRIBUTIONS_LIST_EMAIL = `${scope}/SEND_CONTRIBUTIONS_LIST_EMAIL`;
export const SEND_CONTRIBUTIONS_LIST_EMAIL_SUCCESS = `${scope}/SEND_CONTRIBUTIONS_LIST_EMAIL_SUCCESS`;
export const SEND_CONTRIBUTIONS_LIST_EMAIL_ERROR = `${scope}/SEND_CONTRIBUTIONS_LIST_EMAIL_ERROR`;