/* eslint-disable */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import _ from "lodash";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import * as actions from "pages/Configurations/actions";
import { globalState } from "types/globalState.type";
import {
	ConfigurationsState,
	contributionSteps,
	taxSteps,
	taxStepsData,
	accountData,
} from "types/reducers/configurations.reducer.type";
import styles from "./Modal.module.scss";
import InputCustom from "components/InputCustom/InputCustom.component";
import Table from "components/Table/Table.component";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
	overtimeTypes,
	vacationTypes,
	healthInsurance,
	projects,
} from "types/configuration.type";
import { IconPencil, IconSearch } from "assets/icons/icons";
import { FiCheck, FiTrash2 } from "react-icons/fi";
import * as tableStyles from "components/Table/Table.module.scss";
import SearchInput from "components/SearchInput/SearchInput.component";
import Button from "components/Button/Button.component";
import { AiOutlinePlusCircle } from "react-icons/ai";
import CustomSelect from "components/CustomSelect/CustomSelect.component";
import {
	projectOptions,
	accounts,
	customSelectStyles,
} from "utils/globalVariables";
import DropdownIndicator from "components/DropdownIndicator/DropdownIndicator.component";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import Loader from "components/partials/Loader/Loader.component";
import MikaSelect from "../../../components/BbrosSelect/mikaSelect.component";
import { toast } from "react-toastify";
import { axiosInstanceV2 } from "../../../api";
import { SelectOption } from "../../../types/selectOption.type";
import axios from "axios";
import { getPaymentAccount } from "services/configuration.service";

const setupModalTypes = [
	"TAXES",
	"CONTRIBUTIONS",
	"OVERTIME",
	"HOLIDAYS",
	"PROJECTS",
	"MEDICAL_INSURANCE",
	"ADD_PROJECT",
	"DELETE_PROJECT",
	"UPDATE_PROJECT",
	"SECTOR",
	"SECURITY_CODE",
	"DELETE_SECTOR",
	"QB",
];

const SetupModal: React.FC = () => {
	const dispatch = useDispatch();
	const state: ConfigurationsState = useSelector(
		(state: globalState) => state.configurationsReducer
	);
	const [allAccounts, setAllAccounts] = useState<any[]>([]);
	const [selectedAccount, setSelectedAccount] = useState<any>({
		value: null,
		label: "",
	});
	const {
		showSetupModal,
		setupModalOpener,
		setupEditMode,
		showSubmitButton,
		setButtonText,
		taxesData,
		accountDataInfo,
		contributionData,
		overtime,
		vacations,
		tenantHealthInsurance,
		projects,
		project,
		tenantSectors,
	} = state;

	useEffect(() => {
		let tempArray = [];
		if (
			state?.accounts !== null &&
			state?.accounts !== undefined &&
			state?.accounts.length !== 0 &&
			typeof state?.accounts !== "string" &&
			typeof state?.accounts !== "boolean"
		) {
			state?.accounts?.forEach((account) => {
				tempArray.push(account);
			});
		}
		setAllAccounts(tempArray);
	}, [state?.accounts]);

	const sidebarCollapsed: boolean = useSelector(
		(state: globalState) => state.appWrapperReducer.sidebarMenuCollapsed
	);

	const tenantId = useSelector(
		(state: globalState) => state.globalReducer?.tenantId
	);
	const securityCode = state?.code;
	const tenantAccounts = state?.tenantAccounts;
	const payrollAccounts = state?.payrollAccounts;
	const roleId = useSelector(
		(state: globalState) => state.globalReducer?.user?.roleId
	);

	const initialTaxDataValues = {
		firstStep: {
			id: null,
			percentage: null,
			firstValue: null,
			secondValue: null,
		},
		secondStep: {
			id: null,
			percentage: null,
			firstValue: null,
			secondValue: null,
		},
		thirdStep: {
			id: null,
			percentage: null,
			firstValue: null,
			secondValue: null,
		},
		fourthStep: {
			id: null,
			percentage: null,
			firstValue: null,
			secondValue: null,
		},
	};

	const initialAccountDataValues = {
		id: null,
		tenantId: null,
		payroll_account: null,
		quickbooksAccount: null,
		accountName: null,
	};

	const initialContributeValues = {
		contributeId: null,
		personalContribute: null,
		employerContribute: null,
	};

	const initialOvertimeDataValues = {
		id: null,
		nightPercentage: null,
		weekendPercentage: null,
		afterHoursPercentage: null,
		holidayPercentage: null,
		guardPercentage: null,
	};

	const initialVacationDataValues = {
		weekWorkingDays: null,
		annualLeaveDays: null,
		medicalLeaveDays: null,
		maternityLeaveDays: null,
		maleSpecialLeaveDays: null,
		femaleSpecialLeaveDays: null,
		annualLeavePercentage: null,
		medicalLeavePercentage: null,
		maternityLeavePercentage: null,
		maleSpecialLeavePercentage: null,
		femaleSpecialLeavePercentage: null,
	};

	const initialProjectDataValues = {
		tenantId: tenantId,
		name: null,
		status: null,
		budgetLines: null,
	};

	const initialTenantSectorsData = {
		id: null,
		tenantId: tenantId,
		name: null,
	};

	const initialProjectData = {
		name: null,
		status: null,
		budgetLines: null,
		createdAt: null,
	};

	const initialHealthInsuranceValues = {
		tenantId: tenantId,
		prime: null,
		partner: null,
		children: null,
		adultsChildren: null,
		parent: null,
	};
	const tableColumns = [
		"Projektet",
		"Data e krijimit",
		"Statusi",
		"Vijat buxhetore",
		"",
	];

	const [taxDataValues, setTaxDataValues] =
		useState<taxStepsData>(initialTaxDataValues);

	const [accountDataValues, setAccountDataValues] = useState<accountData[]>([]);

	const [contributionDataValues, setContributionDataValues] =
		useState<contributionSteps>(initialContributeValues);
	const [overtimeDataValues, setOvertimeDataValues] = useState<overtimeTypes>(
		initialOvertimeDataValues
	);
	const [vacationDataValues, setVacationDataValues] = useState<vacationTypes>(
		initialVacationDataValues
	);
	const [healthInsuranceDataValues, setHealthInsuranceDataValues] =
		useState<healthInsurance>(initialHealthInsuranceValues);
	const [isAddProjectModalOpen, setIsAddProjectModalOpen] =
		useState<boolean>(false);
	const [isDeleteProjecttModalOpen, setIsDeleteProjectModalOpen] =
		useState<boolean>(false);
	const [projectDataValues, setProjectDataValues] = useState<projects>(
		initialProjectDataValues
	);
	const [projectValues, setProjectValues] = useState([]);
	const [projectIdToDelete, setProjectIdToDelete] = useState<number>();
	const [projectIdToUpdate, setProjectIdToUpdate] = useState<number>();

	const [projectData, setProjectData] = useState<projects>(initialProjectData);
	const [tenantSectorsData, setTenantSectorsData] = useState([]);

	const [projectStatus, setProjectStatus] = useState<{
		value?: number;
		label?: string;
	}>(projectOptions[0]);

	const [searchTerm, setSearchTerm] = useState<string>("");
	const [code, setCode] = useState(null);
	const [isEditCodeOpen, setIsEditCodeOpen] = useState<boolean>(false);

	const [copied, setCopied] = useState(false);
	const [data, setData] = useState("");
	const [inputs, setInputs] = useState([]);
	const [accountInputs, setAccountInputs] = useState([]);
	const [quickBooksAccountInputs, setQuickBooksAccountInputs] = useState([]);

	const [isDeleteSectorModalOpen, setIsDeleteSectorModalOpen] =
		useState<boolean>(false);
	const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
		useState<boolean>(false);
	const [isEditSectorModeOn, setIsEditSectorModeOn] = useState<boolean>(false);
	const [isEditAccountModeOn, setIsEditAccountModeOn] =
		useState<boolean>(false);
	const [sectorIdToDelete, setSectorIdToDelete] = useState<number>();
	const [accountIdToDelete, setAccountIdToDelete] = useState<number>();
	const [sectorName, setSectorName] = useState<string>();
	const [isAddSectorOn, setIsAddSectorOn] = useState<boolean>(false);
	const [isAddAccountOn, setIsAddAccountOn] = useState<boolean>(false);
	const [hideIcon, setHideIcon] = useState<boolean>(false);
	const [hidePencilIcon, setHidePencilIcon] = useState<boolean>(false);
	const [accountSelected, setAccountSelected] = useState<any>();
	const [payrollAccountSelected, setPayrollAccountSelected] = useState<any>();
	const [mainCheckboxState, setMainCheckboxState] = useState(false);
	const [checkedAccounts, setCheckedAccounts] = useState<Array<number>>([]);
	const [checkedAccount, setCheckedAccount] = useState<boolean>(false);
	const [isCodeGenerated, setIsCodeGenerated] = useState<boolean>(false);
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

	const closeModalCallBack = () => {
		setIsAddSectorOn(false);
		setTaxDataValues(initialTaxDataValues);
		setAccountDataValues([]);
		setContributionDataValues(initialContributeValues);
		setOvertimeDataValues(initialOvertimeDataValues);
		setVacationDataValues(initialVacationDataValues);
		setIsAddAccountOn(false);
	};

	const modalCloseHandler = () => {
		dispatch(actions.setSetupModalState({ show: false, opener: "" }));
		setIsEditSectorModeOn(false);
		setIsEditAccountModeOn(false);
		setInputs([]);
		closeModalCallBack();
		setIsEditCodeOpen(false);
		setHideIcon(false);
		setHidePencilIcon(false);
		setAccountInputs([]);
		setQuickBooksAccountInputs([]);
	};

	const handleHeaderButtonClick = (opener) => {
		dispatch(actions.setSetupModalState({ show: true, opener }));
		dispatch(actions.setShowSubmitButton(true));
	};

	const handleEditButtonClick = () => {
		setIsEditCodeOpen(true);
		dispatch(actions.setShowSubmitButton(true));
	};

	const addProjectModal = () => {
		setIsAddProjectModalOpen(true);
		handleHeaderButtonClick(setupModalTypes[6]);
	};

	const modalMainClasses = classNames({
		[styles.modalMainStyle]: true,
		[styles.sizeMedium]: setupModalOpener !== setupModalTypes[4],
		[styles.sidebarCollapsed]: sidebarCollapsed,
		[styles.vacations]: setupModalOpener === setupModalTypes[3],
	});

	const handleData = (event) => {
		setCopied(false);
		setData(event.target.value);
	};

	const handleEditSectors = () => {
		setIsEditSectorModeOn(true);
		dispatch(actions.setShowSubmitButton(true));
		dispatch(actions.setButtonText("Ruaj"));
	};

	const handleEditAccounts = () => {
		if (isEditAccountModeOn) {
			setIsEditAccountModeOn(false);
			dispatch(actions.setShowSubmitButton(false));
		} else {
			setIsEditAccountModeOn(true);
			dispatch(actions.setShowSubmitButton(true));
			dispatch(actions.setButtonText("Ruaj"));
		}
	};

	const handleOnChangeSector = (event, index) => {
		let sectorsArray = tenantSectorsData;

		let tempArray = [...sectorsArray];

		tempArray[index] = {
			...tempArray[index],
			name: event.target.value,
		};

		setTenantSectorsData(tempArray);
	};

	useEffect(() => {
		switch (showSetupModal) {
			case false:
				closeModalCallBack();
				break;
		}
	}, [showSetupModal]);


	useEffect(() => {
		setTaxDataValues(taxesData);
	}, [taxesData]);

	useEffect(() => {
		setAccountDataValues(accountDataInfo as accountData[]);
	}, [accountDataInfo]);

	useEffect(() => {
		setContributionDataValues(contributionData);
	}, [contributionData]);

	useEffect(() => {
		setOvertimeDataValues({
			...overtime,
			overtimeId: overtime?.id,
		});
	}, [overtime]);

	useEffect(() => {
		setVacationDataValues(vacations);
	}, [vacations]);

	useEffect(() => {
		setHealthInsuranceDataValues(tenantHealthInsurance);
	}, [tenantHealthInsurance]);

	useEffect(() => { }, [project]);

	useEffect(() => {
		setProjectValues(projects);
	}, [projects]);

	const getRandomString = () => {
		let length = 6;
		let randomChars =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += randomChars.charAt(
				Math.floor(Math.random() * randomChars.length)
			);
		}
		setCode(result);
		setIsCodeGenerated(true);
	};

	useEffect(() => {
		setTenantSectorsData(tenantSectors);
	}, [tenantSectors]);

	useEffect(() => {
		switch (setupModalOpener) {
			case setupModalTypes[0]:
				dispatch(actions.getTaxDegreesInformation());
				dispatch(actions.setShowSubmitButton(true));
				break;
			case setupModalTypes[1]:
				dispatch(actions.getContribute());
				dispatch(actions.setShowSubmitButton(true));
				break;
			case setupModalTypes[2]:
				dispatch(actions.getOvertime(tenantId));
				dispatch(actions.setShowSubmitButton(true));
				break;
			case setupModalTypes[3]:
				dispatch(actions.getVacation(tenantId));
				dispatch(actions.setShowSubmitButton(true));
				break;
			case setupModalTypes[5]:
				dispatch(actions.getHealthInsurance(tenantId));
				dispatch(actions.setShowSubmitButton(true));
				break;
			case setupModalTypes[4]:
				dispatch(
					actions.getAllProjects({ tenantId, activeProjectsOnly: true })
				);
				dispatch(actions.setShowSubmitButton(false));
				break;
			case setupModalTypes[8]:
				dispatch(actions.getOneProject(projectIdToUpdate));
				break;
			case setupModalTypes[9]:
				dispatch(actions.getTenantSectors(tenantId));
				dispatch(actions.setShowSubmitButton(false));
				break;
			case setupModalTypes[10]:
				dispatch(actions.getCode());
				dispatch(actions.setShowSubmitButton(false));
				break;
			case setupModalTypes[12]:
				dispatch(actions.getAccounts(tenantId));
				dispatch(actions.getTenantAccounts(tenantId));
				dispatch(actions.setShowSubmitButton(true));
				setHidePencilIcon(true);
				dispatch(actions.getPayrollAccounts());
				setIsEditAccountModeOn(true);
				dispatch(actions.setButtonText("Ruaj"));
				break;
			default:
				break;
		}
	}, [setupModalOpener]);

	const deleteProjectModal = (projectId) => {
		dispatch(actions.setButtonText("Po"));
		setIsDeleteProjectModalOpen(true);
		handleHeaderButtonClick(setupModalTypes[7]);
		setProjectIdToDelete(projectId);
	};

	const updateProjectModal = (projectId) => {
		dispatch(actions.setButtonText("Ruaj"));
		handleHeaderButtonClick(setupModalTypes[8]);
		setProjectIdToUpdate(projectId);
	};

	const companyCodeChangeHandler = (event) => {
		setCode(event.target.value);
	};

	const handleCheckAccountEdit = (checked, id) => {
		checked
			? setSelectedCheckboxes((prev) => {
				return _.union(prev, [id]);
			})
			: setSelectedCheckboxes((prev) => {
				return selectedCheckboxes.filter(function (item) {
					return item !== id;
				});
			});
	};

	const deleteSectorModal = (sectorId) => {
		handleHeaderButtonClick(setupModalTypes[11]);
		setIsDeleteSectorModalOpen(true);
		setSectorIdToDelete(sectorId);
	};

	const deleteAccountModal = (accountId) => {
		handleHeaderButtonClick(setupModalTypes[13]);
		setIsDeleteAccountModalOpen(true);
		setAccountIdToDelete(accountId);
	};

	const taxesDataChangeHandler = (event) => {
		const type = +event.target.dataset?.type;
		const name = event.target.name;
		const value = event.target.value;

		setTaxDataValues((prev) => {
			let result = JSON.parse(JSON.stringify(prev));
			let step: string = "";

			switch (type) {
				case taxSteps.FIRST:
					step = "firstStep";
					break;
				case taxSteps.SECOND:
					step = "secondStep";
					break;
				case taxSteps.THIRD:
					step = "thirdStep";
					break;
				case taxSteps.FOURTH:
					step = "fourthStep";
					break;
				default:
					break;
			}

			result[step][name] = value;
			return result;
		});
	};

	const accountDataChangeHandler = (event, index) => {
		if (event?.target?.name === "payroll_account") {
			let tempArray = [...accountDataValues];

			tempArray[index] = {
				...tempArray[index],
				[event.target.name]: event.target.value?.label,
				payroll_account_id: event.target.value?.value?.toString() ?? "",
				checked_account: event.target.checked ?? tempArray[index].checked_account,
			};

			setAccountDataValues(tempArray);
		} else {
			let tempArray = [...accountDataValues]

			if (event?.target?.label) {
				tempArray[index] = {
					...tempArray[index],
					[event.target.name]: +event.target.value,
					account_name: event?.target?.label,
					label: event?.target?.label,
					value: +event.target.value,
					checked_account: event.target.checked ?? tempArray[index].checked_account
				};
			} else {
				tempArray[index] = {
					...tempArray[index],
					[event.target.name]: event.target.value,
					checked_account: event.target.checked ?? tempArray[index].checked_account,
				};
			}

			setAccountDataValues(tempArray);
		}
	};

	const contributonsDataChangeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		name &&
			setContributionDataValues((prev) => {
				return {
					...prev,
					[name]: value,
				};
			});
	};

	const healthInsuranceDataChangeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		name &&
			setHealthInsuranceDataValues((prev) => {
				return {
					...prev,
					[name]: value,
				};
			});
	};

	const projectDataChangeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		name &&
			setProjectDataValues((prev) => {
				return {
					...prev,
					[name]: value,
				};
			});
	};

	const projectDataUpdateChangeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		name &&
			setProjectData((prev) => {
				return {
					...prev,
					[name]: value,
				};
			});
	};

	const overtimeDataChangeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		name &&
			setOvertimeDataValues((prev) => {
				return {
					...prev,
					[name]: value,
				};
			});
	};

	const vacationDataChangeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		name &&
			setVacationDataValues((prev) => {
				return {
					...prev,
					[name]: value,
				};
			});
	};

	const onInputChange = () => {
		setIsAddSectorOn(true);
		setInputs(inputs.concat(""));
		setHideIcon(true);
		setHidePencilIcon(true);
		dispatch(actions.setShowSubmitButton(true));
	};

	const onClickIconChange = () => {
		setAccountDataValues((prev) => {
			return [
				...prev,
				{
					id: null,
					account_name: null,
					checked_account: null,
					payroll_account: null,
					payroll_account_id: null,
					quickbooks_account: null,
					tenantId: tenantId,
				},
			];
		});
		// setIsAddAccountOn(true);
		// setAccountInputs(accountInputs.concat(""));
		// setQuickBooksAccountInputs(accountInputs.concat(""));
		// dispatch(actions.setShowSubmitButton(true));
	};

	function toggle(value) {
		return !value;
	}

	const modalsubmitHandler = (event) => {
		switch (setupModalOpener) {
			case setupModalTypes[0]:
				dispatch(actions.saveTaxes(taxDataValues));
				break;
			case setupModalTypes[1]:
				dispatch(actions.saveContributes(contributionDataValues));
				break;
			case setupModalTypes[2]:
				dispatch(actions.saveOvertime(overtimeDataValues));
				break;
			case setupModalTypes[3]:
				dispatch(actions.saveVacation(vacationDataValues));
				break;
			case setupModalTypes[5]:
				dispatch(
					actions.updateHealthInsurance(tenantId, healthInsuranceDataValues)
				);
				dispatch(actions.setSetupModalState({ show: false, opener: "" }));
				break;
			case (setupModalTypes[6], setupModalTypes[8]):
				dispatch(actions.saveProject(projectDataValues));
				break;
			case setupModalTypes[7]:
				dispatch(
					actions.deleteProject({ projectId: projectIdToDelete, tenantId })
				);
				break;
			case setupModalTypes[10]:
				const payload = {
					tenantId,
					code,
				};
				dispatch(actions.generateCode(payload));
				dispatch(actions.setSetupModalState({ show: false, opener: "" }));
				setIsEditCodeOpen(false);
				dispatch(actions.setShowSubmitButton(false));
				dispatch(actions.getCode());
				setCopied(false);
				setIsCodeGenerated(false);
				break;
			case setupModalTypes[9]:
				if (isEditSectorModeOn) {
					const payload = {
						sectors: tenantSectorsData,
					};
					dispatch(actions.updateSector(payload));
					dispatch(actions.setSetupModalState({ show: false, opener: "" }));
					setIsEditSectorModeOn(false);
					setHideIcon(false);
					setHidePencilIcon(false);
					dispatch(actions.setShowSubmitButton(false));
				} else {
					const payload = {
						tenantId,
						sectors: inputs,
					};

					dispatch(actions.createSector(payload));
					dispatch(actions.setSetupModalState({ show: false, opener: "" }));
					dispatch(actions.getTenantSectors(tenantId));
					setHideIcon(false);
					setHidePencilIcon(false);
					setInputs([]);
					dispatch(actions.setShowSubmitButton(false));
				}
				break;
			case setupModalTypes[11]:
				dispatch(actions.deleteSector(sectorIdToDelete));
				dispatch(actions.setSetupModalState({ show: false, opener: "" }));
				setIsEditSectorModeOn(false);
				break;

			case setupModalTypes[12]:
				if (!selectedAccount?.value?.toString()?.length) {
					toast.error("Ju lutem, zgjedhni konton e pageses");
					return;
				}
				dispatch(
					actions.saveAccounts({
						userId: tenantId,
						paymentAccount: {
							value: selectedAccount?.value?.toString(),
							title: selectedAccount?.label,
						},
						tenantAccounts: accountDataValues.map((account) => {
							return {
								id: account.id,
								payrollAccount: account.payroll_account,
								payrollAccountId: account.payroll_account_id,
								value:
									account.value?.toString() ??
									(account as any).quickbooks_account?.toString(),
								accountName: account.account_name,
								checkedAccount: account.checked_account,
							};
						}),
					})
				);
				dispatch(actions.setSetupModalState({ show: false, opener: "" }));
				break;
			case setupModalTypes[13]:
				dispatch(actions.deleteAccount(accountIdToDelete));
				dispatch(actions.getTenantAccounts(tenantId));
				dispatch(actions.setSetupModalState({ show: false, opener: "" }));
				setIsEditAccountModeOn(false);
				break;
			default:
				break;
		}
	};

	const footerStyles = classNames({
		[styles.footerClass]: true,
		[styles.setupModalFooter]: true,
	});

	useEffect(() => {
		getPaymentAccount(tenantId)
			.then((res) => {
				setSelectedAccount(res.data.paymentAccount);
			})
			.catch((err) => {
				console.error(
					"failed to fetch the payment account",
					err
				);
			});
	}, [showSetupModal]);

	function saveSelectedAccount(selectedOption) {
		setSelectedAccount({
			...selectedAccount,
			value: selectedOption?.value,
			label: selectedOption?.label,
		});
	}

	function savePaymentAccount() {
		if (selectedAccount?.value === null) {
			toast.error("Ju lutem zgjedhni konton a pageses!");
			return;
		} else {
			let payload = {
				userId: tenantId,
				value: +selectedAccount?.value,
				title: selectedAccount?.label,
			};

			axiosInstanceV2().post("/salaries/payment-account",
				payload
			)
				.catch(() => {
					toast.error("Ruajtja e kontos se pageses deshtoi");
				});
		}
	}

	return (
		<ModalCustom
			show={showSetupModal}
			contentClass={`${styles.modalContent}`}
			footerClass={footerStyles}
			modalMainStyle={modalMainClasses}
			headerClass={styles.headerClass}
			modalPrintClass={styles.modalPrintClass}
			onClose={modalCloseHandler}
			onSubmit={modalsubmitHandler}
			showSubmitButton={showSubmitButton}
			submitText={setButtonText}
			header={
				<div className={`${styles.modalHeader}`}>
					{
						{
							[setupModalTypes[0]]: (
								<div className={styles.modalTitle}> Tatimet </div>
							),
							[setupModalTypes[1]]: (
								<div className={styles.modalTitle}> Kontributet </div>
							),
							[setupModalTypes[2]]: (
								<div className={styles.modalTitle}> Punët shtesë </div>
							),
							[setupModalTypes[3]]: (
								<div className={styles.modalTitle}> Pushimet </div>
							),
							[setupModalTypes[4]]: (
								<div className={styles.modalTitle}> Lista e projekteve </div>
							),
							[setupModalTypes[5]]: (
								<div className={styles.modalTitle}> Sigurimi shëndetësor </div>
							),
							[setupModalTypes[6]]: (
								<div className={styles.modalTitle}> Shto projekt </div>
							),
							[setupModalTypes[7]]: (
								<div className={styles.modalTitle}> Fshij projektin</div>
							),
							[setupModalTypes[8]]: (
								<div className={styles.modalTitle}>Edito projektin</div>
							),
							[setupModalTypes[9]]: <div className={styles.modalTitle}></div>,
							[setupModalTypes[10]]: (
								<div className={styles.titleIconWrapper}>
									<div className={styles.modalTitle} >Fjalëkalimi për mbyllje të periudhës</div>
									{roleId === 1 || roleId === 2 ? (
										<div
											className={styles.icon}
											onClick={handleEditButtonClick}
										>
											<IconPencil />
										</div>
									) : null}
								</div>
							),
							[setupModalTypes[11]]: (
								<div className={styles.modalTitle}>Fshij sektorin</div>
							),
							[setupModalTypes[12]]: (
								<div className={styles.modalTitle}>Lidhja me QuickBooks</div>
							),
							[setupModalTypes[13]]: (
								<div className={styles.modalTitle}>Fshij Konton</div>
							),
						}[setupModalOpener]
					}
				</div>
			}
		>
			{
				{
					[setupModalTypes[0]]: (
						<div className={`${styles.modalBody} ${styles.taxModal} column`}>
							<table>
								<thead>
									<tr className={styles.headerBar}>
										<th className={`${styles.cell} ${styles.inputCol}`}>
											Përqindja
										</th>
										<th className={`${styles.cell}`} colSpan={4}>
											Lloji
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="percentage"
												attributes={{ "data-type": taxSteps.FIRST }}
												value={taxDataValues?.firstStep?.percentage}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
										<td className={""}>
											<div className={styles.text}> Paga </div>
										</td>
										<td className={"pl-0"}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput`}
												min={"0"}
												type="number"
												name="firstValue"
												attributes={{ "data-type": taxSteps.FIRST }}
												value={taxDataValues?.firstStep?.firstValue}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
										<td className={`${styles.secondLabel}`}>
											<div className={styles.text}>deri</div>
										</td>
										<td className={`pl-0`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput`}
												min={"0"}
												type="number"
												name="secondValue"
												attributes={{ "data-type": taxSteps.FIRST }}
												value={taxDataValues?.firstStep?.secondValue}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
									</tr>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="percentage"
												attributes={{ "data-type": taxSteps.SECOND }}
												value={taxDataValues?.secondStep?.percentage}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
										<td className={""}>
											<div className={styles.text}> Paga </div>
										</td>
										<td className={"pl-0"}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput`}
												min={"0"}
												type="number"
												name="firstValue"
												attributes={{ "data-type": taxSteps.SECOND }}
												value={taxDataValues?.secondStep?.firstValue}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
										<td className={`${styles.secondLabel}`}>
											<div className={styles.text}>deri</div>
										</td>
										<td className={"pl-0"}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput`}
												min={"0"}
												type="number"
												name="secondValue"
												attributes={{ "data-type": taxSteps.SECOND }}
												value={taxDataValues?.secondStep?.secondValue}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
									</tr>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="percentage"
												attributes={{ "data-type": taxSteps.THIRD }}
												value={taxDataValues?.thirdStep?.percentage}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
										<td className={""}>
											<div className={styles.text}> Paga </div>
										</td>
										<td className={"pl-0"}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput`}
												min={"0"}
												type="number"
												name="firstValue"
												attributes={{ "data-type": taxSteps.THIRD }}
												value={taxDataValues?.thirdStep?.firstValue}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
										<td className={`${styles.secondLabel}`}>
											<div className={styles.text}>deri</div>
										</td>
										<td className={"pl-0"}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput`}
												min={"0"}
												type="number"
												name="secondValue"
												attributes={{ "data-type": taxSteps.THIRD }}
												value={taxDataValues?.thirdStep?.secondValue}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
									</tr>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="percentage"
												attributes={{ "data-type": taxSteps.FOURTH }}
												value={taxDataValues?.fourthStep?.percentage}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
										<td className={""}>
											<div className={styles.text}> Paga mbi </div>
										</td>
										<td className={`pl-0`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput`}
												min={"0"}
												type="number"
												name="firstValue"
												attributes={{ "data-type": taxSteps.FOURTH }}
												value={taxDataValues?.fourthStep?.firstValue}
												onChangeHandler={taxesDataChangeHandler}
											/>
										</td>
										<td className={"pl-0"}></td>
									</tr>
								</tbody>
							</table>
						</div>
					),
					[setupModalTypes[1]]: (
						<div className={`${styles.modalBody} column`}>
							<table>
								<thead>
									<tr className={styles.headerBar}>
										<th className={`${styles.cell} ${styles.inputCol}`}>
											Përqindja
										</th>
										<th className={`${styles.cell} pl-0`}>Lloji</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="employerContribute"
												value={contributionDataValues?.employerContribute}
												onChangeHandler={contributonsDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}>
												{" "}
												Kontributi i punëdhënësit{" "}
											</div>
										</td>
									</tr>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="personalContribute"
												value={contributionDataValues?.personalContribute}
												onChangeHandler={contributonsDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}>
												{" "}
												Kontributi i punëmarrësit{" "}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					),
					[setupModalTypes[2]]: (
						<div className={`${styles.modalBody} ${styles.overtime} column`}>
							<table>
								<thead>
									<tr className={styles.headerBar}>
										<th className={`${styles.cell} ${styles.inputCol}`}>
											Përqindja e rritjes
										</th>
										<th className={`${styles.cell} pl-0`}>Lloji</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="nightPercentage"
												value={overtimeDataValues?.nightPercentage}
												onChangeHandler={overtimeDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}> Natën </div>
										</td>
									</tr>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="weekendPercentage"
												value={overtimeDataValues?.weekendPercentage}
												onChangeHandler={overtimeDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}> Fundjavën </div>
										</td>
									</tr>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="afterHoursPercentage"
												value={overtimeDataValues?.afterHoursPercentage}
												onChangeHandler={overtimeDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}> Jashtë Orarit </div>
										</td>
									</tr>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="holidayPercentage"
												value={overtimeDataValues?.holidayPercentage}
												onChangeHandler={overtimeDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}> Festat </div>
										</td>
									</tr>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput percentageInput`}
												min={"0"}
												type="number"
												name="guardPercentage"
												value={overtimeDataValues?.guardPercentage}
												onChangeHandler={overtimeDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}> Kujdestaria </div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					),
					[setupModalTypes[3]]: (
						<div className={`${styles.modalBody} column`}>
							<Tabs>
								<div className={styles.tabsWrapper}>
									<div className={styles.col}>
										<TabList
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<Tab title="Mashkull"> Mashkull </Tab>
											<Tab title="Femër"> Femër </Tab>
										</TabList>
									</div>
									<div className={styles.col}>
										<div className={styles.row}>
											<div className={styles.title}>Ditët e punës</div>
											<InputCustom
												className={styles.modalInput}
												min={"0"}
												type="number"
												name="weekWorkingDays"
												value={vacationDataValues?.weekWorkingDays}
												onChangeHandler={vacationDataChangeHandler}
											/>
										</div>
									</div>
								</div>
								<TabPanel>
									<table>
										<thead>
											<tr className={styles.headerBar}>
												<th className={`${styles.cell} ${styles.inputCol}`}>
													{" "}
													Ditët{" "}
												</th>
												<th className={`${styles.cell} ${styles.inputCol}`}>
													{" "}
													Përqindja{" "}
												</th>
												<th className={`${styles.cell}`}> Lloji </th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput`}
														min={"0"}
														type="number"
														name="annualLeaveDays"
														value={vacationDataValues?.annualLeaveDays}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput percentageInput`}
														min={"0"}
														type="number"
														name="annualLeavePercentage"
														value={vacationDataValues?.annualLeavePercentage}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td>
													<div className={styles.box}>Pushimi vjetor</div>
												</td>
											</tr>
											<tr>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput`}
														min={"0"}
														type="number"
														name="medicalLeaveDays"
														value={vacationDataValues?.medicalLeaveDays}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput percentageInput`}
														min={"0"}
														type="number"
														name="medicalLeavePercentage"
														value={vacationDataValues?.medicalLeavePercentage}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td>
													<div className={styles.box}>Pushimi mjekësor</div>
												</td>
											</tr>
											<tr>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput`}
														min={"0"}
														type="number"
														name="maleSpecialLeaveDays"
														value={vacationDataValues?.maleSpecialLeaveDays}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput percentageInput`}
														min={"0"}
														type="number"
														name="maleSpecialLeavePercentage"
														value={
															vacationDataValues?.maleSpecialLeavePercentage
														}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td>
													<div className={styles.box}>Pushimi special</div>
												</td>
											</tr>
										</tbody>
									</table>
								</TabPanel>
								<TabPanel>
									<table>
										<thead>
											<tr className={styles.headerBar}>
												<th className={`${styles.cell} ${styles.inputCol}`}>
													{" "}
													Ditët{" "}
												</th>
												<th className={`${styles.cell} ${styles.inputCol}`}>
													{" "}
													Përqindja{" "}
												</th>
												<th className={`${styles.cell}`}> Lloji </th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput`}
														min={"0"}
														type="number"
														name="annualLeaveDays"
														value={vacationDataValues?.annualLeaveDays}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput percentageInput`}
														min={"0"}
														type="number"
														name="annualLeavePercentage"
														value={vacationDataValues?.annualLeavePercentage}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td>
													<div className={styles.box}>Pushimi vjetor</div>
												</td>
											</tr>
											<tr>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput`}
														min={"0"}
														type="number"
														name="medicalLeaveDays"
														value={vacationDataValues?.medicalLeaveDays}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput percentageInput`}
														min={"0"}
														type="number"
														name="medicalLeavePercentage"
														value={vacationDataValues?.medicalLeavePercentage}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td>
													<div className={styles.box}>Pushimi mjekësor</div>
												</td>
											</tr>
											<tr>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput`}
														min={"0"}
														type="number"
														name="femaleSpecialLeaveDays"
														value={vacationDataValues?.femaleSpecialLeaveDays}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput percentageInput`}
														min={"0"}
														type="number"
														name="femaleSpecialLeavePercentage"
														value={
															vacationDataValues?.femaleSpecialLeavePercentage
														}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td>
													<div className={styles.box}>Pushimi special</div>
												</td>
											</tr>
											<tr>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput`}
														min={"0"}
														type="number"
														name="maternityLeaveDays"
														value={vacationDataValues?.maternityLeaveDays}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td
													className={`${styles.inputCell} ${styles.inputCol}`}
												>
													<InputCustom
														className={`${styles.box} noArrowsNumberInput percentageInput`}
														min={"0"}
														type="number"
														name="maternityLeavePercentage"
														value={vacationDataValues?.maternityLeavePercentage}
														onChangeHandler={vacationDataChangeHandler}
													/>
												</td>
												<td>
													<div className={styles.box}>Pushimi i lehonisë</div>
												</td>
											</tr>
										</tbody>
									</table>
								</TabPanel>
							</Tabs>

							<div className={styles.footerAlert}>
								Tre muajt e parë të pushimit të lehonisë, pas përfundimit të 6 muajve të parë,
								kompensohen me 50% të pagës mesatare në Kosovë nga Qeveria e Kosovës, ndërsa tre muajt e tjerë janë pa pagesë.
							</div>
						</div>
					),
					[setupModalTypes[5]]: (
						<div className={`${styles.modalBody} column`}>
							<table>
								<thead>
									<tr className={styles.headerBar}>
										<th className={`${styles.cell} ${styles.inputCol}`}>
											Vlera
										</th>
										<th className={`${styles.cell} pl-0`}></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput`}
												min={"0"}
												type="number"
												name="prime"
												value={healthInsuranceDataValues?.prime}
												onChangeHandler={healthInsuranceDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}> Primi </div>
										</td>
									</tr>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput`}
												min={"0"}
												type="number"
												name="partner"
												value={healthInsuranceDataValues?.partner}
												onChangeHandler={healthInsuranceDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}> Bashkëshorti/ja </div>
										</td>
									</tr>
									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput `}
												min={"0"}
												type="number"
												name="children"
												value={healthInsuranceDataValues?.children}
												onChangeHandler={healthInsuranceDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}> Fëmijë nën 18 vjeç </div>
										</td>
									</tr>

									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput `}
												min={"0"}
												type="number"
												name="adultsChildren"
												value={healthInsuranceDataValues?.adultsChildren}
												onChangeHandler={healthInsuranceDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}> Fëmijë mbi 18 vjeç </div>
										</td>
									</tr>

									<tr>
										<td className={`${styles.inputCell} ${styles.inputCol}`}>
											<InputCustom
												className={`${styles.box} noArrowsNumberInput `}
												min={"0"}
												type="number"
												name="parent"
												value={healthInsuranceDataValues?.parent}
												onChangeHandler={healthInsuranceDataChangeHandler}
											/>
										</td>
										<td className="pl-0">
											<div className={styles.box}> Prindër </div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					),
					[setupModalTypes[4]]: (
						<div className={`${styles.modalBody} column ${styles.projectList}`}>
							<Button
								viewType="shell"
								className={`${styles.headerButton} ${styles.search} ${styles.customButton}`}
								onClickHandler={addProjectModal}
							>
								<div className={`${styles.iconContainer}`}>
									<AiOutlinePlusCircle />
								</div>
								Shto projekt
							</Button>
							<div className={`${styles.search} ${styles.projectList}`}>
								<SearchInput
									className={`${styles.searchInputContainer} ${styles.customSearchInput}`}
									onChangeHandler={(event) => {
										setSearchTerm(event.target.value);
									}}
									searchPlaceholder="Kërko"
								/>
							</div>
							<Table
								tableHeader={
									<thead
										className={`${tableStyles.tableHead} ${styles.projectList}`}
									>
										<tr className={`${tableStyles.headerRow} `}>
											{tableColumns.map((item, index) => {
												return (
													<th
														className={`${tableStyles.headerCell}`}
														key={index}
													>
														{item}
													</th>
												);
											})}
										</tr>
									</thead>
								}
								tableWrapperClass={styles.tableWrapper}
								data={[]}
							>
								<tbody className={`${styles.tableBody} ${styles.projectList}`}>
									{projects?.length > 0 &&
										projects
											.filter((val) => {
												if (searchTerm === "") {
													return val;
												} else if (
													val.name
														.toLowerCase()
														.includes(searchTerm.toLowerCase())
												) {
													return val;
												}
											})
											.map((project, idx) => {
												return (
													<tr className={styles.tableRow} key={idx}>
														<td className={`${styles.bodyCell} ${styles.text}`}>
															{project?.name}
														</td>
														<td className={`${styles.bodyCell} ${styles.text}`}>
															{project?.createdAt}
														</td>
														<td className={`${styles.bodyCell} ${styles.text}`}>
															{project?.statusName}
														</td>
														<td className={`${styles.bodyCell} ${styles.text}`}>
															{project?.budgetLines}
														</td>
														<td style={{ width: "100px" }}>
															<div className={styles.pencilIcon}>
																<div
																	className={styles.pencil}
																	onClick={() => updateProjectModal(project.id)}
																>
																	<IconPencil fill="#89C732" />
																</div>
																<div>
																	<FiTrash2
																		onClick={() =>
																			deleteProjectModal(project.id)
																		}
																	/>
																</div>
															</div>
														</td>
													</tr>
												);
											})}
								</tbody>
							</Table>
						</div>
					),
					[setupModalTypes[6]]: (
						<div className={styles.addProjectCustom}>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Emri"
										name="name"
										value={projectDataValues?.name}
										onChangeHandler={(event) => projectDataChangeHandler(event)}
									></InputCustom>
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<CustomSelect
										options={projectOptions}
										className={`${styles.customSelect}`}
										onChangeHandler={(value) =>
											setProjectStatus((prev) => value)
										}
										selected={projectStatus}
										placeholder="Zgjedh statusin"
										name="status"
										wideMenu={true}
										components={{ DropdownIndicator }}
										{...customSelectStyles}
										containerStyle={{
											width: "100%",
										}}
										controlStyle={{
											...customSelectStyles.controlStyle,
											width: "100%",
										}}
										valueContainerStyle={{
											...customSelectStyles.valueContainerStyle,
											flex: 1,
										}}
										singleValueStyle={{
											fontWeight: "500",
											paddingLeft: "0.5rem",
											fontSize: "18px",
											opacity: ".85",
										}}
									/>
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Vija Buxhetore"
										name="budgetLines"
										value={projectDataValues?.budgetLines}
										onChangeHandler={projectDataChangeHandler}
									/>
								</div>
							</div>
						</div>
					),
					[setupModalTypes[7]]: (
						<div>
							<div className={styles.deleteCompanyMessage}>
								Jeni të sigurtë që doni të fshini këtë projekt?
							</div>
						</div>
					),
					[setupModalTypes[8]]: (
						<div className={styles.addProjectCustom}>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Emri"
										name="name"
										value={projectData?.name}
										onChangeHandler={(event) =>
											projectDataUpdateChangeHandler(event)
										}
									></InputCustom>
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<CustomSelect
										options={projectOptions}
										className={`${styles.customSelect}`}
										onChangeHandler={(value) =>
											setProjectStatus((prev) => value)
										}
										selected={projectStatus}
										placeholder="Zgjedh statusin"
										name="status"
										wideMenu={true}
										components={{ DropdownIndicator }}
										{...customSelectStyles}
										containerStyle={{
											width: "100%",
										}}
										controlStyle={{
											...customSelectStyles.controlStyle,
											width: "100%",
										}}
										valueContainerStyle={{
											...customSelectStyles.valueContainerStyle,
											flex: 1,
										}}
										singleValueStyle={{
											fontWeight: "500",
											paddingLeft: "0.5rem",
											fontSize: "18px",
											opacity: ".85",
										}}
									/>
								</div>
							</div>
							<div className={styles.inputContainer}>
								<div className={styles.inputBox}>
									<InputCustom
										className={styles.modalInput}
										placeholder="Vija Buxhetore"
										name="budgetLines"
										value={projectData?.budgetLines}
										onChangeHandler={projectDataUpdateChangeHandler}
									/>
								</div>
							</div>
						</div>
					),
					[setupModalTypes[9]]: (
						<div className={`${styles.modalBody} column`}>
							<div className={styles.actionsContainer}></div>
							<div className={styles.sectorTable}>
								<div className={styles.sectorTableHeader}>
									<p>Sektorët</p>
								</div>
								<div className={styles.sectorTableContentBody}>
									{tenantSectorsData?.length > 0 &&
										tenantSectorsData?.map((sector, index) => {
											return (
												<div className={styles.trashIconWrapper} key={index}>
													<InputCustom
														className={styles.box}
														name="sector"
														value={tenantSectorsData[index].label}
														readOnly={isEditSectorModeOn ? false : true}
													/>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					),
					[setupModalTypes[10]]: (
						<div className={`${styles.modalBody} column`}>
							{isEditCodeOpen ? (
								<div
									className={`${styles.allCheckboxes} ${styles.securityCodeInputWrapper}`}
								>
									<span className={styles.allCheckboxesText}>Fjalëkalimi:</span>
									<div
										className={`${styles.codeWrapper} ${styles.securityCodeInput}`}
									>
										<InputCustom
											className={`${styles.modalInput}`}
											name="code"
											placeholder={securityCode}
											value={code}
											onChangeHandler={(event) =>
												companyCodeChangeHandler(event)
											}
										/>
									</div>
									{isCodeGenerated && (
										<div className={styles.copyContainer}>
											<CopyToClipboard
												text={code}
												onCopy={() => setCopied(true)}
											>
												{!copied ? <div>Copy</div> : <div>Copied!</div>}
											</CopyToClipboard>
										</div>
									)}
									<Button
										viewType="shell"
										className={styles.headerButtonGenerate}
										onClickHandler={getRandomString}
									>
										Gjenero Kodin
									</Button>
								</div>
							) : (
								<div
									className={`${styles.allCheckboxes} ${styles.securityCodeInputWrapper}`}
								>
									<span className={styles.allCheckboxesText}>Fjalëkalimi:</span>
									<div
										className={`${styles.codeWrapper} ${styles.securityCodeInput}`}
									>
										<InputCustom
											className={`${styles.modalInput}`}
											name="code"
											value={securityCode}
											readOnly={true}
										/>
									</div>
								</div>
							)}
						</div>
					),
					[setupModalTypes[11]]: (
						<div>
							<div className={styles.deleteCompanyMessage}>
								Jeni të sigurtë që doni të fshini këtë sektor?
							</div>
						</div>
					),
					[setupModalTypes[12]]: (
						<div className={`${styles.modalBody} column`}>
							<div className={styles.actionsContainer}>
								{!isEditSectorModeOn && !hideIcon && (
									<div>
										<AiOutlinePlusCircle onClick={onClickIconChange} />
									</div>
								)}
								{!hidePencilIcon && (
									<div
										className={styles.iconPencil}
										onClick={handleEditAccounts}
									>
										<IconPencil fill="#89C732" />
									</div>
								)}
							</div>
							<div
								className={styles.tableContainerQB}
								style={{ paddingBottom: "200px" }}
							>
								<table>
									<thead>
										<tr className={styles.headerBar}>
											<th
												colSpan={2}
												className={`${styles.cell} ${styles.inputCol}`}
											>
												<div className={styles.savePaymentAccountWrapper}>
													<MikaSelect
														options={allAccounts}
														value={selectedAccount}
														setEntireOptionFunction={saveSelectedAccount}
														placeholder="Zgjedh konton . . ."
													>
														Llogaria ndërmjetëse
													</MikaSelect>
												</div>
											</th>
										</tr>
										<tr className={styles.headerBar}>
											<th
												className={`${styles.cell} ${styles.inputCol}`}
												style={{ padding: "0.5rem" }}
											>
												Llogaria në Payshef&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Llogaria në QBO
											</th>
											<th className={`${styles.cell} pl-0`}></th>
										</tr>
									</thead>
									<tbody className={styles.tableBodyQuickbooks}>
										{!isEditAccountModeOn
											? tenantAccounts?.length > 0 &&
											tenantAccounts?.map((account, index) => {
												return (
													<tr className={styles.accountRow} key={index}>
														<td className={styles.checkboxContainer}>
															<div
																className={styles.customCheckBox}
																style={{ width: 30 }}
															>
																{account?.checked_account && (
																	<input
																		type="checkbox"
																		checked={account?.checked_account}
																		data-tip="Llogaria në Payshef"
																	/>
																)}
																<ReactTooltip
																	place="top"
																	type="dark"
																	effect="solid"
																	multiline={false}
																	className="customTooltipQB"
																/>
															</div>
														</td>
														<td className="pl-0">
															<div className={styles.box2}>
																{account?.payroll_account}
															</div>
														</td>
														<td className="pl-0">
															<div className={styles.box2}>
																{account?.account_name}
															</div>
														</td>
													</tr>
												);
											})
											: accountDataValues?.length > 0 &&
											accountDataValues?.map(
												(account: accountData, idx: number) => {
													return (
														<tr
															key={idx}
															className={`${styles.kontoEdit} ${styles.accountRow}`}
														>
															<td>
																<div className={styles.customCheckBox}>
																	<input
																		type="checkbox"
																		name="checked_account"
																		checked={account?.checked_account}
																		onChange={(e) =>
																			accountDataChangeHandler(e, idx)
																		}
																		data-tip="Llogaria në Payshef"
																	/>
																	<ReactTooltip
																		place="top"
																		type="dark"
																		effect="solid"
																		multiline={false}
																		className="customTooltipQB"
																	/>
																</div>
															</td>
															<td className="pl-0">
																<div className={styles.searchSelectContainer}>
																	<CustomSelect
																		hasSearchFeature
																		options={(payrollAccounts ?? []).filter(
																			(p) =>
																				p.value !=
																				Number(account?.payroll_account_id)
																		)}
																		className={styles.customSelect3}
																		onChangeHandler={(event) => {
																			let eventCopy = {
																				target: {
																					name: "payroll_account",
																					value: event,
																				},
																			};
																			accountDataChangeHandler(
																				eventCopy,
																				idx
																			);
																		}}
																		selected={{
																			label: account?.payroll_account,
																			value: account?.payroll_account_id,
																		}}
																		components={{ DropdownIndicator }}
																		controlStyle={{
																			borderRadius: "4px",
																			minWidth: "fit-content",
																		}}
																		{...customSelectStyles}
																	/>
																</div>
															</td>
															<td className="pl-0">
																{state?.loading ? (
																	<Loader />
																) : (
																	<>
																		<div
																			className={styles.searchSelectContainer}
																		>
																			<CustomSelect
																				hasSearchFeature
																				options={allAccounts}
																				className={styles.customSelect3}
																				onChangeHandler={(event) => {
																					let eventCopy = {
																						target: {
																							name: "quickbooks_account",
																							value: event?.value,
																							label: event?.label,
																						},
																					};
																					accountDataChangeHandler(
																						eventCopy,
																						idx
																					);
																				}}
																				selected={{
																					value: account?.quickbooksAccount,
																					label: account?.account_name,
																				}}
																				components={{ DropdownIndicator }}
																				controlStyle={{
																					borderRadius: "4px",
																					minWidth: "fit-content",
																				}}
																				{...customSelectStyles}
																			/>
																		</div>
																	</>
																)}
															</td>
															{!state?.loading && (
																<td className={styles.editTrashContainer}>
																	{isEditAccountModeOn && (
																		<FiTrash2
																			className={styles.trashCan}
																			onClick={() =>
																				deleteAccountModal(account.id)
																			}
																		/>
																	)}
																</td>
															)}
														</tr>
													);
												}
											)}

										{isAddAccountOn &&
											accountInputs.map((value, index) => (
												<tr
													className={`${styles.accountRow} ${styles.selectCustom1}`}
													key={index}
												>
													<td className={styles.checkboxContainer}>
														<div className={styles.customCheckBox}>
															<input
																type="checkbox"
																checked={checkedAccount || false}
																onChange={() => setCheckedAccount(toggle)}
																data-tip="Llogaria në Payshef"
															/>
															<ReactTooltip
																place="top"
																type="dark"
																effect="solid"
																multiline={false}
																className="customTooltipQB"
															/>
														</div>
													</td>
													<td>
														<div className={styles.boxSelect}>
															{/*<CustomSelect*/}
															{/*    options={payrollAccounts}*/}
															{/*    className={styles.customSelect1}*/}
															{/*    onChangeHandler={(value) => {*/}
															{/*      setPayrollAccountSelected((prev) => value)*/}
															{/*    }}*/}
															{/*    selected={payrollAccountSelected}*/}
															{/*    placeholder="Payshef Konto"*/}
															{/*    name="status"*/}
															{/*    components={{ DropdownIndicator }}*/}
															{/*    controlStyle={{*/}
															{/*      borderRadius: "4px",*/}
															{/*      minWidth: "fit-content",*/}
															{/*    }}*/}
															{/*    {...customSelectStyles}*/}
															{/*/>*/}
															<MikaSelect
																options={payrollAccounts}
																selectWrapperClassName={"qbSetup"}
																value={
																	payrollAccountSelected || ({} as SelectOption)
																}
																setEntireOptionFunction={
																	setPayrollAccountSelected
																}
																placeholder={"Payshef Konto"}
															></MikaSelect>
														</div>
													</td>
													<td>
														<div className={styles.boxSelect}>
															{/*<CustomSelect*/}
															{/*    options={allAccounts}*/}
															{/*    className={styles.customSelect1}*/}
															{/*    onChangeHandler={(value) =>*/}
															{/*        setAccountSelected((prev) => value)*/}
															{/*    }*/}
															{/*    selected={accountSelected}*/}
															{/*    placeholder="Zgjedh opsionin"*/}
															{/*    name="status"*/}
															{/*    components={{ DropdownIndicator }}*/}
															{/*    controlStyle={{*/}
															{/*      borderRadius: "4px",*/}
															{/*      minWidth: "fit-content",*/}
															{/*    }}*/}
															{/*    {...customSelectStyles}*/}
															{/*/>*/}
															<MikaSelect
																options={allAccounts}
																selectWrapperClassName={"qbSetup"}
																value={accountSelected || ({} as SelectOption)}
																setEntireOptionFunction={setAccountSelected}
																placeholder={"Zgjedh opsionin"}
															></MikaSelect>
														</div>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					),
					[setupModalTypes[13]]: (
						<div>
							<div className={styles.deleteCompanyMessage}>
								Jeni të sigurtë që doni të fshini këtë konto?
							</div>
						</div>
					),
				}[setupModalOpener]
			}
		</ModalCustom>
	);
};

export default SetupModal;
