const loginScope = "melitaPayroll/companyLogin";
const scope = "melitaPayroll/configurations";

export const SET_GLOBAL_TENANT_ID = `${scope}/SET_GLOBAL_TENANT_ID`;
export const SET_GLOBAL_HAS_QUICKBOOKS = `${scope}/SET_GLOBAL_HAS_QUICKBOOKS`;
export const SET_TOKEN = `${scope}/SET_TOKEN`;

export const SET_SHOW_SUBMIT_BUTTON = `${scope}/SET_SHOW_SUBMIT_BUTTON`;

export const SET_BUTTON_TEXT = `${scope}/SET_BUTTON_TEXT`;

export const ADD_COMPANY = `${scope}/ADD_COMPANY`;
export const ADD_ADMIN = `${scope}/ADD_ADMIN`;
export const ADD_USER = `${scope}/ADD_USER`;

export const SET_MODAL_STATE = `${scope}/SET_MODAL_STATE`;
export const SET_SETUP_MODAL_STATE = `${scope}/SET_SETUP_MODAL_STATE`;
export const SET_MODAL_EDIT_MODE = `${scope}/SET_MODAL_EDIT_MODE`;

export const SUBMIT_COMPANY_DATA = `${scope}/SUBMIT_COMPANY_DATA`;
export const SUBMIT_COMPANY_DATA_SUCCESS = `${scope}/SUBMIT_COMPANY_DATA_SUCCESS`;
export const SUBMIT_COMPANY_DATA_ERROR = `${scope}/SUBMIT_COMPANY_DATA_ERROR`;

export const SUBMIT_ADMIN_DATA = `${scope}/SUBMIT_ADMIN_DATA`;
export const SUBMIT_ADMIN_DATA_SUCCESS = `${scope}/SUBMIT_ADMIN_DATA_SUCCESS`;
export const SUBMIT_ADMIN_DATA_ERROR = `${scope}/SUBMIT_ADMIN_DATA_ERROR`;

export const SUBMIT_USER_DATA = `${scope}/SUBMIT_USER_DATA`;
export const SUBMIT_USER_DATA_SUCCESS = `${scope}/SUBMIT_USER_DATA_SUCCESS`;
export const SUBMIT_USER_DATA_ERROR = `${scope}/SUBMIT_USER_DATA_ERROR`;

export const SEARCH_COMPANIES = `${scope}/SEARCH_COMPANIES`;
export const SEARCH_COMPANIES_SUCCESS = `${scope}/SEARCH_COMPANIES_SUCCESS`;
export const SEARCH_COMPANIES_ERROR = `${scope}/SEARCH_COMPANIES_ERROR`;

export const GET_CONTACT_INFO = `${scope}/GET_CONTACT_INFO`;
export const GET_CONTACT_INFO_SUCCESS = `${scope}/GET_CONTACT_INFO_SUCCESS`;
export const GET_CONTACT_INFO_ERROR = `${scope}/GET_CONTACT_INFO_ERROR`;

export const GET_ALL_TENANTS = `${scope}/GET_ALL_TENANTS`;
export const GET_ALL_TENANTS_SUCCESS = `${scope}/GET_ALL_TENANTS_SUCCESS`;
export const GET_ALL_TENANTS_ERROR = `${scope}/GET_ALL_TENANTS_ERROR`;

export const GET_ALL_USERS = `${scope}/GET_ALL_USERS`;
export const GET_ALL_USERS_SUCCESS = `${scope}/GET_ALL_USERS_SUCCESS`;
export const GET_ALL_USERS_ERROR = `${scope}/GET_ALL_USERS_ERROR`;

export const DELETE_COMPANY = `${scope}/DELETE_COMPANY`;
export const DELETE_COMPANY_SUCCESS = `${scope}/DELETE_COMPANY_SUCCESS`;
export const DELETE_COMPANY_ERROR = `${scope}/DELETE_COMPANY_ERROR`;

export const GET_TAX_INFORMATION = `${scope}/GET_TAX_INFORMATION`;
export const GET_TAX_INFORMATION_SUCCESS = `${scope}/GET_TAX_INFORMATION_SUCCESS`;
export const GET_TAX_INFORMATION_ERROR = `${scope}/GET_TAX_INFORMATION_ERROR`;

export const SAVE_TAXES = `${scope}/SAVE_TAXES`;
export const SAVE_TAXES_SUCCESS = `${scope}/SAVE_TAXES_SUCCESS`;
export const SAVE_TAXES_ERROR = `${scope}/SAVE_TAXES_ERROR`;

export const GET_CONTRIBUTE = `${scope}/GET_CONTRIBUTE`;
export const GET_CONTRIBUTE_SUCCESS = `${scope}/GET_CONTRIBUTE_SUCCESS`;
export const GET_CONTRIBUTE_ERROR = `${scope}/GET_CONTRIBUTE_ERROR`;

export const SAVE_CONTRIBUTES = `${scope}/SAVE_CONTRIBUTES`;
export const SAVE_CONTRIBUTES_SUCCESS = `${scope}/SAVE_CONTRIBUTES_SUCCESS`;
export const SAVE_CONTRIBUTES_ERROR = `${scope}/SAVE_CONTRIBUTES_ERROR`;

export const SET_TAXES_DATA = `${scope}/SET_TAXES_DATA`;

export const GET_ONE_TENANT = `${scope}/GET_ONE_TENANT`;
export const GET_ONE_TENANT_SUCCESS = `${scope}/GET_ONE_TENANT_SUCCESS`;
export const GET_ONE_TENANT_ERROR = `${scope}/GET_ONE_TENANT_ERROR`;

export const DELETE_USER = `${scope}/DELETE_USER`;
export const DELETE_USER_SUCCESS = `${scope}/DELETE_USER_SUCCESS`;
export const DELETE_USER_ERROR = `${scope}/DELETE_USER_ERROR`;

export const GET_ONE_USER = `${scope}/GET_ONE_USER`;
export const GET_ONE_USER_SUCCESS = `${scope}/GET_ONE_USER_SUCCESS`;
export const GET_ONE_USER_ERROR = `${scope}/GET_ONE_USER_ERROR`;

export const GET_OVERTIME = `${scope}/GET_OVERTIME`;
export const GET_OVERTIME_SUCCESS = `${scope}/GET_OVERTIME_SUCCESS`;
export const GET_OVERTIME_ERROR = `${scope}/GET_OVERTIME_ERROR`;

export const GET_TENANT_AUDITS = `${scope}/GET_TENANT_AUDITS`;
export const GET_TENANT_AUDITS_SUCCESS = `${scope}/GET_TENANT_AUDITS_SUCCESS`;
export const GET_TENANT_AUDITS_ERROR = `${scope}/GET_TENANT_AUDITS_ERROR`;

export const GET_AUTH_AUDITS = `${scope}/GET_AUTH_AUDITS`;
export const GET_AUTH_AUDITS_SUCCESS = `${scope}/GET_AUTH_AUDITS_SUCCESS`;
export const GET_AUTH_AUDITS_ERROR = `${scope}/GET_AUTH_AUDITS_ERROR`;

export const GET_USER_AUDITS = `${scope}/GET_USER_AUDITS`;
export const GET_USER_AUDITS_SUCCESS = `${scope}/GET_USER_AUDITS_SUCCESS`;
export const GET_USER_AUDITS_ERROR = `${scope}/GET_USER_AUDITS_ERROR`;

export const UPLOAD_LOGO = `${scope}/UPLOAD_LOGO`;
export const UPLOAD_LOGO_SUCCESS = `${scope}/UPLOAD_LOGO_SUCCESS`;
export const UPLOAD_LOGO_ERROR = `${scope}/UPLOAD_LOGO_ERROR`;

export const GET_AUTH_USER = `${scope}/GET_AUTH_USER`;
export const GET_AUTH_USER_SUCCESS = `${scope}/GET_AUTH_USER_SUCCESS`;
export const GET_AUTH_USER_ERROR = `${scope}/GET_AUTH_USER_ERROR`;

export const UPDATE_TENANT = `${scope}/UPDATE_TENANT`;
export const UPDATE_TENANT_SUCCESS = `${scope}/UPDATE_TENANT_SUCCESS`;
export const UPDATE_TENANT_ERROR = `${scope}/UPDATE_TENANT_ERROR`;

export const UPDATE_USER = `${scope}/UPDATE_USER`;
export const UPDATE_USER_SUCCESS = `${scope}/UPDATE_USER_SUCCESS`;
export const UPDATE_USER_ERROR = `${scope}/UPDATE_USER_ERROR`;

export const SAVE_OVERTIME = `${scope}/SAVE_OVERTIME`;
export const SAVE_OVERTIME_SUCCESS = `${scope}/SAVE_OVERTIME_SUCCESS`;
export const SAVE_OVERTIME_ERROR = `${scope}/SAVE_OVERTIME_ERROR`;

export const GET_LOGIN_COMPANIES = `${loginScope}/GET_LOGIN_COMPANIES`;
export const GET_LOGIN_COMPANIES_SUCCESS = `${loginScope}/GET_LOGIN_COMPANIES_SUCCESS`;
export const GET_LOGIN_COMPANIES_ERROR = `${loginScope}/GET_LOGIN_COMPANIES_ERROR`;

export const GET_VACATION = `${scope}/GET_VACATION`;
export const GET_VACATION_SUCCESS = `${scope}/GET_VACATION_SUCCESS`;
export const GET_VACATION_ERROR = `${scope}/GET_VACATION_ERROR`;

export const SAVE_VACATION = `${scope}/SAVE_VACATION`;
export const SAVE_VACATION_SUCCESS = `${scope}/SAVE_VACATION_SUCCESS`;
export const SAVE_VACATION_ERROR = `${scope}/SAVE_VACATION_ERROR`;

export const UPDATE_HEALTH_INSURANCE = `${scope}/UPDATE_HEALTH_INSURANCE`;
export const UPDATE_HEALTH_INSURANCE_SUCCESS = `${scope}/UPDATE_HEALTH_INSURANCE_SUCCESS`;
export const UPDATE_HEALTH_INSURANCE_ERROR = `${scope}/UPDATE_HEALTH_INSURANCE_ERROR`;

export const GET_HEALTH_INSURANCE = `${scope}/GET_HEALTH_INSURANCE`;
export const GET_HEALTH_INSURANCE_SUCCESS = `${scope}/GET_HEALTH_INSURANCE_SUCCESS`;
export const GET_HEALTH_INSURANCE_ERROR = `${scope}/GET_HEALTH_INSURANCE_ERROR`;

export const GET_TENANT_PROJECTS = `${scope}/GET_TENANT_PROJECTS`;
export const GET_TENANT_PROJECTS_SUCCESS = `${scope}/GET_TENANT_PROJECTS_SUCCESS`;
export const GET_TENANT_PROJECTS_ERROR = `${scope}/GET_TENANT_PROJECTS_ERROR`;

export const SAVE_PROJECT = `${scope}/SAVE_PROJECT`;
export const SAVE_PROJECT_SUCCESS = `${scope}/SAVE_PROJECT_SUCCESS`;
export const SAVE_PROJECT_ERROR = `${scope}/SAVE_PROJECT_ERROR`;

export const DELETE_PROJECT = `${scope}/DELETE_PROJECT`;
export const DELETE_PROJECT_SUCCESS = `${scope}/DELETE_PROJECT_SUCCESS`;
export const DELETE_PROJECT_ERROR = `${scope}/DELETE_PROJECT_ERROR`;

export const GET_ONE_PROJECT = `${scope}/GET_ONE_PROJECT`;
export const GET_ONE_PROJECT_SUCCESS = `${scope}/GET_ONE_PROJECT_SUCCESS`;
export const GET_ONE_PROJECT_ERROR = `${scope}/GET_ONE_PROJECT_ERROR`;

export const SEND_FORGOT_PASSWORD_EMAIL = `${scope}/SEND_FORGOT_PASSWORD_EMAIL`;
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS = `${scope}/SEND_FORGOT_PASSWORD_EMAIL_SUCCESS`;
export const SEND_FORGOT_PASSWORD_EMAIL_ERROR = `${scope}/SEND_FORGOT_PASSWORD_EMAIL_ERROR`;

export const RESET_PASSWORD = `${scope}/RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESS = `${scope}/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_ERROR = `${scope}/RESET_PASSWORD_ERROR`;

export const ACCEPT_TERMS = `${scope}/ACCEPT_TERMS`;
export const ACCEPT_TERMS_SUCCESS = `${scope}/ACCEPT_TERMS_SUCCESS`;
export const ACCEPT_TERMS_ERROR = `${scope}/ACCEPT_TERMS_ERROR`;

export const GET_USER_BY_EMAIL = `${scope}/GET_USER_BY_EMAIL`;
export const GET_USER_BY_EMAIL_SUCCESS = `${scope}/GET_USER_BY_EMAIL_SUCCESS`;
export const GET_USER_BY_EMAIL_ERROR = `${scope}/GET_USER_BY_EMAIL_ERROR`;

export const CHANGE_PASSWORD = `${scope}/CHANGE_PASSWORD`;
export const CHANGE_PASSWORD_SUCCESS = `${scope}/CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_ERROR = `${scope}/CHANGE_PASSWORD_ERROR`;

export const REQUEST_BACKUP = `${scope}/REQUEST_BACKUP`;
export const REQUEST_BACKUP_SUCCESS = `${scope}/REQUEST_BACKUP_SUCCESS`;
export const REQUEST_BACKUP_ERROR = `${scope}/REQUEST_BACKUP_ERROR`;

export const GET_LAST_BACKUP = `${scope}/GET_LAST_BACKUP`;
export const GET_LAST_BACKUP_SUCCESS = `${scope}/GET_LAST_BACKUP_SUCCESS`;
export const GET_LAST_BACKUP_ERROR = `${scope}/GET_LAST_BACKUP_ERROR`;

export const GET_CODE = `${scope}/GET_CODE`;
export const GET_CODE_SUCCESS = `${scope}/GET_CODE_SUCCESS`;
export const GET_CODE_ERROR = `${scope}/GET_CODE_ERROR`;

export const GENERATE_CODE = `${scope}/GENERATE_CODE`;
export const GENERATE_CODE_SUCCESS = `${scope}/GENERATE_CODE_SUCCESS`;
export const GENERATE_CODE_ERROR = `${scope}/GENERATE_CODE_ERROR`;

export const GET_TENANT_SECTORS = `${scope}/GET_TENANT_SECTORS`;
export const GET_TENANT_SECTORS_SUCCESS = `${scope}/GET_TENANT_SECTORS_SUCCESS`;
export const GET_TENANT_SECTORS_ERROR = `${scope}/GET_TENANT_SECTORS_ERROR`;

export const CREATE_SECTOR = `${scope}/CREATE_SECTOR`;
export const CREATE_SECTOR_SUCCESS = `${scope}/CREATE_SECTOR_SUCCESS`;
export const CREATE_SECTOR_ERROR = `${scope}/CREATE_SECTOR_ERROR`;

export const DELETE_SECTOR = `${scope}/DELETE_SECTOR`;
export const DELETE_SECTOR_SUCCESS = `${scope}/DELETE_SECTOR_SUCCESS`;
export const DELETE_SECTOR_ERROR = `${scope}/DELETE_SECTOR_ERROR`;

export const UPDATE_SECTOR = `${scope}/UPDATE_SECTOR`;
export const UPDATE_SECTOR_SUCCESS = `${scope}/UPDATE_SECTOR_SUCCESS`;
export const UPDATE_SECTOR_ERROR = `${scope}/UPDATE_SECTOR_ERROR`;

export const GET_ACCOUNTS = `${scope}/GET_ACCOUNTS`;
export const GET_ACCOUNTS_SUCCESS = `${scope}/GET_ACCOUNTS_SUCCESS`;
export const GET_ACCOUNTS_ERROR = `${scope}/GET_ACCOUNTS_ERROR`;

export const GET_USER_LIST = `${scope}/GET_USER_LIST`;
export const GET_USER_LIST_SUCCESS = `${scope}/GET_USER_LIST_SUCCESS`;
export const GET_USER_LIST_ERROR = `${scope}/GET_USER_LIST_ERROR`;

export const ALL_TENANT_USERS = `${scope}/ALL_TENANT_USERS`;
export const ALL_TENANT_USERS_SUCCESS = `${scope}/ALL_TENANT_USERS_SUCCESS`;
export const ALL_TENANT_USERS_ERROR = `${scope}/ALL_TENANT_USERS_ERROR`;

export const GET_REFRESH_TOKEN = `${scope}/GET_REFRESH_TOKEN`;
export const GET_REFRESH_TOKEN_SUCCESS = `${scope}/GET_REFRESH_TOKEN_SUCCESS`;
export const GET_REFRESH_TOKEN_ERROR = `${scope}/GET_REFRESH_TOKEN_ERROR`;

export const GET_ALL_PROJECTS = `${scope}/GET_ALL_PROJECTS`;
export const GET_ALL_PROJECTS_SUCCESS = `${scope}/GET_ALL_PROJECTS_SUCCESS`;
export const GET_ALL_PROJECTS_ERROR = `${scope}/GET_ALL_PROJECTS_ERROR`;

export const CHECK_QUICKBOOKS = `${scope}/CHECK_QUICKBOOKS`;
export const CHECK_QUICKBOOKS_SUCCESS = `${scope}/CHECK_QUICKBOOKS_SUCCESS`;
export const SET_QUICKBOOKS_LOADING_STATE = `${scope}/SET_QUICKBOOKS_LOADING_STATE`;
export const CHECK_QUICKBOOKS_ERROR = `${scope}/CHECK_QUICKBOOKS_ERROR`;

export const RESET_MODAL_SUBMIT_LOADING = "RESET_MODAL_SUBMIT_LOADING";

export const GET_TENANT_ACCOUNTS = `${scope}/GET_TENANT_ACCOUNTS`;
export const GET_TENANT_ACCOUNTS_SUCCESS = `${scope}/GET_TENANT_ACCOUNTS_SUCCESS`;
export const GET_TENANT_ACCOUNTS_ERROR = `${scope}/GET_TENANT_ACCOUNTS_ERROR`;

export const NEW_ACCOUNT = `${scope}/NEW_ACCOUNT`;
export const NEW_ACCOUNT_SUCCESS = `${scope}/NEW_ACCOUNT_SUCCESS`;
export const NEW_ACCOUNT_ERROR = `${scope}/NEW_ACCOUNT_ERROR`;

export const SAVE_ACCOUNTS = `${scope}/SAVE_ACCOUNTS`;
export const SAVE_ACCOUNTS_SUCCESS = `${scope}/SAVE_ACCOUNTS_SUCCESS`;
export const SAVE_ACCOUNTS_ERROR = `${scope}/SAVE_ACCOUNTS_ERROR`;

export const UPDATE_ACCOUNTS = `${scope}/UPDATE_ACCOUNTS`;
export const UPDATE_ACCOUNTS_SUCCESS = `${scope}/UPDATE_ACCOUNTS_SUCCESS`;
export const UPDATE_ACCOUNTS_ERROR = `${scope}/UPDATE_ACCOUNTS_ERROR`;

export const DELETE_ACCOUNT = `${scope}/DELETE_ACCOUNT`;
export const DELETE_ACCOUNT_SUCCESS = `${scope}/DELETE_ACCOUNT_SUCCESS`;
export const DELETE_ACCOUNT_ERROR = `${scope}/DELETE_ACCOUNT_ERROR`;

export const GET_PAYROLL_ACCOUNTS = `${scope}/GET_PAYROLL_ACCOUNTS`;
export const GET_PAYROLL_ACCOUNTS_SUCCESS = `${scope}/GET_PAYROLL_ACCOUNTS_SUCCESS`;
export const GET_PAYROLL_ACCOUNTS_ERROR = `${scope}/GET_PAYROLL_ACCOUNTS_ERROR`;

export const ADD_PROJECT_QB = `${scope}/ADD_PROJECT_QB`;
export const ADD_PROJECT_QB_SUCCESS = `${scope}/ADD_PROJECT_QB_SUCCESS`;
export const ADD_PROJECT_QB_ERROR = `${scope}/ADD_PROJECT_QB_ERROR`;

export const VERIFY_PASSWORD = `${scope}/VERIFY_PASSWORD`;
export const VERIFY_PASSWORD_SUCCESS = `${scope}/VERIFY_PASSWORD_SUCCESS`;
export const VERIFY_PASSWORD_ERROR = `${scope}/VERIFY_PASSWORD_ERROR`;
