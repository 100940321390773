import { IconDocument, IconPencil } from "assets/icons/icons";
import Button from "components/Button/Button.component";
import InputCustom from "components/InputCustom/InputCustom.component";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import { useDispatch, useSelector } from "react-redux";
import { globalState } from "types/globalState.type";
import { SalariesState } from "types/reducers/salary.reducer.type";

import styles from "pages/Calculations/Calculations.module.scss";
import { inputOnFocusSelect } from "utils/globalVariables";
import { emailContract, emailPayslip, setModalEditMode } from "../actions";
import {
	formatNumber_,
	number_format,
	parseNumberCustom,
} from "utils/functions";
import { toast } from "react-toastify";
import { Salary } from "types/salary.type";

interface Props {
	showModal?: any;
	modalOpener?: string;
	submitSalaryData?: any;
	modalCloseHandler?: any;
	selectedRow?: any;
	modalContentRef?: any;
	allModalInputs?: any;
	setAllModalInputs?: any;
	handleModalInputChange?: any;
	selectedCheckboxes?: any;
	pickedMonth?: number;
	pickedYear?: number;
}

const CalculationsModal: React.FC<Props> = (props) => {
	const state: SalariesState = useSelector(
		(state: globalState) => state.calculationsReducer
	);
	const editMode = state?.editMode;

	const closedPeriod = state?.closed;

	const dispatch = useDispatch();
	const setEditMode = (state) => {
		dispatch(setModalEditMode(state));
	};
	const paySlipEmailButtonHandler = () => {
		if (!selectedCheckboxes.length) {
			toast.warn("Selektoni të paktën një rresht për të dërguar në email!");
		} else {
			let data: any = new FormData();
			state.salaries &&
				state.salaries.forEach((item: Salary) => {
					data.append("employeeIds[]", item.employeeId);
				});
			data.append("month", pickedMonth + 1);
			dispatch(emailPayslip(data));
		}
	};

	const contractEmailButtonHandler = () => {
		if (!selectedCheckboxes.length) {
			toast.warn("Selektoni të paktën një rresht për të dërguar në email!");
		} else {
			let data: any = new FormData();

			selectedCheckboxes &&
				selectedCheckboxes.forEach((item: Salary) => {
					data.append("employeeIds[]", item);
				});
			data.append("month", pickedMonth + 1);
			data.append("year", pickedYear);
			dispatch(emailContract(data));
		}
	};

	const {
		showModal,
		modalCloseHandler,
		submitSalaryData,
		modalOpener,
		modalContentRef,
		allModalInputs,
		handleModalInputChange,
		selectedCheckboxes,
		pickedMonth,
		pickedYear,
	} = props;
	let hours = allModalInputs?.bruttoWithoutInsurance
		? allModalInputs?.bruttoWithoutInsurance.replace(",", "")
		: allModalInputs?.bruttoWithoutInsurance;
	let salaryType = allModalInputs ? allModalInputs.salaryPaymentType : "";
	console.log(allModalInputs?.nightPrice);
	return (
		<ModalCustom
			show={showModal}
			contentClass={styles.contentClass}
			footerClass={styles.footerClass}
			onClose={modalCloseHandler}
			onSubmit={(event) => {
				const formatedData = {
					...allModalInputs,
					stops: allModalInputs.stops,
					avanseStops: allModalInputs.avanse,
					phoneStops: allModalInputs.phone,
					ticketStops: allModalInputs.ticket,
					initial_brutto: allModalInputs.initialBrutto,
					brutto: +allModalInputs.initialBrutto?.replaceAll(",", ""),
					extraBrutto: +allModalInputs.extraBrutto,
					extraHours: +allModalInputs.extraHours,
					extraNetto: +allModalInputs.extraNetto,
				};
				submitSalaryData(formatedData);
			}}
			submitLoading={state.submitLoading}
			fixDimensions={true}
			hideFooterButton={
				{
					email: true,
					brutto: !editMode,
					bruttoHours: !editMode,
					hours: !editMode,
					additions: !editMode,
					deductions: !editMode,
				}[modalOpener]
			}
			header={
				<div className={styles.modalHeader}>
					{
						{
							email: "Dërgo me Email",
							brutto: (
								<div className={styles.headerContainer}>
									<div className={styles.title}>
										Edito të dhënat e pagës bruto:
										<span className={styles.asterix}>*</span>
									</div>
								</div>
							),
							bruttoHours: (
								<div className={styles.headerContainer}>
									<div className={styles.title}>
										Edito të dhënat e pagës me orë:
										<span className={styles.asterix}>*</span>
									</div>
								</div>
							),
							hours: (
								<div className={styles.headerContainer}>
									<div className={styles.title}>
										Edito të dhënat orëve:
										<span className={styles.asterix}>*</span>
									</div>
								</div>
							),
							additions: (
								<div className={styles.headerContainer}>
									<div className={styles.title}>
										Edito të dhënat e shtesave:
										<span className={styles.asterix}>*</span>
									</div>
								</div>
							),
							deductions: (
								<div className={styles.headerContainer}>
									<div className={styles.title}>
										Edito të dhënat e ndalesave:
										<span className={styles.asterix}>*</span>
									</div>
								</div>
							),
						}[modalOpener]
					}

					{!editMode && modalOpener !== "email" && closedPeriod !== 1 ? (
						<div
							className={styles.icon}
							onClick={() => {
								setEditMode(true);
								let firstInput = modalContentRef?.current?.querySelectorAll(
									"input:not(.noFocusSet)"
								)[0];
								firstInput && firstInput.focus();
							}}
						>
							<IconPencil />
						</div>
					) : (
						""
					)}
				</div>
			}
		>
			<div
				className={`${styles.modalContent} ${editMode ? styles.editMode : ""} ${modalOpener === "hours" ? "pr-0" : ""
					}`}
				ref={modalContentRef}
			>
				{
					{
						email: (
							<div className={`${styles.row} ${styles.emailModalContainer}`}>
								<Button
									onClickHandler={paySlipEmailButtonHandler}
									className={`lightButton ${styles.sendEmailButton}`}
									loading={state.emailPayslipLoading}
									viewType={"light"}
									noPadding={true}
								>
									<div className={`${styles.iconContainer}`}>
										<IconDocument stroke="#89c732" width="2px" />
									</div>
									<div className={`${styles.buttonTitle}`}>Fletëpagesa</div>
								</Button>
								<Button
									onClickHandler={contractEmailButtonHandler}
									className={`lightButton ${styles.sendEmailButton}`}
									loading={state.emailContractLoading}
									noPadding={true}
									viewType={"light"}
								>
									<div className={`${styles.iconContainer}`}>
										<IconDocument stroke="#89c732" width="2px" />
									</div>
									<div className={`${styles.buttonTitle}`}>Dokumentet</div>
								</Button>
							</div>
						),
						brutto: (
							<div
								className={`${styles.modalRow} ${styles.w500} ${styles.bruttoRow}`}
							>
								<div className={`${styles.contentRow}`}>
									<div className={styles.modalRowTitle}>Paga bruto</div>
									<InputCustom
										className={styles.modalInput}
										type="text"
										name="initialBrutto"
										readOnly={!editMode}
										value={allModalInputs?.initialBrutto}
										onChangeHandler={handleModalInputChange}
									/>
								</div>
							</div>
						),
						bruttoHours: (
							<div className={`${styles.modalRow} ${styles.bruttoHoursRow}`}>
								<div className={`${styles.contentRow}`}>
									<div className={styles.modalRowTitle}>Numri i orëve</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="workingHours"
										readOnly={!editMode}
										value={allModalInputs?.workingHours}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
									<div className={styles.rowDescription}>
										<div className={styles.desc}>Çmimi i orës:</div>
										<div className={styles.value}>
											{allModalInputs?.pricePerHour}
										</div>
									</div>
								</div>
							</div>
						),
						hours: (
							<div
								className={`${styles.row} ${styles.modalRow} ${styles.w500} ${styles.hoursContent}`}
							>
								<div
									className={`${styles.contentRow} ${styles.hoursRow} ${styles.dashedBottom}`}
								>
									<div className={styles.modalRowTitle}>Të rregullta</div>
									<div className={styles.regularHoursContainer}>
										<InputCustom
											className={styles.modalInput}
											type="number"
											name="workingHours"
											value={
												allModalInputs?.workingHours !== null &&
													allModalInputs?.workingHours != 0
													? formatNumber_(allModalInputs?.workingHours, 2)
													: formatNumber_(
														allModalInputs?.workingHoursPerMonth,
														2
													)
											}
											inputClass={"noFocusSet"}
											readOnly={!editMode}
											onChangeHandler={handleModalInputChange}
											onFocusHandler={inputOnFocusSelect}
										/>
										<div className={styles.rowDescription}>
											<div className={styles.desc}>Çmimi i orës:</div>
											<div className={styles.value}>
												{(allModalInputs?.pricePerHour ?? 0) > 0
													? formatNumber_(allModalInputs.pricePerHour, 2, false, true)
													: formatNumber_(
														hours / allModalInputs?.workingHoursPerMonth,
														2,
														false,
														true
													)}
											</div>
										</div>
									</div>
									<div className={styles.equalSign}>=</div>
									<div>
										<InputCustom
											className={`${styles.modalInput} ${styles.static}`}
											type="number"
											name="workingHours"
											value={
												allModalInputs?.bruttoWithoutInsurance
													? allModalInputs?.bruttoWithoutInsurance.replace(
														",",
														""
													)
													: ""
											}
											// value={
											//   formatNumber_(formatNumber_((allModalInputs?.workingHours !== null ?  formatNumber_(allModalInputs?.workingHours, 2) :
											//       formatNumber_(allModalInputs?.workingHoursPerMonth, 2)) * allModalInputs?.pricePerHour), 2)}
											inputClass={"noFocusSet"}
											readOnly={true}
											onChangeHandler={handleModalInputChange}
											onFocusHandler={inputOnFocusSelect}
										/>
									</div>
								</div>

								<div className={`${styles.secondContent} ${styles.hoursRow}`}>
									<div className={styles.modalRowTitle}>Natën</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="nightHours"
										readOnly={!editMode}
										value={parseNumberCustom(
											formatNumber_(allModalInputs?.nightHours, 2, false, true)
										)}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
									<div className={styles.equalSign}>=</div>
									<InputCustom
										className={`${styles.modalInput} ${styles.static}`}
										type="text"
										name="nightPrice"
										readOnly={true}
										value={parseNumberCustom(
											number_format(+allModalInputs?.nightPrice, 2)
										)}
									/>
								</div>
								<div className={`${styles.secondContent} ${styles.hoursRow}`}>
									<div className={styles.modalRowTitle}>Fundjavën</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="weekendHours"
										readOnly={!editMode}
										value={parseNumberCustom(
											formatNumber_(
												allModalInputs?.weekendHours,
												2,
												false,
												true
											)
										)}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
									<div className={styles.equalSign}>=</div>
									<InputCustom
										className={`${styles.modalInput} ${styles.static}`}
										type="text"
										name="weekendPrice"
										readOnly={true}
										value={parseNumberCustom(
											number_format(allModalInputs?.weekendPrice, 2)
										)}
									/>
								</div>
								<div className={`${styles.secondContent} ${styles.hoursRow}`}>
									<div className={styles.modalRowTitle}>Jashtë Orarit</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="afterWorkHours"
										readOnly={!editMode}
										value={parseNumberCustom(
											formatNumber_(
												allModalInputs?.afterWorkHours,
												2,
												false,
												true
											)
										)}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
									<div className={styles.equalSign}>=</div>
									<InputCustom
										className={`${styles.modalInput} ${styles.static}`}
										type="text"
										name="afterWorkPrice"
										readOnly={true}
										value={parseNumberCustom(
											number_format(allModalInputs?.afterWorkPrice, 2)
										)}
									/>
								</div>
								<div className={`${styles.secondContent} ${styles.hoursRow}`}>
									<div className={styles.modalRowTitle}>Festat</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="holidaysHours"
										readOnly={!editMode}
										value={parseNumberCustom(
											formatNumber_(
												allModalInputs?.holidaysHours,
												2,
												false,
												true
											)
										)}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
									<div className={styles.equalSign}>=</div>
									<InputCustom
										className={`${styles.modalInput} ${styles.static}`}
										type="text"
										name="holidaysPrice"
										readOnly={true}
										value={parseNumberCustom(
											number_format(allModalInputs?.holidaysPrice, 2)
										)}
									/>
								</div>
								<div className={`${styles.secondContent} ${styles.hoursRow}`}>
									<div className={styles.modalRowTitle}>Kujdestaria</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="guardHours"
										readOnly={!editMode}
										value={parseNumberCustom(
											formatNumber_(allModalInputs?.guardHours, 2, false, true)
										)}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
									<div className={styles.equalSign}>=</div>
									<InputCustom
										className={`${styles.modalInput} ${styles.static}`}
										type="text"
										name="guardPrice"
										readOnly={true}
										value={parseNumberCustom(
											number_format(allModalInputs?.guardPrice, 2)
										)}
									/>
								</div>
								<div className={`${styles.secondContent} ${styles.hoursRow}`}>
									<div className={styles.modalRowSubtitle}>
										Totali i orëve =
									</div>
									<InputCustom
										className={`${styles.modalInput} ${styles.static}`}
										type="number"
										name="hoursTotal"
										readOnly={true}
										value={
											formatNumber_(allModalInputs?.totalHours, 2)
												? (allModalInputs?.workingHours !== null
													? formatNumber_(allModalInputs?.workingHours, 2)
													: formatNumber_(
														allModalInputs?.workingHoursPerMonth,
														2
													)) + formatNumber_(allModalInputs?.totalHours, 2)
												: allModalInputs?.workingHours !== null
													? formatNumber_(allModalInputs?.workingHours, 2)
													: formatNumber_(allModalInputs?.workingHoursPerMonth, 2)
										}
									/>
									<div className={styles.equalSign}>=</div>
									<InputCustom
										className={`${styles.modalInput} ${styles.static}`}
										type="text"
										name="totalPrice"
										readOnly={true}
										value={parseNumberCustom(
											number_format(
												allModalInputs?.totalPrice +
												((allModalInputs?.workingHours ?? 0) * (allModalInputs?.pricePerHour ?? 0)),
												2
											)
										)}
									/>
								</div>
							</div>
						),
						additions: (
							<div
								className={`${styles.modalRow} ${styles.w500} ${styles.additionsRow}`}
							>
								<div className={`${styles.contentRow}`}>
									<div className={styles.modalRowTitle}>Shtesat Bruto</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="extraBrutto"
										readOnly={!editMode}
										value={formatNumber_(allModalInputs?.extraBrutto, 2) || ""}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
								</div>
								<div className={`${styles.contentRow}`}>
									<div className={styles.modalRowTitle}>Shtesat Neto</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="extraNetto"
										readOnly={!editMode}
										value={
											formatNumber_(allModalInputs?.extraNetto, 2) ||
											"pricePerHour"
										}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
								</div>
								{/* <div className={`${styles.contentRow}`}>
							<div className={styles.modalRowTitle}>
								Sigurimi shëndetësor
							</div>
							<InputCustom
								className={styles.modalInput}
								type="number"
								name="healthInsurance"
								readOnly={!editMode}
								value={formatNumber_(allModalInputs?.healthInsurance, 2) || ""}
								onChangeHandler={handleModalInputChange}
								onFocusHandler={inputOnFocusSelect}
							/>
						</div> */}
							</div>
						),
						deductions: (
							<div
								className={`${styles.modalRow} ${styles.w500} ${styles.additionsRow}`}
							>
								<div className={`${styles.contentRow}`}>
									<div className={styles.modalRowTitle}>Ndalesa</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="stops"
										readOnly={!editMode}
										value={
											formatNumber_(allModalInputs?.stops, 2)
												? formatNumber_(allModalInputs?.stops, 2)
												: allModalInputs?.stops || ""
										}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
								</div>
								<div className={`${styles.contentRow}`}>
									<div className={styles.modalRowTitle}>Ndalesat e avansit</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="avanse"
										readOnly={!editMode}
										value={
											formatNumber_(allModalInputs?.avanse, 2)
												? formatNumber_(allModalInputs?.avanse, 2)
												: allModalInputs?.avanse || ""
										}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
								</div>
								<div className={`${styles.contentRow}`}>
									<div className={styles.modalRowTitle}>Telefoni</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="phone"
										readOnly={!editMode}
										value={
											formatNumber_(allModalInputs?.phone, 2)
												? formatNumber_(allModalInputs?.phone, 2)
												: allModalInputs?.phone || ""
										}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
								</div>
								<div className={`${styles.contentRow}`}>
									<div className={styles.modalRowTitle}>Gjobat</div>
									<InputCustom
										className={styles.modalInput}
										type="number"
										name="ticket"
										readOnly={!editMode}
										value={
											formatNumber_(allModalInputs?.ticket, 2)
												? formatNumber_(allModalInputs?.ticket, 2)
												: allModalInputs?.ticket || ""
										}
										onChangeHandler={handleModalInputChange}
										onFocusHandler={inputOnFocusSelect}
									/>
								</div>
							</div>
						),
					}[modalOpener]
				}
			</div>
		</ModalCustom>
	);
};

export default CalculationsModal;
