/* eslint-disable */
import { IconDocument, QuickBooksIcon } from "assets/icons/icons";
import AppWrapper from "components/AppWrapper/AppWrapper.component";
import Button from "components/Button/Button.component";
import CommonHeader from "components/partials/CommonHeader/CommonHeader.component";
import {
	FunctionComponent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import {
	FiCalendar,
	FiCheck,
	FiDownload,
	FiLogIn,
	FiLock,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber_, generateArrayOfYears } from "utils/functions";
import { monthNamesAL } from "utils/globalVariables";
import { setBackdropState } from "components/AppWrapper/actions";
import Loader from "components/partials/Loader/Loader.component";

import styles from "./Calculations.module.scss";
import tableStyles from "components/Table/Table.module.scss";
import Table from "components/Table/Table.component";

import Checkbox from "react-custom-checkbox";
import InputCustom from "components/InputCustom/InputCustom.component";
import {
	exportEcxel,
	generatePayslip,
	loadSalaries,
	setModalEditMode,
	setModalOpen,
	submitUpdateSalary,
	closeMonth,
	openMonth,
} from "./actions";
import { globalState } from "types/globalState.type";

import { collapseSidebarMenu } from "components/AppWrapper/actions";
import { Salary } from "types/salary.type";
import { SalariesState } from "types/reducers/salary.reducer.type";

import _ from "lodash";
import CalculationsModal from "./Modal/Modal.component";
import TableHeader from "./TableHeader/TableHeader.component";
import Payslip from "./Payslip/Payslip.component";
import { toast } from "react-toastify";
import { Shortcut } from "pages/Dashboard/Shortcut/Shortcut.component";
import ReactTooltip from "react-tooltip";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import { getOvertime } from "pages/Configurations/actions";
import { ConfigurationsState } from "types/reducers/configurations.reducer.type";
import * as actions from "../../pages/Configurations/actions";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import { SelectOption } from "../../types/selectOption.type";
import axios from "axios";
import { baseURL } from "../../api";
import { hasSalaries, unsyncedEmployeesWithQuickbooks } from "services/salaries.service";
import UnsyncedEmployeesWithQuickbooksConfirmationModal from "components/UnsyncedEmployeesWithQuickbooksConfirmationModal";
import { SalaryPaymentType } from "types/employee.type";
import Select from "react-select";

interface SalaryAdjustment {
	extraNetto: number;
	extraBrutto: number;
	initialBrutto: number;
	workingHours?: number;
	salaryId: number;
	healthInsurance: number;
	brutto: number;
	stops: number;
	regularHours: number;
	afterWorkHours: number;
	weekendHours: number;
	nightHours: number;
	guardHours: number;
	holidaysHours: number;
	avanseStops: number;
	phoneStops: number;
	ticketStops: number;
	pricePerHour: number;
}

interface UpdateSalary {
	userId: number;
	salaryAdjustment: SalaryAdjustment;
	month: number;
	year: number;
	tenantId: number;
}

const HeaderSubtitle = ({ month, year, name }) => {
	return (
		<>
			{name ? (
				<>
					{`Krijuar në ${month} ${year} nga`}
					<div className={styles.creatorName}>{name}</div>
				</>
			) : (
				<></>
			)}
		</>
	);
};

let currentYear: number = new Date().getFullYear();
let currentMonth: number = new Date().getMonth();

const monthsList = monthNamesAL;
let yearsList = generateArrayOfYears(21, true);

const Calculations: FunctionComponent = () => {
	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);

	const dispatch = useDispatch();

	const backdropParent: string = useSelector(
		(state: globalState) => state.appWrapperReducer.backdropParent
	);
	const state: SalariesState = useSelector(
		(state: globalState) => state.calculationsReducer
	);
	const configurationState: ConfigurationsState = useSelector(
		(state: globalState) => state.configurationsReducer
	);

	const salaries = state?.salaries;
	const showModal = state.showModal;
	const showPayslip = state.showPayslip;

	const creatorName = state.createdBy;
	const closedPeriod = state.closed;

	const [selectedLanguageForQB, setSelectedLanguageForQB] =
		useState<SelectOption>({ value: 0, label: "Shqip" });

	const [modalOpener, setModalOpener] = useState("");
	const [selectedRow, setSelectedRow] = useState(null);
	const [mainCheckboxClick, setMainCheckboxClick] = useState(null);
	const [mainCheckboxState, setMainCheckboxState] = useState(false);
	const [showCalculateUnsyncedModal, setShowCalculateUnsyncedModal] =
		useState(false);
	const [unsyncedEmployees, setUnsyncedEmployees] = useState([]);
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
	const [showFilterContent, setShowFilterContent] = useState(false);
	const [pickedYear, setPickedYear] = useState(currentYear);
	const [pickedMonth, setPickedMonth] = useState(currentMonth);
	const [allModalInputs, setAllModalInputs] = useState<any>({});
	const [buttonText, setButtonText] = useState("");
	const [openConfirmSalaryCalculationModal, setOpenConfirmSalaryCalculationModal] = useState(false);
	const [openCalculationLanguageSelector, setOpenCalculationLanguageSelector] = useState(false);
	const [language, setLanguage] =
		useState<SelectOption>({ value: 0, label: "Shqip" });

	const createdMonth = monthsList[pickedMonth];

	const monthsWrapperRef = useRef(null);
	const periodWrapperRef = useRef(null);
	const modalContentRef = useRef(null);

	const [isPeriodOpen, setIsPeriodOpen] = useState<boolean>(false);
	const [code, setCode] = useState(null);

	const hasQuickbooks = useSelector(
		(state: globalState) => state.globalReducer.hasQuickbooks
	);
	const quickbooksLoadingState = useSelector(
		(state: globalState) => state.configurationsReducer?.quickbooksLoadingState
	);

	const setEditMode = (state) => {
		dispatch(setModalEditMode(state));
	};

	const setShowModal = (set: boolean) => {
		dispatch(setModalOpen(set));
	};

	const handlePeriodContainerClick = (event) => {
		setShowFilterContent((prev) => !prev);
		dispatch(
			setBackdropState({
				showBackdrop: !showFilterContent,
				backDrop_callback: backDropCallback,
				backdropParent: !showFilterContent ? "calculationsPeriodFilter" : null,
			})
		);
	};

	useEffect(() => {
		if (closedPeriod === 1) {
			setButtonText("Hap Periudhën");
		} else {
			setButtonText("Mbyll Periudhën");
		}
	}, [closedPeriod]);

	const bruttoClickHandler = (payload) => {
		setShowModal(true);
		setModalOpener((prev) => "brutto");
		setSelectedRow((prev) => payload.row || null);
	};

	const bruttoHoursClickHandler = (payload) => {
		setShowModal(true);
		setModalOpener((prev) => "bruttoHours");
		setSelectedRow((prev) => payload.row || null);
	};

	const hoursClickHandler = (payload) => {
		setShowModal(true);
		setModalOpener((prev) => "hours");
		setSelectedRow((prev) => payload.row || null);
	};

	const additionsClickHandler = (payload) => {
		setShowModal(true);
		setModalOpener((prev) => "additions");
		setSelectedRow((prev) => payload.row || null);
	};

	const deductionsClickHandler = (payload) => {
		setShowModal(true);
		setModalOpener((prev) => "deductions");
		setSelectedRow((prev) => payload.row || null);
	};

	const sendWithEmailHandler = (event) => {
		setShowModal(true);
		setModalOpener((prev) => "email");
	};

	const excelExportHandler = () => {
		dispatch(exportEcxel(tenantId, pickedMonth + 1, pickedYear));
	};

	const handleIsPeriodOpen = () => {
		setIsPeriodOpen(true);
	};

	const handlePaymentSlipButton = (event, selectedCheckboxes) => {
		console.log(typeof selectedCheckboxes[0]);
		if (selectedCheckboxes.length > 1 || !selectedCheckboxes.length) {
			toast.warn("Selektoni vetëm një rresht për të gjeneruar fletëpagesën");
		} else {
			dispatch(generatePayslip({ salaryId: selectedCheckboxes[0] }));
		}
	};

	const backDropCallback = () => {
		setShowFilterContent((prev) => !prev);
		setSelectedRow((prev) => null);
	};

	const modalCloseHandler = (event) => {
		setShowModal(false);
		setSelectedRow((prev) => null);

		setAllModalInputs((prev) => { });
		setEditMode(false);
	};

	const companyCodeChangeHandler = (event) => {
		setCode(event.target.value);
	};

	useEffect(() => {
		dispatch(getOvertime(tenantId));
		dispatch(actions.setQuickBooksSuccessLoadingState(false));
	}, []);

	const handleModalInputChange = (event) => {
		const names = ["night", "weekend", "afterWork", "holidays", "guard"];
		const name = event?.target?.name;
		const value = event?.target?.value;
		let setName = null;
		let percentage = null;

		let pricePerHour = allModalInputs.pricePerHour;

		let sumHours: number = 0;
		let sumPrices: number = 0;

		switch (name) {
			case "nightHours":
				setName = "nightPrice";
				percentage = configurationState?.overtime?.nightPercentage / 100;
				break;
			case "weekendHours":
				setName = "weekendPrice";
				percentage = configurationState?.overtime?.weekendPercentage / 100;
				break;
			case "afterWorkHours":
				setName = "afterWorkPrice";
				percentage = configurationState?.overtime?.afterHoursPercentage / 100;
				break;
			case "holidaysHours":
				setName = "holidaysPrice";
				percentage = configurationState?.overtime?.holidayPercentage / 100;
				break;
			case "guardHours":
				setName = "guardPrice";
				percentage = configurationState?.overtime?.guardPercentage / 100;
				break;
			case "workingHours":
				setName = "pricePerHour";
				if (allModalInputs?.salaryPaymentType == SalaryPaymentType.Hourly) {
					pricePerHour = allModalInputs?.pricePerHour;
				} else {
					pricePerHour =
						Number(allModalInputs?.bruttoWithoutInsurance?.replace(",", "")) /
						Number(value);
				}

				let recalculateSumPrices = 0;

				const updatedPrices = names.reduce((acc, itemName) => {
					const hourName = itemName + "Hours";
					const priceName = itemName + "Price";
					const currentHours = allModalInputs[hourName];
					let currentPercentage = configurationState?.overtime?.nightPercentage;

					if (itemName === "weekend") {
						currentPercentage = configurationState?.overtime?.weekendPercentage;
					} else if (itemName === "afterWork") {
						currentPercentage =
							configurationState?.overtime?.afterHoursPercentage;
					} else if (itemName === "holidays") {
						currentPercentage = configurationState?.overtime?.holidayPercentage;
					} else if (itemName === "guard") {
						currentPercentage = configurationState?.overtime?.guardPercentage;
					}

					acc[priceName] =
						Number(currentHours) *
						(pricePerHour + pricePerHour * (currentPercentage / 100));

					recalculateSumPrices += acc[priceName];
					return acc;
				}, {});


				setAllModalInputs((prev) => ({
					...prev,
					...updatedPrices,
					totalPrice: recalculateSumPrices,
				}));
				break;
		}

		setAllModalInputs((prev) => ({ ...prev, [name]: value }));

		const setValue =
			Number(value) *
			(Number(pricePerHour) + Number(pricePerHour) * Number(percentage));

		names.forEach((item: string) => {
			const hourString: string = item + "Hours";
			const valueString: string = item + "Price";

			if (hourString === name) {
				sumHours += +value;
				sumPrices += +setValue;
			} else {
				sumHours += +allModalInputs[hourString];
				sumPrices += +allModalInputs[valueString];
			}
		});

		setAllModalInputs((prev) => {
			const newInputs = {
				...prev,
			};

			if (setName == "pricePerHour") {
				newInputs.pricePerHour = pricePerHour;
			} else {
				newInputs[setName] = setValue;
				newInputs.totalHours = sumHours;
				newInputs.totalPrice = sumPrices;
			}

			return newInputs;
		});
	};

	useEffect(() => {
		showFilterContent &&
			periodWrapperRef?.current &&
			(periodWrapperRef.current.style.height =
				monthsWrapperRef.current.offsetHeight + 2 + "px");
	}, [showFilterContent]);

	const stableDispatch = useCallback(dispatch, []);

	useEffect(() => {
		setShowFilterContent((prev) => false);
		stableDispatch(
			setBackdropState({
				showBackdrop: false,
				backDrop_callback: backDropCallback,
			})
		);

		const month = pickedMonth + 1;
		const salariesPayload = { tenantId, month, year: pickedYear };


		const checkIfHasSalaries = async () => {
			try {
				const response = await hasSalaries(tenantId, month, pickedYear)
				if (response.data.hasSalaries) {
					stableDispatch(loadSalaries(salariesPayload));
				} else {
					setOpenConfirmSalaryCalculationModal(true);
				}
			} catch (err) {
				toast.error('Ka ndodhur një gabim gjatë marrjes së të dhënave');
			}
		}
		checkIfHasSalaries();
	}, [pickedMonth, pickedYear, stableDispatch]);



	useEffect(() => {
		switch (mainCheckboxState) {
			case true:
				const checkArray = [];
				mainCheckboxClick &&
					state.salaries?.forEach((item) => checkArray.push(item.id));
				mainCheckboxClick && setSelectedCheckboxes((prev) => checkArray);
				break;
			case false:
				mainCheckboxClick && setSelectedCheckboxes((prev) => []);
				break;
		}
	}, [mainCheckboxState, state.salaries, mainCheckboxClick]);

	useEffect(() => {
		if (selectedRow) {
			const selectedData: any = salaries?.filter((item) => {
				return item.id === selectedRow.id;
			})[0];
			selectedData &&
				setAllModalInputs((prev) => {
					return {
						...prev,
						...selectedData,
						...selectedData?.overtimeTable,
						...selectedData?.extraTable,
						...selectedData?.stopTable,
						stops: selectedData?.stopTable?.stops,
						avanseStops: selectedData?.stopTable?.avanse,
						phoneStops: selectedData?.stopTable?.phone,
						ticketStops: selectedData?.stopTable?.ticket,
						pricePerHour: selectedData?.pricePerHour,
					};
				});
		}
	}, [selectedRow, salaries]);

	useEffect(() => {
		setMainCheckboxClick(true);
		setMainCheckboxState(true);
		setSelectedCheckboxes(state.salaries?.map((item) => item.id));
	}, [salaries?.length]);

	// useEffect(() => {
	//   const salaries = state.salaries?.length || 0;
	//   const rows = selectedCheckboxes?.length || 0;
	//   salaries > 0 && salaries === rows
	//     ? setMainCheckboxState((prev) => true)
	//     : setMainCheckboxState((prev) => false);
	// }, [selectedCheckboxes, state.salaries]);

	const initialLoad = () => {
		dispatch(collapseSidebarMenu(true));
	};

	const handleQuickBooks = async () => {
		if (closedPeriod === 1) {
			toast.warn(
				"Periudha është e mbyllur! Nëse dëshironi të kryeni kontimin, ju lutem ri-hapni periudhën"
			);
			return;
		}
		if (quickbooksLoadingState) {
			return;
		}
		const payload = {
			tenantId,
			month: pickedMonth + 1,
			year: pickedYear,
			language: language?.value == 1 ? "Eng" : "Alb",
		} as any;

		try {
			const unsyncedEmployeesResponse = await unsyncedEmployeesWithQuickbooks(
				payload
			);
			if (
				unsyncedEmployeesResponse.data &&
				unsyncedEmployeesResponse.data.length > 0
			) {
				setUnsyncedEmployees(
					unsyncedEmployeesResponse.data.map((e) => e.employee)
				);
				setShowCalculateUnsyncedModal(true);
				return;
			}
		} catch (err) {
			console.error("failed", err);
		}
		payload.employeeIds =
			salaries
				.filter((s) => {
					return selectedCheckboxes.includes(s.id);
				})
				.filter((s) => {
					return Number(s.totalBrutto?.toString().replace(',', '') ?? 0) > 0;
				})
				.map((s) => s.employeeId) ?? [];
		if (payload.employeeIds.length === 0) {
			toast.info("Nuk ka paga per te kontuar");
			return;
		}
		dispatch(actions.checkQuickbooks(payload));
		toast.info("Kontimi me Quickbooks është duke u procesuar.");
	};

	const submitSalaryDataOld = (selectedRow) => {
		dispatch(
			submitUpdateSalary({
				tenantId,
				salaryId: selectedRow?.id,
				data: {
					...selectedRow,
					initialBrutto: selectedRow?.initialBrutto?.replace("\t", ""),
					initial_brutto: selectedRow?.initialBrutto?.replace("\t", ""),
				},
				month: pickedMonth + 1,
				year: pickedYear,
			})
		);
	};

	const submitSalaryData = (selectedRow) => {
		const data: UpdateSalary = {
			userId: tenantId,
			salaryAdjustment: {
				pricePerHour: allModalInputs.pricePerHour ?? 0,
				salaryId: selectedRow?.id ?? 0,
				initialBrutto: Number(
					selectedRow?.initial_brutto?.replace("\t", "")?.replace(",", "")
				),
				extraNetto: selectedRow.extraNetto,
				extraBrutto: selectedRow.extraBrutto,
				brutto: selectedRow.brutto,
				guardHours: selectedRow.guardHours,
				weekendHours: selectedRow.weekendHours,
				holidaysHours: selectedRow.holidaysHours,
				nightHours: selectedRow.nightHours,
				afterWorkHours: selectedRow.afterWorkHours,
				stops: selectedRow.stops?.length > 0 ? selectedRow.stops : 0,
				avanseStops:
					selectedRow.avanseStops?.length > 0 ? selectedRow.avanseStops : 0,
				phoneStops:
					selectedRow.phoneStops?.length > 0 ? selectedRow.phoneStops : 0,
				ticketStops:
					selectedRow.ticketStops?.length > 0 ? selectedRow.ticketStops : 0,
				healthInsurance: 0,
				regularHours: selectedRow.regularHours,
				workingHours: selectedRow.workingHours,
			},
			month: pickedMonth + 1,
			year: pickedYear,
			tenantId,
		};
		dispatch(submitUpdateSalary(data));
	};

	useEffect(() => {
		axios
			.get(`${baseURL}/register/getLanguage`, {
				headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
			})
			.then((res) => {
				if (res?.data == 0) {
					setSelectedLanguageForQB({ value: 0, label: "Shqip" });
				}
				if (res?.data === 1) {
					setSelectedLanguageForQB({ value: 1, label: "English" });
				}
			});
	}, []);
	function onStateChangeLanguage(option) {
		axios.post(
			`${baseURL}/register/updateSelectedLanguageQB`,
			{ value: option?.value },
			{ headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
		);
	}

	useEffect(initialLoad, []);
	console.log('here', state.totals);
	return (
		<>
			<AppWrapper appContentClass={styles.appContent}>
				<div className={styles.calculationsComponent}>
					<ModalCustom
						show={openConfirmSalaryCalculationModal}
						onClose={() => {
							setOpenConfirmSalaryCalculationModal(false);
						}}
						onSubmit={() => {
							stableDispatch(loadSalaries({ tenantId, month: pickedMonth + 1, year: pickedYear }));
							setOpenConfirmSalaryCalculationModal(false);
						}}
						showSubmitButton
						showRejectButton
						header={
							<div>
								<div>Nuk ka llogaritje për muajin aktual</div>
							</div>
						}
						submitText={'Llogari'}>
						<h4>A dëshironi të llogaritni pagat për periudhën e zgjedhur?</h4>
					</ModalCustom>
					<UnsyncedEmployeesWithQuickbooksConfirmationModal
						showModal={showCalculateUnsyncedModal}
						onClose={() => {
							setShowCalculateUnsyncedModal(false);
							setUnsyncedEmployees([]);
						}}
						employees={unsyncedEmployees}
						confirming={false}
						onConfirmed={() => {
							const payload = {
								tenantId,
								month: pickedMonth + 1,
								year: pickedYear,
							};
							dispatch(actions.checkQuickbooks(payload));
							toast.info("Kontimi me Quickbooks është duke u procesuar.");
							setShowCalculateUnsyncedModal(false);
							setUnsyncedEmployees([]);
						}}
					/>
					<ModalCustom
						show={openCalculationLanguageSelector}
						onClose={() => {
							setOpenCalculationLanguageSelector(false);
						}}
						onSubmit={() => {
							handleQuickBooks();
						}}
						showSubmitButton
						showRejectButton
						header={
							<div>
								<div>Description Language</div>
							</div>
						}
						closeText={'Cancel'}
						submitText={'Calculate'}>
						<div className={styles.calculationLanguageModal}>
							<h4>Please select the language of the descriptions?</h4>
							<MikaSelect
								selectWrapperClassName={"calculationComponent"}
								onStateChange={(option) => setLanguage(option)}
								options={[
									{ value: 0, label: "Shqip" },
									{ value: 1, label: "English" },
								]}
								value={language}
								setEntireOptionFunction={setLanguage}
								removeSearch
							/>
						</div>
					</ModalCustom>
					<CommonHeader
						title={`Përllogaritja e Pagave për ${monthsList[pickedMonth]} ${pickedYear}`}
						subTitle={
							<HeaderSubtitle
								month={createdMonth}
								year={pickedYear}
								name={creatorName}
							/>
						}
						headerClass={styles.header}
						subTitleClass={styles.subTitle}
						buttonContainerClass={styles.headerButtonsContainer}
					>
						<Button
							onClickHandler={sendWithEmailHandler}
							className={styles.headerBtn}
							viewType="light"
							fixDimensions={true}
						>
							<div className={`${styles.iconContainer} ${styles.emailIcon}`}>
								<FiDownload strokeWidth="2px" />
							</div>
							<div className={`${styles.buttonTitle}`}>Dërgo Me Email</div>
						</Button>
						<Button
							onClickHandler={(event) => {
								handlePaymentSlipButton(event, selectedCheckboxes);
							}}
							className={styles.headerBtn}
							viewType="light"
							loading={state.payslipButtonLoading}
							fixDimensions={true}
						>
							<div className={`${styles.iconContainer}`}>
								<IconDocument stroke="#89c732" width="2px" />
							</div>
							<div className={`${styles.buttonTitle} pl-0`}>Fletëpagesa</div>
						</Button>

						{hasQuickbooks === 1 && (
							<Button
								onClickHandler={() => setOpenCalculationLanguageSelector(true)}
								className={`${styles.headerButton} ${styles.employeesModalButton
									} ${quickbooksLoadingState ? styles?.loading : ""}`}
								viewType="shell"
							>
								<div className={`${styles.iconContainer}`}>
									<QuickBooksIcon stroke="#89c732" width="1px" />
								</div>
								<div className={`${styles.buttonTitle}`}>
									Kontimi me QuickBooks
								</div>
							</Button>
						)}
						<Button
							onClickHandler={(event) => excelExportHandler()}
							className={styles.darkButton}
							loading={state.excelButtonLoading}
							fixDimensions={true}
						>
							<div className={`${styles.iconContainer}`}>
								<FiLogIn />
							</div>
							<div className={`${styles.buttonTitle}`}>Eksporto në Excel</div>
						</Button>
					</CommonHeader>
					<div className={`row ${styles.filterRow}`}>
						{closedPeriod !== 1 && salaries?.length > 0 && (
							<Button
								onClickHandler={handleIsPeriodOpen}
								className={styles.darkButton}
								loading={state.excelButtonLoading}
								fixDimensions={true}
							>
								<div className={`${styles.buttonTitle}`}>Mbyll Periudhën</div>
							</Button>
						)}
						{hasQuickbooks === 1 && (
							<MikaSelect
								selectWrapperClassName={"calculationComponent"}
								onStateChange={(option) => onStateChangeLanguage(option)}
								options={[
									{ value: 0, label: "Shqip" },
									{ value: 1, label: "English" },
								]}
								value={selectedLanguageForQB}
								setEntireOptionFunction={setSelectedLanguageForQB}
								removeSearch
							/>
						)}

						{closedPeriod === 1 && (
							<div className={styles.lockContainer}>
								<FiLock
									data-tip="Hap periudhën përmes kodit."
									onClick={handleIsPeriodOpen}
								/>
								<ReactTooltip
									place="right"
									type="dark"
									effect="solid"
									multiline={true}
									className="customTooltip"
								/>
							</div>
						)}
						<div className={styles.filterDescription}>
							{" "}
							Periudha e llogaritjes{" "}
						</div>
						<div
							className={`${styles.filterButtons} ${backdropParent !== "calculationsPeriodFilter" ? "lowZindex" : ""
								}`}
							onClick={handlePeriodContainerClick}
						>
							<div className={styles.button}>{monthsList[pickedMonth]}</div>
							<div className={`${styles.button} ${styles.yearsButton}`}>
								{pickedYear}
								<div className={styles.calendar}>
									<FiCalendar />
								</div>
							</div>
						</div>
						{showFilterContent && (
							<div className={styles.filterContent} ref={periodWrapperRef}>
								<div className={styles.months} ref={monthsWrapperRef}>
									{monthsList.map((item, index) => {
										return (
											<div
												key={index}
												className={`${styles.periodItem} ${index === pickedMonth ? styles.selected : ""
													}`}
												onClick={() => {
													setPickedMonth((prev) => index);
												}}
											>
												{item}
											</div>
										);
									})}
								</div>
								<div className={styles.years}>
									{yearsList.map((item, index) => {
										return (
											<div
												key={index}
												className={`${styles.periodItem} ${item === pickedYear ? styles.selected : ""
													}`}
												onClick={() => {
													setPickedYear((prev) => +item);
												}}
											>
												{item}
											</div>
										);
									})}
								</div>
							</div>
						)}
					</div>

					<div
						className={`${styles.tableContainer} ${!state?.salaries ? styles.loaderShown : ""
							}`}
					>
						{!state?.salaries ? (
							<Loader />
						) : (
							<Table
								tableHeader={
									<TableHeader
										// totals={state?.totals}
										totals={state.totals ? {
											brutto: state.totals.brutto,
											extraHours: state.totals.extraHours,
											extras: state.totals.extras,
											totalBrutto: state.totals.totalBrutto,
											pensionalContribution: state.totals.pensionalContribution,
											totalTaxableSalary: (state.totals as any).totalTaxableSalary,
											totalTax: (state.totals as any).totalTax,
											totalPrime: (state.totals as any).totalPrime,
											stops: state.totals.stops,
											netto: state.totals.netto
										} : {}}
										salariesRowCount={state.salaries?.length || 0}
										mainCheckboxState={mainCheckboxState}
										setMainCheckboxState={setMainCheckboxState}
										setMainCheckboxClick={setMainCheckboxClick}
									/>
								}
								tableWrapperClass={styles.tableWrapper}
								data={[]}
							>
								<tbody className={styles.tableBody}>
									{state?.salaries?.map((row: Salary, rowIndex) => {
										// console.log(row)
										return (
											<tr key={rowIndex}>
												<td className={`${styles.checkboxCell}`}>
													<Checkbox
														className={styles.checkbox}
														icon={
															<div className={styles.checkboxIconContainer}>
																<FiCheck color="#261f63" size={22} />
															</div>
														}
														checked={selectedCheckboxes?.includes(row.id)}
														onChange={(checked) => {
															setMainCheckboxClick((prev) => false);
															checked
																? setSelectedCheckboxes((prev) => {
																	return [...prev, row.id];
																})
																: setSelectedCheckboxes((prev) => {
																	return selectedCheckboxes.filter(function (
																		item
																	) {
																		return item !== row.id;
																	});
																});
														}}
													/>
												</td>
												<td
													className={`${tableStyles.bodyCell} ${styles.nameCell} pl-0`}
												>
													<div className={`${styles.tableTitle}`}>
														{row.name}
													</div>
													<div className={`${styles.tableSubtitle}`}>
														{" "}
														{row?.employer === 1 ? "Primar" : "Sekondar"}
													</div>
												</td>
												<td className={`${tableStyles.bodyCell} ${styles.f20}`}>
													{row.personalNumber}
												</td>
												<td className={`f-22 ${tableStyles.bodyCell}`}>
													<InputCustom
														className={styles.inputCell}
														type="text"
														value={row?.initialBrutto}
														onClick={(event) => {
															if (row?.salaryPaymentType === 2) {
																bruttoHoursClickHandler({ event, row });
															} else {
																bruttoClickHandler({ event, row });
															}
														}}
													/>
												</td>
												<td className={`f-22 ${tableStyles.bodyCell}`}>
													<InputCustom
														className={styles.inputCell}
														type="number"
														value={row?.overtimeTable?.totalHours}
														readOnly={true}
														onClick={(event) => {
															hoursClickHandler({ event, row });
														}}
													/>
												</td>
												<td className={`f-22 ${tableStyles.bodyCell}`}>
													<InputCustom
														className={styles.inputCell}
														type="number"
														// value={formatNumber_(row.extras, 2)}
														value={row.extras}
														readOnly={true}
														onClick={(event) => {
															additionsClickHandler({ event, row });
														}}
													/>
												</td>
												<td className={`${tableStyles.bodyCell}`}>
													{/* {formatNumber_(row.totalBrutto, 2)} */}
													{row.totalBrutto}
												</td>
												<td className={`${tableStyles.bodyCell}`}>
													{row.pensionalContribution}
												</td>
												<td className={`${tableStyles.bodyCell}`}>
													{/* {formatNumber_(row.taxableSalary, 2)} */}
													{row?.taxableSalary}
												</td>
												<td className={`${tableStyles.bodyCell}`}>
													{/* {formatNumber_(row.tax, 2)} */}
													{row?.tax}
												</td>
												<td className={`${tableStyles.bodyCell}`}>
													{/* {formatNumber_(row.totalBrutto, 2)} */}
													{(row as any).prime}
												</td>
												<td className={`f-22 ${tableStyles.bodyCell}`}>
													<InputCustom
														className={styles.inputCell}
														type="number"
														value={row.stops}
														readOnly={true}
														onClick={(event) => {
															deductionsClickHandler({ event, row });
														}}
													/>
												</td>
												<td className={`${tableStyles.bodyCell}`}>
													{/* {formatNumber_(row.netto, 2)} */}
													{row?.netto}
													{/* <p className={styles.hasHealthInsurance}>
                            *
                          </p> */}
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						)}
						<CalculationsModal
							showModal={showModal}
							modalCloseHandler={modalCloseHandler}
							submitSalaryData={submitSalaryData}
							modalOpener={modalOpener}
							modalContentRef={modalContentRef}
							allModalInputs={allModalInputs}
							selectedCheckboxes={selectedCheckboxes}
							handleModalInputChange={handleModalInputChange}
							pickedMonth={pickedMonth}
							pickedYear={pickedYear}
						/>
					</div>
				</div>

				{isPeriodOpen ? (
					<ModalCustom
						show={isPeriodOpen}
						contentClass={styles.contentClass}
						footerClass={styles.footerClass}
						onClose={() => setIsPeriodOpen(false)}
						onSubmit={(event) => {
							if (closedPeriod !== 1) {
								const formatedData = {
									tenantId: tenantId,
									closed: 1,
									year: pickedYear,
									month: pickedMonth + 1,
									code: code,
								};
								dispatch(closeMonth(formatedData));
								setIsPeriodOpen(false);
								setCode(null);
							} else {
								const formatedData = {
									tenantId: tenantId,
									closed: 0,
									year: pickedYear,
									month: pickedMonth + 1,
									code: code,
								};
								dispatch(openMonth(formatedData));
								setIsPeriodOpen(false);
								setCode(null);
							}
						}}
						submitLoading={state.submitLoading}
						fixDimensions={true}
					>
						{closedPeriod === 1 ? (
							<div className={styles.codeContainer}>
								Shkruani kodin nëse doni të hapni periudhën:
							</div>
						) : (
							<div className={styles.codeContainer}>
								Shkruani kodin nëse doni të mbyllni periudhën:
							</div>
						)}

						<div className={styles.codeWrapper}>
							<div className={styles.titleClass}>Kodi</div>
							<InputCustom
								className={styles.modalInput}
								name="code"
								value={code}
								autoComplete="off"
								onChangeHandler={(event) => companyCodeChangeHandler(event)}
							/>
						</div>
					</ModalCustom>
				) : null}
			</AppWrapper>
			<Payslip showModal={showPayslip} modalCloseHandler={modalCloseHandler} />
		</>
	);
};

export default Calculations;
