import userImageLink from "assets/images/user.png";
import { htmlElementAttributes } from 'html-element-attributes'
import { useRef } from "react";

interface Props {
	imageLink?: string;
	alternativeLink?: string;
	attributes?: typeof htmlElementAttributes;
	noAlternative?: boolean;
	className?: string;
	width?: string | number;
	height?: string | number;
}



const ImageElement: React.FC<Props> = (props: Props) => {
	const ref = useRef(null);
	const onImageError = (ref) => {
		if (ref.current && ref.current?.dataset?.src && !props.noAlternative) {
			ref.current.src = userImageLink;
			ref.current.removeAttribute("data-src");
		}
	}
	let imageLink = props.imageLink;
	if (imageLink === null) {
		imageLink = userImageLink;
	}
	return (
		<img
			src={imageLink}
			ref={ref}
			data-src={true}
			onError={(e: any) => { onImageError(ref) }}
			alt=""
		/>
	);
}

export default ImageElement;