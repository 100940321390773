import { Employee } from "types/employee.type";

import {
  LOAD_EMPLOYEES_ALL,
  LOAD_EMPLOYEES_ALL_ERROR,
  LOAD_EMPLOYEES_ALL_SUCCESS,
  LOAD_EMPLOYEES_INACTIVE,
  LOAD_EMPLOYEES_INACTIVE_ERROR,
  LOAD_EMPLOYEES_INACTIVE_SUCCESS,
  LOAD_EMPLOYEES_ON_PROCESS,
  LOAD_EMPLOYEES_ON_PROCESS_ERROR,
  LOAD_EMPLOYEES_ON_PROCESS_SUCCESS,
  EXPORT_EMPLOYEES,
  EXPORT_EMPLOYEES_SUCCESS,
  EXPORT_EMPLOYEES_ERROR,
  TOGGLE_MODAL_STATE,
  SUBMIT_EMPLOYEE_DATA,
  SUBMIT_EMPLOYEE_DATA_ERROR,
  SUBMIT_EMPLOYEE_DATA_SUCCESS,
  LOAD_EMPLOYEES_TAB_NUMBER,
  LOAD_EMPLOYEES_TAB_NUMBER_SUCCESS,
  LOAD_EMPLOYEES_TAB_NUMBER_ERROR,
  SEARCH_EMPLOYEES,
  SEARCH_EMPLOYEES_SUCCESS,
  SEARCH_EMPLOYEES_ERROR,
  GET_EMPLOYEE_BY_ID,
  GET_EMPLOYEE_BY_ID_SUCCESS,
  GET_EMPLOYEE_BY_ID_ERROR,
  SUBMIT_EMPLOYEE_EDIT,
  SUBMIT_EMPLOYEE_EDIT_SUCCESS,
  SUBMIT_EMPLOYEE_EDIT_ERROR,
  CLEAR_EMPLOYEE_MODAL_DATA,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_ERROR,
  SAVE_DRAFT,
  SET_ACTIVE_TAB,
  UPLOAD_MULTIPLE_FILES,
  UPLOAD_MULTIPLE_FILES_ERROR,
  UPLOAD_MULTIPLE_FILES_SUCCESS,
  DELETE_UPLOADED_ATTACHMENT,
  DELETE_UPLOADED_ATTACHMENT_SUCCESS,
  DELETE_UPLOADED_ATTACHMENT_ERROR,
  REMOVE_ATTACHMENTS,
  SET_ATTACHMENTS,
  SHOW_EMPLOYEE_ATTACHMENTS,
  SET_UNCHANGABLE_BRUTTO,
  SET_UNCHANGABLE_NETTO,
  SET_IS_ADD_EMPLOYEE_ON,
  GET_INITIAL_BRUTTO,
  GET_INITIAL_BRUTTO_SUCCESS,
  GET_INITIAL_BRUTTO_ERROR,
  ADD_EMPLOYEE_QB_SUCCESS,
  ADD_EMPLOYEE_QB,
  ADD_EMPLOYEE_QB_ERROR,
  DELETE_EMPLOYEE_PROJECT,
  DELETE_EMPLOYEE_PROJECT_SUCCESS,
  DELETE_EMPLOYEE_PROJECT_ERROR,
} from "./constants";

export const loadAllEmployees = (tenantId: number) => {
  return {
    type: LOAD_EMPLOYEES_ALL,
    payload: {
      tenantId,
    },
  };
};

export const loadAllEmployeesSuccess = (allEmployees: Employee[]) => {
  return {
    type: LOAD_EMPLOYEES_ALL_SUCCESS,
    payload: {
      allEmployees,
    },
  };
};

export const loadAllEmployeesError = () => {
  return {
    type: LOAD_EMPLOYEES_ALL_ERROR,
    payload: {},
  };
};

export const loadInactiveEmployees = (tenantId: number) => {
  return {
    type: LOAD_EMPLOYEES_INACTIVE,
    payload: {
      tenantId,
    },
  };
};

export const loadInactiveEmployeesSuccess = (inactiveEmployees: Employee[]) => {
  return {
    type: LOAD_EMPLOYEES_INACTIVE_SUCCESS,
    payload: {
      inactiveEmployees,
    },
  };
};

export const loadInactiveEmployeesError = () => {
  return {
    type: LOAD_EMPLOYEES_INACTIVE_ERROR,
    payload: {},
  };
};

export const loadOnProcessEmployees = (tenantId: number) => {
  return {
    type: LOAD_EMPLOYEES_ON_PROCESS,
    payload: {
      tenantId,
    },
  };
};
export const loadOnProcessEmployeesSuccess = (
  onProcessEmployees: Employee[]
) => {
  return {
    type: LOAD_EMPLOYEES_ON_PROCESS_SUCCESS,
    payload: {
      onProcessEmployees,
    },
  };
};

export const loadOnProcessEmployeesError = () => {
  return {
    type: LOAD_EMPLOYEES_ON_PROCESS_ERROR,
    payload: {},
  };
};

export const exportEmployees = (tenantId: number, status: number) => {
  return {
    type: EXPORT_EMPLOYEES,
    payload: {
      tenantId,
      status,
    },
  };
};

export const exportEmployeesSuccess = (payload) => {
  return {
    type: EXPORT_EMPLOYEES_SUCCESS,
    payload: payload,
  };
};

export const exportEmployeesError = () => {
  return {
    type: EXPORT_EMPLOYEES_ERROR,
    payload: {},
  };
};
export const toggleModalState = (payload) => {
  return {
    type: TOGGLE_MODAL_STATE,
    payload: payload,
  };
};

export const submitCreateEmployee = (
  tenantId,
  activeTab,
  payload,
  loadingDraftButton
) => {
  return {
    type: SUBMIT_EMPLOYEE_DATA,
    tenantId,
    activeTab,
    payload: payload,
    loadingDraftButton: loadingDraftButton,
  };
};

export const submitSaveEmployee = (
  tenantId,
  activeTab,
  payload,
  loadingDraftButton
) => {
  return {
    type: SUBMIT_EMPLOYEE_DATA,
    tenantId,
    activeTab,
    payload: payload,
    loadingDraftButton: loadingDraftButton,
  };
};
export const submitCreateEmployeeSuccess = (payload, activeTab) => {
  return {
    type: SUBMIT_EMPLOYEE_DATA_SUCCESS,
    payload: payload,
    activeTab,
  };
};

export const submitSaveEmployeeSuccess = (payload, activeTab) => {
  return {
    type: SUBMIT_EMPLOYEE_DATA_SUCCESS,
    payload: payload,
    activeTab,
  };
};

export const submitSaveEmployeeError = (payload) => {
  return {
    type: SUBMIT_EMPLOYEE_DATA_ERROR,
    payload: payload,
  };
};

export const submitCreateEmployeeError = (payload) => {
  return {
    type: SUBMIT_EMPLOYEE_DATA_ERROR,
    payload: payload,
  };
};

export const submitEditEmployee = (tenantId, activeTab, payload) => {
  return {
    type: SUBMIT_EMPLOYEE_EDIT,
    tenantId,
    activeTab,
    payload: payload,
  };
};
export const submitEditEmployeeSuccess = (payload) => {
  return {
    type: SUBMIT_EMPLOYEE_EDIT_SUCCESS,
    payload: payload,
  };
};
export const submitEditEmployeeError = (payload) => {
  return {
    type: SUBMIT_EMPLOYEE_EDIT_ERROR,
    payload: payload,
  };
};
export const deleteEmployee = (
  tenantId: number,
  employeeId: number,
  activeTab: number,
  callback?: any
) => {
  return {
    type: DELETE_EMPLOYEE,
    tenantId,
    employeeId,
    activeTab,
    callback,
  };
};
export const deleteEmployeeSuccess = (payload, activeTab) => {
  return {
    type: DELETE_EMPLOYEE_SUCCESS,
    payload: payload,
    activeTab,
  };
};
export const deleteEmployeeError = (payload) => {
  return {
    type: DELETE_EMPLOYEE_ERROR,
    payload: payload,
  };
};
export const loadEmployeesTabNumbers = (tenantId: number, tabIndex = 0) => {
  return {
    type: LOAD_EMPLOYEES_TAB_NUMBER,
    payload: {
      tenantId,
      tabIndex,
    },
  };
};
export const loadEmployeesTabNumbersSuccess = (
  status: number,
  count: number
) => {
  return {
    type: LOAD_EMPLOYEES_TAB_NUMBER_SUCCESS,
    payload: {
      status,
      count,
    },
  };
};
export const loadEmployeesTabNumbersError = (error) => {
  return {
    type: LOAD_EMPLOYEES_TAB_NUMBER_ERROR,
    payload: {},
  };
};

export const searchEmployees = (payload) => {
  return {
    type: SEARCH_EMPLOYEES,
    payload,
  };
};
export const searchEmployeesSuccess = (status: number, payload: Employee[]) => {
  return {
    type: SEARCH_EMPLOYEES_SUCCESS,
    payload: {
      status,
      data: payload,
    },
  };
};
export const searchEmployeesError = () => {
  return {
    type: SEARCH_EMPLOYEES_ERROR,
    payload: {},
  };
};
export const getEmployeeById = (tenantId: number, employeeId: number) => {
  return {
    type: GET_EMPLOYEE_BY_ID,
    payload: {
      tenantId,
      employeeId,
    },
  };
};
export const getEmployeeByIdSuccess = (payload: Employee[]) => {
  return {
    type: GET_EMPLOYEE_BY_ID_SUCCESS,
    payload: {
      data: payload,
    },
  };
};
export const getEmployeeByIdError = (payload) => {
  return {
    type: GET_EMPLOYEE_BY_ID_ERROR,
    payload,
  };
};
export const clearEmployeeModalData = () => {
  return {
    type: CLEAR_EMPLOYEE_MODAL_DATA,
    payload: "",
  };
};

export const saveDraftModal = (loading: boolean) => {
  return {
    type: SAVE_DRAFT,
    payload: {
      loading,
    },
  };
};

export const setActiveTab = (index: number, prevTab = 0) => {
  return {
    type: SET_ACTIVE_TAB,
    payload: {
      index,
      prevTab,
    },
  };
};
export const uploadMultipleAttachments = (payload) => {
  return {
    type: UPLOAD_MULTIPLE_FILES,
    payload,
  };
};
export const uploadMultipleAttachmentsSuccess = (payload, file) => {
  return {
    type: UPLOAD_MULTIPLE_FILES_SUCCESS,
    payload: { ...payload, file },
  };
};
export const uploadMultipleAttachmentsError = (payload) => {
  return {
    type: UPLOAD_MULTIPLE_FILES_ERROR,
    payload,
  };
};

export const deleteUploadedAttachment = (
  tenantId,
  attachmentId,
  callback,
  fileErrorCallback
) => {
  return {
    type: DELETE_UPLOADED_ATTACHMENT,
    payload: {
      tenantId,
      attachmentId,
      callback,
      fileErrorCallback,
    },
  };
};
export const deleteUploadedAttachmentSuccess = (payload, attachmentId) => {
  return {
    type: DELETE_UPLOADED_ATTACHMENT_SUCCESS,
    payload: {
      ...payload,
      attachmentId,
    },
  };
};
export const deleteUploadedAttachmentError = (payload) => {
  return {
    type: DELETE_UPLOADED_ATTACHMENT_ERROR,
    payload,
  };
};

export const removeAttachments = () => {
  return {
    type: REMOVE_ATTACHMENTS,
  };
};
export const setAttachments = (payload) => {
  return {
    type: SET_ATTACHMENTS,
    payload,
  };
};
export const showEmployeeAttachments = (payload) => {
  return {
    type: SHOW_EMPLOYEE_ATTACHMENTS,
    payload,
  };
};

export const setUnchangableBrutto = (payload) => {
  return {
    type: SET_UNCHANGABLE_BRUTTO,
    payload,
  };
};

export const setUnchangableNetto = (payload) => {
  return {
    type: SET_UNCHANGABLE_NETTO,
    payload,
  };
};

export const setIsAddEmployeeOn = (payload) => {
  return {
    type: SET_IS_ADD_EMPLOYEE_ON,
    payload,
  };
};

export const getInitialBrutto = (employeeId: number) => {
  return {
    type: GET_INITIAL_BRUTTO,
    payload: {
      employeeId,
    },
  };
};
export const getInitialBruttoSuccess = (payload: Employee[]) => {
  return {
    type: GET_INITIAL_BRUTTO_SUCCESS,
    payload: {
      data: payload,
    },
  };
};
export const getInitialBruttoError = (payload) => {
  return {
    type: GET_INITIAL_BRUTTO_ERROR,
    payload,
  };
};

export const addEmployeeQb = (payload) => {
  return {
    type: ADD_EMPLOYEE_QB,
    payload: {
      tenantId: payload,
    },
  };
};
export const addEmployeeQbSuccess = (payload) => {
  return {
    type: ADD_EMPLOYEE_QB_SUCCESS,
    payload,
  };
};
export const addEmployeeQbError = (payload) => {
  return {
    type: ADD_EMPLOYEE_QB_ERROR,
    payload,
  };
};

export const deleteEmployeeProject = (
  employeeId: number,
  projectId: number
) => {
  return {
    type: DELETE_EMPLOYEE_PROJECT,
    employeeId,
    projectId,
  };
};
export const deleteEmployeeProjectSuccess = (payload) => {
  return {
    type: DELETE_EMPLOYEE_PROJECT_SUCCESS,
    payload: payload,
  };
};
export const deleteEmployeeProjectError = (payload) => {
  return {
    type: DELETE_EMPLOYEE_PROJECT_ERROR,
    payload: payload,
  };
};
