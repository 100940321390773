import axios from "axios";
import https from "https";
import http from "http";
import { store } from "store/store";

export const axiosInstance = () => {
  const httpAgent = new http.Agent({ keepAlive: true });

  const httpsAgent = new https.Agent({ keepAlive: true });
  let initializedAxiosInstance = axios.create({
    baseURL,
    httpAgent,
    httpsAgent,
  });

  let token: string = localStorage.getItem("token");
  token &&
    initializedAxiosInstance &&
    initializedAxiosInstance.interceptors.request.use(
      (config) => {
        config.headers.common["Authorization"] = "Bearer " + token;
        return config;
      },
      (error) => {
        if (error.response.status === 411) {
        }
        console.error(`Got Request Error:\n${error}`);
      }
    );

  initializedAxiosInstance.interceptors.response.use(
    function (response) {
      if (response.status > 200) {
        console.error(`Got HTTP-Status ${response.status}`);
      }
      return response;
    },
    async function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("persist:root");
        localStorage.setItem("singleErrorMessage", "Nuk jeni i kyçur");

        window.location.href = "/login";
      }

      if (error.response.status === 411) {
        const state = store.getState();

        if (state.globalReducer?.tenantId) {
          axios(
            `${process.env.REACT_APP_API_URL_V2}/quickbooks/authorization-code-url?tenantId=${state.globalReducer.tenantId}`,
            {
              method: "GET",
            }
          ).then((res) => {
            window.location.href = res.data.value;
          });
        }
      }
      console.error(`Got Error Response:\n${error}`);
      return Promise.reject(error);
    }
  );

  return initializedAxiosInstance;
};

export const baseURL = `${process.env.REACT_APP_API_URL}/api`;

export const loginInstance = () => {
  const newInstance = axios.create({
    baseURL,
  });

  newInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      console.error(`Got Request Error:\n${error}`);
    }
  );

  newInstance.interceptors.response.use(
    function (response) {
      if (response.status > 200) {
        console.error(`Got HTTP-Status ${response.status}`);
      }

      return response;
    },
    function (error) {
      console.error(`Got Error Response:\n${error}`);
      return Promise.reject(error);
    }
  );

  return newInstance;
};

export const axiosInstanceV2 = () => {
  const httpAgent = new http.Agent({ keepAlive: true });
  const httpsAgent = new https.Agent({ keepAlive: true });

  let initializedAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL_V2,
    httpAgent,
    httpsAgent,
  });

  let token: string = localStorage.getItem("token");
  token &&
    initializedAxiosInstance &&
    initializedAxiosInstance.interceptors.request.use(
      (config) => {
        config.headers.common["Authorization"] = "Bearer " + token;
        return config;
      },
      (error) => {
        if (error.response.status === 411) {
        }
        console.error(`Got Request Error:\n${error}`);
      }
    );

  initializedAxiosInstance.interceptors.response.use(
    function (response) {
      if (response.status > 200) {
        console.error(`Got HTTP-Status ${response.status}`);
      }
      return response;
    },
    async function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("persist:root");
        localStorage.setItem("singleErrorMessage", "Nuk jeni i kyçur");

        window.location.href = "/login";
      }

      if (error.response.status === 411) {
        const state = store.getState();

        if (state.globalReducer?.tenantId) {
          axios(
            `${process.env.REACT_APP_API_URL_V2}/quickbooks/authorization-code-url?tenantId=${state.globalReducer.tenantId}`,
            {
              method: "GET",
            }
          ).then((res) => {
            window.location.href = res.data.value;
          });
        }
      }
      console.error(`Got Error Response:\n${error}`);
      return Promise.reject(error);
    }
  );

  return initializedAxiosInstance;
};
