/* eslint-disable */
import { axiosInstance, axiosInstanceV2 } from "api";
import axios from "axios";

export const createTenant = (payload) => {
  return axiosInstance().post(`register/createTenant`, payload);
};
export const deleteTenant = (payload) => {
  return axiosInstance().post(`/register/deleteTenant`, payload);
};
export const updateTenant = (payload) => {
  return axiosInstance().post(`/register/updateTenant`, payload);
};

export const addAdmin = (payload) => {
  return axiosInstance().post(`register/admin`, payload);
};

export const addUser = (payload) => {
  return axiosInstance().post(`register/user`, payload);
};

export const searchCompanies = (payload) => {
  return axiosInstance().post(`register/all-tenants`, payload);
};

export const getContactInfo = (tenantId) => {
  return axiosInstance().get(
    `configurations/contact-info?tenantId=${tenantId}`
  );
};

export const getAllTenants = (tenantId) => {
  return axiosInstance().get(`register/getTenantByRoleId?tenantId=${tenantId}`);
};

export const getAllUsers = (tenantId) => {
  return axiosInstance().get(`configurations/user-list?tenantId=${tenantId}`);
};

export const deleteCompany = (tenantId) => {
  return axiosInstance().post(`register/deleteTenant?tenantId=${tenantId}`);
};

export const getTaxDegreesInformation = () => {
  return axiosInstance().get(`configurations/taxes`);
};

export const updateTax = (payload) => {
  return axiosInstanceV2().post("/taxes/slabs", {
    saveTaxSlabs: payload,
  });
};

export const getContribute = () => {
  return axiosInstance().get(`configurations/getcontribute`);
};

export const getOneTenant = (tenantId) => {
  return axiosInstance().get(`configurations/showTenant?tenantId=${tenantId}`);
};

export const deleteUser = (userId) => {
  return axiosInstance().post(`register/deleteUser?userId=${userId}`);
};

export const getOneUser = (userId) => {
  return axiosInstance().get(`configurations/userById?userId=${userId}`);
};

export const getTenantAudits = (tenantId) => {
  return axiosInstance().get(`audit/getAuditsByTenantId?tenantId=${tenantId}`);
};

export const getAuthAudits = () => {
  return axiosInstance().get(`audit/getAuditsByAuth`);
};

export const getUserAudits = (userId) => {
  return axiosInstance().get(`audit/getAuditsByUserId?userId=${userId}`);
};

export const uploadLogo = (payload) => {
  return axiosInstance().post(`configurations/upload-logo`, payload);
};

export const getAuthUser = () => {
  return axiosInstance().get("configurations/authUser");
};

export const updateUser = (payload) => {
  return axiosInstance().post(`/register/update-admin`, payload);
};

export const updateContribute = (payload) => {
  return axiosInstance().post(`configurations/updateContribute`, payload);
};

export const getOvertime = (tenantId) => {
  return axiosInstance().get(`configurations/overtime?tenantId=${tenantId}`);
};

export const getLoginCompanies = (roleId, email) => {
  return axiosInstance().get(
    `register/loginTenants?roleId=${roleId}&email=${email}`
  );
};

export const updateOvertime = (payload) => {
  return axiosInstance().post(`configurations/update-overtime`, payload);
};

export const getVacations = (tenantId) => {
  return axiosInstance().get(
    `configurations/holiday-configuration?tenantId=${tenantId}`
  );
};

export const updateVacations = (payload) => {
  return axiosInstance().post(
    `configurations/update-holiday/percentage`,
    payload
  );
};

export const updateHealthInsurance = (tenantId, payload) => {
  return axiosInstance().post(
    `configurations/health-insurance/update`,
    payload
  );
};

export const getHealthInsurance = (tenantId) => {
  return axiosInstance().get(
    `configurations/health-insurance?tenantId=${tenantId}`
  );
};

export const importProjectsFromQuickbooks = (tenantId: number) => {
  return axiosInstanceV2().post("/projects/import-from-quickbooks", {
    userId: tenantId,
  });
};

export const saveProject = (payload) => {
  return axiosInstanceV2().post("/projects/save", payload);
};

export const deleteProject = (projectId, userId) => {
  return axiosInstanceV2().post("/projects/delete", {
    projectId,
    userId,
  });
};

export const getQuickbooksItems = (userId) => {
  return axiosInstanceV2().get(`/quickbooks/items?userId=${userId}`);
};

export const getOneProject = (projectId) => {
  return axiosInstance().get(`projects/project?projectId=${projectId}`);
};

export const sendForgotPasswordEmail = (email) => {
  return axiosInstance().post(`users/forgot-password?email=${email}`);
};

export const resetPassword = (payload) => {
  return axiosInstance().post(`users/reset-password`, payload);
};

export const verifyPassword = (payload) => {
  return axiosInstance().post(`users/verify-reset-password`, payload);
};

export const acceptTerms = (payload) => {
  return axiosInstance().post(`users/updateStatus`, payload);
};

export const getUserByEmail = (email) => {
  return axiosInstance().get(`register/userByEmail?email=${email}`);
};

export const changePassword = (payload) => {
  return axiosInstance().post(`configurations/change-password`, payload);
};

export const getSecurityCode = () => {
  return axiosInstance().get("register/getCode");
};

export const generateCode = (payload) => {
  return axiosInstance().post(`register/generateSecureCode`, payload);
};

export const getTenantSectors = (tenantId) => {
  return axiosInstance().get(
    `register/getSectorByTenantId?tenantId=${tenantId}`
  );
};

export const createSector = (payload) => {
  return axiosInstance().post(`register/createSector`, payload);
};

export const deleteSector = (sectorId) => {
  return axiosInstance().post(`register/deleteSector?sectorId=${sectorId}`);
};

export const updateSector = (payload) => {
  return axiosInstance().post(`register/updateSector`, payload);
};

export const getAccounts = (userId) => {
  return axiosInstanceV2().get(`/quickbooks/accounts?userId=${userId}`);
};

export const getUserList = () => {
  return axiosInstance().get(`configurations/getAllUsers`);
};

export const allTenantUsers = (tenantId) => {
  return axiosInstance().get(
    `configurations/getAllUsersByTenantId?tenantId=${tenantId}`
  );
};

export const getRefreshToken = (payload) => {
  return axiosInstanceV2().post(`/quickbooks/access-token`, payload);
};

export const getAllProjects = (userId, activeProjectsOnly = false) => {
  return axiosInstanceV2().get(
    `/projects?userId=${userId}&activeProjectsOnly=${activeProjectsOnly}`
  );
};

export const checkQuickbooks = (payload) => {
  return axiosInstanceV2().post(
    "/salaries/synchronize-with-quickbooks",
    payload
  );
};

export const getPaymentAccount = (userId) => {
  return axiosInstanceV2().get(`/salaries/payment-account?userId=${userId}`);
};

export const getTenantAccounts = (tenantId) => {
  return axiosInstance().get(
    `configurations/existing-accounts?tenantId=${tenantId}`
  );
};

export const saveAccounts = (payload) => {
  return axiosInstanceV2().post(`/quickbooks/accounts`, payload);
};

export const deleteAccount = (accountId) => {
  return axiosInstance().post(`register/delete-account?accountId=${accountId}`);
};

export const getPayrollAccounts = () => {
  return axiosInstance().get(`accounts/getAccounts`);
};

export const addProjectQb = (tenantId: number) => {
  return axiosInstanceV2().post("/projects/send-unsent-to-quickbooks", {
    userId: tenantId,
  });
};
