import AppWrapper from 'components/AppWrapper/AppWrapper.component';
import styles from './TaxAdministrationReports.module.scss';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import CommonHeader from 'components/partials/CommonHeader/CommonHeader.component';
import { FaChevronLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import ReportTemplateBox from 'components/ReportTemplateBox/reportTemplateBox.component';
import ModalCustom from 'components/ModalCustom/ModalCustom.component';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect.component';
import { activeInactiveOptions, customSelectStyles, downloadTypeOptions } from 'utils/globalVariables';
import { FiCheck, FiDownload } from 'react-icons/fi';
import { IconDocument, IconX } from 'assets/icons/icons';
import ReactToPrint from 'react-to-print';
import { DebounceInput } from 'react-debounce-input';
import Table from 'components/Table/Table.component';
import { format } from 'date-fns';
import Checkbox from 'react-custom-checkbox';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
	downloadContributionsExcel,
	downloadContributionsPdf,
	loadAllContributions,
	sendEmployeesEmail,
	setEmployeesChecked,
} from './actions';
import CustomSelect from 'components/CustomSelect/CustomSelect.component';
import DropdownIndicator from 'components/DropdownIndicator/DropdownIndicator.component';
import { toast } from 'react-toastify';
import LoaderCentered from 'components/partials/LoaderCentered/LoaderCentered.component';
import Button from 'components/Button/Button.component';
import { generateArrayOfYears } from 'utils/functions';
import { globalState } from 'types/globalState.type';
import { monthNamesAL } from 'utils/globalVariables';
import { FiCalendar } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import InputCustom from 'components/InputCustom/InputCustom.component';
import { getMinimalEmployeesByUserId } from 'services/employee.service';
import { MinimalEmployee } from 'types/employee.type';

import * as tableStyles from 'components/Table/Table.module.scss';
import * as calculationStyles from "pages/Calculations/Calculations.module.scss";
import classNames from 'classnames';

let currentYear: number = new Date().getFullYear();
let currentMonth: number = new Date().getMonth();

const monthsList = monthNamesAL;
let yearsList = generateArrayOfYears(21);

const TaxAdministrationReports: FunctionComponent = () => {
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);

	const { t, i18n } = useTranslation('common');

	const history = useHistory();
	const dispatch = useDispatch();

	const tableHeaderData = [t('monthlyStats.month'), t('monthlyStats.brutoSalary'), t('monthlyStats.employeerContribution'), t('monthlyStats.employeeContribution'), t('monthlyStats.taxedSalary'), t('monthlyStats.incomeTax'), t('monthlyStats.netoSalary')];
	const todayDate = format(new Date(), 'dd.MM.yyyy');
	const [isContributionsBookOpen, setIsContributionsBookOpen] = useState<boolean>();
	const [checkedEmployees, setCheckedEmployees] = useState<Array<number>>([])
	const [isAllCheckboxesChecked, setIsAllCheckboxesChecked] = useState<boolean>(false);
	const componentRef = useRef<HTMLDivElement>(null);
	const [showFilterContent, setShowFilterContent] = useState(false);
	const [pickedYear, setPickedYear] = useState(currentYear);

	const contentRef = useRef<HTMLDivElement>(null);

	const backdropParent: string = useSelector((state: globalState) => state.appWrapperReducer.backdropParent);
	const periodWrapperRef = useRef(null);

	const [isEmailOpen, setIsEmailOpen] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [emailValidMessage, setEmailValidMessage] = useState<string>("");
	const isSendEmailLoading = useSelector((state: RootStateOrAny) => state.documentsReducer.sendEmailLoading);
	const [employees, setEmployees] = useState<MinimalEmployee[]>([]);
	const [selectedEmployee, setSelectedEmployee] = useState<any>(null);

	const allContributions = useSelector((state: RootStateOrAny) => state.contributionsReducer.allContributions);
	const totalContributions = useSelector((state: RootStateOrAny) => state.contributionsReducer.totalContributions);

	const numberCellIndexes = [0, 2, 3, 4, 5];


	useEffect(() => {
		if (!isSendEmailLoading) {
			setIsModalOpen(false);
		}
	}, [isSendEmailLoading]);

	useEffect(() => {
		const handleOutsideMenuClick = (e: any) => {
			setShowFilterContent(!showFilterContent);
		}

		if (showFilterContent) window.addEventListener("click", handleOutsideMenuClick);
		return () => window.removeEventListener("click", handleOutsideMenuClick);
	}, [showFilterContent]);

	useEffect(() => {
		if(!tenantId?.toString()?.length) {
			return;
		}

		const loadEmployees = async () => {
			try {
				const response = await getMinimalEmployeesByUserId(tenantId);
				setEmployees(response.data);
			} catch(error) {
				console.error('failed to fetch employees');
			}
		}

		loadEmployees();
	}, [tenantId]);

	useEffect(() => {
		if(!selectedEmployee?.value?.toString()?.length) {
			return;
		}

		if(!pickedYear?.toString()?.length) {
			return;
		}

		if( !tenantId?.toString()?.length) {
			return;
		}

		dispatch(loadAllContributions(tenantId, pickedYear, selectedEmployee?.value));

	}, [selectedEmployee, pickedYear, tenantId, dispatch]);

	const pageStyle = `
		@page {
			margin: 10px 20px;
			font-size: 15px;
		}
		.noPrint {
			display: none !important;
		}
		.footer {
			position: fixed;
			bottom: 10px;
		}
		.modalPrint {
			font-size: 13px !important;
		}
		.print-padding {
			padding: 0 20px;
		}
        .reportDropdown__indicator {
            display:none !important;
        }
        .printHeightFix {
            height: unset !important;
            min-height: unset !important;
            overflow: initial !important;
        }
	`;

	const handleEmailChange = (e) => {
		const emailRegex = /\S+@\S+\.\S+/;
		setEmail(e.target.value);
		if (emailRegex.test(email)) {
			setIsEmailValid(true);
		} else {
			setIsEmailValid(false);
			setEmailValidMessage('Please enter a valid email!');
		}
	};

	const handleBackToReports = () => {
		history.push('/reports');
	};

	const handleGoToEdiTax = () => {
		history.push("/reports/tax-administration/edi");
	};

	const handlePeriodContainerClick = (event) => {
		setShowFilterContent(prev => !prev);
	}

	const handleOpenModal = () => {
		setIsContributionsBookOpen(!isContributionsBookOpen);
		setEmail("")
		setIsEmailOpen(false)
	};

	const handleDownloadPick = (e, item) => {
		if(!selectedEmployee?.value) {
			toast.warning("Ju lutem zgjedhni nje punetore paraprakisht");
			return;
		}
		if (item.value === 1) {
			dispatch(downloadContributionsExcel(tenantId, pickedYear, selectedEmployee?.value));
		} else {
			dispatch(downloadContributionsPdf(tenantId, pickedYear, selectedEmployee?.value));
		}
	};

	const handleEmailClick = (tenantId, checkedEmployees) => {
		dispatch(sendEmployeesEmail(tenantId, checkedEmployees, email)) &&
		setIsEmailOpen(false)
	};

	const handleCheckAllEmployee = (checked) => {
		setIsAllCheckboxesChecked(!isAllCheckboxesChecked);
		dispatch(setEmployeesChecked(checked))
		isAllCheckboxesChecked && setCheckedEmployees([]);
		!isAllCheckboxesChecked && setCheckedEmployees(prevState => {
			return allContributions.map(item => item.employeeId);
		})
	}

	const handleCheckEmployee = (checked, id) => {
		setCheckedEmployees(prev => {
			if (checked) {
				return [
					...prev, id,
				];
			} else {
				return prev.filter(item => item !== id);
			}
		});
	}

	return (
		<AppWrapper appContentClass={styles.reportsComponent}>
			<div className={styles.header}>
				<FaChevronLeft className={styles.arrowIcon} onClick={handleBackToReports} />
				<CommonHeader title={t('reports.atkReports')} />
			</div>
			<div className={styles.reportsInner}>
				<ReportTemplateBox name={t('reports.contributionsBook')} onSubmit={handleOpenModal} />
				<ReportTemplateBox name={t('reports.electronicDeclaration')} onSubmit={handleGoToEdiTax} />
			</div>
			<div>
				<ModalCustom
					show={isContributionsBookOpen}
					contentClass={styles.contentClass}
					footerClass={styles.footerClass}
					ref={contentRef}
					onClose={() => {
						setIsContributionsBookOpen(prev => false);
					}}
					hideFooterButton={true}
				>
					<div className={styles.titleHeader}>
						<div className={styles.point}>
						</div>
						<div className={styles.orderPaymentHeader}>
							{t('reports.contributionsBook')}
						</div>
						<div className={`${styles.downloadText} noPrint`}>
							<div className={styles.dropdownText}>
								<DropdownSelect
									data={downloadTypeOptions}
									buttonText={<div className={`${styles.dropdownText} pr-5`}>
										<div className={styles.downloadIcon}>
											<FiDownload strokeWidth="2px" />
										</div>
										{t('reports.download')}
									</div>}
									showArrow={true}
									noWidth={true}
									menuPosition="right"
									optionClick={(event, item) => {
										handleDownloadPick(event, item);
									}}
								/>
							</div>
						</div>
						<div className={`${styles.printIcon} noPrint`}>
							<IconDocument stroke={'#89C732'} />
						</div>
						<div className={`${styles.downloadText} ${styles.iconText} noPrint`}>
							<ReactToPrint
								trigger={() => <div className={styles.printText}>{t('reports.print')}</div>}
								content={() => contentRef.current}
								pageStyle={pageStyle}
							/>
						</div>
						<div className={styles.headerDate}>
							Data: {todayDate}
						</div>
					</div>
					<div className={`${styles.filterRow} noPrint`}>
					<div className={styles.col}>
							<div className={styles.filterText}>
								{t('mainPage.employee')}
							</div>
							<div className={`${styles.row} relative`}>
								<CustomSelect
									options={employees.map((employee) => ({ value: employee.id, label: employee.name }))}
									className={`${styles.customSelect} ${styles.rowInput} ${styles.bankBoxContainer} p-0 justify-start`}
									onChangeHandler={(value) => {
										setSelectedEmployee(value);
									}}
									selected={selectedEmployee}
									placeholder={t('reports.chooseEmployee')}
									name="status"
									wideMenu={true}
									components={{ DropdownIndicator }}
									{...customSelectStyles}
									menuStyle={{ marginTop: 2, }}
									showIndicatorSeparator={selectedEmployee}
									dropdownIndicatorStyle={{ width: "35px", }}
									controlStyle={{
										...customSelectStyles.controlStyle,
										width: "100%",
										backgroundColor: "unset",
										height: "100%",
										borderRadius: "7px",
									}}
									valueContainerStyle={{
										...customSelectStyles.valueContainerStyle,
										width: "100%",
										paddingLeft: "8px",
										paddingRight: "8px",
										fontSize: "16px",
										fontWeight: "normal",
									}}
									optionsStyle={{
										...customSelectStyles.optionsStyle,
										fontSize: "16px",
										fontWeight: "normal",
									}}
								/>
								{
									selectedEmployee
										?
										<div
											className={styles.removeStatusFilter}
											title={"Hiq këtë filter"}
											onClick={(event) => {
												setSelectedEmployee(null);
											}}
										>
											<IconX />
										</div>
										: ""
								}
							</div>
						</div>
						<div className={`${styles.filterDescription}`}>
							<span className="noPrint noWrap">{t('reports.filterPeriod')}</span>
							<div
								className={`${styles.filterButtons} ${backdropParent !== "calculationsPeriodFilter" ? "lowZindex" : ""}`}
								onClick={handlePeriodContainerClick}
							>
								<div className={`${styles.button} ${styles.yearsButton}`}>
									{pickedYear}
									<div className={styles.calendar}>
										<FiCalendar />
									</div>
								</div>
							</div>
						</div>

						{showFilterContent &&
							<div className={styles.filterContent} ref={periodWrapperRef}>
								<div className={styles.years}>
									{yearsList.map((item, index) => {
										return <div
											key={index}
											className={`${styles.periodItem} ${item === pickedYear ? styles.selected : ""}`}
											onClick={() => { setPickedYear(prev => +item) }}
										>
											{item}
										</div>
									})}
								</div>
							</div>
						}
					</div>

					<div className={styles.tableContainer} ref={componentRef}>
						<Table headerData={tableHeaderData}
						tableHeader={<thead className={`${tableStyles.tableHead} ${calculationStyles.tableHeader}`}>
						<tr>
							<td className={tableStyles.headerCell}>{t('reports.total')}</td>
							<td className={`${styles.totalCell}`}>
								<div>
									{totalContributions?.totalBrutto}
								</div>
							</td>
							<td className={`${styles.totalCell}`}>
								<div>
									{totalContributions?.totalPersonalContribute}
								</div>
							</td>
							<td className={`${styles.totalCell}`}>
								<div>
									{totalContributions?.totalEmployerContribute}
								</div>
							</td>
							<td className={`${styles.totalCell}`}>
								<div>
									{totalContributions?.totalTaxableSalary}
								</div>
							</td>
							<td className={`${styles.totalCell}`}>
								<div>
									{totalContributions?.totalTax}
								</div>
							</td>
							<td className={`${styles.totalCell}`}>
								<div>
									{totalContributions?.totalNetto}
								</div>
							</td>

						</tr>
						<tr className={`${tableStyles.headerRow} ${calculationStyles.tableMainRow} `}
				 >
							{tableHeaderData.map((cell, index) => {
								const firstRowThClassnames = classNames({
									[tableStyles.headerCell]: true,
									[calculationStyles.numberCell]: (numberCellIndexes.includes(index) && index !== 0 && index !== 1),
									[calculationStyles.noWidth]: (index === 0 || index === 1),
									[calculationStyles.allTitle]: index === 0,
								})

								return <th key={index} className={`${firstRowThClassnames}`} >
									{cell}
								</th>
							})}
						</tr>

					</thead>}
							tableClass={styles.tableClass}
							headerClass={styles.headerClass}>
							<tbody className={styles.tableBody}>
							{allContributions?.length > 0 && allContributions.map((data, idx) => {
									return <tr className={styles.tableRow} key={idx}>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											<div>
												{data?.month}
											</div>
										</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.brutto}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.personalContribute}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.employerContribute}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.taxableSalary}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.tax}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.netto}</td>
									</tr>;
								})}
							</tbody>
						</Table>
					</div>
				</ModalCustom>
			</div>


			<ModalCustom
				contentClass={styles.sendEmailWrapper}
				show={isEmailOpen}
				hideFooterButton={false}
				submitLoading={isEmailOpen && isSendEmailLoading || !isEmailOpen}
				submitText={isEmailOpen ? 'Dërgo' : `${t('reports.save')}`}
				footerClass={styles.footerClass}
				onClose={handleOpenModal}
				onSubmit={() => handleEmailClick(tenantId, checkedEmployees)}
			>
			{isEmailOpen ? <div className={styles.emailBox} onKeyDown={(e) => {
				const inputCondition = e.key === 'Enter';
			}}>
				<InputCustom
					onChangeHandler={handleEmailChange}
					className={styles.rowInputEmail}
					name="occupation"
					value={email}
					placeholder={'Enter e-mail'}
					attributes={{ autoFocus: true, }}
					type="text"
				/>
				{!isEmailValid && email !== "" ? <span className={styles.validateEmail}>{emailValidMessage}</span> : null}
			</div> : null}
			</ModalCustom>
		</AppWrapper>
	);
};

export default TaxAdministrationReports;