import { call, put, takeLatest } from "redux-saga/effects";
import { LOG_IN, GET_USER_BY_EMAIL, GET_NOTIFICATIONS } from "./constants";
import * as usersService from "services/users.service";
import {
  logInSuccess,
  logInFailed,
  logInError,
  getNotificationsSuccess,
  getNotificationsError,
  getUserByEmailSuccess,
  getUserByEmailError,
} from "./actions";
import { getNotificationsByTenantId } from "../services/notification.service";
import history from "./history";

export function* logIn(action) {
  try {
    const response = yield call(usersService.logIn, {
      email: action.payload.userName,
      password: action.payload.password,
    });

    if (response?.data?.data?.token) {
      const requiresTwoFactor = yield call(
        usersService.userRequiresTwoFactorAuthentication,
        response?.data?.data?.user?.id
      );
      if (requiresTwoFactor.data.value == true) {
        localStorage.setItem("tempAuth", JSON.stringify(response?.data?.data));
        history.push("/login?twoFactor=true");
        return;
      } else {
        yield put(logInSuccess(response?.data?.data));

        localStorage.setItem("token", response?.data?.data?.token);
        if (
          (response?.data?.data?.user?.roleId === 3 ||
            response?.data?.data?.user?.roleId === 4) &&
          response?.data?.data?.user?.agreed !== null
        ) {
          history.push("/companyLogin");
        } else if (response?.data?.data?.user?.agreed === null) {
          history.push("/licenseAgreement");
        } else {
          history.push("/");
        }
      }
    } else {
      yield put(logInFailed("Login failed"));
    }
  } catch (e) {
    yield put(logInError(e));
  }
}

function* getNotifications(action) {
  try {
    const response = yield call(getNotificationsByTenantId);
    yield put(getNotificationsSuccess(response.data.data));
  } catch (e) {
    yield put(getNotificationsError());
  }
}

export function* getUserByEmail(action) {
  try {
    const response = yield call(
      usersService.getUserByEmail,
      action.payload.email
    );
    yield put(getUserByEmailSuccess(response.data.data));
  } catch (error) {
    yield put(getUserByEmailError(error));
  }
}

export default function* logInSaga() {
  yield takeLatest(GET_NOTIFICATIONS, getNotifications);
  yield takeLatest(LOG_IN, logIn);
  yield takeLatest(GET_USER_BY_EMAIL, getUserByEmail);
}
