import { axiosInstance, axiosInstanceV2 } from "api";
import axios from "axios";
import { Employee, SaveEmployee } from "types/employee.type";

export const getEmployeeById = (employeeId: number) => {
  return axiosInstanceV2().get(`/employees?employeeId=${employeeId}`);
};

export const getMinimalEmployeesByUserId = (userId: number) => {
  return axiosInstanceV2().get(`/employees/active/minimal?userId=${userId}`);
};

export const getInitialBrutto = (employeeId: number) => {
  return axiosInstance().post(
    `/employee/initialBrutto?employeeId=${employeeId}`
  );
};

export const createEmployee = (employeeData: Employee) => {
  return axiosInstance().post(`/employee/createEmployee`, employeeData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const saveEmployee = (employee: SaveEmployee) => {
  return axiosInstanceV2().post(`/employees`, employee);
};

export const updateEmployee = (data: Employee) => {
  return axiosInstance().post(`/employee/updateEmployee`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteEmployee = (tenantId: number, employeeId: number) => {
  return axiosInstance().post(
    `/employee/deleteEmployee?tenantId=${tenantId}&employeeId=${employeeId}`
  );
};

export const getUpcomingBirthdays = (tenantId: number) => {
  return axiosInstance().get(
    `/employee/getUpcomingBirthdays?tenantId=${tenantId}`
  );
};

export const getAllEmployees = (tenantId: number) => {
  return axiosInstance().post(
    `/employee/getEmployeesByTenantIdAndFilter?tenantId=${tenantId}`
  );
};

export const getEmployeesByStatus = (tenantId: number, status: number) => {
  return axiosInstance().post(
    `/employee/getEmployeesByStatus?status=${status}&tenantId=${tenantId}`
  );
};

export const getEmployeesByFilter = (
  tenantId: number,
  filterEmployees: string,
  status: number
) => {
  return axiosInstance().post(
    `/employee/getEmployeesByTenantIdAndFilter?tenantId=${tenantId}&status=${status}&filterEmployees=${filterEmployees}`
  );
};

export const exportEmployees = (tenantId: number, status: number) => {
  return axiosInstance().post(
    `/employee/exportEmployees?tenantId=${tenantId}&status=${status}`
  );
};

export const getEmployeesByStatusCount = (tenantId: number, status: number) => {
  return axiosInstance().post(
    `/employee/getEmployeesByStatusCount?status=${status}&tenantId=${tenantId}`
  );
};

export const uploadMultipleAttachments = (payload) => {
  return axiosInstanceV2().post("/attachments/upload", payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteAttachment = (attachmentId) => {
  return axiosInstanceV2().post(`/attachments/remove`, { attachmentId });
};

export const addEmployeeQb = (tenantId: number) => {
  return axiosInstanceV2().post(`/employees/quickbooks/send`, {
    userId: tenantId,
  });
};

export const downloadImportExcelTemplate = () => {
  return axiosInstanceV2().get(
    "/employees/import-from-excel/template/download",
    {
      responseType: "blob",
    }
  );
};

export const previewImportedExcel = (formData: FormData) => {
  return axiosInstanceV2().post(
    "/employees/import-from-excel/preview",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const deleteEmployeeProject = (
  employeeId: number,
  projectId: number
) => {
  return axiosInstance().post(
    `/employee/deleteEmployeeProject?employeeId=${employeeId}&projectId=${projectId}`
  );
};
