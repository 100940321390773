import {
    COLLAPSE_SIDEBAR_MENU,
    SET_COMPANIES_DATA,
    SET_USER_DATA,
    SET_BACKDROP_STATE,
    FLATTEN_PAGE,
    TOGGLE_MODAL_STATE,
} from "./constants";

export const collapseSidebarMenu = (payload) => ({
    type: COLLAPSE_SIDEBAR_MENU,
    payload,
});

export const loadCompanies = (payload) => ({
    type: SET_COMPANIES_DATA,
    payload,
});

export const loadUserData = (payload) => ({
    type: SET_USER_DATA,
    payload,
});

export const setBackdropState = (payload) => {
    return {
        type: SET_BACKDROP_STATE,
        payload,
    }
};
export const flattenPage = (payload) => {
    return {
        type: FLATTEN_PAGE,
        payload,
    }
};

export const toggleModalState = (payload) => {
    return {
        type: TOGGLE_MODAL_STATE,
        payload: payload
    }
}


