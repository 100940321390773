import { combineReducers } from "redux";

import globalReducer from "App/globalReducer";
import appWrapperReducer from "components/AppWrapper/reducer";
import userSelectReducer from "components/UserSelect/reducer";
import navBarReducer from "components/NavBar/reducer";
import dashboardReducer from "pages/Dashboard/reducer";
import employeesReducer from "pages/Employees/reducer";
import calculationsReducer from "pages/Calculations/reducer";
import vacationReducer from "pages/Holidays/reducer";
import paymentOrderReducer from "pages/Reports/ManagementReports/reducer";
import documentsReducer from "pages/Documents/reducer";
import contributionsReducer from 'pages/Reports/TaxAdministrationReports/reducer';
import annualSalariesReducer from 'components/ReportsTable/reducer';
import monthlyStatisticsReducer from "pages/Reports/ManagementReports/MonthlyStatistics/reducer";
import employeeReportsReducer from "pages/Reports/EmployeeReports/reducer";
import statisticsReportReducer from 'pages/Reports/ManagementReports/Statistics/reducer';
import ediDeclarationReports from 'pages/Reports/TaxAdministrationReports/EdiReports/reducer';
import configurationsReducer from 'pages/Configurations/reducer';
import loginCompaniesReducer from 'pages/Login/CompanyLogin/reducer';
import notificationReducer from '../pages/NotificationsPage/reducer';
import globalSearchReducer from "components/GlobalSearch/reducer";

export default combineReducers({
	globalReducer,
	appWrapperReducer,
	userSelectReducer,
	navBarReducer,
	dashboardReducer,
	employeesReducer,
	calculationsReducer,
	vacationReducer,
	paymentOrderReducer,
	documentsReducer,
	contributionsReducer,
	annualSalariesReducer,
	monthlyStatisticsReducer,
	employeeReportsReducer,
	statisticsReportReducer,
	ediDeclarationReports,
	configurationsReducer,
	notificationReducer,
	loginCompaniesReducer,
	globalSearchReducer
});

