import { DOWNLOAD_SALARY_LIST_EXCEL } from "./constants";
import { call, put, takeLatest } from "redux-saga/effects";
import * as reportService from "services/report.service";
import {
  downloadSalaryListExcelError,
  downloadSalaryListExcelSuccess,
} from "./actions";

export function* downloadSalaryListExcel(action) {
  try {
    const response = yield call(
      reportService.downloadSalaryListExcel,
      action.payload.tenantId,
      action.payload.year,
      action.payload.month
    );
    yield put(downloadSalaryListExcelSuccess(response));
  } catch (e) {
    yield put(downloadSalaryListExcelError(e));
  }
}

export default function* contributionsSaga() {
  yield takeLatest(DOWNLOAD_SALARY_LIST_EXCEL, downloadSalaryListExcel);
}
