import React from "react";

const PeriodDataNotFound = () => {
  return (
    <span style={{ padding: 0, margin: 0, fontSize: "10.5px" }}>
      Nuk është gjetur asnjë punëtor me kontratë aktive për këtë periudhë!
      {' '}<span style={{ fontSize: "10px" }}>
        Ju lutem kontrolloni kontratat e punëtorëve nëse dëshironi të kalkuloni
        pagat per periudhën e zgjedhur.
      </span>
    </span>
  );
};

export default PeriodDataNotFound;
