import {
  GET_PAGINATED_NOTIFICATIONS,
  GET_PAGINATED_NOTIFICATIONS_ERROR,
  GET_PAGINATED_NOTIFICATIONS_SUCCESS,
  READ_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS_ERROR,
  READ_ALL_NOTIFICATIONS_SUCCESS,
  READ_SELECTED_NOTIFICATION,
  READ_SELECTED_NOTIFICATION_ERROR,
  READ_SELECTED_NOTIFICATION_SUCCESS,
} from './constants';
import {Notification} from '../../types/notification.type';

export const getPaginatedNotifications = (page: number, canSeeRead: boolean) => {
  return {
    type: GET_PAGINATED_NOTIFICATIONS,
    payload: {
      page,
      canSeeRead
    }
  };
};

export const getPaginatedNotificationsSuccess = (notifications: Notification[], total: number, canSeeRead: boolean) => {
  return {
    type: GET_PAGINATED_NOTIFICATIONS_SUCCESS,
    payload: {
      notifications,
      total,
      canSeeRead
    }
  };
};

export const getPaginatedNotificationsError = () => {
  return {
    type: GET_PAGINATED_NOTIFICATIONS_ERROR,
  };
};

export const readSelectedNotification = (notificationId: string) => {
  return {
    type: READ_SELECTED_NOTIFICATION,
    payload: {
      notificationId
    }
  }
}

export const readSelectedNotificationSuccess = (notificationId: string) => {
  return {
    type: READ_SELECTED_NOTIFICATION_SUCCESS,
    payload: {
      notificationId
    }
  }
}

export const readSelectedNotificationError = () => {
  return {
    type: READ_SELECTED_NOTIFICATION_ERROR,
  }
}

export const readAllNotifications = () => {
  return {
    type: READ_ALL_NOTIFICATIONS,
  }
}

export const readAllNotificationsSuccess = () => {
  return {
    type: READ_ALL_NOTIFICATIONS_SUCCESS,
  }
}

export const readAllNotificationsError = () => {
  return {
    type: READ_ALL_NOTIFICATIONS_ERROR,
  }
}
