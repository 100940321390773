import { IconDocument } from "assets/icons/icons";
import ModalCustom from "components/ModalCustom/ModalCustom.component";

import pageStyles from "pages/Calculations/Calculations.module.scss";
import styles from "pages/Calculations/Payslip/Payslip.module.scss";
import { useSelector } from "react-redux";
import { globalState } from "types/globalState.type";
import PayslipData from "types/payslip.type";
import { SalariesState } from "types/reducers/salary.reducer.type";
import { format, getMonth, getYear } from "date-fns";
import { monthNamesAL } from "utils/globalVariables";
import { formatNumber_ } from "utils/functions";

import { useReactToPrint } from "react-to-print";
import { useEffect, useRef } from "react";

const todayDate = format(new Date(), "dd.MM.yyyy");

interface Props {
	showModal?: any;
	modalCloseHandler?: any;
}

const Payslip: React.FC<Props> = ({ ...props }) => {
	const state: SalariesState = useSelector(
		(state: globalState) => state.calculationsReducer
	);
	const data: PayslipData = state.payslipData;

	const { showModal, modalCloseHandler } = props;

	const contentRef = useRef<HTMLDivElement>(null);

	const pageStyle = `
		@page {
			margin: 10px 20px;
			font-size: 15px;
		}
		.noPrint {
			display: none !important;
		}
		.footer {
			position: fixed;
			bottom: 10px;
		}
		.modalPrint {
			font-size: 13px !important;
		}
		.print-padding {
			padding: 0 20px;
		}
		`;
	const handlePrint = useReactToPrint({
		content: () => contentRef.current,
		pageStyle: () => pageStyle,
	});

	useEffect(() => { }, [data]);

	let arrayOfLines = [];
	let arrayOfLineNames = [];
	return (
		<ModalCustom
			show={showModal}
			contentClass={styles.payslip}
			footerClass={pageStyles.footerClass}
			onClose={modalCloseHandler}
			hideFooterButton={true}
			closeButtonClass={styles.closeButtonClass}
			modalMainStyle={styles.modalMainStyle}
			modalPrintClass={styles.modalPrintClass}
			ref={contentRef}
			header={
				<div
					className={`${pageStyles.modalHeader} ${styles.payslipModalHeader}`}
				>
					<div className={`${pageStyles.title} ${styles.modalTitle}`}>
						{" "}
						Fletëpagesa{" "}
					</div>
					<div className={`noPrint ${styles.subTitle}`} onClick={handlePrint}>
						<div className={`${pageStyles.icon} ${styles.icon}`}>
							<IconDocument stroke="#89C732" />
						</div>
						<div>Printo Fletëpagesën</div>
					</div>
				</div>
			}
		>
			<div className={`${styles.row} ${styles.title}`}>
				{data?.employee?.fullName}
			</div>
			<div className={`${styles.row} ${styles.section} ${styles.track}`}>
				<div className={styles.cell}>
					<div className={styles.cellTitle}>ID:</div>
					<div className={styles.cellValue}>
						{data?.employee?.personalNumber}
					</div>
				</div>
				<div className={styles.cell}>
					<div className={styles.cellTitle}>Muaji:</div>
					<div className={styles.cellValue}>
						{data?.month ? data?.month + `/` + data?.year : ""}
					</div>
				</div>
				<div className={styles.cell}>
					<div className={styles.cellTitle}>Punëdhënësi:</div>
					<div className={styles.cellValue}>
						{+data?.employee?.employer === 1 ? "Primar" : "Sekondar"}
					</div>
				</div>
				<div className={styles.cell}>
					<div className={styles.cellTitle}>Paga Bazë Bruto:</div>
					{/* {data?.paymentType === 3 ?  */}
					<div className={styles.cellValue}>{data?.initialBrutto}</div>
					{/* : 
          data?.employee?.hasInsurance === 1 ? 
          <div className={styles.cellValue}>{data?.unchangableBrutto}</div> 
          : 
          <div className={styles.cellValue}>{data?.brutto}</div>
            // ({data?.employee?.hasInsurance === 1 ? 
            //   <div className={styles.cellValue}>{data?.unchangableBrutto}</div>
            //   :
            //   <div className={styles.cellValue}>{data?.unchangableBrutto}</div>

            // }

            // ) */}
					{/* } */}
				</div>
			</div>

			<div
				className={`${styles.row} ${styles.section} pb-0 mb-1 print-padding`}
			>
				{data?.paymentType !== 3 && (
					<>
						<div className={`${styles.col}`}>
							<div className={styles.boxesTitle}>Përqindja e rritjes</div>
							<div className={styles.box}></div>
							<div className={styles.box}>
								{(data?.percentage?.night_hour_price || 0) * 100}%
							</div>
							<div className={styles.box}>
								{(data?.percentage?.weekend_hour_price || 0) * 100}%
							</div>
							<div className={styles.box}>
								{(data?.percentage?.after_work_hour_price || 0) * 100}%
							</div>
							<div className={styles.box}>
								{(data?.percentage?.holidays_hour_price || 0) * 100}%
							</div>
							<div className={styles.box}>
								{(data?.percentage?.guard_hour_price || 0) * 100}%
							</div>
						</div>
						<div className={styles.colsWrapper}>
							<div className={`${styles.col} ${styles.solid}`}>
								<div className={styles.boxesTitle}> Orë/Ditë Pune </div>
								<div className={styles.box}> Të rregullta </div>
								<div className={styles.box}> Natën </div>
								<div className={styles.box}> Fundjavën </div>
								<div className={styles.box}> Jashtë Orarit </div>
								<div className={styles.box}> Festat </div>
								<div className={styles.box}> Kujdestaria </div>
							</div>
							<div
								className={`${styles.col} ${styles.solid} ${styles.secondCol} pr-0`}
							>
								<div className={styles.box}> {data?.workingHoursPerMonth} </div>
								<div className={styles.box}> {data?.hours?.nightHours} </div>
								<div className={styles.box}> {data?.hours?.weekendHours} </div>
								<div className={styles.box}>
									{" "}
									{data?.hours?.afterWorkHours}{" "}
								</div>
								<div className={styles.box}> {data?.hours?.holidaysHours} </div>
								<div className={styles.box}> {data?.hours?.guardHours} </div>
							</div>
						</div>
						<div
							className={`${styles.col} ${styles.solid} ${styles.secondCol} ${styles.flatCol}`}
						>
							<div className={`${styles.box} ${styles.clean}`}> x </div>
							<div className={`${styles.box} ${styles.clean}`}> x </div>
							<div className={`${styles.box} ${styles.clean}`}> x </div>
							<div className={`${styles.box} ${styles.clean}`}> x </div>
							<div className={`${styles.box} ${styles.clean}`}> x </div>
							<div className={`${styles.box} ${styles.clean}`}> x </div>
						</div>
						<div className={`${styles.col} ${styles.solid} ${styles.midCol}`}>
							<div className={styles.boxesTitle}> Ora </div>
							<div className={`${styles.box} ${styles.midBox}`}>
								{" "}
								{formatNumber_(data?.pricePerHour || 0, 2)}{" "}
							</div>
							<div className={`${styles.box} ${styles.midBox}`}>
								{" "}
								{formatNumber_(data?.nightPricePerHour || 0, 2)}{" "}
							</div>
							<div className={`${styles.box} ${styles.midBox}`}>
								{" "}
								{formatNumber_(data?.weekendPricePerHour || 0, 2)}{" "}
							</div>
							<div className={`${styles.box} ${styles.midBox}`}>
								{" "}
								{formatNumber_(data?.afterWorkPricePerHour || 0, 2)}{" "}
							</div>
							<div className={`${styles.box} ${styles.midBox}`}>
								{" "}
								{formatNumber_(data?.holidayPricePerHour || 0, 2)}{" "}
							</div>
							<div className={`${styles.box} ${styles.midBox}`}>
								{" "}
								{formatNumber_(data?.guardPricePerHour || 0, 2)}{" "}
							</div>
						</div>
						<div
							className={`${styles.col} ${styles.solid} ${styles.secondCol} ${styles.flatCol}`}
						>
							<div className={`${styles.box} ${styles.clean}`}> = </div>
							<div className={`${styles.box} ${styles.clean}`}> = </div>
							<div className={`${styles.box} ${styles.clean}`}> = </div>
							<div className={`${styles.box} ${styles.clean}`}> = </div>
							<div className={`${styles.box} ${styles.clean}`}> = </div>
							<div className={`${styles.box} ${styles.clean}`}> = </div>
						</div>
						<div
							className={`${styles.col} ${styles.solid} ${styles.secondCol} ${styles.wideCol}`}
						>
							<div className={`${styles.box} ${styles.wideBox}`}>
								{" "}
								{data?.brutto}{" "}
							</div>
							<div className={`${styles.box} ${styles.wideBox}`}>
								{" "}
								{data?.overtimeCalculated?.totalNightHours}{" "}
							</div>
							<div className={`${styles.box} ${styles.wideBox}`}>
								{" "}
								{data?.overtimeCalculated?.totalWeekendHours}{" "}
							</div>
							<div className={`${styles.box} ${styles.wideBox}`}>
								{" "}
								{data?.overtimeCalculated?.totalAfterWorkHours}{" "}
							</div>
							<div className={`${styles.box} ${styles.wideBox}`}>
								{" "}
								{data?.overtimeCalculated?.totalHolidayHours}{" "}
							</div>
							<div className={`${styles.box} ${styles.wideBox}`}>
								{" "}
								{data?.overtimeCalculated?.totalGuardHours}{" "}
							</div>
						</div>
					</>
				)}

				{data?.paymentType === 3 && (
					<>
						<div className={`${styles.colProject}`}>
							<div className={styles.boxesTitle}>Projekti</div>
							{data?.projects &&
								data?.projects?.map((line) => {
									console.log(line);
									return <div className={styles.projectBox}>{line?.name}</div>;
								})}
						</div>

						<div className={styles.colsWrapperProject}>
							<div className={`${styles.colProject} ${styles.solid}`}>
								<div className={styles.boxesTitle}> Vija Buxhetore</div>
								{Object.keys(data?.lines).map((item) => {
									data?.lines[item]?.map((line) => {
										arrayOfLineNames.push(line);
									});
								})}
								{arrayOfLineNames?.map((name, index) => (
									<>
										<div key={index} className={styles.projectBox}>
											{name?.name}
										</div>
									</>
								))}
							</div>
						</div>
						<div
							className={`${styles.col} ${styles.solid} ${styles.secondCol} ${styles.flatCol}`}
						>
							{arrayOfLineNames?.map((name) => (
								<div className={`${styles.box} ${styles.clean}`}> = </div>
							))}
						</div>
						<div
							className={`${styles.col} ${styles.solid} ${styles.secondCol} ${styles.wideCol}`}
						>
							{arrayOfLineNames?.map((name) => (
								<div className={`${styles.box} ${styles.wideBox}`}>
									{" "}
									{name?.budget}
								</div>
							))}
						</div>
					</>
				)}
			</div>
			<div
				className={`${styles.row} ${styles.section} ${styles.flexEnd} ${styles.alignStart} pb-0 mb-1 w-fit-content`}
			>
				<div className={`${styles.col} ${styles.solid} ${styles.secondCol}`}>
					<div className={`${styles.row} ${styles.totalCell} justify-between`}>
						{data?.paymentType !== 3 && (
							<div
								className={`${styles.box} ${styles.wideBox} ${styles.row} justify-between`}
							>
								<div> TOTALI: </div>
								{+data?.workingHoursPerMonth +
									+data?.hours?.nightHours +
									+data?.hours?.weekendHours +
									+data?.hours?.afterWorkHours +
									+data?.hours?.holidaysHours +
									+data?.hours?.guardHours || 0}
							</div>
						)}
					</div>
					{data?.paymentType === 3 && (
						<div className={`${styles.box} ${styles.hideBox}`}> - </div>
					)}

					<div className={`${styles.box} ${styles.hideBox}`}> - </div>
					{data?.employee?.hasInsurance === 1 && (
						<div className={`${styles.box} ${styles.hideBox}`}> - </div>
					)}
					<div className={`${styles.box} ${styles.clean} justify-end`}>
						{" "}
						Kontributi i punëtorit{" "}
					</div>
					<div className={`${styles.box} ${styles.clean} justify-end`}>
						{" "}
						Kontributi i punëdhënësit{" "}
					</div>
				</div>
				<div
					className={`${styles.col} ${styles.solid} ${styles.secondCol} ${styles.midCol}`}
				>
					<div
						className={`${styles.box} ${styles.midBox} ${styles.clean} justify-end`}
					>
						{" "}
						Shtesat
					</div>
					{data?.employee?.hasInsurance === 1 && (
						<div
							className={`${styles.box} ${styles.midBox} ${styles.clean} justify-end`}
						>
							{" "}
							Sigurimi Shëndetësor
						</div>
					)}
					<div
						className={`${styles.box} ${styles.midBox} ${styles.clean} justify-end`}
					>
						{" "}
						Paga bruto total{" "}
					</div>
					<div className={`${styles.box} ${styles.midBox}`}>
						{(data?.taxes?.personalContribute || 0) * 100}%{" "}
					</div>
					<div className={`${styles.box} ${styles.midBox}`}>
						{" "}
						{(data?.taxes?.employerContribute || 0) * 100}%{" "}
					</div>
				</div>
				<div className={`${styles.col} ${styles.flatCol} ${styles.secondCol}`}>
					<div className={`${styles.box} ${styles.clean}`}> = </div>
					{data?.employee?.hasInsurance === 1 && (
						<div className={`${styles.box} ${styles.clean}`}> = </div>
					)}
					<div className={`${styles.box} ${styles.clean}`}> = </div>
					<div className={`${styles.box} ${styles.clean}`}> = </div>
					<div className={`${styles.box} ${styles.clean}`}> = </div>
				</div>
				<div
					className={`${styles.col} ${styles.solid} ${styles.secondCol} ${styles.wideCol}`}
				>
					<div className={`${styles.box} ${styles.wideBox}`}>
						{" "}
						{data?.extras}{" "}
					</div>
					{data?.employee?.hasInsurance === 1 && (
						<div className={`${styles.box} ${styles.wideBox}`}>
							{" "}
							{data?.salaryWithHealthInsurancePercentage}{" "}
						</div>
					)}
					<div className={`${styles.box} ${styles.wideBox}`}>
						{" "}
						{data?.totalBrutto}{" "}
					</div>
					<div className={`${styles.box} ${styles.wideBox}`}>
						{" "}
						{data?.taxValues?.personalContribute}{" "}
					</div>
					<div className={`${styles.box} ${styles.wideBox}`}>
						{" "}
						{data?.taxValues?.employerContribute}{" "}
					</div>
				</div>

			</div>
			<div
				className={`${styles.row} ${styles.section} ${styles.flexEnd} ${styles.alignStart}`}
			>
				<div className={`${styles.col} ${styles.solid} ${styles.secondCol}`}>
					<div className={`${styles.box} ${styles.clean} h-end`}> Tatimi </div>
					<div className={`${styles.box} ${styles.clean} h-end`}>Paga neto</div>
					{data?.employee?.hasInsurance === 1 && (
						<div className={`${styles.box} ${styles.clean} h-end`}>
							Ndalesa e Sigurimit Shëndetësor
						</div>
					)}
					{data?.employee?.hasInsurance === 1 && (
						<div className={`${styles.box} ${styles.clean} h-end`}>
							Ndalesa e Sigurimit Shëndetësor për Familjarë
						</div>
					)}
					<div
						className={`${styles.box} ${styles.midBox} ${styles.clean} justify-end`}
					>
						{" "}
						Ndalesat{" "}
					</div>
					<div className={`${styles.box} ${styles.clean} h-end`}>
						Neto Për pagesë
					</div>
				</div>
				<div className={`${styles.col} ${styles.flatCol} ${styles.secondCol}`}>
					<div className={`${styles.box} ${styles.clean}`}> = </div>
					<div className={`${styles.box} ${styles.clean}`}> = </div>
					<div className={`${styles.box} ${styles.clean}`}> = </div>
					{data?.employee?.hasInsurance === 1 && (
						<div className={`${styles.box} ${styles.clean}`}> = </div>
					)}
					{data?.employee?.hasInsurance === 1 && (
						<div className={`${styles.box} ${styles.clean}`}> = </div>
					)}
					<div className={`${styles.box} ${styles.clean}`}> = </div>
				</div>
				<div
					className={`${styles.col} ${styles.solid} ${styles.secondCol} ${styles.midCol}`}
				>
					<div className={`${styles.box} ${styles.wideBox}`}> {data?.tax} </div>
					<div className={`${styles.box} ${styles.wideBox}`}>{formatNumber_((+data?.totalBrutto) - (+data?.taxValues?.employerContribute) - (+data?.tax), 2)}</div>
					{data?.employee?.hasInsurance === 1 && (
						<div className={`${styles.box} ${styles.wideBox}`}>
							{data?.prime}
						</div>
					)}
					{data?.employee?.hasInsurance === 1 && (
						<div className={`${styles.box} ${styles.wideBox}`}>
							{data?.familyExpenses}
						</div>
					)}
					<div className={`${styles.box} ${styles.wideBox}`}>
						{" "}
						{data?.stops}{" "}
					</div>
					<div className={`${styles.box} ${styles.wideBox}`}>{data?.netto}</div>
				</div>
			</div>
			<div className={`footer ${styles.row} ${styles.bottomSection} h-center`}>
				Data e gjenerimit {todayDate}
			</div>
		</ModalCustom>
	);
};

export default Payslip;
