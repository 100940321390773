const scope = 'mpayroll/notifications';


export const GET_PAGINATED_NOTIFICATIONS = `${scope}/GET_PAGINATED_NOTIFICATIONS`;
export const GET_PAGINATED_NOTIFICATIONS_SUCCESS = `${scope}/GET_PAGINATED_NOTIFICATIONS_SUCCESS`;
export const GET_PAGINATED_NOTIFICATIONS_ERROR = `${scope}/GET_PAGINATED_NOTIFICATIONS_ERROR`;


export const READ_SELECTED_NOTIFICATION = `${scope}/READ_SELECTED_NOTIFICATION`;
export const READ_SELECTED_NOTIFICATION_SUCCESS = `${scope}/READ_SELECTED_NOTIFICATION_SUCCESS`;
export const READ_SELECTED_NOTIFICATION_ERROR = `${scope}/READ_SELECTED_NOTIFICATION_ERROR`;

export const READ_ALL_NOTIFICATIONS = `${scope}/READ_ALL_NOTIFICATIONS`;
export const READ_ALL_NOTIFICATIONS_SUCCESS = `${scope}/READ_ALL_NOTIFICATIONS_SUCCESS`;
export const READ_ALL_NOTIFICATIONS_ERROR = `${scope}/READ_ALL_NOTIFICATIONS_ERROR`;
