import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import * as employeeService from "services/employee.service";
import {
  ADD_EMPLOYEE_QB,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_PROJECT,
  DELETE_UPLOADED_ATTACHMENT,
  EXPORT_EMPLOYEES,
  GET_EMPLOYEE_BY_ID,
  GET_INITIAL_BRUTTO,
  LOAD_EMPLOYEES_ALL,
  LOAD_EMPLOYEES_INACTIVE,
  LOAD_EMPLOYEES_ON_PROCESS,
  LOAD_EMPLOYEES_TAB_NUMBER,
  SEARCH_EMPLOYEES,
  SUBMIT_EMPLOYEE_DATA,
  SUBMIT_EMPLOYEE_EDIT,
  UPLOAD_MULTIPLE_FILES,
} from "./constants";
import * as employeeActions from "./actions";
import { toggleModalState } from "components/AppWrapper/actions";

export function* loadAllEmployees(action) {
  try {
    const response = yield call(
      employeeService.getAllEmployees,
      action.payload.tenantId
    );
    yield put(employeeActions.loadAllEmployeesSuccess(response.data.data));
  } catch (e) {
    yield put(employeeActions.loadAllEmployeesError());
  }
}

export function* loadInactiveEmployees(action) {
  try {
    const response = yield call(
      employeeService.getEmployeesByStatus,
      action.payload.tenantId,
      2
    );
    yield put(employeeActions.loadInactiveEmployeesSuccess(response.data.data));
  } catch (e) {
    yield put(employeeActions.loadInactiveEmployeesError());
  }
}

export function* loadOnProcessEmployees(action) {
  try {
    const response = yield call(
      employeeService.getEmployeesByStatus,
      action.payload.tenantId,
      3
    );
    yield put(
      employeeActions.loadOnProcessEmployeesSuccess(response.data.data)
    );
  } catch (e) {
    yield put(employeeActions.loadOnProcessEmployeesError());
  }
}

export function* exportEmployees(action) {
  try {
    const response = yield call(
      employeeService.exportEmployees,
      action.payload.tenantId,
      action.payload.status
    );
    yield put(employeeActions.exportEmployeesSuccess(response));
  } catch (e) {
    yield put(employeeActions.exportEmployeesError());
  }
}

export function* loadEmployeesTabNumbers(action) {
  try {
    const { tabIndex, tenantId } = action.payload;
    if (tabIndex === 0) {
      const activeCount = yield call(
        employeeService.getEmployeesByStatusCount,
        tenantId,
        1
      );
      yield put(
        employeeActions.loadEmployeesTabNumbersSuccess(1, activeCount.data)
      );
      const inactiveCount = yield call(
        employeeService.getEmployeesByStatusCount,
        tenantId,
        2
      );
      yield put(
        employeeActions.loadEmployeesTabNumbersSuccess(2, inactiveCount.data)
      );
      const onProcessCount = yield call(
        employeeService.getEmployeesByStatusCount,
        tenantId,
        3
      );
      yield put(
        employeeActions.loadEmployeesTabNumbersSuccess(3, onProcessCount.data)
      );
    } else {
      const count = yield call(
        employeeService.getEmployeesByStatusCount,
        tenantId,
        tabIndex
      );
      yield put(
        employeeActions.loadEmployeesTabNumbersSuccess(tabIndex, count.data)
      );
    }
  } catch (e) {
    yield put(employeeActions.loadEmployeesTabNumbersError(e));
  }
}

function* searchEmployees(action) {
  try {
    const response = yield call(
      employeeService.getEmployeesByFilter,
      action.payload.tenantId,
      action.payload.filterEmployees,
      action.payload.status > 1 ? action.payload.status : ""
    );
    yield put(
      employeeActions.searchEmployeesSuccess(
        action.payload.status,
        response.data.data
      )
    );
  } catch (e) {
    yield put(employeeActions.searchEmployeesError());
  }
}

function* getEmployeeById(action) {
  try {
    const response = yield call(
      employeeService.getEmployeeById,
      action.payload.employeeId
    );
    yield put(employeeActions.getEmployeeByIdSuccess(response?.data));
  } catch (e) {
    yield put(employeeActions.getEmployeeByIdError(e));
  }
}

function* getInitialBrutto(action) {
  try {
    const response = yield call(
      employeeService.getInitialBrutto,
      action.payload.employeeId
    );
    console.log(response);
    yield put(employeeActions.getInitialBruttoSuccess(response?.data?.data));
  } catch (e) {
    yield put(employeeActions.getInitialBruttoError(e));
  }
}

function* createEmployee(action) {
  try {
    const response = yield call(employeeService.createEmployee, action.payload);
    action.activeTab === 3
      ? yield put(employeeActions.setActiveTab(3, action.activeTab))
      : yield put(employeeActions.setActiveTab(0, action.activeTab));

    yield put(
      employeeActions.submitCreateEmployeeSuccess(response, action.activeTab)
    );
  } catch (e) {
    yield put(employeeActions.submitCreateEmployeeError(e));
  }
}

function* saveEmployee(action) {
  try {
    const response = yield call(employeeService.saveEmployee, action.payload);
    action.activeTab === 3
      ? yield put(employeeActions.setActiveTab(3, action.activeTab))
      : yield put(employeeActions.setActiveTab(0, action.activeTab));

    if (response.data?.errorMessage?.length > 0) {
      yield put(
        employeeActions.submitSaveEmployeeError(response.data?.errorMessage)
      );
      return;
    }
    yield put(
      employeeActions.submitSaveEmployeeSuccess(response, action.activeTab)
    );
  } catch (e) {
    yield put(employeeActions.submitSaveEmployeeError(e));
  }
}

function* editEmployee(action) {
  try {
    const response = yield call(employeeService.updateEmployee, action.payload);
    yield put(employeeActions.setActiveTab(0));
    yield put(employeeActions.submitEditEmployeeSuccess(response?.data));
    yield put(toggleModalState(false));
  } catch (e) {
    yield put(employeeActions.submitEditEmployeeError(e));
  }
}

function* deleteEmployee(action) {
  try {
    const response = yield call(
      employeeService.deleteEmployee,
      action.tenantId,
      action.employeeId
    );
    yield put(employeeActions.setActiveTab(0));
    yield put(employeeActions.deleteEmployeeSuccess(response, 2));
    yield put(toggleModalState(false));
    if (action.callback) {
      yield action.callback();
    }
  } catch (e) {
    yield put(employeeActions.deleteEmployeeError(e));
  }
}
function* uploadMultipleAttachments(action) {
  try {
    const response = yield call(
      employeeService.uploadMultipleAttachments,
      action.payload
    );
    yield put(
      employeeActions.uploadMultipleAttachmentsSuccess(
        response,
        action.payload.get("file")
      )
    );
  } catch (e) {
    yield put(employeeActions.uploadMultipleAttachmentsError(e));
  }
}

function* deleteEmployeeAttachment(action) {
  try {
    yield call(employeeService.deleteAttachment, action.payload.attachmentId);
    yield put(
      employeeActions.deleteUploadedAttachmentSuccess(
        action.payload,
        action.payload.attachmentId
      )
    );

    if (action?.payload?.callback) {
      yield action.payload.callback();
    }
  } catch (e) {
    if (action?.payload?.fileErrorCallback) {
      yield action.payload.fileErrorCallback();
    }
    yield put(employeeActions.deleteUploadedAttachmentError(e));
  }
}

export function* addEmployeeQb(action) {
  try {
    const response = yield call(
      employeeService.addEmployeeQb,
      action.payload.tenantId
    );
    if (response.data?.errorMessage?.length > 0) {
      yield put(
        employeeActions.addEmployeeQbError(response.data?.errorMessage)
      );
      return;
    }
    yield put(employeeActions.addEmployeeQbSuccess(response));
  } catch (e) {
    yield put(employeeActions.addEmployeeQbError(e));
  }
}

function* deleteEmployeeProject(action) {
  try {
    const response = yield call(
      employeeService.deleteEmployeeProject,
      action.employeeId,
      action.projectId
    );

    yield put(employeeActions.deleteEmployeeProjectSuccess(response));
  } catch (e) {
    yield put(employeeActions.deleteEmployeeProjectError(e));
  }
}

export default function* employeesSaga() {
  yield takeLatest(LOAD_EMPLOYEES_ALL, loadAllEmployees);
  yield takeLatest(LOAD_EMPLOYEES_INACTIVE, loadInactiveEmployees);
  yield takeLatest(LOAD_EMPLOYEES_ON_PROCESS, loadOnProcessEmployees);
  yield takeLatest(EXPORT_EMPLOYEES, exportEmployees);
  yield takeEvery(LOAD_EMPLOYEES_TAB_NUMBER, loadEmployeesTabNumbers);
  yield takeLatest(SUBMIT_EMPLOYEE_DATA, saveEmployee);
  yield takeLatest(SUBMIT_EMPLOYEE_EDIT, editEmployee);
  yield takeLatest(SEARCH_EMPLOYEES, searchEmployees);
  yield takeLatest(GET_EMPLOYEE_BY_ID, getEmployeeById);
  yield takeLatest(GET_INITIAL_BRUTTO, getInitialBrutto);
  yield takeLatest(DELETE_EMPLOYEE, deleteEmployee);
  yield takeLatest(UPLOAD_MULTIPLE_FILES, uploadMultipleAttachments);
  yield takeLatest(DELETE_UPLOADED_ATTACHMENT, deleteEmployeeAttachment);
  yield takeLatest(ADD_EMPLOYEE_QB, addEmployeeQb);
  yield takeLatest(DELETE_EMPLOYEE_PROJECT, deleteEmployeeProject);
}
