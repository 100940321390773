import { useTranslation } from 'react-i18next';
import produce from 'immer';
import {
  SAVE_DOCUMENT,
  SAVE_DOCUMENT_ERROR,
  SAVE_DOCUMENT_SUCCESS,
  LOAD_ALL_DOCUMENTS,
  LOAD_ALL_DOCUMENTS_ERROR,
  LOAD_ALL_DOCUMENTS_SUCCESS,
  DELETE_SELECTED_DOCUMENT,
  DELETE_SELECTED_DOCUMENT_ERROR,
  DELETE_SELECTED_DOCUMENT_SUCCESS,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_ERROR,
  SEND_EMAIL_TEMPLATE,
  SEND_EMAIL_TEMPLATE_SUCCESS,
  SEND_EMAIL_TEMPLATE_ERROR,
  DOWNLOAD_DOCUMENT_TEMPLATE,
  DOWNLOAD_DOCUMENT_TEMPLATE_SUCCESS,
  DOWNLOAD_DOCUMENT_TEMPLATE_ERROR,
} from './constants';
import { toast } from 'react-toastify';

const initialState: any = {
  loading: false,
  documentSaveLoading: false,
  sendEmailLoading: false,
  downloadTemplateLoading: false,
  updateDocumentTemplateLoading: false,
  allDocumentsTemplate: [],
  isDocumentSelectedDeleted:false,
  isDocumentEdited:false,
}

const documentsReducer = (state = initialState, action) =>
    produce(state, draft => {
      switch (action.type) {
        case SAVE_DOCUMENT:
          draft.documentSaveLoading = true;
          break;
        case SAVE_DOCUMENT_SUCCESS:
          draft.documentSaveLoading = false;
          toast.success('Dokumenti u ruajt me sukses');
          break;
        case SAVE_DOCUMENT_ERROR:
          draft.documentSaveLoading = false;
          toast.error('Diçka shkoi gabim!');
          break;
        case LOAD_ALL_DOCUMENTS:
          draft.loading = true;
          break;
        case LOAD_ALL_DOCUMENTS_SUCCESS:
          let newList = action.payload.documents;
          newList.map((document)=>{
            document.isChecked = false;
          })
          draft.allDocumentsTemplate = newList;
          draft.loading = false;
          break;
        case LOAD_ALL_DOCUMENTS_ERROR:
          draft.loading = false;
          toast.error('Diçka shkoi gabim!');
          break;
        case DELETE_SELECTED_DOCUMENT:
          draft.loading = true;
          break;
        case DELETE_SELECTED_DOCUMENT_SUCCESS:
            draft.isDocumentSelectedDeleted = true;
          draft.loading = false;
          toast.success('Dokumentet u fshinë me sukses!');
          break;
        case DELETE_SELECTED_DOCUMENT_ERROR:
          draft.loading = false;
          toast.error('Diçka shkoi gabim!');
          break;
        case UPDATE_TEMPLATE:
          draft.isLoading = true;
          draft.updateDocumentTemplateLoading = true;
          break;
        case UPDATE_TEMPLATE_SUCCESS:
          draft.isLoading = true;
          draft.isDocumentEdited = !draft.isDocumentEdited;
          draft.updateDocumentTemplateLoading = false;
          toast.success("Të dhënat u ruajten me sukses!");
          break;
        case UPDATE_TEMPLATE_ERROR:
          draft.isLoading = false;
          draft.updateDocumentTemplateLoading = false;
          toast.error('Diçka shkoi gabim!');
          break;
        case SEND_EMAIL_TEMPLATE:
          draft.sendEmailLoading = true;
          break;
        case SEND_EMAIL_TEMPLATE_SUCCESS:
          draft.sendEmailLoading = false;
          toast.success('Email u dërgua me sukses!');
          break;
          case SEND_EMAIL_TEMPLATE_ERROR:
            draft.sendEmailLoading = false;
            toast.error('Diçka shkoi gabim!');
            break;
        case DOWNLOAD_DOCUMENT_TEMPLATE:
          draft.downloadTemplateLoading = true;
          break;
        case DOWNLOAD_DOCUMENT_TEMPLATE_SUCCESS:
          draft.downloadTemplateLoading = false;
          window.open(action.payload.downloadedFile[0].file, "_blank");
          break;
        case DOWNLOAD_DOCUMENT_TEMPLATE_ERROR:
          draft.downloadTemplateLoading = false;
          toast.error('Diçka shkoi gabim!');
          break;
        default:
          break;
      }
    });

export default documentsReducer;