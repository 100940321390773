import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { ToastContainer } from 'react-toastify';
import { FiMail, FiLock, FiCheck } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import InputCustom from "components/InputCustom/InputCustom.component";
import Card from "components/Card/Card.component";
import Button from 'components/Button/Button.component';
import { globalState } from 'types/globalState.type';
import styles from "./Login.module.scss";
import { logIn, resetFailMessage } from 'App/actions';
import {  useSelector } from 'react-redux';
import * as actions from '../../pages/Configurations/actions';
import { AiOutlineEye } from "react-icons/ai";

const ForgotPassword: React.FC = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const state = useSelector((state: globalState) => state);
	const globalState = useSelector((state: globalState) => state.globalReducer);
	const [passwordShown, setPasswordShown] = useState(false);

	let search = window.location.search;
	let params = new URLSearchParams(search);
	let token = params.get('token');

	let searchEmail = window.location.search;
	let emailParam = new URLSearchParams(searchEmail);
	let email = emailParam.get('email');

	let searchRandom = window.location.search;
	let randomParam = new URLSearchParams(searchRandom);
	let randomToken = randomParam.get('digit');

	const emailInputRef = useRef(null);
	const inputPasswordRef = useRef(null);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const handleSaveNewPassword = () => {
		const payload = {
		token: token,
		email: email,
		password: newPassword,
		password_confirmation: confirmNewPassword,
		randomToken: randomToken
		}

		if(!token){
			dispatch(actions.verifyPassword(payload))
		}else{
			dispatch(actions.resetPassword(payload))
		}
	
		history.push("/login")
	}
	 

	return (
		<form
			className={styles.form}
			id="loginForm"
		>
			<Card
				className={styles.card}
				cardContentClass={styles.cardContentClass}
			>
				<div className={styles.header}>
					{!token ? 
					<h4>Set Password</h4>
					:
					<h4>Forgot Password</h4>
					}
					<p>Here you can reset your password.</p>
				</div>
				<InputCustom
					placeholder="New Password"
					className={styles.loginInput}
					value={newPassword}
					type={passwordShown ? "text" : "password"}
					onChangeHandler={(e)=>setNewPassword(e.target.value)}
					ref={emailInputRef}
					iconStyle={styles.inputIcon}
					attributes={{
						id:"emailInput"
					}}
				>
					<FiLock />
					<div className={styles.eyeSvg}>
						<AiOutlineEye onClick={togglePassword}></AiOutlineEye>
					</div>
				</InputCustom>
				<InputCustom
					placeholder="Confirm Password"
					type={passwordShown ? "text" : "password"}
					className={styles.loginInput}
					value={confirmNewPassword}
					onChangeHandler={(e)=>setConfirmNewPassword(e.target.value)}
					ref={inputPasswordRef}
					iconStyle={styles.inputIcon}
				>
					<FiLock />
					<div className={styles.eyeSvg}>
						<AiOutlineEye onClick={togglePassword}></AiOutlineEye>
					</div>
				</InputCustom>
				<div className={`row spaceBetween`}>
				</div>
				<Button
					onClickHandler={handleSaveNewPassword}
					className={styles.signInButton}
					fixDimensions={true}
				>
					Save
				</Button>
			</Card>
			<ToastContainer position="bottom-right" />
		</form>
	);
};

const mapDispatchToProps = (dispatch: any): any => {
	return bindActionCreators<any, any>({
		logIn
	}, dispatch);
}

const mapStateToProps = (state) => {
	return {
		loginFail: state.globalReducer.loginFail,
		loading: state.globalReducer.loading,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(
withConnect)(ForgotPassword)

