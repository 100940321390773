import React, { PureComponent } from "react";
import AppWrapper from "components/AppWrapper/AppWrapper.component";
import styles from "./Holidays.module.scss";
import calendarStyles from "./Calendar.module.scss";
import { FunctionComponent, useEffect, useState, useRef } from "react";
import SearchInput from "components/SearchInput/SearchInput.component";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
	loadAllEmployeesAndVacations,
	loadAllCalendarVacations,
	loadAllVacationTypes,
	loadNewVacation,
	loadDeleteVacation,
	loadUpdateVacation,
	loadSingleVacation,
} from "./actions";
import FullCalendar, { EventInput } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { EmployeeVactions } from "types/employeeVactions.type";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import classNames from "classnames";
import { IconPencil } from "assets/icons/icons";
import { FiTrash2 } from "react-icons/fi";
import Button from "components/Button/Button.component";
import { collapseSidebarMenu } from "components/AppWrapper/actions";
import { globalState } from "types/globalState.type";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Reports: FunctionComponent = () => {
	const { t, i18n } = useTranslation("common");

	const period = 3;
	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);

	const dispatch = useDispatch();
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isAddVacationModalOpen, setIsAddVacationModalOpen] =
		useState<boolean>(false);
	const [isDeleteVacationModalOpen, setIsDeleteVacationModalOpen] =
		useState<boolean>(false);
	const [employeeVacation, setEmployeeVacation] = useState<EmployeeVactions>();
	const [selectedVacationType, setSelectedVacationType] = useState<number>(0);
	const [vacationIdToDelete, setVacationIdToDelete] = useState<number>();
	const [from, setFrom] = useState<any>(new Date());
	const [to, setTo] = useState<any>(new Date());
	const [editVacationMode, setEditVacationMode] = useState<boolean>(false);
	const [viewMode, setViewMode] = useState<boolean>(false);
	const [selectedVacation, setSelectedVacation] = useState<any>();
	const [employeeVacationType, setEmployeeVacationType] = useState<any>(0);

	const allVacations = useSelector(
		(state: RootStateOrAny) => state.vacationReducer.allVacations
	);
	const isDeletingLoader = useSelector(
		(state: RootStateOrAny) => state.vacationReducer.isDeletingLoader
	);
	const createLoading = useSelector(
		(state: RootStateOrAny) => state.vacationReducer.createLoading
	);
	const updateLoading = useSelector(
		(state: RootStateOrAny) => state.vacationReducer.updateLoading
	);
	const allCalendarVacations = useSelector(
		(state: RootStateOrAny) => state.vacationReducer.allCalendarVacations
	);
	const allVacationTypes = useSelector(
		(state: RootStateOrAny) => state.vacationReducer.allVacationTypes.data
	);
	const isVacationAdded = useSelector(
		(state: RootStateOrAny) => state.vacationReducer.isVacationAdded
	);

	const initialLoad = () => {
		dispatch(loadAllEmployeesAndVacations(tenantId));
		dispatch(loadAllCalendarVacations(tenantId, period));
		dispatch(loadAllVacationTypes());
		dispatch(collapseSidebarMenu(true));
	};

	useEffect(() => {
		initialLoad();
	}, []);

	useEffect(() => {
		dispatch(loadAllEmployeesAndVacations(tenantId));
	}, [isVacationAdded]);

	const employeeVacationModal = (employee) => {
		setEmployeeVacation(employee);
		setIsModalOpen(true);
	};

	const addEmployeeVacationModal = () => {
		setIsAddVacationModalOpen(true);
		setIsModalOpen(false);
	};

	const deleteEmployeeVacationModal = (vacationId) => {
		setIsModalOpen(false);
		setIsDeleteVacationModalOpen(true);
		setVacationIdToDelete(vacationId);
	};

	const editVacationModal = (vacation) => {
		setSelectedVacation(vacation);
		setEmployeeVacationType(vacation?.typeId);
		dispatch(loadSingleVacation(vacation?.id));
		setEditVacationMode(true);
		setSelectedVacation(vacation);
		setFrom(vacation?.from);
		setTo(vacation?.to);
	};

	const vacationTypeColorClasses = (type) => {
		return classNames({
			[styles.medicalLeave]: type === "Shëndetësor",
			[styles.maternityLeave]: type === "Lehonisë",
			[styles.annualLeave]: type === "Vjetor",
			[styles.specialLeave]: type === "Special",
		});
	};

	const handleSelectedVacationType = (e) => {
		setEmployeeVacationType(e.target.value);
	};

	const handleSelectedVacationTypeOnCreate = (e) => {
		setSelectedVacationType(e.target.value);
	};

	const allEmployeeVacations: EventInput[] = allCalendarVacations;

	const handleNewVacationData = (employeeVacation) => {
		const newVacation = {
			tenantId: employeeVacation.tenantId,
			employeeId: employeeVacation.id,
			type: selectedVacationType,
			from: from,
			to: to,
		};

		if (typeof from === 'string' && typeof to === 'string') {
			setViewMode(true);
			dispatch(loadNewVacation(newVacation));
			dispatch(loadAllCalendarVacations(tenantId, period));
			setIsAddVacationModalOpen(false);
			setIsModalOpen(true);
			setSelectedVacationType(0);
			setFrom("");
			setTo("");
		} else {
			toast.warning("Duhet të zgjedhësh datën e fillimit dhe mbarimit!")
		}
	};

	const handleUpdatedVacationData = (vacationId) => {
		const updatedVacation = {
			id: vacationId,
			type: employeeVacationType,
			from: from,
			to: to,
		};

		dispatch(loadUpdateVacation(updatedVacation));
		setEditVacationMode(false);
		setFrom("");
		setTo("");
		dispatch(loadAllEmployeesAndVacations(tenantId));
	};

	const handleDeleteEmployeeVacation = (employeeVacation) => {
		dispatch(loadDeleteVacation(employeeVacation));
		setIsDeleteVacationModalOpen(false);
		setIsModalOpen(false);
		dispatch(loadAllEmployeesAndVacations(tenantId));
	};

	let maleVacationTypes = [];
	return (
		<AppWrapper appContentClass={styles.holidaysComponent}>
			<div className={styles.employeeContainer}>
				<div className={styles.employeesTable}>
					<div className={styles.search}>
						<SearchInput
							className={styles.searchInputContainer}
							onChangeHandler={(event) => {
								setSearchTerm(event.target.value);
							}}
							searchPlaceholder={t("calendar.search")}
						/>
					</div>
					<div className={styles.scrollContainer}>
						{allVacations?.length > 0 &&
							allVacations
								.filter((val: EmployeeVactions) => {
									if (searchTerm === "") {
										return val;
									} else if (
										val.name.toLowerCase().includes(searchTerm.toLowerCase())
									) {
										return val;
									}
								})
								.map((employee: EmployeeVactions, idx: number) => {
									return (
										<div className={styles.employeeNameBox} key={idx}>
											<div
												className={styles.employeeName}
												onClick={() => employeeVacationModal(employee)}
											>
												{employee?.name}
											</div>
										</div>
									);
								})}
					</div>
				</div>
				<div className={styles.calendarContainer}>
					<div className={styles.calendarHeader}>
						<div className={styles.calendarText}>{t("calendar.calendar")}</div>
					</div>
					<div className={calendarStyles.calendarContainer} id="calendar">
						<FullCalendar
							plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
							initialView="dayGridMonth"
							height="100%"
							buttonText={{
								prev: 'mbrapa',
								next: 'Përpara',
								today: 'Sot',
								year: 'Viti',
								month: 'Muaj',
								week: 'Javë',
								day: 'Ditë',
								list: 'Listë',
							}}
							weekText="Javë"
							allDayText='Gjithë ditën'
							moreLinkText={(n) => {
								return '+më tepër ' + n
							}}
							dayHeaderContent={(info) => {
								const daysOfWeek = ['Diel', 'Hënë', 'Martë', 'Mërkurë', 'Enjte', 'Premte', 'Shtunë'];
								return daysOfWeek[info.date.getUTCDay()];
							}}
							firstDay={1}
							noEventsText='Nuk ka evente për të shfaqur'
							displayEventTime={false}
							headerToolbar={{
								left: "prev,next",
								center: "title",
								right: "timeGridDay,timeGridWeek,dayGridMonth",
							}}
							events={allEmployeeVacations}
							eventColor="red"
							nowIndicator
						/>
					</div>
					{isModalOpen && (
						<ModalCustom
							show={isModalOpen}
							contentClass={styles.contentClass}
							footerClass={styles.footerClass}
							onClose={() => {
								setIsModalOpen((prev) => false);
								setEditVacationMode((prev) => false);
							}}
							hideFooterButton={true}
						>
							<div className={styles.modalContainer}>
								<div className={styles.modalContainerInner}>
									<div className={styles.employeeNameModal}>
										{employeeVacation?.name}
									</div>
									{!editVacationMode ? (
										<div
											className={styles.addVacationButton}
											onClick={addEmployeeVacationModal}
										>
											{t("calendar.addBreak")}
										</div>
									) : null}
								</div>
								<div className={styles.employeeEmailModal}>
									{employeeVacation?.email}
								</div>
								<div className={styles.employeeTenantNameModal}>
									{employeeVacation?.tenantName}
								</div>

								{!viewMode && !employeeVacation?.vacations.length ? (
									<>
										<div className={styles.annualDays}>
											{t("vacation.annualLeave")}/ {employeeVacation.annualDays}{" "}
											{t("calendar.days")}
										</div>

										<div className={styles.medicalDays}>
											{t("vacation.medicalLeave")} /{" "}
											{employeeVacation.medicalDays} {t("calendar.days")}
										</div>

										{employeeVacation?.gender == 2 ? (
											<div className={styles.specialDays}>
												{t("vacation.specialLeave")} /{" "}
												{employeeVacation?.maleSpecialDays} {t("calendar.days")}
											</div>
										) : null}

										{employeeVacation?.gender == 1 ? (
											<div className={styles.maternityDays}>
												{t("vacation.maternityLeave")} /{" "}
												{employeeVacation?.maternityDays} {t("calendar.days")}
											</div>
										) : null}

										{employeeVacation?.gender == 1 ? (
											<div className={styles.specialDays}>
												{t("vacation.specialLeave")} /{" "}
												{employeeVacation?.femaleSpecialDays}{" "}
												{t("calendar.days")}
											</div>
										) : null}
									</>
								) : null}
								{allVacations?.map(
									(employee: EmployeeVactions, idx: number) => {
										if (
											employeeVacation.id === employee.id &&
											employee.vacations.length > 0
										) {
											return (
												<React.Fragment key={idx}>
													{employee?.vacations?.length > 0 &&
														employee?.vacations?.map((vacation, idx) => {
															if (editVacationMode) {
																if (selectedVacation.id === vacation.id) {
																	return (
																		<div
																			className={`${styles.vacationsAppendedModal}`}
																			key={idx}
																		>
																			<div className={styles.vacationTypeEdit}>
																				<div
																					className={styles.vacationContainer}
																				>
																					<select
																						style={
																							employeeVacationType == 0
																								? { color: "#2DCE98" }
																								: employeeVacationType == 1
																									? { color: "#FEB969" }
																									: employeeVacationType == 2
																										? { color: "#11CDEF" }
																										: employeeVacationType == 3 && {
																											color: "#D52D0F",
																										}
																						}
																						className={`${styles.vacationTypeDropdownEdit}`}
																						defaultValue={
																							selectedVacation?.typeId
																						}
																						onChange={
																							handleSelectedVacationType
																						}
																					>
																						{allVacationTypes?.map(
																							(type, idx) => {
																								return (
																									<option
																										className={vacationTypeColorClasses(
																											type
																										)}
																										value={idx}
																										key={idx}
																									>
																										{type}
																									</option>
																								);
																							}
																						)}
																					</select>
																				</div>
																				<div className={styles.fromToContainer}>
																					<div className={styles.from}>
																						{t("calendar.since")}
																					</div>
																					<div className={styles.to}>
																						{t("calendar.until")}
																					</div>
																				</div>
																				<div
																					className={styles.fromToBoxContainer}
																				>
																					<div
																						className={styles.fromBoxContainer}
																					>
																						<input
																							type="date"
																							value={from?.slice(0, 10)}
																							onChange={(event) =>
																								setFrom(event.target.value)
																							}
																						/>
																					</div>
																					<div
																						className={styles.toBoxContainer}
																					>
																						<input
																							type="date"
																							value={to?.slice(0, 10)}
																							onChange={(event) =>
																								setTo(event.target.value)
																							}
																						/>
																					</div>
																				</div>
																				<div className={styles.saveButton}>
																					<Button
																						onClickHandler={() =>
																							handleUpdatedVacationData(
																								vacation?.id
																							)
																						}
																						loading={updateLoading}
																					>
																						{t("configuration.save")}
																					</Button>
																				</div>
																			</div>
																		</div>
																	);
																} else {
																	return null;
																}
															} else {
																return (
																	<div
																		className={`${styles.vacationsAppendedModal}`}
																		key={idx}
																	>
																		<React.Fragment>
																			<div className={styles.vacationType}>
																				<div
																					className={styles.vacationModalType}
																				>
																					<div
																						className={styles.vacationTypeInner}
																					>
																						{t("calendar.typeOfBreak")}
																					</div>

																					<div
																						className={styles.vacationTypeName}
																					>
																						<div
																							className={vacationTypeColorClasses(
																								vacation?.type
																							)}
																						>
																							{vacation?.type}
																						</div>
																					</div>
																				</div>
																				{!editVacationMode ? (
																					<div className={styles.pencilIcon}>
																						<div
																							onClick={() =>
																								editVacationModal(vacation)
																							}
																						>
																							<IconPencil />
																						</div>
																						<div>
																							<FiTrash2
																								onClick={() =>
																									deleteEmployeeVacationModal(
																										vacation.id
																									)
																								}
																							/>
																						</div>
																					</div>
																				) : null}
																			</div>
																			<div className={styles.fromToContainer}>
																				<div className={styles.from}>
																					{t("calendar.since")}
																				</div>
																				<div className={styles.to}>
																					{t("calendar.until")}
																				</div>
																			</div>
																			<div
																				className={styles.fromToBoxContainer}
																			>
																				<div
																					className={styles.fromBoxContainer}
																				>
																					<input
																						type="date"
																						readOnly={!editVacationMode}
																						value={
																							editVacationMode
																								? from
																								: vacation?.from.slice(0, 10)
																						}
																						onChange={(event) =>
																							setFrom(event.target.value)
																						}
																					/>
																				</div>
																				<div className={styles.toBoxContainer}>
																					<input
																						type="date"
																						readOnly={!editVacationMode}
																						value={
																							editVacationMode
																								? to
																								: vacation?.to.slice(0, 10)
																						}
																						onChange={(event) =>
																							setTo(event.target.value)
																						}
																					/>
																				</div>
																			</div>
																		</React.Fragment>
																	</div>
																);
															}
														})}
													{!editVacationMode ? (
														<>
															<div
																className={styles.leftDaysContainer}
																style={{ marginTop: "20px" }}
															>
																{employeeVacation?.annualUsedDays}{" "}
																{t("calendar.daysUsed")}{" "}
																{employeeVacation?.annualLeftDays}{" "}
																{t("calendar.daysLeft")}{" "}
																{t("vacation.annualLeave")}
															</div>

															<div className={styles.leftDaysContainer}>
																{employeeVacation?.medicalUsedDays}{" "}
																{t("calendar.daysUsed")}{" "}
																{employeeVacation?.medicalLeftDays}{" "}
																{t("calendar.daysLeft")}{" "}
																{t("vacation.medicalLeave")}
															</div>

															{employeeVacation?.gender == 1 ? (
																<div className={styles.leftDaysContainer}>
																	{employeeVacation?.maternityUsedDays}{" "}
																	{t("calendar.daysUsed")}{" "}
																	{employeeVacation?.maternityLeftDays}{" "}
																	{t("calendar.daysLeft")}{" "}
																	{t("vacation.maternityLeave")}
																</div>
															) : null}

															{employeeVacation?.gender == 1 ? (
																<div className={styles.leftDaysContainer}>
																	{employeeVacation?.femaleSpecialUsedDays}{" "}
																	{t("calendar.daysUsed")}{" "}
																	{employeeVacation?.femaleSpecialLeftDays}{" "}
																	{t("calendar.daysLeft")}{" "}
																	{t("vacation.specialLeave")}
																</div>
															) : null}

															{employeeVacation?.gender == 2 ? (
																<div className={styles.leftDaysContainer}>
																	{employeeVacation?.maleSpecialUsedDays}{" "}
																	{t("calendar.daysUsed")}{" "}
																	{employeeVacation?.maleSpecialLeftDays}{" "}
																	{t("calendar.daysLeft")} Special Leave
																</div>
															) : null}
														</>
													) : null}
												</React.Fragment>
											);
										}
									}
								)}
							</div>
						</ModalCustom>
					)}
					{isDeleteVacationModalOpen ? (
						<ModalCustom
							show={isDeleteVacationModalOpen}
							contentClass={styles.contentClass}
							submitLoading={isDeletingLoader}
							footerClass={styles.footerClass}
							onClose={() => {
								setIsDeleteVacationModalOpen((prev) => false);
							}}
							hideFooterButton={false}
							submitText={"Po"}
							onSubmit={() => handleDeleteEmployeeVacation(vacationIdToDelete)}
						>
							<div className={styles.deleteVacationMessage}>
								{t("vacation.areYou")}
							</div>
						</ModalCustom>
					) : null}
					{isAddVacationModalOpen ? (
						<ModalCustom
							show={isAddVacationModalOpen}
							contentClass={styles.contentClass}
							submitLoading={createLoading}
							footerClass={styles.footerClass}
							onClose={() => {
								setIsAddVacationModalOpen((prev) => false);
								setEditVacationMode(false);
								setIsModalOpen(true);
							}}
							hideFooterButton={false}
							onSubmit={() => handleNewVacationData(employeeVacation)}
						>
							<div
								className={`${styles.addVacationModal} ${styles.vacationContainer}`}
							>
								<div className={styles.employeeNameModal}>
									{employeeVacation?.name}
								</div>
								<div className={styles.typeName}>Type</div>
								<select
									style={
										selectedVacationType == 0
											? { color: "#2DCE98" }
											: selectedVacationType == 1
												? { color: "#FEB969" }
												: selectedVacationType == 2
													? { color: "#11CDEF" }
													: selectedVacationType == 3 && { color: "#D52D0F" }
									}
									className={`${styles.vacationTypeDropdown}`}
									value={selectedVacationType}
									onChange={handleSelectedVacationTypeOnCreate}
								>
									{maleVacationTypes.push(allVacationTypes[0], allVacationTypes[1], allVacationTypes[3])}
									{employeeVacation?.gender === 1 && allVacationTypes?.map((type, idx) => {
										return (
											<option
												className={vacationTypeColorClasses(type)}
												value={idx}
												key={idx}
											>
												{type}
											</option>
										);
									})}

									{employeeVacation?.gender === 2 && maleVacationTypes?.map((type, idx) => {
										return (
											<option
												className={vacationTypeColorClasses(type)}
												value={idx}
												key={idx}
											>
												{type}
											</option>
										);
									})}
								</select>
								<div className={styles.fromToContainer}>
									<div className={styles.from}>{t("calendar.since")}</div>
									<div className={styles.to}>{t("calendar.until")}</div>
								</div>
								<div className={styles.fromToBoxContainer}>
									<div className={styles.fromBoxContainer}>
										<input
											type="date"
											value={from}
											onChange={(event) => setFrom(event.target.value)}
										/>
									</div>
									<div className={styles.toBoxContainer}>
										<input
											type="date"
											value={to}
											onChange={(event) => setTo(event.target.value)}
										/>
									</div>
								</div>
							</div>
						</ModalCustom>
					) : null}
				</div>
			</div>
		</AppWrapper>
	);
};

export default Reports;
