import {
	LOAD_REPORT_STATISTICS,
	DOWNLOAD_REPORT_STATISTICS_EXCEL,
	DOWNLOAD_REPORT_STATISTICS_PDF,
	LOAD_EMAIL_STATISTICS,
} from "./constants";
import { call, put, takeLatest } from 'redux-saga/effects';
import { getAllStatisticsReport, sendStatisticsEmail, exportStatisticsPdf, exportStatisticsExcel } from 'services/report.service';
import {
	loadReportStatisticsSuccess,
	loadReportStatisticsError,
	downloadReportStatisticsPdfSuccess,
	downloadReportStatisticsExcelSuccess,
	downloadReportStatisticsExcelError,
	downloadReportStatisticsPdfError,
	loadEmailStatisticsSuccess,
	loadEmailStatisticsError,
} from './actions';

export function* loadReportStatistics(action) {
	try {
		const response = yield call(
			getAllStatisticsReport, 
			action.payload.payload.tenantId,
			action.payload.payload.year,
			);
		yield put(loadReportStatisticsSuccess(response));
	} catch (e) {
		yield put(loadReportStatisticsError(e));
	}
}

export function* downloadReport_StatisticsPdf(action) {
	try {
		const response = yield call(
			exportStatisticsPdf,
			action.payload.tenantId,
			action.payload.year,
		);
		yield put(downloadReportStatisticsPdfSuccess(response));
	} catch (e) {
		yield put(downloadReportStatisticsPdfError(e));
	}
}

export function* downloadReportStatisticsExcel(action) {
	try {
		const response = yield call(
			exportStatisticsExcel,
			action.payload.tenantId,
			action.payload.year
		);
		yield put(downloadReportStatisticsExcelSuccess(response));
	} catch (e) {
		yield put(downloadReportStatisticsExcelError(e));
	}
}

export function* loadEmailStatistics(action) {
	try {
		const response = yield call(sendStatisticsEmail, action.payload.email);
		yield put(loadEmailStatisticsSuccess(response));
	} catch (e) {
		yield put(loadEmailStatisticsError(e));
	}
}

export default function* statisticsReportSaga() {
	yield takeLatest(LOAD_REPORT_STATISTICS, loadReportStatistics);
	yield takeLatest(DOWNLOAD_REPORT_STATISTICS_EXCEL, downloadReportStatisticsExcel);
	yield takeLatest(DOWNLOAD_REPORT_STATISTICS_PDF, downloadReport_StatisticsPdf);
	yield takeLatest(LOAD_EMAIL_STATISTICS, loadEmailStatistics);
}