import classNames from 'classnames';
import Loader from 'components/partials/Loader/Loader.component';
import React, { useEffect, useRef, useState } from 'react';
import { formatNumber_ } from 'utils/functions';
import { doNothing } from 'utils/globalVariables';
import styles from "./Button.module.scss";

interface Props {
    type?: any;
    width?: string;
    height?: string;
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
    radius?: string
    onClickHandler?: any;
    children?: React.ReactNode;
    className?: string;
    attributes?: object;
    loading?: boolean;
    fixedDimensions?: boolean;
    fixDimensions?: boolean
    viewType?: string;
    noHeight?: boolean;
    noPadding?: boolean;
    noMargin?: boolean;
	noHover?: boolean;
}

const Button: React.FC<Props> = (props) => {
    const buttonClasses = classNames({
        [styles.button_]: true,
        [props.className]: props.className,
        [styles.fixed]: props.fixedDimensions,
        [styles.shell]: props.viewType === "shell",
        [styles.light]: props.viewType === "light",
        [styles.shadow]: props.viewType === "shadow",
        [styles.noHeight]: props.noHeight,
        [styles.noPadding]: props.noPadding,
        [styles.noMargin]: props.noMargin,
        [styles.noHover]: props.noHover,
    });

    const buttonRef = useRef(null);
    const [rootStyle, setRootStyle] = useState({});

    useEffect(() => {
        if (buttonRef.current) {
            if (props.fixDimensions) {
                let RECT = buttonRef.current.getBoundingClientRect();
                if (!buttonRef?.current?.style?.width?.length && !buttonRef?.current?.style?.height?.length) {
                    let set = {
                        width: formatNumber_(RECT.width, 2) + "px",
                        minWidth: formatNumber_(RECT.width, 2) + "px",
                        height: formatNumber_(RECT.height, 2) + "px",
                    }
                    setRootStyle(set);
                }

            }
        }
    }, [props.loading, props.fixDimensions]);

    return (
        <button
            onClick={
                props.loading
                    ?
                    doNothing
                    :
                    (
                        props.onClickHandler
                            ?
                            props.onClickHandler
                            :
                            doNothing
                    )
            }
            className={buttonClasses}
            type={props.type || "button"}
            ref={buttonRef}
            style={rootStyle}
            {...props?.attributes}
        >
            {props.loading
                ?
                <Loader className={styles.loading} />
                :
                props.children
            }
        </button>
    );
}

export default Button;