import { FunctionComponent } from 'react';

import MenuItem from './MenuItem.component';
import routes from "utils/routes";
import { useTranslation } from 'react-i18next';

const Menu: FunctionComponent = () => {
	const { t } = useTranslation('common');

	return <ul>
		{routes.map((route, index) => {
			if (route.type === 'sidebar') {
				return <MenuItem
					link={'/' + route.path}
					title={t(route.name)}
					key={index}
					isExact={route.isExact}
				>
					{route.icon}
				</MenuItem>
			}
			else return false;
		})}
	</ul>
}

export default Menu;