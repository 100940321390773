import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InputCustom from "components/InputCustom/InputCustom.component";
import Card from "components/Card/Card.component";
import { globalState } from 'types/globalState.type';
import styles from "./LicenseAgreement.module.scss";
import { useSelector } from 'react-redux';
import * as actions from "../../Configurations/actions";
import { setIsLoggedIn, setToken } from '../../../App/actions';
import { useEffect } from 'react';
import { ConfigurationsState } from 'types/reducers/configurations.reducer.type';
import { setGlobalTenantId } from "App/actions";

interface LoginProps {
	logIn?: (userName: string, password: string) => void;
	loginFail?: boolean;
	loading?: boolean;
}

const LicenseAgreement: React.FC<LoginProps> = (props: LoginProps) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const email = useSelector((state: globalState) => state.globalReducer?.email);
	const token = useSelector((state: globalState) => state.globalReducer?.token);
	const isLoggedIn = useSelector((state: globalState) => state.globalReducer?.isLoggedIn);
	const roleId = useSelector((state: globalState) => state.globalReducer?.roleId);
	const globalState = useSelector((state: globalState) => state.globalReducer);

	useEffect(() => {
		dispatch(actions.getLoginCompanies({ roleId: globalState?.user?.roleId, email: globalState?.user?.email }));
	}, [])

	const state: ConfigurationsState = useSelector((state: globalState) => state.configurationsReducer);
	const loginCompanies = state?.companyLogin;

	const handleAcceptTerms = () => {
		dispatch(setIsLoggedIn(true))
		dispatch(setToken(token))

		localStorage.setItem('token', token);

		const payload = {
			email: email,
			agreed: 1
		}

		dispatch(actions.acceptTerms(payload))
		dispatch(actions.getUserByEmail(email))

		if (roleId === 2) {
			history.push("/");
		}

		if (loginCompanies?.length === 1) {
			history.push("/");
		}

		if ((roleId === 3 || roleId === 4) && loginCompanies?.length > 1) {
			history.push("/companyLogin");
		}

		if ((roleId === 3 || roleId === 4) && loginCompanies?.length === 1) {
			dispatch(setGlobalTenantId(loginCompanies[0]?.id));
			history.push("/");
		}
	}

	return (
		<div className={styles.loginWrapper}>
			<div className={styles.title}>
				Mirë se vini në llogarinë tuaj të re
			</div>
			<div className={styles.form}>
				<Card className={styles.card} cardContentClass={styles.cardContentClass}>
					<div className={styles.card}>
						<p>Kushtet e përdorimit</p>
						<p>Last updated: (01.01.2022)</p>
						<p>Ju lutemi lexoni me kujdes kushtet e përdorimit para se të përdorni https://payshef.com/, që menaxhohet nga Melita&Partners. Përdorimi i këtij shërbimi është i kushtëzuar nga pranimi dhe respektimi i këtyre kushteve. Këto kushte zbatohen për të gjithë ata që vizitojnë, përdorin ose kanë qasje në shërbim. Duke përdorur shërbimin, ju pranoni të respektoni këto kushte. Nëse nuk jeni dakord me ndonjë nga këto kushte, ju lutemi mos e përdorni shërbimin. </p>
					</div>

					<button className={styles.acceptButtonCustom} onClick={handleAcceptTerms}>Prano</button>

				</Card>
			</div>
		</div>
	);
};

export default LicenseAgreement;

