import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from '../TaxAdministrationReports.module.scss';
import { FaChevronLeft } from 'react-icons/fa';
import AppWrapper from '../../../../components/AppWrapper/AppWrapper.component';
import CommonHeader from '../../../../components/partials/CommonHeader/CommonHeader.component';
import ReportTemplateBox from '../../../../components/ReportTemplateBox/reportTemplateBox.component';
import { useHistory } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { downloadSalaryListExcel } from './actions';
import { globalState } from 'types/globalState.type';
import { useTranslation } from 'react-i18next';	
import SalaryListReport from 'pages/Reports/TaxAdministrationReports/EdiReports/SalaryListReport.component';

const EdiReports: FunctionComponent = () => {
	
    const { t,i18n } = useTranslation('common');
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);
	const history = useHistory();
	const dispatch = useDispatch();
	const isLoading = useSelector((state: RootStateOrAny) => state.ediDeclarationReports.loading);
	const [isSalaryListModalOpen, setIsSalaryListModalOpen] = useState(false);

	const handleBackToReports = () => {
		history.push('/reports/tax-administration');
	};

	const handleGoToEdiTax = () => {
		window.open(
			'https://edeklarimi.atk-ks.org/Account/Login?ReturnUrl=%2F',
			'_blank',
		);
	}

	return (
		<AppWrapper appContentClass={styles.reportsComponent}>
			<div className={styles.header}>
				<FaChevronLeft className={styles.arrowIcon}
					onClick={handleBackToReports}
				/>
				<CommonHeader title={t('reports.electronicDeclaration')} />
			</div>
			<div className={styles.reportsInner}>
				<ReportTemplateBox name={t('reports.ediSalaries')}
					onSubmit={() => {
						setIsSalaryListModalOpen(true);
					}}
				/>
				<ReportTemplateBox name={'EDI'}
					onSubmit={handleGoToEdiTax}
				/>
			</div>
			<SalaryListReport open={isSalaryListModalOpen} setOpen={setIsSalaryListModalOpen} />
		</AppWrapper>
	);
}

export default EdiReports;
