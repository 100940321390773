import { FunctionComponent, useEffect } from "react";
import styles from "pages/Configurations/Configurations.module.scss";
import Button from "components/Button/Button.component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "pages/Configurations/actions";
import SetupModal from "../Modal/SetupModal.component";
import { globalState } from "types/globalState.type";
import { useState } from "react";
import {
	ConfigurationsState,
	contributionSteps,
	taxSteps,
	taxStepsData,
} from "types/reducers/configurations.reducer.type";
import SetupInactivityModal from "pages/Configurations/SetupInactivityModal";

const setupModalTypes = [
	"TAXES",
	"CONTRIBUTIONS",
	"OVERTIME",
	"HOLIDAYS",
	"PROJECTS",
	"MEDICAL_INSURANCE",
	"SECTOR",
	"SECURITY_CODE",
	"QB",
];

const SetupTab: FunctionComponent = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [showInactivityModal, setShowInactivityModal] = useState(false);

	const roleId = useSelector(
		(state: globalState) => state.globalReducer.user?.roleId
	);
	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);

	const state: ConfigurationsState = useSelector(
		(state: globalState) => state.configurationsReducer
	);
	const [allAccounts, setAllAccounts] = useState<any[]>();

	const handleHeaderButtonClick = (opener) => {
		dispatch(actions.setSetupModalState({ show: true, opener }));
		dispatch(actions.setShowSubmitButton(true));
	};

	useEffect(() => {
		dispatch(actions.getAccounts(tenantId));
		dispatch(actions.getTenantAccounts(tenantId));
	}, []);

	const projectsOnClick = () => {
		history.push("/projects");
	};

	return (
		<div className={styles.payrollTab}>
			<SetupInactivityModal showModal={showInactivityModal} onClose={() => setShowInactivityModal(false)} />
			{roleId === 1 && (
				<>
					<Button
						viewType="shadow"
						className={styles.configButton}
						onClickHandler={() => {
							handleHeaderButtonClick(setupModalTypes[0]);
						}}
					>
						Tatimi në të ardhura personale
					</Button>
					<Button
						viewType="shadow"
						className={styles.configButton}
						onClickHandler={() => {
							handleHeaderButtonClick(setupModalTypes[1]);
						}}
					>
						Kontributet
					</Button>
				</>
			)}
			<Button
				viewType="shadow"
				className={styles.configButton}
				onClickHandler={() => {
					handleHeaderButtonClick(setupModalTypes[2]);
				}}
			>
				Puna shtesë
			</Button>
			<Button
				viewType="shadow"
				className={styles.configButton}
				onClickHandler={() => {
					handleHeaderButtonClick(setupModalTypes[3]);
				}}
			>
				Pushimet
			</Button>
			<Button
				viewType="shadow"
				className={styles.configButton}
				onClickHandler={projectsOnClick}
			>
				Projektet
			</Button>
			<Button
				viewType="shadow"
				className={styles.configButton}
				onClickHandler={() => {
					handleHeaderButtonClick(setupModalTypes[5]);
				}}
			>
				Sigurimi shëndetësor
			</Button>
			<Button
				viewType="shadow"
				className={styles.configButton}
				onClickHandler={() => {
					handleHeaderButtonClick(setupModalTypes[6]);
				}}
			>
				Sektorët
			</Button>
			<Button
				viewType="shadow"
				className={styles.configButton}
				onClickHandler={() => {
					handleHeaderButtonClick(setupModalTypes[7]);
				}}
			>
				Fjalëkalimi për mbyllje të periudhës
			</Button>

			<Button
				viewType="shadow"
				className={styles.configButton}
				onClickHandler={() => {
					handleHeaderButtonClick(setupModalTypes[8]);
				}}
			>
				Quickbooks
			</Button>

			<Button
				viewType="shadow"
				className={styles.configButton}
				onClickHandler={() => {
					setShowInactivityModal(true);
				}}
			>
				Inaktiviteti
			</Button>
			<SetupModal />
		</div>
	);
};

export default SetupTab;
