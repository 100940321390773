import Button from 'components/Button/Button.component';
import { FunctionComponent, useCallback, useEffect } from 'react';


import styles from 'pages/Configurations/Configurations.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getLastBackup, requestBackup } from '../actions';
import { globalState } from '../../../types/globalState.type';


const BackupTab: FunctionComponent = () => {
	const isRequestingBackup: boolean = useSelector((state: globalState) => state.configurationsReducer.isRequestingBackup);
	const dispatch = useDispatch();
	const startRequest = useCallback(() => dispatch(requestBackup()), [dispatch]);
	const getLastBackupDate = useCallback(() => dispatch(getLastBackup()), [dispatch])

	const lastBackup = useSelector((state: globalState) => state.configurationsReducer.lastBackupDate);

	useEffect(() => {
		getLastBackupDate();
	}, []);

	return (
		<div className={styles.backupTab}>
			<div className={styles.buttonsWrapper}>
				<Button
					onClickHandler={() => {
						startRequest();
					}}
					viewType="shadow"
					className={`${styles.configButton} ml-0`}
					loading={isRequestingBackup}
				>
					Kërko Backup
				</Button>
				<div className={styles.backUpDate}>
					{lastBackup
						? <><p style={{ fontWeight: 'bold' }}>Kerkesa e fundit e backupit:</p>
							<p style={{ color: '#89c732' }}>{lastBackup}</p></>
						: <div style={{ fontWeight: 'bold', display: "flex", height: "100%", alignItems: "center" }}>Nuk ka Backup për momentin</div>
					}
				</div>
			</div>

		</div>
	);

};

export default BackupTab;
