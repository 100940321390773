import produce from 'immer';
import { toast } from 'react-toastify';
import { ConfigurationsState } from 'types/reducers/configurations.reducer.type';
import * as constants from './constants';

const initialGlobalSearchValues =
{
	vacations: {
		id: null,
		name: null,
		module: null,
	},
	employees: {
		id: null,
		name: null,
		module: null,
	},
	users: {
		id: null,
		name: null,
		module: null,
	},
};

const initialState: ConfigurationsState = {
	showModal: false,
	modalOpener: null,
	modalSubmitLoading: false,
	editMode: false,
	globalData: null,
	tenantId: null,
	projects: null,
	showSubmitButton: true,
	setButtonText: 'Ruaj',
	searchLoading: false,
	isRequestingBackup: null
}

const globalSearchReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case constants.GET_GLOBAL_SEARCHED_DATA:
				draft.searchLoading = true;
				break;
			case constants.GET_GLOBAL_SEARCHED_DATA_SUCCESS:
				draft.searchLoading = false;
                draft.globalData = action.payload;
				break;
			case constants.GET_GLOBAL_SEARCHED_DATA_ERROR:
				draft.modalSubmitLoading = false;
				toast.error('Diqka shkoi gabim!');
				break;

			default: draft = initialState; break;
		}
	});

export default globalSearchReducer;