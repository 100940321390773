import { useTranslation } from "react-i18next";
import produce from "immer";
import {
  LOAD_MONTHLY_STATISTICS,
  LOAD_MONTHLY_STATISTICS_SUCCESS,
  LOAD_MONTHLY_STATISTICS_ERROR,
  LOAD_EXPORT_EXCEL,
  LOAD_EXPORT_EXCEL_SUCCESS,
  LOAD_EXPORT_EXCEL_ERROR,
  LOAD_EXPORT_PDF,
  LOAD_EXPORT_PDF_SUCCESS,
  LOAD_EXPORT_PDF_ERROR,
  SEND_EMPLOYEES_EMAIL_MONTHLY,
  SEND_EMPLOYEES_EMAIL_MONTHLY_SUCCESS,
  SEND_EMPLOYEES_EMAIL_MONTHLY_ERROR,
} from "./constants";
import { toast } from "react-toastify";
import { SET_EMPLOYEES_CHECKED } from "../../TaxAdministrationReports/constants";

const initialState: any = {
  loading: false,
  allMonthlyStatistics: [],
  loadingEmailButton: false,
};

const monthlyStatisticsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_MONTHLY_STATISTICS:
        draft.loading = true;
        break;
      case LOAD_MONTHLY_STATISTICS_SUCCESS:
        let newMonthlyStatistics = action.payload.allMonthlyStatistics.data;
        newMonthlyStatistics?.data?.map((item) => {
          item.isChecked = false;
        });
        draft.allMonthlyStatistics = newMonthlyStatistics;
        draft.loading = false;
        break;
      case LOAD_MONTHLY_STATISTICS_ERROR:
        draft.loading = true;
        toast.error("Diçka shkoi gabim!");
        break;
      case LOAD_EXPORT_EXCEL:
        draft.loading = true;
        break;
      case LOAD_EXPORT_EXCEL_SUCCESS:
        window.open(action.payload?.excelFile?.data, "_blank");
        break;
      case LOAD_EXPORT_EXCEL_ERROR:
        draft.loading = true;
        toast.error("Diçka shkoi gabim!");
        break;
      case LOAD_EXPORT_PDF:
        draft.loading = true;
        break;
      case LOAD_EXPORT_PDF_SUCCESS:
        window.open(action.payload?.pdfFile?.data, "_blank");
        break;
      case LOAD_EXPORT_PDF_ERROR:
        draft.loading = true;
        toast.error("Diçka shkoi gabim!");
        break;
      case SET_EMPLOYEES_CHECKED:
        draft.allMonthlyStatistics.map((item) => {
          item.isChecked = action.payload.checked;
        });
        break;
      case SEND_EMPLOYEES_EMAIL_MONTHLY:
        draft.loadingEmailButton = true;
        break;
      case SEND_EMPLOYEES_EMAIL_MONTHLY_SUCCESS:
        draft.loadingEmailButton = false;
        toast.success("Email-i u dërgua me sukses!");
        break;
      case SEND_EMPLOYEES_EMAIL_MONTHLY_ERROR:
        draft.loadingEmailButton = false;
        toast.error("Diçka shkoi gabim!");
        break;

      default:
        break;
    }
  });

export default monthlyStatisticsReducer;
