import { useTranslation } from 'react-i18next';
import produce from 'immer';
import {
  DOWNLOAD_SALARY_LIST_EXCEL,
    DOWNLOAD_SALARY_LIST_EXCEL_ERROR,
    DOWNLOAD_SALARY_LIST_EXCEL_SUCCESS
} from './constants';
import { toast } from 'react-toastify';

const initialState: any = {
  loading: false,
}

const ediDeclarationReports = (state = initialState, action) =>
    produce(state, draft => {
      switch (action.type) {
        case DOWNLOAD_SALARY_LIST_EXCEL:
          draft.loading = true;
          break;
        case DOWNLOAD_SALARY_LIST_EXCEL_SUCCESS:
          window.open(action.payload.excelFile.data, "_blank");
          draft.loading = false;
          break;
        case DOWNLOAD_SALARY_LIST_EXCEL_ERROR:
          draft.loading = false;
          toast.error('Diçka shkoi gabim!');
          break;
        default:
          break;
      }
    });

export default ediDeclarationReports;