import {
	GLOBAL_SEARCH_DONE,
	INIT_GLOBAL_SEARCH,
	CLEAR_GLOBAL_SEARCH,
	SYNC_QUICKBOOKS,
	SHOW_HELP_DATA,
	SET_LANGUAGE,
	SEE_ALL_NOTIFICATIONS,
} from "./constants";

export const setInitGlobalSearch = (payload) => ({
	type: INIT_GLOBAL_SEARCH,
	payload,
});

export const setGlobalSearchDone = (payload) => ({
	type: GLOBAL_SEARCH_DONE,
	payload,
});

export const setClearGlobalSearch = (payload) => ({
	type: CLEAR_GLOBAL_SEARCH,
	payload,
});

export const setLanguage = (tenantId: number, item: { value: number, label: string }) => ({
	type: SET_LANGUAGE,
	tenantId,
	item
});

export const syncQuickbooks = (payload) => ({
	type: SYNC_QUICKBOOKS,
	payload,
});

export const seeAllNotifications = (payload) => ({
	type: SEE_ALL_NOTIFICATIONS,
	payload,
});

export const showHelpData = (payload) => ({
	type: SHOW_HELP_DATA,
	payload,
});


