import { FunctionComponent, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { IconAdobe } from "assets/icons/icons";
import ReactPaginate from 'react-paginate';

import CustomSelect from 'components/CustomSelect/CustomSelect.component';
import { Employee } from "types/employee.type";
import { getEmployeeById, getInitialBrutto, showEmployeeAttachments, toggleModalState } from "pages/Employees/actions";
import { toggleModalState as toggleModalStateAppwrapper } from 'components/AppWrapper/actions';

import "styles/_pagination.scss";
import tableStyles from './Table.module.scss';
import ImageElement from "components/ImageElement/ImageElement.component";

interface Props {
	tableHeader?: React.ReactChild;
	children?: any;
	data?: any;
	headerClass?: string;
	headerData?: string[];
	tableWrapperClass?: string;
	tableClass?: string;
}

const Table: FunctionComponent<Props> = (props) => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(0);
	const [paginationStep, setPaginationStep] = useState({ value: 1, label: "10 / page" });
	const [pageCount, setPageCount] = useState(0);
	let paginationStepsOptions = [];

	const globalState = useSelector((state: RootStateOrAny) => state.globalReducer);
	const tenantId = globalState.tenantId || 1;

	for (var i = 1; i <= Math.ceil(props?.data?.length / 10); i++) {
		let step = i;
		paginationStepsOptions.push({ value: step, label: step * 10 + ' / page' });
	}
	const offset = currentPage * paginationStep.value * 10;
	const currentPageData = props?.data?.slice(offset, offset + (paginationStep.value * 10));
	function handlePageClick({ selected: selectedPage }) { setCurrentPage(prev => selectedPage); }
	function handleStepChange(value) {
		setPaginationStep(prev => value);
		setCurrentPage(prev => 0);
	}

	const setShowEmployeeModal = (state: boolean, row: any) => {
		dispatch(toggleModalState({ state, row }));
		dispatch(toggleModalStateAppwrapper({ state, row }));
	}

	useEffect(() => {
		setPageCount(prev => Math.ceil(props?.data?.length / (paginationStep.value * 10)));
	}, [paginationStep, props.data]);

	const employeeRowClickHandler = (row) => {
		setShowEmployeeModal(true, row);
		dispatch(getEmployeeById(tenantId, row.id));
		dispatch(getInitialBrutto(row.id))
	}

	return (
		<>
			<div className={`${tableStyles.tableWrapper} ${props.tableWrapperClass || ""} printHeightFix`}>
				<table className={`${tableStyles.table}  ${props.tableClass ? props.tableClass : ""}`}>
					{
						props.tableHeader
							?
							props.tableHeader
							:
							<thead className={`${tableStyles.tableHead} ${props.headerClass || ""}`}>
								<tr className={tableStyles.headerRow}>
									{
										props?.headerData?.map((cell, index) =>
											<th className={`${tableStyles.headerCell} ${!cell.length && index === 0 ? "noPrint" : ""}`} key={index}>
												{cell}
											</th>
										)
									}
								</tr>
							</thead>
					}

					{props.children ? props.children :
						<tbody className={tableStyles.tableBody}>
							{currentPageData?.map((row: Employee, index: number) => {
								return (
									<tr key={index} className={tableStyles.tableRow}>
										<td
											className={`${tableStyles.bodyCell} pointer ${tableStyles.bold}`}
											onClick={() => employeeRowClickHandler(row)}
										>
											<div className={`${tableStyles.userNameCell}`}>
												<div className={tableStyles.imageWrapper}>
													<ImageElement
														imageLink={row.photo}
														className={tableStyles.rowImage}
														width={50}
														height={50}
													/>
												</div>


												<div className={tableStyles.nameContainer} >
													{`${row.firstName || ""} ${row.lastName || ""}`}
												</div>
											</div>
										</td>
										<td
											className={`${tableStyles.bodyCell} pointer`}
											onClick={() => employeeRowClickHandler(row)}
										>
											{row.occupation}
										</td>
										<td
											className={`${tableStyles.bodyCell} pointer`}
											onClick={() => employeeRowClickHandler(row)}
										>
											{row.phone}
										</td>
										<td
											className={`${tableStyles.bodyCell} pointer`}
											onClick={() => employeeRowClickHandler(row)}
										>
											{row.email}
										</td>
										<td className={`${tableStyles.bodyCell} ${tableStyles.iconCell} text-center`}>
											{
												row?.attachments?.length
													?
													<div
														className={`pointer center ${tableStyles.docIconWrapper}`}
														onClick={(event) => {
															dispatch(showEmployeeAttachments({ show: true, row }))
														}}
													>
														<IconAdobe />
													</div>
													:
													<IconAdobe
														fill="gray"
														attributes={{
															title: ["Ky punëtor nuk ka dokumente të ngarkuara"]
														}}
													/>
											}
										</td>
									</tr>
								)
							})}
						</tbody>}
				</table>
			</div>
			{currentPageData?.length
				?
				<div className={tableStyles.paginationWrapper}>
					<div className={tableStyles.paginationSteps}>
						<CustomSelect
							options={paginationStepsOptions}
							className={tableStyles.paginationSelect}
							onChangeHandler={(value) => handleStepChange(value)}
							selected={paginationStep}
							controlStyle={{ borderRadius: "4px" }}
							wideMenu={true}
							singleValueStyle={{
								transform: "unset",
								position: "relative",
								maxWidth: "unset",
								top: 0,
								display: "block",
								opacity: ".5"
							}}
							optionsStyle={{ height: "50px", display: "flex", alignItems: "center" }}
						/>
					</div>

					<ReactPaginate
						previousLabel={
							<svg className={tableStyles.previousSvg} width="11.922" height="21.844" viewBox="0 0 11.922 21.844">
								<g transform="translate(-0.585 -0.17)">
									<path style={{ fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }} d="M18.508,25.016,9,15.508,18.508,6" transform="translate(-7.415 -4.415)" />
								</g>
							</svg>
						}
						nextLabel={
							<svg className={tableStyles.nextSvg} width="11.922" height="21.844" viewBox="0 0 11.922 21.844">
								<g transform="translate(-0.171 -0.17)">
									<path style={{ fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }} d="M9,25.016l9.508-9.508L9,6" transform="translate(-7.415 -4.415)" />
								</g>
							</svg>
						}
						pageCount={pageCount}
						onPageChange={handlePageClick}
						containerClassName={"pagination"}
						previousClassName={"pagination__link_mains"}
						nextClassName={"pagination__link_mains"}
						previousLinkClassName={"pagination__link"}
						nextLinkClassName={"pagination__link"}
						disabledClassName={"pagination__link--disabled"}
						activeClassName={"pagination__link--active"}
						forcePage={currentPage}
					/>
				</div>
				:
				""}
		</>
	)
}

export default Table;