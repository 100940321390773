import Table from 'components/Table/Table.component';
import * as tableStyles from 'components/Table/Table.module.scss';
import styles from 'pages/Configurations/Configurations.module.scss';
import { ConfigurationsState } from 'types/reducers/configurations.reducer.type';
import { globalState } from 'types/globalState.type';
import { useDispatch, useSelector } from "react-redux";
import SearchInput from 'components/SearchInput/SearchInput.component';
import { FunctionComponent, useEffect, useState } from 'react';
import * as actions from '../actions';
import ReactPaginate from 'react-paginate';
import CustomSelect from 'components/CustomSelect/CustomSelect.component';

const UsersTab: FunctionComponent = () => {
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);
	const tableColumns = ["Përdoruesi", "Ndryshimi", "Faqja", "Data", "Koha"];

	const dispatch = useDispatch();
	const roleId = useSelector((state: globalState) => state.globalReducer.user?.roleId);
	const state: ConfigurationsState = useSelector((state: globalState) => state.configurationsReducer);
	const allTenantAudits = state.audits;

	const [searchTerm, setSearchTerm] = useState<string>("");
	const [pageCount, setPageCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [paginationStep, setPaginationStep] = useState({ value: 1, label: "10 / page" });
	
	let paginationStepsOptions = [];

	for (var i = 1; i <= Math.ceil(allTenantAudits?.length / 10); i++) {
		let step = i;
		paginationStepsOptions.push({ value: step, label: step * 10 + ' / page' });
	}

	const offset = currentPage * paginationStep.value * 10;
	const currentPageData = allTenantAudits?.length && allTenantAudits?.slice(offset, offset + (paginationStep.value * 10));
	function handlePageClick({ selected: selectedPage }) { setCurrentPage(prev => selectedPage); }

	function handleStepChange(value) {
		setPaginationStep(prev => value);
		setCurrentPage(prev => 0);
	}

	useEffect(() => {
		setPageCount(prev => Math.ceil(allTenantAudits?.length / (paginationStep.value * 10)));
	}, [paginationStep, allTenantAudits]);


	const initialLoad = () => {
		if(roleId === 2){
			dispatch(actions.getTenantAudits(tenantId));

		}else if(roleId === 3 || roleId === 4){
			dispatch(actions.getAuthAudits());

		}else if(roleId === 1){
			dispatch(actions.getUserAudits(tenantId));
		}
	}

	useEffect(() => {
		initialLoad();
	}, [])

	return (
		<div>
			<div className={styles.search}>
				<SearchInput
					className={styles.searchInputContainer}
					onChangeHandler={event => { setSearchTerm(event.target.value) }}
					searchPlaceholder="Kërko"
				/>
			</div>
			<Table
				tableHeader={
					<thead className={`${tableStyles.tableHead}`}>
						<tr className={`${tableStyles.headerRow} `} >
							{
								tableColumns.map((item, index) => {
									return <th
										className={`${tableStyles.headerCell}`}
										key={index}
									>
										{item}
									</th>
								})
							}
						</tr>

					</thead>
				}
				tableWrapperClass={styles.tableWrapper}
				data={[]}
			>
				<tbody className={styles.tableBody}>
					{currentPageData?.length > 0 && currentPageData.filter((val) => {
							if (searchTerm === "") {
								return val
							} else if (val.user.toLowerCase().includes(searchTerm.toLowerCase())) {
								return val
							}
						}).map((audit, idx) =>  {
							return <tr className={styles.tableRow} key={idx}>
								<td className={`${styles.bodyCell} ${styles.text}`} >{audit?.user}</td>
								{/* <td className={`${styles.bodyCell} ${styles.text}`}>{audit?.personalNumber || '001789934'}</td> */}
								<td className={`${styles.bodyCell} ${styles.text}`}>{audit?.type}</td>
								<td className={`${styles.bodyCell} ${styles.text}`}>{audit?.page}</td>
								<td className={`${styles.bodyCell} ${styles.text}`}>{audit?.date}</td>
								<td className={`${styles.bodyCell} ${styles.text}`}>{audit?.time}</td>
							</tr>
						}
						)}
				</tbody>
			</Table>
			{currentPageData?.length ?
				<div className={styles.paginationWrapper}>
					<div className={styles.paginationSteps}>
						<CustomSelect
							options={paginationStepsOptions}
							className={tableStyles.paginationSelect}
							onChangeHandler={(value) => handleStepChange(value)}
							selected={paginationStep}
							controlStyle={{ borderRadius: "4px" }}
							wideMenu={true}
							singleValueStyle={{
								transform: "unset",
								position: "relative",
								maxWidth: "unset",
								top: 0,
								display: "block",
								opacity: ".5"
							}}
							optionsStyle={{ height: "50px", display: "flex", alignItems: "center" }}
						/>
					</div>
					<ReactPaginate
						previousLabel={
							<svg className={styles.previousSvg} width="11.922" height="21.844" viewBox="0 0 11.922 21.844">
								<g transform="translate(-0.585 -0.17)">
									<path style={{ fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }} d="M18.508,25.016,9,15.508,18.508,6" transform="translate(-7.415 -4.415)" />
								</g>
							</svg>
						}
						nextLabel={
							<svg className={styles.nextSvg} width="11.922" height="21.844" viewBox="0 0 11.922 21.844">
								<g transform="translate(-0.171 -0.17)">
									<path style={{ fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }} d="M9,25.016l9.508-9.508L9,6" transform="translate(-7.415 -4.415)" />
								</g>
							</svg>
						}
						pageCount={pageCount}
						onPageChange={handlePageClick}
						containerClassName={"pagination"}
						previousClassName={"pagination__link_mains"}
						nextClassName={"pagination__link_mains"}
						previousLinkClassName={"pagination__link"}
						nextLinkClassName={"pagination__link"}
						disabledClassName={"pagination__link--disabled"}
						activeClassName={"pagination__link--active"}
						forcePage={currentPage}
					/>
				</div> :
			""}
		</div>
	);
}

export default UsersTab;