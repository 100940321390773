import classNames from "classnames";
import { FunctionComponent } from "react";
import styles from "./CommonHeader.module.scss";

interface Props {
	title?: string;
	subTitle?: any;
	headerClass?: string;
	subTitleClass?: string;
	titleClass?: string;
	buttonContainerClass?: string;
	children?: React.ReactNode;
	alignedButtons?: boolean;
}

const CommonHeader: FunctionComponent<Props> = (props) => {
	const classes = classNames({
		[styles.commonHeader]: true,
		[props.headerClass]: props.headerClass,
		[styles.alignedButtons]: props.alignedButtons,
	});
	const titleClasses = classNames({
		[styles.title]: true,
		[props.titleClass]: props.titleClass,
	});

	return (
		<div className={classes} id="commonHeader">
			<h1 className={titleClasses}> {props.title} </h1>
			{props.subTitle ?
				<h5
					className={`${styles.subTitle || ""} ${props.subTitleClass || ""}`}
				>
					{props.subTitle}
				</h5>
				: ""
			}
			<div className={`${styles.buttonContainer} ${props.buttonContainerClass || ""}`}>
				{props.children}
			</div>
		</div>
	)
};

export default CommonHeader;