import { call, put, takeLatest } from "redux-saga/effects";
import * as services from "services/configuration.service";
import * as actions from "./actions";
import * as constants from "./constants";
import { toast } from "react-toastify";
import history from "../../App/history";
import { getBackup, startRequestBackup } from "../../services/backup.service";
import {
  getLastBackupError,
  getLastBackupSuccess,
  requestBackupError,
  requestBackupSuccess,
} from "./actions";
import { GET_LAST_BACKUP, REQUEST_BACKUP } from "./constants";

export function* submitCompanyData(action) {
  try {
    const response = yield call(services.createTenant, action.payload);
    yield put(actions.submitCompanyDataSuccess(response));
  } catch (error) {
    yield put(actions.submitCompanyDataError(error));
  }
}

export function* submitAdminData(action) {
  try {
    const response = yield call(services.addAdmin, action.payload);
    yield put(actions.submitAdminDataSuccess(response));
  } catch (error) {
    yield put(actions.submitAdminDataError(error));
  }
}

export function* submitUserData(action) {
  try {
    const response = yield call(services.addUser, action.payload);
    yield put(actions.submitUserDataSuccess(response));
  } catch (error) {
    yield put(actions.submitUserDataError(error));
  }
}

export function* searchCompanies(action) {
  try {
    const response = yield call(services.searchCompanies, action.payload);
    yield put(actions.searchCompaniesSuccess(response));
  } catch (error) {
    yield put(actions.searchCompaniesError(error));
  }
}

export function* getContactInfo(action) {
  try {
    const response = yield call(
      services.getContactInfo,
      action.payload.tenantId
    );
    yield put(actions.getContactInfoSuccess(response.data));
  } catch (error) {
    yield put(actions.getContactInfoError(error));
  }
}

export function* getAllTenants(action) {
  try {
    const response = yield call(
      services.getAllTenants,
      action.payload.tenantId
    );
    yield put(actions.getAllTenantsSuccess(response.data));
  } catch (error) {
    yield put(actions.getAllTenantsError(error));
  }
}

export function* getAllUsers(action) {
  try {
    const response = yield call(services.getAllUsers, action.payload.tenantId);
    yield put(actions.getAllUsersSuccess(response.data));
  } catch (error) {
    yield put(actions.getAllUsersError(error));
  }
}

export function* deleteCompany(action) {
  try {
    const response = yield call(
      services.deleteCompany,
      action.payload.tenantId
    );
    yield put(actions.deleteCompanySuccess(response));
  } catch (error) {
    yield put(actions.deleteCompanyError(error));
  }
}

export function* getTaxDegreesInformation(action) {
  try {
    const response = yield call(services.getTaxDegreesInformation);
    yield put(actions.getTaxDegreesInformationSuccess(response));
  } catch (error) {
    yield put(actions.getTaxDegreesInformationError(error));
  }
}

export function* getContribute(action) {
  try {
    const response = yield call(services.getContribute);
    yield put(actions.getContributeSuccess(response));
  } catch (error) {
    yield put(actions.getContributeError(error));
  }
}

export function* saveTaxes(action) {
  try {
    const response = yield call(services.updateTax, action.payload);
    yield put(actions.saveTaxesSuccess(response));
  } catch (error) {
    yield put(actions.saveTaxesError(error));
  }
}

export function* saveContributes(action) {
  try {
    const response = yield call(services.updateContribute, action.payload);
    yield put(actions.saveContributesSuccess(response));
  } catch (error) {
    yield put(actions.saveContributesError(error));
  }
}

export function* getOneTenant(action) {
  try {
    const response = yield call(services.getOneTenant, action.payload.tenantId);
    yield put(actions.getOneTenantSuccess(response.data));
  } catch (error) {
    yield put(actions.getOneTenantError(error));
  }
}

export function* deleteUser(action) {
  try {
    const response = yield call(services.deleteUser, action.payload.userId);
    yield put(actions.deleteUserSuccess(response));
  } catch (error) {
    yield put(actions.deleteUserError(error));
  }
}

export function* getOneUser(action) {
  try {
    const response = yield call(services.getOneUser, action.payload.userId);
    yield put(actions.getOneUserSuccess(response.data));
  } catch (error) {
    yield put(actions.getOneUserError(error));
  }
}

export function* getTenantAudits(action) {
  try {
    const response = yield call(
      services.getTenantAudits,
      action.payload.tenantId
    );
    yield put(actions.getTenantAuditsSuccess(response.data));
  } catch (error) {
    yield put(actions.getTenantAuditsError(error));
  }
}

export function* getAuthAudits() {
  try {
    const response = yield call(services.getAuthAudits);
    yield put(actions.getAuthAuditsSuccess(response));
  } catch (error) {
    yield put(actions.getAuthAuditsError(error));
  }
}

export function* getUserAudits(action) {
  try {
    const response = yield call(services.getUserAudits, action.payload.userId);
    yield put(actions.getUserAuditsSuccess(response.data));
  } catch (error) {
    yield put(actions.getUserAuditsError(error));
  }
}

export function* uploadLogo(action) {
  try {
    const response = yield call(services.uploadLogo, action.payload);
    yield put(actions.uploadLogoSuccess(response));
  } catch (error) {
    yield put(actions.uploadLogoError(error));
  }
}

export function* getAuthUser() {
  try {
    const response = yield call(services.getAuthUser);
    yield put(actions.getAuthUserSuccess(response));
  } catch (error) {
    yield put(actions.getAuthUserError(error));
  }
}

export function* updateTenant(action) {
  try {
    const response = yield call(services.updateTenant, action.payload);
    yield put(actions.updateTenantSuccess(response));
  } catch (error) {
    yield put(actions.updateTenantError(error));
  }
}

export function* updateUser(action) {
  try {
    const response = yield call(services.updateUser, action.payload);
    yield put(actions.updateUserSuccess(response));
  } catch (error) {
    yield put(actions.updateUserError(error));
  }
}

function* startBackUpRequest(action) {
  try {
    yield call(startRequestBackup);
    yield put(requestBackupSuccess());
  } catch (e) {
    yield put(requestBackupError());
  }
}

export function* getOvertime(action) {
  try {
    const response = yield call(services.getOvertime, action.payload);
    yield put(actions.getOvertimeSuccess(response));
  } catch (error) {
    yield put(actions.getOvertimeError(error));
  }
}
export function* saveOvertime(action) {
  try {
    const response = yield call(services.updateOvertime, action.payload);
    yield put(actions.saveOvertimeSuccess(response));
  } catch (error) {
    yield put(actions.saveOvertimeError(error));
  }
}

export function* getVacation(action) {
  try {
    const response = yield call(services.getVacations, action.payload);
    yield put(actions.getVacationSuccess(response));
  } catch (error) {
    yield put(actions.getVacationError(error));
  }
}

export function* saveVacation(action) {
  try {
    const response = yield call(services.updateVacations, action.payload);
    yield put(actions.saveVacationSuccess(response));
  } catch (error) {
    yield put(actions.saveVacationError(error));
  }
}

export function* updateHealthInsurance(action) {
  try {
    const response = yield call(
      services.updateHealthInsurance,
      action.payload.tenantId,
      action.payload
    );
    yield put(actions.updateHealthInsuranceSuccess(response));
  } catch (error) {
    yield put(actions.updateHealthInsuranceError(error));
  }
}

export function* getHealthInsurance(action) {
  try {
    const response = yield call(
      services.getHealthInsurance,
      action.payload.tenantId
    );
    yield put(actions.getHealthInsuranceSuccess(response?.data));
  } catch (error) {
    yield put(actions.getHealthInsuranceError(error));
  }
}

export function* getLoginCompanies(action) {
  try {
    const response = yield call(
      services.getLoginCompanies,
      action.payload.roleId,
      action.payload.email
    );
    yield put(actions.getLoginCompaniesSuccess(response.data));
  } catch (error) {
    yield put(actions.getLoginCompaniesError(error));
  }
}

export function* saveProject(action) {
  try {
    const response = yield call(services.saveProject, action.payload);
    yield put(actions.saveProjectSuccess(response));
    yield put(
      actions.getAllProjects({
        tenantId: action.payload.userId,
        activeProjectsOnly: false,
      })
    );
  } catch (error) {
    yield put(actions.saveProjectError(error));
  }
}

export function* getLastBackup() {
  try {
    const response = yield call(getBackup);
    yield put(getLastBackupSuccess(response.data.data.time));
  } catch (e) {
    yield put(getLastBackupError());
  }
}

export function* deleteProject(action) {
  try {
    const response = yield call(
      services.deleteProject,
      action.payload.projectId,
      action.payload.tenantId
    );
    if (response.data && response.data.success) {
      yield put(actions.deleteProjectSuccess(response));
    } else {
      yield put(actions.deleteProjectError(response.data.errorMessage));
    }
  } catch (error) {
    yield put(actions.deleteProjectError(error));
  }
}

export function* getOneProject(action) {
  try {
    const response = yield call(
      services.getOneProject,
      action.payload.projectId
    );
    yield put(actions.getOneProjectSuccess(response.data));
  } catch (error) {
    yield put(actions.getOneProjectError(error));
  }
}

export function* sendForgotPasswordEmail(action) {
  try {
    const response = yield call(
      services.sendForgotPasswordEmail,
      action.payload.email
    );
    yield put(actions.sendForgotPasswordEmailSuccess(response));
  } catch (error) {
    yield put(actions.sendForgotPasswordEmailError(error));
  }
}

export function* resetPassword(action) {
  try {
    const response = yield call(services.resetPassword, action.payload);
    yield put(actions.resetPasswordSuccess(response));
  } catch (error) {
    yield put(actions.resetPasswordError(error));
  }
}

export function* verifyPassword(action) {
  try {
    const response = yield call(services.verifyPassword, action.payload);
    yield put(actions.verifyPasswordSuccess(response));
  } catch (error) {
    yield put(actions.verifyPasswordError(error));
  }
}

export function* acceptTerms(action) {
  try {
    const response = yield call(services.acceptTerms, action.payload);
    yield put(actions.acceptTermsSuccess(response));
  } catch (error) {
    yield put(actions.acceptTermsError(error));
  }
}

export function* changePassword(action) {
  try {
    const response = yield call(services.changePassword, action.payload);
    yield put(actions.changePasswordSuccess(response));
    if (response.data === "Current password does not exist.") {
      toast.error("Your current password is wrong.");
    }
    history.push("/");
    toast.success("Fjalëkalimi është ndryshuar me sukses.");
  } catch (error) {
    yield put(actions.changePasswordError(error));
  }
}

export function* getCode() {
  try {
    const response = yield call(services.getSecurityCode);
    yield put(actions.getCodeSuccess(response?.data));
  } catch (error) {
    yield put(actions.getCodeError(error));
  }
}

export function* generateCode(action) {
  try {
    const response = yield call(services.generateCode, action.payload);
    yield put(actions.generateCodeSuccess(response));
  } catch (error) {
    yield put(actions.generateCodeError(error));
  }
}

export function* getTenantSectors(action) {
  try {
    const response = yield call(
      services.getTenantSectors,
      action.payload.tenantId
    );
    yield put(actions.getTenantSectorsSuccess(response?.data));
  } catch (error) {
    yield put(actions.getTenantSectorsError(error));
  }
}

export function* createSector(action) {
  try {
    const response = yield call(services.createSector, action.payload);
    yield put(actions.createSectorSuccess(response));
  } catch (error) {
    yield put(actions.createSectorError(error));
  }
}

export function* deleteSector(action) {
  try {
    const response = yield call(services.deleteSector, action.payload.sectorId);
    yield put(actions.deleteSectorSuccess(response));
  } catch (error) {
    yield put(actions.deleteSectorError(error));
  }
}

export function* updateSector(action) {
  try {
    const response = yield call(services.updateSector, action.payload);
    yield put(actions.updateSectorSuccess(response));
  } catch (error) {
    yield put(actions.updateSectorError(error));
  }
}

export function* getAccounts(action) {
  try {
    const response = yield call(services.getAccounts, action.payload.tenantId);
    if (response.data.value) {
      yield put(actions.getAccountsSuccess(response.data.value));
    } else {
      yield put(actions.getAccountsSuccess(response.data.error));
    }
  } catch (error) {
    yield put(actions.getAccountsError(error));
  }
}

export function* getUserList() {
  try {
    const response = yield call(services.getUserList);
    yield put(actions.getUserListSuccess(response?.data?.data));
  } catch (error) {
    yield put(actions.getUserListError(error));
  }
}

export function* allTenantUsers(action) {
  try {
    const response = yield call(
      services.allTenantUsers,
      action.payload.tenantId
    );
    yield put(actions.allTenantUsersSuccess(response?.data?.data));
  } catch (error) {
    yield put(actions.allTenantUsersError(error));
  }
}

export function* getRefreshToken(action) {
  try {
    const response = yield call(services.getRefreshToken, action.payload);
    yield put(actions.getRefreshTokenSuccess(response));
  } catch (error) {
    yield put(actions.getRefreshTokenError(error));
  }
}

export function* getAllProjects(action) {
  try {
    const response = yield call(
      services.getAllProjects,
      action.payload.tenantId,
      action.payload.activeProjectsOnly
    );
    yield put(actions.getAllProjectsSuccess(response?.data?.value));
  } catch (error) {
    yield put(actions.getAllProjectsError(error));
  }
}

export function* addProjectQb(action) {
  try {
    const response = yield call(services.addProjectQb, action.payload.tenantId);
    if (response.data && response.data.success) {
      yield put(actions.addProjectQbSuccess(response));
    } else {
      yield put(actions.addProjectQbError(response.data.errorMessage));
    }
  } catch (e) {
    yield put(actions.addProjectQbError(e));
  }
}

export function* checkQuickbooks(action) {
  yield put(actions.setQuickBooksSuccessLoadingState(true));
  try {
    const response = yield call(services.checkQuickbooks, action.payload);
    if (response.data && response.data.success) {
      yield put(actions.checkQuickbooksSuccess(response));
      yield put(actions.setQuickBooksSuccessLoadingState(false));
    } else {
      yield put(actions.checkQuickbooksError(response.data.errorMessage));
      yield put(actions.setQuickBooksSuccessLoadingState(false));
    }
  } catch (error) {
    yield put(actions.checkQuickbooksError(error));
    yield put(actions.setQuickBooksSuccessLoadingState(false));
  }
}

export function* getTenantAccounts(action) {
  try {
    const response = yield call(
      services.getTenantAccounts,
      action.payload.tenantId
    );
    yield put(actions.getTenantAccountsSuccess(response?.data));
  } catch (error) {
    yield put(actions.getTenantAccountsError(error));
  }
}

export function* resetModalSubmitLoading() {
  yield put(actions.resetModalSubmitLoadingState());
}

export function* saveAccounts(action) {
  try {
    const response = yield call(services.saveAccounts, action.payload);
    yield put(actions.saveAccountsSuccess(response));
  } catch (error) {
    yield put(actions.saveAccountsError(error));
  }
}

export function* deleteAccount(action) {
  try {
    const response = yield call(
      services.deleteAccount,
      action.payload.accountId
    );
    yield put(actions.deleteAccountSuccess(response));
  } catch (error) {
    yield put(actions.deleteAccountError(error));
  }
}

export function* getPayrollAccounts() {
  try {
    const response = yield call(services.getPayrollAccounts);
    yield put(actions.getPayrollAccountsSuccess(response.data));
  } catch (error) {
    yield put(actions.getPayrollAccountsError(error));
  }
}
export default function* configurationSaga() {
  yield takeLatest(constants.SUBMIT_COMPANY_DATA, submitCompanyData);
  yield takeLatest(constants.SUBMIT_ADMIN_DATA, submitAdminData);
  yield takeLatest(constants.SUBMIT_USER_DATA, submitUserData);
  yield takeLatest(constants.SEARCH_COMPANIES, searchCompanies);
  yield takeLatest(constants.GET_CONTACT_INFO, getContactInfo);
  yield takeLatest(constants.GET_ALL_TENANTS, getAllTenants);
  yield takeLatest(constants.GET_ALL_USERS, getAllUsers);
  yield takeLatest(constants.DELETE_COMPANY, deleteCompany);
  yield takeLatest(constants.GET_TAX_INFORMATION, getTaxDegreesInformation);
  yield takeLatest(constants.GET_CONTRIBUTE, getContribute);
  yield takeLatest(constants.GET_ONE_TENANT, getOneTenant);
  yield takeLatest(constants.DELETE_USER, deleteUser);
  yield takeLatest(constants.GET_ONE_USER, getOneUser);
  yield takeLatest(constants.GET_TENANT_AUDITS, getTenantAudits);
  yield takeLatest(constants.GET_AUTH_AUDITS, getAuthAudits);
  yield takeLatest(constants.GET_USER_AUDITS, getUserAudits);
  yield takeLatest(constants.UPLOAD_LOGO, uploadLogo);
  yield takeLatest(constants.GET_AUTH_USER, getAuthUser);
  yield takeLatest(constants.UPDATE_TENANT, updateTenant);
  yield takeLatest(constants.UPDATE_USER, updateUser);
  yield takeLatest(constants.SAVE_TAXES, saveTaxes);
  yield takeLatest(constants.SAVE_CONTRIBUTES, saveContributes);
  yield takeLatest(constants.GET_OVERTIME, getOvertime);
  yield takeLatest(constants.GET_LOGIN_COMPANIES, getLoginCompanies);
  yield takeLatest(constants.SAVE_OVERTIME, saveOvertime);
  yield takeLatest(constants.GET_VACATION, getVacation);
  yield takeLatest(constants.SAVE_VACATION, saveVacation);
  yield takeLatest(constants.UPDATE_HEALTH_INSURANCE, updateHealthInsurance);
  yield takeLatest(constants.GET_HEALTH_INSURANCE, getHealthInsurance);
  yield takeLatest(constants.SAVE_PROJECT, saveProject);
  yield takeLatest(constants.DELETE_PROJECT, deleteProject);
  yield takeLatest(constants.GET_ONE_PROJECT, getOneProject);
  yield takeLatest(
    constants.SEND_FORGOT_PASSWORD_EMAIL,
    sendForgotPasswordEmail
  );
  yield takeLatest(constants.RESET_PASSWORD, resetPassword);
  yield takeLatest(constants.ACCEPT_TERMS, acceptTerms);
  yield takeLatest(constants.CHANGE_PASSWORD, changePassword);
  yield takeLatest(REQUEST_BACKUP, startBackUpRequest);
  yield takeLatest(GET_LAST_BACKUP, getLastBackup);
  yield takeLatest(constants.GET_CODE, getCode);
  yield takeLatest(constants.GENERATE_CODE, generateCode);
  yield takeLatest(constants.GET_TENANT_SECTORS, getTenantSectors);
  yield takeLatest(constants.CREATE_SECTOR, createSector);
  yield takeLatest(constants.DELETE_SECTOR, deleteSector);
  yield takeLatest(constants.UPDATE_SECTOR, updateSector);
  yield takeLatest(constants.GET_ACCOUNTS, getAccounts);
  yield takeLatest(constants.GET_USER_LIST, getUserList);
  yield takeLatest(constants.ALL_TENANT_USERS, allTenantUsers);
  yield takeLatest(constants.GET_REFRESH_TOKEN, getRefreshToken);
  yield takeLatest(constants.GET_ALL_PROJECTS, getAllProjects);
  yield takeLatest(constants.CHECK_QUICKBOOKS, checkQuickbooks);
  yield takeLatest(constants.GET_TENANT_ACCOUNTS, getTenantAccounts);
  yield takeLatest(constants.SAVE_ACCOUNTS, saveAccounts);
  yield takeLatest(constants.DELETE_ACCOUNT, deleteAccount);
  yield takeLatest(constants.GET_PAYROLL_ACCOUNTS, getPayrollAccounts);
  yield takeLatest(constants.ADD_PROJECT_QB, addProjectQb);
  yield takeLatest(constants.VERIFY_PASSWORD, verifyPassword);
  yield takeLatest(
    constants.RESET_MODAL_SUBMIT_LOADING,
    resetModalSubmitLoading
  );
}
