const scope = "melitaPayroll/salaries";

export const SET_MODAL_OPEN = `${scope}/SET_MODAL_OPEN`;
export const SET_MODAL_EDIT_MODE = `${scope}/SET_MODAL_EDIT_MODE`;
export const GET_SALARY_CONFIGURATIONS = `${scope}/GET_SALARY_CONFIGURATIONS`;

export const LOAD_SALARIES = `${scope}/LOAD_SALARIES`;
export const LOAD_SALARIES_SUCCESS = `${scope}/LOAD_SALARIES_SUCCESS`;
export const LOAD_SALARIES_ERROR = `${scope}/LOAD_SALARIES_ERROR`;

export const GENERATE_SALARIES = `${scope}/GENERATE_SALARIES`;
export const GENERATE_SALARIES_SUCCESS = `${scope}/GENERATE_SALARIES_SUCCESS`;
export const GENERATE_SALARIES_ERROR = `${scope}/GENERATE_SALARIES_ERROR`;

export const CREATE_MONTH_SALARIES = `${scope}/CREATE_MONTH_SALARIES`;
export const CREATE_MONTH_SALARIES_SUCCESS = `${scope}/CREATE_MONTH_SALARIES_SUCCESS`;
export const CREATE_MONTH_SALARIES_ERROR = `${scope}/CREATE_MONTH_SALARIES_ERROR`;

export const UPDATE_SALARY = `${scope}/UPDATE_SALARY`;
export const UPDATE_SALARY_SUCCESS = `${scope}/UPDATE_SALARY_SUCCESS`;
export const UPDATE_SALARY_ERROR = `${scope}/UPDATE_SALARY_ERROR`;

export const EXPORT_EXCEL_SALARIES = `${scope}/EXPORT_EXCEL_SALARIES`;
export const EXPORT_EXCEL_SALARIES_SUCCESS = `${scope}/EXPORT_EXCEL_SALARIES_SUCCESS`;
export const EXPORT_EXCEL_SALARIES_ERROR = `${scope}/EXPORT_EXCEL_SALARIES_ERROR`;

export const GENERATE_PAYSLIP = `${scope}/GENERATE_PAYSLIP`;
export const GENERATE_PAYSLIP_SUCCESS = `${scope}/GENERATE_PAYSLIP_SUCCESS`;
export const GENERATE_PAYSLIP_ERROR = `${scope}/GENERATE_PAYSLIP_ERROR`;

export const PRINT_PAYSLIP = `${scope}/PRINT_PAYSLIP`;
export const PRINT_PAYSLIP_SUCCESS = `${scope}/PRINT_PAYSLIP_SUCCESS`;
export const PRINT_PAYSLIP_ERROR = `${scope}/PRINT_PAYSLIP_ERROR`;

export const EMAIL_PAYSLIP = `${scope}/EMAIL_PAYSLIP`;
export const EMAIL_PAYSLIP_SUCCESS = `${scope}/EMAIL_PAYSLIP_SUCCESS`;
export const EMAIL_PAYSLIP_ERROR = `${scope}/EMAIL_PAYSLIP_ERROR`;

export const EMAIL_CONTRACT = `${scope}/EMAIL_CONTRACT`;
export const EMAIL_CONTRACT_SUCCESS = `${scope}/EMAIL_CONTRACT_SUCCESS`;
export const EMAIL_CONTRACT_ERROR = `${scope}/EMAIL_CONTRACT_ERROR`;

export const CLOSE_MONTH = `${scope}/CLOSE_MONTH`;
export const CLOSE_MONTH_SUCCESS = `${scope}/CLOSE_MONTH_SUCCESS`;
export const CLOSE_MONTH_ERROR = `${scope}/CLOSE_MONTH_ERROR`;

export const OPEN_MONTH_SALARY = `${scope}/OPEN_MONTH_SALARY`;
export const OPEN_MONTH_SALARY_SUCCESS = `${scope}/OPEN_MONTH_SALARY_SUCCESS`;
export const OPEN_MONTH_SALARY_ERROR = `${scope}/OPEN_MONTH_SALARY_ERROR`;
