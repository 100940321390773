import { FunctionComponent } from 'react';

import  styles from "./ChangePassword.module.scss";
import payshefLogoWide from 'assets/icons/payshefLogoWide.svg';
import { toast } from 'react-toastify';
import ChangePasswordFormComponent from './ChangePasswordForm.component';

const ChangePassword: FunctionComponent = (props) => {
    let errorMessage = localStorage.getItem('singleErrorMessage');
    if (errorMessage) {
        toast.error("Nuk jeni i kyçur!");
        localStorage.removeItem('singleErrorMessage');
    }

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <img className={styles.logo} src={payshefLogoWide} alt="Product logo" />
            </div>
            <div className={styles.right}>
                <ChangePasswordFormComponent />
            </div>
        </div>
    )
};

export default ChangePassword;
