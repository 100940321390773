import {
  SAVE_DOCUMENT,
  LOAD_ALL_DOCUMENTS, DELETE_SELECTED_DOCUMENT,
  UPDATE_TEMPLATE, DOWNLOAD_DOCUMENT_TEMPLATE, SEND_EMAIL_TEMPLATE,
} from 'pages/Documents/constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  saveDocumentsTemplate,
  loadAllDocuments,
  deleteSelectedDocumentsTemplate,
  updateDocument, downloadDocument, sendEmail,
} from 'services/documents.service';
import {
  saveDocumentSuccess,
  saveDocumentError,
  loadAllDocumentsTemplateSuccess,
  loadAllDocumentsTemplateError,
  deleteSelectedDocumentsSuccess,
  deleteSelectedDocumentsError,
  updateDocumentTemplateNameSuccess,
  updateDocumentTemplateNameError,
  downloadDocumentTemplateSuccess,
  downloadDocumentTemplateError, sendEmailTemplateSuccess, sendEmailTemplateError,
} from './actions';

export function* saveDocument(action) {
  try {
    yield call(saveDocumentsTemplate, action.payload.payload);
    yield put(saveDocumentSuccess());
  } catch (e) {
    yield put(saveDocumentError(e));
  }
}

export function* loadAllDocumentsTemplate(action) {
  try {
    const response = yield call(loadAllDocuments, action.payload.tenantId);
    yield put(loadAllDocumentsTemplateSuccess(response.data.data));
  } catch (e) {
    yield put(loadAllDocumentsTemplateError());
  }
}

export function* deleteSelectedDocuments(action) {
  try {
    yield call(deleteSelectedDocumentsTemplate, action.payload);
    yield put(deleteSelectedDocumentsSuccess());
  } catch (e) {
    yield put(deleteSelectedDocumentsError(e));
  }
}

export function* updateDocumentTemplateName(action) {
  try{
    yield call(updateDocument,action.payload.template);
    yield put(updateDocumentTemplateNameSuccess());
  }
  catch(e){
    yield put(updateDocumentTemplateNameError(e));
  }
}

export function* downloadDocumentTemplate(action){
  try{
    const response = yield call(downloadDocument, action.payload.payload);
    yield put(downloadDocumentTemplateSuccess(response.data.data))
  }catch(e){
    yield put(downloadDocumentTemplateError(e));
  }
}

export function* sendEmailTemplate(action){
  try{
 yield call(sendEmail, action.payload.emailBody);
     yield put(sendEmailTemplateSuccess())
  }catch(e){
    yield put(sendEmailTemplateError(e));
  }
}

export default function* documentsSaga() {
  yield takeLatest(SAVE_DOCUMENT, saveDocument);
  yield takeLatest(LOAD_ALL_DOCUMENTS,loadAllDocumentsTemplate);
  yield takeLatest(DELETE_SELECTED_DOCUMENT, deleteSelectedDocuments);
  yield takeLatest(UPDATE_TEMPLATE, updateDocumentTemplateName);
  yield takeLatest(DOWNLOAD_DOCUMENT_TEMPLATE, downloadDocumentTemplate);
  yield takeLatest(SEND_EMAIL_TEMPLATE, sendEmailTemplate);
}