import {
  SAVE_DOCUMENT,
  SAVE_DOCUMENT_SUCCESS,
  SAVE_DOCUMENT_ERROR,
  LOAD_ALL_DOCUMENTS,
  LOAD_ALL_DOCUMENTS_SUCCESS,
  LOAD_ALL_DOCUMENTS_ERROR,
  DELETE_SELECTED_DOCUMENT,
  DELETE_SELECTED_DOCUMENT_SUCCESS,
  DELETE_SELECTED_DOCUMENT_ERROR,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_ERROR,
  UPDATE_TEMPLATE_SUCCESS,
  DOWNLOAD_DOCUMENT_TEMPLATE,
  DOWNLOAD_DOCUMENT_TEMPLATE_ERROR,
  DOWNLOAD_DOCUMENT_TEMPLATE_SUCCESS,
  SEND_EMAIL_TEMPLATE,
  SEND_EMAIL_TEMPLATE_SUCCESS,
  SEND_EMAIL_TEMPLATE_ERROR,
} from './constants';
import {TemplateName} from '../../types/templateName.type';
import {DownloadDocument} from '../../types/downloadDocument.type';
import {EmailBody} from '../../types/emailBody.type';
import {Document} from '../../types/document.type';

export const saveDocument = (payload: FormData) => {
  return {
    type: SAVE_DOCUMENT,
    payload: {
      payload,
    },
  };
};

export const saveDocumentSuccess = () => {
  return {
    type: SAVE_DOCUMENT_SUCCESS,
    payload: {},
  };
};

export const saveDocumentError = (error) => {
  return {
    type: SAVE_DOCUMENT_ERROR,
    payload: {
      error
    },
  };
};

export const loadAllDocumentsTemplate = (payload) => {
  return {
    type: LOAD_ALL_DOCUMENTS,
    payload: {
      tenantId: payload
    },
  };
};

export const loadAllDocumentsTemplateSuccess = (documents: Array<Document>) => {
  return {
    type: LOAD_ALL_DOCUMENTS_SUCCESS,
    payload: {
      documents,
    },
  };
};

export const loadAllDocumentsTemplateError = () => {
  return {
    type: LOAD_ALL_DOCUMENTS_ERROR,
    payload: {},
  };
};

export const deleteSelectedDocuments = (documentIds: Array<number>) => {
  return {
    type: DELETE_SELECTED_DOCUMENT,
    payload: {
      documentIds,
    },
  };
};

export const deleteSelectedDocumentsSuccess = () => {
  return {
    type: DELETE_SELECTED_DOCUMENT_SUCCESS,
    payload: {},
  };
};

export const deleteSelectedDocumentsError = (error) => {
  return {
    type: DELETE_SELECTED_DOCUMENT_ERROR,
    payload: {
      error,
    },
  };
};

export const updateDocumentTemplateName = (template: TemplateName) => {
  return {
    type: UPDATE_TEMPLATE,
    payload: {
      template,
    },
  };
};

export const updateDocumentTemplateNameSuccess = () => {
  return {
    type: UPDATE_TEMPLATE_SUCCESS,
    payload: {},
  };
};

export const updateDocumentTemplateNameError = (error) => {
  return {
    type: UPDATE_TEMPLATE_ERROR,
    payload: {
      error,
    },
  };
};

export const downloadDocumentTemplate = (payload: DownloadDocument) => {
  return {
    type: DOWNLOAD_DOCUMENT_TEMPLATE,
    payload: {
      payload,
    },
  };
};

export const downloadDocumentTemplateSuccess = (downloadedFile: DownloadDocument) => {
  return {
    type: DOWNLOAD_DOCUMENT_TEMPLATE_SUCCESS,
    payload: {
      downloadedFile,
    },
  };
};

export const downloadDocumentTemplateError = (error) => {
  return {
    type: DOWNLOAD_DOCUMENT_TEMPLATE_ERROR,
    payload: {error},
  };
};

export const sendEmailTemplate = (emailBody: EmailBody) => {
  return {
    type: SEND_EMAIL_TEMPLATE,
    payload: {
      emailBody,
    },
  };
};

export const sendEmailTemplateSuccess = () => {
  return {
    type: SEND_EMAIL_TEMPLATE_SUCCESS,
    payload: {},
  };
};

export const sendEmailTemplateError = (error) => {
  return {
    type: SEND_EMAIL_TEMPLATE_ERROR,
    payload: {error},
  };
};