import React from 'react'
import styles from "./NavTab.module.scss";

type Props = {
  title: string | any;
  tabContentClass?: string;
}

const NavTab: React.FC<Props> = (props) => {
  return (
    <div className={`${styles.tabContent || ""} ${props.tabContentClass || ""}`}>
      {props.children}
    </div>
  );
}

export default NavTab;
