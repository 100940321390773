
import { CSSProperties, FunctionComponent } from "react";
import Loader from "components/partials/Loader/Loader.component";
import styles from "./LoaderCentered.module.scss";

interface Props {
    className?: string;
    style?: CSSProperties;
}

const LoaderCentered: FunctionComponent<Props> = (props) => {
    return <div className={styles.centered} style={props.style}>
        <Loader style={props.style}/>
    </div>
}

export default LoaderCentered;



