import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import styles from "./PreviewImportEmployeesModal.module.scss";
import Loader from "components/partials/Loader/Loader.component";
import LoaderCentered from "components/partials/LoaderCentered/LoaderCentered.component";
import {
	DraftStatus,
	Employee,
	EmployeeStatus,
	EmployeeToImport,
	EmployeeToImportColumnError,
	EmploymentTier,
	SalaryPaymentType,
} from "types/employee.type";
import Table from "components/Table/Table.component";
import tableStyles from "../../../components/Table/Table.module.scss";
import { employeeHolidays } from "utils/globalVariables";
import { ReactNode, useState } from "react";
import { IoTabletPortraitSharp } from "react-icons/io5";
import { number_format } from "utils/functions";
import CommonHeader from "components/partials/CommonHeader/CommonHeader.component";
import ReactTooltip from "react-tooltip";
import Button from "components/Button/Button.component";
import { FiUpload } from "react-icons/fi";
import { saveEmployee } from "services/employee.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { globalState } from "types/globalState.type";
import { fi } from "date-fns/locale";

type PreviewImportEmployeesModalProps = {
	importing: boolean;
	showModal: boolean;
	onCloseModal: (saved: boolean) => void;
	employees?: EmployeeToImport[];
};

const HeaderCell = ({ children }: { children: ReactNode }) => {
	return <th className={tableStyles.headerCell}>{children}</th>;
};


const formatDate = (date: string) => {
	const delimiter = getDelimiter(date);
	if (!delimiter?.length) return '';
	const dateArray = date.split(delimiter);
	if (dateArray?.length != 3) return date;
	return `${dateArray[1]}${delimiter}${dateArray[0]}${delimiter}${dateArray[2]}`;
}

const PreviewImportEmployeesTable = ({
	employees,
	onClose,
	onSave,
}: {
	employees: EmployeeToImport[];
	onClose: () => void;
	onSave: () => void;
}) => {
	const [errorsToShow, setErrorsToShow] = useState<
		EmployeeToImportColumnError[]
	>([]);

	return (
		<>
			<Modal
				open={errorsToShow.length > 0}
				styles={{
					modal: {
						minWidth: "100%",
						minHeight: "20%",
						display: "flex",
					},
				}}
				onClose={() => setErrorsToShow([])}
				classNames={{
					root: styles.modalRoot,
					overlay: styles.modalOverlay,
					modalContainer: styles.modalContainer,
					modal: styles.responsiveModal,
				}}
				closeOnOverlayClick={true}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						width: "100%",
					}}
				>
					<CommonHeader title={"Problemet"} />
					{errorsToShow.map((error, index) => {
						return (
							<p key={index} style={{ color: "red" }}>
								{error.error}
							</p>
						);
					})}
				</div>
			</Modal>
			<div className={`${tableStyles.tableWrapper}`} style={{ height: "80%" }}>
				<CommonHeader title={"Importimi i punetoreve"} />
				<div>
					<h4>Gjithsej Punëtorë: {employees.length}</h4>
					<h4>
						Gjithsej Punëtorë valid për importim:{" "}
						{employees?.filter((x) => x.isValid).length}
					</h4>
					{employees?.filter((x) => !x.isValid).length > 0 && (
						<h3 style={{ color: "red" }}>
							Vetëm punëtorët valid do të ruhen, nese dëshironi të rregulloni
							punëtoret jo valid, ju lutem kontrolloni problemet duke klikuar
							mbi rreshtat me ngjyrë te kuqe ,për detajet e problemeve!
						</h3>
					)}
				</div>
				<div className={`printHeightFix`}>
					<table className={`${tableStyles.table}`}>
						<thead className={tableStyles.tableHead}>
							<tr className={tableStyles.headerRow}>
								<HeaderCell>Emri</HeaderCell>
								<HeaderCell>Pozita</HeaderCell>
								<HeaderCell>Telefoni</HeaderCell>
								<HeaderCell>Email</HeaderCell>
								<HeaderCell>Paga bruto</HeaderCell>
								<HeaderCell>Paga neto</HeaderCell>
								<HeaderCell>Tatimi</HeaderCell>
								<HeaderCell>Kontributet</HeaderCell>
								<HeaderCell>Data e kontrates</HeaderCell>
								<HeaderCell>Adresa</HeaderCell>
								<HeaderCell>Sektori</HeaderCell>
								<HeaderCell>Banka</HeaderCell>
								<HeaderCell>Llogaria bankare</HeaderCell>
								<HeaderCell>Punëdhënësi</HeaderCell>
								<HeaderCell>Full Time/ Part Time/ Contractor</HeaderCell>
							</tr>
						</thead>
						<tbody className={tableStyles.tableBody}>
							{employees.map((employee, index) => {
								const additionalClassName = !employee.isValid ? "error" : "";
								return (
									<>
										<ReactTooltip
											place="right"
											type="dark"
											effect="solid"
											multiline={true}
											className="customTooltip"
										/>
										<tr
											key={index}
											className={tableStyles.tableRow}
											onClick={() => setErrorsToShow(employee.errors)}
											data-tip={employee.errors.map((x) => x.error).join(", ")}
										>
											<td
												className={`${tableStyles.bodyCell} pointer ${tableStyles.bold} ${additionalClassName}`}
											>
												{employee.name}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.jobTitle}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.phoneNumber}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.emailAddress}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.grossSalary &&
													number_format(employee.grossSalary, 2)}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.netSalary &&
													number_format(employee.netSalary, 2)}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.tax && number_format(employee.tax, 2)}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.contribution &&
													`${number_format(
														employee.contribution,
														2
													)} / ${number_format(employee.contribution, 2)}`}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>

												{formatDate(employee.contractDate)}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.address}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.class}
											</td>

											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.bank}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.bankAccountNumber}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.employerTier == EmploymentTier.Primary
													? "Primar"
													: "Sekondar"}
											</td>
											<td
												className={`${tableStyles.bodyCell} pointer ${additionalClassName}`}
											>
												{employee.employmentTypeString}
											</td>
										</tr>
									</>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className={styles.footer}>
				<Button
					className={`${styles.button} ${styles.close}`}
					onClickHandler={() => {
						onClose();
					}}
				>
					Mbyll
				</Button>
				<Button
					className={`${styles.button}`}
					onClickHandler={() => {
						onSave();
					}}
				>
					Ruaj
				</Button>
			</div>
		</>
	);
};

const getDelimiter = (date: string) => {
	let delimiter = '/';
	if (date?.includes('-')) {
		delimiter = '-';
	} else if (date?.includes('.')) {
		delimiter = '.';
	}
	return delimiter;
}

const PreviewImportEmployeesModal = ({
	importing,
	showModal,
	onCloseModal,
	employees,
}: PreviewImportEmployeesModalProps) => {
	const userId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);

	const [saving, setSaving] = useState(false);

	const onSave = async () => {
		setSaving(true);
		let failed = false;
		const validEmployees = employees.filter((x) => x.isValid);
		for (let i = 0; i < validEmployees.length; i++) {
			const employee = validEmployees[i];
			try {
				if (employee.isValid) {
					let contractDate = null;
					if (employee.contractDate?.length) {
						const delimiter = getDelimiter(employee?.contractDate);
						const date = employee.contractDate.split(delimiter);
						let year = date[2]?.length == 2 ? `20${date[2]}` : date[2];
						let month = date[1]?.length == 1 ? `0${date[1]}` : date[1];
						let day = date[0]?.length == 1 ? `0${date[0]}` : date[0];

						contractDate = `${year}-${day}-${month}`;
					}
					await saveEmployee({
						firstName: employee.firstName,
						lastName: employee.lastName,
						occupation: employee.jobTitle,
						phone: employee.phoneNumber,
						pricePerHour: 0,
						email: employee.emailAddress,
						employeeStatus: EmployeeStatus.Active,
						bruttoSalary: employee.grossSalary,
						nettoSalary: employee.netSalary,
						noTaxes: false,
						tax: employee.tax,
						contribution: employee.contribution,
						contributePercentage: employee.contributionPercentage,
						contractStarted: contractDate,
						bank: employee.bank,
						attachmentIds: [],
						bankAccountNumber: employee.bankAccountNumber,
						employerTier: employee.employerTier,
						unchangableBrutto: employee.grossSalary,
						personalNumber: employee.idNumber,
						address: employee.address,
						healthInsurancePercentage: null,
						healthInsuranceValue: null,
						healthInsurance: null,
						draftStatus: DraftStatus.Undrafted,
						employmentType: employee.employmentType,
						gender: employee.gender,
						familyMembers: [],
						userId,
						salaryPaymentType: SalaryPaymentType.Fixed,
						projectBudgetLines: [],
						sectors: employee.sectors,
					});
				}
			} catch (error) {
				failed = true;
				toast.error("Nuk u ruajt punetori " + employee.name);
				break;
			}
		}
		if (!failed) {
			toast.success("Punetoret u ruajten me sukses");
			onCloseModal(true);
		}

		setSaving(false);
	};

	return (
		<>
			<Modal
				open={showModal}
				styles={{
					modal: {
						minWidth: "100%",
						minHeight: "80%",
						display: "flex",
						alignItems: (importing || saving) && "center",
						justifyContent: "center",
						flexDirection: "column",
					},
				}}
				onClose={() => onCloseModal(false)}
				classNames={{
					root: styles.modalRoot,
					overlay: styles.modalOverlay,
					modalContainer: styles.modalContainer,
					modal: styles.responsiveModal,
				}}
				closeOnOverlayClick={false}
			>
				{importing || saving ? (
					<LoaderCentered style={{ width: "80px", height: "80px" }} />
				) : employees?.length ? (
					<PreviewImportEmployeesTable
						employees={employees}
						onClose={() => onCloseModal(false)}
						onSave={onSave}
					/>
				) : (
					<p>Nuk u gjet asnje punetor per importim</p>
				)}
			</Modal>
		</>
	);
};

export default PreviewImportEmployeesModal;
