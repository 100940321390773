/* eslint-disable */
import React from "react";
import PeriodDataNotFound from "components/PeriodDataNotFound";
import produce from "immer";
import {
  EXPORT_EXCEL_SALARIES,
  EXPORT_EXCEL_SALARIES_ERROR,
  EXPORT_EXCEL_SALARIES_SUCCESS,
  GENERATE_PAYSLIP,
  GENERATE_PAYSLIP_ERROR,
  GENERATE_PAYSLIP_SUCCESS,
  LOAD_SALARIES,
  LOAD_SALARIES_ERROR,
  LOAD_SALARIES_SUCCESS,
  UPDATE_SALARY,
  UPDATE_SALARY_ERROR,
  UPDATE_SALARY_SUCCESS,
  SET_MODAL_EDIT_MODE,
  SET_MODAL_OPEN,
  EMAIL_PAYSLIP,
  EMAIL_PAYSLIP_SUCCESS,
  EMAIL_PAYSLIP_ERROR,
  EMAIL_CONTRACT_ERROR,
  EMAIL_CONTRACT_SUCCESS,
  EMAIL_CONTRACT,
  CLOSE_MONTH,
  CLOSE_MONTH_SUCCESS,
  CLOSE_MONTH_ERROR,
  OPEN_MONTH_SALARY,
  OPEN_MONTH_SALARY_SUCCESS,
  OPEN_MONTH_SALARY_ERROR,
} from "./constants";
import { toast } from "react-toastify";
import { SalariesState } from "types/reducers/salary.reducer.type";

const initialState: SalariesState = {
  loading: false,
  submitLoading: false,
  excelButtonLoading: false,
  emailPayslipLoading: false,
  emailContractLoading: false,
  salaries: null,
  totals: null,
  showModal: false,
  showPayslip: false,
  payslipData: null,
  payslipButtonLoading: false,
  editMode: false,
  createdBy: null,
  closed: null,
  salariesConfig: null,
};

const calculationsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_MODAL_OPEN:
        draft.showModal = action.payload;
        if (!action.payload) {
          draft.payslipData = null;
          draft.showPayslip = false;
        }
        break;
      case SET_MODAL_EDIT_MODE:
        draft.editMode = action.payload;
        break;
      case LOAD_SALARIES:
        draft.loading = true;
        draft.salaries = null;
        break;
      case LOAD_SALARIES_SUCCESS:
        draft.salaries = action.payload.salaries.salaries || [];
        draft.totals = action.payload.salaries.totals || [];
        draft.createdBy = action.payload.salaries.createdBy;
        draft.closed = action.payload.salaries.closed;
        draft.salariesConfig = action.payload.config || [];
        draft.loading = false;
        (!action.payload.salaries.salaries ||
          !action.payload.salaries.salaries.length) &&
          toast.warn(PeriodDataNotFound);
        break;
      case LOAD_SALARIES_ERROR:
        draft.salaries = [];
        draft.loading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case UPDATE_SALARY:
        draft.submitLoading = true;
        break;
      case UPDATE_SALARY_SUCCESS:
        toast.success("Të dhënat u ruajtën me sukses!");
        draft.salaries = action.payload.salaries;
        draft.totals = action.payload.totals;
        draft.createdBy = action.payload.createdBy;

        draft.showModal = false;
        draft.submitLoading = false;
        draft.editMode = false;
        break;
      case UPDATE_SALARY_ERROR:
        toast.error("Diçka shkoi gabim!");
        draft.submitLoading = false;
        break;
      case EXPORT_EXCEL_SALARIES:
        draft.excelButtonLoading = true;
        break;
      case EXPORT_EXCEL_SALARIES_SUCCESS:
        draft.excelButtonLoading = false;
        action.payload?.data && window.open(action.payload?.data, "_blank");
        break;
      case EXPORT_EXCEL_SALARIES_ERROR:
        toast.error("Diçka shkoi gabim!");
        draft.excelButtonLoading = false;
        break;
      case GENERATE_PAYSLIP:
        draft.payslipButtonLoading = true;
        draft.showPayslip = false;
        draft.payslipData = null;
        break;
      case GENERATE_PAYSLIP_SUCCESS:
        draft.payslipData = action.payload;
        draft.payslipButtonLoading = false;
        draft.showPayslip = true;
        break;
      case GENERATE_PAYSLIP_ERROR:
        toast.error("Diçka shkoi gabim!");
        draft.payslipButtonLoading = false;
        draft.showPayslip = false;
        break;

      case EMAIL_PAYSLIP:
        draft.emailPayslipLoading = true;
        break;
      case EMAIL_PAYSLIP_SUCCESS:
        draft.emailPayslipLoading = false;
        toast.success("Fletëpagesat u dërguan në email me sukses!");
        break;
      case EMAIL_PAYSLIP_ERROR:
        toast.error("Diçka shkoi gabim!");
        draft.emailPayslipLoading = false;
        break;

      case EMAIL_CONTRACT:
        draft.emailContractLoading = true;
        break;
      case EMAIL_CONTRACT_SUCCESS:
        draft.emailContractLoading = false;
        if (action.payload?.data?.message === "Nuk ka dokument!") {
          toast.warning("Nuk ka dokument!");
        } else {
          toast.success("Dokumentet u dërguan në email me sukses!");
        }
        break;
      case EMAIL_CONTRACT_ERROR:
        toast.error("Diçka shkoi gabim!");
        draft.emailContractLoading = false;
        break;
      case CLOSE_MONTH:
        draft.emailContractLoading = true;
        break;
      case CLOSE_MONTH_SUCCESS:
        if (action.payload?.data === "Unauthenticated") {
          toast.error("Kodi i dhënë nuk është i saktë!");
        } else {
          draft.closed = 1;
          toast.success("Periudha u mbyll me sukses!");
          draft.emailContractLoading = false;
          draft.showModal = false;
        }
        break;
      case CLOSE_MONTH_ERROR:
        draft.emailContractLoading = false;
        toast.error("Mund të provoni përsëri pas 1 ore!");
        break;

      case OPEN_MONTH_SALARY:
        draft.emailContractLoading = true;
        break;
      case OPEN_MONTH_SALARY_SUCCESS:
        if (action.payload?.data === "Unauthenticated") {
          toast.error("Kodi i dhënë nuk është i saktë!");
        } else {
          draft.closed = null;
          toast.success("Periudha u hap me sukses!");
          draft.emailContractLoading = false;
          draft.showModal = false;
        }
        break;
      case OPEN_MONTH_SALARY_ERROR:
        draft.emailContractLoading = false;
        toast.error("Diqka shkoi gabim!");
        break;

      default:
        draft = initialState;
        break;
    }
  });

export default calculationsReducer;
