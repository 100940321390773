import { axiosInstanceV2, loginInstance } from "api";
import { axiosInstance } from "api";

export const logIn = (loginPayload) => {
  return loginInstance().post(`/users/login`, loginPayload);
};

export const getLoginCompanies = (roleId, email) => {
  return axiosInstance().get(
    `register/loginTenants?roleId=${roleId}&email=${email}`
  );
};

export const getUserByEmail = (email) => {
  return axiosInstance().get(`register/userByEmail?email=${email}`);
};

function generateDeviceId() {
  const browserDetails = [
    navigator.userAgent,
    navigator.language,
    window.screen.width,
    window.screen.height,
    new Date().getTimezoneOffset(),
  ].join(" ");

  // Use a simple hash function to create a unique identifier
  let hash = 0,
    i,
    chr;
  for (i = 0; i < browserDetails.length; i++) {
    chr = browserDetails.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return "device" + hash;
}

const getOrCreateDeviceId = (userId: number) => {
  const key = `deviceId-${userId}`;
  let deviceId = localStorage.getItem(key);
  if (!deviceId) {
    deviceId = generateDeviceId();
    localStorage.setItem(key, deviceId);
  }
  return deviceId;
};

export const userRequiresTwoFactorAuthentication = (userId: number) => {
  return axiosInstanceV2().post("/users/requires-two-factor", {
    userId,
    deviceId: getOrCreateDeviceId(userId),
  });
};

export const confirmTwoFactorAuthenticationCode = (
  userId: number,
  code: string
) => {
  return axiosInstanceV2().post("/users/two-factor-code/confirm", {
    userId,
    deviceId: getOrCreateDeviceId(userId),
    code,
  });
};
