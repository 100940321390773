import { FunctionComponent } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import styles from "./Backdrop.module.scss";
import { setBackdropState } from "components/AppWrapper/actions";

const Backdrop: FunctionComponent = () => {
    const appWrapperReducer = useSelector((state: RootStateOrAny) => state.appWrapperReducer);
    const showBackdrop = appWrapperReducer.showBackdrop;
    const backDrop_callback = (appWrapperReducer.backDrop_callback ? appWrapperReducer.backDrop_callback : function () {});

    const dispatch = useDispatch();
    const handleBackdropClick = () => {
        backDrop_callback();
        dispatch(setBackdropState({ showBackdrop: false }));
    }
    return <div
        className={`${styles.backdrop} ${showBackdrop ? "" : styles.hide}`}
        onClick={handleBackdropClick}
    ></div>
}

export default Backdrop;