export function formatNumber_(_number: any = 0, _decimal: any = false, _fixed = false, allowEmpty = false) {
	var number: any = 0, decimal = 0;
	if (typeof _number === "number") number = _number;
	else if (typeof _number === "string") {
		number = Number(_number.replace(/,/g, ""));
		if (isNaN(number)) number = 0;
	}

	if (typeof _decimal === "number") decimal = _decimal;
	else if (typeof _decimal === "string") {
		decimal = Number(_decimal);
		if (isNaN(decimal)) decimal = 0;
	}

	if (!_decimal) decimal = 0;

	if (_fixed) {
		if (_decimal === false) {
			number = number.toLocaleString();
		} else {
			if (number.toLocaleString().includes(".")) {
				number = number.toFixed(decimal).toLocaleString();
			} else {
				if (decimal > 0) {
					number = number.toLocaleString() + ".";
					for (var i = 0; i < decimal; i++) {
						number += "0";
					}
				} else {
					number = number.toLocaleString();
				}
			}
		}
	} else {
		if (_decimal === false) {
			number = _number;
		} else {
			number = +number.toFixed(decimal);
		}
	}

	if (_number !== 0 && !_number && allowEmpty) {
		return "";
	} else {
		return number;
	}
}

export function number_format(number, decimals, dec_point = ".", thousands_sep = ",") {
	number = (+number).toFixed(decimals);
	var nstr = number.toString();
	nstr += '';
	var x = nstr.split('.');
	var x1 = x[0];
	var x2 = x.length > 1 ? dec_point + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1))
		x1 = x1.replace(rgx, '$1' + thousands_sep + '$2');
	return x1 + x2 !== "NaN" ? x1 + x2 : "0";
}

export function generateArrayOfYears(till: number = 21, plusOne: boolean = false) {
	let max = new Date().getFullYear()
	if (plusOne) max += 1;
	let min = max - till;
	let years = [];
	for (var i = max; i >= min; i--) { years.push(i) }
	return years;
}

export const toTitleCase = (phrase) => {
	return phrase
		.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
}

export const parseNumberCustom = (number) => {
	let result = null;
	if (number === null) {
		result = "";
	} else if (number === 0) {
		result = 0;
	} else {
		result = number;
	}

	return result;
}