import ModalCustom from "components/ModalCustom/ModalCustom.component";

const UnsyncedEmployeesWithQuickbooksConfirmationModal = ({ showModal, onClose, onConfirmed, confirming, employees }) => {

	return (
		<ModalCustom
			show={showModal}
			onClose={onClose}
			onSubmit={onConfirmed}
			submitLoading={confirming}
			showSubmitButton
			showRejectButton
			header={
				<div>
					<div>Punëtorët e pa sinkronizuar ne Quickbooks</div>
				</div>
			}
			submitText={'Konfirmo'}>
			<div>
				{employees.map((employee, index) => (
					<div key={employee.id}>
						<h2>{index + 1}. {employee.first_name} {employee.last_name}</h2>
					</div>
				))}
			</div>
			<h4>A deshironi te vazhdoni me kontimin(kontimi nuk do te perfshije punetoret me larte pasi qe nuk jane sinkronizuar ne quickbooks) ?</h4>
		</ModalCustom>
	)
}

export default UnsyncedEmployeesWithQuickbooksConfirmationModal;