import { actionChannel } from "redux-saga/effects";
import {
	LOG_IN,
	LOG_IN_SUCCESS,
	LOG_IN_FAIL,
	LOG_IN_ERROR,
	LOG_OUT,
	RESET_FAIL_MESSAGE,
	SET_GLOBAL_TENANT_ID,
	SET_EMAIL,
	GET_USER_BY_EMAIL,
	GET_USER_BY_EMAIL_SUCCESS,
	GET_USER_BY_EMAIL_ERROR,
	SET_IS_LOGGED_IN,
	SET_TOKEN,
	GET_NOTIFICATIONS, GET_NOTIFICATIONS_ERROR,
	GET_NOTIFICATIONS_SUCCESS,
	SET_LOGO,
	SET_GLOBAL_HAS_QUICKBOOKS
} from "./constants";


export const logIn = (userName: string, password: string) => {
	return {
		type: LOG_IN,
		payload: {
			userName,
			password
		}
	}
}

export const setGlobalTenantId = (payload) => {
	return {
		type: SET_GLOBAL_TENANT_ID,
		payload
	}
}

export const setGlobalHasQuickbooks = (payload) => {
	return {
		type: SET_GLOBAL_HAS_QUICKBOOKS,
		payload
	}
}

export const setLogo = (payload) => {
	return {
		type: SET_LOGO,
		payload
	}
}

export const setEmail = (payload) => {
	return {
		type: SET_EMAIL,
		payload
	}
}

export const setIsLoggedIn = (payload) => {
	return {
		type: SET_IS_LOGGED_IN,
		payload
	}
}

export const setToken = (payload) => {
	return {
		type: SET_TOKEN,
		payload
	}
}

export const logInSuccess = (payload) => {
	return {
		type: LOG_IN_SUCCESS,
		payload: payload,
	}
}

export const logInFailed = (payload) => {
	return {
		type: LOG_IN_FAIL,
		payload: payload,
	}
}

export const logInError = (error) => {
	return {
		type: LOG_IN_ERROR,
		payload: {
			error
		}
	}
}
export const logOut = () => {
	return {
		type: LOG_OUT,
		payload: {
		}
	}
}

export const resetFailMessage = () => {
    return {
        type: RESET_FAIL_MESSAGE,
        payload: {}
    }
}

export const getNotifications = (tenantId: number) => {
    return {
        type: GET_NOTIFICATIONS,
        payload: {
            tenantId
        }
    }
}

export const getNotificationsSuccess = (notifications: Notification[]) => {
    return {
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: {
        	notifications
		}
    }
}

export const getNotificationsError = () => {
    return {
        type: GET_NOTIFICATIONS_ERROR,
    }
}

export const getUserByEmail = (payload) => {
	return {
		type: GET_USER_BY_EMAIL,
		payload: {
			email: payload
		}
	}
}
export const getUserByEmailSuccess = (payload) => {
	return {
		type: GET_USER_BY_EMAIL_SUCCESS,
		payload
	}
}
export const getUserByEmailError = (payload) => {
	return {
		type: GET_USER_BY_EMAIL_ERROR,
	}
}
