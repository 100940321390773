import { useTranslation } from "react-i18next";
import produce from "immer";
import { toast } from "react-toastify";
import { EmployeesReducer } from "types/reducers/employees.reducer.type";

import * as constants from "./constants";

const initialState: EmployeesReducer = {
  loading: false,
  exportButtonLoading: false,
  submitButtonLoading: false,
  deleteButtonLoading: false,
  loadingDraftButton: false,
  allEmployees: null,
  inactiveEmployees: null,
  onProcessEmployees: null,
  activeTab: null,
  showEmployeeModal: false,
  modalEmployeeSelected: { id: 0 },
  modalEmployeeData: {},
  activeEmployeesCount: null,
  inactiveEmployeesCount: null,
  onProcessEmployeesCount: null,
  pickedFiles: [],
  showAttachmentsModal: false,
  attachmentModalName: "",
  unchangableBrutto: null,
  unchangableNetto: null,
  isAddEmployeeOn: false,
  initialBrutto: null,
};

const employeesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case constants.LOAD_EMPLOYEES_ALL:
        draft.loading = true;
        draft.activeTab = 1;
        break;
      case constants.LOAD_EMPLOYEES_ALL_SUCCESS:
        draft.allEmployees = action.payload.allEmployees;
        draft.inactiveEmployees = [];
        draft.onProcessEmployees = [];
        draft.loading = false;
        break;
      case constants.LOAD_EMPLOYEES_INACTIVE:
        draft.loading = true;
        draft.activeTab = 2;
        break;
      case constants.LOAD_EMPLOYEES_INACTIVE_SUCCESS:
        draft.allEmployees = [];
        draft.inactiveEmployees = action.payload.inactiveEmployees;
        draft.onProcessEmployees = [];
        draft.loading = false;
        break;
      case constants.LOAD_EMPLOYEES_ON_PROCESS:
        draft.loading = true;
        draft.activeTab = 3;
        break;
      case constants.LOAD_EMPLOYEES_ON_PROCESS_SUCCESS:
        draft.allEmployees = [];
        draft.inactiveEmployees = [];
        draft.onProcessEmployees = action.payload.onProcessEmployees;
        draft.loading = false;
        break;
      case constants.LOAD_EMPLOYEES_ALL_ERROR:
        draft.allEmployees = [];
        draft.loading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case constants.LOAD_EMPLOYEES_INACTIVE_ERROR:
        draft.inactiveEmployees = [];
        draft.loading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case constants.LOAD_EMPLOYEES_ON_PROCESS_ERROR:
        draft.onProcessEmployees = [];
        draft.loading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case constants.EXPORT_EMPLOYEES:
        draft.exportButtonLoading = true;
        break;
      case constants.EXPORT_EMPLOYEES_SUCCESS:
        draft.exportButtonLoading = false;
        window.open(action.payload.data, "_blank");
        break;
      case constants.EXPORT_EMPLOYEES_ERROR:
        draft.exportButtonLoading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case constants.DELETE_EMPLOYEE:
        draft.deleteButtonLoading = true;
        break;
      case constants.DELETE_EMPLOYEE_SUCCESS:
        draft.deleteButtonLoading = false;
        draft.showEmployeeModal = false;
        toast.success("Punëtori u fshi me sukses!");
        break;
      case constants.DELETE_EMPLOYEE_ERROR:
        draft.deleteButtonLoading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case constants.TOGGLE_MODAL_STATE:
        draft.showEmployeeModal = action.payload.state;
        action.payload.state &&
          (draft.modalEmployeeSelected.id = action.payload?.row.id);
        break;
      case constants.SUBMIT_EMPLOYEE_DATA:
        draft.loadingDraftButton = action.loadingDraftButton;
        draft.submitButtonLoading = !action.loadingDraftButton;
        break;
      case constants.SUBMIT_EMPLOYEE_DATA_SUCCESS:
        toast.success("Punëtori u krijua me sukses!");
        draft.submitButtonLoading = false;
        draft.loadingDraftButton = false;
        draft.showEmployeeModal = false;
        draft.unchangableBrutto = null;
        draft.unchangableNetto = null;
        draft.isAddEmployeeOn = null;
        draft.initialBrutto = null;
        break;
      case constants.SUBMIT_EMPLOYEE_DATA_ERROR:
        draft.submitButtonLoading = false;
        draft.loadingDraftButton = false;

        if (typeof action.payload === "string") {
          toast.error(action.payload);
        } else {
          if (action.payload?.response?.data?.errors) {
            Object.entries(action.payload?.response?.data?.errors).forEach(
              (error) => {
                error[1][0] && toast.error(error[1][0]);
              }
            );
          } else {
            toast.error("Diçka shkoi gabim!");
          }
        }

        break;
      case constants.SUBMIT_EMPLOYEE_EDIT:
        draft.submitButtonLoading = true;
        break;
      case constants.SUBMIT_EMPLOYEE_EDIT_SUCCESS:
        toast.success("Të dhënat u ruajtën me sukses!");
        draft.submitButtonLoading = false;
        draft.showEmployeeModal = false;
        break;
      case constants.SUBMIT_EMPLOYEE_EDIT_ERROR:
        draft.submitButtonLoading = false;
        if (action.payload?.response?.data?.errors) {
          Object.entries(action.payload?.response?.data?.errors).forEach(
            (error) => {
              error[1][0] && toast.error(error[1][0]);
            }
          );
        } else {
          toast.error("Diçka shkoi gabim!");
        }
        break;
      case constants.LOAD_EMPLOYEES_TAB_NUMBER:
        switch (action.payload.tabIndex) {
          case 0:
            draft.activeEmployeesCount = null;
            draft.inactiveEmployeesCount = null;
            draft.onProcessEmployeesCount = null;
            break;
          case 1:
            draft.activeEmployeesCount = null;
            break;
          case 2:
            draft.inactiveEmployeesCount = null;
            break;
          case 3:
            draft.onProcessEmployeesCount = null;
            break;
          default:
            break;
        }

        break;
      case constants.LOAD_EMPLOYEES_TAB_NUMBER_SUCCESS:
        switch (action.payload.status) {
          case 0:
            draft.activeEmployeesCount = action.payload.count;
            draft.inactiveEmployeesCount = action.payload.count;
            draft.onProcessEmployeesCount = action.payload.count;
            break;
          case 1:
            draft.activeEmployeesCount = action.payload.count;
            break;
          case 2:
            draft.inactiveEmployeesCount = action.payload.count;
            break;
          case 3:
            draft.onProcessEmployeesCount = action.payload.count;
            break;
          default:
            break;
        }

        break;
      case constants.LOAD_EMPLOYEES_TAB_NUMBER_ERROR:
        switch (action.payload.tabIndex) {
          case 0:
            draft.activeEmployeesCount = null;
            draft.inactiveEmployeesCount = null;
            draft.onProcessEmployeesCount = null;
            break;
          case 1:
            draft.activeEmployeesCount = null;
            break;
          case 2:
            draft.inactiveEmployeesCount = null;
            break;
          case 3:
            draft.onProcessEmployeesCount = null;
            break;
          default:
            break;
        }

        break;
      case constants.SEARCH_EMPLOYEES:
        draft.loading = true;
        break;
      case constants.SEARCH_EMPLOYEES_SUCCESS:
        draft.loading = false;
        switch (action.payload.status) {
          case 1:
            draft.allEmployees = action.payload.data;
            break;
          case 2:
            draft.inactiveEmployees = action.payload.data;
            break;
          case 3:
            draft.onProcessEmployees = action.payload.data;
            break;
        }
        break;
      case constants.SEARCH_EMPLOYEES_ERROR:
        draft.loading = false;
        break;
      case constants.GET_EMPLOYEE_BY_ID:
        break;
      case constants.GET_EMPLOYEE_BY_ID_SUCCESS:
        draft.modalEmployeeSelected = action.payload.data;
        break;
      case constants.GET_EMPLOYEE_BY_ID_ERROR:
        break;
      case constants.CLEAR_EMPLOYEE_MODAL_DATA:
        draft.modalEmployeeSelected = { id: 0 };
        break;
      case constants.SET_ACTIVE_TAB:
        draft.activeTab = action.payload.index;
        break;
      case constants.SAVE_DRAFT:
        break;

      case constants.UPLOAD_MULTIPLE_FILES:
        draft.filePickerLoading = true;
        break;
      case constants.UPLOAD_MULTIPLE_FILES_SUCCESS:
        draft.filePickerLoading = false;
        const newFiles = [...state.pickedFiles];
        newFiles.push(action.payload.data.value);
        draft.pickedFiles = newFiles;
        break;
      case constants.UPLOAD_MULTIPLE_FILES_ERROR:
        draft.pickedFiles = null;
        draft.filePickerLoading = false;
        break;

      case constants.DELETE_UPLOADED_ATTACHMENT:
        break;
      case constants.DELETE_UPLOADED_ATTACHMENT_SUCCESS:
        draft.pickedFiles = state.pickedFiles?.filter((item) => {
          return item.attachmentId !== action.payload.attachmentId;
        });
        break;
      case constants.DELETE_UPLOADED_ATTACHMENT_ERROR:
        toast.error("Diçka shkoi gabim!");
        break;
      case constants.REMOVE_ATTACHMENTS:
        draft.pickedFiles = initialState.pickedFiles;
        break;
      case constants.SET_ATTACHMENTS:
        draft.pickedFiles = state.pickedFiles.concat(action.payload);
        break;
      case constants.SHOW_EMPLOYEE_ATTACHMENTS:
        draft.showAttachmentsModal = action.payload.show;
        draft.attachmentModalItems = action?.payload?.row?.attachments;
        draft.attachmentModalName =
          action?.payload?.row?.firstName +
          " " +
          action?.payload?.row?.lastName;
        break;
      case constants.SET_UNCHANGABLE_BRUTTO:
        draft.unchangableBrutto = action.payload;
        break;
      case constants.SET_UNCHANGABLE_NETTO:
        draft.unchangableNetto = action.payload;
        break;

      case constants.SET_IS_ADD_EMPLOYEE_ON:
        draft.isAddEmployeeOn = action.payload;
        break;

      case constants.GET_INITIAL_BRUTTO:
        break;
      case constants.GET_INITIAL_BRUTTO_SUCCESS:
        draft.initialBrutto = action.payload?.data?.unchangable_brutto;
        break;
      case constants.GET_INITIAL_BRUTTO_ERROR:
        break;
      case constants.ADD_EMPLOYEE_QB:
        toast.info("Kontimi është duke u procesuar!");
        break;
      case constants.ADD_EMPLOYEE_QB_SUCCESS:
        toast.success("Kontimi është përfundoi me sukses!");
        break;
      case constants.ADD_EMPLOYEE_QB_ERROR:
        toast.error("Dicka shkoi gabim.");
        break;
      case constants.DELETE_EMPLOYEE_PROJECT:
        break;
      case constants.DELETE_EMPLOYEE_PROJECT_SUCCESS:
        toast.success("Projekti është fshirë me sukses!");
        break;
      case constants.DELETE_EMPLOYEE_PROJECT_ERROR:
        toast.error("Projekti nuk është fshirë");
        break;
      default:
        break;
    }
  });

export default employeesReducer;
