import { FunctionComponent, useEffect, useRef, useState } from "react";
import { IoImageOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import InputCustom from "components/InputCustom/InputCustom.component";
import styles from "../EmployeeModal/EmployeeModal.module.scss";
import { Employee } from "types/employee.type";
import { toTitleCase } from "utils/functions";
import { doNothing } from "utils/globalVariables";
import ImageElement from "components/ImageElement/ImageElement.component";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface Props {
	editMode: boolean;
	modalEmployeeInputs: Employee;
	modalEmployeeSelected: Employee;
	setModalEmployeeInputs: Function;
	handleModalEmployeeInputsChange: Function;
}

const EmployeeNameAndImage: FunctionComponent<Props> = (props: Props) => {
	const { t, i18n } = useTranslation("common");
	const attachmentFileInput = useRef(null);
	const [rawFile, setRawFile] = useState(null);
	const [isAttachmentFilePicked, setIsAttachmentFilePicked] = useState(false);
	const isEmployeeSelected = props.modalEmployeeSelected?.id > 0;
	const handleEmployeeInfoInputFileChange = (event) => {
		const mbLimit = 999;
		const file = event.target.files[0];
		const mbSize = file.size / (1024 * 1024);
		if (mbSize > mbLimit) {
			toast.error(t("toast.fileExceeded"));
		} else {
			const fr = new FileReader();
			fr.readAsDataURL(event.target.files[0]);
			setRawFile(file);
			fr.onload = function (e) {
				setIsAttachmentFilePicked(true);
			};
		}
	};

	const handleNameChange = (event) => {
		const value = event?.target?.value?.trimStart();

		let nameCount = value.split(" ").length;

		nameCount <= 3 &&
			props.setModalEmployeeInputs((prevState) => ({
				...prevState,
				[event.target.name]: value,
			}));

		if (event.target?.value) {
			let firstName = value.split(" ")[0];
			let lastName = value.split(" ")[1];

			if (
				event.target?.value.split(" ").length > 1 &&
				firstName.length &&
				!!lastName &&
				lastName.length &&
				nameCount <= 3
			) {
				event.target.parentNode.classList.remove("inputInvalid");
			} else {
				event.target.parentNode.classList.add("inputInvalid");
			}
		}
	};

	const attachmentButtonOnClick = (event) => {
		attachmentFileInput.current?.current &&
			attachmentFileInput.current.current.click();
	};
	const imageLink = props?.modalEmployeeInputs?.photo
		? typeof props?.modalEmployeeInputs?.photo === "string"
			? props?.modalEmployeeInputs?.photo
			: rawFile
				? URL.createObjectURL(rawFile)
				: ""
		: "";

	useEffect(() => {
		props.setModalEmployeeInputs &&
			props.setModalEmployeeInputs((prevState) => {
				return {
					...prevState,
					photo: rawFile,
				};
			});
	}, [rawFile]);

	const imageClasses = classNames({
		[styles.unset]:
			!isAttachmentFilePicked && !props?.modalEmployeeInputs?.photo,
		[styles.image]: true,
		pointer: props.editMode,
	});

	const noAlternative = !isEmployeeSelected
		? false
		: !isAttachmentFilePicked && !props?.modalEmployeeInputs?.photo;

	return (
		<div className={`${styles.row} ${styles.mainRow}`}>
			<div
				className={imageClasses}
				onClick={props.editMode ? attachmentButtonOnClick : doNothing}
			>
				<InputCustom
					onChangeHandler={handleEmployeeInfoInputFileChange}
					className={`${styles.rowInput} ${styles.attachmentRowInput}`}
					type="file"
					ref={attachmentFileInput}
					attributes={{ accept: "image/*" }}
					style={{ visibility: "hidden" }}
				/>
				<div className={`${styles.uploadImageContainer}`}>
					{isAttachmentFilePicked || props?.modalEmployeeInputs?.photo ? (
						<ImageElement
							imageLink={imageLink}
							attributes={{
								title: props?.modalEmployeeInputs?.photo,
							}}
							noAlternative={noAlternative}
						/>
					) : (
						<>
							<IoImageOutline />
							<div className={`${styles.uploadImageContainer}`}>
								<div className={styles.plusIcon}>+</div>
							</div>
						</>
					)}
				</div>
			</div>

			<div className={`${styles.row} ${styles.nameRow}`}>
				<InputCustom
					onChangeHandler={handleNameChange}
					inputClass={`${styles.title} ${styles.rowInput} `}
					name="firstName"
					placeholder="Shto Emrin dhe Mbiemrin"
					value={props.modalEmployeeInputs?.firstName || ""}
					readOnly={!props.editMode}
					className={styles.nameInput}
					isRequired={props.editMode}
					validationText={"Shkruani Emrin dhe Mbiemrin"}
					specificValidation={true}
					onBlur={(event) => {
						if (event.target?.value) {
							let firstName = event.target.value.split(" ")[0];
							let lastName = event.target.value.split(" ")[1];

							if (
								event.target?.value.split(" ").length > 1 &&
								firstName.length &&
								!!lastName &&
								lastName.length
							) {
								event.target.parentNode.classList.remove("inputInvalid");
							} else {
								event.target.parentNode.classList.add("inputInvalid");
							}
						}
					}}
					onKeyUp={(event) => {
						if (event.target?.value) {
							let firstName = event.target.value.split(" ")[0];
							let lastName = event.target.value.split(" ")[1];

							if (
								event.target?.value.split(" ").length > 1 &&
								firstName.length &&
								!!lastName &&
								lastName.length
							) {
								event.target.parentNode.classList.remove("inputInvalid");
							}
						}
					}}
				/>
			</div>
		</div>
	);
};

export default EmployeeNameAndImage;
