import { FunctionComponent, MouseEventHandler } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import styles from "./ThreeDots.module.scss";

interface threeDotsProps {
    onClickHandler?: MouseEventHandler;
    className?: string;
}

export const ThreeDots: FunctionComponent<threeDotsProps> = (props) => {
    return (
        <div className={`${styles.threeDots} ${props.className}`} onClick={props.onClickHandler}>
            <BsThreeDots />
        </div>
    );
}
