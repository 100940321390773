import Table from "components/Table/Table.component";
import * as tableStyles from "components/Table/Table.module.scss";
import styles from "pages/Configurations/Configurations.module.scss";
import { ConfigurationsState } from "types/reducers/configurations.reducer.type";
import { globalState } from "types/globalState.type";
import { FunctionComponent, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions";
import SearchInput from "components/SearchInput/SearchInput.component";
import { FiTrash2 } from "react-icons/fi";
import { IconPencil } from "assets/icons/icons";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import InputCustom from "components/InputCustom/InputCustom.component";
import CustomSelect from "components/CustomSelect/CustomSelect.component";
import classNames from "classnames";
import {
	activeInactiveOptions,
	customSelectStyles,
} from "utils/globalVariables";
import DropdownIndicator from "components/DropdownIndicator/DropdownIndicator.component";

const CompaniesTab: FunctionComponent = () => {
	const tableColumns = [
		"Kompania",
		"Email",
		"Fillimi i kontratës",
		"Skadimi i kontratës",
		"Statusi",
		"",
	];

	const dispatch = useDispatch();
	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);
	const state: ConfigurationsState = useSelector(
		(state: globalState) => state.configurationsReducer
	);
	const { showModal, modalOpener, editMode } = state;

	const sidebarCollapsed: boolean = useSelector(
		(state: globalState) => state.appWrapperReducer.sidebarMenuCollapsed
	);
	const allTenants = state.allTenants;

	const [passwordShown, setPasswordShown] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] =
		useState<boolean>(false);
	const [isDeleteCompanyModalOpen, setIsDeleteCompanyModalOpen] =
		useState<boolean>(false);
	const [companyIdToDelete, setCompanyIdToDelete] = useState<number>();
	const [companyIdToEdit, setCompanyIdToEdit] = useState<number>();

	const tenantById = state.tenant;

	const [companyStatus, setCompanyStatus] = useState(null);

	const [companyData, setCompanyData] = useState(null);

	const initialLoad = () => {
		dispatch(actions.getAllTenants(tenantId));
	};

	const iconPencilStyle = {
		fill: "#89c732",
		width: "20px",
		height: "20px",
	};

	useEffect(() => {
		initialLoad();
	}, []);

	const deleteCompanyModal = (companyId) => {
		setIsDeleteCompanyModalOpen(true);
		setCompanyIdToDelete(companyId);
	};

	const handleDeleteCompany = (companyId) => {
		dispatch(actions.deleteCompany(companyId));
		setIsDeleteCompanyModalOpen(false);
		dispatch(actions.getAllTenants(tenantId));
	};

	const editCompanyModal = (companyId) => {
		setIsEditCompanyModalOpen(true);
		setCompanyIdToEdit(companyId);
		dispatch(actions.getOneTenant(companyId));
	};

	useEffect(() => {
		setCompanyData(tenantById);
	}, [tenantById]);

	useEffect(() => {
		setCompanyStatus(activeInactiveOptions[companyData?.statusId - 1]);
	}, [companyData]);

	const companyDataChangeHandler = (event) => {
		setCompanyData((previous) => {
			return {
				...previous,
				[event.target.name]: event.target.value,
				"checkedQb": event.target.checked
			};
		});
	};

	const setInputEditMode = (event) => {
		event.stopPropagation();
	};

	const modalMainClasses = classNames({
		[styles.modalMainStyle]: true,
		[styles.sidebarCollapsed]: sidebarCollapsed,
	});

	const modalsubmitHandler = () => {
		dispatch(actions.updateTenant(companyData));
		setIsEditCompanyModalOpen(false);
		dispatch(actions.getAllTenants(tenantId));
	};

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	return (
		<div>
			<div className={styles.search}>
				<SearchInput
					className={styles.searchInputContainer}
					onChangeHandler={(event) => {
						setSearchTerm(event.target.value);
					}}
					searchPlaceholder="Kërko"
				/>
			</div>
			<Table
				tableHeader={
					<thead className={`${tableStyles.tableHead}`}>
						<tr className={`${tableStyles.headerRow} `}>
							{tableColumns.map((item, index) => {
								return (
									<th className={`${tableStyles.headerCell}`} key={index}>
										{item}
									</th>
								);
							})}
						</tr>
					</thead>
				}
				tableWrapperClass={styles.tableWrapper}
				data={[]}
			>
				<tbody className={styles.tableBody}>
					{allTenants?.length > 0 &&
						allTenants
							.filter((val) => {
								if (searchTerm === "") {
									return val;
								} else if (
									val.tenantName
										.toLowerCase()
										.includes(searchTerm.toLowerCase())
								) {
									return val;
								}
							})
							.map((tenant, idx) => {
								return (
									<tr className={styles.tableRow} key={idx}>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											{tenant?.tenantName}
										</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											{tenant?.email}
										</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											{tenant?.registrationDate}
										</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											{tenant?.expirationDate}
										</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											{tenant?.status}
										</td>
										<td style={{ width: "100px" }}>
											<div className={styles.pencilIcon}>
												<div
													className={styles.pencil}
													onClick={() => editCompanyModal(tenant.id)}
												>
													<IconPencil fill="#89C732" />
												</div>
												<div>
													<FiTrash2
														onClick={() => deleteCompanyModal(tenant.id)}
													/>
												</div>
											</div>
										</td>
									</tr>
								);
							})}
				</tbody>
			</Table>

			{isDeleteCompanyModalOpen ? (
				<ModalCustom
					show={isDeleteCompanyModalOpen}
					contentClass={styles.contentClass}
					footerClass={styles.footerClass}
					onClose={() => {
						setIsDeleteCompanyModalOpen((prev) => false);
					}}
					hideFooterButton={false}
					submitText={"Po"}
					onSubmit={() => handleDeleteCompany(companyIdToDelete)}
				>
					<div className={styles.deleteCompanyMessage}>
						Jeni të sigurtë që doni të fshini këtë kompani ?
					</div>
				</ModalCustom>
			) : null}

			{isEditCompanyModalOpen && (
				<ModalCustom
					show={isEditCompanyModalOpen}
					contentClass={styles.modalContent}
					footerClass={styles.footerClass}
					modalMainStyle={modalMainClasses}
					onClose={() => {
						setIsEditCompanyModalOpen((prev) => false);
					}}
					onSubmit={modalsubmitHandler}
					header={
						<div className={styles.modalHeader}>
							<div className={styles.modalTitle}>Edito kompani</div>
						</div>
					}
				>
					<div className={styles.inputContainer}>
						<div className={styles.inputBox}>
							<InputCustom
								className={styles.modalInput}
								placeholder="Emri i kompanisë"
								name="tenantName"
								value={companyData?.tenantName}
								onChangeHandler={companyDataChangeHandler}
							/>
						</div>
					</div>
					<div className={styles.inputContainer}>
						<div className={styles.inputBox}>
							<InputCustom
								className={styles.modalInput}
								placeholder="Email"
								name="email"
								value={companyData?.email}
								onChangeHandler={companyDataChangeHandler}
							/>
						</div>
					</div>
					<div className={styles.inputContainer}>
						<div className={styles.inputBox}>
							<InputCustom
								className={`${styles.modalInput} noArrowsNumberInput`}
								placeholder="NUI"
								name="businessNumber"
								value={companyData?.businessNumber}
								type="number"
								onChangeHandler={companyDataChangeHandler}
							/>
						</div>
					</div>
					<div className={styles.inputContainer}>
						<div className={styles.inputBox}>
							<CustomSelect
								options={activeInactiveOptions}
								className={`${styles.customSelect}`}
								name="statusId"
								onChangeHandler={(value) => setCompanyStatus((prev) => value)}
								selected={companyStatus}
								placeholder="Zgjedh opsionin"
								wideMenu={true}
								components={{ DropdownIndicator }}
								{...customSelectStyles}
								containerStyle={{
									width: "100%",
								}}
								controlStyle={{
									...customSelectStyles.controlStyle,
									width: "100%",
								}}
								valueContainerStyle={{
									...customSelectStyles.valueContainerStyle,
									flex: 1,
								}}
								singleValueStyle={{
									fontWeight: "500",
									paddingLeft: "0.5rem",
									fontSize: "18px",
									opacity: ".85",
								}}
							/>
						</div>
					</div>
					<div className={styles.inputContainer}>
						<div className={styles.inputBox}>
							<InputCustom
								className={styles.modalInput}
								placeholder="Fillimi i kontratës"
								name="registrationDate"
								type="date"
								value={companyData?.registrationDate}
								onChangeHandler={companyDataChangeHandler}
							></InputCustom>
						</div>
					</div>
					<div className={styles.inputContainer}>
						<div className={styles.inputBox}>
							<InputCustom
								className={styles.modalInput}
								placeholder="Skadimi i kontratës"
								name="expirationDate"
								type="date"
								value={companyData?.expirationDate}
								onChangeHandler={companyDataChangeHandler}
							></InputCustom>
						</div>
					</div>

					<div className={styles.inputContainer}>
						<div className={styles.inputBox}
						>
							<InputCustom
								className={styles.modalInput}
								placeholder="Fjalëkalimi për mbyllje të periudhës"
								name="code"
								value={companyData?.code}
								onChangeHandler={companyDataChangeHandler}
							></InputCustom>
						</div>
					</div>

					<div className={styles.inputContainer}>
						<div className={styles.qbContainer}>
							<div className={styles.qbCheckbox}>
								<input type="checkbox" name="checkedQb" checked={companyData?.checkedQb} onChange={(checked) => companyDataChangeHandler(checked)} />
							</div>
							<div className={styles.qbCheckboxText}>
								Lidh me QuickBooks
							</div>
						</div>
					</div>
				</ModalCustom>
			)}
		</div>
	);
};

export default CompaniesTab;
