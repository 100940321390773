
import { CSSProperties, FunctionComponent } from "react";
import styles from "./Loader.module.scss";

interface Props {
    className?: string;
    style?: CSSProperties;
}

const Loader: FunctionComponent<Props> = (props) => {
    return <div className={`${styles.spinner} ${props?.className}`} style={props?.style}>
        <div className={`${styles.blueDot}`}></div>
        <div className={`${styles.blueDot}`}></div>
        <div className={`${styles.blueDot}`}></div>
        <div className={`${styles.blueDot}`}></div>
        <div className={`${styles.blueDot}`}></div>
        <div className={`${styles.blueDot}`}></div>
        <div className={`${styles.blueDot}`}></div>
        <div className={`${styles.blueDot}`}></div>
        <div className={`${styles.greenStrip}`}></div>
    </div>

}

export default Loader;



