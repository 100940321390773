import { FunctionComponent } from "react";

import styles from "./ConfirmModal.module.scss";
import ModalCustom from "components/ModalCustom/ModalCustom.component";

interface Props {
	confirmCallback: any;
	rejectCallback: any;
	show?: boolean;
	submitLoading?: boolean;
	title?: string;
	subTitle?: string;
	submitText?: string;
	preventBackdropCloseClick?: boolean;
	setShowConfirmModal?: any;
	setConfirmLoading?: any;
}

const ConfirmModal: FunctionComponent<Props> = (props: Props) => {
	return (
		<ModalCustom
			show={props.show}
			showRejectButton={true}
			contentClass={styles.confirmModal}
			onClose={props.rejectCallback}
			onSubmit={props.confirmCallback}
			submitText={props.submitText || "OK"}
			footerClass={styles.confirmFooter}
			modalMainStyle={styles.confirmModalMainStyle}
			submitLoading={props.submitLoading}
			backdropClass={styles.confirmModalBackdrop}
			preventBackdropCloseClick={props.preventBackdropCloseClick || false}
		>
			<div className={styles.content}>
				<div className={styles.title}>
					{props.title}
				</div>
				<div className={styles.subTitle}>
					{props.subTitle}
				</div>
			</div>
		</ModalCustom>
	);
}

export default ConfirmModal;