import {
  LOAD_ANNUAL_SALARIES,
  LOAD_ANNUAL_SALARIES_SUCCESS,
  LOAD_ANNUAL_SALARIES_ERROR,
  DOWNLOAD_ANNUAL_SALARIES_EXCEL,
  DOWNLOAD_ANNUAL_SALARIES_EXCEL_ERROR,
  DOWNLOAD_ANNUAL_SALARIES_EXCEL_SUCCESS,
  DOWNLOAD_ANNUAL_SALARIES_PDF,
  DOWNLOAD_ANNUAL_SALARIES_PDF_ERROR,
  DOWNLOAD_ANNUAL_SALARIES_PDF_SUCCESS,
  SEND_EMPLOYEES_EMAIL,
  SEND_EMPLOYEES_EMAIL_SUCCESS,
  SEND_EMPLOYEES_EMAIL_ERROR,
  SEND_ANNUAL_SALARY_EMAIL,
  SEND_ANNUAL_SALARY_EMAIL_SUCCESS,
  SEND_ANNUAL_SALARY_EMAIL_ERROR,
} from "./constants";
import { SET_EMPLOYEES_CHECKED } from "pages/Reports/TaxAdministrationReports/constants";

export const loadAnnualSalaries = (tenantId: number, year: number) => {
  return {
    type: LOAD_ANNUAL_SALARIES,
    payload: {
      year,
      tenantId,
    },
  };
};

export const loadAnnualSalariesSuccess = (res: any) => {
  return {
    type: LOAD_ANNUAL_SALARIES_SUCCESS,
    payload: {
      res,
    },
  };
};

export const loadAnnualSalariesError = (error) => {
  return {
    type: LOAD_ANNUAL_SALARIES_ERROR,
    payload: {
      error,
    },
  };
};

export const downloadAnnualSalariesExcel = (tenantId: number, year: number) => {
  return {
    type: DOWNLOAD_ANNUAL_SALARIES_EXCEL,
    payload: {
      tenantId,
      year,
    },
  };
};

export const downloadAnnualSalariesExcelSuccess = (downloadFile) => {
  return {
    type: DOWNLOAD_ANNUAL_SALARIES_EXCEL_SUCCESS,
    payload: {
      downloadFile,
    },
  };
};

export const downloadAnnualSalariesExcelError = (error) => {
  return {
    type: DOWNLOAD_ANNUAL_SALARIES_EXCEL_ERROR,
    payload: {
      error,
    },
  };
};

export const downloadAnnualSalariesPdf = (tenantId: number, year: number) => {
  return {
    type: DOWNLOAD_ANNUAL_SALARIES_PDF,
    payload: {
      tenantId,
      year,
    },
  };
};

export const downloadAnnualSalariesPdfSuccess = (downloadFile) => {
  return {
    type: DOWNLOAD_ANNUAL_SALARIES_PDF_SUCCESS,
    payload: {
      downloadFile,
    },
  };
};

export const downloadAnnualSalariesPdfError = (error) => {
  return {
    type: DOWNLOAD_ANNUAL_SALARIES_PDF_ERROR,
    payload: {
      error,
    },
  };
};

export const setEmployeesChecked = (checked) => {
  return {
    type: SET_EMPLOYEES_CHECKED,
    payload: {
      checked,
    },
  };
};

export const sendEmployeesEmail = (
  tenantId: number,
  employeeIds: Array<number>,
  email: string
) => {
  return {
    type: SEND_EMPLOYEES_EMAIL,
    payload: {
      tenantId,
      employeeIds,
      email,
    },
  };
};

export const sendEmployeesEmailSuccess = (data) => {
  return {
    type: SEND_EMPLOYEES_EMAIL_SUCCESS,
    payload: {
      data,
    },
  };
};

export const sendEmployeesEmailError = (error) => {
  return {
    type: SEND_EMPLOYEES_EMAIL_ERROR,
    payload: {
      error,
    },
  };
};

export const sendAnnualSalaryToMail = (
  tenantId: number,
  year: number,
  employeeIds: Array<number>,
  email: string
) => {
  return {
    type: SEND_ANNUAL_SALARY_EMAIL,
    payload: {
      tenantId,
      year,
      employeeIds,
      email,
    },
  };
};

export const sendAnnualSalaryToMailSuccess = (data) => {
  return {
    type: SEND_ANNUAL_SALARY_EMAIL_SUCCESS,
    payload: {
      data,
    },
  };
};

export const sendAnnualSalaryToMailError = (error) => {
  return {
    type: SEND_ANNUAL_SALARY_EMAIL_ERROR,
    payload: {
      error,
    },
  };
};
