const scope = "melitaPayroll/employees";

export const TOGGLE_MODAL_STATE = `${scope}/TOGGLE_MODAL_STATE`;
export const CLEAR_EMPLOYEE_MODAL_DATA = `${scope}/CLEAR_EMPLOYEE_MODAL_DATA`;
export const SAVE_DRAFT = `${scope}/SAVE_DRAFT`;
export const SET_ACTIVE_TAB = `${scope}/SET_ACTIVE_TAB`;

export const LOAD_EMPLOYEES_ALL = `${scope}/LOAD_EMPLOYEES_ALL`;
export const LOAD_EMPLOYEES_INACTIVE = `${scope}/LOAD_EMPLOYEES_INACTIVE`;
export const LOAD_EMPLOYEES_ON_PROCESS = `${scope}/LOAD_EMPLOYEES_ON_PROCESS`;

export const LOAD_EMPLOYEES_ALL_SUCCESS = `${scope}/LOAD_EMPLOYEES_ALL_SUCCESS`;
export const LOAD_EMPLOYEES_INACTIVE_SUCCESS = `${scope}/LOAD_EMPLOYEES_INACTIVE_SUCCESS`;
export const LOAD_EMPLOYEES_ON_PROCESS_SUCCESS = `${scope}/LOAD_EMPLOYEES_ON_PROCESS_SUCCESS`;

export const LOAD_EMPLOYEES_ALL_ERROR = `${scope}/LOAD_EMPLOYEES_ALL_ERROR`;
export const LOAD_EMPLOYEES_INACTIVE_ERROR = `${scope}/LOAD_EMPLOYEES_INACTIVE_ERROR`;
export const LOAD_EMPLOYEES_ON_PROCESS_ERROR = `${scope}/LOAD_EMPLOYEES_ON_PROCESS_ERROR`;

export const EXPORT_EMPLOYEES = `${scope}/EXPORT_EMPLOYEES`;
export const EXPORT_EMPLOYEES_SUCCESS = `${scope}/EXPORT_EMPLOYEES_SUCCESS`;
export const EXPORT_EMPLOYEES_ERROR = `${scope}/EXPORT_EMPLOYEES_ERROR`;

export const DELETE_EMPLOYEE = `${scope}/DELETE_EMPLOYEE`;
export const DELETE_EMPLOYEE_SUCCESS = `${scope}/DELETE_EMPLOYEE_SUCCESS`;
export const DELETE_EMPLOYEE_ERROR = `${scope}/DELETE_EMPLOYEE_ERROR`;

export const SUBMIT_EMPLOYEE_DATA = `${scope}/SUBMIT_EMPLOYEE_DATA`;
export const SUBMIT_EMPLOYEE_DATA_SUCCESS = `${scope}/SUBMIT_EMPLOYEE_DATA_SUCCESS`;
export const SUBMIT_EMPLOYEE_DATA_ERROR = `${scope}/SUBMIT_EMPLOYEE_DATA_ERROR`;
export const SUBMIT_EMPLOYEE_EDIT = `${scope}/SUBMIT_EMPLOYEE_EDIT`;
export const SUBMIT_EMPLOYEE_EDIT_SUCCESS = `${scope}/SUBMIT_EMPLOYEE_EDIT_SUCCESS`;
export const SUBMIT_EMPLOYEE_EDIT_ERROR = `${scope}/SUBMIT_EMPLOYEE_EDIT_ERROR`;

export const LOAD_EMPLOYEES_TAB_NUMBER = `${scope}/LOAD_EMPLOYEES_TAB_NUMBER`;
export const LOAD_EMPLOYEES_TAB_NUMBER_SUCCESS = `${scope}/LOAD_EMPLOYEES_TAB_NUMBER_SUCCESS`;
export const LOAD_EMPLOYEES_TAB_NUMBER_ERROR = `${scope}/LOAD_EMPLOYEES_TAB_NUMBER_ERROR`;

export const SEARCH_EMPLOYEES = `${scope}/SEARCH_EMPLOYEES`;
export const SEARCH_EMPLOYEES_SUCCESS = `${scope}/SEARCH_EMPLOYEES_SUCCESS`;
export const SEARCH_EMPLOYEES_ERROR = `${scope}/SEARCH_EMPLOYEES_ERROR`;

export const GET_EMPLOYEE_BY_ID = `${scope}/GET_EMPLOYEE_BY_ID`;
export const GET_EMPLOYEE_BY_ID_SUCCESS = `${scope}/GET_EMPLOYEE_BY_ID_SUCCESS`;
export const GET_EMPLOYEE_BY_ID_ERROR = `${scope}/GET_EMPLOYEE_BY_ID_ERROR`;

export const UPLOAD_MULTIPLE_FILES = `${scope}/UPLOAD_MULTIPLE_FILES`;
export const UPLOAD_MULTIPLE_FILES_SUCCESS = `${scope}/UPLOAD_MULTIPLE_FILES_SUCCESS`;
export const UPLOAD_MULTIPLE_FILES_ERROR = `${scope}/UPLOAD_MULTIPLE_FILES_ERROR`;

export const DELETE_UPLOADED_ATTACHMENT = `${scope}/DELETE_UPLOADED_ATTACHMENT`;
export const DELETE_UPLOADED_ATTACHMENT_SUCCESS = `${scope}/DELETE_UPLOADED_ATTACHMENT_SUCCESS`;
export const DELETE_UPLOADED_ATTACHMENT_ERROR = `${scope}/DELETE_UPLOADED_ATTACHMENT_ERROR`;

export const SET_ATTACHMENTS = `${scope}/SET_ATTACHMENTS`;
export const REMOVE_ATTACHMENTS = `${scope}/REMOVE_ATTACHMENTS`;
export const SHOW_EMPLOYEE_ATTACHMENTS = `${scope}/SHOW_EMPLOYEE_ATTACHMENTS`;

export const SET_UNCHANGABLE_BRUTTO = `${scope}/SET_UNCHANGABLE_BRUTTO`;
export const SET_UNCHANGABLE_NETTO = `${scope}/SET_UNCHANGABLE_NETTO`;

export const SET_IS_ADD_EMPLOYEE_ON = `${scope}/SET_IS_ADD_EMPLOYEE_ON`;

export const GET_INITIAL_BRUTTO = `${scope}/GET_INITIAL_BRUTTO`;
export const GET_INITIAL_BRUTTO_SUCCESS = `${scope}/GET_INITIAL_BRUTTO_SUCCESS`;
export const GET_INITIAL_BRUTTO_ERROR = `${scope}/GET_INITIAL_BRUTTO_ERROR`;

export const ADD_EMPLOYEE_QB = `${scope}/ADD_EMPLOYEE_QB`;
export const ADD_EMPLOYEE_QB_SUCCESS = `${scope}/ADD_EMPLOYEE_QB_SUCCESS`;
export const ADD_EMPLOYEE_QB_ERROR = `${scope}/ADD_EMPLOYEE_QB_ERROR`;

export const DELETE_EMPLOYEE_PROJECT = `${scope}/DELETE_EMPLOYEE_PROJECT`;
export const DELETE_EMPLOYEE_PROJECT_SUCCESS = `${scope}/DELETE_EMPLOYEE_PROJECT_SUCCESS`;
export const DELETE_EMPLOYEE_PROJECT_ERROR = `${scope}/DELETE_EMPLOYEE_PROJECT_ERROR`;