import React, { useState, MouseEvent, useRef, useEffect } from 'react';
import {BsFolder} from 'react-icons/bs';
import styles from './reportTemplateBox.module.scss';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {setBackdropState} from '../AppWrapper/actions';
import {setClearGlobalSearch, setGlobalSearchDone} from '../NavBar/actions';


interface Props {
  name?: string;
  onSubmit?: (event: MouseEvent<HTMLButtonElement>) => void;
  id?: number;
  file?: any;
}

const ReportTemplateBox: React.FC<Props> = props => {
  const dispatch = useDispatch();

  const truncate = (str) => {
    return str.length > 10 ? str.substring(0, 20) + '...' : str;
  };

  const handleSubmitClick = (e) => {
    props.onSubmit && props?.onSubmit(e);
  }

  return (
      <div className={styles.template}>
        <div className={styles.templateBox} onClick={handleSubmitClick}>
          <div className={styles.firstBox}>
            <span><BsFolder className={styles.folderIcon} color="#89c732" width={30} height={28}/></span>
            <span className={styles.templateTitle}>{props?.name}</span>
          </div>
        </div>
      </div>
  );
};

export default ReportTemplateBox;