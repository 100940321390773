import {Gender} from '../enums/gender.enum';

export interface Notification{
    createdAt: Date,
    data: any,
    id: string,
    notifiableId: number,
    notifiableType: string,
    readAt: Date,
    type: string,
    updatedAt: Date
    name: string
}


export const notificationTypes = {
    BIRTHDAY: 'App\\Notifications\\BirthdaysNotification',
    CONTRACT: 'App\\Notifications\\ContractNotification',
    VACATION: 'App\\Notifications\\VacationsNotification',

};

export const getNotificationTitle = (notificationType: string): string => {
    switch (notificationType) {
        case notificationTypes.CONTRACT:
            return 'Kontrat';
        case notificationTypes.BIRTHDAY:
            return 'Ditelindje';
        case notificationTypes.VACATION:
            return 'Pushim';
    }
};

export const getNotificationDescription = (notification: Notification): string => {
    switch (notification.type) {
        case notificationTypes.CONTRACT:
            return `${notification.name} i ka skaduar kontrata`;
        case notificationTypes.BIRTHDAY:
            return `${notification.name} ka ditelindjen`;
        case notificationTypes.VACATION:
            return `${notification.name} eshte ne pushim`;
    }
};
