import { IconSearch } from "assets/icons/icons";
import React, { useRef, useState } from "react"
import styles from "./SearchInput.module.scss";
import {useTranslation} from 'react-i18next';

interface Props {
    className?: string;
    onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    searchPlaceholder?: string;
}

const SearchInput: React.FC<Props> = (props) => {
    const {t, i18n} = useTranslation('common');
    const [value, setValue] = useState("");
    const inputRef = useRef(null);

    return (
        <div className={`${styles.container} ${props.className ? props.className : ""}`} onClick={() => inputRef?.current?.focus()}>
            <IconSearch color="#261f63" class={`${styles.icon} pointer`} />
            <input
                type="search"
                name="globalSearch"
                id="globalSearch"
                placeholder={props.searchPlaceholder || "Kërko"} 
                onChange={(event) => {
                    setValue(value => event.target.value);
                    props?.onChangeHandler(event);
                }}
                value={value}
                autoComplete="off"
                ref={inputRef}
            />
        </div>
    )
}

export default SearchInput;