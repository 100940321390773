import styles from "../NavBar/NavBar.module.scss";
import { useSelector } from "react-redux";
import { globalState } from "../../types/globalState.type";
import {
	getNotificationDescription,
	getNotificationTitle,
	Notification,
} from "../../types/notification.type";
import LoaderCentered from "../partials/LoaderCentered/LoaderCentered.component";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import noNotificationLink from "../../assets/images/no_notifications.png";

interface Props {
	notifications?: Notification[];
}

export const NotificationsDropdown: React.FC<Props> = (props) => {
	const history = useHistory();
	const isCurrentBackdropActive: boolean = useSelector(
		(state: globalState) =>
			state.appWrapperReducer.backdropParent === "notificationsMenu"
	);

	return (
		<div className={styles.notificationsContent}>
			<div className={styles.header}>
				Njoftime:{" "}
				{props.notifications ? props.notifications.length : 0}
			</div>
			{isCurrentBackdropActive && (
				<div className={styles.body}>
					{props.notifications?.length === 0 ? (
						<div className={styles.noNotificationsContainer}>
							<img src={noNotificationLink} alt="" />
						</div>
					) : props.notifications ? (
						isCurrentBackdropActive &&
						props.notifications.slice(0, 6).map((notification) => {
							return (
								<div key={notification.id} className={styles.item}>
									<div className={styles.title}>
										{getNotificationTitle(notification.type)}
									</div>
									<div className={styles.subTitle}>
										{getNotificationDescription(notification)}
									</div>
								</div>
							);
						})
					) : (
						<div style={{ marginTop: "34.5px" }}>
							<LoaderCentered />
						</div>
					)}
				</div>
			)}
			<div
				onClick={() => {
					history.push("/notifications");
				}}
				className={classNames({
					[styles.footer]: true,
					[styles.notificationSeeMore]: true,
				})}
			>
				Shiko më shumë
			</div>
		</div>
	);
};
