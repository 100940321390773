import * as constants from "./constants";
import {
  GET_LAST_BACKUP,
  GET_LAST_BACKUP_ERROR,
  GET_LAST_BACKUP_SUCCESS,
  REQUEST_BACKUP,
  REQUEST_BACKUP_ERROR,
  REQUEST_BACKUP_SUCCESS,
} from "./constants";

export const setGlobalTenantId = (payload) => {
  return {
    type: constants.SET_GLOBAL_TENANT_ID,
    payload,
  };
};

export const setModalState = (payload) => {
  return {
    type: constants.SET_MODAL_STATE,
    payload,
  };
};

export const setShowSubmitButton = (payload) => {
  return {
    type: constants.SET_SHOW_SUBMIT_BUTTON,
    payload,
  };
};

export const setButtonText = (payload) => {
  return {
    type: constants.SET_BUTTON_TEXT,
    payload,
  };
};

export const setModalEditMode = (payload) => {
  return {
    type: constants.SET_MODAL_EDIT_MODE,
    payload,
  };
};

export const submitCompanyData = (payload) => {
  return {
    type: constants.SUBMIT_COMPANY_DATA,
    payload,
  };
};
export const submitCompanyDataSuccess = (payload) => {
  return {
    type: constants.SUBMIT_COMPANY_DATA_SUCCESS,
    payload,
  };
};
export const submitCompanyDataError = (payload) => {
  return {
    type: constants.SUBMIT_COMPANY_DATA_ERROR,
    payload,
  };
};

export const submitAdminData = (payload) => {
  return {
    type: constants.SUBMIT_ADMIN_DATA,
    payload,
  };
};
export const submitAdminDataSuccess = (payload) => {
  return {
    type: constants.SUBMIT_ADMIN_DATA_SUCCESS,
    payload,
  };
};
export const submitAdminDataError = (payload) => {
  return {
    type: constants.SUBMIT_ADMIN_DATA_ERROR,
    payload,
  };
};

export const submitUserData = (payload) => {
  return {
    type: constants.SUBMIT_USER_DATA,
    payload,
  };
};
export const submitUserDataSuccess = (payload) => {
  return {
    type: constants.SUBMIT_USER_DATA_SUCCESS,
    payload,
  };
};
export const submitUserDataError = (payload) => {
  return {
    type: constants.SUBMIT_USER_DATA_ERROR,
    payload,
  };
};

export const searchCompanies = (payload) => {
  return {
    type: constants.SEARCH_COMPANIES,
    payload: {
      name: payload,
    },
  };
};
export const searchCompaniesSuccess = (payload) => {
  return {
    type: constants.SEARCH_COMPANIES_SUCCESS,
    payload,
  };
};
export const searchCompaniesError = (payload) => {
  return {
    type: constants.SEARCH_COMPANIES_ERROR,
    payload,
  };
};

export const setSetupModalState = (payload) => {
  return {
    type: constants.SET_SETUP_MODAL_STATE,
    payload,
  };
};

export const getContactInfo = (payload) => {
  return {
    type: constants.GET_CONTACT_INFO,
    payload: {
      tenantId: payload,
    },
  };
};
export const getContactInfoSuccess = (payload) => {
  return {
    type: constants.GET_CONTACT_INFO_SUCCESS,
    payload,
  };
};
export const getContactInfoError = (payload) => {
  return {
    type: constants.GET_CONTACT_INFO_ERROR,
    payload,
  };
};

export const getAllTenants = (payload) => {
  return {
    type: constants.GET_ALL_TENANTS,
    payload: {
      tenantId: payload,
    },
  };
};
export const getAllTenantsSuccess = (payload) => {
  return {
    type: constants.GET_ALL_TENANTS_SUCCESS,
    payload,
  };
};
export const getAllTenantsError = (payload) => {
  return {
    type: constants.GET_ALL_TENANTS_ERROR,
  };
};

export const getTaxDegreesInformation = () => {
  return {
    type: constants.GET_TAX_INFORMATION,
  };
};

export const getTaxDegreesInformationSuccess = (payload) => {
  return {
    type: constants.GET_TAX_INFORMATION_SUCCESS,
    payload,
  };
};
export const getTaxDegreesInformationError = (payload) => {
  return {
    type: constants.GET_TAX_INFORMATION_ERROR,
    payload,
  };
};

export const getAllUsers = (payload) => {
  return {
    type: constants.GET_ALL_USERS,
    payload: {
      tenantId: payload,
    },
  };
};
export const getAllUsersSuccess = (payload) => {
  return {
    type: constants.GET_ALL_USERS_SUCCESS,
    payload,
  };
};
export const getAllUsersError = (payload) => {
  return {
    type: constants.GET_ALL_USERS_ERROR,
  };
};
export const getContribute = () => {
  return {
    type: constants.GET_CONTRIBUTE,
  };
};
export const getContributeSuccess = (payload) => {
  return {
    type: constants.GET_CONTRIBUTE_SUCCESS,
    payload,
  };
};
export const getContributeError = (payload) => {
  return {
    type: constants.GET_CONTRIBUTE_ERROR,
    payload,
  };
};

export const deleteCompany = (payload) => {
  return {
    type: constants.DELETE_COMPANY,
    payload: {
      tenantId: payload,
    },
  };
};
export const deleteCompanySuccess = (payload) => {
  return {
    type: constants.DELETE_COMPANY_SUCCESS,
    payload,
  };
};
export const deleteCompanyError = (payload) => {
  return {
    type: constants.DELETE_COMPANY_ERROR,
    payload,
  };
};

export const getOneTenant = (payload) => {
  return {
    type: constants.GET_ONE_TENANT,
    payload: {
      tenantId: payload,
    },
  };
};
export const getOneTenantSuccess = (payload) => {
  return {
    type: constants.GET_ONE_TENANT_SUCCESS,
    payload,
  };
};
export const getOneTenantError = (payload) => {
  return {
    type: constants.GET_ONE_TENANT_ERROR,
  };
};

export const deleteUser = (payload) => {
  return {
    type: constants.DELETE_USER,
    payload: {
      userId: payload,
    },
  };
};
export const deleteUserSuccess = (payload) => {
  return {
    type: constants.DELETE_USER_SUCCESS,
    payload,
  };
};
export const deleteUserError = (payload) => {
  return {
    type: constants.DELETE_USER_ERROR,
    payload,
  };
};

export const getOneUser = (payload) => {
  return {
    type: constants.GET_ONE_USER,
    payload: {
      userId: payload,
    },
  };
};
export const getOneUserSuccess = (payload) => {
  return {
    type: constants.GET_ONE_USER_SUCCESS,
    payload,
  };
};
export const getOneUserError = (payload) => {
  return {
    type: constants.GET_ONE_USER_ERROR,
  };
};

export const getTenantAudits = (payload) => {
  return {
    type: constants.GET_TENANT_AUDITS,
    payload: {
      tenantId: payload,
    },
  };
};
export const getTenantAuditsSuccess = (payload) => {
  return {
    type: constants.GET_TENANT_AUDITS_SUCCESS,
    payload,
  };
};
export const getTenantAuditsError = (payload) => {
  return {
    type: constants.GET_TENANT_AUDITS_ERROR,
  };
};

export const getAuthAudits = () => {
  return {
    type: constants.GET_AUTH_AUDITS,
  };
};
export const getAuthAuditsSuccess = (payload) => {
  return {
    type: constants.GET_AUTH_AUDITS_SUCCESS,
    payload,
  };
};
export const getAuthAuditsError = (payload) => {
  return {
    type: constants.GET_AUTH_AUDITS_ERROR,
  };
};

export const getUserAudits = (payload) => {
  return {
    type: constants.GET_USER_AUDITS,
    payload: {
      userId: payload,
    },
  };
};
export const getUserAuditsSuccess = (payload) => {
  return {
    type: constants.GET_USER_AUDITS_SUCCESS,
    payload,
  };
};
export const getUserAuditsError = (payload) => {
  return {
    type: constants.GET_USER_AUDITS_ERROR,
  };
};

export const uploadLogo = (payload) => {
  return {
    type: constants.UPLOAD_LOGO,
    payload,
  };
};
export const uploadLogoSuccess = (payload) => {
  return {
    type: constants.UPLOAD_LOGO_SUCCESS,
    payload,
  };
};
export const uploadLogoError = (payload) => {
  return {
    type: constants.UPLOAD_LOGO_ERROR,
    payload,
  };
};

export const getAuthUser = () => {
  return {
    type: constants.GET_AUTH_USER,
  };
};
export const getAuthUserSuccess = (payload) => {
  return {
    type: constants.GET_AUTH_USER_SUCCESS,
    payload,
  };
};
export const getAuthUserError = (payload) => {
  return {
    type: constants.GET_AUTH_USER_ERROR,
  };
};

export const updateTenant = (payload) => {
  return {
    type: constants.UPDATE_TENANT,
    payload,
  };
};
export const updateTenantSuccess = (payload) => {
  return {
    type: constants.UPDATE_TENANT_SUCCESS,
    payload,
  };
};
export const updateTenantError = (payload) => {
  return {
    type: constants.UPDATE_TENANT_ERROR,
  };
};

export const saveTaxes = (payload) => {
  return {
    type: constants.SAVE_TAXES,
    payload,
  };
};
export const saveTaxesSuccess = (payload) => {
  return {
    type: constants.SAVE_TAXES_SUCCESS,
    payload,
  };
};
export const saveTaxesError = (payload) => {
  return {
    type: constants.SAVE_TAXES_ERROR,
    payload,
  };
};

export const updateUser = (payload) => {
  return {
    type: constants.UPDATE_USER,
    payload,
  };
};
export const updateUserSuccess = (payload) => {
  return {
    type: constants.UPDATE_USER_SUCCESS,
    payload,
  };
};
export const updateUserError = (payload) => {
  return {
    type: constants.UPDATE_USER_ERROR,
  };
};

export const saveContributes = (payload) => {
  return {
    type: constants.SAVE_CONTRIBUTES,
    payload,
  };
};
export const saveContributesSuccess = (payload) => {
  return {
    type: constants.SAVE_CONTRIBUTES_SUCCESS,
    payload,
  };
};
export const saveContributesError = (payload) => {
  return {
    type: constants.SAVE_CONTRIBUTES_ERROR,
    payload,
  };
};

export const getOvertime = (payload) => {
  return {
    type: constants.GET_OVERTIME,
    payload,
  };
};
export const getOvertimeSuccess = (payload) => {
  return {
    type: constants.GET_OVERTIME_SUCCESS,
    payload,
  };
};
export const getOvertimeError = (payload) => {
  return {
    type: constants.GET_OVERTIME_ERROR,
    payload,
  };
};

export const saveOvertime = (payload) => {
  return {
    type: constants.SAVE_OVERTIME,
    payload,
  };
};
export const saveOvertimeSuccess = (payload) => {
  return {
    type: constants.SAVE_OVERTIME_SUCCESS,
    payload,
  };
};
export const saveOvertimeError = (payload) => {
  return {
    type: constants.SAVE_OVERTIME_ERROR,
    payload,
  };
};

export const getLoginCompanies = (payload) => {
  return {
    type: constants.GET_LOGIN_COMPANIES,
    payload,
  };
};
export const getLoginCompaniesSuccess = (payload) => {
  return {
    type: constants.GET_LOGIN_COMPANIES_SUCCESS,
    payload,
  };
};
export const getLoginCompaniesError = (payload) => {
  return {
    type: constants.GET_LOGIN_COMPANIES_ERROR,
  };
};

export const getVacation = (payload) => {
  return {
    type: constants.GET_VACATION,
    payload,
  };
};
export const getVacationSuccess = (payload) => {
  return {
    type: constants.GET_VACATION_SUCCESS,
    payload,
  };
};
export const getVacationError = (payload) => {
  return {
    type: constants.GET_VACATION_ERROR,
    payload,
  };
};

export const saveVacation = (payload) => {
  return {
    type: constants.SAVE_VACATION,
    payload,
  };
};
export const saveVacationSuccess = (payload) => {
  return {
    type: constants.SAVE_VACATION_SUCCESS,
    payload,
  };
};
export const saveVacationError = (payload) => {
  return {
    type: constants.SAVE_VACATION_ERROR,
    payload,
  };
};

export const updateHealthInsurance = (tenantId, payload) => {
  return {
    type: constants.UPDATE_HEALTH_INSURANCE,
    payload,
  };
};
export const updateHealthInsuranceSuccess = (payload) => {
  return {
    type: constants.UPDATE_HEALTH_INSURANCE_SUCCESS,
    payload,
  };
};
export const updateHealthInsuranceError = (payload) => {
  return {
    type: constants.UPDATE_HEALTH_INSURANCE_ERROR,
    payload,
  };
};

export const getHealthInsurance = (payload) => {
  return {
    type: constants.GET_HEALTH_INSURANCE,
    payload: {
      tenantId: payload,
    },
  };
};
export const getHealthInsuranceSuccess = (payload) => {
  return {
    type: constants.GET_HEALTH_INSURANCE_SUCCESS,
    payload,
  };
};
export const getHealthInsuranceError = (payload) => {
  return {
    type: constants.GET_HEALTH_INSURANCE_ERROR,
  };
};

export const saveProject = (payload) => {
  return {
    type: constants.SAVE_PROJECT,
    payload,
  };
};
export const saveProjectSuccess = (payload) => {
  return {
    type: constants.SAVE_PROJECT_SUCCESS,
    payload,
  };
};
export const saveProjectError = (payload) => {
  return {
    type: constants.SAVE_PROJECT_ERROR,
    payload,
  };
};

export const deleteProject = (payload) => {
  return {
    type: constants.DELETE_PROJECT,
    payload: {
      projectId: payload.projectId,
      tenantId: payload.tenantId,
    },
  };
};
export const deleteProjectSuccess = (payload) => {
  return {
    type: constants.DELETE_PROJECT_SUCCESS,
    payload,
  };
};
export const deleteProjectError = (payload) => {
  return {
    type: constants.DELETE_PROJECT_ERROR,
    payload,
  };
};

export const updateProjectError = (payload) => {
  return {
    type: constants.UPDATE_TENANT_ERROR,
  };
};

export const getOneProject = (payload) => {
  return {
    type: constants.GET_ONE_PROJECT,
    payload: {
      projectId: payload,
    },
  };
};
export const getOneProjectSuccess = (payload) => {
  return {
    type: constants.GET_ONE_PROJECT_SUCCESS,
    payload,
  };
};
export const getOneProjectError = (payload) => {
  return {
    type: constants.GET_ONE_PROJECT_ERROR,
  };
};

export const sendForgotPasswordEmail = (payload) => {
  return {
    type: constants.SEND_FORGOT_PASSWORD_EMAIL,
    payload: {
      email: payload,
    },
  };
};
export const sendForgotPasswordEmailSuccess = (payload) => {
  return {
    type: constants.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
    payload,
  };
};
export const sendForgotPasswordEmailError = (payload) => {
  return {
    type: constants.SEND_FORGOT_PASSWORD_EMAIL_ERROR,
  };
};

export const resetPassword = (payload) => {
  return {
    type: constants.RESET_PASSWORD,
    payload,
  };
};
export const resetPasswordSuccess = (payload) => {
  return {
    type: constants.RESET_PASSWORD_SUCCESS,
    payload,
  };
};
export const resetPasswordError = (payload) => {
  return {
    type: constants.RESET_PASSWORD_ERROR,
    payload,
  };
};

export const verifyPassword = (payload) => {
  return {
    type: constants.VERIFY_PASSWORD,
    payload,
  };
};
export const verifyPasswordSuccess = (payload) => {
  return {
    type: constants.VERIFY_PASSWORD_SUCCESS,
    payload,
  };
};
export const verifyPasswordError = (payload) => {
  return {
    type: constants.VERIFY_PASSWORD_ERROR,
    payload,
  };
};

export const acceptTerms = (payload) => {
  return {
    type: constants.ACCEPT_TERMS,
    payload,
  };
};
export const acceptTermsSuccess = (payload) => {
  return {
    type: constants.ACCEPT_TERMS_SUCCESS,
    payload,
  };
};
export const acceptTermsError = (payload) => {
  return {
    type: constants.ACCEPT_TERMS_ERROR,
    payload,
  };
};

export const getUserByEmail = (payload) => {
  return {
    type: constants.GET_USER_BY_EMAIL,
    payload: {
      email: payload,
    },
  };
};
export const getUserByEmailSuccess = (payload) => {
  return {
    type: constants.GET_USER_BY_EMAIL_SUCCESS,
    payload,
  };
};
export const getUserByEmailError = (payload) => {
  return {
    type: constants.GET_USER_BY_EMAIL_ERROR,
  };
};

export const changePassword = (payload) => {
  return {
    type: constants.CHANGE_PASSWORD,
    payload,
  };
};
export const changePasswordSuccess = (payload) => {
  return {
    type: constants.CHANGE_PASSWORD_SUCCESS,
    payload,
  };
};
export const changePasswordError = (payload) => {
  return {
    type: constants.CHANGE_PASSWORD_ERROR,
    payload,
  };
};

export const requestBackup = () => {
  return {
    type: REQUEST_BACKUP,
  };
};

export const requestBackupSuccess = () => {
  return {
    type: REQUEST_BACKUP_SUCCESS,
  };
};

export const requestBackupError = () => {
  return {
    type: REQUEST_BACKUP_ERROR,
  };
};

export const getLastBackup = () => {
  return {
    type: GET_LAST_BACKUP,
  };
};

export const getLastBackupSuccess = (lastBackUpDate: Date) => {
  return {
    type: GET_LAST_BACKUP_SUCCESS,
    payload: {
      lastBackUpDate,
    },
  };
};

export const getLastBackupError = () => {
  return {
    type: GET_LAST_BACKUP_ERROR,
  };
};

export const getCode = () => {
  return {
    type: constants.GET_CODE,
  };
};

export const getCodeSuccess = (payload) => {
  return {
    type: constants.GET_CODE_SUCCESS,
    payload,
  };
};

export const getCodeError = (payload) => {
  return {
    type: constants.GET_CODE_ERROR,
  };
};

export const generateCode = (payload) => {
  return {
    type: constants.GENERATE_CODE,
    payload,
  };
};

export const generateCodeSuccess = (payload) => {
  return {
    type: constants.GENERATE_CODE_SUCCESS,
    payload,
  };
};

export const generateCodeError = (payload) => {
  return {
    type: constants.GENERATE_CODE_ERROR,
    payload,
  };
};

export const getTenantSectors = (payload) => {
  return {
    type: constants.GET_TENANT_SECTORS,
    payload: {
      tenantId: payload,
    },
  };
};

export const getTenantSectorsSuccess = (payload) => {
  return {
    type: constants.GET_TENANT_SECTORS_SUCCESS,
    payload,
  };
};

export const getTenantSectorsError = (payload) => {
  return {
    type: constants.GET_TENANT_SECTORS_ERROR,
    payload,
  };
};

export const createSector = (payload) => {
  return {
    type: constants.CREATE_SECTOR,
    payload,
  };
};

export const createSectorSuccess = (payload) => {
  return {
    type: constants.CREATE_SECTOR_SUCCESS,
    payload,
  };
};

export const createSectorError = (payload) => {
  return {
    type: constants.CREATE_SECTOR_ERROR,
    payload,
  };
};

export const deleteSector = (payload) => {
  return {
    type: constants.DELETE_SECTOR,
    payload: {
      sectorId: payload,
    },
  };
};

export const deleteSectorSuccess = (payload) => {
  return {
    type: constants.DELETE_SECTOR_SUCCESS,
    payload,
  };
};

export const deleteSectorError = (payload) => {
  return {
    type: constants.DELETE_SECTOR_ERROR,
    payload,
  };
};

export const updateSector = (payload) => {
  return {
    type: constants.UPDATE_SECTOR,
    payload,
  };
};

export const updateSectorSuccess = (payload) => {
  return {
    type: constants.UPDATE_SECTOR_SUCCESS,
    payload,
  };
};

export const updateSectorError = (payload) => {
  return {
    type: constants.UPDATE_SECTOR_ERROR,
  };
};

export const getAccounts = (payload) => {
  return {
    type: constants.GET_ACCOUNTS,
    payload: {
      tenantId: payload,
    },
  };
};

export const getAccountsSuccess = (payload) => {
  return {
    type: constants.GET_ACCOUNTS_SUCCESS,
    payload,
  };
};

export const getAccountsError = (payload) => {
  return {
    type: constants.GET_ACCOUNTS_ERROR,
  };
};

export const getUserList = (payload) => {
  return {
    type: constants.GET_USER_LIST,
    payload,
  };
};

export const getUserListSuccess = (payload) => {
  return {
    type: constants.GET_USER_LIST_SUCCESS,
    payload,
  };
};

export const getUserListError = (payload) => {
  return {
    type: constants.GET_USER_LIST_ERROR,
  };
};

export const allTenantUsers = (payload) => {
  return {
    type: constants.ALL_TENANT_USERS,
    payload: {
      tenantId: payload,
    },
  };
};
export const allTenantUsersSuccess = (payload) => {
  return {
    type: constants.ALL_TENANT_USERS_SUCCESS,
    payload,
  };
};
export const allTenantUsersError = (payload) => {
  return {
    type: constants.ALL_TENANT_USERS_ERROR,
    payload,
  };
};

export const getRefreshToken = (payload) => {
  return {
    type: constants.GET_REFRESH_TOKEN,
    payload,
  };
};
export const getRefreshTokenSuccess = (payload) => {
  return {
    type: constants.GET_REFRESH_TOKEN_SUCCESS,
    payload,
  };
};
export const getRefreshTokenError = (payload) => {
  return {
    type: constants.GET_REFRESH_TOKEN_ERROR,
    payload,
  };
};

export const getAllProjects = (payload) => {
  return {
    type: constants.GET_ALL_PROJECTS,
    payload: {
      tenantId: payload.tenantId,
      activeProjectsOnly: payload.activeProjectsOnly,
    },
  };
};

export const getAllProjectsSuccess = (payload) => {
  return {
    type: constants.GET_ALL_PROJECTS_SUCCESS,
    payload,
  };
};

export const getAllProjectsError = (payload) => {
  return {
    type: constants.GET_ALL_PROJECTS_ERROR,
  };
};

export const checkQuickbooks = (payload) => {
  return {
    type: constants.CHECK_QUICKBOOKS,
    payload,
  };
};

export const checkQuickbooksSuccess = (payload) => {
  return {
    type: constants.CHECK_QUICKBOOKS_SUCCESS,
    payload,
  };
};

export const setQuickBooksSuccessLoadingState = (payload) => {
  return {
    type: constants.SET_QUICKBOOKS_LOADING_STATE,
    payload,
  };
};

export const checkQuickbooksError = (payload) => {
  return {
    type: constants.CHECK_QUICKBOOKS_ERROR,
    payload,
  };
};
export const resetModalSubmitLoadingState = () => {
  console.log("ktu bre hereeeee");
  return {
    type: constants.RESET_MODAL_SUBMIT_LOADING,
  };
};

export const getTenantAccounts = (payload) => {
  return {
    type: constants.GET_TENANT_ACCOUNTS,
    payload: {
      tenantId: payload,
    },
  };
};
export const getTenantAccountsSuccess = (payload) => {
  return {
    type: constants.GET_TENANT_ACCOUNTS_SUCCESS,
    payload,
  };
};
export const getTenantAccountsError = (payload) => {
  return {
    type: constants.GET_TENANT_ACCOUNTS_ERROR,
  };
};

export const saveAccounts = (payload) => {
  return {
    type: constants.SAVE_ACCOUNTS,
    payload,
  };
};

export const saveAccountsSuccess = (payload) => {
  return {
    type: constants.SAVE_ACCOUNTS_SUCCESS,
    payload,
  };
};

export const saveAccountsError = (payload) => {
  return {
    type: constants.SAVE_ACCOUNTS_ERROR,
    payload,
  };
};

export const deleteAccount = (payload) => {
  return {
    type: constants.DELETE_ACCOUNT,
    payload: {
      accountId: payload,
    },
  };
};

export const deleteAccountSuccess = (payload) => {
  return {
    type: constants.DELETE_ACCOUNT_SUCCESS,
    payload,
  };
};

export const deleteAccountError = (payload) => {
  return {
    type: constants.DELETE_ACCOUNT_ERROR,
    payload,
  };
};

export const getPayrollAccounts = () => {
  return {
    type: constants.GET_PAYROLL_ACCOUNTS,
  };
};

export const getPayrollAccountsSuccess = (payload) => {
  return {
    type: constants.GET_PAYROLL_ACCOUNTS_SUCCESS,
    payload,
  };
};

export const getPayrollAccountsError = (payload) => {
  return {
    type: constants.GET_PAYROLL_ACCOUNTS_ERROR,
  };
};

export const addProjectQb = (payload) => {
  return {
    type: constants.ADD_PROJECT_QB,
    payload: {
      tenantId: payload,
    },
  };
};

export const addProjectQbSuccess = (payload) => {
  return {
    type: constants.ADD_PROJECT_QB_SUCCESS,
    payload,
  };
};

export const addProjectQbError = (payload) => {
  return {
    type: constants.ADD_PROJECT_QB_ERROR,
    payload,
  };
};
