import {
	LOAD_VACATIONS_ALL,
	LOAD_CALENDAR_VACATIONS_ALL,
	LOAD_VACATION_TYPES_ALL,
	LOAD_NEW_VACATION,
	LOAD_DELETE_VACATION,
	LOAD_UPDATE_VACATION,
	LOAD_SINGLE_VACATION,
} from 'pages/Holidays/constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getEmployeesAndVacations, getAllCalendarVacations, getAllVacationTypes, storeNewVacation, deleteEmployeeVacation, updateEmployeeVacation, showEmployeeVacationById } from 'services/vacations.service';
import {
	loadAllVacationsSuccess,
	loadAllVacationsError,
	loadAllCalendarVacationsSuccess,
	loadAllCalendarVacationsError,
	loadAllVacationTypesSuccess,
	loadAllVacationTypesError,
	loadNewVacationSuccess,
	loadNewVacationError,
	loadDeleteVacationSuccess,
	loadDeleteVacationError,
	loadUpdateVacationSuccess,
	loadUpdateVacationError,
	loadSingleVacationSuccess,
	loadSingleVacationError
} from './actions';

export function* loadAllEmployeesAndVacations(action) {
	try {
		const response = yield call(
			getEmployeesAndVacations,
			action.payload.tenantId,
		);
		yield put(loadAllVacationsSuccess(response.data.data));
	} catch (e) {
		yield put(loadAllVacationsError());
	}
}

export function* loadAllCalendarVacations(action) {
	try {
		const response = yield call(
			getAllCalendarVacations,
			action.payload.tenantId,
			action.payload.period,
		);
		yield put(loadAllCalendarVacationsSuccess(response.data.data));
	} catch (e) {
		yield put(loadAllCalendarVacationsError());
	}
}

export function* loadAllVacationTypes(action) {
	try {
		const response = yield call(getAllVacationTypes);
		yield put(loadAllVacationTypesSuccess(response));
	} catch (e) {
		yield put(loadAllVacationTypesError());
	}
}

export function* loadNewVacation(action) {
	try {
		const response = yield call(storeNewVacation, action.payload.newVacation);
		yield put(loadNewVacationSuccess(response));
	} catch (e) {
		yield put(loadNewVacationError());
	}
}

export function* loadDeleteVacation(action) {
	try {
		const response = yield call(deleteEmployeeVacation, action.payload.vacation);

		yield put(loadDeleteVacationSuccess(response));
	} catch (e) {
		yield put(loadDeleteVacationError());
	}
}

export function* loadEmployeeUpdateVacation(action) {
	try {
		const response = yield call(updateEmployeeVacation, action.payload.vacation);

		yield put(loadUpdateVacationSuccess(response));
	} catch (e) {
		yield put(loadUpdateVacationError());
	}
}

export function* loadSingleVacation(action) {
	try {
		const response = yield call(showEmployeeVacationById, action.payload.vacation);
		yield put(loadSingleVacationSuccess(response.data.data));
	} catch (e) {
		yield put(loadSingleVacationError());
	}
}

export default function* employeesAndVacationsSaga() {
	yield takeLatest(LOAD_VACATIONS_ALL, loadAllEmployeesAndVacations);
	yield takeLatest(LOAD_CALENDAR_VACATIONS_ALL, loadAllCalendarVacations);
	yield takeLatest(LOAD_VACATION_TYPES_ALL, loadAllVacationTypes);
	yield takeLatest(LOAD_NEW_VACATION, loadNewVacation);
	yield takeLatest(LOAD_DELETE_VACATION, loadDeleteVacation);
	yield takeLatest(LOAD_UPDATE_VACATION, loadEmployeeUpdateVacation);
	yield takeLatest(LOAD_SINGLE_VACATION, loadSingleVacation);
}