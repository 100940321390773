import {
	FunctionComponent,
	useState,
	useEffect,
	useRef,
	useCallback,
} from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import styles from "./UserSelect.module.scss";
import { setBackdropState } from "components/AppWrapper/actions";
import {
	setClearGlobalSearch,
	setGlobalSearchDone,
} from "components/NavBar/actions";
import { logOut, setLogo } from "App/actions";
import { User } from "types/user.type";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { globalState } from "types/globalState.type";
import { useHistory } from "react-router-dom";
import userImageLink from "assets/images/user.png";
import { getAuthUser } from "pages/Configurations/actions";
import { ConfigurationsState } from "types/reducers/configurations.reducer.type";

interface UserSelectProps {
	className?: string;
	user: User;
}

const UserSelect: FunctionComponent<UserSelectProps> = (props) => {
	const history = useHistory();
	const backdropParent: string = useSelector(
		(state: globalState) => state.appWrapperReducer.backdropParent
	);
	const roleId = useSelector(
		(state: globalState) => state.globalReducer.user?.roleId
	);

	const state: ConfigurationsState = useSelector(
		(state: globalState) => state.configurationsReducer
	);
	const loginCompanies = state?.companyLogin;

	const dispatch = useDispatch();
	const dropdownMenuRef = useRef<HTMLInputElement>(null);
	const [showDropdown, setShowDropdown] = useState(false);
	const [userLogo, setUserLogo] = useState(null);

	const menuToggleButtonHandler = () => {
		setShowDropdown(!showDropdown);
	};
	const listItemClickHandler = (company?: object) => {
		menuToggleButtonHandler();
	};

	const accountSettingsButtonClick = () => {
		menuToggleButtonHandler();
		history.push("/configurations");
	};

	const changePasswordButtonClick = () => {
		menuToggleButtonHandler();
		history.push("/changePassword");
	};

	const changeAccountButtonClick = () => {
		menuToggleButtonHandler();
		history.push("/companyLogin");
		localStorage.removeItem("token");
	};

	const logoutButtonClick = () => {
		menuToggleButtonHandler();
		dispatch(logOut());
		setLogo("");
	};

	useEffect(() => {
		const handleOutsideMenuClick = (e: any) => {
			if (!dropdownMenuRef?.current?.contains(e.target) && showDropdown) {
				menuToggleButtonHandler();
			}
		};
		if (showDropdown) window.addEventListener("click", handleOutsideMenuClick);
		dispatch(
			setBackdropState({
				showBackdrop: showDropdown,
				backdropParent: showDropdown ? "userSelect" : null,
			})
		);
		showDropdown && dispatch(setClearGlobalSearch(true));
		showDropdown && dispatch(setGlobalSearchDone(false));
		return () => window.removeEventListener("click", handleOutsideMenuClick);
	}, [showDropdown, dropdownMenuRef]);

	const navBarReducer = useSelector(
		(state: RootStateOrAny) => state.navBarReducer
	);
	const globalSearchDone = navBarReducer.globalSearchDone;

	const logoFromSettings = useSelector(
		(state: globalState) => state.configurationsReducer?.user?.logo
	);

	useEffect(() => {
		logoFromSettings && setUserLogo(logoFromSettings);
	}, [logoFromSettings]);

	useEffect(() => {
		if (localStorage.getItem("token")) {
			dispatch(getAuthUser());
		}
	}, [roleId]);

	return (
		<div
			className={`${styles.listContainer} ${globalSearchDone || showDropdown ? styles.dropDownShown : ""
				} ${backdropParent !== "userSelect" ? "lowZindex" : ""}`}
			ref={dropdownMenuRef}
		>
			<button onClick={menuToggleButtonHandler} className={styles.toggleButton}>
				<div className={styles.userContainer}>
					<img
						className={styles.userImage}
						src={userLogo ? userLogo : userImageLink}
						alt="User img"
						onError={(event) => {
							event.currentTarget.src = userImageLink;
						}}
					/>
					<span className={styles.userName}>{props.user?.name}</span>
				</div>
				<div className={styles.userMenuIcon}>
					{showDropdown ? <BsChevronUp /> : <BsChevronDown />}
				</div>
			</button>
			<div className={`${styles.menu} ${showDropdown ? styles.active : ""}`}>
				<div className={styles.listWrapper}>
					<div className={styles.listItem} onClick={accountSettingsButtonClick}>
						Konfigurimet e llogarisë
					</div>
					{(roleId === 3 || roleId === 4) && loginCompanies?.length > 1 ? (
						<div className={styles.listItem} onClick={changeAccountButtonClick}>
							Ndrysho kompaninë
						</div>
					) : null}
					<div className={styles.listItem} onClick={changePasswordButtonClick}>
						Ndrysho fjalëkalimin
					</div>
					<div className={styles.listItem} onClick={logoutButtonClick}>
						Shkyçja
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserSelect;
