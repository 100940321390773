import AppWrapper from "components/AppWrapper/AppWrapper.component";
import { useEffect, useState, useRef } from "react";
import styles from "./Documents.module.scss";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsUpload } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import { IconPencil } from "assets/icons/icons";
import Loader from "../../components/partials/Loader/Loader.component";
import Button from "../../components/Button/Button.component";
import TemplateBox from "../../components/TemplateBox/templateBox.component";
import CommonHeader from "../../components/partials/CommonHeader/CommonHeader.component";
import ModalCustom from "../../components/ModalCustom/ModalCustom.component";
import InputCustom from "../../components/InputCustom/InputCustom.component";
import {
	saveDocument,
	loadAllDocumentsTemplate,
	deleteSelectedDocuments,
} from "./actions";
import { toast } from "react-toastify";
import { Document } from "../../types/document.type";
import { globalState } from "types/globalState.type";
import noDocument from '../../assets/images/noDocuments.svg'

interface Props { }

const Documents: React.FC<Props> = (props) => {
	const [templateName, setTemplateName] = useState<string>("");
	const [isOnEdit, setIsOnEdit] = useState<boolean>(false);
	const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] =
		useState<boolean>(false);
	const [checkedDocuments, setCheckedDocuments] = useState<Array<number>>([]);
	const [isForDelete, setIsForDelete] = useState<boolean>(false);
	const [attachmentFile, setAttachmentFile] = useState(null);
	const [isAttachmentFilePicked, setIsAttachmentFilePicked] =
		useState<boolean>(false);
	const allDocuments = useSelector(
		(state: RootStateOrAny) => state.documentsReducer.allDocumentsTemplate
	);

	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);

	const isDocumentSelectedDelete = useSelector(
		(state: RootStateOrAny) => state.documentsReducer.isDocumentSelectedDeleted
	);
	const isLoading = useSelector(
		(state: RootStateOrAny) => state.documentsReducer.loading
	);
	const documentSaveLoading = useSelector(
		(state: RootStateOrAny) => state.documentsReducer.documentSaveLoading
	);

	const dispatch = useDispatch();
	const nameRef = useRef(null);
	const attachmentFileInput = useRef(null);

	useEffect(() => {
		dispatch(loadAllDocumentsTemplate(tenantId));
	}, [isDocumentSelectedDelete, documentSaveLoading]);

	const addTemplate = () => {
		setIsCreateTemplateModalOpen(true);
	};

	const handleTemplateName = (e) => {
		setTemplateName(e.target.value);
	};

	const handleEmployeeInfoInputFileChange = (event) => {
		event.target.files[0] && setAttachmentFile(event.target.files[0]);
		event.target.files[0] && setIsAttachmentFilePicked(true);
	};

	const attachmentButtonOnClick = (e) => {
		attachmentFileInput.current?.current &&
			attachmentFileInput.current.current.click();
	};

	const handleSaveDocument = () => {
		const payload: any = new FormData();
		payload.append("name", templateName);
		payload.append("tenantId", tenantId);
		payload.append("file", attachmentFile);

		if (templateName !== "" && attachmentFile != null) {
			dispatch(saveDocument(payload));
			dispatch(loadAllDocumentsTemplate(tenantId));
			setIsOnEdit(false);
			setTemplateName("");
			setAttachmentFile(null);
			setIsAttachmentFilePicked(false);
			setIsCreateTemplateModalOpen(false);
		} else {
			toast.error("Name and File are required to save template");
		}
	};

	const handleDeleteSelected = () => {
		if (checkedDocuments.length > 0) {
			dispatch(deleteSelectedDocuments(checkedDocuments));
			setCheckedDocuments([]);
		} else {
			setIsForDelete(!isForDelete);
		}
	};

	return (
		<AppWrapper appContentClass={styles.appContent}>
			<div className={styles.header}>
				<CommonHeader title="Administrimi i Dokumenteve" />
				<div className={styles.actionButtons}>
					<div className={styles.addTemplateBox} onClick={() => addTemplate()}>
						<AiOutlinePlusCircle
							className={styles.plusIcon}
							color="#89c732"
							width={24}
							height={24}
						/>
						<span className={styles.addTemplateText}>Shto shabllon</span>
					</div>
					<div
						className={styles.deleteTemplateBox}
						onClick={handleDeleteSelected}
					>
						<FiTrash2
							className={styles.plusIcon}
							color="#D52D0F"
							width={24}
							height={24}
						/>
						{checkedDocuments.length > 0 ? (
							<span className={styles.deleteTemplateText}>
								({checkedDocuments.length}){" "}
							</span>
						) : null}
					</div>
				</div>
			</div>
			{isLoading ? (
				<span className={styles.loader}>
					<Loader />
				</span>
			) : (
				<>
					<div className={styles.allTemplatesBox}>
						{allDocuments.length > 0 ?
							allDocuments.map((document: Document, idx: number) => {
								return (
									<TemplateBox
										name={document.name}
										id={document.id}
										file={document.file}
										key={idx}
										isChecked={document.isChecked}
										setCheckedDocuments={setCheckedDocuments}
										isForDelete={isForDelete}
									/>
								);
							}) : (
								<div className={styles.noDocumentWrapper}>

									<div >
										<img src={noDocument} alt="" />
									</div>
									<div>
										<h3 className={styles.noDocumentHeader}>Oops! Për momentin nuk ka dokumente.</h3>
										<p onClick={() => addTemplate()} className={styles.noDocumentText}>Kliko për të shtuar dokument</p>
									</div>
								</div>
							)}
					</div>
					<ModalCustom
						show={isCreateTemplateModalOpen}
						contentClass={styles.contentClass}
						submitLoading={documentSaveLoading}
						footerClass={styles.footerClass}
						onClose={() => {
							setIsCreateTemplateModalOpen((prev) => false);
						}}
						hideFooterButton={false}
						onSubmit={handleSaveDocument}
					>
						<div className={styles.modalContent}>
							<div className={styles.addVacationModal}>
								<span>Shto shabllon</span>
							</div>
							<div className={styles.formTemplateBox}>
								<div
									className={styles.templateName}
									onClick={() => {
										nameRef.current.focus();
										setIsOnEdit(!isOnEdit);
									}}
									onKeyDown={(e) => {
										const inputCondition = e.key === "Enter";
										inputCondition && handleSaveDocument();
									}}
								>
									<InputCustom
										onChangeHandler={handleTemplateName}
										className={styles.rowInput}
										name="occupation"
										value={templateName}
										placeholder={"Emri i template"}
										attributes={{
											autoFocus: true,
										}}
										readOnly={!isOnEdit}
										type="text"
										ref={nameRef}
									/>
									{!isOnEdit ? (
										<span
											className={styles.icon}
											onClick={() => {
												nameRef.current.focus();
												setIsOnEdit(!isOnEdit);
											}}
										>
											<IconPencil fill={"#89c732"} width="21px" height="20px" />
										</span>
									) : null}
								</div>
								<div className={styles.templateName}>
									<span className={styles.uploadIcon}>
										<BsUpload
											style={{ width: "21px", height: "20px" }}
											color={"#89c732"}
										/>
									</span>
									<div
										className={styles.row}
										onKeyDown={(e) => {
											const inputCondition = e.key === "Enter";
											inputCondition && handleSaveDocument();
										}}
									>
										<InputCustom
											onChangeHandler={handleEmployeeInfoInputFileChange}
											className={`${styles.rowInput} ${styles.attachmentRowInput}`}
											name="attachment"
											readOnly={!isOnEdit}
											type="file"
											ref={attachmentFileInput}
										/>
										<div
											className={` ${styles.rowInput} ${styles.attachmentRow} ${!isOnEdit ? styles.pointerEventsNone : ""
												}`}
										>
											<Button
												viewType="light"
												noHover={true}
												className={`${styles.button} ${styles.attachmentRowButton
													} ${isAttachmentFilePicked
														? styles.contractFilePicked
														: ""
													}`}
												onClickHandler={attachmentButtonOnClick}
											>
												{isAttachmentFilePicked ? (
													attachmentFile && (
														<span className={styles.attachmentsName}>
															{attachmentFile?.name}
														</span>
													)
												) : (
													<span className={styles.textUpload}>
														Ngarko shabllon
													</span>
												)}
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ModalCustom>
				</>
			)}
		</AppWrapper>
	);
};

export default Documents;
