import AppWrapper from 'components/AppWrapper/AppWrapper.component';
import Button from 'components/Button/Button.component';
import CommonHeader from 'components/partials/CommonHeader/CommonHeader.component';
import { FunctionComponent, useEffect } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './Tabs/Tabs.style.scss'
import styles from './Configurations.module.scss';
import UsersTab from './Tabs/UsersTab.component';
import PayrollTab from './Tabs/PayrollTab.component';
import HistoryTab from './Tabs/HistoryTab.component';
import BackupTab from './Tabs/BackupTab.component';
import SetupTab from './Tabs/SetupTab.component';
import CompaniesTab from './Tabs/CompaniesTab.component';

import { useDispatch, useSelector } from 'react-redux';
import { globalState } from 'types/globalState.type';
import * as actions from './actions';

import ConfigurationsModal from './Modal/Modal.component';
import { ConfigurationsState } from 'types/reducers/configurations.reducer.type';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';


const configurationTabs = ['Payshef', 'Lista e kompanive', 'Lista e përdoruesve', "Regjistri i auditimit", "Backup", "Konfigurimi"];

const Configurations: FunctionComponent = () => {
	const { t, i18n } = useTranslation('common');
	const dispatch = useDispatch();

	const roleId = useSelector((state: globalState) => state.globalReducer.user?.roleId);
	const state: ConfigurationsState = useSelector((state: globalState) => state.configurationsReducer);

	const handleHeaderButtonClick = (opener) => {
		dispatch(actions.setModalState({ show: true, opener }));
	}

	return (
		<AppWrapper appContentClass={styles.configurationsPage}>
			<CommonHeader
				title={t('configuration.configuration')}
				alignedButtons={true}
				titleClass={styles.headerTitle}
			>
				{roleId === 1 &&
					<Button
						viewType='shell'
						className={styles.headerButton}
						onClickHandler={() => { handleHeaderButtonClick("ADD_COMPANY") }}
					>
						<div className={`${styles.iconContainer}`}>
							<AiOutlinePlusCircle />
						</div>
						{t('configuration.addCompany')}
					</Button>
				}
				{(roleId === 1 || roleId === 2) &&
					<Button
						viewType='shell'
						className={styles.headerButton}
						onClickHandler={() => { handleHeaderButtonClick("ADD_ADMIN") }}
					>
						<div className={`${styles.iconContainer}`}>
							<AiOutlinePlusCircle />
						</div>
						{t('configuration.addAdmin')}
					</Button>
				}
				{(roleId === 1 || roleId === 2 || roleId === 3) &&
					<Button
						viewType='shell'
						className={styles.headerButton}
						onClickHandler={() => { handleHeaderButtonClick("ADD_USER") }}
					>
						<div className={`${styles.iconContainer}`}>
							<AiOutlinePlusCircle />
						</div>
						{t('configuration.addUsers')}
					</Button>
				}
			</CommonHeader>
			<Tabs>
				<TabList style={{ display: "flex", justifyContent: "space-between" }}>
					{configurationTabs.map((item, index) => {
						if (index === 1) {
							if (roleId === 1) {
								return <Tab key={index} title={item}> {item} </Tab>
							}
						} else {
							return <Tab key={index} title={item}> {item} </Tab>
						}
					})}
				</TabList>
				<div className={styles.tabsWrapper}>
					<TabPanel>
						<PayrollTab />
					</TabPanel>

					{roleId === 1 ?
						<TabPanel>
							<CompaniesTab />
						</TabPanel>
						: null}

					<TabPanel>
						<HistoryTab />
					</TabPanel>
					<TabPanel>
						<UsersTab />
					</TabPanel>
					<TabPanel>
						<BackupTab />
					</TabPanel>
					<TabPanel>
						<SetupTab />
					</TabPanel>
				</div>
			</Tabs>

			<ConfigurationsModal />

		</AppWrapper>
	)
};

export default Configurations;