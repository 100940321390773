import React from "react"
import styles from "./NavTab.module.scss";

type Props = {
    title: string;
    index: number;
    isSelected: boolean;
    setSelectedTab: (index: number) => void;
    tabClickHandler?: (index: number, isSelected: boolean) => void;
}

const NavTabTitle: React.FC<Props> = (props) => {
    const onClick = () => {
        props.setSelectedTab(props.index);
        props.tabClickHandler && props?.tabClickHandler(props.index, props.isSelected);
    };
    return (
        <li
            className={`${styles.tabListItem} ${props.isSelected ? styles.active : ""}`}
            onClick={onClick}
        >
            <div title={props.title}>{props.title}</div>
        </li>
    )
}

export default NavTabTitle;