export const getDate = () => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    var today: any = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = months[today.getMonth()];
    var yyyy = today.getFullYear();

    today = dd + " " + mm + " " + yyyy;
    return today;
}
