import { call, put, takeLatest } from "redux-saga/effects";
import {
  employeeSalaries,
  exportEcxel,
  generatePaymentSlip,
  getSalaryConfigurations,
  sendContractToEmail,
  sendPayslipToEmail,
  updateSalary,
  closeMonthSalary,
  openMonthSalary,
  getTotals,
  getGeneratedSalaries,
  updateSalaryV2,
} from "services/salaries.service";

import {
  closeMonthError,
  closeMonthSuccess,
  emailContractError,
  emailContractSuccess,
  emailPayslipError,
  emailPayslipSuccess,
  exportEcxelError,
  exportEcxelSuccess,
  generatePayslipError,
  generatePayslipSuccess,
  loadSalariesError,
  loadSalariesSuccess,
  openMonthSuccess,
  openMonthError,
  submitUpdateSalaryError,
  submitUpdateSalarySuccess,
} from "./actions";

import {
  CLOSE_MONTH,
  EMAIL_CONTRACT,
  EMAIL_PAYSLIP,
  EXPORT_EXCEL_SALARIES,
  GENERATE_PAYSLIP,
  GENERATE_SALARIES,
  LOAD_SALARIES,
  OPEN_MONTH_SALARY,
  UPDATE_SALARY,
} from "./constants";

export function* loadSalaries_saga(action) {
  try {
    const response = yield call(employeeSalaries, action.payload);
    const config = yield call(getSalaryConfigurations, action.payload.tenantId);
    let data: { salaries: {}; config: {} } = {
      salaries: response.data,
      config: config.data,
    };
    yield put(loadSalariesSuccess(data));
  } catch (e) {
    yield put(loadSalariesError());
  }
}

export function* generateSalaries(action) {
  try {
    yield call(getGeneratedSalaries, action.payload);
    const totalsResponse = yield call(getTotals, action.payload);
    const configResponse = yield call(
      getSalaryConfigurations,
      action.payload.tenantId
    );
    let data: { salaries: {}; config: {} } = {
      salaries: totalsResponse.data,
      config: configResponse.data,
    };
    yield put(loadSalariesSuccess(data));
  } catch (e) {
    yield put(loadSalariesError());
  }
}

export function* updateSalary_saga(action) {
  try {
    const response = yield call(updateSalary, action.payload);
    yield put(submitUpdateSalarySuccess(response.data));
  } catch (e) {
    yield put(submitUpdateSalaryError(e));
  }
}

export function* updateSalary_sagaV2(action) {
  try {
    const updateSalaryResponse = yield call(updateSalaryV2, action.payload);
    if (updateSalaryResponse.data?.errorMessage?.length > 0) {
      yield put(
        submitUpdateSalaryError(updateSalaryResponse.data?.errorMessage)
      );
      return;
    }
    const totalsResponse = yield call(getTotals, action.payload);
    yield put(submitUpdateSalarySuccess(totalsResponse.data));
  } catch (e) {
    yield put(submitUpdateSalaryError(e));
  }
}

export function* exportEcxel_saga(action) {
  try {
    const { tenantId, month, year } = action.payload;
    const response = yield call(exportEcxel, tenantId, month, year);
    yield put(exportEcxelSuccess(response));
  } catch (e) {
    yield put(exportEcxelError(e));
  }
}

export function* generatePayslip_saga(action) {
  try {
    const response = yield call(generatePaymentSlip, action.payload.salaryId);

    yield put(generatePayslipSuccess(response.data));
  } catch (e) {
    yield put(generatePayslipError(e));
  }
}

export function* emailPayslip_saga(action) {
  try {
    const response = yield call(sendPayslipToEmail, action.payload);
    yield put(emailPayslipSuccess(response));
  } catch (e) {
    yield put(emailPayslipError(e));
  }
}

export function* emailContract_saga(action) {
  try {
    const response = yield call(sendContractToEmail, action.payload);
    yield put(emailContractSuccess(response));
  } catch (e) {
    yield put(emailContractError(e));
  }
}

export function* closeMonth(action) {
  try {
    const response = yield call(closeMonthSalary, action.payload);
    yield put(closeMonthSuccess(response));
  } catch (e) {
    yield put(closeMonthError(e));
  }
}

export function* openMonth(action) {
  try {
    const response = yield call(openMonthSalary, action.payload);
    yield put(openMonthSuccess(response));
  } catch (e) {
    yield put(openMonthError(e));
  }
}

export default function* salariesSaga() {
  yield takeLatest(LOAD_SALARIES, generateSalaries);
  // yield takeLatest(UPDATE_SALARY, updateSalary_saga);
  yield takeLatest(UPDATE_SALARY, updateSalary_sagaV2);
  yield takeLatest(EXPORT_EXCEL_SALARIES, exportEcxel_saga);
  yield takeLatest(GENERATE_PAYSLIP, generatePayslip_saga);
  yield takeLatest(EMAIL_PAYSLIP, emailPayslip_saga);
  yield takeLatest(EMAIL_CONTRACT, emailContract_saga);
  yield takeLatest(CLOSE_MONTH, closeMonth);
  yield takeLatest(OPEN_MONTH_SALARY, openMonth);
}
