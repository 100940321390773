import styles from "./NavBar.module.scss";
import GlobalSearch from "components/GlobalSearch/GlobalSearch.component";
import UserSelect from "components/UserSelect/UserSelect.component";
import {
	LanguageIcon,
	QuickBooksIcon,
	NotificationIcon,
	HelpIcon,
} from "assets/icons/icons";
import { IconWrapper } from "components/IconWrapper/IconWrapper.component";
import { useDispatch, useSelector } from "react-redux";
import { User } from "types/user.type";
import DropdownSelect from "components/DropdownSelect/DropdownSelect.component";
import { languageList } from "utils/globalVariables";
import { globalState } from "types/globalState.type";
import { setBackdropState } from "components/AppWrapper/actions";
import { setLanguage, syncQuickbooks } from "./actions";
import { FunctionComponent, useState } from "react";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import { NotificationsDropdown } from "../NotificationDropdownComponent/notificationsDropdown.component";
import { Notification } from "../../types/notification.type";
import t from "i18next";
import { useTranslation } from "react-i18next";
import * as actions from "../../pages/Configurations/actions";

interface NavbarProps {
	user?: User;
}

const NavBar: FunctionComponent<NavbarProps> = (props: NavbarProps) => {
	const { t, i18n } = useTranslation("common");
	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);
	const dispatch = useDispatch();

	const state: globalState = useSelector((state) => state);
	const user = state.globalReducer.user;
	const globalSearchDone = state.navBarReducer.globalSearchDone;
	const backdropParent: string = useSelector(
		(state: globalState) => state.appWrapperReducer.backdropParent
	);
	const notifications: Notification[] | undefined = useSelector(
		(state: globalState) => state.globalReducer.notifications
	);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isNotificationsSelected, setIsNotificationsSelected] =
		useState<boolean>(false);

	const handleLanguagePick = (event, item) => {
		dispatch(setLanguage(tenantId, item));
		if (item.label === `Shqip`) {
			i18n.changeLanguage("al");
		} else {
			i18n.changeLanguage("en");
		}
	};

	const handleSeeAllNotifications = (event) => {
		dispatch(setBackdropState({ showBackdrop: false }));
	};

	const showNavbarDropdownHandler = (event, type) => {
		if (type === "notificationsMenu") {
			setIsNotificationsSelected(true);
		}

		dispatch(
			setBackdropState({
				showBackdrop: true,
				backDrop_callback: () => { },
				backdropParent: type,
			})
		);
	};

	const handleIsModalOpen = () => {
		setIsModalOpen(true);
	};

	return (
		<div
			className={`${styles.navBar} ${(globalSearchDone && styles.dropDownShown) || ""
				}`}
		>
			<GlobalSearch />
			<div className={`${styles.iconsContainer}`}>
				<DropdownSelect
					data={languageList}
					buttonText={
						<IconWrapper>
							<LanguageIcon invert={globalSearchDone} />
						</IconWrapper>
					}
					className={`${styles.navIcon} ${backdropParent !== "languageDropdown" ? "lowZindex" : ""
						}`}
					menuClass={`${styles.dropdownMenu} ${styles.languageMenu}`}
					showArrow={false}
					showTopArrow={true}
					noWidth={true}
					static={true}
					showShadow={true}
					itemClass={styles.languageItem}
					activeClass={styles.active}
					wideMenu={true}
					showDropdownCallback={(event) => {
						showNavbarDropdownHandler(event, "languageDropdown");
					}}
					optionClick={(event, item) => {
						handleLanguagePick(event, item);
					}}
				/>
				<DropdownSelect
					data={languageList}
					buttonText={
						<IconWrapper>
							<NotificationIcon
								invert={globalSearchDone}
								isSelected={isNotificationsSelected}
								hasNotifications={notifications && notifications?.length > 0}
							/>
						</IconWrapper>
					}
					className={`${styles.navIcon} ${backdropParent !== "notificationsMenu" ? "lowZindex" : ""
						}`}
					menuClass={styles.dropdownMenu}
					showArrow={false}
					showTopArrow={true}
					noWidth={true}
					static={true}
					showShadow={true}
					activeClass={styles.active}
					showDropdownCallback={(event) => {
						showNavbarDropdownHandler(event, "notificationsMenu");
					}}
					onClose={() => {
						setIsNotificationsSelected(false);
					}}
				>
					<NotificationsDropdown notifications={notifications} />
				</DropdownSelect>
				<IconWrapper>
					<div onClick={() => handleIsModalOpen()}>
						<HelpIcon invert={globalSearchDone} />
					</div>
				</IconWrapper>
			</div>
			{isModalOpen && (
				<ModalCustom
					show={isModalOpen}
					contentClass={`${styles.contentClass} ${styles.modalPayroll}`}
					footerClass={styles.footerClass}
					onClose={() => {
						setIsModalOpen((prev) => false);
					}}
					hideFooterButton={true}
				>
					<div className={styles.title}>Përkrahja</div>
					<div className={styles.nameBox}>
						<div className={styles.name}>Numri i telefonit:</div>
						<div className={styles.tenantName}>+38344698098</div>
					</div>
					<div className={styles.emailBox}>
						<div className={styles.email}>Email:</div>
						<div className={styles.emailAddress}>support@melita-partners.com</div>
					</div>
				</ModalCustom>
			)}
			<UserSelect user={user} />
		</div>
	);
};

export default NavBar;
