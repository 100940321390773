import { useTranslation } from 'react-i18next';
import produce from 'immer';
import {
  LOAD_REPORT_STATISTICS,
  LOAD_REPORT_STATISTICS_SUCCESS,
  LOAD_REPORT_STATISTICS_ERROR,
  DOWNLOAD_REPORT_STATISTICS_PDF,
  DOWNLOAD_REPORT_STATISTICS_PDF_SUCCESS,
  DOWNLOAD_REPORT_STATISTICS_PDF_ERROR,
  DOWNLOAD_REPORT_STATISTICS_EXCEL,
  DOWNLOAD_REPORT_STATISTICS_EXCEL_SUCCESS,
  DOWNLOAD_REPORT_STATISTICS_EXCEL_ERROR,
  LOAD_EMAIL_STATISTICS,
  LOAD_EMAIL_STATISTICS_SUCCESS,
  LOAD_EMAIL_STATISTICS_ERROR,
} from './constants';
import {toast} from 'react-toastify';

const initialState: any = {
  loading: false,
  allStatisticsReport: [],
  totalStatistics: null,
  isExcelStatisticsLoading:false,
  isPdfStatisticsLoading: false,
};

const statisticsReportReducer = (state = initialState, action) =>
    produce(state, draft => {
      switch (action.type) {
        case LOAD_REPORT_STATISTICS:
          draft.loading = true;
          break;
        case LOAD_REPORT_STATISTICS_SUCCESS:
          let newArr = [];
          action.payload.statistics.data.monthlyStatistics.map((month) => {
            if (newArr.length === 0) {
              newArr.push(month);
            } else {
              const dayToFind = newArr.findIndex(s => s.monthId === month.monthId);
              if (dayToFind === -1) {
                newArr.push(month);
              }
            }
          });

          newArr.sort(function(a, b) {
            return a.monthId - b.monthId;
          });
          draft.allStatisticsReport = newArr;
          draft.totalStatistics = action.payload.statistics.data.total;
          draft.loading = false;
          break;
        case LOAD_REPORT_STATISTICS_ERROR:
          draft.loading = true;
          toast.error('Diçka shkoi gabim!');
          break;
        case DOWNLOAD_REPORT_STATISTICS_PDF:
          draft.isPdfStatisticsLoading = true;
          break;
        case DOWNLOAD_REPORT_STATISTICS_PDF_SUCCESS:
          window.open(action.payload.contributions.data, '_blank');
          draft.isPdfStatisticsLoading = false;
          break;
        case DOWNLOAD_REPORT_STATISTICS_PDF_ERROR:
          draft.isPdfStatisticsLoading = false;
          toast.error('Diçka shkoi gabim!');
          break;
        case DOWNLOAD_REPORT_STATISTICS_EXCEL:
          draft.isExcelContributionLoading = true;
          break;
        case DOWNLOAD_REPORT_STATISTICS_EXCEL_SUCCESS:
          window.open(action.payload.contributions.data, '_blank');
          draft.isExcelContributionLoading = false;
          break;
        case DOWNLOAD_REPORT_STATISTICS_EXCEL_ERROR:
          draft.isExcelContributionLoading = false;
          toast.error('Diçka shkoi gabim!');
          break;
          case LOAD_EMAIL_STATISTICS:
            draft.isExcelContributionLoading = true;
            break;
          case LOAD_EMAIL_STATISTICS_SUCCESS:
            toast.success('Email u dergua me sukses!');
            break;
          case LOAD_EMAIL_STATISTICS_ERROR:
            toast.error('Diçka shkoi gabim!');
          break;
        default:
          break;
      }
    });

export default statisticsReportReducer;