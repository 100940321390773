import { FunctionComponent } from 'react';

import LoginForm from "./LoginForm";
import  styles from "./Login.module.scss";
import payshefLogoWide from 'assets/icons/payshefLogoWide.svg';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Translation = (param) =>{
    const { t,i18n } = useTranslation('common');
    return t(param);
}

const Login: FunctionComponent = (props) => {
    let errorMessage = localStorage.getItem('singleErrorMessage');
    if (errorMessage) {
        toast.error(Translation('toast.notLogin'));
        localStorage.removeItem('singleErrorMessage');
    }

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <img className={styles.logo} src={payshefLogoWide} alt="Product logo" />
            </div>
            <div className={styles.right}>
                <LoginForm />
            </div>
        </div>
    )
};

export default Login;
