import classNames from "classnames";

import Checkbox from "react-custom-checkbox";


import { FiCheck } from "react-icons/fi";
import styles from "pages/Calculations/Calculations.module.scss";
import tableStyles from 'components/Table/Table.module.scss';

import { number_format } from "utils/functions";

const TableHeader = (props) => {

	const salariesRowCount = props.salariesRowCount;
	let tableHeaderData = [
		[
			"Të gjithë",
			"",
			"Totali",
			"Totali",
			"Totali",
			"Totali",
			"Totali",
			"Totali",
			"Totali",
			"Totali",
			"Totali",
			"Totali"
		],
		[
			"",
			"Emri dhe Mbiemri",
			"ID",
			"Paga bruto",
			"Orët shtesë",
			"Shtesat",
			"Totali i Pagës Bruto",
			{ title: "Kontributi Pensional", subTitle: "Punonjësi / Punëdhënësi" },
			"Paga që tatohet",
			"Tatimi",
			"Ndalesa për sigurim shëndetësor",
			"Ndalesat",
			"Paga neto"
		],
	];

	const checkboxThClasses = classNames({
		[tableStyles.headerCell]: true,
		[tableStyles.noBackground]: true,
		[tableStyles.noRadiusBottom]: true,
		[styles.checkboxCell]: true,
		"pt-0": true,
	});
	const numberCellIndexes = [0, 2, 3, 4, 5];

	return <thead className={`${tableStyles.tableHead} ${props.headerClass || ""} ${styles.tableHeader}`}>
		<tr className={`${tableStyles.headerRow} ${styles.tableMainRow} `} >
			{
				<th rowSpan={2} className={checkboxThClasses} >
					<Checkbox
						className={styles.checkbox}
						icon={
							<div className={styles.checkboxIconContainer} >
								<FiCheck color="#261f63" size={22} />
							</div>
						}
						checked={props.mainCheckboxState}
						onChange={(event) => {
							props.setMainCheckboxClick && props.setMainCheckboxClick(prev => true);
							props.setMainCheckboxState && props.setMainCheckboxState(prev => !props.mainCheckboxState);
						}}
					/>
				</th>
			}
			{tableHeaderData[0].map((cell, index) => {
				const firstRowThClassnames = classNames({
					[tableStyles.headerCell]: true,
					[tableStyles.noBackground + " " + tableStyles.noRadiusBottom]: true,
					[styles.numberCell]: (numberCellIndexes.includes(index) && index !== 0 && index !== 1),
					[styles.noWidth]: (index === 0 || index === 1),
					[styles.allTitle]: index === 0,
					"pl-0": index === 0,
				})

				return <th key={index} className={`${firstRowThClassnames}`} >
					{typeof cell === 'string' || typeof cell === "number" ? cell
						:
						<div>
							<div className={`noWrap`}>{cell?.title}</div>
							<div style={{ whiteSpace: "nowrap" }} className={styles.tableHeaderSubtitle}>{cell?.subTitle}</div>
						</div>
					}
				</th>
			})}
		</tr>
		<tr className={`${tableStyles.headerRow} ${styles.headerDataRow}`}>
			<th className={`pl-0 ${styles.noWidth} ${tableStyles.headerCell} ${tableStyles.noRadiusAll + " " + tableStyles.noBackground}`} >
				{salariesRowCount}
			</th>
			<th className={`${tableStyles.headerCell} ${tableStyles.noRadiusAll + " " + tableStyles.noBackground}`} >

			</th>
			{props.totals
				?
				Object.entries(props.totals).map((item, index) => {

					let value = item[1];
					value = number_format(value, 2);
					const secondRowClasses = classNames({
						[tableStyles.headerCell]: true,
						[tableStyles.noRadiusAll + " " + tableStyles.noBackground]: true,
						[styles.numberCell]: numberCellIndexes.includes(index),
						[styles.noWidth]: index === 0,
					});
					return <th key={index} className={secondRowClasses} >
						{value}
					</th>
				})
				:
				tableHeaderData[0].map((item, index) => {
					const secondRowClasses = classNames({
						[tableStyles.headerCell]: true,
						[tableStyles.noRadiusAll + " " + tableStyles.noBackground]: true,
						[styles.numberCell]: numberCellIndexes.includes(index),
						[styles.noWidth]: index === 0,
					});

					if (index < tableHeaderData[0].length - 2)
						return <th key={index} className={secondRowClasses}></th>;
					else
						return false;
				})
			}
		</tr>
		<tr className={`${tableStyles.headerRow}`}>
			{tableHeaderData[1].map((cell, index) => {
				const thirdRowThClassnames = classNames({
					[tableStyles.headerCell]: true,
					[tableStyles.noRadiusAll]: true,
					[styles.numberCell]: numberCellIndexes.includes(index) && index !== 0 && index !== 1 && index !== 2,
					[styles.noWidth]: (index === 0 || index === 1),
					[styles.mainHeaderCell]: true,
					"pl-0": index === 1,
					[styles.noWrap]: index === 0 || index === 1 || index === 2,

				})
				return <th key={index} className={`${thirdRowThClassnames}`} >
					{typeof cell === 'string' || typeof cell === "number" ? cell
						:
						<div>
							<div className={`noWrap`}>{cell?.title}</div>
							<div style={{ whiteSpace: "nowrap" }} className={styles.tableHeaderSubtitle}>{cell?.subTitle}</div>
						</div>
					}
				</th>
			})}
		</tr>
	</thead>
}

export default TableHeader;