import classNames from "classnames";
import { format } from "date-fns";
import React, { useEffect, useImperativeHandle, useRef } from "react";
import { futureLimitDate, validEmail, validPhone } from "utils/globalVariables";
import styles from "./InputCustom.module.scss";

const todayDate = format(new Date(), 'yyyy-MM-dd');

interface Props {
	children?: React.ReactNode;
	value?: any;
	name?: string;
	placeholder?: string;
	type?: string;
	readOnly?: boolean;

	style?: object;
	className?: string;
	inputClass?: string;
	iconStyle?: string;

	onClick?: any;
	onKeyDown?: any;
	onBlur?: any;
	onKeyUp?: any;
	onChangeHandler?: any;
	onFocusHandler?: any;
	onWheel?: any;

	max?: string;
	min?: string;
	noFuture?: boolean;
	noPast?: boolean;
	positiveInteger?: boolean;
	customType?: string;
	isRequired?: boolean;
	specificValidation?: boolean;
	validationText?: string;
	attributes?: any;
	autoComplete?: any;
	pattern?: any;
	for?: any;
}


const InputCustom = React.forwardRef<any, Props>((props: Props, ref) => {
	const inputRef = useRef(null);
	useImperativeHandle(ref, () => ({
		focus: () => {
			inputRef?.current.focus();
		},
		current: inputRef.current,
	}));

	const inputClasses = classNames({
		[props.inputClass]: props?.inputClass,
		[styles.isRequired || ""]: props.isRequired && !props?.attributes?.disabled,
	});

	useEffect(() => {
		if (
			inputRef?.current
			&&
			props?.isRequired
			&&
			props?.value
			&&
			!props?.specificValidation
		) {
			inputRef?.current?.parentNode?.classList?.remove("inputInvalid");
		}

	}, [props])

	return <div
		className={`${styles.inputCustom} ${props.className || ""}`}
		style={props.style}
	>
		{
			props?.children ?
				<div className={`${styles.icon} ${props.iconStyle ? props.iconStyle : ""}`}>
					{props.children}
				</div>
				:
				""
		}
		{
			!props?.attributes?.disabled && props.isRequired &&
			<span className={styles.asterix}>*</span>
		}
		{
			props.validationText && !props?.attributes?.disabled
				?
				<div className={styles.validationContainer}>
					<div className={'validationText'}>
						{props.validationText}
					</div>
				</div>
				: ""
		}

		<input
			type={props.type || "text"}
			pattern={props?.pattern}
			for={props?.for}
			placeholder={props.placeholder}
			value={(props.value === 0 ? props.value : (props.value || ""))}
			onClick={props.onClick}
			ref={inputRef}
			name={props.name || ""}
			className={inputClasses}
			readOnly={props.readOnly}
			onFocus={props.onFocusHandler}
			autoComplete="new-password"
			onWheel={(event) => { props.onWheel && props.onWheel(event); }}
			onKeyDown={(event: any) => {
				if (props.type === "number" && event.key === ",") {
					event.preventDefault();
				}

				if (props.positiveInteger && event?.key === ".") {
					event.preventDefault();
				}

				if (
					props.type === "number" &&
					(
						event.key === "e" ||
						(
							props.positiveInteger &&
							(event.key === "+" || event.key === "-")
						)
					)
				) {
					event.preventDefault();
					return false;
				}

				if (props.customType === "phone") {
					if (validPhone.test(event.target.value)) {
						event?.target?.parentNode?.classList?.remove("inputInvalid");
					}
				}

				if (props.type === "email") {
					if (validEmail.test(event.target.value)) {
						event?.target?.parentNode?.classList?.remove("inputInvalid");
					}
				}

				props.onKeyDown && props.onKeyDown(event);
			}}
			onKeyUp={(event) => {
				props.onKeyUp && props.onKeyUp(event);
			}}
			onChange={(event: any) => {
				const value = event?.target?.value;
				if (props.type === "date" && props.noFuture) {
					let resetCheck = new Date(todayDate) < new Date(value);
					resetCheck && (event.target.value = todayDate);
				}

				if (props.type === "date" && props.noPast) {
					const value = event?.target?.value;
					let resetCheck = +todayDate.split("-")[0] < +value.split("-")[0];
					resetCheck && (event.target.value = todayDate);
				}

				// if (props.isRequired && !value) {
				// 	event?.target?.parentNode?.classList?.add("inputInvalid");
				// } else {
				// 	if(props.customType !== "phone"){
				// 		event?.target?.parentNode?.classList?.remove("inputInvalid");
				// 	}
				// }

				// if (props.customType === "phone" && value.length > 0) {
				// 	var regEx = /^[+]?\d+$/;

				// 	if (regEx.test(value)) {
				// 		event?.target?.parentNode?.classList?.remove("inputInvalid");
				// 	} else {
				// 		event.preventDefault();
				// 	return false;
				// 	}
				// }

				if (props.type === "email") {
					if (validEmail.test(value)) {
						event?.target?.parentNode?.classList?.remove("inputInvalid");
					} else {
						event?.target?.parentNode?.classList?.add("inputInvalid");
					}
				}

				props.onChangeHandler && props.onChangeHandler(event);
			}}
			onBlur={(event: any) => {

				if (props.isRequired && !event.target?.value) {
					event?.target?.parentNode?.classList?.add("inputInvalid")
				}
				else {
					event?.target?.parentNode?.classList?.remove("inputInvalid")
				}

				if (props.type === "email") {
					if (validEmail.test(event.target.value)) {
						event?.target?.parentNode?.classList?.remove("inputInvalid");
					} else {
						event?.target?.parentNode?.classList?.add("inputInvalid");
					}
				}

				// if (props.customType === "phone") {
				// 	if (validPhone.test(event.target.value)) {
				// 		event?.target?.parentNode?.classList?.remove("inputInvalid");
				// 	} else {
				// 		event?.target?.parentNode?.classList?.add("inputInvalid");
				// 	}
				// }

				props.onBlur && props.onBlur(event);
			}}
			min={
				props.type === "number" ? (props.min ? props.min : "")
					:
					(props.type === "date" ?
						(props.min ? props.min : (props.noPast ? todayDate : "")) : ""
					)
			}
			max={
				props.type === "date" ? (props?.max ? props.max : (props.noFuture ? todayDate : futureLimitDate))
					: (props.max ? props.max : "")
			}
			{...props?.attributes}
		/>
	</div>;
})

export default InputCustom