
import { CSSProperties, FunctionComponent } from "react";
import LoaderCentered from "../LoaderCentered/LoaderCentered.component";
import styles from "./InputReports.module.scss";

interface Props {
    className?: string;
    inputClass?: string;
    style?: CSSProperties;
    text?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    static?: boolean;
    value?: any;
    loading?: boolean;
    name?: string;
}

const InputReports: FunctionComponent<Props> = (props) => {
    return <div className={`${styles.wrapper} ${props.className ? props.className : ""}`}>
        <span className={styles.text}>
            {props.text}
        </span>
        {
            props.loading ? <LoaderCentered style={{ height: "30px", width: "30px" }} /> : <>
                {
                    props.static
                        ?
                        <div
                            className={`${styles.input} ${props.inputClass ? props.inputClass : ""}`}
                            title={props.value === 0 ? 0 : (props.value || "")}
                        >
                            {props.value === 0 ? 0 : (props.value || "")}
                        </div>
                        :
                        <input
                            type="text"
                            className={`${styles.input} ${props.inputClass ? props.inputClass : ""}`}
                            onChange={(event) => props.onChange && props.onChange(event)}
                            readOnly={props.static ? true : false}
                            value={props.value === 0 ? 0 : (props.value || "")}
                            title={props.value === 0 ? 0 : (props.value || "")}
                            name={props.name}
                        />

                }
            </>
        }
    </div>
}

export default InputReports;



