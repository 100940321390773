import React, { useState, useRef, useEffect } from 'react';
import styles from './Statistics.module.scss';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { format } from 'date-fns';
import classNames from 'classnames';

import InputCustom from 'components/InputCustom/InputCustom.component';
import Table from 'components/Table/Table.component';
import ModalCustom from 'components/ModalCustom/ModalCustom.component';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect.component';

import { generateArrayOfYears } from 'utils/functions';
import { globalState } from 'types/globalState.type';
import { downloadTypeOptions } from 'utils/globalVariables';
import { FiCalendar, FiDownload } from 'react-icons/fi';
import { IconDocument } from 'assets/icons/icons';

import * as tableStyles from 'components/Table/Table.module.scss';
import * as calculationStyles from "pages/Calculations/Calculations.module.scss";

import {
	loadReportStatistics,
	loadEmailStatistics,
	downloadReportStatisticsPdf,
	downloadReportStatisticsExcel
} from './actions';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
interface Props {
	tableHeaderData?: Array<string>,
	show: boolean,
	setIsStatisticsShowed?: any,
	tableHeaderTitle?: string,
	data?: any,
}

let currentYear: number = new Date().getFullYear();
let yearsList = generateArrayOfYears(21);


const pageStyle = `
	@page {
		margin: 10px 20px;
		font-size: 15px;
	}
	.noPrint {
		display: none !important;
	}
	.footer {
		position: fixed;
		bottom: 10px;
	}
	.modalPrint {
		font-size: 13px !important;
	}
	.print-padding {
		padding: 0 20px;
	}
	.reportDropdown__indicator {
		display:none !important;
	}
	.printHeightFix {
		height: unset !important;
		min-height: unset !important;
		overflow: initial !important;
	}
`;

const Statistics: React.FC<Props> = props => {
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);
	const { t, i18n } = useTranslation('common');
	const dispatch = useDispatch();
	const backdropParent: string = useSelector((state: globalState) => state.appWrapperReducer.backdropParent);

	const componentRef = useRef<HTMLDivElement>(null);
	const todayDate = format(new Date(), 'dd.MM.yyyy');
	const [showFilterContent, setShowFilterContent] = useState(false);
	const [pickedYear, setPickedYear] = useState(currentYear);
	const [isEmailOpen, setIsEmailOpen] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [emailValidMessage, setEmailValidMessage] = useState<string>("");

	const allStatistics = useSelector((state: RootStateOrAny) => state.statisticsReportReducer.allStatisticsReport);
	const totalStatistics = useSelector((state: RootStateOrAny) => state.statisticsReportReducer.totalStatistics);

	const periodWrapperRef = useRef(null);
	const dropdownMenuRef = useRef<HTMLInputElement>(null);


	const tableHeaderData = [t('monthlyStats.month'), t('monthlyStats.brutoSalary'), t('monthlyStats.employeerContribution'), t('monthlyStats.employeeContribution'), t('monthlyStats.taxedSalary'), t('monthlyStats.incomeTax'), t('monthlyStats.netoSalary')];


	useEffect(() => {
		const payload = {
			tenantId,
			year: pickedYear,
		}
		dispatch(loadReportStatistics(payload));
	}, [pickedYear])

	const handleSendEmail = () => {
		setIsEmailOpen(true);
	}

	const handleEmailChange = (e) => {
		const emailRegex = /\S+@\S+\.\S+/;
		setEmail(e.target.value);
		if (emailRegex.test(email)) {
			setIsEmailValid(true);
		} else {
			setIsEmailValid(false);
			setEmailValidMessage('Please enter a valid email!');
		}
	};

	const handlePeriodContainerClick = (event) => {
		setShowFilterContent(prev => !prev);
	}


	useEffect(() => {
		const handleOutsideMenuClick = (e: any) => {
			setShowFilterContent(!showFilterContent);
		}

		if (showFilterContent) window.addEventListener("click", handleOutsideMenuClick);
		return () => window.removeEventListener("click", handleOutsideMenuClick);
	}, [showFilterContent]);


	const handleDownloadPick = (e, item) => {
		if (item.value === 1) {
			dispatch(downloadReportStatisticsExcel(tenantId, pickedYear));
		} else {
			dispatch(downloadReportStatisticsPdf(tenantId, pickedYear));
		}
	};

	const handleOpenModal = () => {
		setIsEmailOpen(false);
	};

	const handleSaveDocument = () => {
		if (email !== "" && isEmailValid) {
			dispatch(loadEmailStatistics(email));
		}

		setIsEmailOpen(false);
	}

	const numberCellIndexes = [0, 2, 3, 4, 5];



	return (
		<div className={styles.template}>
			<div className={styles.templateBox}>
				<ModalCustom
					show={props.show}
					contentClass={styles.contentClass}
					footerClass={styles.footerClass}
					ref={componentRef}
					onClose={() => {
						props.setIsStatisticsShowed(prev => false);
					}}
					hideFooterButton={true}
				>
					<div className={styles.titleHeader}>
						<div className={styles.point}>
						</div>
						<div className={styles.orderPaymentHeader}>
							{t('reports.monthlyStatistics')}
						</div>
						<div className={`${styles.downloadText} noPrint`}>
							<div className={styles.dropdownText}>
								<DropdownSelect
									data={downloadTypeOptions}
									buttonText={<div className={`${styles.dropdownText} pr-5`}>
										<div className={styles.downloadIcon}>
											<FiDownload strokeWidth="2px" />
										</div>
										{t('reports.download')}
									</div>}
									showArrow={true}
									noWidth={true}
									menuPosition="right"
									optionClick={(event, item) => {
										handleDownloadPick(event, item);
									}}
								/>
							</div>
						</div>
						<div className={`${styles.downloadText} ${styles.iconText} noPrint`} onClick={handleSendEmail}>
							<div className={styles.sendEmailIcon}>
								<FiDownload strokeWidth="2px" />
							</div>
							{t('salaryCalculation.sendEmail')}
						</div>
						<div className={`${styles.printIcon} noPrint`}>
							<IconDocument stroke={'#89C732'} />
						</div>
						<div className={`${styles.downloadText} ${styles.iconText} noPrint`}>
							<ReactToPrint
								trigger={() => <div className={styles.printText}>{t('reports.print')}</div>}
								content={() => componentRef.current}
								pageStyle={pageStyle}
							/>
						</div>
						<div className={styles.headerDate}>
							Data: {todayDate}
						</div>
					</div>
					<div className={`row ${styles.filterRow}`} ref={dropdownMenuRef}>
						<div className={`${styles.filterDescription}`}>
							<span className="noPrint">
								{t('reports.filterPeriod')}
							</span>
						</div>
						<div
							className={`${styles.filterButtons} ${backdropParent !== "calculationsPeriodFilter" ? "lowZindex" : ""}`}
						>
							<div className={`${styles.button} cursorAuto`}>
								{t('reports.year')}
							</div>
							<div className={`${styles.button} ${styles.yearsButton}`}
								onClick={handlePeriodContainerClick}
							>
								{pickedYear}
								<div className={styles.calendar}>
									<FiCalendar />
								</div>
							</div>
						</div>
						{showFilterContent &&
							<div className={styles.filterContent} ref={periodWrapperRef}>
								<div className={styles.years}>
									{yearsList.map((item, index) => {
										return <div
											key={index}
											className={`${styles.periodItem} ${item === pickedYear ? styles.selected : ""}`}
											onClick={() => {
												setPickedYear(prev => +item)
												setShowFilterContent(prev => false)
											}}
										>
											{item}
										</div>
									})}
								</div>
							</div>
						}
					</div>
					<div className={styles.tableContainer}
					>
						<Table
							tableHeader={
								<thead className={`${tableStyles.tableHead} ${calculationStyles.tableHeader}`}>
									<tr>
										<td className={tableStyles.headerCell}>{t('reports.total')}</td>
										<td className={`${styles.totalCell}`}>
											<div>
												{totalStatistics?.totalBrutto}
											</div>
										</td>
										<td className={`${styles.totalCell}`}>
											<div>
												{totalStatistics?.totalPersonalContribute}
											</div>
										</td>
										<td className={`${styles.totalCell}`}>
											<div>
												{totalStatistics?.totalEmployerContribute}
											</div>
										</td>
										<td className={`${styles.totalCell}`}>
											<div>
												{totalStatistics?.totalTaxableSalary}
											</div>
										</td>
										<td className={`${styles.totalCell}`}>
											<div>
												{totalStatistics?.totalTax}
											</div>
										</td>
										<td className={`${styles.totalCell}`}>
											<div>
												{totalStatistics?.totalNetto}
											</div>
										</td>

									</tr>
									<tr className={`${tableStyles.headerRow} ${calculationStyles.tableMainRow} `} >
										{tableHeaderData.map((cell, index) => {
											const firstRowThClassnames = classNames({
												[tableStyles.headerCell]: true,
												[calculationStyles.numberCell]: (numberCellIndexes.includes(index) && index !== 0 && index !== 1),
												[calculationStyles.noWidth]: (index === 0 || index === 1),
												[calculationStyles.allTitle]: index === 0,
											})

											return <th key={index} className={`${firstRowThClassnames}`} >
												{cell}
											</th>
										})}
									</tr>

								</thead>
							}
							tableClass={styles.tableClass}
							headerClass={styles.headerClass}>
							<tbody className={styles.tableBody} >
								{allStatistics?.length > 0 && allStatistics.map((data, idx) => {
									return <tr className={styles.tableRow} key={idx}>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											<div>
												{data?.month}
											</div>
										</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.brutto}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.personalContribute}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.employerContribute}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.taxableSalary}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.tax}</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>{data?.netto}</td>
									</tr>;
								})}
							</tbody>
						</Table>
					</div>

				</ModalCustom>
			</div>
			{isEmailOpen ?
				<div>
					<ModalCustom
						show={isEmailOpen}
						contentClass={styles.contentClass}
						hideFooterButton={false}
						submitText={"Dërgo"}
						footerClass={styles.footerClass}
						onClose={handleOpenModal}
						onSubmit={handleSaveDocument}
					>
						<div className={`${styles.downloadText} ${styles.customModal} ${styles.iconText}`} onClick={handleSendEmail}>
							<div className={styles.sendEmailIcon}>
								<FiDownload strokeWidth="2px" />
							</div>
							{t('reports.monthlyStatistics')}
						</div>
						<InputCustom
							onChangeHandler={handleEmailChange}
							className={styles.rowInputEmail}
							name="occupation"
							value={email}
							placeholder={'Enter e-mail'}
							attributes={{ autoFocus: true, }}
							type="text"
						/>
						{!isEmailValid && email !== "" ? <span className={styles.validateEmail}>{emailValidMessage}</span> : null}
					</ModalCustom>
				</div>
				: null}
		</div>
	);
};

export default Statistics;