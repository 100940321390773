import { UpcomingVacation } from "types/upcomingVacation.type"
import { UpcomingBirthday } from "types/upcomingBirthday.type"
import {
	LOAD_UPCOMING_BIRTHDAYS, LOAD_UPCOMING_BIRTHDAYS_ERROR, LOAD_UPCOMING_BIRTHDAYS_SUCCESS,
	LOAD_UPCOMING_VACATIONS, LOAD_UPCOMING_VACATIONS_ERROR, LOAD_UPCOMING_VACATIONS_SUCCESS,
	LOAD_EXPIRED_CONTRACTS, LOAD_EXPIRED_CONTRACTS_ERROR, LOAD_EXPIRED_CONTRACTS_SUCCESS, LOAD_DASHBOARD_CARDS_DATA, LOAD_DASHBOARD_CARDS_DATA_SUCCESS, LOAD_DASHBOARD_CARDS_DATA_ERROR,
} from "./constants"

import { ExpiredContract } from "types/expiredContract.type"


export const loadExpiredContracts = (tenantId: number) => {
	return {
		type: LOAD_EXPIRED_CONTRACTS,
		payload: {
			tenantId
		}
	}
}
export const loadExpiredContractsSuccess = (expiredContracts: ExpiredContract[]) => {
	return {
		type: LOAD_EXPIRED_CONTRACTS_SUCCESS,
		payload: {
			expiredContracts
		}
	}
}
export const loadExpiredContractsError = () => {
	return {
		type: LOAD_EXPIRED_CONTRACTS_ERROR,
		payload: {
		}
	}
}

export const loadUpcomingBirthdays = (tenantId: number) => {
	return {
		type: LOAD_UPCOMING_BIRTHDAYS,
		payload: {
			tenantId
		}
	}
}
export const loadUpcomingBirthdaysSuccess = (upcomingBirthdays: UpcomingBirthday[]) => {
	return {
		type: LOAD_UPCOMING_BIRTHDAYS_SUCCESS,
		payload: {
			upcomingBirthdays
		}
	}
}
export const loadUpcomingBirthdaysError = () => {
	return {
		type: LOAD_UPCOMING_BIRTHDAYS_ERROR,
		payload: {
		}
	}
}

export const loadUpcomingVacations = (tenantId: number) => {
	return {
		type: LOAD_UPCOMING_VACATIONS,
		payload: {
			tenantId,
		}
	}
}
export const loadUpcomingVacationsSuccess = (upcomingVacations: UpcomingVacation[]) => {
	return {
		type: LOAD_UPCOMING_VACATIONS_SUCCESS,
		payload: {
			upcomingVacations
		}
	}
}
export const loadUpcomingVacationsError = () => {
	return {
		type: LOAD_UPCOMING_VACATIONS_ERROR,
		payload: {
		}
	}
}

export const loadSalaryCardsData = (tenantId: number) => {
	return {
		type: LOAD_DASHBOARD_CARDS_DATA,
		payload: {
			tenantId,
		}
	}
}
export const loadSalaryCardsDataSuccess = (payload) => {
	return {
		type: LOAD_DASHBOARD_CARDS_DATA_SUCCESS,
		payload
	}
}
export const loadSalaryCardsDataError = (payload) => {
	return {
		type: LOAD_DASHBOARD_CARDS_DATA_ERROR,
		payload
	}
}

