const scope = "melitaPayroll/reports";

export const LOAD_ANNUAL_SALARIES = `${scope}/LOAD_ANNUAL_SALARIES`;
export const LOAD_ANNUAL_SALARIES_SUCCESS = `${scope}/LOAD_ANNUAL_SALARIES_SUCCESS`;
export const LOAD_ANNUAL_SALARIES_ERROR = `${scope}/LOAD_ANNUAL_SALARIES_ERROR`;

export const DOWNLOAD_ANNUAL_SALARIES_PDF = `${scope}/DOWNLOAD_ANNUAL_SALARIES_PDF`;
export const DOWNLOAD_ANNUAL_SALARIES_PDF_SUCCESS = `${scope}/DOWNLOAD_ANNUAL_SALARIES_PDF_SUCCESS`;
export const DOWNLOAD_ANNUAL_SALARIES_PDF_ERROR = `${scope}/DOWNLOAD_ANNUAL_SALARIES_PDF_ERROR`;

export const DOWNLOAD_ANNUAL_SALARIES_EXCEL = `${scope}/DOWNLOAD_ANNUAL_SALARIES_EXCEL`;
export const DOWNLOAD_ANNUAL_SALARIES_EXCEL_SUCCESS = `${scope}/DOWNLOAD_ANNUAL_SALARIES_EXCEL_SUCCESS`;
export const DOWNLOAD_ANNUAL_SALARIES_EXCEL_ERROR = `${scope}/DOWNLOAD_ANNUAL_SALARIES_EXCEL_ERROR`;

export const SET_EMPLOYEES_CHECKED = `${scope}/SET_EMPLOYEES_CHECKED`;

export const SEND_EMPLOYEES_EMAIL = `${scope}/SEND_EMPLOYEES_EMAIL`;
export const SEND_EMPLOYEES_EMAIL_SUCCESS = `${scope}/SEND_EMPLOYEES_EMAIL_SUCCESS`;
export const SEND_EMPLOYEES_EMAIL_ERROR = `${scope}/SEND_EMPLOYEES_EMAIL_ERROR`;

export const SEND_ANNUAL_SALARY_EMAIL = `${scope}/SEND_ANNUAL_SALARY_EMAIL`;
export const SEND_ANNUAL_SALARY_EMAIL_SUCCESS = `${scope}/SEND_ANNUAL_SALARY_EMAIL_SUCCESS`;
export const SEND_ANNUAL_SALARY_EMAIL_ERROR = `${scope}/SEND_ANNUAL_SALARY_EMAIL_ERROR`;