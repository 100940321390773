const scope = "melitaPayroll/reports";

export const LOAD_ANNUAL_CERTIFICATE = `${scope}/LOAD_ANNUAL_CERTIFICATE`;
export const LOAD_ANNUAL_CERTIFICATE_SUCCESS = `${scope}/LOAD_ANNUAL_CERTIFICATE_SUCCESS`;
export const LOAD_ANNUAL_CERTIFICATE_ERROR = `${scope}/LOAD_ANNUAL_CERTIFICATE_ERROR`;

export const LOAD_EMPLOYEE_DATA = `${scope}/LOAD_EMPLOYEE_DATA`;
export const LOAD_EMPLOYEE_DATA_SUCCESS = `${scope}/LOAD_EMPLOYEE_DATA_SUCCESS`;
export const LOAD_EMPLOYEE_DATA_ERROR = `${scope}/LOAD_EMPLOYEE_DATA_ERROR`;

export const CERTIFICATE_PDF = `${scope}/CERTIFICATE_PDF`;
export const CERTIFICATE_PDF_SUCCESS = `${scope}/CERTIFICATE_PDF_SUCCESS`;
export const CERTIFICATE_PDF_ERROR = `${scope}/CERTIFICATE_PDF_ERROR`;

export const EXPORT_CERTIFICATE = `${scope}/EXPORT_CERTIFICATE`;
export const EXPORT_CERTIFICATE_SUCCESS = `${scope}/EXPORT_CERTIFICATE_SUCCESS`;
export const EXPORT_CERTIFICATE_ERROR = `${scope}/EXPORT_CERTIFICATE_ERROR`;

export const EMAIL_CERTIFICATE = `${scope}/EMAIL_CERTIFICATE`;
export const EMAIL_CERTIFICATE_SUCCESS = `${scope}/EMAIL_CERTIFICATE_SUCCESS`;
export const EMAIL_CERTIFICATE_ERROR = `${scope}/EMAIL_CERTIFICATE_ERROR`;

export const LOAD_VACATION_LIST = `${scope}/LOAD_VACATION_LIST`;
export const LOAD_VACATION_LIST_SUCCESS = `${scope}/LOAD_VACATION_LIST_SUCCESS`;
export const LOAD_VACATION_LIST_ERROR = `${scope}/LOAD_VACATION_LIST_ERROR`;

export const EMAIL_VACATION_LIST = `${scope}/EMAIL_VACATION_LIST`;
export const EMAIL_VACATION_LIST_SUCCESS = `${scope}/EMAIL_VACATION_LIST_SUCCESS`;
export const EMAIL_VACATION_LIST_ERROR = `${scope}/EMAIL_VACATION_LIST_ERROR`;

export const VACATION_LIST_PDF = `${scope}/VACATION_LIST_PDF`;
export const VACATION_LIST_PDF_SUCCESS = `${scope}/VACATION_LIST_PDF_SUCCESS`;
export const VACATION_LIST_PDF_ERROR = `${scope}/VACATION_LIST_PDF_ERROR`;

export const EXPORT_EXCEL_VACATION_LIST = `${scope}/EXPORT_EXCEL_VACATION_LIST`;
export const EXPORT_EXCEL_VACATION_LIST_SUCCESS = `${scope}/EXPORT_EXCEL_VACATION_LIST_SUCCESS`;
export const EXPORT_EXCEL_VACATION_LIST_ERROR = `${scope}/EXPORT_EXCEL_VACATION_LIST_ERROR`;
