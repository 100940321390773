import Table from "components/Table/Table.component";
import * as tableStyles from "components/Table/Table.module.scss";
import styles from "pages/Configurations/Configurations.module.scss";
import { ConfigurationsState } from "types/reducers/configurations.reducer.type";
import { globalState } from "types/globalState.type";
import { FunctionComponent, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions";
import SearchInput from "components/SearchInput/SearchInput.component";
import { IconPencil, IconSearch } from "assets/icons/icons";
import { FiTrash2 } from "react-icons/fi";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import classNames from "classnames";
import InputCustom from "components/InputCustom/InputCustom.component";
import { globalReducerState } from "types/reducers/global.reducer.type";
import { DebounceInput } from "react-debounce-input";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const HistoryTab: FunctionComponent = () => {
	const { t } = useTranslation("common");

	const tableColumns = [
		"Emri dhe Mbiemri",
		"Email",
		"Kompania",
		"Roli",
		"Statusi",
		"",
	];

	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);

	const dispatch = useDispatch();
	const state: ConfigurationsState = useSelector(
		(state: globalState) => state.configurationsReducer
	);
	const sidebarCollapsed: boolean = useSelector(
		(state: globalState) => state.appWrapperReducer.sidebarMenuCollapsed
	);
	const allUsers = state.allUsers;

	const roleId = useSelector(
		(state: globalState) => state.globalReducer.user?.roleId
	);

	const [passwordShown, setPasswordShown] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] =
		useState<boolean>(false);
	const [userIdToDelete, setUserIdToDelete] = useState<number>();
	const [isEditUserModalOpen, setIsEditUserModalOpen] =
		useState<boolean>(false);
	const [userIdToEdit, setUserIdToEdit] = useState<number>();
	const [companySearchValue, setCompanySearchValue] = useState(null);
	const [showCompaniesDropdown, setShowCompaniesDropdown] = useState(false);
	const searchedCompaniesList = state.searchedCompaniesList;
	const [pickedCompanies, setPickedCompanies] = useState([]);

	const userById = state.user;

	const [userData, setUserData] = useState(null);

	const initialLoad = () => {
		if (roleId === 1) {
			dispatch(actions.getUserList(tenantId));
		} else {
			dispatch(actions.allTenantUsers(tenantId));
		}
	};

	useEffect(() => {
		initialLoad();
	}, []);

	useEffect(() => {
		setUserData(userById);
	}, [userById]);

	useEffect(() => {
		searchedCompaniesList && setShowCompaniesDropdown(true);
	}, [searchedCompaniesList]);

	const deleteUserModal = (userId) => {
		setIsDeleteUserModalOpen(true);
		setUserIdToDelete(userId);
	};

	const handleDeleteUser = (companyId) => {
		dispatch(actions.deleteUser(companyId));
		setIsDeleteUserModalOpen(false);

		if (roleId === 1) {
			dispatch(actions.getUserList(tenantId));
		} else {
			dispatch(actions.allTenantUsers(tenantId));
		}
	};

	const editUserModal = (userId) => {
		setIsEditUserModalOpen(true);
		setUserIdToEdit(userId);
		dispatch(actions.getOneUser(userId));
	};

	const modalMainClasses = classNames({
		[styles.modalMainStyle]: true,
		[styles.sidebarCollapsed]: sidebarCollapsed,
	});

	const searchCompanies = (searchKey) => {
		searchKey !== null && dispatch(actions.searchCompanies(searchKey));
	};

	useEffect(() => {
		searchCompanies(companySearchValue);
	}, [companySearchValue]);

	const userDataChangeHandler = (event) => {
		setUserData((previous) => {
			return {
				...previous,
				[event.target.name]: event.target.value,
			};
		});
	};

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const modalsubmitHandler = () => {
		const payload = {
			userData,
			pickedCompanies,
		};
		dispatch(actions.updateUser(payload));
		dispatch(actions.getUserList(tenantId));
		setIsEditUserModalOpen(false);
	};

	return (
		<div>
			<div className={styles.search}>
				<SearchInput
					className={styles.searchInputContainer}
					onChangeHandler={(event) => {
						setSearchTerm(event.target.value);
					}}
					searchPlaceholder={t("employees.search")}
				/>
			</div>

			<Table
				tableHeader={
					<thead className={`${tableStyles.tableHead}`}>
						<tr className={`${tableStyles.headerRow} `}>
							{tableColumns.map((item, index) => {
								return (
									<th className={`${tableStyles.headerCell}`} key={index}>
										{item}
									</th>
								);
							})}
						</tr>
					</thead>
				}
				tableWrapperClass={styles.tableWrapper}
				data={[]}
			>
				<tbody className={styles.tableBody}>
					{allUsers?.length > 0 &&
						allUsers
							.filter((val) => {
								if (searchTerm === "") {
									return val;
								} else if (
									val.name.toLowerCase().includes(searchTerm.toLowerCase())
								) {
									return val;
								}
							})
							.map((user, idx) => {
								return (
									<tr className={styles.tableRow} key={idx}>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											{user?.name}
										</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											{user?.email}
										</td>
										<td
											className={`${styles.bodyCell} ${styles.text}`}
											data-tip={user?.companies?.name}
										>
											<ReactTooltip
												place="bottom"
												type="dark"
												effect="float"
												multiline={true}
												className="customTooltip"
											/>
											{user?.companies?.name}
										</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											{user?.role}
										</td>
										<td className={`${styles.bodyCell} ${styles.text}`}>
											{user?.status}
										</td>
										<td style={{ width: "100px" }}>
											<div className={styles.pencilIcon}>
												<div
													className={styles.pencil}
													onClick={() => editUserModal(user.id)}
												>
													<IconPencil fill="#89C732" />
												</div>
												<div>
													<FiTrash2 onClick={() => deleteUserModal(user.id)} />
												</div>
											</div>
										</td>
									</tr>
								);
							})}
				</tbody>
			</Table>

			{isDeleteUserModalOpen ? (
				<ModalCustom
					show={isDeleteUserModalOpen}
					contentClass={styles.contentClass}
					footerClass={styles.footerClass}
					onClose={() => {
						setIsDeleteUserModalOpen((prev) => false);
					}}
					hideFooterButton={false}
					submitText={"Po"}
					onSubmit={() => handleDeleteUser(userIdToDelete)}
				>
					<div className={styles.deleteCompanyMessage}>
						Jeni të sigurtë që doni të fshini këtë përdorues ?
					</div>
				</ModalCustom>
			) : null}

			{isEditUserModalOpen && (
				<ModalCustom
					show={isEditUserModalOpen}
					contentClass={styles.modalContent}
					footerClass={styles.footerClass}
					modalMainStyle={modalMainClasses}
					onClose={() => {
						setIsEditUserModalOpen((prev) => false);
					}}
					onSubmit={modalsubmitHandler}
					header={
						<div className={styles.modalHeader}>
							<div className={styles.modalTitle}>Edito përdorues</div>
						</div>
					}
				>
					<div className={styles.inputContainer}>
						<div className={styles.inputBox}>
							<InputCustom
								className={styles.modalInput}
								placeholder="Emri mbiemri"
								name="name"
								value={userData?.name}
								onChangeHandler={userDataChangeHandler}
							></InputCustom>
						</div>
					</div>
					<div className={styles.inputContainer}>
						<div className={styles.inputBox}>
							<InputCustom
								className={styles.modalInput}
								placeholder="E-mail"
								name="email"
								type="email"
								value={userData?.email}
								onChangeHandler={userDataChangeHandler}
							></InputCustom>
						</div>
					</div>
					<div className={`${styles.inputContainer}`}>
						{roleId === 1 ? (
							<div
								className={styles.inputBox}
								onFocus={(event) => {
									searchCompanies("");
								}}
							>
								<IconSearch color="#89c732" />
								<DebounceInput
									id="searchInput"
									type="search"
									minLength={1}
									aria-autocomplete="none"
									autoComplete="off"
									autoFocus={false}
									placeholder="Ndrysho kompani"
									debounceTimeout={800}
									value={companySearchValue || ""}
									className={`${styles.modalInput} ${styles.searchBox}`}
									onChange={(event) => {
										setCompanySearchValue(event.target.value);
									}}
									onBlur={() => {
										setTimeout(() => {
											setShowCompaniesDropdown(false);
										}, 200);
									}}
								/>
							</div>
						) : null}
						{searchedCompaniesList &&
							showCompaniesDropdown &&
							searchedCompaniesList.length ? (
							<div className={styles.searchCompaniesResult}>
								<div className={styles.companiesContainer}>
									{searchedCompaniesList?.map((item, index) => {
										const isSelected = _.find(
											pickedCompanies,
											(pickedCompany) => pickedCompany.id === item.id
										);
										const itemStyles = classNames({
											[styles.company]: true,
											[styles.selected]: isSelected,
										});

										return (
											<div
												key={index}
												className={itemStyles}
												onClick={(event) => {
													if (isSelected) {
														setPickedCompanies((prev) => {
															if (prev && prev.length) {
																return prev.filter(
																	(pickedItem) => pickedItem.id !== item.id
																);
															} else {
																return [];
															}
														});
													} else {
														setPickedCompanies((prev) => [...prev, item]);
													}
													setShowCompaniesDropdown(false);
												}}
											>
												{item.name}
											</div>
										);
									})}
								</div>
							</div>
						) : (
							""
						)}
						{pickedCompanies.length ? (
							<div className={styles.companiesContainer}>
								{pickedCompanies?.map((item, index) => {
									return (
										<div className={styles.company} key={index}>
											{item.name}
										</div>
									);
								})}
							</div>
						) : (
							""
						)}
					</div>
				</ModalCustom>
			)}
		</div>
	);
};

export default HistoryTab;
