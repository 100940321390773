import {htmlElementAttributes} from 'html-element-attributes';

interface iconProps {
  class?: string;
  fill?: string;
  color?: string;
  invert?: boolean;
  stroke?: any;
  width?: string;
  height?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  attributes?: typeof htmlElementAttributes;
  isSelected?: boolean;
  hasNotifications?: boolean;
}

export const IconSearch = (props: iconProps) => {
  const color1 = props.color ? props.color : (props.invert ? '#1f1960' : '#fff');
  return <svg className={props?.class} width="20" height="20" viewBox="0 0 31.117 30.663">
    <g transform="translate(-0.406 -0.859)">
      <ellipse
          style={{ fill: 'none', stroke: color1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }}
          cx="13" cy="12.5" rx="13" ry="12.5" transform="translate(1.406 1.859)"/>
      <line
          style={{ fill: 'none', stroke: color1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }}
          x1="6.893" y1="6.893" transform="translate(23.215 23.215)"/>
    </g>
  </svg>;
};

export const IconUser = (props: iconProps) => {
  return <svg width="66.446" height="54.728" viewBox="0 0 66.446 54.728">
    <g transform="translate(1 1)">
      <path style={{
        fill: 'none',
        stroke: (props.stroke || '#89c732'),
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: (props.width || '2px'),
      }} d="M47.869,32.576V26.717A11.717,11.717,0,0,0,36.152,15H12.717A11.717,11.717,0,0,0,1,26.717v5.859"
            transform="translate(-1 20.152)"/>
      <circle style={{
        fill: 'none',
        stroke: (props.stroke || '#89c732'),
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: (props.width || '2px'),
      }} cx="12.016" cy="12.016" r="12.016" transform="translate(11.091)"/>
      <path style={{
        fill: 'none',
        stroke: (props.stroke || '#89c732'),
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: (props.width || '2px'),
      }} d="M28.788,32.325V26.467A11.717,11.717,0,0,0,20,15.13" transform="translate(35.657 20.403)"/>
      <path style={{
        fill: 'none',
        stroke: (props.stroke || '#89c732'),
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: (props.width || '2px'),
      }} d="M16,3.13a11.717,11.717,0,0,1,0,22.7" transform="translate(27.94 -2.749)"/>
    </g>
  </svg>;
};

export const IconHome = (props: iconProps) => {
  const iconStyle: object = {
    fill: `${(props.fill || 'none')}`,
    stroke: `${(props.stroke || '#1f1960')}`,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: (props.width || '2px'),
  };
  return <svg width="30.524" height="33.693" viewBox="0 0 30.524 33.693">
    <g transform="translate(-0.585 -0.585)">
      <path style={iconStyle}
            d="M3,13.093,17.262,2,31.524,13.093V30.524a3.169,3.169,0,0,1-3.169,3.169H6.169A3.169,3.169,0,0,1,3,30.524Z"
            transform="translate(-1.415 -0.415)"/>
      <path style={iconStyle} d="M9,27.847V12h9.508V27.847" transform="translate(2.093 5.431)"/>
    </g>
  </svg>;
};

export const IconDollar = (props: iconProps) => {
  return <svg width="71" height="71" viewBox="0 0 71 71">
    <g transform="translate(21.704 11)">
      <line style={{
        fill: (props.fill || 'none'),
        stroke: (props.stroke || '#89c732'),
        strokeWidth: (props.width || '2px'),
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }} y2="49" transform="translate(15.296)"/>
      <path style={{
        fill: (props.fill || 'none'),
        stroke: (props.stroke || '#89c732'),
        strokeWidth: (props.width || '2px'),
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }} d="M31.3,5H14.049a8.049,8.049,0,0,0,0,16.1h11.5a8.049,8.049,0,1,1,0,16.1H6" transform="translate(-6 3.303)"/>
    </g>
    <g style={{
      fill: (props.fill || 'none'),
      stroke: (props.stroke || '#89c732'),
      strokeWidth: (props.width || '2px'),
    }}>
      <circle style={{ stroke: 'none' }} cx="35.5" cy="35.5" r="35.5"/>
      <circle style={{ fill: 'none' }} cx="35.5" cy="35.5" r="34.5"/>
    </g>
  </svg>;
};

export const IconDocument = (props: iconProps) => {
  const iconStyle: object = {
    fill: 'none',
    stroke: (props.stroke || '#1f1960'),
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: (props.width || '2px'),
  };
  return <svg width="27.355" height="33.693" viewBox="0 0 27.355 33.693">
    <g transform="translate(-0.585 -0.585)">
      <path style={iconStyle}
            d="M19.847,2H7.169A3.169,3.169,0,0,0,4,5.169V30.524a3.169,3.169,0,0,0,3.169,3.169H26.185a3.169,3.169,0,0,0,3.169-3.169V11.508Z"
            transform="translate(-2.415 -0.415)"/>
      <path style={iconStyle} d="M14,2v9.508h9.508" transform="translate(3.431 -0.415)"/>
      <line style={iconStyle} x1="13" transform="translate(8 19)"/>
      <line style={iconStyle} x1="13" transform="translate(8 25)"/>
      <path style={iconStyle} d="M11.169,9H8" transform="translate(-0.077 3.677)"/>
    </g>
  </svg>;
};

export const IconCalendar = (props: iconProps) => {
  const iconStyle: object = {
    fill: 'none',
    stroke: (props.stroke || '#1f1960'),
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: (props.width || '2px'),
  };
  return <svg width="30" height="33" viewBox="0 0 30 33">
    <g transform="translate(-1 -1)">
      <rect style={iconStyle} width="28" height="28" rx="2" transform="translate(2 5)"/>
      <line style={iconStyle} y2="6" transform="translate(22 2)"/>
      <line style={iconStyle} y2="6" transform="translate(10 2)"/>
      <line style={iconStyle} x2="28" transform="translate(2 14)"/>
    </g>
  </svg>;
};

export const IconSettings = (props: iconProps) => {
  const iconStyle: object = {
    fill: 'none',
    stroke: (props.stroke || '#1f1960'),
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: (props.width || '2px'),
  };
  return <svg width="36.863" height="36.863" viewBox="0 0 36.863 36.863">
    <g transform="translate(-0.585 -0.585)">
      <circle style={iconStyle} cx="5" cy="5" r="5" transform="translate(14 14)"/>
      <path style={iconStyle}
            d="M30.158,23.185a2.615,2.615,0,0,0,.523,2.884l.1.1a3.171,3.171,0,1,1-4.485,4.485l-.1-.1a2.636,2.636,0,0,0-4.469,1.87v.269a3.169,3.169,0,0,1-6.339,0v-.143a2.615,2.615,0,0,0-1.711-2.393,2.615,2.615,0,0,0-2.884.523l-.1.1a3.171,3.171,0,1,1-4.485-4.485l.1-.1a2.636,2.636,0,0,0-1.87-4.469H4.169a3.169,3.169,0,1,1,0-6.339h.143A2.615,2.615,0,0,0,6.7,13.677a2.615,2.615,0,0,0-.523-2.884l-.1-.1a3.171,3.171,0,1,1,4.485-4.485l.1.1a2.615,2.615,0,0,0,2.884.523h.127a2.615,2.615,0,0,0,1.585-2.393V4.169a3.169,3.169,0,0,1,6.339,0v.143a2.636,2.636,0,0,0,4.469,1.87l.1-.1a3.171,3.171,0,1,1,4.485,4.485l-.1.1a2.615,2.615,0,0,0-.523,2.884v.127a2.615,2.615,0,0,0,2.393,1.585h.269a3.169,3.169,0,0,1,0,6.339h-.143A2.615,2.615,0,0,0,30.158,23.185Z"
            transform="translate(0.585 0.585)"/>
    </g>
  </svg>;
};

export const LanguageIcon = (props: iconProps) => {
  const color1 = props.invert ? '#1f1960' : '#fff';
  const color2 = '#89c732';
  return <svg width="30" height="30" viewBox="0 0 30 30">
    <path fill={color1}
          d="M1363.575,510.466c0,2.291.009,4.581,0,6.872a1.892,1.892,0,0,1-2.13,2.107q-6.946.01-13.893,0a1.849,1.849,0,0,1-2.011-2c-.014-3.037-.022-6.075.006-9.112.006-.645-.169-.864-.839-.857-2.987.033-5.975.017-8.963.013a1.9,1.9,0,0,1-2.164-2.148q-.008-6.909,0-13.818a1.879,1.879,0,0,1,2.09-2.068q6.909-.01,13.818,0a1.89,1.89,0,0,1,2.125,2.112c.006,3.013.016,6.025-.009,9.038-.005.612.175.82.8.814,2.963-.027,5.926-.015,8.888-.011,1.6,0,2.273.676,2.275,2.263Q1363.578,507.068,1363.575,510.466Zm-20.9-19.712v0c-2.239,0-4.478,0-6.717,0-.943,0-1.07.13-1.071,1.087q-.007,6.6,0,13.21c0,.994.127,1.129,1.112,1.13q6.643.007,13.285,0c.886,0,1.036-.154,1.036-1.053q.005-6.6,0-13.21c0-1.053-.119-1.169-1.147-1.17Q1345.923,490.751,1342.676,490.755Zm19.6,19.748h0c0-2.266-.007-4.531,0-6.8,0-.616-.167-1-.879-1q-4.594.018-9.187,0c-.468,0-.605.2-.594.635.018.722.015,1.444,0,2.166a1.866,1.866,0,0,1-1.953,1.982c-.647.015-1.3.042-1.941-.007-.672-.051-.91.16-.9.87.038,2.862.014,5.726.016,8.589,0,1.093.1,1.2,1.191,1.2q6.572.006,13.145,0c.971,0,1.1-.135,1.1-1.143Q1362.278,513.752,1362.274,510.5Z"
          transform="translate(-1333.578 -489.45)"/>
    <path fill={color2}
          d="M1359.115,517.182c-.342,0-.565.006-.787,0-.439-.013-.723-.214-.707-.68.014-.432.3-.6.7-.607,1.071-.007,2.143-.039,3.212.005.645.027.935-.165.852-.838a4.93,4.93,0,0,1,0-.9.641.641,0,0,1,1.281-.035c.009.074.006.149.01.224.077,1.532.077,1.532,1.643,1.532.747,0,1.494,0,2.241,0,.466,0,.883.094.873.668s-.451.636-.9.631a1.988,1.988,0,0,1-.3,0,1.057,1.057,0,0,0-1.347.9,7.184,7.184,0,0,1-1.481,2.66c-.435.5-.271.735.188,1.034a10.765,10.765,0,0,0,3.037,1.4c.5.137.954.331.774.95-.17.581-.654.5-1.113.374a10.157,10.157,0,0,1-3.782-1.887.647.647,0,0,0-.963-.018,9.89,9.89,0,0,1-3.8,1.841c-.455.108-.946.185-1.1-.414s.292-.782.755-.9a10.136,10.136,0,0,0,3.237-1.494c.343-.237.4-.417.111-.764a8.608,8.608,0,0,1-1.369-2.3c-.16-.4-.319-.489-.576-.084a2.078,2.078,0,0,1-.137.178c-.318.4-.661.882-1.213.43-.537-.439-.151-.9.149-1.3C1358.731,517.608,1358.886,517.448,1359.115,517.182Zm2.128.006a12.349,12.349,0,0,0,1.451,2.712c.255.326.451.33.692,0a10.523,10.523,0,0,0,1.207-2.22c.144-.346.045-.5-.35-.5C1363.277,517.2,1362.309,517.188,1361.243,517.188Z"
          transform="translate(-1354.007 -509.905)"/>
    <path fill={color2}
          d="M1442.8,603.228c.06-.164.131-.376.216-.583q1.771-4.316,3.545-8.632c.161-.391.28-.835.823-.837s.66.444.821.835q1.763,4.279,3.526,8.559c.066.161.128.324.188.487a.626.626,0,0,1-.3.885.646.646,0,0,1-.927-.366c-.378-.839-.738-1.688-1.064-2.548a.806.806,0,0,0-.9-.6c-.9.028-1.793.039-2.688,0a.834.834,0,0,0-.925.657c-.316.837-.672,1.661-1.035,2.48a.659.659,0,0,1-.857.4A.623.623,0,0,1,1442.8,603.228Zm4.559-7.75a22.059,22.059,0,0,1-1.182,3.04c-.227.54-.044.651.459.626.519-.026,1.041-.026,1.56,0s.6-.147.4-.608C1448.174,597.543,1447.784,596.534,1447.357,595.478Z"
          transform="translate(-1426.408 -577.616)"/>
  </svg>;
};

export const QuickBooksIcon = (props: iconProps) => {
  const color1 = props.invert ? '#1f1960' : '#fff';
  const color2 = '#89c732';
  return <svg width="33.367" height="33.367" viewBox="0 0 33.367 33.367">
    <g transform="translate(0.75 0.75)">
      <g transform="translate(4.722 6.588)">
        <path fill={color2}
              d="M-306.03,756.422c-.335,0-.445.129-.425.445.017.275,0,.552,0,.828,0,.821,0,.818.807.852a4.155,4.155,0,0,1,3.893,4.2,4.152,4.152,0,0,1-3.467,4.03,13.625,13.625,0,0,1-2.2.064c-.5.011-.538-.037-.538-.538q0-2.6,0-5.208,0-3.591,0-7.181c0-.558,0-.56-.58-.561h-.986c-.552,0-.554,0-.554.541q0,3.63,0,7.26c0,2.446.006,4.893-.006,7.339,0,.355.1.485.47.479,1.262-.019,2.526.01,3.788-.013a6.2,6.2,0,0,0,2.776-.672,6.253,6.253,0,0,0,2.809-8.272A6.027,6.027,0,0,0-306.03,756.422Z"
              transform="translate(322.049 -753.352)"/>
        <path fill={color2}
              d="M-392.127,776.956c-1.3.017-2.6-.013-3.906.012a5.894,5.894,0,0,0-4.651,2.3,6.134,6.134,0,0,0-.395,7.354,6.121,6.121,0,0,0,5.389,2.882c.284,0,.408-.094.4-.389-.016-.394,0-.789,0-1.183,0-.546,0-.546-.549-.551a4.2,4.2,0,0,1-4.106-3.739,4.167,4.167,0,0,1,3.389-4.5,13.5,13.5,0,0,1,2.243-.074c.515-.009.538.02.538.526q0,3.077,0,6.155,0,3.156,0,6.312c0,.519,0,.52.533.521q.533,0,1.065,0c.514,0,.519-.006.519-.539q0-3.63,0-7.259v-7.3a.334.334,0,0,1,0-.039C-391.619,777.091-391.757,776.951-392.127,776.956Z"
              transform="translate(402.115 -773.888)"/>
      </g>
      <circle style={{
        fill: 'none',
        stroke: (props.stroke || color1),
        strokeMiterlimit: 10,
        strokeWidth: (props.width || '1.5px'),
      }} cx="15.934" cy="15.934" r="15.934"/>
    </g>
  </svg>;
};

export const NotificationIcon = (props: iconProps) => {
  const color1 = props.invert ? '#1f1960' : '#fff';
  const color2 = '#89c732';
  return <svg width="25.817" height="28.458" viewBox="0 0 25.817 28.458">
    <g transform="translate(1 1)">
      <g transform="translate(0)">
        <path style={{
          fill: 'none',
          stroke: props.isSelected ? color2 : color1,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2px',
        }} d="M22.847,9.939a7.939,7.939,0,0,0-15.878,0C6.969,19.2,3,21.847,3,21.847H26.817S22.847,19.2,22.847,9.939"
              transform="translate(-3 -2)"/>
        <path style={{
          fill: 'none',
          stroke: props.isSelected ? color2 : color1,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2px',
        }} d="M14.848,21a2.646,2.646,0,0,1-4.578,0" transform="translate(-0.651 4.14)"/>
      </g>
      <ellipse fill={props.hasNotifications ? color2 : '#fff'} cx="3.921" cy="3.921" rx="3.921" ry="3.921"
               transform="translate(15.88 0.05)"/>
    </g>
  </svg>;
};

export const HelpIcon = (props: iconProps) => {
  const color1 = props.invert ? '#1f1960' : '#fff';
  const color2 = '#89c732';
  return <svg width="29" height="29" viewBox="0 0 29 29">
    <g transform="translate(1 1)">
      <path style={{ fill: 'none', strokeLinecap: 'round', stroke: color1, strokeWidth: '2px' }}
            d="M13.5,0A13.5,13.5,0,1,1,0,13.5,13.5,13.5,0,0,1,13.5,0Z"/>
      <path
          style={{ fill: 'none', strokeLinecap: 'round', strokeWidth: '2px', stroke: color2, strokeLinejoin: 'round' }}
          d="M9.09,9.852A4.275,4.275,0,0,1,17.4,11.277c0,2.85-4.275,4.275-4.275,4.275"
          transform="translate(0.201 -0.689)"/>
      <line
          style={{ fill: 'none', strokeLinecap: 'round', stroke: color2, strokeLinejoin: 'round', strokeWidth: '3px' }}
          transform="translate(13 20)"/>
    </g>
  </svg>;
};

export const IconPaperclip = (props: iconProps) => {
  const iconStyle: object = {
    fill: 'none',
    stroke: (props.stroke || '#1f1960'),
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: (props.width || '2px'),
  };
  return <svg width="33.217" height="34.661" viewBox="0 0 33.217 34.661">
    <g transform="translate(-0.585 -0.585)">
      <path style={iconStyle}
            d="M32.8,16.7,18.242,31.262A9.513,9.513,0,1,1,4.788,17.808L19.351,3.245a6.342,6.342,0,1,1,8.969,8.969L13.741,26.778a3.171,3.171,0,1,1-4.485-4.485L22.711,8.855"
            transform="translate(-0.417 0.197)"/>
    </g>
  </svg>;
};

export const IconPencil = (props: iconProps) => {
  const iconStyle: object = { fill: (props.fill || '#261f63') };
  return <svg width={props.width || '32.26'} height={props.height || '31.14'} viewBox="0 0 32.26 31.14">
    <defs>
      <clipPath id="a">
        <rect style={iconStyle} width="32.26" height="31.14"/>
      </clipPath>
    </defs>
    <g transform="translate(-266.1 -265.6)">
      <g transform="translate(266.1 265.6)">
        <g style={{ clipPath: 'url(#a)' }} transform="translate(0 0)">
          <path style={iconStyle}
                d="M296.187,268.208a7.307,7.307,0,0,0-10.062,0L267.68,285.962a.829.829,0,0,0-.259.51l-1.367,9.745a.865.865,0,0,0,.259.749.962.962,0,0,0,.654.267.893.893,0,0,0,.124-.006l6.1-.794a.9.9,0,0,0,.8-1,.922.922,0,0,0-1.043-.766l-4.89.635.954-6.8,7.429,7.153a.962.962,0,0,0,.654.267.912.912,0,0,0,.654-.267L296.187,277.9a6.675,6.675,0,0,0,0-9.694Zm-9.7,2.184,3.1,2.984-16.831,16.2-3.1-2.984ZM277.1,293.755l-3.028-2.915L290.9,274.634l3.028,2.916Zm18.121-17.482-7.411-7.135a5.427,5.427,0,0,1,7.075.323,4.942,4.942,0,0,1,.336,6.812Zm0,0"
                transform="translate(-266.048 -266.166)"/>
        </g>
      </g>
    </g>
  </svg>;
};

export const IconPlus = (props: iconProps) => {
  const iconStyle: object = {
    fill: '#fff',
    stroke: (props.stroke || '#1f1960'),
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: (props.width || '2px'),
  };
  return <svg className={props.class} width="46.669" height="46.669" viewBox="0 0 46.669 46.669">
    <g transform="translate(-6504.086 4887.087) rotate(45)">
      <circle style={iconStyle} cx="15.5" cy="15.5" r="15.5" transform="translate(1160.891 -8070.276)"/>
      <g transform="translate(1168.645 -8062.823)">
        <line style={iconStyle} x1="10.707" y2="10.707" transform="translate(2.392 2.347)"/>
        <line style={iconStyle} x2="10.707" y2="10.707" transform="translate(2.392 2.347)"/>
      </g>
    </g>
  </svg>;
};

export const IconTrendingUp = (props: iconProps) => {
  const iconStyle: object = { fill: props.fill || 'transparent' };
  const iconStyle_2: object = {
    fill: 'none',
    stroke: (props.stroke || '#fff'),
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: (props.width || '2px'),
  };
  return <svg width="71" height="70" viewBox="0 0 71 70">
    <ellipse style={iconStyle} cx="35.5" cy="35" rx="35.5" ry="35"/>
    <g transform="translate(20.978 27)">
      <path style={iconStyle_2} d="M31.927,6,18.572,19.355l-7.029-7.029L1,22.87" transform="translate(-1 -6)"/>
      <path style={iconStyle_2} d="M17,6h8.435v8.435" transform="translate(5.493 -6)"/>
    </g>
  </svg>;
};

export const IconTrendingUpTransparent = (props: iconProps) => {
  const iconStyle_2: object = {
    fill: 'none',
    stroke: (props.stroke || '#fff'),
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: (props.width || '2px'),
  };
  return <svg width="37.691" height="21.844" viewBox="0 0 37.691 21.844">
    <g transform="translate(1.414 1.414)">
      <path style={iconStyle_2} d="M35.863,6,20.808,21.054l-7.923-7.923L1,25.016" transform="translate(-1 -6)"/>
      <path style={iconStyle_2} d="M17,6h9.508v9.508" transform="translate(8.355 -6)"/>
    </g>
  </svg>;
};

export const IconDollarFilled = (props: iconProps) => {
  const iconStyle: object = { fill: '#7764e4' };
  const iconStyle_2: object = {
    fill: 'none',
    stroke: (props.stroke || '#fff'),
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: (props.width || '2px'),
  };
  return <svg width="70" height="70" viewBox="0 0 70 70">
    <circle style={iconStyle} cx="35" cy="35" r="35"/>
    <g transform="translate(26.089 20)">
      <line style={iconStyle_2} y2="31" transform="translate(7.911)"/>
      <path style={iconStyle_2} d="M21.464,5H10.92a4.92,4.92,0,0,0,0,9.841h7.029a4.92,4.92,0,1,1,0,9.841H6"
            transform="translate(-6 0.623)"/>
    </g>
  </svg>;
};

export const IconUserPlus = (props: iconProps) => {
  return <svg width="84.446" height="72.864" viewBox="0 0 84.446 72.864">
    <g transform="translate(-941.99 -807.457)">
      <g transform="translate(960.99 808.457)">
        <path style={{
          fill: 'none',
          stroke: '#89c732',
          strokeLinecap: 'round',
          strokeWidth: '2px',
          strokeLinejoin: 'round',
        }} d="M47.869,32.576V26.717A11.717,11.717,0,0,0,36.152,15H12.717A11.717,11.717,0,0,0,1,26.717v5.859"
              transform="translate(-1 20.152)"/>
        <circle style={{
          fill: 'none',
          stroke: '#89c732',
          strokeLinecap: 'round',
          strokeWidth: '2px',
          strokeLinejoin: 'round',
        }} cx="12.016" cy="12.016" r="12.016" transform="translate(11.091)"/>
        <path style={{
          fill: 'none',
          stroke: '#89c732',
          strokeLinecap: 'round',
          strokeWidth: '2px',
          strokeLinejoin: 'round',
        }} d="M28.788,32.325V26.467A11.717,11.717,0,0,0,20,15.13" transform="translate(35.657 20.403)"/>
        <path style={{
          fill: 'none',
          stroke: '#89c732',
          strokeLinecap: 'round',
          strokeWidth: '2px',
          strokeLinejoin: 'round',
        }} d="M16,3.13a11.717,11.717,0,0,1,0,22.7" transform="translate(27.94 -2.749)"/>
      </g>
      <g transform="translate(941.99 848.321)">
        <g style={{ fill: '#fafafa' }}>
          <path style={{ stroke: 'none' }}
                d="M 16 31 C 11.9933500289917 31 8.226519584655762 29.43972969055176 5.393400192260742 26.60659980773926 C 2.560270071029663 23.77347946166992 1 20.00665092468262 1 16 C 1 11.9933500289917 2.560270071029663 8.226519584655762 5.393400192260742 5.393400192260742 C 8.226519584655762 2.560270071029663 11.9933500289917 1 16 1 C 20.00665092468262 1 23.77347946166992 2.560270071029663 26.60659980773926 5.393400192260742 C 29.43972969055176 8.226519584655762 31 11.9933500289917 31 16 C 31 20.00665092468262 29.43972969055176 23.77347946166992 26.60659980773926 26.60659980773926 C 23.77347946166992 29.43972969055176 20.00665092468262 31 16 31 Z"/>
          <path style={{ fill: '#89c732', stroke: 'none' }}
                d="M 16 2 C 12.26045989990234 2 8.744749069213867 3.456249237060547 6.100500106811523 6.100500106811523 C 3.456249237060547 8.744749069213867 2 12.26045989990234 2 16 C 2 19.73954010009766 3.456249237060547 23.2552490234375 6.100500106811523 25.89949989318848 C 8.744749069213867 28.54375076293945 12.26045989990234 30 16 30 C 19.73954010009766 30 23.2552490234375 28.54375076293945 25.89949989318848 25.89949989318848 C 28.54375076293945 23.2552490234375 30 19.73954010009766 30 16 C 30 12.26045989990234 28.54375076293945 8.744749069213867 25.89949989318848 6.100500106811523 C 23.2552490234375 3.456249237060547 19.73954010009766 2 16 2 M 16 0 C 24.8365592956543 0 32 7.163440704345703 32 16 C 32 24.8365592956543 24.8365592956543 32 16 32 C 7.163440704345703 32 0 24.8365592956543 0 16 C 0 7.163440704345703 7.163440704345703 0 16 0 Z"/>
        </g>
        <path style={{ fill: 'none', stroke: '#89c732', strokeLinecap: 'round', strokeWidth: '2px' }} d="M0,0H13.713"
              transform="translate(9.144 16.263)"/>
        <line style={{ fill: 'none', stroke: '#89c732', strokeLinecap: 'round', strokeWidth: '2px' }} y2="15.547"
              transform="translate(16 8.49)"/>
      </g>
    </g>
  </svg>;
};

export const IconAdobe = (props: iconProps) => {
  return <svg
      width="26.039"
      height="32.313"
      viewBox="0 0 26.039 32.313"
      {...props.attributes}
  >
    {
      props?.attributes?.title
          ?
          <title>{props.attributes.title}</title>
          : ''
    }
    <g transform="translate(281.031 -597.524)">
      <path style={{ fill: props.fill || '#1f1960' }}
            d="M-82.52,828.355a.441.441,0,0,0-.067.177C-82.564,828.474-82.541,828.417-82.52,828.355Z"
            transform="translate(-172.471 -200.62)"/>
      <path style={{ fill: props.fill || '#1f1960' }}
            d="M-281.03,600.7c.036,8.716.017,17.432.017,26.149a2.651,2.651,0,0,0,2.958,2.969c6.607,0,13.215-.025,19.822.019a2.965,2.965,0,0,0,3.174-1.925.441.441,0,0,1,.067-.177v-17.97a5.653,5.653,0,0,0-1.492-1.976q-4.391-4.376-8.764-8.771a5.671,5.671,0,0,0-1.974-1.494h-11.71A2.934,2.934,0,0,0-281.03,600.7Zm1.058-.242c0-1.318.579-1.891,1.907-1.892,3.294,0,6.589.011,9.883-.01a2.256,2.256,0,0,1,1.728.722q4.834,4.871,9.7,9.708a2.342,2.342,0,0,1,.729,1.794c-.017,5.358-.009,10.716-.01,16.073,0,1.357-.572,1.922-1.944,1.922q-10.046,0-20.091,0c-1.321,0-1.9-.58-1.9-1.9q0-6.638,0-13.275Q-279.973,607.031-279.972,600.458Z"
            transform="translate(0)"/>
      <path style={{ fill: props.fill || '#1f1960' }}
            d="M-243.016,693.877a1.981,1.981,0,0,0,2.334-.525,14.506,14.506,0,0,0,2.634-3.549,1.546,1.546,0,0,1,.985-.783c1.794-.513,3.6-.965,5.436-1.306a2.532,2.532,0,0,1,1.706.235,10.04,10.04,0,0,0,4.38,1.221,1.188,1.188,0,0,0,1.351-1.193c.151-1.046-.357-1.6-1.672-1.744a13.684,13.684,0,0,0-3.572.067,1.713,1.713,0,0,1-1.308-.287,9.341,9.341,0,0,1-3.119-3.9,2.184,2.184,0,0,1-.171-1.429,23.7,23.7,0,0,0,.424-3.551,7.836,7.836,0,0,0,0-.856,1.371,1.371,0,0,0-1.833-1.247c-.84.231-.9.935-.93,1.611a15.156,15.156,0,0,0,.667,4.448,2.133,2.133,0,0,1-.107,1.5c-.778,1.727-1.673,3.394-2.52,5.085a3.832,3.832,0,0,1-1.981,1.956,7.549,7.549,0,0,0-1.929,1.3,4.345,4.345,0,0,0-1.3,1.627A.9.9,0,0,0-243.016,693.877Zm17.722-6.416c.217.072.487.163.418.455-.06.256-.339.182-.529.192a5.032,5.032,0,0,1-2.555-.749A5.594,5.594,0,0,1-225.294,687.461Zm-9.647-11.909a3.558,3.558,0,0,1,.245,3.879A10.071,10.071,0,0,1-234.941,675.552Zm.107,7.837a12,12,0,0,0,2.764,3.3,38.276,38.276,0,0,0-5.042,1.427A41.357,41.357,0,0,0-234.833,683.389Zm-5.329,7.127a6.287,6.287,0,0,1-2.15,2.473A6.214,6.214,0,0,1-240.163,690.515Z"
            transform="translate(-34.089 -68.878)"/>
    </g>
  </svg>;
};

export const IconNoBirthdays = (props: iconProps) => {
  return <svg id="Group_759" data-name="Group 759" width="110.708" height="100.403" viewBox="0 0 110.708 100.403">
    <defs>
      <clipPath id="clip-path">
        <circle id="Ellipse_407" data-name="Ellipse 407" cx="40.162" cy="40.162" r="40.162" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-2">
        <path id="Path_611" data-name="Path 611"
              d="M4219.214-6057.04l17.872-27.923-34.2-2.535A262.4,262.4,0,0,1,4219.214-6057.04Z"
              transform="translate(-4202.888 6087.498)" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_757" data-name="Group 757" transform="translate(4.935 13.576)">
      <g id="Group_752" data-name="Group 752">
        <g id="Group_750" data-name="Group 750" transform="translate(7.169)">
          <circle id="Ellipse_406" data-name="Ellipse 406" cx="40.162" cy="40.162" r="40.162" fill="#ffb217"/>
          <g id="Group_749" data-name="Group 749">
            <g id="Group_748" data-name="Group 748" clipPath="url(#clip-path)">
              <path id="Path_599" data-name="Path 599"
                    d="M4073.688-6040.023s-6.151,69.4-75.132,37.146c0,0-12.357,43.256,30.082,48.758S4122.179-6000.928,4073.688-6040.023Z"
                    transform="translate(-3997.521 6063.148)" fill="#ef9d0a" opacity="0.54"/>
            </g>
          </g>
        </g>
        <path id="Path_600" data-name="Path 600"
              d="M4150.06-5995.148a11.8,11.8,0,0,0,1.868,4.015,11.32,11.32,0,0,0,3.375,3.15,8.6,8.6,0,0,0,4.671,1.256,6.673,6.673,0,0,0,4.306-1.732.841.841,0,0,0,.073-1.187.835.835,0,0,0-.544-.28l-.125-.012c-1.409-.143-2.55-.038-3.634-.219a7.961,7.961,0,0,1-3.1-.83,12.418,12.418,0,0,1-2.855-2.015,25.846,25.846,0,0,1-2.569-2.779l-.052-.064a.808.808,0,0,0-1.135-.119A.809.809,0,0,0,4150.06-5995.148Z"
              transform="translate(-4095.149 6033.123)" fill="#1f1960"/>
        <path id="Path_601" data-name="Path 601"
              d="M4189.346-6015.521a17.047,17.047,0,0,0-2.369-4.04,14.957,14.957,0,0,0-3.441-3.272,10.981,10.981,0,0,0-4.54-1.795l-1.234-.109-1.227.113a10.1,10.1,0,0,0-2.334.595.788.788,0,0,0-.54.977.789.789,0,0,0,.809.569l.154-.01a13.359,13.359,0,0,1,4.023.071,10.253,10.253,0,0,1,3.677,1.434,16.594,16.594,0,0,1,3.1,2.716,21.956,21.956,0,0,1,2.47,3.408l.025.043a.8.8,0,0,0,1.088.294A.8.8,0,0,0,4189.346-6015.521Z"
              transform="translate(-4111.293 6052.688)" fill="#1f1960"/>
        <path id="Path_602" data-name="Path 602"
              d="M4057.625-6056.209a22.082,22.082,0,0,1,4.048-1.152,16.5,16.5,0,0,1,4.116-.218,10.224,10.224,0,0,1,3.777,1.146,13.4,13.4,0,0,1,3.207,2.431l.114.1a.791.791,0,0,0,1.114-.061.79.79,0,0,0,.054-.988,10.161,10.161,0,0,0-1.469-1.911l-.895-.847-1.037-.678a10.961,10.961,0,0,0-4.679-1.394,14.9,14.9,0,0,0-4.728.444,17.061,17.061,0,0,0-4.358,1.711.8.8,0,0,0-.3,1.086.8.8,0,0,0,.991.347Z"
              transform="translate(-4031.135 6076.69)" fill="#1f1960"/>
        <path id="Path_603" data-name="Path 603"
              d="M4077.171-6017.683a25.949,25.949,0,0,1-3.738.6,12.39,12.39,0,0,1-3.49-.182,7.989,7.989,0,0,1-2.952-1.266c-.963-.528-1.8-1.316-2.991-2.074l-.1-.066a.84.84,0,0,0-1.16.258.843.843,0,0,0-.117.6,6.677,6.677,0,0,0,2.313,4.024,8.617,8.617,0,0,0,4.448,1.9,11.334,11.334,0,0,0,4.6-.391,11.8,11.8,0,0,0,3.95-2,.806.806,0,0,0,.135-1.133.812.812,0,0,0-.812-.288Z"
              transform="translate(-4035.323 6049.998)" fill="#1f1960"/>
        <path id="Path_604" data-name="Path 604"
              d="M4074.138-5957.984a4.876,4.876,0,0,1,3.076-1.087c-1.362-1.589-2.34-3.467-1.228-5.342,1.4-2.352,4.755-2.558,7.546-2.56,1.271,0,1.166,1.55-.055,1.614-2.284.12-3.726.118-4.74,1.985-1,1.834.167,2.886,1.765,4.329.7.634.267,1.52-.628,1.766-2.223.609-4.953,1.245-3.272,3.808.946,1.444,2.435,2.269,3.694,3.471a.46.46,0,0,1-.283.8c-3.176.682-6.006-2.9-6.641-5.179A3.5,3.5,0,0,1,4074.138-5957.984Z"
              transform="translate(-4042.565 6013.162)" fill="#1f1960"/>
        <g id="Group_751" data-name="Group 751" transform="translate(0 34.532)">
          <path id="Path_605" data-name="Path 605"
                d="M4001.325-5988.877l-20.2-6.991a2.006,2.006,0,0,1-1.64-2.057,2.693,2.693,0,0,1,2.351-2.678,2.14,2.14,0,0,1,2.436,2.192c.161,2.2-2.7.813-2.7.813a5.43,5.43,0,0,0,2.782,2.269c2.808.44,3.469-2.933,2.6-5.324-.777-2.141-4.024-4.825-8.391-2.068-3.107,1.959-3.148,7.562-.182,9.954,3.54,1.846,21.628,7.68,21.628,7.68Z"
                transform="translate(-3976.198 6003.895)" fill="#1f1960"/>
          <path id="Path_606" data-name="Path 606" d="M4058.841-5954.636l-9.149-3.166-1.522,4.4,9.149,3.166,4.23-1.915Z"
                transform="translate(-4025.446 5972.355)" fill="#89c732"/>
        </g>
      </g>
      <g id="Group_756" data-name="Group 756" transform="translate(71.575 8.135)">
        <path id="Path_607" data-name="Path 607"
              d="M4219.214-6057.04l17.872-27.923-34.2-2.535A262.4,262.4,0,0,1,4219.214-6057.04Z"
              transform="translate(-4202.888 6087.498)" fill="#1f1960"/>
        <g id="Group_755" data-name="Group 755">
          <g id="Group_754" data-name="Group 754" clipPath="url(#clip-path-2)">
            <g id="Group_753" data-name="Group 753" transform="translate(8.11 -14.659)">
              <path id="Path_608" data-name="Path 608"
                    d="M4231.551-6133.115c2.245,7.559,2.483,15.355,5.412,22.816a71.556,71.556,0,0,0,12.089,20.218c1.124,1.326-.8,2.889-2.014,1.728-10.839-10.393-21.891-29.261-17.466-44.9C4229.875-6134.318,4231.286-6134.006,4231.551-6133.115Z"
                    transform="translate(-4228.573 6133.927)" fill="#89c732"/>
              <path id="Path_609" data-name="Path 609"
                    d="M4261.874-6128.263c2.2,5.208,2.5,11.008,3.8,16.482a52.771,52.771,0,0,0,7.872,18.276,1.177,1.177,0,0,1-1.787,1.531c-8.451-8.77-13.5-23.848-11.52-35.97C4260.369-6128.716,4261.509-6129.126,4261.874-6128.263Z"
                    transform="translate(-4249.951 6130.382)" fill="#89c732"/>
              <path id="Path_610" data-name="Path 610"
                    d="M4289.628-6117.088c.87,3.809,1.233,7.694,2.107,11.506.949,4.145,2.409,10.566,6.088,13.182a1.141,1.141,0,0,1-1.29,1.88c-3.98-2.391-5.875-7.526-7.166-11.771-1.485-4.885-2.247-9.9-.89-14.875C4288.641-6117.766,4289.5-6117.632,4289.628-6117.088Z"
                    transform="translate(-4269.08 6122.728)" fill="#89c732"/>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Group_758" data-name="Group 758">
      <path id="Path_612" data-name="Path 612"
            d="M4191.132-5858.783a3.3,3.3,0,0,1,2.019,4.157,3.326,3.326,0,0,1-4.157,2.021,3.308,3.308,0,0,1-2.021-4.158A3.325,3.325,0,0,1,4191.132-5858.783Z"
            transform="translate(-4115.355 5952.831)" fill="#d52d0f"/>
      <path id="Path_613" data-name="Path 613"
            d="M3979.771-5941.386a3.842,3.842,0,0,1,2.347,4.833,3.865,3.865,0,0,1-4.832,2.347,3.84,3.84,0,0,1-2.348-4.832A3.865,3.865,0,0,1,3979.771-5941.386Z"
            transform="translate(-3970.246 6009.372)" fill="#d52d0f"/>
      <path id="Path_614" data-name="Path 614"
            d="M4062.077-6148.964l.1-.108c1.821-1.947,5.844-.555,6.072,2.1l.012.15a3.974,3.974,0,0,1-1.251,3.617,3.82,3.82,0,0,1-3.708.7,3.817,3.817,0,0,1-2.479-2.846A3.975,3.975,0,0,1,4062.077-6148.964Z"
            transform="translate(-4029.121 6151.966)" fill="#d52d0f"/>
      <path id="Path_615" data-name="Path 615"
            d="M4200.055-6120.663c4.679,1.619,2.17,8.894-2.517,7.271S4195.369-6122.285,4200.055-6120.663Z"
            transform="translate(-4121.01 6132.063)" fill="#d52d0f"/>
      <path id="Path_616" data-name="Path 616"
            d="M4188.563-5937.173a4.326,4.326,0,0,1,2.644,5.441,4.352,4.352,0,0,1-5.441,2.643,4.324,4.324,0,0,1-2.642-5.44A4.351,4.351,0,0,1,4188.563-5937.173Z"
            transform="translate(-4112.679 6006.507)" fill="#d52d0f"/>
      <path id="Path_617" data-name="Path 617"
            d="M4250.656-5916.486a3.7,3.7,0,0,1,2.263,4.656,3.726,3.726,0,0,1-4.657,2.263,3.7,3.7,0,0,1-2.262-4.656A3.724,3.724,0,0,1,4250.656-5916.486Z"
            transform="translate(-4155.729 5992.329)" fill="#1f1960"/>
      <path id="Path_618" data-name="Path 618"
            d="M4015.351-6104.356a2.994,2.994,0,0,1,.961,5.061l-.116.095a2.911,2.911,0,0,1-4.7-1.626l-.033-.146A2.994,2.994,0,0,1,4015.351-6104.356Z"
            transform="translate(-3995.35 6120.852)" fill="#d52d0f"/>
      <path id="Path_619" data-name="Path 619"
            d="M4012.031-6156.062a3.7,3.7,0,0,1,2.261,4.654,3.722,3.722,0,0,1-4.654,2.262,3.7,3.7,0,0,1-2.26-4.655A3.721,3.721,0,0,1,4012.031-6156.062Z"
            transform="translate(-3992.448 6156.261)" fill="#1f1960"/>
      <path id="Path_620" data-name="Path 620"
            d="M3963.756-5900.065c2.976,1.03,1.38,5.656-1.6,4.624S3960.776-5901.1,3963.756-5900.065Z"
            transform="translate(-3960.569 5981.058)" fill="#d52d0f"/>
      <path id="Path_621" data-name="Path 621"
            d="M4009.53-5895.781c4.633,1.6,2.149,8.808-2.492,7.2S4004.889-5897.387,4009.53-5895.781Z"
            transform="translate(-3990.675 5978.184)" fill="#1f1960"/>
    </g>
  </svg>;
};

export const IconNoBookings = (props: iconProps) => {
  return <svg xmlns="http://www.w3.org/2000/svg" id="Group_746" data-name="Group 746" width="126.07" height="102.481"
              viewBox="0 0 126.07 102.481">
    <g id="Group_723" data-name="Group 723" transform="translate(0 7.479)">
      <path id="Path_553" data-name="Path 553"
            d="M224.029-2214.821c0-2.743-2.535-4.988-5.634-4.988h-114.8c-3.1,0-5.634,2.245-5.634,4.988v11.342h126.07Z"
            transform="translate(-97.959 2219.808)" fill="#1f1960"/>
      <path id="Path_554" data-name="Path 554"
            d="M224.029-2014.543v-74.936H97.959v74.936a3.687,3.687,0,0,0,3.636,3.737h118.8A3.687,3.687,0,0,0,224.029-2014.543Z"
            transform="translate(-97.959 2105.808)" fill="#edeffc"/>
    </g>
    <g id="Group_725" data-name="Group 725" transform="translate(39.468 32.529)">
      <rect id="Rectangle_1687" data-name="Rectangle 1687" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_724" data-name="Group 724" transform="translate(2.517 3.772)">
        <path id="Path_555" data-name="Path 555" d="M462.937-1922.79h0Z" transform="translate(-459.193 1931.182)"
              fill="#f44e4e"/>
        <path id="Path_556" data-name="Path 556"
              d="M473.918-1989.609a.559.559,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.559.559,0,0,0,473.918-1989.609Z"
              transform="translate(-461.944 1989.772)" fill="#f44e4e"/>
        <path id="Path_557" data-name="Path 557"
              d="M436.8-1955.219l.394-.394L434-1958.8a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,436.8-1955.219Z"
              transform="translate(-433.053 1962.823)" fill="#f44e4e"/>
        <path id="Path_558" data-name="Path 558"
              d="M461.8-1931.829a.559.559,0,0,0,0,.788h0a.558.558,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-458.059 1939.434)" fill="#f44e4e"/>
      </g>
    </g>
    <g id="Group_727" data-name="Group 727" transform="translate(9.295 54.179)">
      <rect id="Rectangle_1688" data-name="Rectangle 1688" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_726" data-name="Group 726" transform="translate(2.517 3.772)">
        <path id="Path_559" data-name="Path 559" d="M222.112-1749.99h0Z" transform="translate(-218.368 1758.382)"
              fill="#f44e4e"/>
        <path id="Path_560" data-name="Path 560"
              d="M233.093-1816.81a.559.559,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.559.559,0,0,0,233.093-1816.81Z"
              transform="translate(-221.119 1816.973)" fill="#f44e4e"/>
        <path id="Path_561" data-name="Path 561"
              d="M195.973-1782.418l.394-.394L193.18-1786a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,195.973-1782.418Z"
              transform="translate(-192.229 1790.023)" fill="#f44e4e"/>
        <path id="Path_562" data-name="Path 562"
              d="M220.979-1759.029a.559.559,0,0,0,0,.788h0a.559.559,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-217.234 1766.634)" fill="#f44e4e"/>
      </g>
    </g>
    <g id="Group_729" data-name="Group 729" transform="translate(69.432 32.529)">
      <rect id="Rectangle_1689" data-name="Rectangle 1689" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_728" data-name="Group 728" transform="translate(2.517 3.772)">
        <path id="Path_563" data-name="Path 563" d="M702.088-1922.79h0Z" transform="translate(-698.344 1931.182)"
              fill="#f44e4e"/>
        <path id="Path_564" data-name="Path 564"
              d="M713.069-1989.609a.559.559,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.559.559,0,0,0,713.069-1989.609Z"
              transform="translate(-701.095 1989.772)" fill="#f44e4e"/>
        <path id="Path_565" data-name="Path 565"
              d="M675.949-1955.219l.394-.394-3.188-3.188a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,675.949-1955.219Z"
              transform="translate(-672.204 1962.823)" fill="#f44e4e"/>
        <path id="Path_566" data-name="Path 566"
              d="M700.954-1931.829a.559.559,0,0,0,0,.788h0a.558.558,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-697.21 1939.434)" fill="#f44e4e"/>
      </g>
    </g>
    <g id="Group_731" data-name="Group 731" transform="translate(99.186 32.529)">
      <rect id="Rectangle_1690" data-name="Rectangle 1690" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_730" data-name="Group 730" transform="translate(2.517 3.772)">
        <path id="Path_567" data-name="Path 567" d="M939.565-1922.79h0Z" transform="translate(-935.821 1931.182)"
              fill="#f44e4e"/>
        <path id="Path_568" data-name="Path 568"
              d="M950.546-1989.609a.559.559,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.559.559,0,0,0,950.546-1989.609Z"
              transform="translate(-938.572 1989.772)" fill="#f44e4e"/>
        <path id="Path_569" data-name="Path 569"
              d="M913.426-1955.219l.394-.394-3.188-3.188a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,913.426-1955.219Z"
              transform="translate(-909.682 1962.823)" fill="#f44e4e"/>
        <path id="Path_570" data-name="Path 570"
              d="M938.431-1931.829a.559.559,0,0,0,0,.788h0a.558.558,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-934.687 1939.434)" fill="#f44e4e"/>
      </g>
    </g>
    <g id="Group_733" data-name="Group 733" transform="translate(39.468 54.179)">
      <rect id="Rectangle_1691" data-name="Rectangle 1691" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_732" data-name="Group 732" transform="translate(2.517 3.772)">
        <path id="Path_571" data-name="Path 571" d="M462.937-1749.99h0Z" transform="translate(-459.193 1758.382)"
              fill="#f44e4e"/>
        <path id="Path_572" data-name="Path 572"
              d="M473.918-1816.81a.559.559,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.559.559,0,0,0,473.918-1816.81Z"
              transform="translate(-461.944 1816.973)" fill="#f44e4e"/>
        <path id="Path_573" data-name="Path 573"
              d="M436.8-1782.418l.394-.394L434-1786a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,436.8-1782.418Z"
              transform="translate(-433.053 1790.023)" fill="#f44e4e"/>
        <path id="Path_574" data-name="Path 574"
              d="M461.8-1759.029a.559.559,0,0,0,0,.788h0a.559.559,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-458.059 1766.634)" fill="#f44e4e"/>
      </g>
    </g>
    <g id="Group_735" data-name="Group 735" transform="translate(69.432 54.179)">
      <rect id="Rectangle_1692" data-name="Rectangle 1692" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_734" data-name="Group 734" transform="translate(2.517 3.772)">
        <path id="Path_575" data-name="Path 575" d="M702.088-1749.99h0Z" transform="translate(-698.344 1758.382)"
              fill="#f44e4e"/>
        <path id="Path_576" data-name="Path 576"
              d="M713.069-1816.81a.559.559,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.559.559,0,0,0,713.069-1816.81Z"
              transform="translate(-701.095 1816.973)" fill="#f44e4e"/>
        <path id="Path_577" data-name="Path 577"
              d="M675.949-1782.418l.394-.394L673.155-1786a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,675.949-1782.418Z"
              transform="translate(-672.204 1790.023)" fill="#f44e4e"/>
        <path id="Path_578" data-name="Path 578"
              d="M700.954-1759.029a.559.559,0,0,0,0,.788h0a.559.559,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-697.21 1766.634)" fill="#f44e4e"/>
      </g>
    </g>
    <g id="Group_737" data-name="Group 737" transform="translate(99.186 54.179)">
      <rect id="Rectangle_1693" data-name="Rectangle 1693" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_736" data-name="Group 736" transform="translate(2.517 3.772)">
        <path id="Path_579" data-name="Path 579" d="M939.565-1749.99h0Z" transform="translate(-935.821 1758.382)"
              fill="#f44e4e"/>
        <path id="Path_580" data-name="Path 580"
              d="M950.546-1816.81a.559.559,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.559.559,0,0,0,950.546-1816.81Z"
              transform="translate(-938.572 1816.973)" fill="#f44e4e"/>
        <path id="Path_581" data-name="Path 581"
              d="M913.426-1782.418l.394-.394L910.632-1786a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,913.426-1782.418Z"
              transform="translate(-909.682 1790.023)" fill="#f44e4e"/>
        <path id="Path_582" data-name="Path 582"
              d="M938.431-1759.029a.559.559,0,0,0,0,.788h0a.559.559,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-934.687 1766.634)" fill="#f44e4e"/>
      </g>
    </g>
    <g id="Group_739" data-name="Group 739" transform="translate(9.295 75.83)">
      <rect id="Rectangle_1694" data-name="Rectangle 1694" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_738" data-name="Group 738" transform="translate(2.517 3.772)">
        <path id="Path_583" data-name="Path 583" d="M222.112-1577.19h0Z" transform="translate(-218.368 1585.583)"
              fill="#f44e4e"/>
        <path id="Path_584" data-name="Path 584"
              d="M233.093-1644.01a.558.558,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.558.558,0,0,0,233.093-1644.01Z"
              transform="translate(-221.119 1644.173)" fill="#f44e4e"/>
        <path id="Path_585" data-name="Path 585"
              d="M195.973-1609.619l.394-.394-3.188-3.187a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,195.973-1609.619Z"
              transform="translate(-192.229 1617.223)" fill="#f44e4e"/>
        <path id="Path_586" data-name="Path 586"
              d="M220.979-1586.23a.559.559,0,0,0,0,.788h0a.559.559,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-217.234 1593.834)" fill="#f44e4e"/>
      </g>
    </g>
    <g id="Group_741" data-name="Group 741" transform="translate(39.468 75.83)">
      <rect id="Rectangle_1695" data-name="Rectangle 1695" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_740" data-name="Group 740" transform="translate(2.517 3.772)">
        <path id="Path_587" data-name="Path 587" d="M462.937-1577.19h0Z" transform="translate(-459.193 1585.583)"
              fill="#f44e4e"/>
        <path id="Path_588" data-name="Path 588"
              d="M473.918-1644.01a.558.558,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.559.559,0,0,0,473.918-1644.01Z"
              transform="translate(-461.944 1644.173)" fill="#f44e4e"/>
        <path id="Path_589" data-name="Path 589"
              d="M436.8-1609.619l.394-.394L434-1613.2a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,436.8-1609.619Z"
              transform="translate(-433.053 1617.223)" fill="#f44e4e"/>
        <path id="Path_590" data-name="Path 590"
              d="M461.8-1586.23a.559.559,0,0,0,0,.788h0a.559.559,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-458.059 1593.834)" fill="#f44e4e"/>
      </g>
    </g>
    <g id="Group_743" data-name="Group 743" transform="translate(69.432 75.83)">
      <rect id="Rectangle_1696" data-name="Rectangle 1696" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_742" data-name="Group 742" transform="translate(2.517 3.772)">
        <path id="Path_591" data-name="Path 591" d="M702.088-1577.19h0Z" transform="translate(-698.344 1585.583)"
              fill="#f44e4e"/>
        <path id="Path_592" data-name="Path 592"
              d="M713.069-1644.01a.558.558,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.559.559,0,0,0,713.069-1644.01Z"
              transform="translate(-701.095 1644.173)" fill="#f44e4e"/>
        <path id="Path_593" data-name="Path 593"
              d="M675.949-1609.619l.394-.394-3.188-3.187a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,675.949-1609.619Z"
              transform="translate(-672.204 1617.223)" fill="#f44e4e"/>
        <path id="Path_594" data-name="Path 594"
              d="M700.954-1586.23a.559.559,0,0,0,0,.788h0a.559.559,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-697.21 1593.834)" fill="#f44e4e"/>
      </g>
    </g>
    <g id="Group_745" data-name="Group 745" transform="translate(99.186 75.83)">
      <rect id="Rectangle_1697" data-name="Rectangle 1697" width="17.17" height="16.099" rx="8.049" fill="#fff"/>
      <g id="Group_744" data-name="Group 744" transform="translate(2.517 3.772)">
        <path id="Path_595" data-name="Path 595" d="M939.565-1577.19h0Z" transform="translate(-935.821 1585.583)"
              fill="#f44e4e"/>
        <path id="Path_596" data-name="Path 596"
              d="M950.546-1644.01a.558.558,0,0,0-.788,0l-7.048,7.048.788.788,7.048-7.048A.559.559,0,0,0,950.546-1644.01Z"
              transform="translate(-938.572 1644.173)" fill="#f44e4e"/>
        <path id="Path_597" data-name="Path 597"
              d="M913.426-1609.619l.394-.394-3.188-3.187a.559.559,0,0,0-.788,0,.559.559,0,0,0,0,.788l3.582,3.582A.559.559,0,0,1,913.426-1609.619Z"
              transform="translate(-909.682 1617.223)" fill="#f44e4e"/>
        <path id="Path_598" data-name="Path 598"
              d="M938.431-1586.23a.559.559,0,0,0,0,.788h0a.558.558,0,0,0,.788,0l.394-.394-.788-.788Z"
              transform="translate(-934.687 1593.834)" fill="#f44e4e"/>
      </g>
    </g>
    <circle id="Ellipse_402" data-name="Ellipse 402" cx="4.21" cy="4.21" r="4.21" transform="translate(24.388 10.37)"
            fill="#837dc1"/>
    <rect id="Rectangle_1698" data-name="Rectangle 1698" width="4.643" height="16.437" rx="2.322"
          transform="translate(26.442)" fill="#89c732"/>
    <circle id="Ellipse_403" data-name="Ellipse 403" cx="4.21" cy="4.21" r="4.21" transform="translate(47.267 10.37)"
            fill="#837dc1"/>
    <rect id="Rectangle_1699" data-name="Rectangle 1699" width="4.643" height="16.437" rx="2.322"
          transform="translate(49.32)" fill="#89c732"/>
    <circle id="Ellipse_404" data-name="Ellipse 404" cx="4.21" cy="4.21" r="4.21" transform="translate(70.145 10.37)"
            fill="#837dc1"/>
    <rect id="Rectangle_1700" data-name="Rectangle 1700" width="4.643" height="16.437" rx="2.322"
          transform="translate(72.199)" fill="#89c732"/>
    <circle id="Ellipse_405" data-name="Ellipse 405" cx="4.21" cy="4.21" r="4.21" transform="translate(93.024 10.37)"
            fill="#837dc1"/>
    <rect id="Rectangle_1701" data-name="Rectangle 1701" width="4.643" height="16.437" rx="2.322"
          transform="translate(95.077)" fill="#89c732"/>
  </svg>;
};


export const IconX = (props: iconProps) => {
  return <svg style={props.style} className="Bz112c Bz112c-r9oPif" viewBox="0 0 36 36" height="28" width="28"
              fill="#261f63">
    <path
        d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
  </svg>;
};

export const IconSave = (props: iconProps) => {
  return <svg stroke="currentColor" fill="#89c732" strokeWidth="0" viewBox="0 0 1024 1024" width="33px" height="32px"
              xmlns="http://www.w3.org/2000/svg">
    <path
        d="M893.3 293.3L730.7 130.7c-7.5-7.5-16.7-13-26.7-16V112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V338.5c0-17-6.7-33.2-18.7-45.2zM384 184h256v104H384V184zm456 656H184V184h136v136c0 17.7 14.3 32 32 32h320c17.7 0 32-14.3 32-32V205.8l136 136V840zM512 442c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144-64.5-144-144-144zm0 224c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80z"></path>
  </svg>;
};

export const IconCancel = (props: iconProps) => {
  return <svg id="Layer_1" data-name="Layer 1" opacity={'0.5'} width={'33px'} height={'32px'} fill={'#d52d0f'}
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.89 38.89">
    <path id="fi-rr-cross-small" className="cls-1"
          d="M26.92,12h0a1.24,1.24,0,0,0-1.76,0l-5.72,5.71L13.73,12A1.24,1.24,0,0,0,12,12h0a1.24,1.24,0,0,0,0,1.76l5.71,5.72L12,25.16a1.24,1.24,0,0,0,0,1.76h0a1.24,1.24,0,0,0,1.76,0l5.72-5.71,5.71,5.71a1.24,1.24,0,0,0,1.76,0h0a1.24,1.24,0,0,0,0-1.76l-5.71-5.71,5.71-5.72A1.24,1.24,0,0,0,26.92,12Z"/>
  </svg>;
};

export const IconCheck = (props: iconProps) => {
  return <svg id="Layer_1" data-name="Layer 1" opacity={'0.7'} width={'25px'} height={'24px'} fill={'#89c732'}
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.89 38.89">
    <path id="fi-rr-check" className="cls-1"
          d="M31.19,10.53,16.3,25.83a1.29,1.29,0,0,1-1.8,0L7.7,19a1.27,1.27,0,0,0-1.8,0h0a1.27,1.27,0,0,0,0,1.8l6.8,6.8a3.83,3.83,0,0,0,5.41,0L33,12.33a1.27,1.27,0,0,0,0-1.8h0A1.27,1.27,0,0,0,31.19,10.53Z"/>
  </svg>;
};


export const NotificationEntryIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63">
    <defs>
    </defs>
    <g transform="translate(-763 -331)">
      <rect
          style={{ fill: 'none', stroke: '#eff5e6', strokeWidth: '2px' }}
          className="a" width="61" height="61" rx="30.5" transform="translate(764 332)"/>
      <path className="b"
            style={{ fill: '#837dc1' }}
            d="M21.683,15.937l-3.921,6.4a5.442,5.442,0,0,1-4.023,2.575,5.525,5.525,0,0,1-.653.038,5.418,5.418,0,0,1-3.119-.993,4.928,4.928,0,0,1-6.953-6.826L1.647,15.766A5.472,5.472,0,0,1,2.64,7.241L8.635,3.534a9.209,9.209,0,0,1,10.735.573l1.725-1.725a1.039,1.039,0,1,1,1.47,1.47L20.842,5.576a9.3,9.3,0,0,1,.841,10.361ZM8.384,22.5,4.518,18.636A2.829,2.829,0,0,0,5,22.029,2.939,2.939,0,0,0,8.384,22.5Zm11.643,2.446a1.04,1.04,0,0,1-.614-1.879,8.974,8.974,0,0,0,3.446-5.621,1.039,1.039,0,1,1,2.03.447,11.123,11.123,0,0,1-4.25,6.854,1.04,1.04,0,0,1-.612.2ZM1.039,5.966A1.04,1.04,0,0,1,.208,4.309,11.115,11.115,0,0,1,7.186.021,1.04,1.04,0,0,1,7.6,2.058,8.977,8.977,0,0,0,1.871,5.545a1.04,1.04,0,0,1-.832.421Z"
            transform="translate(782.043 350.023)"/>
    </g>
  </svg>;
};


export const IconNoBirthdays_2 = (props: iconProps) => {
  const iconStyle: object = {
    fill: '#e6e6e6',
    stroke: (props.stroke || '#1f1960'),
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: (props.width || '2px'),
  };
  return (
      <div></div>
  );
};
