import {
    CHANGE_COMPANY,
} from "./constants";

const initialState = {
    showDropdown: false,
    selectedUser: {},
    error: null,
};

const userSelectReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CHANGE_COMPANY:
            return {
                ...state,
                selectedCompany: payload,
            };
        default: return state;
    }
}

export default userSelectReducer;
