import {
  adminData,
  companyData,
  overtimeTypes,
  userData,
  vacationTypes,
  healthInsurance,
  projects,
} from "types/configuration.type";

export interface ConfigurationsState {
  quickbooksLoadingState?: boolean;
  showModal: boolean;
  modalOpener?: string;
  editMode?: boolean;
  tenantId?: number;
  code?: string;
  searchLoading?: boolean;
  qbLink?: string;
  refreshToken?: string;
  loading?: boolean;
  setButtonText?: string;
  hasQuickbooks?: any;

  lastBackupDate?: Date;
  isRequestingBackup: boolean;
  showSetupModal?: boolean;
  setupModalOpener?: string;
  setupEditMode?: boolean;
  showSubmitButton?: boolean;

  modalSubmitLoading?: boolean;
  companyData?: companyData;
  adminData?: adminData;
  userData?: userData;
  searchedCompaniesList?: {
    id: number;
    name: string;
  }[];

  pickedCompanies?: {
    id: number;
    name: string;
  }[];

  payrollAccounts?: {
    id: number;
    value?: number;
    name: string;
  }[];

  contactInfo?: {
    name: string;
    email: string;
    password: string;
    registrationDate: string;
    expirationDate: string;
    quickbooksId: number;
    status: number;
    nui: number;
  };

  tenantSectors?: {
    id: number;
    tenantId: number;
    name: string;
  }[];

  accounts?: {
    name: string;
  }[];

  taxesData?: taxStepsData;
  globalData?: globalData;
  accountDataInfo?: accountData;

  contributionData?: {
    employerContribute?: number;
    personalContribute?: number;
  };

  overtime?: overtimeTypes;
  vacations?: vacationTypes;
  healthInsurance?: healthInsurance;
  tenantHealthInsurance?: healthInsurance;

  projects?: {
    id?: number;
    tenantId?: number;
    name?: string;
    status?: number;
    createdAt?: string;
    budget?: number;
    budgetLines?: {
      budget?: number;
      id?: number;
      name?: string;
      projectId?: number;
      contributionItemQuickbooksId?: string;
      quickbooksId?: string;
      createdAt?: string;
    }[];
    statusName?: string;
  }[];

  allTenants?: {
    id: number;
    name: string;
    email: string;
    tenantName: string;
    status: string;
    registrationDate: string;
    expirationDate: string;
  }[];

  companyLogin?: {
    id: number;
    name: string;
    logo: string;
    hasQuickbooks?: any;
  }[];

  allUsers?: {
    id: number;
    name: string;
    email: string;
    company: string;
    status: string;
    role: string;
    companies?: {
      name?: string;
    };
  }[];

  tenantAccounts?: {
    account_name?: string;
    id: number;
    payroll_account?: string;
    quickbooks_account?: number;
    tenant_id?: number;
    checked_account?: boolean;
  }[];

  allTenantUsers?: {
    id: number;
    name: string;
    email: string;
    company: string;
    status: string;
    role: string;
    companies?: {
      name?: string;
    };
  }[];

  tenant?: {
    id: number;
    name: string;
    email: string;
    tenantName: string;
    status: string;
    registrationDate: string;
    expirationDate: string;
    nui: number;
    statusId: number;
  };

  project?: {
    id?: number;
    tenantId?: number;
    name?: string;
    status?: number;
    createdAt?: string;
    budgetLines?: {
      budget?: number;
      id?: number;
      name?: string;
      projectId?: number;
      contributionItemQuickbooksId?: string;
      quickbooksId?: string;
      createdAt?: string;
    }[];
    statusName?: string;
    projectBudget?: string;
    budget?: string;
  };

  user?: {
    id: number;
    name: string;
    email: string;
    company: string;
    status: string;
    role: string;
    logo: string;
    roleId: number;
    agreed?: number;
  };

  audits?: {
    user: string;
    personalNumber: number;
    type: string;
    page: string;
    time: string;
    date: string;
  }[];
}
export interface contributionSteps {
  contributeId?: number;
  personalContribute?: number;
  employerContribute?: number;
}

export enum taxSteps {
  FIRST,
  SECOND,
  THIRD,
  FOURTH,
}

export interface taxStepsData {
  firstStep: {
    id?: number;
    percentage?: number;
    firstValue?: number;
    secondValue?: number;
    secondaryEmployerPercentage?: number;
  };

  secondStep: {
    id?: number;
    percentage?: number;
    firstValue?: number;
    secondValue?: number;
    secondaryEmployerPercentage?: number;
  };

  thirdStep: {
    id?: number;
    percentage?: number;
    firstValue?: number;
    secondValue?: number;
    secondaryEmployerPercentage?: number;
  };

  fourthStep: {
    id?: number;
    percentage?: number;
    firstValue?: number;
    secondValue?: number;
    secondaryEmployerPercentage?: number;
  };
}

export interface accountData {
  id?: number;
  tenantId?: number;
  payroll_account?: string;
  payroll_account_id?: string;
  quickbooksAccount?: any;
  accountName?: string;
  checkedAccount?: boolean;
  checked_account?: boolean;
  account_name?: string;
  value?: number;
  label?: string;
}

export interface globalData {
  vacations?: {
    id?: number;
    name?: string;
    module?: string;
  }[];

  employees?: {
    id?: number;
    name?: string;
    module?: string;
  }[];

  users?: {
    id?: number;
    name?: string;
    module?: string;
  }[];
}
