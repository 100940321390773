import styles from "./MonthlyStatistics.module.scss";
import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import DropdownSelect from "components/DropdownSelect/DropdownSelect.component";
import { FiDownload } from "react-icons/fi";
import { downloadTypeOptions } from "utils/globalVariables";
import { IconDocument } from "assets/icons/icons";
import ReactToPrint from "react-to-print";
import { format } from "date-fns";
import { DebounceInput } from "react-debounce-input";
import Table from "components/Table/Table.component";
import {
	loadMonthlyStatistics,
	loadExportExcel,
	loadExportPdf,
	sendEmployeesEmail,
} from "./actions";
import { monthNamesAL } from "utils/globalVariables";
import { generateArrayOfYears } from "utils/functions";
import Checkbox from "react-custom-checkbox";
import { FiCalendar, FiCheck } from "react-icons/fi";
import { globalState } from "types/globalState.type";
import { toast } from "react-toastify";
import _ from "lodash";
import LoaderCentered from "components/partials/LoaderCentered/LoaderCentered.component";
import { useTranslation } from "react-i18next";
import InputCustom from "components/InputCustom/InputCustom.component";
import tableStyles from 'components/Table/Table.module.scss';
import classNames from 'classnames';


interface Props {
	isOpen: boolean;
	setIsMonthlyStatisticsOpen?: any;
}

let currentYear: number = new Date().getFullYear();
let currentMonth: number = new Date().getMonth();

const monthsList = monthNamesAL;
let yearsList = generateArrayOfYears(21);

const MonthlyStatistics: React.FC<Props> = (props) => {
	const tenantId = useSelector(
		(state: globalState) => state.globalReducer.tenantId
	);

	const { t, i18n } = useTranslation("common");
	const history = useHistory();
	const dispatch = useDispatch();
	const componentRef = useRef<HTMLDivElement>(null);
	const [isDownloadModalOpen, setIsDownloadModalOpen] =
		useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [checkedEmployees, setCheckedEmployees] = useState<Array<number>>([]);
	const [isAllCheckboxesChecked, setIsAllCheckboxesChecked] =
		useState<boolean>(false);
	const todayDate = format(new Date(), "dd.MM.yyyy");
	const [showFilterContent, setShowFilterContent] = useState(false);
	const [pickedYear, setPickedYear] = useState(currentYear);
	const [pickedMonth, setPickedMonth] = useState(currentMonth);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
	const [mainCheckboxState, setMainCheckboxState] = useState(false);
	const [mainCheckboxClick, setMainCheckboxClick] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [isEmailOpen, setIsEmailOpen] = useState<boolean>(false);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [emailValidMessage, setEmailValidMessage] = useState<string>("");
	const isSendEmailLoading = useSelector((state: RootStateOrAny) => state.documentsReducer.sendEmailLoading);

	const tableHeaderData = [
		"",
		t("employees.nameAndSurname"),
		t("bankPay.personalId"),
		t("monthlyStats.brutoSalary"),
		t("monthlyStats.employeerContribution"),
		t("monthlyStats.employeeContribution"),
		t("salaryCalculation.taxableIncome"),
		t("salaryCalculation.taxes"),
		t("salaryCalculation.extras"),
		t("salaryCalculation.stops"),
		t("salaryCalculation.healthInsurance"),
		t("monthlyStats.netoSalary"),
	];
	const allMonthlyStatisticsReports = useSelector(
		(state: RootStateOrAny) =>
			state?.monthlyStatisticsReducer?.allMonthlyStatistics
	);
	const backdropParent: string = useSelector(
		(state: globalState) => state.appWrapperReducer.backdropParent
	);
	const state = useSelector(
		(state: RootStateOrAny) => state.monthlyStatisticsReducer
	);

	useEffect(() => {
		if (!isSendEmailLoading) {
			setIsModalOpen(false);
		}
	}, [isSendEmailLoading]);

	const monthsWrapperRef = useRef(null);
	const periodWrapperRef = useRef(null);

	const handleOpenModal = () => {
		setIsEmailOpen(false);
	};

	useEffect(() => {
		const dataLength = allMonthlyStatisticsReports?.data?.length || 0;
		const rows = selectedCheckboxes?.length || 0;
		if (dataLength) {
			dataLength > 0 && dataLength === rows
				? setMainCheckboxState((prev) => true)
				: setMainCheckboxState((prev) => false);
		}
	}, [selectedCheckboxes, allMonthlyStatisticsReports]);

	useEffect(() => {
		switch (mainCheckboxState) {
			case true:
				const checkArray = [];
				mainCheckboxClick &&
					allMonthlyStatisticsReports?.data?.forEach((item) =>
						checkArray.push(item.id)
					);
				mainCheckboxClick && setSelectedCheckboxes((prev) => checkArray);
				break;
			case false:
				mainCheckboxClick && setSelectedCheckboxes((prev) => []);
				break;
		}
	}, [mainCheckboxState, mainCheckboxClick, allMonthlyStatisticsReports]);

	const handleSearchByEmployeeName = (event) => {
		let searchPass = true;
		if (event.target.value) {
			const value = event.target.value;
			if (value && !value.trim().length) {
				searchPass = false;
			}
		}

		searchPass && setSearchTerm(event.target.value);
		searchPass &&
			dispatch(loadMonthlyStatistics(tenantId, event.target.value, null, null));
	};

	const handleEmailChange = (e) => {
		const emailRegex = /\S+@\S+\.\S+/;
		setEmail(e.target.value);
		if (emailRegex.test(email)) {
			setIsEmailValid(true);
		} else {
			setIsEmailValid(false);
			setEmailValidMessage('Please enter a valid email!');
		}
	};

	const pageStyle = `
        @page {
            padding: 0 10px;
            font-size: 15px;
            background-color: red;
        }
        .noPrint {
            display: none !important;
        }
          
        .printHeightFix {
            height: unset !important;
            min-height: unset !important;
            overflow: initial !important;
        }
    `;

	const handleDownloadPick = (e, item) => {
		if (item.value === 1) {
			dispatch(
				loadExportExcel({
					tenantId,
					month: pickedMonth + 1,
					year: pickedYear,
					name: searchTerm || null,
				})
			);
		} else {
			dispatch(
				loadExportPdf({
					tenantId,
					month: pickedMonth + 1,
					year: pickedYear,
					name: searchTerm || null,
				})
			);
		}
	};

	const backDropCallback = () => {
		setShowFilterContent((prev) => !prev);
		setSelectedRow((prev) => null);
	};

	const handlePeriodContainerClick = (event) => {
		setShowFilterContent((prev) => !prev);
	};
	useEffect(() => {
		const handleOutsideMenuClick = (e: any) => {
			setShowFilterContent(!showFilterContent);
		};

		if (showFilterContent)
			window.addEventListener("click", handleOutsideMenuClick);
		return () => window.removeEventListener("click", handleOutsideMenuClick);
	}, [showFilterContent]);

	useEffect(() => {
		showFilterContent &&
			setTimeout(() => {
				periodWrapperRef?.current &&
					(periodWrapperRef.current.style.height =
						monthsWrapperRef.current.offsetHeight + 2 + "px");
			}, 0);
	}, [showFilterContent]);

	useEffect(() => {
		const dataLength = allMonthlyStatisticsReports?.data?.length || 0;
		const rows = selectedCheckboxes?.length || 0;
		if (dataLength) {
			dataLength > 0 && dataLength === rows
				? setMainCheckboxState((prev) => true)
				: setMainCheckboxState((prev) => false);
		}
	}, [selectedCheckboxes, allMonthlyStatisticsReports]);

	useEffect(() => {
		switch (mainCheckboxState) {
			case true:
				const checkArray = [];
				mainCheckboxClick &&
					allMonthlyStatisticsReports?.data?.forEach((item) =>
						checkArray.push(item.id)
					);
				mainCheckboxClick && setSelectedCheckboxes((prev) => checkArray);
				break;
			case false:
				mainCheckboxClick && setSelectedCheckboxes((prev) => []);
				break;
		}
	}, [mainCheckboxState, mainCheckboxClick, allMonthlyStatisticsReports]);

	const handleCheckAllEmployee = () => {
		setMainCheckboxClick((prev) => true);
		setMainCheckboxState((prev) => !mainCheckboxState);
	};

	const handleCheckEmployee = (checked, id) => {
		setMainCheckboxClick((prev) => false);
		checked
			? setSelectedCheckboxes((prev) => {
				return _.union(prev, [id]);
			})
			: setSelectedCheckboxes((prev) => {
				return selectedCheckboxes.filter(function (item) {
					return item !== id;
				});
			});
	};

	useEffect(() => {
		const month = pickedMonth + 1;
		dispatch(loadMonthlyStatistics(tenantId, null, month, pickedYear));
	}, [dispatch, pickedMonth, pickedYear, tenantId]);

	const handleEmailClick = (tenantId, checkedEmployees) => {
		dispatch(
			sendEmployeesEmail(
				tenantId,
				pickedMonth + 1,
				pickedYear,
				checkedEmployees,
				email
			)
		) && setIsEmailOpen(false)
	};

	return (
		<>
			<ModalCustom
				show={props?.isOpen}
				contentClass={styles.contentClass}
				footerClass={styles.footerClass}
				ref={componentRef}
				onClose={() => {
					props?.setIsMonthlyStatisticsOpen((prev) => false);
				}}
				hideFooterButton={true}
			>
				<div className={styles.monthlyStatistics}>
					<div className={styles.point}></div>
					<div className={styles.monthlyStatisticTitle}>
						{t("reports.monthlySalary")}
					</div>
					<div
						className={`${styles.downloadText} noPrint`}
						onClick={() => setIsDownloadModalOpen(true)}
					>
						<div className={styles.dropdownText}>
							<DropdownSelect
								data={downloadTypeOptions}
								buttonText={
									<div className={`${styles.dropdownText} pr-5`}>
										<div className={styles.downloadIcon}>
											<FiDownload strokeWidth="2px" />
										</div>
										{t("reports.download")}
									</div>
								}
								showArrow={true}
								noWidth={true}
								menuPosition="right"
								optionClick={(event, item) => {
									handleDownloadPick(event, item);
								}}
							/>
						</div>
					</div>
					<div
						className={`${styles.downloadText} ${styles.iconText} noPrint`}
						onClick={(event) => {
							setIsEmailOpen(!isEmailOpen);
						}}
					>
						{state.loadingEmailButton ? (
							<LoaderCentered style={{ height: "22px", width: "22px" }} />
						) : (
							<>
								<div className={styles.sendEmailIcon}>
									<FiDownload strokeWidth="2px" />
								</div>
								{t("salaryCalculation.sendEmail")}
							</>
						)}
					</div>
					<div className={`${styles.printIcon} noPrint`}>
						<IconDocument stroke={"#89C732"} />
					</div>
					<div className={`${styles.downloadText} ${styles.iconText} noPrint`}>
						<ReactToPrint
							trigger={() => (
								<div className={styles.printText}>{t("reports.print")}</div>
							)}
							content={() => componentRef.current}
							pageStyle={pageStyle}
						/>
					</div>
					<div className={styles.headerDate}>Data: {todayDate}</div>
				</div>

				<div className={`row ${styles.filterRow}`}>
					<div className={styles.col}>
						<div
							className={`${styles.filterText} ${searchTerm.length ? "" : "noPrint"
								}`}
						>
							<div className={styles.nameAndSurname}>
								{t("employees.nameAndSurname")}
							</div>
						</div>
						<div
							className={`${styles.filterBox} ${searchTerm.length ? "" : "noPrint"
								}`}
						>
							<DebounceInput
								id="searchInput"
								type="search"
								minLength={1}
								aria-autocomplete="none"
								autoComplete="off"
								autoFocus={false}
								debounceTimeout={300}
								value={searchTerm}
								className={styles.surnameBoxContainer}
								onChange={handleSearchByEmployeeName}
							/>
						</div>
					</div>

					<div className={`${styles.filterDescription}`}>
						<span className="noPrint">{t("reports.filterPeriod")}</span>
						<div
							className={`${styles.filterButtons} ${backdropParent !== "calculationsPeriodFilter" ? "lowZindex" : ""
								}`}
							onClick={handlePeriodContainerClick}
						>
							<div className={styles.button}>{monthsList[pickedMonth]}</div>
							<div className={`${styles.button} ${styles.yearsButton}`}>
								{pickedYear}
								<div className={styles.calendar}>
									<FiCalendar />
								</div>
							</div>
						</div>
					</div>

					{showFilterContent && (
						<div className={styles.filterContent} ref={periodWrapperRef}>
							<div className={styles.months} ref={monthsWrapperRef}>
								{monthsList.map((item, index) => {
									return (
										<div
											key={index}
											className={`${styles.periodItem} ${index === pickedMonth ? styles.selected : ""
												}`}
											onClick={() => {
												setPickedMonth((prev) => index);
											}}
										>
											{item}
										</div>
									);
								})}
							</div>
							<div className={styles.years}>
								{yearsList.map((item, index) => {
									return (
										<div
											key={index}
											className={`${styles.periodItem} ${item === pickedYear ? styles.selected : ""
												}`}
											onClick={() => {
												setPickedYear((prev) => +item);
											}}
										>
											{item}
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>

				<div className={styles.tableContainer}>
					<Table tableHeader={
						<thead className={`${tableStyles.tableHead} ${tableStyles.noPosition}`}>
							<tr className={tableStyles.headerRow}>
								<th className={`${tableStyles.rootCell} ${tableStyles.center} ${tableStyles.pr0} noPrint`}>
									<Checkbox
										className={styles.checkbox}
										icon={
											<div className={styles.checkboxIconContainer}>
												<FiCheck color="#261F63" size={20} />
											</div>
										}
										checked={mainCheckboxState}
										onChange={(checked) => handleCheckAllEmployee()}
									/>
								</th>
								<th className={`${tableStyles.rootCell} noPrint`}>
									{t('salaryCalculation.all')}
								</th>
								<td >
								</td>
								<td className={`${styles.totalCell}`}>
									<div>
										{allMonthlyStatisticsReports?.totals?.brutto}
									</div>
								</td>
								<td className={`${styles.totalCell}`}>
									<div>
										{allMonthlyStatisticsReports?.totals?.employerContribute}
									</div>
								</td>
								<td className={`${styles.totalCell}`}>
									<div>
										{allMonthlyStatisticsReports?.totals?.personalContribute}
									</div>
								</td>
								<td className={`${styles.totalCell}`}>
									<div>
										{allMonthlyStatisticsReports?.totals?.taxableIncome}
									</div>
								</td>
								<td className={`${styles.totalCell}`}>
									<div>
										{allMonthlyStatisticsReports?.totals?.tax}
									</div>
								</td>
								<td className={`${styles.totalCell}`}>
									<div>
										{allMonthlyStatisticsReports?.totals?.extras}
									</div>
								</td>
								<td className={`${styles.totalCell}`}>
									<div>
										{allMonthlyStatisticsReports?.totals?.stops}
									</div>
								</td>
								<td className={`${styles.totalCell}`}>
									<div>
										{allMonthlyStatisticsReports?.totals?.healthInsurance}
									</div>
								</td>
								<td className={`${styles.totalCell}`}>
									<div>
										{allMonthlyStatisticsReports?.totals?.netto}
									</div>
								</td>
							</tr>
							<tr className={tableStyles.headerRow}>
								{
									tableHeaderData.map((cell, index) => {
										const thClasses = classNames({
											"noPrint printPaddingLeft": index === 0,
											[tableStyles.headerCell]: true,
											[tableStyles.rootCell + " " + tableStyles.pr0]: index === 0,
											[tableStyles.rootCell]: index === 1,
										});
										return <th className={thClasses} key={index}>
											{cell}
										</th>
									}
									)
								}
							</tr>
						</thead>
					}>
						<tbody className={styles.tableBody}>
							{allMonthlyStatisticsReports?.data?.length > 0 &&
								allMonthlyStatisticsReports?.data?.map((data, idx) => {
									return (
										<tr className={styles.tableRow} key={idx}>
											<td className={"noPrint"}>
												<div className={styles.checkboxIcon}>
													<Checkbox
														className={styles.checkbox}
														icon={
															<div className={styles.checkboxIconContainer}>
																<FiCheck color="#261F63" size={20} />
															</div>
														}
														checked={selectedCheckboxes?.includes(data.id)}
														onChange={(checked) =>
															handleCheckEmployee(checked, data.id)
														}
													/>
												</div>
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												<div>{data?.employeeName}</div>
												<div className={styles.sectorType}>
												</div>
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{data?.personalNumber}
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{data?.brutto}
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{data?.personalContribute}
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{data?.personalContribute}
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{data?.taxableIncome}
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{data?.tax}
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{data?.extras}
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{data?.stops}
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{data?.healthInsurance}
											</td>
											<td className={`${styles.bodyCell} ${styles.text}`}>
												{data?.netto}
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>
				</div>
			</ModalCustom>


			<ModalCustom
				contentClass={styles.sendEmailWrapper}
				show={isEmailOpen}
				hideFooterButton={false}
				submitLoading={isEmailOpen && isSendEmailLoading || !isEmailOpen}
				submitText={isEmailOpen ? 'Dërgo' : `${t('reports.save')}`}
				footerClass={styles.footerClass}
				onClose={handleOpenModal}
				onSubmit={() => handleEmailClick(tenantId, selectedCheckboxes)}
			>
				{isEmailOpen ? <div className={styles.emailBox} onKeyDown={(e) => {
					const inputCondition = e.key === 'Enter';
				}}>
					<InputCustom
						onChangeHandler={handleEmailChange}
						className={styles.rowInputEmail}
						name="occupation"
						value={email}
						placeholder={'Enter e-mail'}
						attributes={{ autoFocus: true, }}
						type="text"
					/>
					{!isEmailValid && email !== "" ? <span className={styles.validateEmail}>{emailValidMessage}</span> : null}
				</div> : null}
			</ModalCustom>
		</>
	);
};

export default MonthlyStatistics;
