import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import styles from "./Shortcut.module.scss";
import { IconWrapper } from "components/IconWrapper/IconWrapper.component";

interface shortcutTypes {
    children?: React.ReactNode;
    title?: string;
    link?: string;
    toolTip?: string;
    toolTipId?: string;
    className?: string;
    onClickHandler?: any;
}

export const Shortcut: FunctionComponent<shortcutTypes> = (props) => {
    return (
        props.onClickHandler ?
            <div
                className={styles.shortcut}
                onClick={props.onClickHandler}
                data-tip={props.toolTip}
                data-for={props.link}
            >
                <IconWrapper className={`${styles.icon}`}>
                    <div className={props.className}>
                        {props.children}
                    </div>
                    <div className={styles.title}> {props.title} </div>
                </IconWrapper>
                <ReactTooltip
                    id={props.link}
                    place="bottom"
                    type="dark"
                    effect="float"
                    multiline={true}
                    className="customTooltip"
                />
            </div>
            :
            <Link
                className={styles.shortcut}
                to={props.link}
                data-tip={props.toolTip}
                data-for={props.link}
            >
                <IconWrapper className={`${styles.icon}`}>
                    <div className={props.className}>
                        {props.children}
                    </div>
                    <div className={styles.title}> {props.title} </div>
                </IconWrapper>
                <ReactTooltip
                    id={props.link}
                    place="bottom"
                    type="dark"
                    effect="float"
                    multiline={true}
                    className="customTooltip"
                />
            </Link>
    );
}
