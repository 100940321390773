import { useTranslation } from "react-i18next";
import produce from "immer";
import { toast } from "react-toastify";
import { ConfigurationsState } from "types/reducers/configurations.reducer.type";
import * as constants from "./constants";
import * as constant from "../Login/CompanyLogin/constants";

import {
  GET_LAST_BACKUP_ERROR,
  GET_LAST_BACKUP_SUCCESS,
  REQUEST_BACKUP,
  REQUEST_BACKUP_ERROR,
  REQUEST_BACKUP_SUCCESS,
} from "./constants";
import { DRAFT_STATE } from "immer/dist/internal";

const initialTaxDataValues = {
  firstStep: {
    id: null,
    percentage: null,
    firstValue: null,
    secondValue: null,
  },
  secondStep: {
    id: null,
    percentage: null,
    firstValue: null,
    secondValue: null,
  },
  thirdStep: {
    id: null,
    percentage: null,
    firstValue: null,
    secondValue: null,
  },
  fourthStep: {
    id: null,
    percentage: null,
    firstValue: null,
    secondValue: null,
  },
};

const initialAccountDataValues = {
  id: null,
  tenantId: null,
  payroll_account: null,
  quickbooksAccount: null,
  accountName: null,
};

const initialState: ConfigurationsState = {
  showModal: false,
  modalOpener: null,
  modalSubmitLoading: false,
  editMode: false,
  taxesData: initialTaxDataValues,
  isRequestingBackup: false,
  lastBackupDate: undefined,
  tenantId: null,
  projects: null,
  showSubmitButton: true,
  setButtonText: "Ruaj",
  qbLink: null,
  refreshToken: null,
  accountDataInfo: null,
  payrollAccounts: null,
  loading: false,
  hasQuickbooks: null,
  quickbooksLoadingState: false,
};

const configurationsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case constants.SET_GLOBAL_TENANT_ID:
        draft.tenantId = action.payload;
        break;
      case constants.SET_GLOBAL_HAS_QUICKBOOKS:
        draft.hasQuickbooks = action.payload;
        break;
      case constants.SET_MODAL_STATE:
        draft.showModal = action.payload.show;
        draft.modalOpener = action.payload.opener;
        break;
      case constants.SET_SHOW_SUBMIT_BUTTON:
        draft.showSubmitButton = action.payload;
        break;
      case constants.RESET_MODAL_SUBMIT_LOADING:
        draft.modalSubmitLoading = false;
        break;
      case constants.SUBMIT_COMPANY_DATA:
        draft.modalSubmitLoading = true;
        break;
      case constants.SUBMIT_COMPANY_DATA_SUCCESS:
        toast.success("Kompania u shtua me sukses!");
        draft.showModal = false;

        if (localStorage.getItem("checkedQb") === "false") {
          draft.modalSubmitLoading = false;
        } else {
          window.open(action.payload?.data, "_blank");
          draft.qbLink = action.payload.data;
        }
        draft.modalSubmitLoading = false;
        break;
      case constants.SUBMIT_COMPANY_DATA_ERROR:
        toast.error("Diqka shkoi gabim!");
        draft.modalSubmitLoading = false;
        break;
      case constants.SUBMIT_ADMIN_DATA:
        draft.modalSubmitLoading = true;
        break;
      case constants.SUBMIT_ADMIN_DATA_SUCCESS:
        toast.success("Admini u shtua me sukses!");
        draft.modalSubmitLoading = false;
        draft.showModal = false;
        break;
      case constants.SUBMIT_ADMIN_DATA_ERROR:
        draft.modalSubmitLoading = false;
        break;
      case constants.SUBMIT_USER_DATA:
        draft.modalSubmitLoading = true;
        break;
      case constants.SUBMIT_USER_DATA_SUCCESS:
        toast.success("Përdoruesi u shtua me sukses!");
        draft.modalSubmitLoading = false;
        draft.showModal = false;
        break;
      case constants.SUBMIT_USER_DATA_ERROR:
        draft.modalSubmitLoading = false;
        break;

      case constants.SET_BUTTON_TEXT:
        draft.setButtonText = action.payload;
        break;
      case constants.SET_MODAL_EDIT_MODE:
        draft.editMode = action.payload;
        break;
      case constants.SET_MODAL_STATE:
        draft.showModal = action.payload.show;
        draft.modalOpener = action.payload.opener;
        break;
      case constants.SET_MODAL_EDIT_MODE:
        draft.editMode = action.payload;
        break;

      case constants.SUBMIT_COMPANY_DATA:
        draft.modalSubmitLoading = true;
        break;
      case constants.SUBMIT_COMPANY_DATA_SUCCESS:
        toast.success("Kompania u shtua me sukses!");
        draft.showModal = false;
        draft.modalSubmitLoading = false;
        break;
      case constants.SUBMIT_COMPANY_DATA_ERROR:
        toast.error("Diqka shkoi gabim!");
        draft.modalSubmitLoading = false;
        break;
      case constants.SUBMIT_ADMIN_DATA:
        draft.modalSubmitLoading = true;
        break;
      case constants.SUBMIT_ADMIN_DATA_SUCCESS:
        toast.success("Admini u shtua me sukses!");
        draft.modalSubmitLoading = false;
        draft.showModal = false;
        break;
      case constants.SUBMIT_ADMIN_DATA_ERROR:
        draft.modalSubmitLoading = false;
        break;
      case constants.SUBMIT_USER_DATA:
        draft.modalSubmitLoading = true;
        break;

      case constants.GET_HEALTH_INSURANCE:
        break;
      case constants.GET_HEALTH_INSURANCE_SUCCESS:
        draft.tenantHealthInsurance = action.payload?.data;
        break;
      case constants.GET_CONTACT_INFO_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.SUBMIT_USER_DATA_SUCCESS:
        toast.success("Përdoruesi u shtua me sukses!");
        draft.modalSubmitLoading = false;
        draft.showModal = false;
        break;
      case constants.SUBMIT_USER_DATA_ERROR:
        draft.modalSubmitLoading = false;
        break;
      case constants.SEARCH_COMPANIES:
        break;
      case constants.SEARCH_COMPANIES_SUCCESS:
        draft.searchedCompaniesList = action.payload.data;
        break;
      case constants.SEARCH_COMPANIES_ERROR:
        break;

      case constants.SET_SETUP_MODAL_STATE:
        draft.showSetupModal = action.payload.show;
        draft.setupModalOpener = action.payload.opener;
        break;
      case constants.GET_CONTACT_INFO:
        break;
      case constants.GET_CONTACT_INFO_SUCCESS:
        draft.contactInfo = action.payload.data;
        break;
      case constants.GET_CONTACT_INFO_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_ALL_TENANTS:
        break;
      case constants.GET_ALL_TENANTS_SUCCESS:
        draft.allTenants = action.payload.data;
        break;
      case constants.GET_ALL_TENANTS:
        break;
      case constants.GET_ALL_TENANTS_SUCCESS:
        draft.allTenants = action.payload.data;
        break;
      case constants.GET_ALL_TENANTS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.GET_ALL_USERS:
        break;
      case constants.GET_ALL_USERS_SUCCESS:
        draft.allUsers = action.payload.data;
        break;
      case constants.GET_ALL_USERS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.SET_TAXES_DATA:
        draft.taxesData = action.payload;
        break;

      case constants.GET_TAX_INFORMATION:
        break;
      case constants.GET_TAX_INFORMATION_SUCCESS:
        draft.taxesData = JSON.parse(JSON.stringify(initialTaxDataValues));
        draft.taxesData.firstStep = action.payload?.data?.data[0];
        draft.taxesData.secondStep = action.payload?.data?.data[1];
        draft.taxesData.thirdStep = action.payload?.data?.data[2];
        draft.taxesData.fourthStep = action.payload?.data?.data[3];

        break;
      case constants.GET_TAX_INFORMATION_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_CONTRIBUTE:
        break;
      case constants.GET_CONTRIBUTE_SUCCESS:
        draft.contributionData = action.payload?.data?.data[0];
        break;
      case constants.GET_CONTRIBUTE_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.SAVE_TAXES:
        draft.modalSubmitLoading = true;
        break;
      case constants.SAVE_TAXES_SUCCESS:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        draft.setupModalOpener = "";
        toast.success("Të dhënat u ruajtën me sukses!");
        break;
      case constants.SAVE_TAXES_ERROR:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        draft.setupModalOpener = "";
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.SAVE_CONTRIBUTES:
        draft.modalSubmitLoading = true;
        break;
      case constants.SAVE_CONTRIBUTES_SUCCESS:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        draft.setupModalOpener = "";
        toast.success("Të dhënat u ruajtën me sukses!");
        break;
      case constants.SAVE_CONTRIBUTES_ERROR:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        draft.setupModalOpener = "";
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_CONTRIBUTE_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.DELETE_COMPANY:
        break;
      case constants.DELETE_COMPANY_SUCCESS:
        draft.allUsers = action.payload.data;
        toast.success("Kompania u fshi me sukses!");
        break;
      case constants.DELETE_COMPANY_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_ONE_TENANT:
        break;
      case constants.GET_ONE_TENANT_SUCCESS:
        draft.tenant = action.payload.data;
        break;
      case constants.GET_ONE_TENANT_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.DELETE_USER:
        break;
      case constants.DELETE_USER_SUCCESS:
        draft.allUsers = action.payload.data;
        toast.success("Përdoruesi u fshi me sukses!");
        break;
      case constants.DELETE_USER_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.GET_ONE_USER:
        break;
      case constants.GET_ONE_USER_SUCCESS:
        draft.user = action.payload.data;
        break;
      case constants.GET_ONE_USER_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.GET_OVERTIME:
        break;
      case constants.GET_OVERTIME_SUCCESS:
        draft.overtime = action.payload?.data?.data;
        break;
      case constants.GET_OVERTIME_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_ONE_USER_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.SAVE_OVERTIME:
        draft.modalSubmitLoading = true;
        break;
      case constants.SAVE_OVERTIME_SUCCESS:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        draft.setupModalOpener = "";
        toast.success("Të dhënat u ruajtën me sukses!");
        break;
      case constants.SAVE_OVERTIME_ERROR:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        draft.setupModalOpener = "";
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.GET_VACATION:
        break;
      case constants.GET_VACATION_SUCCESS:
        draft.vacations = {
          annualLeaveDays: action.payload?.data?.data?.annualLeaveDays,
          annualLeavePercentage: action.payload?.data?.data?.annualPercentage,
          femaleSpecialLeaveDays:
            action.payload?.data?.data?.femaleSpecialLeaveDays,
          femaleSpecialLeavePercentage:
            action.payload?.data?.data?.femaleSpecialPercentage,
          maleSpecialLeaveDays:
            action.payload?.data?.data?.maleSpecialLeaveDays,
          maleSpecialLeavePercentage:
            action.payload?.data?.data?.maleSpecialPercentage,
          maternityLeaveDays: action.payload?.data?.data?.maternityLeaveDays,
          maternityLeavePercentage:
            action.payload?.data?.data?.maternityPercentage,
          medicalLeaveDays: action.payload?.data?.data?.medicalLeaveDays,
          medicalLeavePercentage: action.payload?.data?.data?.medicalPercentage,
          tenantId: action.payload?.data?.data?.tenantId,
          weekWorkingDays: action.payload?.data?.data?.weekWorkingDays,
        };
        break;
      case constants.GET_VACATION_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.SAVE_VACATION:
        draft.modalSubmitLoading = true;
        break;
      case constants.SAVE_VACATION_SUCCESS:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        draft.setupModalOpener = "";
        toast.success("Të dhënat u ruajtën me sukses!");
        break;
      case constants.SAVE_VACATION_ERROR:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        draft.setupModalOpener = "";
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_TENANT_AUDITS:
        break;
      case constants.GET_TENANT_AUDITS_SUCCESS:
        draft.audits = action.payload.data;
        break;
      case constants.GET_TENANT_AUDITS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_AUTH_AUDITS:
        break;
      case constants.GET_AUTH_AUDITS_SUCCESS:
        draft.audits = action.payload.data;
        break;
      case constants.GET_AUTH_AUDITS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_USER_AUDITS:
        break;
      case constants.GET_USER_AUDITS_SUCCESS:
        draft.audits = action.payload.data;
        break;
      case constants.GET_USER_AUDITS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.UPLOAD_LOGO:
        draft.modalSubmitLoading = true;
        break;
      case constants.UPLOAD_LOGO_SUCCESS:
        toast.success("Logo u ngarkua me sukses!");
        draft.modalSubmitLoading = false;
        draft.showModal = false;
        break;
      case constants.UPLOAD_LOGO_ERROR:
        draft.modalSubmitLoading = false;
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_AUTH_USER:
        break;
      case constants.GET_AUTH_USER_SUCCESS:
        draft.user = action.payload?.data?.data;
        break;
      case constants.GET_AUTH_USER_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.UPDATE_TENANT:
        draft.modalSubmitLoading = true;
        break;
      case constants.UPDATE_TENANT_SUCCESS:
        toast.success("Kompania u përditësua me sukses!");
        if (
          action.payload?.data?.client_secret === null &&
          action.payload?.data?.client_id === null
        ) {
          draft.modalSubmitLoading = false;
        } else {
          window.open(action.payload?.data, "_blank");
          draft.qbLink = action.payload.data;
        }
        draft.modalSubmitLoading = false;
        draft.showModal = false;
        break;
      case constants.UPDATE_TENANT_ERROR:
        draft.modalSubmitLoading = false;
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.UPDATE_USER:
        draft.modalSubmitLoading = true;
        break;
      case constants.UPDATE_USER_SUCCESS:
        toast.success("Admini u përditësua me sukses!");
        draft.modalSubmitLoading = false;
        draft.showModal = false;
        break;
      case constants.UPDATE_USER_ERROR:
        draft.modalSubmitLoading = false;
        toast.error("Diqka shkoi gabim!");
        break;
      case REQUEST_BACKUP:
        draft.isRequestingBackup = true;
        break;
      case REQUEST_BACKUP_SUCCESS:
        draft.isRequestingBackup = false;
        toast.success("Kerkesa per backup u startua me sukses");
        break;
      case REQUEST_BACKUP_ERROR:
        draft.isRequestingBackup = false;
        toast.error("Kerkesa per backup nuk mundi te fillohej");
        break;
      case GET_LAST_BACKUP_SUCCESS:
        draft.lastBackupDate = action.payload.lastBackUpDate;
        break;
      case GET_LAST_BACKUP_ERROR:
        toast.error("Nuk ishte e mundur te shfaqej data e backup te fundit");
        break;
      case constants.GET_TENANT_PROJECTS:
        break;
      case constants.GET_TENANT_PROJECTS_SUCCESS:
        console.log("here 22", action.payload);
        draft.projects = action.payload;
        break;
      case constants.GET_TENANT_PROJECTS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.GET_CODE:
        break;
      case constants.GET_CODE_SUCCESS:
        draft.code = action.payload?.data?.code;
        break;
      case constants.GET_CODE_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.GENERATE_CODE:
        draft.modalSubmitLoading = true;
        break;
      case constants.GENERATE_CODE_SUCCESS:
        toast.success("Kodi u gjenerua me sukses!");
        draft.modalSubmitLoading = false;
        draft.showModal = false;
        break;
      case constants.GENERATE_CODE_ERROR:
        draft.modalSubmitLoading = false;
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.SAVE_PROJECT:
        draft.modalSubmitLoading = true;
        break;
      case constants.SAVE_PROJECT_SUCCESS:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        draft.setupModalOpener = "";
        toast.success("Projekti u krijua me sukses!");
        break;
      case constants.SAVE_PROJECT_ERROR:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        draft.setupModalOpener = "";
        toast.success("Projekti u krijua me sukses!");
        break;

      case constants.GET_TENANT_SECTORS:
        break;
      case constants.GET_TENANT_SECTORS_SUCCESS:
        draft.tenantSectors = action.payload;
        break;
      case constants.GET_TENANT_SECTORS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.CREATE_SECTOR:
        draft.modalSubmitLoading = true;
        break;
      case constants.CREATE_SECTOR_SUCCESS:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        toast.success("Te dhënat u ruajtën me sukses!");
        break;
      case constants.CREATE_SECTOR_ERROR:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.DELETE_SECTOR:
        break;
      case constants.DELETE_SECTOR_SUCCESS:
        draft.tenantSectors = action.payload.data;
        toast.success("Sektori u fshi me sukses!");
        break;
      case constants.DELETE_SECTOR_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.UPDATE_SECTOR:
        draft.modalSubmitLoading = true;
        break;
      case constants.UPDATE_SECTOR_SUCCESS:
        toast.success("Sektori u përditësua me sukses!");
        draft.modalSubmitLoading = false;
        draft.showModal = false;
        break;
      case constants.UPDATE_SECTOR_ERROR:
        draft.modalSubmitLoading = false;
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_ACCOUNTS:
        break;
      case constants.GET_ACCOUNTS_SUCCESS:
        draft.accounts = action.payload;
        break;
      case constants.GET_ACCOUNTS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.GET_ONE_PROJECT:
        break;
      case constants.GET_ONE_PROJECT_SUCCESS:
        draft.project = action.payload.data;
        break;
      case constants.GET_ONE_TENANT_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constant.GET_LOGIN_COMPANIES:
        break;
      case constant.GET_LOGIN_COMPANIES_SUCCESS:
        draft.companyLogin = action.payload.data;
        break;
      case constant.GET_LOGIN_COMPANIES_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.GET_USER_LIST:
        break;
      case constants.GET_USER_LIST_SUCCESS:
        draft.allUsers = action.payload;
        break;
      case constants.GET_USER_LIST_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.ALL_TENANT_USERS:
        break;
      case constants.ALL_TENANT_USERS_SUCCESS:
        draft.allUsers = action.payload;
        break;
      case constants.ALL_TENANT_USERS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.GET_REFRESH_TOKEN:
        break;
      case constants.GET_REFRESH_TOKEN_SUCCESS:
        draft.refreshToken = action.payload.data;
        break;
      case constants.GET_REFRESH_TOKEN_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.GET_ALL_PROJECTS:
        break;
      case constants.GET_ALL_PROJECTS_SUCCESS:
        console.log("here 11", action.payload);
        draft.projects = action.payload;
        break;
      case constants.GET_ALL_PROJECTS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;

      case constants.CHECK_QUICKBOOKS:
        break;
      case constants.CHECK_QUICKBOOKS_SUCCESS:
        toast.success("Kontimi me quickbooks përfundoi me sukses.");
        draft.contactInfo = action.payload.data;
        break;
      case constants.SET_QUICKBOOKS_LOADING_STATE:
        draft.quickbooksLoadingState = action.payload;
        break;
      case constants.CHECK_QUICKBOOKS_ERROR:
        toast.error(action.payload);
        break;

      case constants.DELETE_PROJECT_SUCCESS:
        toast.success("Projekti u fshi me sukses.");
        break;

      case constants.DELETE_PROJECT_ERROR:
        toast.error(action.payload);
        break;

      case constants.GET_TENANT_ACCOUNTS:
        break;
      case constants.GET_TENANT_ACCOUNTS_SUCCESS:
        draft.tenantAccounts = action.payload;
        draft.accountDataInfo = action.payload;
        break;
      case constants.GET_TENANT_ACCOUNTS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.SAVE_ACCOUNTS:
        draft.modalSubmitLoading = true;
        break;
      case constants.SAVE_ACCOUNTS_SUCCESS:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        toast.success("Te dhënat u ruajten me sukses!");
        break;
      case constants.SAVE_ACCOUNTS_ERROR:
        draft.modalSubmitLoading = false;
        draft.showSetupModal = false;
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.DELETE_ACCOUNT:
        break;
      case constants.DELETE_ACCOUNT_SUCCESS:
        toast.success("Konto u fshi me sukses!");
        break;
      case constants.DELETE_ACCOUNT_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.UPDATE_HEALTH_INSURANCE:
        draft.modalSubmitLoading = true;
        break;
      case constants.UPDATE_HEALTH_INSURANCE_SUCCESS:
        toast.success("Sigurimi shëndetësor u përditësua me sukses!");
        draft.modalSubmitLoading = false;
        draft.showModal = false;
        break;
      case constants.UPDATE_HEALTH_INSURANCE_ERROR:
        draft.modalSubmitLoading = false;
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.GET_PAYROLL_ACCOUNTS:
        break;
      case constants.GET_PAYROLL_ACCOUNTS_SUCCESS:
        draft.payrollAccounts = action.payload.data;
        break;
      case constants.GET_PAYROLL_ACCOUNTS_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      case constants.ADD_PROJECT_QB:
        toast.info("Kontimi është duke u procesuar!");
        break;
      case constants.ADD_PROJECT_QB_SUCCESS:
        toast.success("Kontimi është përfundoi me sukses!");
        break;
      case constants.ADD_PROJECT_QB_ERROR:
        break;

      case constants.VERIFY_PASSWORD:
        break;
      case constants.VERIFY_PASSWORD_SUCCESS:
        toast.success("Fjalëkalimi u ndërrua me sukses!");
        break;
      case constants.VERIFY_PASSWORD_ERROR:
        toast.error("Diqka shkoi gabim!");
        break;
      default:
        draft = initialState;
        break;
    }
  });

export default configurationsReducer;
