import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

import styles from "./../SideBar.module.scss";

interface MenuItemProps {
    title?: string;
    link: string;
    styles?: React.CSSProperties;
    children?: React.ReactNode;
    isExact?: boolean;
}

const MenuItem: FunctionComponent<MenuItemProps> = (props) => {
    return (
        <li className={styles.menuItem} >
            {
                props.isExact ?
                    <NavLink
                        exact
                        to={props.link}
                        activeClassName={styles.itemActive}
                    >
                        <div className={styles.menuIcon}>
                            {props.children}
                        </div>
                        <span className={styles.menuTitle}>{props.title}</span>
                    </NavLink>
                    :
                    <NavLink
                        to={props.link}
                        activeClassName={styles.itemActive}
                    >
                        <div className={styles.menuIcon}>
                            {props.children}
                        </div>
                        <span className={styles.menuTitle}>{props.title}</span>
                    </NavLink>
            }
        </li>
    );
}

export default MenuItem;