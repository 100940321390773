import { FunctionComponent } from 'react';
import Select from 'react-select';
import "./CustomSelect.scss";
import MikaSelect from '../BbrosSelect/mikaSelect.component';
import {SelectOption} from '../../types/selectOption.type';

interface Props {
	wideMenu?: boolean;
	isSearchable?: boolean;
	optionsStyle?: any;
	placeholderStyle?: any;
	controlStyle?: any;
	singleValueStyle?: any;
	dropdownIndicatorStyle?: any;
	containerStyle?: any;
	valueContainerStyle?: any;
	menuListStyle?: any;
	multiValueStyle?: any;
	menuStyle?: any;
	selected?: any;
	placeholder?: string;
	attributes?: any;
	inputClassName?: string;
	onChangeHandler?: any;
	components?: any;
	name?: any;
	menuPlacement?: string;
	className?: string;
	options?: any;
	showIndicatorSeparator?: boolean;
	indicatorContainerStyle?: any;
	loading?: boolean;
	hasSearchFeature?: boolean;
}

const CustomSelect: FunctionComponent<Props> = (props, state) => {

	const menuOptionalStyle = props.wideMenu ? {} : { minWidth: "100px", width: "unset" };
	const customStyles = {
		option: (provided, state) => {
			return {
				...provided,
				cursor: "pointer",
				backgroundColor: state.isSelected ? '#EFF5E6' : '',
				"&:hover": {
					backgroundColor: "#EFF5E6"
				},
				color: "#1F1960",
				opacity: ".5",
				whiteSpace: "nowrap",
				height: "40px",
				...props.optionsStyle,
			}
		},
		control: (provided, state) => {
			return {
				width: "100%",
				display: "flex",
				padding: 5,
				cursor: "pointer",
				backgroundColor: "white",
				borderRadius: "30px",
				color: "#1f1960",
				height: "40px",
				...props?.controlStyle,
			}
		},
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			return {
				...provided,
				opacity,
				transition,
				color: "#1f1960",
				...props.singleValueStyle
			};
		},
		placeholder: (provided) => ({
			...provided,
			...props.singleValueStyle,
			color: "#1f1960",
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			display: (props.showIndicatorSeparator ? provided.display : "none"),
		}),
		indicatorsContainer: (provided) => ({
			...provided,
			...props.indicatorContainerStyle,
		}),
		dropdownIndicator: (provided, state) => {
			return {
				...provided,
				width: "30px",
				display: "flex",
				justifyContent: "center",
				color: "#261F63",
				transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
				...props.dropdownIndicatorStyle,
			}
		},
		container: (provided, state) => {
			return {
				...provided,
				...props.containerStyle,
			}
		},
		valueContainer: (provided) => {
			return {
				...provided,
				color: "#1F1960",
				...props.valueContainerStyle,
			}
		},
		multiValue: (provided) => {
			return {
				...provided,
				...props.multiValueStyle,
			}
		},
		menu: (provided, state) => {
			return {
				...provided,
				backgroundColor: "white",
				zIndex: 1042,

				borderRadius: "10px",
				...props.menuStyle,
				boxShadow: state.isFocused ? "none" : "none",
				...menuOptionalStyle,
			}
		},
		menuList: (provided, state) => ({
			...provided,
			paddingTop: 0,
			paddingBottom: 0,
			boxShadow: "0px 2px 3px #0000000D",
			borderRadius: "10px",
			...props.menuListStyle,
		}),

	}

	return (
		<>
			{props?.hasSearchFeature ?
					<MikaSelect
							options={props.options}
							value={props.selected || {} as SelectOption}
							setEntireOptionFunction={props.onChangeHandler}
							placeholder={props.placeholder}>
					</MikaSelect>
					:
				<Select
					placeholder={props.placeholder}
					value={props.selected}
					onChange={props.onChangeHandler}
					options={props.options}
					className={props.className}
					styles={customStyles}
					isSearchable={props.isSearchable || false}
					menuPlacement={props.menuPlacement || "auto"}
					components={props.components}
					name={props.name}
					inputClassName={props.inputClassName}
					{...props.attributes}
			>
				{props.children}
			</Select>}
		</>
	);
}

export default CustomSelect;