const scope = "melitaPayroll/reports";

export const LOAD_MONTHLY_STATISTICS = `${scope}/LOAD_MONTHLY_STATISTICS`;
export const LOAD_MONTHLY_STATISTICS_SUCCESS = `${scope}/LOAD_MONTHLY_STATISTICS_SUCCESS`;
export const LOAD_MONTHLY_STATISTICS_ERROR = `${scope}/LOAD_MONTHLY_STATISTICS_ERROR`;
export const LOAD_EXPORT_EXCEL = `${scope}/LOAD_EXPORT_EXCEL`;
export const LOAD_EXPORT_EXCEL_SUCCESS = `${scope}/LOAD_EXPORT_EXCEL_SUCCESS`;
export const LOAD_EXPORT_EXCEL_ERROR = `${scope}/LOAD_EXPORT_EXCEL_ERROR`;
export const LOAD_EXPORT_PDF = `${scope}/LOAD_EXPORT_PDF`;
export const LOAD_EXPORT_PDF_SUCCESS = `${scope}/LOAD_EXPORT_PDF_SUCCESS`;
export const LOAD_EXPORT_PDF_ERROR = `${scope}/LOAD_EXPORT_PDF_ERROR`;
export const SET_EMPLOYEES_CHECKED = `${scope}/SET_EMPLOYEES_CHECKED`;
export const SEND_EMPLOYEES_EMAIL_MONTHLY = `${scope}/SEND_EMPLOYEES_EMAIL_MONTHLY`;
export const SEND_EMPLOYEES_EMAIL_MONTHLY_SUCCESS = `${scope}/SEND_EMPLOYEES_EMAIL_MONTHLY_SUCCESS`;
export const SEND_EMPLOYEES_EMAIL_MONTHLY_ERROR = `${scope}/SEND_EMPLOYEES_EMAIL_MONTHLY_ERROR`;