import { FunctionComponent, MouseEvent, useEffect } from "react";
import { IconSearch } from "assets/icons/icons";
import styles from "./GlobalSearch.module.scss";
import GlobalSearchContent from "components/GlobalSearch/GlobalSearchContent/GlobalSearchContent.component";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  setInitGlobalSearch,
  setGlobalSearchDone,
  setClearGlobalSearch,
} from "components/NavBar/actions";
import Loader from "components/partials/Loader/Loader.component";
import { getGlobalSearchedData } from "./actions";
import { setBackdropState } from "components/AppWrapper/actions";
import { useState } from "react";
import classNames from "classnames";
import { globalState } from "types/globalState.type";
import { searchData } from "utils/dummyData";
import { GlobalSearchState } from "types/reducers/globalSearch.reducer.type";
import HolidayModalComponent from "components/HolidayModal/HolidayModalComponent.component";
import EmployeeSearchModal from "pages/Employees/EmployeeModal/EmployeeSearchModal.component";
import { toggleModalState as toggleModalStateAppwrapper } from "components/AppWrapper/actions";
import { getEmployeeById, getInitialBrutto, toggleModalState } from "pages/Employees/actions";
import { useTranslation } from "react-i18next";
import EmployeesModal from "pages/Employees/EmployeeModal/EmployeeModal.component";

const GlobalSearch: FunctionComponent = () => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] =
    useState<boolean>(false);
  const [isVacationModalOpen, setIsVacationModalOpen] =
    useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const [vacationId, setVacationId] = useState<number>();
  const [employeeId, setEmployeeId] = useState<number>();

  const tenantId = useSelector(
    (state: globalState) => state.globalReducer?.tenantId
  );

  const navBarReducer = useSelector(
    (state: RootStateOrAny) => state.navBarReducer
  );
  const backdropParent: string = useSelector(
    (state: globalState) => state.appWrapperReducer.backdropParent
  );
  const state: GlobalSearchState = useSelector(
    (state: globalState) => state?.globalSearchReducer
  );
  const searchedData = state?.globalData;

  const globalSearchDone = navBarReducer.globalSearchDone;
  const searchLoading = navBarReducer.searchLoading;
  const clearSearch = navBarReducer.clearSearch;

  const searchDivClickHandler = (event: MouseEvent) => {
    event.currentTarget.querySelector("input")?.focus();
  };

  const backDrop_callback = () => {
    dispatch(setGlobalSearchDone(false));
    dispatch(setClearGlobalSearch(true));
    setSearchInputValue("");
  };

  const searchInputChangeHandler = (event) => {
    event.preventDefault();
    dispatch(setClearGlobalSearch(false));
    setSearchInputValue(event.target.value);
    dispatch(setInitGlobalSearch(true));
    dispatch(
      setBackdropState({
        showBackdrop: true,
        backDrop_callback,
        backdropParent: "globalSearch",
      })
    );

    if (!event.target.value) {
      dispatch(setClearGlobalSearch(true));
    }

    const payload = {
      searchTerm: event.target.value,
      tenantId,
    };
    setTimeout(() => {
      dispatch(setGlobalSearchDone(true));
      dispatch(getGlobalSearchedData(payload));
    }, 1500);
  };


  const vacationIdHandler = (vacationId) => {
    setVacationId(vacationId);
    setIsVacationModalOpen(true);
  };

  const searchClassNames = classNames({
    [styles.search]: true,
    [styles.isOpen]: globalSearchDone,
    lowZindex: backdropParent !== "globalSearch",
  });

  const setShowEmployeeModal = (state: boolean, row: any = { id: 0 }) => {
    dispatch(toggleModalState({ state, row }));
    dispatch(toggleModalStateAppwrapper({ state, row }));
  };

  const employeeRowClickHandler = (row) => {
		setShowEmployeeModal(true, row);
		dispatch(getEmployeeById(tenantId, row.id));
		dispatch(getInitialBrutto(row.id))
	}

  return (
    <div className={searchClassNames} onClick={searchDivClickHandler}>
      <div className={styles.loaderIcon}>
        {searchLoading ? (
          <Loader />
        ) : (
          <IconSearch
            class={`${styles.icon} pointer`}
            invert={globalSearchDone}
          />
        )}
      </div>

      <input
        type="search"
        name="globalSearch"
        id="globalSearch"
        placeholder={t("employees.search")}
        onChange={searchInputChangeHandler}
        value={clearSearch ? "" : searchInputValue}
        autoComplete="off"
      />

      {globalSearchDone && (
        <GlobalSearchContent>
          <div className={styles.searchRow}>
            {searchedData?.employees?.length > 0 ? <div>Punonjësit</div> : null}
            {!clearSearch ? (
              searchedData?.employees.map((row) => {
                return (
                  <div
                    className={styles.row}
                    key={row.id}
                    onClick={() => {
                      employeeRowClickHandler(row)
                    }}
                  >
                    {row.name}
                  </div>
                );
              })
            ) : (
              <div>Type to search...</div>
            )}
          </div>

          <div className={styles.searchRow}>
            {searchedData?.vacations?.length > 0 ? <div>Pushimet</div> : null}
            {!clearSearch ? (
              searchedData?.vacations.map((row) => {
                return (
                  <div
                    className={styles.row}
                    key={row.id}
                    onClick={() => vacationIdHandler(row?.id)}
                  >
                    {row.name}
                  </div>
                );
              })
            ) : (
              <div>Type to search...</div>
            )}
          </div>
        </GlobalSearchContent>
      )}

      {isVacationModalOpen ? (
        <HolidayModalComponent employeeId={vacationId} />
      ) : null}

      {isEmployeeModalOpen ? (
        <EmployeesModal
          setShowEmployeeModal={setShowEmployeeModal}
          showEmployeeModal={isEmployeeModalOpen}
        />
      ) : null}
    </div>
  );
};

export default GlobalSearch;
