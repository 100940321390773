import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import App from "App/App";
import { store, persistor } from "store/store";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import commonEn from 'translations/en/common.json';
import commonAl from 'translations/al/common.json';
import commonDe from 'translations/de/common.json';
import { InactivityLogoutProvider } from "providers/inactivity-logout-provider";

i18next.init({
	interpolation: { escapeValue: false },
	lng: 'al',
	resources: {
		en: {
			common: commonEn
		},
		de: {
			common: commonDe
		},
		al: {
			common: commonAl
		}
	},
});


ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<I18nextProvider i18n={i18next}>
					<InactivityLogoutProvider>
						<App />
					</InactivityLogoutProvider>
				</I18nextProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);
