import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { toast } from 'react-toastify';
import { globalReducerState } from 'types/reducers/global.reducer.type';
import {
	LOG_IN, LOG_IN_SUCCESS, LOG_IN_ERROR, LOG_IN_FAIL, LOG_IN_INIT, LOG_OUT, RESET_FAIL_MESSAGE,
	SET_GLOBAL_TENANT_ID, SET_EMAIL, GET_USER_BY_EMAIL, GET_USER_BY_EMAIL_SUCCESS, GET_USER_BY_EMAIL_ERROR,
	SET_IS_LOGGED_IN, SET_TOKEN,
	GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_ERROR, SET_LOGO
} from "./constants";
import { SEND_FORGOT_PASSWORD_EMAIL, SEND_FORGOT_PASSWORD_EMAIL_ERROR, SEND_FORGOT_PASSWORD_EMAIL_SUCCESS } from 'pages/Configurations/constants';

const initialState: globalReducerState = {
	loading: false,
	isLoggedIn: false,
	user: null,
	token: "",
	loginFail: false,
	forgotPasswordPayload: null,
	notifications: undefined,
	roleId: null,
	tenantId: null,
	agreed: null,
	email: null,
	logo: '',
	hasQuickbooks: null,
}

const globalReducer = (state = initialState, action) =>

	produce(state, draft => {
		switch (action.type) {
			case LOG_IN:
				draft.loading = true;
				draft.loginFail = false;
				draft.email = action.payload.email;
				break;
			case LOG_IN_INIT:
				draft.isLoggedIn = false;
				break;
			case LOG_IN_SUCCESS:
				if (action.payload.user?.roleId === 1) {
					localStorage.setItem('token', action.payload.token);
					draft.tenantId = action.payload.user?.id;
					localStorage.setItem('isLoggedIn', action.payload.user?.success);
					draft.user = action.payload.user;
					draft.roleId = action.payload.user?.roleId;
					draft.hasQuickbooks = action.payload.user?.hasQuickbooks;
					draft.loginFail = false;
					draft.loading = false;
					draft.isLoggedIn = true;
				}

				if (action.payload.user?.roleId === 2) {
					draft.user = action.payload.user;
					draft.tenantId = action.payload.user?.id;
					localStorage.setItem('isLoggedIn', action.payload.user?.success);
					draft.roleId = action.payload.user?.roleId;
					draft.hasQuickbooks = action.payload.user?.hasQuickbooks;
					draft.loginFail = false;
					draft.loading = false;
					draft.isLoggedIn = true;
					draft.token = action.payload.token;
					if (action.payload.user?.agreed !== null) {
						localStorage.setItem('token', action.payload.token);
					}
				}

				if (action.payload.user?.roleId === 3 || action.payload.user?.roleId === 4) {
					draft.token = action.payload.token;
					draft.user = action.payload.user;
					draft.roleId = action.payload.user?.roleId;
					draft.hasQuickbooks = action.payload.user?.hasQuickbooks;
					draft.loginFail = false;
					draft.loading = false;
					draft.isLoggedIn = true;
				}
				setTimeout(() => {
					toast.success('Jeni kyçur me sukses!');
				}, 200);
				break;
			case LOG_IN_ERROR:
				draft.loginFail = true;
				toast.error('Diçka shkoi gabim!');
				draft.isLoggedIn = false;
				draft.loading = false;
				break;
			case LOG_IN_FAIL:
				draft.loginFail = true;
				draft.isLoggedIn = false;
				draft.loading = false;
				localStorage.removeItem('token');
				break;
			case SET_GLOBAL_TENANT_ID:
				draft.tenantId = action.payload;
				break;
			case SET_LOGO:
				draft.logo = action.payload;
				break;
			case SET_EMAIL:
				draft.email = action.payload;
				break;
			case SET_TOKEN:
				draft.token = action.payload;
				break;
			case LOG_OUT:
				draft.logo = '';
				draft.isLoggedIn = false;
				draft.loginFail = false;
				draft.loading = false;
				localStorage.removeItem('token');
				draft = initialState;
				break;
			case RESET_FAIL_MESSAGE:
				draft.user = undefined;
				draft.loading = false;
				draft.loginFail = false;
				break;
			case GET_USER_BY_EMAIL:
				draft.loading = true;
				break;
			case GET_USER_BY_EMAIL_SUCCESS:
				draft.loading = false;
				draft.user = action.payload
				draft.roleId = action.payload?.roleId;
				draft.tenantId = action.payload?.id;
				break;
			case GET_USER_BY_EMAIL_ERROR:
				draft.loading = false;
				toast.error('Diqka shkoi gabim!');
				break;
			case SET_IS_LOGGED_IN:
				draft.isLoggedIn = action.payload;
				break;

			case GET_NOTIFICATIONS:
				draft.notifications = undefined;
				break;
			case GET_NOTIFICATIONS_SUCCESS:
				draft.notifications = action.payload.notifications;
				break;
			case GET_NOTIFICATIONS_ERROR:
				toast.error('Notifications deshtuan te lodohen!');
				break;
			case SEND_FORGOT_PASSWORD_EMAIL:
				draft.loading = true;
				break;
			case SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
				draft.loading = false;
				toast.success('Email për keni harruar fjalëkalimin është dërguar.');
				break;
			case SEND_FORGOT_PASSWORD_EMAIL_ERROR:
				draft.loading = false;
				toast.error('Diqka shkoi gabim!');
				break;
			default:
				break;
		}
	});

export default globalReducer;
