import {
  LOAD_ORDER_PAYMENT,
  LOAD_ORDER_PAYMENT_EXPORT_EXCEL,
  LOAD_ORDER_PAYMENT_EXPORT_PDF,
  SEND_EMAIL_PAYMENT_ORDER,
} from "./constants";
import { call, put, takeLatest } from "redux-saga/effects";
import * as reportService from "services/report.service";
import {
  loadAllPaymentOrdersSuccess,
  loadAllPaymentOrdersError,
  loadPaymentOrderExportSuccess,
  loadPaymentOrderExportError,
  loadPaymentOrderPdfSuccess,
  loadPaymentOrderPdfError,
  sendPaymentOrderToEmailSuccess,
  sendPaymentOrderToEmailError,
} from "./actions";

export function* loadAllOrderPayments(action) {
  try {
    const response = yield call(reportService.getOrderPayments, action.payload);
    yield put(loadAllPaymentOrdersSuccess(response));
  } catch (e) {
    yield put(loadAllPaymentOrdersError());
  }
}

export function* loadOrderPaymentExport(action) {
  try {
    const { tenantId, employerType, bank, sectorId, month, year } =
      action.payload;
    const response = yield call(
      reportService.exportPaymentOrderExcel,
      tenantId,
      employerType,
      bank,
      sectorId,
      month,
      year
    );
    yield put(loadPaymentOrderExportSuccess(response));
  } catch (e) {
    yield put(loadPaymentOrderExportError());
  }
}

export function* loadOrderPaymentPdf(action) {
  try {
    const { tenantId, employerType, bank, month, year, sectorId } =
      action.payload;
    const response = yield call(
      reportService.exportPaymentOrderPdf,
      tenantId,
      employerType,
      bank,
      month,
      year,
      sectorId
    );
    yield put(loadPaymentOrderPdfSuccess(response));
  } catch (e) {
    yield put(loadPaymentOrderPdfError());
  }
}

export function* sendPaymentOrderToEmail(action) {
  try {
    const response = yield call(
      reportService.sendPaymentOrderToEmail,
      action.payload.tenantId,
      action.payload.month,
      action.payload.year,
      action.payload.employeeIds,
      action.payload.email
    );

    yield put(sendPaymentOrderToEmailSuccess(response));
  } catch (e) {
    yield put(sendPaymentOrderToEmailError(e));
  }
}

export default function* paymentOrderSaga() {
  yield takeLatest(LOAD_ORDER_PAYMENT, loadAllOrderPayments);
  yield takeLatest(LOAD_ORDER_PAYMENT_EXPORT_EXCEL, loadOrderPaymentExport);
  yield takeLatest(LOAD_ORDER_PAYMENT_EXPORT_PDF, loadOrderPaymentPdf);
  yield takeLatest(SEND_EMAIL_PAYMENT_ORDER, sendPaymentOrderToEmail);
}
