import { useState } from "react";
import { useEffect } from "react";

import Card from "components/Card/Card.component";
import CustomSelect from "components/CustomSelect/CustomSelect.component";
import InputField from "components/InputField/InputField.component";

import styles from "./CalculationsCard.module.scss";
import NumberCard from "components/NumberCard/NumberCard.component";

import { employerTypeOptions, inputOnFocusSelect } from "utils/globalVariables";
import { calculateSalary } from "utils/brutoNeto";
import { formatNumber_, number_format } from "utils/functions";
import { useTranslation } from "react-i18next";
import { CalculateSalary } from "services/salaries.service";

interface EmployerType {
	value: number;
	label: string;
}

const CalculationsCard = (props) => {
	const { t } = useTranslation("common");
	const [inputBruto, setInputBruto] = useState("");
	const [inputNeto, setInputNeto] = useState("");
	const [employerType, setEmployerType] = useState<EmployerType>(
		employerTypeOptions[0]
	);
	const [brutoResult, setBrutoResult] = useState("0.00");
	const [netoResult, setNetoResult] = useState("0.00");
	const [contributionResult, setContributionResult] = useState("0.00");
	const [taxResult, setTaxResult] = useState("0.00");

	const setCalculationResult = (calculatedSalary) => {
		setBrutoResult((prev) => number_format(calculatedSalary.bruto, 2));
		setNetoResult((prev) => number_format(calculatedSalary.neto, 2));
		setContributionResult((prev) =>
			number_format(calculatedSalary.contribution, 2)
		);
		setTaxResult((prev) => number_format(calculatedSalary.tax, 2));
	};
	const inputBrutoOnChange = async (event) => {
		let brutoValue = event.target.value.replace(",", ".");
		setInputBruto(brutoValue);
		let calculatedSalary = await calculateSalary({
			gross: +(brutoValue ?? 0),
			contributionPercentage: 5,
			employerTier: employerType.value as any,
			noTaxes: false,
		});

		setInputNeto((prev) => number_format(calculatedSalary.neto, 2));
		setCalculationResult(calculatedSalary);
	};

	const inputNetoOnChange = async (event) => {
		let netoValue = event.target.value.replace(",", ".");
		setInputNeto(netoValue);

		let calculatedSalary = await calculateSalary({
			net: +(netoValue ?? 0),
			contributionPercentage: 5,
			employerTier: employerType.value as any,
			noTaxes: false,
		});


		setInputBruto(number_format(calculatedSalary.bruto, 2));
		setCalculationResult(calculatedSalary);
	};

	const onKeydownHandler = (event) => {
		if (
			(event.target.value.includes(".") &&
				(event.key === "." || event.key === ",")) ||
			(!/^[0-9.,\b]+$/.test(event.key) &&
				event.key !== "Backspace" &&
				event.key !== "Tab" &&
				event.key !== "ArrowLeft" &&
				event.key !== "ArrowRight" &&
				!(
					(event.code === "KeyA" ||
						event.code === "KeyC" ||
						event.code === "KeyX") &&
					(event.CtrlKey || event.metaKey)
				))
		) {
			event.preventDefault();
			return false;
		}
	};

	useEffect(() => {
		const loadCalculatedSalary = async () => {
			const calculatedSalary = await calculateSalary({
				gross: +(inputBruto ?? 0),
				contributionPercentage: 5,
				employerTier: employerType.value as any,
				noTaxes: false,
			});
			setInputNeto((prev) => number_format(calculatedSalary.neto, 2));
			setCalculationResult(calculatedSalary);
		}

		loadCalculatedSalary();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employerType]);

	useEffect(() => {
		setInputBruto("");
		setInputNeto("");
	}, []);
	return (
		<Card
			className={styles.calculationsCard}
			titleClass={styles.titleClass}
			cardContentClass={styles.calculationsCardContent}
		>
			<div className={styles.titleClass}>{t("mainPage.salaryCalculator")}</div>
			<div className={styles.leftWrapper}>
				<div className={styles.selectWrapper}>
					<div className={styles.title}> {t("mainPage.employeer")} </div>
					<CustomSelect
						options={employerTypeOptions}
						className={styles.employerTypeSelect}
						onChangeHandler={(value) => setEmployerType((prevValue) => value)}
						selected={employerType}
						controlStyle={{ minWidth: "200px", borderRadius: "23px" }}
						menuStyle={{ borderRadius: "5px", top: "35px" }}
						menuListStyle={{ boxShadow: "0px 2px 3px #0000000D" }}
						wideMenu={true}
						optionsStyle={{
							height: "50px",
							display: "flex",
							alignItems: "center",
							fontSize: "15px",
							opacity: "1",
						}}
					/>
				</div>

				<InputField
					value={inputBruto}
					placeholder={t("mainPage.brutoSalary")}
					label="Paga bruto"
					onFocusHandler={inputOnFocusSelect}
					onChangeHandler={inputBrutoOnChange}
					onKeydownHandler={onKeydownHandler}
					labelColor="#89c732"
					textColor="#1f1960"
					id="inputBruto"
					className={styles.numberInput}
					attributes={{
						min: 0,
						autoComplete: "off",
					}}
				/>
				<InputField
					value={inputNeto}
					placeholder={t("mainPage.netoSalary")}
					label="Paga neto"
					onFocusHandler={inputOnFocusSelect}
					onChangeHandler={inputNetoOnChange}
					onKeydownHandler={onKeydownHandler}
					labelColor="#89c732"
					textColor="#1f1960"
					id="inputNeto"
					className={styles.numberInput}
					attributes={{
						min: 0,
						autoComplete: "off",
					}}
				/>
			</div>
			<div className={styles.rightWrapper}>
				<Card
					className={styles.card}
					title={t("mainPage.calculationDetails")}
					titleClass={styles.title}
					cardContentClass={styles.cardContentClass}
				>
					<div className={styles.swHelper}>
						<div className={`${styles.subWrapper} ${styles.borderRight}`}>
							<NumberCard
								title={t("mainPage.totalBruto")}
								number={`${brutoResult} €`}
								numberClass={styles.numberCardNumber}
								titleClass={styles.numberCardTitle}
								cardClass={`${styles.numberCard}`}
							/>
						</div>
					</div>
					<div className={styles.swHelper}>
						<div className={`${styles.subWrapper}`}>
							<NumberCard
								title={t("mainPage.contribution")}
								number={`${contributionResult} €`}
								numberClass={styles.numberCardNumber}
								titleClass={`${styles.numberCardTitle} ${styles.redColor}`}
								cardClass={styles.numberCard}
							/>
						</div>
					</div>
					<div className={styles.swHelper}>
						<div className={`${styles.subWrapper} ${styles.borderRight}`}>
							<NumberCard
								title={t("mainPage.totalTaxes")}
								number={`${taxResult} €`}
								numberClass={styles.numberCardNumber}
								titleClass={`${styles.numberCardTitle} ${styles.redColor}`}
								cardClass={`${styles.numberCard}`}
							/>
						</div>
					</div>
					<div className={styles.swHelper}>
						<div className={`${styles.subWrapper}`}>
							<NumberCard
								title={t("mainPage.totalNeto")}
								number={`${netoResult} €`}
								numberClass={styles.numberCardNumber}
								titleClass={`${styles.numberCardTitle}`}
								cardClass={styles.numberCard}
							/>
						</div>
					</div>
				</Card>
			</div>
		</Card>
	);
};

export default CalculationsCard;
