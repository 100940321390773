import { FunctionComponent } from 'react';

import styles from "./AppWrapper.module.scss";
import NavBar from 'components/NavBar/NavBar.component';
import SideBar from 'components/SideBar/SideBar.component';
import Backdrop from 'components/Backdrop/Backdrop.component';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { globalState } from 'types/globalState.type';
import classNames from 'classnames';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
    appContentClass?: string;
    children: React.ReactNode;
}

const AppWrapper: FunctionComponent<Props> = (props) => {
    const state: globalState = useSelector(state => state);
    const hideOverflow: boolean = state.appWrapperReducer.showEmployeeModal || state.calculationsReducer.showPayslip;

    const appContentStyle = classNames({
        [styles.appContent]: true,
        [styles.hideOverflow]: hideOverflow,
        [props.appContentClass]: props.appContentClass,
    });
    const mainContainerStyles = classNames({
        [styles.main]: true,
        [styles.collapsed]: state.appWrapperReducer.sidebarMenuCollapsed,
        [styles.open]: !state.appWrapperReducer.sidebarMenuCollapsed,
    });

    return <div className={styles.appWrapper}>
        <SideBar />
        <div className={mainContainerStyles}>
            <NavBar />
            <div id="appContent" className={appContentStyle} >
                {props.children}
            </div>
        </div>
        <Backdrop />
        <ToastContainer position="bottom-left"
        />
       
    </div>
        ;
}

export default AppWrapper;