import { axiosInstance, axiosInstanceV2 } from "api";
import axios from "axios";

export const employeeSalaries = (payload) => {
  const month: number = payload.month;
  const year: number = payload.year;
  const tenantId: number = payload.tenantId;
  return axiosInstance().post(
    `/salaries/createSalaries?month=${month}&year=${year}&tenantId=${tenantId}`
  );
};

export const getTotals = (payload) => {
  const month: number = payload.month;
  const year: number = payload.year;
  const tenantId: number = payload.tenantId;
  return axiosInstance().get(
    `/salaries/totals?month=${month}&year=${year}&userId=${tenantId}`
  );
};

export const getGeneratedSalaries = (payload) => {
  return axiosInstanceV2().get(
    `/salaries/generate?month=${payload.month}&year=${payload.year}&userId=${payload.tenantId}`
  );
};

export interface HealthInsuranceSalaryCalculationSetup {
  hasHealthInsurance: boolean;
  healthInsurancePercentage: number;
  familyMembers?: FamilyMembersHealthInsuranceSalaryCalculationSetup;
}

export interface FamilyMembersHealthInsuranceSalaryCalculationSetup {
  adultsChildren: number;
  children: number;
  parent: number;
  partners: number;
}

export interface CalculateSalary {
  gross?: number;
  net?: number;
  contributionPercentage: number;
  employerTier: 1 | 2 | number;
  noTaxes?: boolean;
  userId?: number;
  healthInsurance?: HealthInsuranceSalaryCalculationSetup;
}

export const calculateSalary = (data: CalculateSalary) => {
  return axiosInstanceV2().post(`/salaries/calculate`, {
    calculateSalary: data,
  });
};

export const unsyncedEmployeesWithQuickbooks = (payload) => {
  const month: number = payload.month;
  const year: number = payload.year;
  const tenantId: number = payload.tenantId;
  return axiosInstance().post(
    `/salaries/unsynced-employees-with-qb?month=${month}&year=${year}&tenantId=${tenantId}`
  );
};

export const updateSalary = (payload) => {
  return axiosInstance().post(
    `/salaries/updateSalary/${payload.salaryId}`,
    payload.data
  );
};

export const updateSalaryV2 = (payload) => {
  return axiosInstanceV2().post(`/salaries/update`, payload);
};

export const exportEcxel = (tenantId: number, month, year) => {
  return axiosInstance().post(
    `/salaries/export?tenantId=${tenantId}&month=${month}&year=${year}`
  );
};

export const generatePaymentSlip = (salaryId) => {
  return axiosInstance().get(`/salaries/generatePaymentSlip/${salaryId}`);
};

export const salariesTillNow = (tenantId: number) => {
  return axiosInstance().get(`/salaries/salariesTillNow?tenantId=${tenantId}`);
};

export const averageSalary = (tenantId: number) => {
  return axiosInstance().get(`/salaries/averageSalary?tenantId=${tenantId}`);
};

export const sendPayslipToEmail = (data) => {
  return axiosInstance().post(`/salaries/sendPayslipToEmail`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const sendContractToEmail = (data) => {
  return axiosInstance().post(`/salaries/sendContractToEmail`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getSalaryConfigurations = (tenantId: number) => {
  return axiosInstance().post(`/general/generalValues/${tenantId}`);
};

export const closeMonthSalary = (payload) => {
  return axiosInstance().post(`/updateClosedSalaries`, payload);
};

export const openMonthSalary = (payload) => {
  return axiosInstance().post(`/salaries/openPeriodByCode`, payload);
};

export const hasSalaries = (userId: number, month: number, year: number) => {
  return axiosInstanceV2().post("/salaries/has", { userId, month, year });
};
