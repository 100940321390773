import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styles from "./NumberCard.module.scss";

interface Props {
    title: string;
    number: number | string;
    titleStyle?: object;
    numberStyle?: object;
    cardClass?: string;
    titleClass?: string;
    titleId?: string;
    numberClass?: string;
    titleLink?: string;
    styles?: any;
}

const NumberCard: FunctionComponent<Props> = (props) => {
    return <div className={`${styles.numberCard} ${props.cardClass || ""}`} style={{ ...props.styles } || ""}>
        <div className={`${styles.title} ${props.titleClass || ""}`} id={props.titleId}>
            {
                props.titleLink
                    ?
                    <Link to={props.titleLink}> {props.title} </Link>
                    :
                    props.title
            }
        </div>
        <div className={`${styles.number} ${props.numberClass || ""}`}>
            {props.number}
        </div>
    </div>;
}

export default NumberCard;