import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { toast, ToastContainer } from 'react-toastify';
import { FiMail, FiLock, FiCheck } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import InputCustom from "components/InputCustom/InputCustom.component";
import Card from "components/Card/Card.component";
import Button from 'components/Button/Button.component';
import { globalState } from 'types/globalState.type';
import styles from "./ChangePassword.module.scss";
import { logIn, resetFailMessage } from 'App/actions';
import { useSelector } from 'react-redux';
import * as actions from '../../Configurations/actions';
import { AiOutlineEye } from "react-icons/ai";

const ChangePasswordForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [passwordShown, setPasswordShown] = useState(false);

	const state = useSelector((state: globalState) => state);
	const globalState = useSelector((state: globalState) => state.globalReducer);
	const tenantId = useSelector((state: globalState) => state.globalReducer?.user?.id);

	const emailInputRef = useRef(null);
	const inputPasswordRef = useRef(null);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const handleSaveNewPassword = () => {
		if (newPassword != confirmNewPassword) {
			toast.error('Fjalekalimet nuk perputhen');
			return;
		}

		if (newPassword.length < 6) {
			toast.error('Fjalekalimi duhet te jete me i gjate se 6 karaktere');
			return;
		}

		const hasNumber = /\d/.test(newPassword);
		const hasUpper = /[A-Z]/.test(newPassword);
		const hasAlpha = /[a-z]/.test(newPassword);

		if (!hasAlpha) {
			toast.error('Fjalekalimi duhet te permbaje te pakten nje shkronje te vogel');
			return;
		}

		if (!hasNumber) {
			toast.error('Fjalekalimi duhet te permbaje te pakten nje numer');
			return;
		}

		if (!hasUpper) {
			toast.error('Fjalekalimi duhet te permbaje te pakten nje shkronje te madhe');
			return;
		}

		const payload = {
			tenantId: tenantId,
			currentPassword: oldPassword,
			newPassword: newPassword,
			confirmPassword: confirmNewPassword,
		}

		dispatch(actions.changePassword(payload))
	}

	return (
		<form
			className={styles.form}
			id="loginForm"
		>
			<Card
				className={styles.card}
				cardContentClass={styles.cardContentClass}
			>
				<div className={styles.header}>
					<h4>Ndrysho fjalëkalimin</h4>
				</div>
				<InputCustom
					placeholder="Fjalëkalimi aktual"
					className={styles.loginInput}
					value={oldPassword}
					type={passwordShown ? "text" : "password"}
					onChangeHandler={(e) => setOldPassword(e.target.value)}
					ref={emailInputRef}
					iconStyle={styles.inputIcon}
					attributes={{
						id: "emailInput"
					}}
				>
					<FiLock />
					<div className={styles.eyeSvg}>
						<AiOutlineEye onClick={togglePassword}></AiOutlineEye>
					</div>
				</InputCustom>
				<InputCustom
					placeholder="Fjalëkalimi i ri"
					className={styles.loginInput}
					value={newPassword}
					type={passwordShown ? "text" : "password"}
					onChangeHandler={(e) => setNewPassword(e.target.value)}
					ref={emailInputRef}
					iconStyle={styles.inputIcon}
					attributes={{
						id: "emailInput"
					}}
				>
					<FiLock />
					<div className={styles.eyeSvg}>
						<AiOutlineEye onClick={togglePassword}></AiOutlineEye>
					</div>
				</InputCustom>
				<InputCustom
					placeholder="Konfirmo fjalëkalimin"
					type={passwordShown ? "text" : "password"}
					className={styles.loginInput}
					value={confirmNewPassword}
					onChangeHandler={(e) => setConfirmNewPassword(e.target.value)}
					ref={inputPasswordRef}
					iconStyle={styles.inputIcon}
				>
					<FiLock />
					<div className={styles.eyeSvg}>
						<AiOutlineEye onClick={togglePassword}></AiOutlineEye>
					</div>
				</InputCustom>
				<div className={`row spaceBetween`}>
				</div>
				<Button
					onClickHandler={handleSaveNewPassword}
					className={styles.signInButton}
					fixDimensions={true}
				>
					Save
				</Button>
			</Card>
			<ToastContainer position="bottom-right" />
		</form>
	);
};

const mapDispatchToProps = (dispatch: any): any => {
	return bindActionCreators<any, any>({
		logIn
	}, dispatch);
}

const mapStateToProps = (state) => {
	return {
		loginFail: state.globalReducer.loginFail,
		loading: state.globalReducer.loading,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(
	withConnect)(ChangePasswordForm)

