const scope = "melitaPayroll/dashboard";

export const LOAD_DASHBOARD_CARDS_DATA = `${scope}/LOAD_DASHBOARD_CARDS_DATA`;
export const LOAD_DASHBOARD_CARDS_DATA_SUCCESS = `${scope}/LOAD_DASHBOARD_CARDS_DATA_SUCCESS`;
export const LOAD_DASHBOARD_CARDS_DATA_ERROR = `${scope}/LOAD_DASHBOARD_CARDS_DATA_ERROR`;

export const LOAD_UPCOMING_BIRTHDAYS = `${scope}/LOAD_UPCOMING_BIRTHDAYS`;
export const LOAD_UPCOMING_BIRTHDAYS_SUCCESS = `${scope}/LOAD_UPCOMING_BIRTHDAYS_SUCCESS`;
export const LOAD_UPCOMING_BIRTHDAYS_ERROR = `${scope}/LOAD_UPCOMING_BIRTHDAYS_ERROR`;

export const LOAD_UPCOMING_VACATIONS = `${scope}/LOAD_UPCOMING_VACATIONS`;
export const LOAD_UPCOMING_VACATIONS_SUCCESS = `${scope}/LOAD_UPCOMING_VACATIONS_SUCCESS`;
export const LOAD_UPCOMING_VACATIONS_ERROR = `${scope}/LOAD_UPCOMING_VACATIONS_ERROR`;

export const LOAD_EXPIRED_CONTRACTS = `${scope}/LOAD_EXPIRED_CONTRACTS`;
export const LOAD_EXPIRED_CONTRACTS_SUCCESS = `${scope}/LOAD_EXPIRED_CONTRACTS_SUCCESS`;
export const LOAD_EXPIRED_CONTRACTS_ERROR = `${scope}/LOAD_EXPIRED_CONTRACTS_ERROR`;