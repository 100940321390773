import {axiosInstance} from '../api';


export const startRequestBackup = () => {
  return axiosInstance().get(`backup/backupDatabase`);
}

export const getBackup = () => {
  return axiosInstance().get(`backup/getBackup`);
}
