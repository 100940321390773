import { FunctionComponent } from 'react';

import styles from "./GlobalSearchContent.module.scss";

const GlobalSearchContent: FunctionComponent = (props) => {
    return <div className={styles.globalSearchContent}>
        {props.children}
    </div>

}

export default GlobalSearchContent;
