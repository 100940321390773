import { call, put, takeLatest } from 'redux-saga/effects';
import * as services from 'services/globalSearch.service';
import * as actions from './actions';
import * as constants from './constants';


export function* getGlobalSearchedData(action) {
	try {
		const response = yield call(services.getGlobalSearchedData, action.payload.searchTerm, action.payload.tenantId);
		yield put(actions.getGlobalSearchedDataSuccess(response?.data?.data?.[0]));
	} catch (error) {
		yield put(actions.getGlobalSearchedDataError(error));
	}
}

export default function* globalSearchSaga() {
	yield takeLatest(constants.GET_GLOBAL_SEARCHED_DATA, getGlobalSearchedData);
}