import Table from 'components/Table/Table.component';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Checkbox from "react-custom-checkbox";
import _ from 'lodash';

import { globalState } from 'types/globalState.type';
import { FiCheck } from 'react-icons/fi';
import tableStyles from 'components/Table/Table.module.scss';
import styles from './EmployeeReports.module.scss';
import calculationStyles from "pages/Calculations/Calculations.module.scss";

import checkboxStyle from "styles/customCheckbox.module.scss";


import { EmployeeReportsState } from 'types/reducers/reports.reducer.type';
import LoaderCentered from 'components/partials/LoaderCentered/LoaderCentered.component';
import { useTranslation } from 'react-i18next';


interface Props {
	showModal?: any;
	modalCloseHandler?: any;
	data?: any;
	selectedCheckboxes: any;
	setSelectedCheckboxes: any;
	filteredEmployeeName?: string;
}

const VacationList: React.FC<Props> = (props) => {
	const { t,i18n } = useTranslation('common');
	const numberCellIndexes = [0, 2, 3, 4, 5];
	let tableHeaderData = [["", `${t('employees.nameAndSurname')}`,`${t('vacation.specialLeave')}` ,`${t('vacation.annualLeave')}`, `${t('vacation.medicalLeave')}`, `${t('vacation.maternityLeave')}`,]];

	const state: EmployeeReportsState = useSelector((state: globalState) => state.employeeReportsReducer);
	const data = state.vacationList;
	const vacationListCount = state.vacationList?.count || 0;

	const [mainCheckboxClick, setMainCheckboxClick] = useState(null);
	const [mainCheckboxState, setMainCheckboxState] = useState(false);

	useEffect(() => {
		switch (mainCheckboxState) {
			case true:
				const checkArray = [];
				mainCheckboxClick && data?.data?.forEach(item => checkArray.push(item.id));
				mainCheckboxClick && props.setSelectedCheckboxes(prev => checkArray);
				break;
			case false:
				mainCheckboxClick && props.setSelectedCheckboxes(prev => []);
				break;
		}

	}, [data, mainCheckboxState, mainCheckboxClick, props]);


	useEffect(() => {
		const dataLength = data?.data?.length || 0;
		const rows = props.selectedCheckboxes?.length || 0;
		if (dataLength) {
			(dataLength > 0 && dataLength === rows)
				?
				setMainCheckboxState(prev => true)
				:
				setMainCheckboxState(prev => false);
		}
	}, [props.selectedCheckboxes, data]);

	return (
		<div className={`${styles.vacationList}`}>
			<Table
				tableWrapperClass={`${calculationStyles.tableWrapper} ${styles.tableWrapper}`}
				tableClass={state.loadingVacationList ? "h-100" : ""}
				tableHeader={
					<thead className={`${tableStyles.tableHead} ${calculationStyles.tableHeader}`}>
						<tr className={""}>
							<td className={`${checkboxStyle.checkboxCell} pr-0 noPrint`}>
								<Checkbox
									className={checkboxStyle.checkbox}
									icon={
										<div className={checkboxStyle.checkboxIconContainer} >
											<FiCheck color="#261f63" size={22} />
										</div>
									}
									checked={mainCheckboxState}
									onChange={(event) => {
										setMainCheckboxClick(prev => true);
										setMainCheckboxState(prev => !mainCheckboxState);
									}}
								/>
							</td>
							<td className={`${styles.nameCell}`} >
								<div className={`${styles.tableSubtitle} align-left`}>{"Të gjithë"}</div>
								<div className={`${styles.tableTitle} f-22 align-left`}>{vacationListCount}</div>
							</td>
							<td colSpan={tableHeaderData[0]?.length - 1 || 1}>

							</td>
						</tr>
						<tr className={`${tableStyles.headerRow} ${calculationStyles.tableMainRow} `} >
							{tableHeaderData[0].map((cell, index) => {
								const firstRowThClassnames = classNames({
									[tableStyles.headerCell]: true,
									[styles.nameCell]: index === 1,
									[tableStyles.noBackground + " " + tableStyles.noRadiusBottom]: true,
									[calculationStyles.numberCell]: (numberCellIndexes.includes(index) && index !== 0 && index !== 1),
									[calculationStyles.noWidth]: (index === 0 || index === 1),
									[calculationStyles.allTitle]: index === 0,
									[styles.pr0]: index === 0,
									"noPrint": index === 0,
								})

								return <th key={index} className={`${firstRowThClassnames}`} >
									{cell}
								</th>
							})}
						</tr>

					</thead>
				}
			>
				{

					<tbody className={calculationStyles.tableBody}>
						{state.loadingVacationList
							?
							<tr>
								<td colSpan={tableHeaderData[0].length || 1}>
									<LoaderCentered />
								</td>
							</tr>
							:
							data?.data?.filter((row) => {
								if(props.filteredEmployeeName?.length) {
									return row.employeeName == props.filteredEmployeeName;
								}
								return true;
							}).map((row: any, rowIndex) => {
								return <tr key={rowIndex}>
									<td className={`${checkboxStyle.checkboxCell} ${styles.pr0} noPrint`}>
										<Checkbox
											className={checkboxStyle.checkbox}
											icon={
												<div className={checkboxStyle.checkboxIconContainer} >
													<FiCheck color="#261f63" size={22} />
												</div>
											}
											checked={props.selectedCheckboxes?.includes(row.id)}
											onChange={(checked) => {
												setMainCheckboxClick(prev => false);
												checked ?
													props.setSelectedCheckboxes(prev => {
														return (_.union(prev, [row.id]));
													})
													:
													props.setSelectedCheckboxes(prev => {
														return props.selectedCheckboxes.filter(function (item) {
															return item !== row.id
														})
													})
											}}
										/>
									</td>
									<td className={`${tableStyles.bodyCell} ${calculationStyles.nameCell} ${styles.nameCell}`} >
										<div className={`${styles.tableTitle}`}>{row.employeeName}</div>
										<div className={`${styles.tableSubtitle}`}>{row.sector}</div>
									</td>
									{row?.gender === 1 ? 
										<td className={`${tableStyles.bodyCell} ${calculationStyles.f20}`}>
											{row.days.femaleSpecialLeave}
										</td>
										:
										<td className={`${tableStyles.bodyCell} ${calculationStyles.f20}`}>
											{row.days.maleSpecialLeave}
										</td>
									}
									<td className={`${tableStyles.bodyCell} ${calculationStyles.f20}`}>
										{row.days.annual}
									</td>
									<td className={`${tableStyles.bodyCell} ${calculationStyles.f20}`}>
										{row.days.MedicalLeave}
									</td>

									{row?.gender === 2 ? 
									<td className={`${tableStyles.bodyCell} ${calculationStyles.f20}`}>
										N/A
									</td>
									:
									<td className={`${tableStyles.bodyCell} ${calculationStyles.f20}`}>
										{row.days.MaternityLeave}
									</td>
									}
								</tr>
							})}
					</tbody>
				}
			</Table>
		</div>
	);
}

export default VacationList;