import AppWrapper from '../../components/AppWrapper/AppWrapper.component';
import styles from './Notification.module.scss';
import {Notifications} from './notifications.component';

export const NotificationsPage: React.FC = (props) => {


  return <AppWrapper appContentClass={styles.pageContainer}>
    <div className={styles.notificationParent}>
      <Notifications />
    </div>
  </AppWrapper>
}
