import produce from "immer";
import { appWrapperState } from "types/reducers/appWrapper.reducer.type";
import {
    SET_COMPANIES_DATA,
    SET_USER_DATA,
    SET_BACKDROP_STATE,
    TOGGLE_MODAL_STATE,
    COLLAPSE_SIDEBAR_MENU,
} from "./constants";

const initialState: appWrapperState = {
    companies: {},
    user: {},
    showBackdrop: false,
    backdropParent: null,
    backDrop_callback: null,
    showEmployeeModal: false,
    sidebarMenuCollapsed: false,
    error: null,
};

const appWrapperReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case SET_COMPANIES_DATA:
                return {
                    ...state,
                    companies: action.payload,
                };
            case SET_USER_DATA:
                return {
                    ...state,
                    user: action.payload,
                };
            case SET_BACKDROP_STATE:
                return {
                    ...state,
                    showBackdrop: action.payload.showBackdrop,
                    backdropParent: action.payload.backdropParent || null,
                    backDrop_callback: action.payload.backDrop_callback,
                };
            case TOGGLE_MODAL_STATE:
                return {
                    ...state,
                    showEmployeeModal: action.payload.state,
                };
            case COLLAPSE_SIDEBAR_MENU:
                return {
                    ...state,
                    sidebarMenuCollapsed: action.payload,
                };

            default: return state;
        }
    })

export default appWrapperReducer;
