import { IconX } from "assets/icons/icons";
import Button from "components/Button/Button.component";
import LoaderCentered from "components/partials/LoaderCentered/LoaderCentered.component";
import { t } from "i18next";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Employee } from "types/employee.type";
import { globalState } from "types/globalState.type";
import { FileObject } from "types/partials/fileObject.type";
import { EmployeesReducer } from "types/reducers/employees.reducer.type";
import { deleteUploadedAttachment, uploadMultipleAttachments } from "../actions";
import styles from "./EmployeeAttachments.module.scss";
import { FileUploadRequest } from "pages/Employees/EmployeeModal/EmployeeModal.component";

interface Props {
	editMode: boolean;
	modalEmployeeInputs?: Employee;
	setModalEmployeeInputs?: any;
	employeeAttachments?: any;
	setShowConfirmModal?: any;
	setConfirmLoading?: any;
}

const EmployeeAttachments = (props: Props) => {
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);
	const { t } = useTranslation('common');
	const dispatch = useDispatch();
	const attachmentFileInput = useRef(null);

	const afterDeleteFile = (deletedId) => {
		props.setConfirmLoading(false);
		props.setShowConfirmModal({ show: false });
		props.setModalEmployeeInputs((prev) => {
			return {
				...prev,
				attachments: prev?.attachments?.filter((item: FileObject) => item.attachmentId !== deletedId)
			}
		});
		toast.success(t('toast.documentDeleteSuccess'));
	}

	const handleDeletePickedFile = (id) => {
		props.setShowConfirmModal(prev => {
			return {
				show: true,
				title: "Fshij dokumentin",
				subTitle: "A jeni të sigurtë që doni ta fshini dokumentin ?",
				callback: function () {
					props.setConfirmLoading(true)
					dispatch(deleteUploadedAttachment(
						tenantId,
						id,
						() => { afterDeleteFile(id) },
						() => { props.setConfirmLoading(false); }
					));
				}
			}
		})

	}

	const attachmentButtonOnClick = (event) => {
		attachmentFileInput.current && attachmentFileInput.current.click();
	}

	const handleMultiFilePickerChange = async (event) => {
		if (event?.target?.files && event?.target?.files.length) {
			const data: any = new FormData();
			data.append("userId", tenantId.toString());
			data.append("file", event.target.files[0]);
			dispatch(uploadMultipleAttachments(data));
		}
	}

	const employeesReducer: EmployeesReducer = useSelector((state: RootStateOrAny) => state.employeesReducer);
	const pickedFiles: FileObject[] = employeesReducer.pickedFiles;
	const filePickerLoading = employeesReducer.filePickerLoading;

	return (
		<div className={styles.employeeAttachmentsContainer}>
			<input
				type="file"
				name="multiFilePicker"
				id="multiFilePicker"
				className={`d-none`}
				ref={attachmentFileInput}
				onChange={handleMultiFilePickerChange}
			/>
			<div className={styles.title}>{t('reports.attachments')}</div>
			{
				filePickerLoading
					?
					<LoaderCentered style={{ width: "20px", height: "20px", margin: "5px auto" }} />
					:
					<div className={styles.content}>
						{
							props?.modalEmployeeInputs?.attachments?.map((item: FileObject) => {
								return (
									<div className={styles.row} key={item.attachmentId}>
										<div className={styles.nameWrapper}>
											<a
												href={`${process.env.REACT_APP_API_URL_V2}/${item.filePath}`}
												target="_blank"
												rel="noreferrer"
												className={styles.name}
												title={item.fileName}
											>
												{item.fileName}
											</a>
										</div>
										{
											props.editMode
											&&
											<div
												className={styles.removeItem}
												title="Fshij"
												onClick={() => handleDeletePickedFile(item.attachmentId)}
											>
												<IconX />
											</div>
										}
									</div>
								);
							})
						}
						{
							pickedFiles?.map((item: FileObject) => {
								return (
									<div className={styles.row} key={item.attachmentId}>
										<div className={styles.nameWrapper}>
											<a
												href={`${process.env.REACT_APP_API_URL_V2}/${item.filePath}`}
												target="_blank"
												rel="noreferrer"
												className={styles.name}
												title={item.fileName}
											>
												{item.fileName}
											</a>
										</div>
										{
											props.editMode
											&&
											<div
												className={styles.removeItem}
												title="Fshij"
												onClick={() => handleDeletePickedFile(item.attachmentId)}
											>
												<IconX />
											</div>
										}
									</div>
								);
							})
						}
					</div>
			}
			<div className={styles.footer}>
				{
					props.editMode &&
					<Button
						className={styles.button}
						viewType="shell"
						noMargin={true}
						onClickHandler={attachmentButtonOnClick}
						attributes={{
							disabled: filePickerLoading,
						}}
					>
						{t('reports.upload')}
					</Button>
				}
			</div>
		</div>
	);
}

export default EmployeeAttachments;