import { useTranslation } from "react-i18next";
import produce from "immer";
import {
  LOAD_CONTRIBUTIONS,
  LOAD_CONTRIBUTIONS_SUCCESS,
  LOAD_CONTRIBUTIONS_ERROR,
  DOWNLOAD_CONTRIBUTIONS_EXCEL,
  DOWNLOAD_CONTRIBUTIONS_PDF,
  DOWNLOAD_CONTRIBUTIONS_PDF_SUCCESS,
  DOWNLOAD_CONTRIBUTIONS_PDF_ERROR,
  DOWNLOAD_CONTRIBUTIONS_EXCEL_SUCCESS,
  DOWNLOAD_CONTRIBUTIONS_EXCEL_ERROR,
  SET_EMPLOYEES_CHECKED,
  SEND_CONTRIBUTIONS_LIST_EMAIL,
  SEND_CONTRIBUTIONS_LIST_EMAIL_SUCCESS,
  SEND_CONTRIBUTIONS_LIST_EMAIL_ERROR,
} from "./constants";
import { toast } from "react-toastify";

const initialState: any = {
  loading: false,
  isExcelContributionLoading: false,
  isPdfContributionLoading: false,
  loadingEmailContributionsList: false,
  allContributions: [],
};

const contributionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_CONTRIBUTIONS:
        draft.loading = true;
        break;
      case LOAD_CONTRIBUTIONS_SUCCESS:
        let newArr = [];
        action.payload.contributions.data.monthlyStatistics.map((month) => {
          if (newArr.length === 0) {
            newArr.push(month);
          } else {
            const dayToFind = newArr.findIndex(
              (s) => s.monthId === month.monthId
            );
            if (dayToFind === -1) {
              newArr.push(month);
            }
          }
        });

        newArr.sort(function (a, b) {
          return a.monthId - b.monthId;
        });
        draft.allContributions = newArr;
        draft.totalContributions = action.payload.contributions.data.total;
        draft.loading = false;
        break;
      case LOAD_CONTRIBUTIONS_ERROR:
        draft.loading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case DOWNLOAD_CONTRIBUTIONS_PDF:
        draft.isPdfContributionLoading = true;
        break;
      case DOWNLOAD_CONTRIBUTIONS_PDF_SUCCESS:
        window.open(action.payload.contributions.data, "_blank");
        draft.isPdfContributionLoading = false;
        break;
      case DOWNLOAD_CONTRIBUTIONS_PDF_ERROR:
        draft.isPdfContributionLoading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case DOWNLOAD_CONTRIBUTIONS_EXCEL:
        draft.isExcelContributionLoading = true;
        break;
      case DOWNLOAD_CONTRIBUTIONS_EXCEL_SUCCESS:
        window.open(action.payload.contributions.data, "_blank");
        draft.isExcelContributionLoading = false;
        break;
      case DOWNLOAD_CONTRIBUTIONS_EXCEL_ERROR:
        draft.isExcelContributionLoading = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case SEND_CONTRIBUTIONS_LIST_EMAIL:
        draft.loadingEmailContributionsList = true;
        break;
      case SEND_CONTRIBUTIONS_LIST_EMAIL_SUCCESS:
        draft.loadingEmailContributionsList = false;
        toast.success("Emailet u dërguan me sukses!");
        break;
      case SEND_CONTRIBUTIONS_LIST_EMAIL_ERROR:
        draft.loadingEmailContributionsList = false;
        toast.error("Diçka shkoi gabim!");
        break;
      case SET_EMPLOYEES_CHECKED:
        draft.allContributions.map((item) => {
          item.isChecked = action.payload.checked;
        });
        break;
      default:
        break;
    }
  });

export default contributionsReducer;
