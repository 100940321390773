import { call, put, takeLatest } from 'redux-saga/effects';
import { getUpcomingBirthdays } from 'services/employee.service';
import { getUpcomingVacations } from 'services/vacations.service';
import { getExpiredContracts } from 'services/contracts.service';

import {
	loadUpcomingBirthdaysSuccess, loadUpcomingBirthdaysError,
	loadUpcomingVacationsSuccess, loadUpcomingVacationsError,
	loadExpiredContractsSuccess, loadExpiredContractsError, loadSalaryCardsDataSuccess, loadSalaryCardsDataError,
} from './actions';

import {
	LOAD_UPCOMING_BIRTHDAYS,
	LOAD_UPCOMING_VACATIONS,
	LOAD_EXPIRED_CONTRACTS,
	LOAD_DASHBOARD_CARDS_DATA
} from './constants';
import { averageSalary, salariesTillNow } from 'services/salaries.service';

export function* loadUpcomingVacations(action) {
	try {
		const response = yield call(
			getUpcomingVacations,
			action.payload.tenantId,
		);
		yield put(loadUpcomingVacationsSuccess(response.data.data));
	} catch (e) {
		yield put(loadUpcomingVacationsError());
	}
}
export function* loadUpcomingBirthdays(action) {
	try {
		const response = yield call(
			getUpcomingBirthdays,
			action.payload.tenantId,
		);
		yield put(loadUpcomingBirthdaysSuccess(response.data));
	} catch (e) {
		yield put(loadUpcomingBirthdaysError());
	}
}

export function* loadExpiredContracts(action) {
	try {
		const response = yield call(
			getExpiredContracts,
			action.payload.tenantId,
		);
		yield put(loadExpiredContractsSuccess(response.data.data));
	} catch (e) {
		yield put(loadExpiredContractsError());
	}
}

export function* loadDashboardCardsData(action) {
	try {
		const salariesTillNowData = yield call(salariesTillNow, action.payload.tenantId,);
		const averageSalaryData = yield call(averageSalary, action.payload.tenantId);
		let data = { salariesTillNowData, averageSalaryData };

		yield put(loadSalaryCardsDataSuccess(data));

	} catch (e) {
		yield put(loadSalaryCardsDataError(e));
	}
}

export default function* dashboardSaga() {
	yield takeLatest(LOAD_UPCOMING_BIRTHDAYS, loadUpcomingBirthdays);
	yield takeLatest(LOAD_UPCOMING_VACATIONS, loadUpcomingVacations);
	yield takeLatest(LOAD_EXPIRED_CONTRACTS, loadExpiredContracts);
	yield takeLatest(LOAD_DASHBOARD_CARDS_DATA, loadDashboardCardsData);
}
