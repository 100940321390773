import Button from 'components/Button/Button.component';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import styles from 'pages/Configurations/Configurations.module.scss';
import { FunctionComponent, useEffect, useState } from 'react';
import ModalCustom from 'components/ModalCustom/ModalCustom.component';
import { ConfigurationsState } from 'types/reducers/configurations.reducer.type';
import { globalState } from 'types/globalState.type';
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../actions';
import { useTranslation } from 'react-i18next';

interface Props {
}

const PayrollTab: FunctionComponent = (props: Props) => {
    const { t, i18n } = useTranslation('common')
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);
	const state: ConfigurationsState = useSelector((state: globalState) => state.configurationsReducer);
	const contactInfo = state.contactInfo;

	const dispatch = useDispatch();

	const roleId = useSelector((state: globalState) => state.globalReducer.user?.roleId);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [ filePicked, setFilePicked ] = useState(null);

	const globalState = useSelector((state: globalState) => state.globalReducer);

	const initialLoad = () => {
		dispatch(actions.getContactInfo(tenantId));
	}

	useEffect(() => {
		initialLoad();
	}, [])

	const handleIsModalOpen = () => {
		setIsModalOpen(true);
	}

	const handleLogoUploadChange = (event) => {
		setFilePicked(event.target.files[0],);

		const formData = new FormData();
		formData.append('logo', event.target.files[0]);

		dispatch(actions.uploadLogo(formData));
		dispatch(actions.getAuthUser())
	}

	return (
		<div className={styles.payrollTab}>
			<Button
				viewType='shadow'
				className={styles.configButton}
				onClickHandler={() => { }}
			>
			<div className={`${styles.iconContainer}`}>
				<AiOutlinePlusCircle />
			</div>
			<input className={styles.customFileUpload} id="file-upload" accept="image/*" type="file" name="image" onChange={handleLogoUploadChange}/>
				{t('configuration.addLogo')}
				<div className={`${styles.iconContainer}`}>
				</div>
			</Button>
			<Button
				viewType='shadow'
				className={styles.configButton}
				onClickHandler={() => handleIsModalOpen()}
			>
				{t('configuration.contactInfo')}
			</Button>

			{isModalOpen && 
				<ModalCustom
					show={isModalOpen}
					contentClass={`${styles.contentClass} ${styles.modalPayroll}`}
					footerClass={styles.footerClass}
					onClose={() => {
						setIsModalOpen(prev => false)
					}}
					hideFooterButton={true}
				>
				<div className={styles.title}>
					{t('configuration.contactInfo')}
				</div>

				<div className={styles.nameBox}>
					<div className={styles.name}>
						{t('configuration.name')}
					</div>
					<div className={styles.tenantName}>
						{contactInfo?.name}
					</div>
				</div>

				<div className={styles.emailBox}>
					<div className={styles.email}>
						{t('configuration.email')}
					</div>
					<div className={styles.emailAddress}>
						{contactInfo?.email}
					</div>
				</div>
				{roleId === 2 || roleId === 3 || roleId === 4? 
					<>
						<div className={styles.emailBox}>
							<div className={styles.email}>
								Data e Regjistrimit:
							</div>
							<div className={styles.emailAddress}>
								{contactInfo?.registrationDate}
							</div>
						</div>
						<div className={styles.emailBox}>
							<div className={styles.email}>
								Data e Skadimit:
							</div>
							<div className={styles.emailAddress}>
								{contactInfo?.expirationDate}
							</div>
						</div>
						<div className={styles.emailBox}>
							<div className={styles.email}>
								Status:
							</div>
							<div className={styles.emailAddress}>
								{contactInfo?.status}
							</div>
						</div>
						<div className={styles.emailBox}>
							<div className={styles.email}>
								NUI:
							</div>
							<div className={styles.emailAddress}>
								{contactInfo?.nui}
							</div>
						</div>
					</>
				: null}		
				</ModalCustom>
			}
		</div>
	);

}

export default PayrollTab;