import { useEffect, useRef, useState } from "react";
import { ThreeDots } from 'components/partials/ThreeDots/ThreeDots.component';

import styles from "./DropdownMenu.module.scss";
import { useDispatch } from "react-redux";
import { setBackdropState } from "components/AppWrapper/actions";
interface Props {
    children?: React.ReactNode;
    onClickHandler?: string;
    withoutBackDrop?: boolean
}

const DropdownMenu = (props: Props) => {
    const menuRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleOutsideMenuClick = (e: any) => {
            if (!menuRef?.current?.contains(e.target) && menuOpen) {
                setMenuOpen(!menuOpen);
            }
        }
        if (menuOpen) window.addEventListener("click", handleOutsideMenuClick);
        if(props.withoutBackDrop){
            dispatch(setBackdropState({
                showBackdrop: menuOpen,
                backdropParent: menuOpen ? "dropdownMenu" : null
            }));
        }


        return () => window.removeEventListener("click", handleOutsideMenuClick);
    }, [menuOpen, menuRef]);

    return <div ref={menuRef}>
        <ThreeDots className={styles.threeDotsIcon} onClickHandler={() => setMenuOpen(!menuOpen)} />
        {menuOpen && props.children}
    </div>
}

export default DropdownMenu;
