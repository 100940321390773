import {
	LOAD_REPORT_STATISTICS,
	LOAD_REPORT_STATISTICS_SUCCESS,
	LOAD_REPORT_STATISTICS_ERROR,
	DOWNLOAD_REPORT_STATISTICS_PDF,
	DOWNLOAD_REPORT_STATISTICS_PDF_SUCCESS,
	DOWNLOAD_REPORT_STATISTICS_PDF_ERROR,
	DOWNLOAD_REPORT_STATISTICS_EXCEL,
	DOWNLOAD_REPORT_STATISTICS_EXCEL_SUCCESS,
	DOWNLOAD_REPORT_STATISTICS_EXCEL_ERROR,
	LOAD_EMAIL_STATISTICS,
	LOAD_EMAIL_STATISTICS_SUCCESS,
	LOAD_EMAIL_STATISTICS_ERROR,
} from './constants';

export const loadReportStatistics = (payload) => {
	return {
		type: LOAD_REPORT_STATISTICS,
		payload: {
			payload
		}
	}
}

export const loadReportStatisticsSuccess = (statistics) => {
	return {
		type: LOAD_REPORT_STATISTICS_SUCCESS,
		payload: {
			statistics
		}
	}
}

export const loadReportStatisticsError = (error) => {
	return {
		type: LOAD_REPORT_STATISTICS_ERROR,
		payload: {
			error
		}
	}
}

export const downloadReportStatisticsPdf = (tenantId: number, year: number) => {
	return {
		type: DOWNLOAD_REPORT_STATISTICS_PDF,
		payload: { tenantId, year }
	}
}

export const downloadReportStatisticsPdfSuccess = (contributions) => {
	return {
		type: DOWNLOAD_REPORT_STATISTICS_PDF_SUCCESS,
		payload: {
			contributions
		}
	}
}

export const downloadReportStatisticsPdfError = (error) => {
	return {
		type: DOWNLOAD_REPORT_STATISTICS_PDF_ERROR,
		payload: {
			error
		}
	}
}

export const downloadReportStatisticsExcel = (tenantId: number, year: number) => {
	return {
		type: DOWNLOAD_REPORT_STATISTICS_EXCEL,
		payload: {
			tenantId,
			year,
		}
	}
}

export const downloadReportStatisticsExcelSuccess = (contributions) => {
	return {
		type: DOWNLOAD_REPORT_STATISTICS_EXCEL_SUCCESS,
		payload: {
			contributions
		}
	}
}

export const downloadReportStatisticsExcelError = (error) => {
	return {
		type: DOWNLOAD_REPORT_STATISTICS_EXCEL_ERROR,
		payload: {
			error
		}
	}
}


export const loadEmailStatistics = (email: string) => {
	return {
		type: LOAD_EMAIL_STATISTICS,
		payload: {
			email
		}
	}
}

export const loadEmailStatisticsSuccess = (email) => {
	return {
		type: LOAD_EMAIL_STATISTICS_SUCCESS,
		payload: {
			email
		}
	}
}

export const loadEmailStatisticsError = (error) => {
	return {
		type: LOAD_EMAIL_STATISTICS_ERROR,
		payload: {
			error
		}
	}
}