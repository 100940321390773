import { FunctionComponent, useEffect } from 'react';
import { Router, Switch } from "react-router-dom";

import Login from "pages/Login/Login.component";
import Dashboard from "pages/Dashboard/Dashboard.component";
import Employees from "pages/Employees/Employees.component";
import Calculations from "pages/Calculations/Calculations.component";
import Documents from "pages/Documents/Documents.component";
import Project from 'pages/Configurations/Projects/Project.component';
import Reports from "pages/Reports/Reports.component";
import ManagementReports from 'pages/Reports/ManagementReports/ManagementReports.component';
import Holidays from "pages/Holidays/Holidays.component";
import Configurations from "pages/Configurations/Configurations.component";
import NotFound from "components/404/404.component";
import CompanyLogin from 'pages/Login/CompanyLogin/CompanyLogin.component';
import ForgotPasswordForm from 'pages/Login/ForgotPasswordForm.component';
import LicenseAgreement from 'pages/Login/LicenseAgreement/LicenseAgreement.component';
import ChangePassword from 'pages/Login/ChangePassword/ChangePassword.component';

import { Protected } from "auth/ProtectedRoute";
import { Public } from "auth/PublicRoute";
import EmployeeReports from 'pages/Reports/EmployeeReports/EmployeeReports.component';

import 'react-toastify/dist/ReactToastify.css';
import "styles/_global.scss";
import TaxAdministrationReports from 'pages/Reports/TaxAdministrationReports/TaxAdministrationReports.component';
import EdiReports from '../pages/Reports/TaxAdministrationReports/EdiReports/EdiReports.component';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { getNotifications } from "./actions";
import { NotificationsPage } from '../pages/NotificationsPage/notificationsPage.component';
import history from './history';
import { globalState } from 'types/globalState.type';

const App: FunctionComponent = () => {
	const isLoggedIn = useSelector((state: RootStateOrAny) => state).globalReducer.isLoggedIn;
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);
	const dispatch = useDispatch();

	useEffect(() => {
		if (localStorage.getItem('token') && isLoggedIn) {
			dispatch(getNotifications(tenantId));
		}
	}, [isLoggedIn]);

	return (
		<Router history={history}>
			<Switch>
				<Protected exact path="/" component={Dashboard} />
				<Protected exact path="/employees" component={Employees} />
				<Protected exact path="/calculations" component={Calculations} />
				<Protected exact path="/configurations" component={Configurations} />
				<Protected exact path="/documents" component={Documents} />
				<Protected exact path="/projects" component={Project} />
				<Protected exact path="/holidays" component={Holidays} />
				<Protected exact path="/reports" component={Reports} />
				<Protected exact path="/reports/management" component={ManagementReports} />
				<Protected exact path="/reports/employee" component={EmployeeReports} />
				<Protected exact path="/reports/tax-administration" component={TaxAdministrationReports} />
				<Protected exact path="/reports/tax-administration/edi" component={EdiReports} />
				<Protected exact path="/changePassword" component={ChangePassword} />
				<Public path="/forgotPassword" component={ForgotPasswordForm} />
				<Protected path="/licenseAgreement" component={LicenseAgreement} />
				<Public exact path="/login" component={Login} />
				<Public exact path="/companyLogin" component={CompanyLogin} />
				<Protected exact path="/notifications" component={NotificationsPage} />
				<Public path="/resetPassword" component={ForgotPasswordForm} />
				<Public component={NotFound} />
			</Switch>
		</Router>
	)
};

export default App;
