
import React, { FunctionComponent, MouseEvent, ReactChild, ReactChildren, useEffect, useRef, useState } from 'react';
import styles from './ModalCustom.module.scss';
import Button from "components/Button/Button.component";
import { IconX } from 'assets/icons/icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
interface Props {
	children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
	onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
	onSubmit?: (event: MouseEvent<HTMLButtonElement>) => void;
	header?: ReactChild;
	show?: boolean;
	backdropClass?: string;
	submitText?: string;
	contentClass?: string;
	footerClass?: string;
	headerClass?: string;
	hideFooterButton?: boolean;
	submitLoading?: boolean;
	closeButtonClass?: string;
	modalPrintClass?: string;
	modalMainStyle?: string;
	ref?: any;
	fixDimensions?: boolean;
	showRejectButton?: boolean;
	preventBackdropCloseClick?: boolean;
	showSubmitButton?: boolean;
	closeText?: string;
}

const ModalCustom: FunctionComponent<Props> = React.forwardRef((props: Props, contentRef: any) => {
	const showSubmitButton = props.showSubmitButton !== undefined ? props.showSubmitButton : true;
	const modalRef = useRef(null);
	const [showModal, setShowModal] = useState(props.show);

	const handleOutsideClick = (event) => {
		if (event.target === modalRef.current && !props.preventBackdropCloseClick) {
			setShowModal(prev => false);
			props.onClose && props?.onClose(event)
		}
	}

	const handleCloseClick = (event) => {
		setShowModal(prev => false);
		props.onClose && props?.onClose(event)
	}

	const handleSubmitClick = (event) => {
		props.onSubmit && props?.onSubmit(event)
	}

	useEffect(() => {
		setShowModal(prev => props.show);
	}, [props.show]);

	const modalClasses = classNames({
		[styles.modalWrapper]: true,
		[props.backdropClass]: props.backdropClass,
		[styles.visible]: showModal,
	})
	const headerStyles = classNames({
		[styles.header]: true,
		[props.headerClass]: props.headerClass,
	})

	return <div
		ref={modalRef}
		// onClick={handleOutsideClick}
		className={modalClasses}
	>
		<div className={`modal ${styles.modal} ${props.modalMainStyle || ""}`}>
			<Button
				className={`${styles.closeButton} ${props.closeButtonClass || ""}`}
				onClickHandler={handleCloseClick}
				attributes={{ title: "Close" }}
				viewType="shell"
			>
				<IconX />
			</Button>
			<div ref={contentRef} className={`modalPrint ${props.modalPrintClass ? props.modalPrintClass : ""}`}>
				<div className={headerStyles}>
					{props?.header}
				</div>
				<div className={`${styles.content} ${props.contentClass || ""} printPage`}>
					{props.children}
				</div>
			</div>
			{!props.hideFooterButton ?
				<div className={`${styles.footer} ${props.footerClass || ""}`}>
					{
						props.showRejectButton
						&&
						<Button
							onClickHandler={(event) => {
								setShowModal(false);
								props.onClose && props?.onClose(event)
							}}
							className={`${styles.btn} ${styles.submitBtn}`}
							fixDimensions={props.fixDimensions || false}
							viewType={"shell"}
						>
							{props.closeText || "Mbyll"}
						</Button>

					}
					{
						showSubmitButton
						&&
						<Button
							onClickHandler={handleSubmitClick}
							className={`${styles.btn} ${styles.submitBtn}`}
							loading={props.submitLoading}
							fixDimensions={props.fixDimensions || false}
						>
							{props.submitText || "Ruaj"}
						</Button>
					}
				</div>
				: ""
			}

		</div>
	</div>

});
export default ModalCustom;
