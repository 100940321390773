import  styles from "./CompanyLogin.module.scss";
import { toast } from 'react-toastify';
import Card from "components/Card/Card.component";
import userImageLink from "assets/images/user.png";
import { ConfigurationsState } from 'types/reducers/configurations.reducer.type';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { globalState } from 'types/globalState.type';
import * as actions from '../../Configurations/actions';
import { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { setGlobalHasQuickbooks, setGlobalTenantId, setLogo } from "App/actions";

const CompanyLogin: FunctionComponent = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const state: ConfigurationsState = useSelector((state: globalState) => state.configurationsReducer);
    const globalState = useSelector((state: globalState) => state.globalReducer);
    const loginCompanies = state?.companyLogin;

    const [tenantId, setTenantId] = useState<number>();

    const initialLoad = () => {
		dispatch(actions.getLoginCompanies({roleId: globalState?.user?.roleId, email: globalState?.user?.email}));
	}

    useEffect(() => {
        if(loginCompanies?.length === 1){
            history.push("/")
            dispatch(setGlobalTenantId(loginCompanies[0]?.id));
            dispatch(setGlobalHasQuickbooks(loginCompanies[0]?.hasQuickbooks));
            localStorage.setItem('token', globalState?.token);
            localStorage.setItem('isLoggedIn', globalState?.user?.success);
            dispatch(setLogo(loginCompanies[0]?.logo));
        }
    }, [loginCompanies])

    const handlePickedTenant = (companyId, logo) => {
        setTenantId(companyId)
        dispatch(setGlobalTenantId(companyId));
        dispatch(setLogo(logo));
        localStorage.setItem('token', globalState?.token);
        localStorage.setItem('isLoggedIn', globalState?.user?.success);
        history.push("/")
    }

    useEffect(() => {
		initialLoad();
	}, [])

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.title}>
                Zgjedh Kompaninë ...
            </div>
            <div className={styles.form}>
                {loginCompanies?.length > 0 && loginCompanies.map((company, idx) => {
                    return <Card className={styles.card} cardContentClass={styles.cardContentClass}>
                                <div className={styles.card} onClick={() => handlePickedTenant(company?.id, company?.logo)}>
                                <img src={company?.logo} alt="" />
                                <p>{company?.name}</p>
                                </div>
                            </Card>
                })}
            </div>
        </div>
    )
};

export default CompanyLogin;
