import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppWrapper from 'components/AppWrapper/AppWrapper.component';
import CommonHeader from 'components/partials/CommonHeader/CommonHeader.component';
import { FaChevronLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import ReportTemplateBox from 'components/ReportTemplateBox/reportTemplateBox.component';
import styles from './EmployeeReports.module.scss';
import ModalCustom from 'components/ModalCustom/ModalCustom.component';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { certificatePdf, emailCertificate, emailVacationList, exportCertificate, exportExcelVacationList, loadAnnualCertificate, loadEmployeeData, loadVacationList, vacationListPdf } from './actions';
import { IconDocument, IconX } from 'assets/icons/icons';
import { useReactToPrint } from 'react-to-print';
import { FiCalendar, FiCheck, FiDownload } from 'react-icons/fi';
import InputReports from 'components/partials/InputReports/InputReports.component';
import { globalState } from 'types/globalState.type';

import Checkbox from "react-custom-checkbox";
import CustomSelect from 'components/CustomSelect/CustomSelect.component';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { customSelectStyles, downloadTypeOptions, monthNamesAL } from 'utils/globalVariables';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect.component';
import LoaderCentered from 'components/partials/LoaderCentered/LoaderCentered.component';
import VacationList from './VacationList';
import { EmployeeReportsState } from 'types/reducers/reports.reducer.type';
import { generateArrayOfYears } from 'utils/functions';
import DropdownIndicator from 'components/DropdownIndicator/DropdownIndicator.component';
import { useTranslation } from 'react-i18next';
import InputCustom from 'components/InputCustom/InputCustom.component';
import Select from 'react-select';

const todayDate = format(new Date(), 'dd.MM.yyyy');
const currentYear: number = new Date().getFullYear();
const currentMonth: number = new Date().getMonth();

const monthsList = monthNamesAL;
let yearsList = generateArrayOfYears(21);

const pageStyle = `
@page {
	margin: 10px 20px;
	font-size: 15px;
}
.noPrint {
	display: none !important;
}
.footer {
	position: fixed;
	bottom: 10px;
}
.modalPrint {
	font-size: 13px !important;
}
.print-padding {
	padding: 0 20px;
}
.reportDropdown__indicator {
	display:none !important;
}
.printHeightFix {
	height: unset !important;
	min-height: unset !important;
	overflow: initial !important;
}
`;


const EmployeeReports: FunctionComponent = () => {
	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);
	const { t,i18n } = useTranslation('common');
	const dispatch = useDispatch();
	const history = useHistory();

	const state: EmployeeReportsState = useSelector((state: globalState) => state.employeeReportsReducer);
	const tenantData = state.tenantData;
	const employees = state.tenantData?.employees;
	const employeeData = state.employeeData;

	const [showVacationListReport, setShowVacationListReport] = useState<boolean>(false)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [checkedAgreement, setCheckedAgreement] = useState<boolean>(false)
	const [modalOpener, setModalOpener] = useState<string>("")
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [employeeList, setEmployeeList] = useState([]);
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

	const [pickedYear, setPickedYear] = useState(currentYear);
	const [pickedMonth, setPickedMonth] = useState(currentMonth);

	const monthsWrapperRef = useRef(null);
	const periodWrapperRef = useRef(null);
	const [showFilterContent, setShowFilterContent] = useState(false);

	const [isEmailOpen, setIsEmailOpen] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [emailValidMessage, setEmailValidMessage] = useState<string>("");
	const isSendEmailLoading = useSelector((state: RootStateOrAny) => state.documentsReducer.sendEmailLoading);

	const backdropParent: string = useSelector((state: globalState) => state.appWrapperReducer.backdropParent);

	const [from, setFrom] = useState<any>(new Date());
	const [to, setTo] = useState<any>(new Date());
	const [filteredEmployeeName, setFilteredEmployeeName] = useState<any>({});

	useEffect(() => {
		const handleOutsideMenuClick = (e: any) => {
			setShowFilterContent(!showFilterContent);
		}

		if (showFilterContent) window.addEventListener("click", handleOutsideMenuClick);
		return () => window.removeEventListener("click", handleOutsideMenuClick);
	}, [showFilterContent]);


	useEffect(() => {
		showFilterContent &&
			setTimeout(() => {
				periodWrapperRef?.current && !periodWrapperRef.current.style.height && (periodWrapperRef.current.style.height = (monthsWrapperRef.current.offsetHeight + 2) + "px");
			}, 0);

	}, [showFilterContent]);

	const stableDispatch = useCallback(dispatch, [])

	useEffect(() => {
		if(typeof from === 'string' && typeof to === 'string'){
			setShowFilterContent(prev => false);
			dispatch(loadVacationList(tenantId, from, to));
		}else{
			setShowFilterContent(prev => false);
			dispatch(loadVacationList(tenantId, todayDate, todayDate));
		}
		
	}, [to, stableDispatch, from, dispatch, tenantId]);

	useEffect(() => {
		const formatEmployees = [];
		employees && employees?.forEach(item => {
			if (item.name.trim().length) {
				formatEmployees.push({
					value: item.id,
					label: item.name
				})
			}
		})
		formatEmployees && setEmployeeList(prev => formatEmployees);
	}, [employees]);

	useEffect(() => {
		selectedEmployee && selectedEmployee.value && dispatch(loadEmployeeData(selectedEmployee?.value));
	}, [selectedEmployee, dispatch]);


	const handleBackToReports = () => {
		history.push("/reports");
	}

	useEffect(() => {
		if (!isSendEmailLoading) {
			setIsModalOpen(false);
		}
	}, [isSendEmailLoading]);

	const openModal = (opener) => {
		setShowModal(true);
		setModalOpener(opener);
	}

	const handleEmailChange = (e) => {
		const emailRegex = /\S+@\S+\.\S+/;
		setEmail(e.target.value);
		if (emailRegex.test(email)) {
			setIsEmailValid(true);
		} else {
			setIsEmailValid(false);
			setEmailValidMessage('Please enter a valid email!');
		}
	};

	useEffect(() => {
		switch (modalOpener) {
			case "annualCertificateReport":
				dispatch(loadAnnualCertificate(tenantId));
				break;
			case "vacationListReport":
				setShowVacationListReport(prev => true);
				dispatch(loadVacationList(tenantId, todayDate, todayDate));
				break;
			default:
				dispatch(loadEmployeeData());
				break;
		}
	}, [dispatch, modalOpener, tenantId]);

	const handleOpenModal = () => {
		setEmail("")
		setIsEmailOpen(false)
	};

	const handleDownload = (event, item) => {
		switch (modalOpener) {
			case "annualCertificateReport":
				selectedEmployee && selectedEmployee.value
					?
					(
						item.value === 1
							?
							dispatch(exportCertificate(tenantId, selectedEmployee.value))
							: handlePrint()
					)
					:
					toast.warn(t('reports.chooseEmployee'));

				break;
			case "vacationListReport":
				if(item.value === 1){

					if(typeof from === 'string' && typeof to === 'string'){
						dispatch(exportExcelVacationList(tenantId, from, to));
					}else{
						dispatch(exportExcelVacationList(tenantId, todayDate, todayDate));
					}
				}else{
					if(typeof from === 'string' && typeof to === 'string'){
						dispatch(vacationListPdf(tenantId, from, to));
					}else{
						dispatch(vacationListPdf(tenantId, todayDate, todayDate));
					}
				}			
				break;
		}
	}

	const handleEmail = (event) => {
		switch (modalOpener) {
			case "annualCertificateReport":

				selectedEmployee && selectedEmployee.value
					?
					dispatch(emailCertificate(tenantId, selectedEmployee.value, email))
					:
					toast.warn(t('reports.chooseEmployee'));

				break;
			case "vacationListReport":
					if(typeof from === 'string' && typeof to === 'string'){
						setShowFilterContent(prev => false);
						dispatch(
							emailVacationList({
								tenantId,
								from: from,
								to: to,
								employeeIds: selectedCheckboxes,
								email: email
							}))
					}else{
						setShowFilterContent(prev => false);
						dispatch(
							emailVacationList({
								tenantId,
								from: todayDate,
								to: todayDate,
								employeeIds: selectedCheckboxes,
								email: email
							}))
					}
				break;
		}
	}

	const contentRef = useRef<HTMLDivElement>(null);
	const handlePrint = useReactToPrint({
		content: () => contentRef.current,
		pageStyle: () => pageStyle,
	});

	const beforePrint = (event) => {
		switch (modalOpener) {
			case "annualCertificateReport":
				selectedEmployee && selectedEmployee.value
					?
					handlePrint()
					:
					toast.warn(t('reports.chooseEmployee'));
				break;
			case "vacationListReport":
				handlePrint();
				break;
		}

	}

	return (
		<AppWrapper appContentClass={styles.reportsComponent}>
			<div className={styles.header}>
				<FaChevronLeft className={styles.arrowIcon} onClick={handleBackToReports} />
				<CommonHeader title={t('reports.employeeReports')} />
			</div>
			<div className={styles.reportsInner}>
				<ReportTemplateBox
					name={t('reports.yearCertificate')}
					onSubmit={() => openModal("annualCertificateReport")}
				/>
				<ReportTemplateBox
					name={t('reports.vacation')}
					onSubmit={() => openModal("vacationListReport")}
				/>
			</div>
			<ModalCustom
				show={showModal}
				onClose={() => {
					setTimeout(() => {
						setShowModal(prev => false);
						setModalOpener(prev => "");
					}, 400);
				}}
				contentClass={styles.contentClass}
				closeButtonClass={styles.closeButtonClass}
				submitText={t('reports.continue')}
				modalMainStyle={styles.modal}
				ref={contentRef}
				onSubmit={
					() => {
						setShowModal(prev => false);
						setTimeout(() => {
							setModalOpener(prev => "");
						}, 400);
					}
				}
				header={
					{
						"annualCertificateReport":
							<div className={`${styles.modalHeader} ${styles.sectionHeader} ${styles.columned}`}>
								<div className={styles.title}>
									<span className={styles.point}> ● </span>
									{t('reports.certificateContribution')}
								</div>
								<div className={`noPrint ${styles.buttonContainer}`}>
									<DropdownSelect
										data={downloadTypeOptions}
										buttonText={
											state?.loadingCertificateDownload
												?
												<div style={{ width: "117px" }}>
													<LoaderCentered style={{ width: "20px", height: "20px", margin: "auto" }} />
												</div>
												:
												<div className={styles.cell}>
													<div className={` ${styles.icon}`}>
														<FiDownload strokeWidth="2px" />
													</div>
													<div className={styles.dropdownText}>{t('reports.download')}</div>
												</div>
										}
										className={styles.headerDropdonw}
										menuClass={styles.menuClass}
										showArrow={true}
										noWidth={true}
										wideMenu={true}
										menuPosition="right"
										optionClick={handleDownload}
									/>
									{state.loadingCertificateEmail ?
										<div className={`${styles.cell} ${styles.emailHeaderCell}`} >
											<LoaderCentered style={{ width: "20px", height: "20px" }} />
										</div>
										:
										<div
											className={`${styles.cell} ${styles.emailHeaderCell}`}
											onClick={() => setIsEmailOpen(!isEmailOpen)}
										>
											<div className={` ${styles.icon} ${styles.emailIcon}`}> <FiDownload stroke="#89C732" /></div>
											<div>{t('salaryCalculation.sendEmail')}</div>
										</div>
									}
									<div
										className={styles.cell}
										onClick={beforePrint}
									>
										<div className={` ${styles.icon}`}><IconDocument stroke="#89C732" /></div>
										<div>{t('reports.print')}</div>
									</div>
									<div className={styles.dateCell} >
										Data: {todayDate}
									</div>
								</div>
							</div>,
						"vacationListReport":
							<div className={`${styles.modalHeader} ${styles.sectionHeader} ${styles.columned}`}>
								<div className={styles.title}>
									<span className={styles.point}> ● </span>
									{t('reports.vacationList')}
								</div>
								<div className={`noPrint ${styles.buttonContainer}`}>
									<DropdownSelect
										data={downloadTypeOptions}
										buttonText={
											state?.loadingVacationListDownload
												?
												<div style={{ width: "117px" }}>
													<LoaderCentered style={{ width: "20px", height: "20px", margin: "auto" }} />
												</div>
												:
												<div className={styles.cell}>
													<div className={` ${styles.icon}`}>
														<FiDownload strokeWidth="2px" />
													</div>
													<div className={styles.dropdownText}>{t('reports.download')}</div>
												</div>
										}
										className={styles.headerDropdonw}
										menuClass={styles.menuClass}
										showArrow={true}
										showShadow={true}
										noWidth={true}
										wideMenu={true}
										menuPosition="right"
										optionClick={handleDownload}
									/>
									{state.loadingVacationListEmail ?
										<div className={`${styles.cell} ${styles.emailHeaderCell}`} >
											<LoaderCentered style={{ width: "20px", height: "20px" }} />
										</div>
										:
										<div
											className={`${styles.cell} ${styles.emailHeaderCell}`}
											onClick={() => setIsEmailOpen(!isEmailOpen)}
										>
											<div className={` ${styles.icon} ${styles.emailIcon}`}> <FiDownload stroke="#89C732" /></div>
											<div>{t('salaryCalculation.sendEmail')}</div>
										</div>
									}
									<div className={styles.cell} onClick={beforePrint} >
										<div className={` ${styles.icon}`}><IconDocument stroke="#89C732" /></div>
										<div>{t('reports.print')}</div>
									</div>
									<div className={styles.dateCell} >
										Data: {todayDate}
									</div>
								</div>
							</div>

					}[modalOpener]
				}
			>
				{{
					"annualCertificateReport":
						<>
							<div className={styles.inputsContainer}>
								<InputReports
									text={t("reports.nameEmployeer")}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={tenantData?.tenantName}
								/>
								<InputReports
									text="NUI"
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={tenantData?.NRB}
								/>
								<InputReports
									text={t('reports.adress')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={tenantData?.tenantAddress}
								/>
								<InputReports
									text={t('reports.owner')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={tenantData?.authorizedPerson}
								/>
								<InputReports
									text={t('reports.telephone')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={tenantData?.tenantPhone}
								/>

							</div>
							<div className={styles.sectionHeader}>
								<div className={`${styles.title} ${styles.second}`}>
									<span className={styles.point}> ● </span>
									{t('reports.employed')}
								</div>
							</div>
							<div className={styles.inputsContainer}>
								<div className={`${styles.employeeInput} ${styles.input}`} title={selectedEmployee?.name}>
									<div className={styles.desc}>{t('reports.nameOfEmployed')}</div>
									<CustomSelect
										options={employeeList}
										placeholder={t('reports.chooseEmployee')}
										wideMenu={true}
										components={{ DropdownIndicator }}
										dropdownIndicatorStyle={{ width: "35px", }}
										onChangeHandler={(e) => {
											setSelectedEmployee(prev => e);
										}}
										attributes={{
											classNamePrefix: "reportDropdown"
										}}
										controlStyle={{
											minWidth: "200px",
											borderRadius: "10px",
											border: "1px solid #89c732",
											fontWeight: "500",
											height: "fit-content",
											alignItems: "center",
											minHeight: "57px",
										}}
										menuStyle={{ borderRadius: "10px", top: "52px" }}
										menuListStyle={{
											boxShadow: "0px 2px 10px #00000027",
											backgroundColor: "#fff",
										}}
										optionsStyle={{
											height: "50px",
											display: "flex",
											alignItems: "center",
											fontSize: "15px",
											opacity: "1",
										}}
										valueContainerStyle={{
											fontSize: "1.2em",
											minHeight: "fit-content",
											height: "fit-content",
											overflow: "unset",
											padding: "3px"
										}}
										singleValueStyle={{
											minHeight: "fit-content",
											display: "flex",
											whiteSpace: "normal",
											position: "relative",
											top: "unset",
											transform: "unset",
										}}
									/>
								</div>

								<InputReports
									text={t('reports.personalNumber')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={employeeData?.personalNumber}
									loading={state.loadingEmployeeData}
								/>
								<InputReports
									text={t('reports.adressEmployee')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={employeeData?.address}
									loading={state.loadingEmployeeData}
								/>
								<InputReports
									text={t('reports.telephone')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={employeeData?.phone}
									loading={state.loadingEmployeeData}
								/>

								<InputReports
									text={t('reports.taxPeriod')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={employeeData?.year}
									loading={state.loadingEmployeeData}
								/>

								<InputReports
									text={t('reports.totalGross')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={employeeData?.bruto}
									loading={state.loadingEmployeeData}
								/>
								<InputReports
									text={t('reports.employeeContribute')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={employeeData?.totalPersonalContribute}
									loading={state.loadingEmployeeData}
								/>

								<InputReports
									text={t('reports.employerContribute')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={employeeData?.totalEmployerContribute}
									loading={state.loadingEmployeeData}
								/>

								<InputReports
									text={t('reports.totalTax')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={employeeData?.totalTaxes}
									loading={state.loadingEmployeeData}
								/>

								<InputReports
									text={t('reports.totalAdds')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={employeeData?.totalExtras}
									loading={state.loadingEmployeeData}
								/>

								<InputReports
									text={t('reports.totalNet')}
									className={styles.input}
									inputClass={styles.inputClass}
									static={true}
									value={employeeData?.totalNetto}
									loading={state.loadingEmployeeData}
								/>
							</div>
							<div className={styles.agreementRow}>
								<Checkbox
									className={styles.checkbox}
									icon={
										<div className={styles.checkboxIconContainer} >
											<FiCheck color="white" size={20} />
										</div>
									}
									checked={checkedAgreement || false}
									onChange={(checked) => setCheckedAgreement(checked)}
									borderRadius={"50%"}
									borderColor={checkedAgreement ? "transparent" : "#89C732"}
								/>

								<div className={styles.agreementText}>
									{t('reports.declare')}
								</div>
							</div>
						</>,
					"vacationListReport":
						<div className={styles.vacationListReport}>
							<div className={`row noPrint ${styles.filterRow} ${styles.filterRow}`}>


							<div className={styles.fromToBoxContainer}>
							<div className={`${styles.fromBoxContainer} noPrint`}>
							<div className={styles.inputContainer}>
                          <Select
                            styles={{
                              control: (base) => {
                                return {
                                  ...base,
                                  width: 250,
                                  border: "0px",
                                  borderRadius: 16,
								  marginRight: 10,
								  marginTop: 10,
                                  fontWeight: 500,
                                  boxShadow: "2px 8px 16px #18327314",
                                };
                              },
                            }}
							options={(state?.vacationList?.data ?? [])?.map((vacationItem) => { let item = vacationItem.employeeName; return { label: item, value: item } })}
                            value={filteredEmployeeName}
                            onChange={(option) => {
                              setFilteredEmployeeName(option);
                            }}
                            isClearable
                            noOptionsMessage={() =>
                              "Nuk u gjet asnje punetor per filtrim"
                            }
                            placeholder="Filtro sipas punetorit . . ."
                          />
                        </div>
							</div>
								<div className={styles.fromBoxContainer}>
									<div className={styles.fromStyle}>Nga</div>
									<input type="date" value={from} onChange={(event) => setFrom(event.target.value)} />
								</div>
								<div className={styles.toBoxContainer}>	
								<div className={styles.toStyle}>Deri</div>
									<input type="date" value={to} onChange={(event) => setTo(event.target.value)} />
								</div>
							</div>
							{showFilterContent &&
								<div className={styles.filterContent} ref={periodWrapperRef}>
									<div className={styles.months} ref={monthsWrapperRef}>
										{monthsList.map((item, index) => {
											return <div
												key={index}
												className={`${styles.periodItem} ${index === pickedMonth ? styles.selected : ""}`}
												onClick={() => { setPickedMonth(prev => index) }}
											>
												{item}
											</div>
										})}
									</div>
									<div className={styles.years}>
										{yearsList.map((item, index) => {
											return <div
												key={index}
												className={`${styles.periodItem} ${item === pickedYear ? styles.selected : ""}`}
												onClick={() => { setPickedYear(prev => +item) }}
											>
												{item}
											</div>
										})}
									</div>
								</div>
							}
							</div>
							{
								showVacationListReport
								&&
								<VacationList
									data={state}
									filteredEmployeeName={filteredEmployeeName?.value}
									selectedCheckboxes={selectedCheckboxes}
									setSelectedCheckboxes={setSelectedCheckboxes}
								/>
							}
						</div>,
				}[modalOpener]}
			</ModalCustom >

			<ModalCustom
				contentClass={styles.sendEmailWrapper}
				show={isEmailOpen}
				hideFooterButton={false}
				submitLoading={isEmailOpen && isSendEmailLoading || !isEmailOpen}
				submitText={isEmailOpen ? 'Dërgo' : `${t('reports.save')}`}
				footerClass={styles.footerClass}
				onClose={handleOpenModal}
				onSubmit={(e) => handleEmail(e)}
			>
			{isEmailOpen ? <div className={styles.emailBox} onKeyDown={(e) => {
				const inputCondition = e.key === 'Enter';
			}}>
				<InputCustom
					onChangeHandler={handleEmailChange}
					className={styles.rowInputEmail}
					name="occupation"
					value={email}
					placeholder={'Enter e-mail'}
					attributes={{ autoFocus: true, }}
					type="text"
				/>
				{!isEmailValid && email !== "" ? <span className={styles.validateEmail}>{emailValidMessage}</span> : null}
			</div> : null}
			</ModalCustom>
		</AppWrapper >
	);
}

export default EmployeeReports;