import React, { FunctionComponent } from 'react';

import styles from "./Card.module.scss";

interface CardTypes {
    children?: React.ReactNode;
    className?: string;
    titleClass?: string;
    cardContentClass?: string;
    contentClass?: string;
    title?: string;
}

const Card: FunctionComponent<CardTypes> = (props) => {
    return (
        <div className={`${styles.card} ${props.className || ""}`}>
            <div className={`${styles.title} ${props.titleClass || ""}`}>
                {props.title}
            </div> 
            <div className={`${styles.content} ${props.cardContentClass || ""}`}>
                {props.children}
            </div>
        </div>
    );
};

export default Card;

