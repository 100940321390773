
import * as constants from "./constants";

export const getGlobalSearchedData = (payload) => {
	return {
		type: constants.GET_GLOBAL_SEARCHED_DATA,
		payload
	}
}
export const getGlobalSearchedDataSuccess = (payload) => {
	return {
		type: constants.GET_GLOBAL_SEARCHED_DATA_SUCCESS,
		payload
	}
}
export const getGlobalSearchedDataError = (payload) => {
	return {
		type: constants.GET_GLOBAL_SEARCHED_DATA_ERROR,
		payload
	}
}