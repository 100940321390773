import React, { PureComponent } from 'react';
import AppWrapper from 'components/AppWrapper/AppWrapper.component';
import styles from "./HolidayModalComponent.module.scss";
import calendarStyles from "./Calendar.module.scss";
import { FunctionComponent, useEffect, useState, useRef } from 'react';
import SearchInput from 'components/SearchInput/SearchInput.component';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { loadAllEmployeesAndVacations, loadAllCalendarVacations, loadAllVacationTypes, loadNewVacation, loadDeleteVacation, loadUpdateVacation, loadSingleVacation } from '../../pages/Holidays/actions';
import FullCalendar, { EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'
import { EmployeeVactions } from 'types/employeeVactions.type';
import ModalCustom from 'components/ModalCustom/ModalCustom.component';
import classNames from 'classnames';
import { IconPencil } from 'assets/icons/icons';
import { FiTrash2 } from 'react-icons/fi';
import Button from 'components/Button/Button.component';
import { collapseSidebarMenu } from 'components/AppWrapper/actions';
import { globalState } from 'types/globalState.type';

interface Props {
    employeeId?: number
}

const HolidayModalComponent: FunctionComponent <Props> = (props) => {

	const tenantId = useSelector((state: globalState) => state.globalReducer.tenantId);

	const dispatch = useDispatch();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
	const [isAddVacationModalOpen, setIsAddVacationModalOpen] = useState<boolean>(false);
	const [isDeleteVacationModalOpen, setIsDeleteVacationModalOpen] = useState<boolean>(false);
	const [employeeVacation, setEmployeeVacation] = useState<EmployeeVactions>();
	const [selectedVacationType, setSelectedVacationType] = useState<number>(0);
	const [vacationIdToDelete, setVacationIdToDelete] = useState<number>();
	const [from, setFrom] = useState<any>(new Date());
	const [to, setTo] = useState<any>(new Date());
	const [editVacationMode, setEditVacationMode] = useState<boolean>(false);
	const [selectedVacation, setSelectedVacation] = useState<any>();
	const [employeeVacationType, setEmployeeVacationType] = useState<any>(0);

	const allVacations = useSelector((state: RootStateOrAny) => state.vacationReducer.allVacations);
	const isDeletingLoader = useSelector((state: RootStateOrAny) => state.vacationReducer.isDeletingLoader);
	const createLoading = useSelector((state: RootStateOrAny) => state.vacationReducer.createLoading);
	const updateLoading = useSelector((state: RootStateOrAny) => state.vacationReducer.updateLoading);
	const allCalendarVacations = useSelector((state: RootStateOrAny) => state.vacationReducer.allCalendarVacations);
	const allVacationTypes = useSelector((state: RootStateOrAny) => state.vacationReducer.allVacationTypes.data);
	const isVacationAdded = useSelector((state: RootStateOrAny) => state.vacationReducer.isVacationAdded);
    const singleVacation = useSelector((state: RootStateOrAny) => state.vacationReducer.singleVacation);

	const initialLoad = () => {
		dispatch(loadAllVacationTypes());
		dispatch(collapseSidebarMenu(true));
        dispatch(loadSingleVacation(props?.employeeId))
	}

	useEffect(() => {
		initialLoad();
	}, [])

	useEffect(() => {
		dispatch(loadAllEmployeesAndVacations(tenantId));
	}, [isVacationAdded])

	const employeeVacationModal = (employee) => {
		setEmployeeVacation(employee);
		setIsModalOpen(true);
	}

	const addEmployeeVacationModal = () => {
		setIsAddVacationModalOpen(true)
		setIsModalOpen(false)
	}

	const deleteEmployeeVacationModal = (vacationId) => {
		setIsModalOpen(false)
		setIsDeleteVacationModalOpen(true)
		setVacationIdToDelete(vacationId)
	}

	const editVacationModal = (vacation) => {
		setSelectedVacation(vacation);
		setEmployeeVacationType(vacation?.typeId)
		dispatch(loadSingleVacation(vacation?.id))
		setEditVacationMode(true);
		setSelectedVacation(vacation);
		setFrom(vacation?.from)
		setTo(vacation?.to)
	}

	const vacationTypeColorClasses = (type) => {
		return classNames({
		  [styles.medicalLeave]: type === "Shëndetësor",
		  [styles.maternityLeave]: type === "Lehonisë",
		  [styles.annualLeave]: type === "Vjetor",
		  [styles.specialLeave]: type === "Special",
		});
	  };

	const handleSelectedVacationType = (e) => {
		setEmployeeVacationType(e.target.value)
	}

	const handleSelectedVacationTypeOnCreate = (e) => {
		setSelectedVacationType(e.target.value)
	}

	const allEmployeeVacations: EventInput[] = allCalendarVacations;

	const handleNewVacationData = (employeeVacation) => {
		const newVacation = {
			tenantId: employeeVacation.tenantId,
			employeeId: employeeVacation.id,
			type: selectedVacationType,
			from: from,
			to: to
		}

		dispatch(loadNewVacation(newVacation));
		setIsAddVacationModalOpen(false);
		setIsModalOpen(true);
		setSelectedVacationType(0);
		setFrom('');
		setTo('');
	}

	const handleUpdatedVacationData = (vacationId) => {
		const updatedVacation = {
			id: vacationId,
			type: employeeVacationType,
			from: from,
			to: to
		}

		dispatch(loadUpdateVacation(updatedVacation));
		setEditVacationMode(false);
		setFrom('');
		setTo('');
		dispatch(loadAllEmployeesAndVacations(tenantId));
	}

	const handleDeleteEmployeeVacation = (employeeVacation) => {
		dispatch(loadDeleteVacation(employeeVacation));
		setIsDeleteVacationModalOpen(false);
		setIsModalOpen(false);
		dispatch(loadAllEmployeesAndVacations(tenantId));
	}

	return (
        <div className={styles.holidaysComponent}>
			<div className={styles.employeeContainer}>
				<div className={styles.employeesTable}>
				</div>
				<div className={styles.calendarContainer}>
						<ModalCustom
							show={isModalOpen}
							contentClass={styles.contentClass}
							footerClass={styles.footerClass}
							onClose={() => {
								setIsModalOpen(false)
							}}
							hideFooterButton={true}
						>
							<div className={styles.modalContainer}>
								<div className={styles.modalContainerInner}>
									<div className={styles.employeeNameModal}>
										{singleVacation?.name}
									</div>
								</div>
								<div className={styles.employeeEmailModal}>
									{singleVacation?.email}
								</div>
								<div className={styles.employeeTenantNameModal}>
									{singleVacation?.tenantName}
								</div>
								{allVacations?.map((employee: EmployeeVactions, idx: number) => {
									console.log(employee, 'emp')
										return <React.Fragment key={idx}>
											{employee?.vacations?.length > 0 && employee?.vacations?.map((vacation, idx) => {
												if (editVacationMode) {
													if (selectedVacation.id === vacation.id) {
														return <div className={`${styles.vacationsAppendedModal}`} key={idx}>
															<div className={styles.vacationTypeEdit}>
																<div className={styles.vacationContainer}>
																	<select style={employeeVacationType == 0 ? { color: '#2DCE98' }
																		: employeeVacationType == 1 ? { color: '#FEB969' }
																			: employeeVacationType == 2 ? { color: '#11CDEF' }
																				: employeeVacationType == 3 && { color: '#D52D0F' }} className={`${styles.vacationTypeDropdownEdit}`} defaultValue={selectedVacation?.typeId} onChange={handleSelectedVacationType}>
																		{allVacationTypes?.map((type, idx) => {
																			return <option className={vacationTypeColorClasses(type)} value={idx} key={idx} >{type}</option>
																		})}
																	</select>
																</div>
																<div className={styles.fromToContainer}>
																	<div className={styles.from}>
																		Nga
																	</div>
																	<div className={styles.to}>
																		Deri
																	</div>
																</div>
																<div className={styles.fromToBoxContainer}>
																	<div className={styles.fromBoxContainer}>
																		<input type="date" value={from?.slice(0, 10)} onChange={(event) => setFrom(event.target.value)} />
																	</div>
																	<div className={styles.toBoxContainer}>
																		<input type="date" value={to?.slice(0, 10)} onChange={(event) => setTo(event.target.value)} />
																	</div>
																</div>
																<div className={styles.saveButton}>
																	<Button onClickHandler={() => handleUpdatedVacationData(vacation?.id)} loading={updateLoading}>
																		Ruaj
																	</Button>
																</div>
															</div>
														</div>
													} else {
														return null
													}
												} else {
													return <div className={`${styles.vacationsAppendedModal}`} key={idx}>
														<React.Fragment>
															<div className={styles.vacationType}>
																<div className={styles.vacationModalType}>
																	<div className={styles.vacationTypeInner}>
																		Lloji i pushimit:
																	</div>

																	<div className={styles.vacationTypeName}>
																		<div className={vacationTypeColorClasses(vacation?.type)}>
																			{vacation?.type}
																		</div>
																	</div>
																</div>
															</div>
															<div className={styles.fromToContainer}>
																<div className={styles.from}>
																	Nga
																</div>
																<div className={styles.to}>
																	Deri
																</div>
															</div>
															<div className={styles.fromToBoxContainer}>
																<div className={styles.fromBoxContainer}>
																	<input type="date" readOnly={!editVacationMode} value={editVacationMode ? from : vacation?.from.slice(0, 10)} onChange={(event) => setFrom(event.target.value)} />
																</div>
																<div className={styles.toBoxContainer}>
																	<input type="date" readOnly={!editVacationMode} value={editVacationMode ? to : vacation?.to.slice(0, 10)} onChange={(event) => setTo(event.target.value)} />
																</div>
															</div>
														</React.Fragment>
													</div>
												}

											})}
										</React.Fragment>
								})
								}
							</div>
						</ModalCustom>
                    </div>
                    
					{isDeleteVacationModalOpen ?
						<ModalCustom
							show={isDeleteVacationModalOpen}
							contentClass={styles.contentClass}
							submitLoading={isDeletingLoader}
							footerClass={styles.footerClass}
							onClose={() => {
								setIsDeleteVacationModalOpen(prev => false)
							}}
							hideFooterButton={false}
							submitText={"Po"}
							onSubmit={() => handleDeleteEmployeeVacation(vacationIdToDelete)}
						>
							<div className={styles.deleteVacationMessage}>
								Jeni të sigurtë që doni të fshini pushimin ?
							</div>
						</ModalCustom> : null}
			</div>
        </div>
	);
}

export default HolidayModalComponent;