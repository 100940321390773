import { useTranslation } from 'react-i18next';
import produce from 'immer';
import {
    LOAD_ANNUAL_CERTIFICATE,
    LOAD_ANNUAL_CERTIFICATE_SUCCESS,
    LOAD_ANNUAL_CERTIFICATE_ERROR,
    LOAD_EMPLOYEE_DATA,
    LOAD_EMPLOYEE_DATA_SUCCESS,
    LOAD_EMPLOYEE_DATA_ERROR,
    CERTIFICATE_PDF,
    CERTIFICATE_PDF_SUCCESS,
    CERTIFICATE_PDF_ERROR,
    EXPORT_CERTIFICATE,
    EXPORT_CERTIFICATE_SUCCESS,
    EXPORT_CERTIFICATE_ERROR,
    EMAIL_CERTIFICATE,
    EMAIL_CERTIFICATE_SUCCESS,
    EMAIL_CERTIFICATE_ERROR,
    LOAD_VACATION_LIST,
    LOAD_VACATION_LIST_SUCCESS,
    LOAD_VACATION_LIST_ERROR,
    EMAIL_VACATION_LIST,
    EMAIL_VACATION_LIST_SUCCESS,
    EMAIL_VACATION_LIST_ERROR,
    VACATION_LIST_PDF,
    VACATION_LIST_PDF_SUCCESS,
    VACATION_LIST_PDF_ERROR,
    EXPORT_EXCEL_VACATION_LIST,
    EXPORT_EXCEL_VACATION_LIST_SUCCESS,
    EXPORT_EXCEL_VACATION_LIST_ERROR,
} from "./constants";
import { toast } from 'react-toastify';
import { EmployeeReportsState } from "types/reducers/reports.reducer.type";

const initialState: EmployeeReportsState = {
    loadingCertificate: false,
    loadingHolidays: false,
    loadingEmployeeData: false,
    loadingCertificateDownload: false,
    loadingCertificateEmail: false,
    loadingVacationList: false,
    loadingVacationListDownload: false,
    loadingVacationListEmail: false,
    vacationList: null,
    vacationCount: null,
}

const employeeReportsReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case LOAD_ANNUAL_CERTIFICATE:
                draft.loadingCertificate = true;
                draft.tenantData = initialState.tenantData;
                break;
            case LOAD_ANNUAL_CERTIFICATE_SUCCESS:
                draft.tenantData = action.payload;
                draft.loadingCertificate = false;
                break;
            case LOAD_ANNUAL_CERTIFICATE_ERROR:
                draft.tenantData = initialState.tenantData;
                draft.loadingCertificate = false;
                toast.error('Diçka shkoi gabim!');
                break;

            case LOAD_EMPLOYEE_DATA:
                draft.loadingEmployeeData = true;
                draft.employeeData = initialState.employeeData;
                break;
            case LOAD_EMPLOYEE_DATA_SUCCESS:
                draft.employeeData = action.payload;
                draft.loadingEmployeeData = false;
                break;
            case LOAD_EMPLOYEE_DATA_ERROR:
                draft.loadingEmployeeData = false;
                draft.employeeData = initialState.employeeData;
                toast.error('Diçka shkoi gabim!');
                break;

            case EMAIL_CERTIFICATE:
                draft.loadingCertificateEmail = true;
                break;
            case EMAIL_CERTIFICATE_SUCCESS:
                draft.loadingCertificateEmail = false;
                toast.success("Emaili u dërgua me sukses!");
                break;
            case EMAIL_CERTIFICATE_ERROR:
                draft.loadingCertificateEmail = false;
                toast.error('Diçka shkoi gabim!');
                break;

            case CERTIFICATE_PDF:
                draft.loadingCertificateDownload = true;
                break;
            case CERTIFICATE_PDF_SUCCESS:
                window.open(action.payload, "_blank");
                draft.loadingCertificateDownload = false;
                break;
            case CERTIFICATE_PDF_ERROR:
                draft.loadingCertificateDownload = false;
                toast.error('Diçka shkoi gabim!');
                break;

            case EXPORT_CERTIFICATE:
                draft.loadingCertificateDownload = true;
                break;
            case EXPORT_CERTIFICATE_SUCCESS:
                window.open(action.payload, "_blank");
                draft.loadingCertificateDownload = false;
                break;
            case EXPORT_CERTIFICATE_ERROR:
                draft.loadingCertificateDownload = false;
                toast.error('Diçka shkoi gabim!');
                break;

            case LOAD_VACATION_LIST:
                draft.loadingVacationList = true;
                break;
            case LOAD_VACATION_LIST_SUCCESS:
                draft.vacationList = action.payload;
                draft.loadingVacationList = false;
                break;
            case LOAD_VACATION_LIST_ERROR:
                draft.loadingVacationList = false;
                toast.error('Diçka shkoi gabim!');
                break;

            case EMAIL_VACATION_LIST:
                draft.loadingVacationListEmail = true;
                break;
            case EMAIL_VACATION_LIST_SUCCESS:
                draft.loadingVacationListEmail = false;
                toast.success('Emailet u dërguan me sukses!');
                break;
            case EMAIL_VACATION_LIST_ERROR:
                draft.loadingVacationListEmail = false;
                toast.error('Diçka shkoi gabim!');
                break;

            case VACATION_LIST_PDF:
                draft.loadingVacationListDownload = true;
                break;
            case VACATION_LIST_PDF_SUCCESS:
                window.open(action.payload, "_blank");
                draft.loadingVacationListDownload = false;
                break;
            case VACATION_LIST_PDF_ERROR:
                draft.loadingVacationListDownload = false;
                toast.error('Diçka shkoi gabim!');
                break;

            case EXPORT_EXCEL_VACATION_LIST:
                draft.loadingVacationListDownload = true;
                break;
            case EXPORT_EXCEL_VACATION_LIST_SUCCESS:
                window.open(action.payload, "_blank");
                draft.loadingVacationListDownload = false;
                break;
            case EXPORT_EXCEL_VACATION_LIST_ERROR:
                draft.loadingVacationListDownload = false;
                toast.error('Diçka shkoi gabim!');
                break;

            default:
                break;
        }
    });

export default employeeReportsReducer;