import { EmploymentTier } from "types/employee.type";
import { formatNumber_ } from "./functions";
import {
  CalculateSalary,
  calculateSalary as calculateSalaryService,
} from "../services/salaries.service";

export enum SalaryCalculationSource {
  Gross = 1,
  Net = 2,
}

export const calculateSalary: any = async (data: CalculateSalary) => {
  try {
    const response = await calculateSalaryService(data);
    return {
      bruto: formatNumber_(response.data.gross, 2),
      neto: formatNumber_(response.data.net, 2),
      tax: formatNumber_(response.data.tax, 2),
      contribution: formatNumber_(response.data.contribution, 2),
      healthInsurancePercentage: formatNumber_(
        response.data.healthInsurancePercentage,
        2
      ),
      healthInsuranceValue: formatNumber_(
        response.data.healthInsuranceValue,
        2
      ),
    };
  } catch (err) {
    console.error("couldnt calculate the salary from the api", err);
    return calcSalary(
      data.gross != null ? data.net : data.gross,
      data.contributionPercentage,
      data.gross != null
        ? SalaryCalculationSource.Gross
        : SalaryCalculationSource.Net,
      data.employerTier,
      !data.noTaxes
    );
  }
};

const calcSalary = (
  setSalary: number = 100,
  ECP: number = 5,
  salaryCalculationSource: SalaryCalculationSource = SalaryCalculationSource.Gross,
  employmentTier: EmploymentTier = EmploymentTier.Primary,
  hasTaxes: boolean = true
) => {
  let returnObject = {
    bruto: "",
    neto: "",
    contribution: "0.00",
    tax: "0.00",
  };

  let ECPVar = (setSalary * ECP) / 100;
  let taxedSalary = setSalary - ECPVar;
  let totalTaxes = 0;
  let taxedSalaryVar = taxedSalary;
  let up_to_80 = 0;
  let up_to_80_tax = 0;
  let up_to_250 = 0;
  let up_to_250_tax = 0;
  let up_to_450 = 0;
  let up_to_450_tax = 0;
  let over_450_tax = 0;

  if (salaryCalculationSource === 1) {
    if (employmentTier === 1) {
      if (taxedSalary <= 80) up_to_80 = taxedSalary;
      else up_to_80 = 80;

      taxedSalaryVar = taxedSalaryVar - up_to_80;
      if (taxedSalaryVar <= 170) up_to_250 = taxedSalaryVar;
      else up_to_250 = 170;

      up_to_250_tax = up_to_250 * 0.04;
      taxedSalaryVar = taxedSalaryVar - up_to_250;
      if (taxedSalaryVar <= 200) up_to_450 = taxedSalaryVar;
      else up_to_450 = 200;

      up_to_450_tax = up_to_450 * 0.08;
      taxedSalaryVar = taxedSalaryVar - up_to_450;
      over_450_tax = taxedSalaryVar * 0.1;
      returnObject = {
        bruto: formatNumber_(setSalary, 2),
        neto: (
          Math.floor(
            formatNumber_(
              hasTaxes
                ? taxedSalary -
                    (over_450_tax +
                      up_to_450_tax +
                      up_to_250_tax +
                      up_to_80_tax)
                : taxedSalary,
              3
            ) * 100
          ) / 100
        ).toFixed(2),
        contribution: formatNumber_(ECPVar, 2),
        tax: formatNumber_(
          hasTaxes
            ? over_450_tax + up_to_450_tax + up_to_250_tax + up_to_80_tax
            : 0,
          2
        ),
      };
    } else if (employmentTier === 2) {
      returnObject = {
        bruto: formatNumber_(setSalary, 2),
        neto: formatNumber_(
          hasTaxes ? taxedSalary - taxedSalary * 0.1 : taxedSalary,
          2
        ),
        contribution: formatNumber_(ECPVar, 2),
        tax: formatNumber_(hasTaxes ? taxedSalary * 0.1 : 0, 2),
      };
    }
  } else if (salaryCalculationSource === 2) {
    let brutoSalary = 0;

    ECPVar = ECP / 100;
    totalTaxes = 0;
    up_to_80 = 0;
    up_to_80_tax = 0;
    up_to_250 = 0;
    up_to_250_tax = 0;
    up_to_450 = 0;
    up_to_450_tax = 0;
    over_450_tax = 0;

    if (employmentTier === 1) {
      up_to_80_tax = (80 * 0) / 100;
      if (setSalary > 80) {
        up_to_250_tax = ((250 - 80) * 4) / 100;
        if (setSalary > 250) {
          up_to_450_tax = ((450 - 250) * 8) / 100;
        }
      }

      var tax_UpTo_450 = up_to_450_tax + up_to_250_tax + up_to_80_tax;
      var PN = setSalary;

      if (setSalary < 450) {
        ECPVar = ECP / 100;
        brutoSalary = 0;
        let leftOver = setSalary;
        totalTaxes = 0;
        up_to_80 = 0;
        up_to_80_tax = 0;
        up_to_250 = 0;
        up_to_250_tax = 0;
        up_to_450 = 0;
        up_to_450_tax = 0;
        let over_450 = 0;
        over_450_tax = 0;

        if (employmentTier === 1) {
          if (setSalary <= 80) {
            up_to_80 = setSalary;
          } else {
            up_to_80 = 80;
          }

          leftOver = leftOver - up_to_80;
          up_to_80_tax = up_to_80 * 0;

          if (leftOver > 0) {
            if (leftOver / 0.96 <= 170) {
              up_to_250 = leftOver / 0.96;
            } else {
              up_to_250 = 170;
            }

            leftOver = leftOver - up_to_250;
            up_to_250_tax = up_to_250 * 0.04;
          }

          if (leftOver > 0) {
            if ((setSalary + up_to_250_tax - 250) / 0.92 <= 200) {
              up_to_450 = (setSalary + up_to_250_tax - 250) / 0.92;
            } else {
              up_to_450 = 200;
            }
            leftOver = leftOver - up_to_450;
            up_to_450_tax = up_to_450 * 0.08;
          }

          if (leftOver > 0) {
            if (up_to_450 === 200) {
              over_450 =
                (setSalary + up_to_450_tax + up_to_250_tax - 450) / 0.9;
            } else {
              over_450 = 0;
            }
            over_450_tax = over_450 * 0.1;
          }
          totalTaxes =
            over_450_tax + up_to_450_tax + up_to_250_tax + up_to_80_tax;

          let salaryForTax = salaryForTaxNettoToBrutto(setSalary);
          brutoSalary = salaryForTax / (1 - 0.05);
        }
      } else {
        brutoSalary =
          ((PN * 10) / 9 - (45 * 10) / 9 + (tax_UpTo_450 * 10) / 9) /
          (1 - ECP / 100);
      }
      let totalContribution = (ECP * brutoSalary) / 100;
      totalTaxes = brutoSalary - totalContribution - setSalary;

      ECPVar = (setSalary * ECP) / 100;
      taxedSalary = setSalary - ECPVar;

      returnObject = {
        bruto: formatNumber_(
          hasTaxes ? brutoSalary : setSalary + totalContribution,
          2
        ),
        neto: formatNumber_(setSalary, 2),
        contribution: formatNumber_(totalContribution, 2),
        tax: formatNumber_(hasTaxes ? totalTaxes : 0, 2),
      };
    } else if (employmentTier === 2) {
      brutoSalary = setSalary / (1 - ECPVar - (1 - ECPVar) * 0.1);
      let totalContribution = (ECP * brutoSalary) / 100;
      totalTaxes = brutoSalary - totalContribution - setSalary;

      returnObject = {
        bruto: formatNumber_(
          hasTaxes ? brutoSalary : setSalary + totalContribution,
          2
        ),
        neto: formatNumber_(setSalary, 2),
        contribution: formatNumber_(totalContribution * 2, 2),
        tax: formatNumber_(hasTaxes ? totalTaxes : 0, 2),
      };
    }
  }
  returnObject.bruto = Number(returnObject.bruto).toFixed(2).toString();
  returnObject.neto = Number(returnObject.neto).toFixed(2).toString();
  returnObject.contribution = Number(returnObject.contribution)
    .toFixed(2)
    .toString();
  returnObject.tax = Number(returnObject.tax).toFixed(2).toString();
  return returnObject;
};

export function nettoToBruttoSalary(netto) {
  let salaryForTax = salaryForTaxNettoToBrutto(netto);
  let brutto = salaryForTax / (1 - 0.05);
  return formatNumber_(brutto, 2);
}

function salaryForTaxNettoToBrutto(netto) {
  let taxNettoToBrutto = taxNettoToBrutto_FN(netto);
  let salaryForTax = netto + taxNettoToBrutto;
  return salaryForTax;
}

function taxNettoToBrutto_FN(neto) {
  let tax1 = 0,
    data1 = 0,
    data2 = 0,
    data3 = 0,
    data4 = 0,
    tax2 = 0,
    tax3 = 0,
    tax4 = 0,
    sumTax = 0;

  neto <= 80 ? (tax1 = neto) : (tax1 = 80);
  data1 = tax1 * 0;

  (neto - tax1) / 0.96 <= 170 ? (tax2 = (neto - tax1) / 0.96) : (tax2 = 170);
  data2 = tax2 * 0.04;

  if (tax2 === 170) {
    (neto + data2 - 250) / 0.92 <= 200
      ? (tax3 = (neto + data2 - 250) / 0.92)
      : (tax3 = 200);
  } else {
    tax3 = 0;
  }
  data3 = tax3 * 0.08;
  tax3 === 200 ? (tax4 = (neto + data2 + data3 - 450) / 0.9) : (tax4 = 0);
  data4 = tax4 * 0.1;
  sumTax = data1 + data2 + data3 + data4;
  return sumTax;
}

export default calcSalary;
