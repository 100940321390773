import { FunctionComponent, MouseEventHandler } from 'react';
import styles from "./IconWrapper.module.scss";

interface iconWrapperProps {
    children?: React.ReactNode;
    clickHandler?: MouseEventHandler;
    className?: string;
}

export const IconWrapper: FunctionComponent<iconWrapperProps> = (props) => {
    return (
        <div className={`${styles.iconWrapper} ${props.className || ""}`} onClick={props.clickHandler}>
            {props.children}
        </div>
    );
}
