import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { 
    LOAD_VACATIONS_ALL, 
    LOAD_VACATIONS_ALL_SUCCESS,
    LOAD_VACATIONS_ALL_ERROR,
    LOAD_CALENDAR_VACATIONS_ALL,
    LOAD_CALENDAR_VACATIONS_SUCCESS,
    LOAD_CALENDAR_VACATIONS_ERROR,
    LOAD_VACATION_TYPES_ALL,
    LOAD_VACATION_TYPES_ALL_SUCCESS,
    LOAD_VACATION_TYPE_ALL_ERROR,
    LOAD_NEW_VACATION,
    LOAD_NEW_VACATION_SUCCESS,
    LOAD_NEW_VACATION_ERROR,
    LOAD_DELETE_VACATION,
    LOAD_DELETE_VACATION_SUCCESS,
    LOAD_DELETE_VACATION_ERROR,
    LOAD_UPDATE_VACATION,
    LOAD_UPDATE_VACATION_SUCCESS,
    LOAD_UPDATE_VACATION_ERROR,
    LOAD_SINGLE_VACATION,
    LOAD_SINGLE_VACATION_SUCCESS,
    LOAD_SINGLE_VACATION_ERROR,
} from './constants';
import { toast } from 'react-toastify';

const initialState: any = {
    loading: false,
    allVacations: [],
    allCalendarVacations: [],
    allVacationTypes: [],
    newVacation: null,
    updatedVacation: null,
    singleVacation: null,
    isDeletingLoader: false,
    createLoading: false,
    updateLoading: false,
    isVacationAdded:false,
}

const vacationReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case LOAD_VACATIONS_ALL:
                draft.loading = true;
            break;
            case LOAD_VACATIONS_ALL_SUCCESS:
                draft.allVacations = action.payload.allVacations;
                draft.loading = false;
            break;
            case LOAD_VACATIONS_ALL_ERROR:
                draft.loading = false;
                toast.error('Diçka shkoi gabim!');
            break;
            case LOAD_CALENDAR_VACATIONS_ALL:
                draft.loading = true;
            break;
            case LOAD_CALENDAR_VACATIONS_SUCCESS:
                let newAllCalendarVacations = [...action.payload.allCalendarVacations];
                newAllCalendarVacations.forEach((item,idx)=>{
                    item.title = item.employeeName;
                    item.start = item.from;
                    item.end = item.to;
                    if(item.type === 0){
                        item.color = '#2DCE98'
                    }
                    else if(item.type === 1){
                        item.color = '#FEB969'
                    }
                    else if(item.type === 2){
                        item.color = '#11CDEF'
                    }
                    else{
                        item.color = '#D52D0F'
                    }
                })
                draft.allCalendarVacations = newAllCalendarVacations;
                draft.loading = false;
            break;
            case LOAD_CALENDAR_VACATIONS_ERROR:
                draft.loading = false;
            break;
            case LOAD_VACATION_TYPES_ALL:
                draft.loading = true;
            break;
            case LOAD_VACATION_TYPES_ALL_SUCCESS:
                draft.allVacationTypes = action.payload.allVacationTypes;
                draft.loading = false;
            break;
            case LOAD_VACATION_TYPE_ALL_ERROR:
                draft.loading = false;
            break;
            case LOAD_NEW_VACATION:
                draft.createLoading = true;
            break;
            case LOAD_NEW_VACATION_SUCCESS:
                draft.newVacation = action.payload.newVacation;
                draft.createLoading = false;
                if(action.payload?.newVacation?.data?.created == false){
                    toast.info('Nuk mund të aplikoni për pushim në këto data.');
                }else if(action.payload?.newVacation?.data?.created == null){
                    toast.warn('Aplikimi për pushim nuk mund te ruhet!');
                }else if(action.payload?.newVacation?.data?.created == 'Vacation already exists.'){
                    toast.warn('Nuk mund të aplikoni për pushim në të njejtat data!');
                }else{
                    draft.isVacationAdded = true;
                    toast.success('Aplikimi për pushim u ruajt me sukses!');
                }
            break;
            case LOAD_NEW_VACATION_ERROR:
                draft.createLoading = false;
                toast.error('Diçka shkoi gabim!');
            break;
            case LOAD_DELETE_VACATION:
                draft.isDeletingLoader = true;
            break;
            case LOAD_DELETE_VACATION_SUCCESS:
                draft.allVacations = action.payload.allVacations;
                draft.isDeletingLoader = false;
                toast.success('Pushimi është fshirë me sukses!');
            break;
            case LOAD_DELETE_VACATION_ERROR:
                draft.isDeletingLoader = false;
                toast.error('Diçka shkoi gabim!');
            break;
            case LOAD_UPDATE_VACATION:
                draft.updateLoading = true;
            break;
            case LOAD_UPDATE_VACATION_SUCCESS:
                draft.updatedVacation = action.payload.updatedVacation;
                draft.updateLoading = false;
                toast.success('Pushimi është edituar me sukses!');
            break;
            case LOAD_UPDATE_VACATION_ERROR:
                draft.updateLoading = false;
                toast.error('Diçka shkoi gabim!');
            break;
            case LOAD_SINGLE_VACATION:
                draft.loading = true;
            break;
            case LOAD_SINGLE_VACATION_SUCCESS:
                draft.singleVacation = action.payload.singleVacation;
                draft.loading = false;
            break;
            case LOAD_SINGLE_VACATION_ERROR:
                draft.loading = false;
                toast.error('Diçka shkoi gabim!');
            break;
            default:
                break;
        }
    });

export default vacationReducer;