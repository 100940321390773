const scope = "melitaPayroll/documents";

export const SAVE_DOCUMENT = `${scope}/SAVE_DOCUMENT`;
export const SAVE_DOCUMENT_SUCCESS = `${scope}/SAVE_DOCUMENT_SUCCESS`;
export const SAVE_DOCUMENT_ERROR = `${scope}/SAVE_DOCUMENT_ERROR`;

export const LOAD_ALL_DOCUMENTS = `${scope}/LOAD_ALL_DOCUMENTS`;
export const LOAD_ALL_DOCUMENTS_SUCCESS = `${scope}/LOAD_ALL_DOCUMENTS_SUCCESS`;
export const LOAD_ALL_DOCUMENTS_ERROR = `${scope}/LOAD_ALL_DOCUMENTS_ERROR`;
export const DELETE_SELECTED_DOCUMENT = `${scope}/DELETE_SELECTED_DOCUMENT`;
export const DELETE_SELECTED_DOCUMENT_SUCCESS = `${scope}/DELETE_SELECTED_DOCUMENT_SUCCESS`;
export const DELETE_SELECTED_DOCUMENT_ERROR = `${scope}/DELETE_SELECTED_DOCUMENT_ERROR`;
export const UPDATE_TEMPLATE = `${scope}/UPDATE_TEMPLATE`;
export const UPDATE_TEMPLATE_SUCCESS = `${scope}/UPDATE_TEMPLATE_SUCCESS`;
export const UPDATE_TEMPLATE_ERROR = `${scope}/UPDATE_TEMPLATE_ERROR`;
export const DOWNLOAD_DOCUMENT_TEMPLATE = `${scope}/DOWNLOAD_DOCUMENT_TEMPLATE`;
export const DOWNLOAD_DOCUMENT_TEMPLATE_SUCCESS = `${scope}/DOWNLOAD_DOCUMENT_TEMPLATE_SUCCESS`;
export const DOWNLOAD_DOCUMENT_TEMPLATE_ERROR = `${scope}/DOWNLOAD_DOCUMENT_TEMPLATE_ERROR`;
export const SEND_EMAIL_TEMPLATE = `${scope}/SEND_EMAIL_TEMPLATE`;
export const SEND_EMAIL_TEMPLATE_SUCCESS = `${scope}/SEND_EMAIL_TEMPLATE_SUCCESS`;
export const SEND_EMAIL_TEMPLATE_ERROR = `${scope}/SEND_EMAIL_TEMPLATE_ERROR`;