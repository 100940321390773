import {
  LOAD_ANNUAL_SALARIES,
  DOWNLOAD_ANNUAL_SALARIES_PDF,
  DOWNLOAD_ANNUAL_SALARIES_EXCEL,
  SEND_EMPLOYEES_EMAIL,
  SEND_ANNUAL_SALARY_EMAIL,
} from "./constants";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  downloadAnnualSalariesPdfService,
  downloadAnnualSalariesExcelService,
  getAnnualSalary,
  sendEmailForAnnualSalaries,
} from "services/report.service";
import {
  loadAnnualSalariesSuccess,
  loadAnnualSalariesError,
  downloadAnnualSalariesExcelSuccess,
  downloadAnnualSalariesPdfSuccess,
  downloadAnnualSalariesPdfError,
  downloadAnnualSalariesExcelError,
  sendAnnualSalaryToMailError,
  sendAnnualSalaryToMailSuccess,
} from "./actions";

export function* loadAnnualSalaries(action) {
  try {
    const response = yield call(
      getAnnualSalary,
      action.payload.tenantId,
      action.payload.year
    );
    yield put(loadAnnualSalariesSuccess(response.data));
  } catch (e) {
    yield put(loadAnnualSalariesError(e));
  }
}

export function* downloadAnnualSalaryExcel(action) {
  try {
    const response = yield call(
      downloadAnnualSalariesExcelService,
      action.payload.tenantId,
      action.payload.year
    );
    yield put(downloadAnnualSalariesExcelSuccess(response));
  } catch (e) {
    yield put(downloadAnnualSalariesExcelError(e));
  }
}

export function* downloadAnnualSalariesPdf(action) {
  try {
    const response = yield call(
      downloadAnnualSalariesPdfService,
      action.payload.tenantId,
      action.payload.year
    );
    yield put(downloadAnnualSalariesPdfSuccess(response));
  } catch (e) {
    yield put(downloadAnnualSalariesPdfError(e));
  }
}

export function* sendEmployeesEmail(action) {
  try {
    const response = yield call(
      sendEmailForAnnualSalaries,
      action.payload.tenantId,
      action.payload.year,
      action.payload.employeeIds,
      action.payload.email
    );
    yield put(sendAnnualSalaryToMailSuccess(response));
  } catch (e) {
    yield put(sendAnnualSalaryToMailError(e));
  }
}

export default function* annualSalariesSaga() {
  yield takeLatest(LOAD_ANNUAL_SALARIES, loadAnnualSalaries);
  yield takeLatest(DOWNLOAD_ANNUAL_SALARIES_EXCEL, downloadAnnualSalaryExcel);
  yield takeLatest(DOWNLOAD_ANNUAL_SALARIES_PDF, downloadAnnualSalariesPdf);
  yield takeLatest(SEND_ANNUAL_SALARY_EMAIL, sendEmployeesEmail);
}
