import { useTranslation } from 'react-i18next';
import produce from 'immer';
import {
	LOAD_ORDER_PAYMENT,
	LOAD_ORDER_PAYMENT_SUCCESS,
	LOAD_ORDER_PAYMENT_ERROR,
	LOAD_ORDER_PAYMENT_EXPORT_EXCEL,
	LOAD_ORDER_PAYMENT_EXPORT_EXCEL_SUCCESS,
	LOAD_ORDER_PAYMENT_EXPORT_EXCEL_ERROR,
	LOAD_ORDER_PAYMENT_EXPORT_PDF,
	LOAD_ORDER_PAYMENT_EXPORT_PDF_SUCCESS,
	LOAD_ORDER_PAYMENT_EXPORT_PDF_ERROR,
	SEND_EMAIL_PAYMENT_ORDER,
	SEND_EMAIL_PAYMENT_ORDER_SUCCESS,
	SEND_EMAIL_PAYMENT_ORDER_ERROR,
} from "./constants";
import { toast } from 'react-toastify';

const initialState: any = {
	loading: false,
	allPaymentOrders: [],
	loadingEmailButton: false,
}

const paymentOrderReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case LOAD_ORDER_PAYMENT:
				draft.loading = true;
				break;
			case LOAD_ORDER_PAYMENT_SUCCESS:
				draft.allPaymentOrders = action.payload.allPaymentOrders;
				draft.loading = false;
				break;
			case LOAD_ORDER_PAYMENT_ERROR:
				draft.loading = true;
				toast.error('Diçka shkoi gabim!');
				break;
			case LOAD_ORDER_PAYMENT_EXPORT_EXCEL:
				draft.loading = true;
				break;
			case LOAD_ORDER_PAYMENT_EXPORT_EXCEL_SUCCESS:
				window.open(action.payload?.excelFile?.data, "_blank");
				break;
			case LOAD_ORDER_PAYMENT_EXPORT_EXCEL_ERROR:
				draft.loading = true;
				toast.error('Diçka shkoi gabim!');
				break;
			case LOAD_ORDER_PAYMENT_EXPORT_PDF:
				draft.loading = true;
				break;
			case LOAD_ORDER_PAYMENT_EXPORT_PDF_SUCCESS:
				window.open(action.payload?.pdfFile?.data, "_blank");
				break;
			case LOAD_ORDER_PAYMENT_EXPORT_PDF_ERROR:
				draft.loading = true;
				toast.error('Diçka shkoi gabim!');
				break;
			case SEND_EMAIL_PAYMENT_ORDER:
				draft.loadingEmailButton = true;
				break;
			case SEND_EMAIL_PAYMENT_ORDER_SUCCESS:
				draft.loadingEmailButton = false;
				toast.success('Emailet u dërguan me sukses!');
				break;
			case SEND_EMAIL_PAYMENT_ORDER_ERROR:
				draft.loadingEmailButton = false;
				toast.error('Diçka shkoi gabim!');
				break;

			default:
				break;
		}
	});

export default paymentOrderReducer;