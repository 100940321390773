import { MonthlyStatisticsReport } from "types/reports.type";
import {
	LOAD_MONTHLY_STATISTICS,
	LOAD_MONTHLY_STATISTICS_SUCCESS,
	LOAD_MONTHLY_STATISTICS_ERROR,
	LOAD_EXPORT_EXCEL,
	LOAD_EXPORT_EXCEL_SUCCESS,
	LOAD_EXPORT_EXCEL_ERROR,
	LOAD_EXPORT_PDF,
	LOAD_EXPORT_PDF_SUCCESS,
	LOAD_EXPORT_PDF_ERROR,
	SET_EMPLOYEES_CHECKED,
	SEND_EMPLOYEES_EMAIL_MONTHLY,
	SEND_EMPLOYEES_EMAIL_MONTHLY_SUCCESS,
	SEND_EMPLOYEES_EMAIL_MONTHLY_ERROR,
} from "./constants";

export const loadMonthlyStatistics = (tenantId: number, searchTerm: string, month: any, year: any) => {
	return {
		type: LOAD_MONTHLY_STATISTICS,
		payload: {
			tenantId,
			searchTerm,
			month,
			year
		}
	}
}

export const loadMonthlyStatisticsSuccess = (allMonthlyStatistics: MonthlyStatisticsReport[]) => {
	return {
		type: LOAD_MONTHLY_STATISTICS_SUCCESS,
		payload: {
			allMonthlyStatistics
		}
	}
}

export const loadMonthlyStatisticsError = () => {
	return {
		type: LOAD_MONTHLY_STATISTICS_ERROR,
		payload: {
		}
	}
}

export const loadExportExcel = (payload) => {
	return {
		type: LOAD_EXPORT_EXCEL,
		payload
	}
}

export const loadExportExcelSuccess = (excelFile) => {
	return {
		type: LOAD_EXPORT_EXCEL_SUCCESS,
		payload: {
			excelFile
		}
	}
}

export const loadExportExcelError = () => {
	return {
		type: LOAD_EXPORT_EXCEL_ERROR,
		payload: {
		}
	}
}



export const loadExportPdf = (payload) => {
	return {
		type: LOAD_EXPORT_PDF,
		payload
	}
}

export const loadExportPdfSuccess = (pdfFile) => {
	return {
		type: LOAD_EXPORT_PDF_SUCCESS,
		payload: {
			pdfFile
		}
	}
}

export const loadExportPdfError = () => {
	return {
		type: LOAD_EXPORT_PDF_ERROR,
		payload: {
		}
	}
}

export const setEmployeesChecked = (checked) => {
	return {
		type: SET_EMPLOYEES_CHECKED,
		payload: {
			checked
		}
	}
}

export const sendEmployeesEmail = (tenantId: number, month: number, year: number, employeeIds: Array<number>, email: string) => {
	return {
		type: SEND_EMPLOYEES_EMAIL_MONTHLY,
		payload: {
			tenantId,
			month,
			year,
			employeeIds,
			email
		}
	}
}

export const sendEmployeesEmailSuccess = (data) => {
	return {
		type: SEND_EMPLOYEES_EMAIL_MONTHLY_SUCCESS,
		payload: {
			data
		}
	}
}

export const sendEmployeesEmailError = (error) => {
	return {
		type: SEND_EMPLOYEES_EMAIL_MONTHLY_ERROR,
		payload: {
			error
		}
	}
}