import styles from './Notification.module.scss';
import NavTabs from '../../components/NavTabs/NavTabs.component';
import {useCallback, useEffect, useState} from 'react';
import LoaderCentered from '../../components/partials/LoaderCentered/LoaderCentered.component';
import NavTab from '../../components/NavTabs/NavTab.component';
import {NotificationEntry} from '../../components/NotificationEntry/notificationEntry.component';
import {useDispatch, useSelector} from 'react-redux';
import {globalState} from '../../types/globalState.type';
import {Notification} from '../../types/notification.type';
import InfiniteScroll from 'react-infinite-scroll-component';
import {getPaginatedNotifications, readAllNotifications} from './actions';
import DropdownMenu from '../../components/partials/DropdownMenu/DropdownMenu.component';
import noNotificationLink from '../../assets/images/no_notifications.png';

interface NotificationsEntriesProps {
  notifications: Notification[]
}

const NotificationEntries: React.FC<NotificationsEntriesProps> = (props) => {
  return <div className={styles.notificationEntriesParent}>
    {props.notifications.map((notification) => {
      return <NotificationEntry notification={notification} key={notification.id}/>;
    })}
  </div>;
};

export const Notifications: React.FC = () => {
  const notifications: Notification[] = useSelector((state: globalState) => state.notificationReducer.notifications);
  const total: number = useSelector((state: globalState) => state.notificationReducer.total);

  const dispatch = useDispatch();
  const getNotificationsPaged = useCallback((page: number, canSeeUnread: boolean) => dispatch(getPaginatedNotifications(page, canSeeUnread)), [dispatch]);
  const markAllAsRead = useCallback(() => dispatch(readAllNotifications()), [dispatch]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [canSeeRead, setCanSeeRead] = useState<boolean>(true);

  useEffect(() => {
    getNotificationsPaged(currentPage, canSeeRead);
  }, []);

  useEffect(() => {
    getNotificationsPaged(currentPage, canSeeRead);
  }, [canSeeRead]);

  const loadNextNotificationPage = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);

    getNotificationsPaged(nextPage, canSeeRead);
  };

  const onTabChange = (index: number) => {
    setCurrentPage(1);
    setCanSeeRead(index === 0);
  };

  return <div className={styles.notificationsContainer}>
    <div className={styles.titleSection}>
      <p>Notifications</p>
      <DropdownMenu>
        <div
            onClick={() => {
              markAllAsRead();
            }}
            className={styles.markAllContainer}
        >
          Shenoj te gjitha si te lexuara
        </div>
      </DropdownMenu>
    </div>
    <div className={styles.notificationTabSection}>
      <NavTabs
          tabClickHandler={onTabChange}
      >
        <NavTab title="Te gjitha"/>
        <NavTab title="Te pa lexuara"/>
      </NavTabs>
    </div>
    {notifications
        ? notifications.length > 0
            ? <div id="scrollableNotificationDiv" className={styles.infiniteScrollContainer}>
              <InfiniteScroll
                  next={() => loadNextNotificationPage()}
                  hasMore={total !== notifications.length}
                  loader={<></>}
                  dataLength={100}
                  scrollableTarget="scrollableNotificationDiv"
              >
                <div className={styles.notificationSubTitle}>Të reja</div>
                <NotificationEntries notifications={[...notifications].slice(0, 5)}/>
                <div className={styles.notificationSubTitle}>Më të hershme</div>
                <NotificationEntries notifications={[...notifications].slice(5)}/>
              </InfiniteScroll>
            </div>

            : <div className={styles.noNotificationContainer}><img style={{ width: '50%' }} src={noNotificationLink}/></div>
        : <div className={styles.infiniteScrollContainer}><LoaderCentered/></div>
    }
  </div>;
};
