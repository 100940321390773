import React, { SetStateAction, useEffect, useRef, useState } from "react";
import styles from "./SalaryListReport.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { globalState } from "types/globalState.type";
import { format, set } from "date-fns";
import { getEDIData } from "services/report.service";
import { downloadSalaryListExcel } from './actions';
import { useTranslation } from "react-i18next";
import ModalCustom from "components/ModalCustom/ModalCustom.component";
import DropdownSelect from "components/DropdownSelect/DropdownSelect.component";
import { FiDownload, FiCalendar } from "react-icons/fi";
import { downloadTypeOptions, monthNamesAL } from "utils/globalVariables";
import { IconDocument } from "assets/icons/icons";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { DebounceInput } from "react-debounce-input";
import Table from "components/Table/Table.component";
import { generateArrayOfYears, number_format } from "utils/functions";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
};

let currentYear: number = new Date().getFullYear();
let currentMonth: number = new Date().getMonth();

type YesNo = "PO" | "JO";

const pageStyle = `
@page {
    padding: 0 10px;
    font-size: 15px;
    background-color: red;
}
.noPrint {
    display: none !important;
}
  
.printHeightFix {
    height: unset !important;
    min-height: unset !important;
    overflow: initial !important;
}
`;

type SalaryEDI = {
  firstName: string;
  lastName: string;
  personalNumber: string;
  gross: string;
  personalContribute: string;
  employerContribute: string;
  supplementaryPersonalContribute: string;
  supplementaryEmployerContribute: string;
  primaryWorkplace: YesNo;
  contributesIncluded: YesNo;
  hasTaxes: YesNo;
};

const monthsList = monthNamesAL;
let yearsList = generateArrayOfYears(21);

const SalaryListReport: React.FC<Props> = ({ open, setOpen }) => {
  const tenantId = useSelector(
    (state: globalState) => state.globalReducer.tenantId
  );

  const dispatch = useDispatch();

  const todayDate = format(new Date(), "dd.MM.yyyy");
  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useState<boolean>(false);
  const [pickedYear, setPickedYear] = useState(currentYear);
  const [pickedMonth, setPickedMonth] = useState(currentMonth);
  const [salaries, setSalaries] = useState<SalaryEDI[]>([]);
  const [showFilterContent, setShowFilterContent] = useState(false);

  const backdropParent: string = useSelector(
    (state: globalState) => state.appWrapperReducer.backdropParent
  );

  const { t } = useTranslation("common");

  const tableHeaderData = [
    t("edi.firstName"),
    t("edi.lastName"),
    t("edi.personalNumber"),
    t("edi.grossPerMonth"),
    t("edi.personalContribute"),
    t("edi.employerContribute"),
    t("edi.primary"),
    t("edi.contributesIncluded"),
    t("edi.hasTaxes"),
  ];

  const componentRef = useRef<HTMLDivElement>(null);
  const monthsWrapperRef = useRef(null);
  const periodWrapperRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: () => pageStyle,
  });

  useEffect(() => {
    if (
      !tenantId?.toString().length ||
      pickedMonth == null ||
      pickedYear == null
    )
      return;

    const loadData = async () => {
      try {
        const response = await getEDIData(
          tenantId,
          pickedMonth + 1,
          pickedYear
        );
        setSalaries(response.data);
      } catch (error) {
        console.error("failed to load data");
      }
    };

    loadData();
  }, [tenantId, pickedMonth, pickedYear]);

  const handleDownloadPick = (e, item) => {
    if (item.value === 1) {
        dispatch(downloadSalaryListExcel(tenantId, pickedYear, pickedMonth + 1));
    } else {
        handlePrint();
    }
  };

  const onPrint = () => {
    handlePrint();
  };
  const handlePeriodContainerClick = (event) => {
    setShowFilterContent((prev) => !prev);
  };

  return (
    <>
      <ModalCustom
        show={open}
        contentClass={styles.contentClass}
        footerClass={styles.footerClass}
        ref={componentRef}
        onClose={() => {
          setOpen(false);
        }}
        hideFooterButton={true}
      >
        <div className={styles.monthlyStatistics}>
          <div className={styles.point}></div>
          <div className={styles.monthlyStatisticTitle}>
            {t("reports.ediSalaries")}
          </div>
          <div
            className={`${styles.downloadText} noPrint`}
            onClick={() => setIsDownloadModalOpen(true)}
          >
            <div className={styles.dropdownText}>
              <DropdownSelect
                data={downloadTypeOptions}
                buttonText={
                  <div className={`${styles.dropdownText} pr-5`}>
                    <div className={styles.downloadIcon}>
                      <FiDownload strokeWidth="2px" />
                    </div>
                    {t("reports.download")}
                  </div>
                }
                showArrow={true}
                noWidth={true}
                menuPosition="right"
                optionClick={(event, item) => {
                  handleDownloadPick(event, item);
                }}
              />
            </div>
          </div>
          <div className={`${styles.printIcon} noPrint`}>
            <IconDocument stroke={"#89C732"} />
          </div>
          <div className={`${styles.downloadText} ${styles.iconText} noPrint`}>
            <div
              className={styles.printText}
              onClick={onPrint}
              onKeyDown={onPrint}
            >
              {t("reports.print")}
            </div>
          </div>
          <div className={styles.headerDate}>Data: {todayDate}</div>
        </div>

        <div className={`row ${styles.filterRow}`}>
          <div className={`${styles.filterDescription}`}>
            <span className="noPrint">{t("reports.filterPeriod")}</span>
            <div
              className={`${styles.filterButtons} ${
                backdropParent !== "calculationsPeriodFilter" ? "lowZindex" : ""
              }`}
              onClick={handlePeriodContainerClick}
            >
              <div className={styles.button}>{monthsList[pickedMonth]}</div>
              <div className={`${styles.button} ${styles.yearsButton}`}>
                {pickedYear}
                <div className={styles.calendar}>
                  <FiCalendar />
                </div>
              </div>
            </div>
          </div>

          {showFilterContent && (
            <div className={styles.filterContent} ref={periodWrapperRef}>
              <div className={styles.months} ref={monthsWrapperRef}>
                {monthsList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`${styles.periodItem} ${
                        index === pickedMonth ? styles.selected : ""
                      }`}
                      onClick={() => {
                        setPickedMonth((prev) => index);
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
              <div className={styles.years}>
                {yearsList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`${styles.periodItem} ${
                        item === pickedYear ? styles.selected : ""
                      }`}
                      onClick={() => {
                        setPickedYear((prev) => +item);
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className={styles.tableContainer}>
          <Table headerData={tableHeaderData}>
            <tbody className={styles.tableBody}>
              {salaries?.length > 0 &&
                salaries?.map((data, idx) => {
                  return (
                    <tr className={styles.tableRow} key={idx}>
                      <td className={`${styles.bodyCell} ${styles.text}`}>
                        <div>{data?.firstName}</div>
                      </td>
                      <td className={`${styles.bodyCell} ${styles.text}`}>
                        <div>{data?.lastName}</div>
                      </td>
                      <td className={`${styles.bodyCell} ${styles.text}`}>
                        {data?.personalNumber}
                      </td>
                      <td className={`${styles.bodyCell} ${styles.text}`}>
                        {number_format(data?.gross, 2, ".", ",")}
                      </td>
                      <td className={`${styles.bodyCell} ${styles.text}`}>
                        {number_format(data?.personalContribute, 2, ".", ",")}
                      </td>
                      <td className={`${styles.bodyCell} ${styles.text}`}>
                        {number_format(data?.employerContribute, 2, ".", ",")}
                      </td>
                      <td className={`${styles.bodyCell} ${styles.text}`}>
                        {data?.primaryWorkplace}
                      </td>
                      <td className={`${styles.bodyCell} ${styles.text}`}>
                        {data?.contributesIncluded}
                      </td>
                      <td className={`${styles.bodyCell} ${styles.text}`}>
                        {data?.hasTaxes}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </ModalCustom>
    </>
  );
};
export default SalaryListReport;
