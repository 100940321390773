import { call, put, takeLatest } from "redux-saga/effects";
import {
  CERTIFICATE_PDF,
  EMAIL_CERTIFICATE,
  EMAIL_VACATION_LIST,
  EXPORT_CERTIFICATE,
  EXPORT_EXCEL_VACATION_LIST,
  LOAD_ANNUAL_CERTIFICATE,
  LOAD_EMPLOYEE_DATA,
  LOAD_VACATION_LIST,
  VACATION_LIST_PDF,
} from "./constants";
import {
  certificatePdf,
  emailVacationList,
  exportCertificate,
  exportVacationList,
  getEmployeeCertification,
  getTenantCertificate,
  getVacationList,
  sendCertificateToMail,
  vacationListPdf,
} from "services/report.service";

import {
  loadAnnualCertificateSuccess,
  loadAnnualCertificateError,
  loadEmployeeDataSuccess,
  loadEmployeeDataError,
  certificatePdfSuccess,
  certificatePdfError,
  exportCertificateSuccess,
  exportCertificateError,
  emailCertificateSuccess,
  emailCertificateError,
  loadVacationListSuccess,
  loadVacationListError,
  emailVacationListSuccess,
  emailVacationListError,
  exportExcelVacationListSuccess,
  exportExcelVacationListError,
  vacationListPdfSuccess,
  vacationListPdfError,
} from "./actions";

export function* loadAnnualCertificate_Saga(action) {
  try {
    const response = yield call(getTenantCertificate, action.payload.tenantId);
    yield put(loadAnnualCertificateSuccess(response.data.data));
  } catch (e) {
    yield put(loadAnnualCertificateError(e));
  }
}

export function* loadVacationList_Saga(action) {
  try {
    const { tenantId, from, to } = action.payload;
    const response = yield call(getVacationList, tenantId, from, to);
    yield put(loadVacationListSuccess(response.data));
  } catch (e) {
    yield put(loadVacationListError(e));
  }
}

export function* loadEmployeeData_Saga(action) {
  try {
    if (action?.payload?.employeeId) {
      const response = yield call(
        getEmployeeCertification,
        action.payload.employeeId
      );
      yield put(loadEmployeeDataSuccess(response.data.data));
    } else {
      yield put(loadEmployeeDataSuccess({}));
    }
  } catch (e) {
    yield put(loadEmployeeDataError(e));
  }
}

export function* emailCertificate_Saga(action) {
  const { tenantId, employeeId, email } = action.payload;

  try {
    const response = yield call(
      sendCertificateToMail,
      tenantId,
      employeeId,
      email
    );
    yield put(emailCertificateSuccess(response.data));
  } catch (e) {
    yield put(emailCertificateError(e));
  }
}
export function* certificatePdf_Saga(action) {
  try {
    const response = yield call(
      certificatePdf,
      action.payload.tenantId,
      action.payload.employeeId
    );
    yield put(certificatePdfSuccess(response.data));
  } catch (e) {
    yield put(certificatePdfError(e));
  }
}
export function* exportCertificate_Saga(action) {
  try {
    const response = yield call(
      exportCertificate,
      action.payload.tenantId,
      action.payload.employeeId
    );
    yield put(exportCertificateSuccess(response.data));
  } catch (e) {
    yield put(exportCertificateError(e));
  }
}

export function* emailVacationList_Saga(action) {
  try {
    const response = yield call(emailVacationList, action.payload);
    yield put(emailVacationListSuccess(response.data));
  } catch (e) {
    yield put(emailVacationListError(e));
  }
}
export function* vacationListPdf_Saga(action) {
  try {
    const { tenantId, from, to } = action.payload;
    const response = yield call(vacationListPdf, tenantId, from, to);
    yield put(vacationListPdfSuccess(response.data));
  } catch (e) {
    yield put(vacationListPdfError(e));
  }
}
export function* exportExcelVacationList_Saga(action) {
  try {
    const { tenantId, month, year } = action.payload;
    const response = yield call(exportVacationList, tenantId, month, year);
    yield put(exportExcelVacationListSuccess(response.data));
  } catch (e) {
    yield put(exportExcelVacationListError(e));
  }
}

export default function* employeeReportsSaga() {
  yield takeLatest(LOAD_ANNUAL_CERTIFICATE, loadAnnualCertificate_Saga);
  yield takeLatest(LOAD_VACATION_LIST, loadVacationList_Saga);
  yield takeLatest(LOAD_EMPLOYEE_DATA, loadEmployeeData_Saga);
  yield takeLatest(CERTIFICATE_PDF, certificatePdf_Saga);
  yield takeLatest(EXPORT_CERTIFICATE, exportCertificate_Saga);
  yield takeLatest(EMAIL_CERTIFICATE, emailCertificate_Saga);
  yield takeLatest(EMAIL_VACATION_LIST, emailVacationList_Saga);
  yield takeLatest(VACATION_LIST_PDF, vacationListPdf_Saga);
  yield takeLatest(EXPORT_EXCEL_VACATION_LIST, exportExcelVacationList_Saga);
}
