import React from 'react';
import { CgMenuRight } from 'react-icons/cg';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Menu from "./Menu/Menu.component";
import styles from "./SideBar.module.scss";
import { collapseSidebarMenu } from 'components/AppWrapper/actions';
import payshefLogoWide from 'assets/icons/payShef-03.svg';
import payshefLogo from 'assets/icons/payshefLogo.svg';

const SideBar: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const menuCollapseClick = () => dispatch(collapseSidebarMenu(!menuCollapse));
    const menuCollapse = useSelector((state: RootStateOrAny) => state.appWrapperReducer.sidebarMenuCollapsed);

    const handleToDashboard = () => {
        history.push("/");
    }

    return (
        <div className={menuCollapse ? styles.sideBar : `${styles.sideBar} ${styles.open}`}>
            <div className={`${styles.headerDiv} ${menuCollapse ? "pointer" : ""}`} {...(menuCollapse && { onClick: menuCollapseClick })}>
                <div className={`${styles.productLogo}`}>
                    <img className={menuCollapse ? `${styles.showIcon}` : ""} src={payshefLogo} alt="Logo"/>
                    <img className={!menuCollapse ? `${styles.showIcon} ${styles.large}` : ""} src={payshefLogoWide} alt="Logo"  onClick={handleToDashboard}/>
                </div>
                {!menuCollapse ?
                    <div className={styles.iconDiv} onClick={menuCollapseClick} >
                        <CgMenuRight className={styles.viewModeButton} />
                    </div>
                    : ""
                }
            </div>
            <div className={styles.contentDiv}>
                <Menu />
            </div>
        </div>
    );
}

export default SideBar;